import React from 'react';
import { dateFormat } from '../../../../../utils/tableFormatters';
import iconFormatter from '../../../../../../pages/private/utils/iconFormatter';

function IntervalFormatter(row, intervals) {
  const { data } = intervals;
  const week = {
    'Пн:': 'monday_id',
    'Вт:': 'tuesday_id',
    'Ср:': 'wednesday_id',
    'Чт:': 'thursday_id',
    'Пт:': 'friday_id',
    'Сб:': 'saturday_id',
    'Вс:': 'sunday_id',
  };

  return (
    <div className="intervalBox">
      {Object.keys(week).map((el) => {
        const ele = data.find((elem) => elem.id === row[week[el]]);
        return (
          <div className="connectIntervals" key={el}>
            {el}
            <span>{ele ? ele.name : 'Не задано'}</span>
          </div>
        );
      })}
    </div>
  );
}

export default function TableConfig(intervals, write, searchPropsFactory) {
  return {
    columns: [
      {
        dataIndex: 'name',
        title: 'Наименование',
        ...searchPropsFactory('name', 'наименованию'),
      },
      {
        dataIndex: 'description',
        title: 'Описание',
        ...searchPropsFactory('name', 'наименованию'),
      },
      {
        dataIndex: 'modified_by_username',
        title: 'Изменен',
        ...searchPropsFactory('name', 'наименованию'),
      },
      {
        dataIndex: 'modified_on',
        title: 'Когда изменен',
        ...searchPropsFactory('modified_on', 'дате/времени изменения'),
        render: (value) => (
          <p>{dateFormat(value)}</p>
        ),
      },
      {
        dataIndex: 'connectIntervals',
        title: 'Подключенные интервалы',
        width: 450,
        ...searchPropsFactory('connectIntervals', 'подключенным интервалам'),
        render: (cell, column) => IntervalFormatter(column, intervals),
      },
      {
        dataIndex: 'Settings',
        title: '',
        width: 100,
        render: () => iconFormatter({ filter: false, write, params: false }),
      },
    ],
  };
}
