import React, { useState, useEffect, useRef } from 'react';
import { FaMale } from 'react-icons/fa';
import { Button, Typography } from 'antd';
import promo from '../../../../../../../common/images/Nps/test/promo.png';
import haters from '../../../../../../../common/images/Nps/test/haters.png';
import pasive from '../../../../../../../common/images/Nps/test/pasive.png';
import newArrow from '../../../../../../../common/images/Nps/test/newArrow.png';

import circle from '../../../../../../../common/images/Nps/test/circle.png';

import { ReportParametrsBox } from '../../../../../components/report/ReportMenu';

function NpsCard({ data, test }) {
  const barcodeRef = useRef(null);
  const [cardData, setCardData] = useState({
    peopleData: [],
    pGroupData: [],
    circleData: {
      npsScore: 0, npsArrowDegree: 0,
    },
  });

  const colorMap = [
    '#ba0000',
    '#e52000',
    '#e54600',
    '#e56d00',
    '#e59200',
    '#e5b900',
    '#e5d400',
    '#e5e500',
    '#b4e500',
    '#5ae500',
  ];

  const prepareData = () => {
    const { all_cnt: allCnt } = data;
    const peopleData = [];

    let hayterCnt = 0;
    let pasiveCnt = 0;
    let promoCnt = 0;

    Object.keys(data).forEach((key) => {
      if (key.indexOf('cnt') === 0) {
        const isplit = key.split('cnt');
        const idx = (data.cnt6 === '0' && data.cnt7 === '0' && data.cnt8 === '0' && data.cnt9 === '0' && data.cnt10 === '0') ? isplit[1] * 2 : isplit[1];
        const pCount = data[key];
        peopleData.push({
          count: data[key],
          persent: (allCnt > 0) ? ((pCount * 100) / allCnt).toFixed(2) : 0,
          color: colorMap[idx - 1],
        });

        if (idx > 8) {
          promoCnt += Number(pCount);
        } else if (idx > 6) {
          pasiveCnt += Number(pCount);
        } else {
          hayterCnt += Number(pCount);
        }
      }
    });

    const pGroupData = [{
      title: 'Критики',
      cnt: hayterCnt,
      persent: (allCnt > 0) ? ((hayterCnt * 100) / allCnt).toFixed(2) : 0,
    },
    {
      title: 'Нейтральные',
      cnt: pasiveCnt,
      persent: (allCnt > 0) ? ((pasiveCnt * 100) / allCnt).toFixed(2) : 0,
    },
    {
      title: 'Cторонники',
      cnt: promoCnt,
      persent: (allCnt > 0) ? ((promoCnt * 100) / allCnt).toFixed(2) : 0,
    },
    ];
    const npsScore = (pGroupData[2].persent - pGroupData[0].persent).toFixed(2);
    const npsArrowDegree = (npsScore / 100) * 150;

    setCardData({
      pGroupData,
      peopleData,
      circleData: { npsScore, npsArrowDegree },
    });
  };

  useEffect(() => {
    prepareData();
  }, [data]);

  const getGroupImg = (i) => {
    switch (i) {
      case 0:
        return <img src={haters} alt="logo" />;
      case 1:
        return <img src={pasive} alt="logo" />;
      case 2:
        return <img src={promo} alt="logo" />;
      default:
        return '';
    }
  };

  const { circleData, pGroupData, peopleData } = cardData;

  return (
    <>
      <Typography.Title level={2} style={{ textAlign: 'center' }}>
        Индекс потребительской лояльности
      </Typography.Title>
      <div className="exportData">
        <Button size="large" type="primary" onClick={() => test(barcodeRef)}>Экспортировать</Button>
      </div>
      <ReportParametrsBox ref={barcodeRef}>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20px',
          height: 'max-content',
        }}
        >
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {peopleData.filter((el) => !!el.color).map((people, i) => (
                <div className="npsPeopleBox" key={Math.random()}>
                  <div className="npsPeople">
                    <FaMale
                      size="80px"
                      color={people.color}
                    />
                    <div className="npsPeopleNumber">{i + 1}</div>
                  </div>
                  <div className="npsPeopleDescBox">
                    <div className="npsPeopleAllCnt">{people.count}</div>
                    <div className="npsPeoplePercent">
                      (
                      {people.persent}
                      %)
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="npsPeopleGroupBox">
              {pGroupData.map((group, i) => (
                <div className="tolpaBox" key={group.title}>
                  <div className="tolpa">
                    {getGroupImg(i)}
                  </div>
                  <div className="toltopaDesc">
                    <div className="tolpaDescItem">{group.title}</div>
                    <div className="tolpaDescItem">{group.cnt}</div>
                    <div className="tolpaDescItem">
                      (
                      {group.persent}
                      %)
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div style={{ marginLeft: '25px' }}>
            <div className="npsScoreBox">
              {/* <div className="tolpaDescItem">NPS</div> */}
              <div className="npsScore">
                NPS:
                <span className="npsScorePercent">
                  {(circleData.npsScore)}
                  %
                </span>
              </div>

            </div>
            <div className="cercleBox">
              <img src={circle} className="circle" alt="dasd" />
              <img src={newArrow} className="arrow" alt="dasd" style={{ transform: `rotate(${circleData.npsArrowDegree}deg)` }} />
            </div>
          </div>
        </div>

      </ReportParametrsBox>
    </>
  );
}

export default NpsCard;
