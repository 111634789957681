import React from 'react';
import { BtnTable } from '../../../../common/ui/Common';
import { ReactComponent as True } from '../../../../common/images/true.svg';
import { ReactComponent as False } from '../../../../common/images/false.svg';
import { dateFormat, placeholder } from '../../../utils/tableFormatters';
import iconFormatter from '../../../../pages/private/utils/iconFormatter';

function checkFormatter(value) {
  return (
    value
      ? <BtnTable active bg="accent" hNone><True /></BtnTable>
      : <BtnTable bg="gray" hNone><False /></BtnTable>
  );
}

export default function TableConfig(searchPropsFactory) {
  return {
    columns: [
      {
        dataIndex: 'name',
        title: 'Наименование',
        headerFormatter: placeholder,
        ...searchPropsFactory('name', 'наименованию'),
      },
      {
        dataIndex: 'address',
        title: 'IP адрес',
        ...searchPropsFactory('address', 'IP адресу'),
        render(cell) {
          return cell.split(':')[0];
        },
      },
      {
        dataIndex: 'port',
        title: 'Порт',
        ...searchPropsFactory('port', 'порту'),
        render(cell, row) {
          return row.address.split(':')[1];
        },
      },
      {
        dataIndex: 'number',
        title: 'Номер',
        editable: false,
      },
      {
        dataIndex: 'created_by_username',
        title: 'Создан',
        sort: true,
        editable: false,
      },
      {
        dataIndex: 'created_on',
        title: 'Дата создания',
        sort: true,
        editable: false,
        render: (val) => (
          <p>{dateFormat(val)}</p>
        ),
      },
      {
        dataIndex: 'enabled',
        title: 'Включен',
        sort: true,
        editable: false,
        render: checkFormatter,
      },
      {
        dataIndex: 'Settings',
        isDummyField: true,
        title: ' ',
        render: () => iconFormatter({ write: true }),
        formatExtraData: {},
        editable: false,
        width: 100,
      },
    ],
  };
}
