import React from 'react';
import ReactDOM from 'react-dom';
import { PreloaderBackground, ModalBox } from '../../../../common/ui/Common2';
import { Animate } from '../../../../pages/private/components/preloader/preload';

const PortalPreloader = ({ className }) => {
  const modalMarkup = (
    <PreloaderBackground className={className}>
      <ModalBox>
        <Animate />
      </ModalBox>
    </PreloaderBackground>
  );

  return ReactDOM.createPortal(modalMarkup, document.body);
};

export default PortalPreloader;
