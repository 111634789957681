import ticket from './defaultTicket';

const setTicket = ({ name, value }) => ({
  type: 'CHANGE-TICKET',
  value,
  name,
});
const resetTicket = (value) => ({
  type: 'RESET-TICKET',
  value: value || ticket,
});
const recoveryTicket = ({ value }) => ({
  type: 'RECOVERY',
  value,
});

const changeDictionary = (arr) => ({
  type: 'ADD-DICTIONARY',
  value: arr,
});

const changeEmulatePreRecord = (boolen) => ({
  type: 'CHANGE-EMULATEPRERECORD',
  value: boolen,
});

const resetPre = () => ({
  type: 'RESET-PRE',
});
const setPre = (payload) => ({
  type: 'SET-PRE',
  payload,
});

const clearBeforeDataScene = (id) => ({
  type: 'CLEAR-DICTIONARY',
  value: id,
});

const setConfig = (value) => ({
  type: 'SET-CONFIG',
  value,
});
const changeConfig = ({ value, name }) => ({
  type: 'CHANGE-CONFIG',
  value,
  name,
});
const setStyle = (value) => ({
  type: 'SET-STYLE',
  value,
});
const setWS = (value) => ({
  type: 'SET-WS',
  value,
});
const setTextError = (value) => ({
  type: 'SET-ERROR',
  value,
});

const setConfirmText = (value) => ({
  type: 'SET-CONFIRM',
  value,
});

const setInfoScreen = (value) => ({
  type: 'SET_INFO',
  value,
});

export {
  changeDictionary,
  setConfig,
  setStyle,
  setWS,
  setTicket,
  setInfoScreen,
  resetTicket,
  recoveryTicket,
  setTextError,
  clearBeforeDataScene,
  resetPre,
  setPre,
  changeConfig,
  changeEmulatePreRecord,
  setConfirmText,
};
