import React, { useState } from 'react';
import { Button } from 'antd';
import Modal from '../../../../../../common/ui/Modal/Modal';
import request from '../../../../../../logic/utils/request';
import RenderInputs from './RenderInputs';
import ModalButtons from '../../../../../../common/ui/Modal/ModalButtons';

function NewField({
  isOpen, toggleOpen, fetchFields, fieldUpdate, setFieldData,
  fieldData, initialData, setFieldUpdate, preloader,
  bookUpdate, setBookUpdate, showError, write,
}) {
  const [isValid, setIsValid] = useState(false);

  const resetModal = () => {
    setFieldData({ ...initialData });
    toggleOpen();
    setFieldUpdate(false);
    setIsValid(false);
    setBookUpdate(false);
  };

  const addField = async () => {
    if (isValid) {
      try {
        await request('/server/api/ticketsfields/', 'add', { ...fieldData });
        fetchFields();
        resetModal();
      } catch (e) {
        showError(e.data.error);
      }
    }
  };

  const updateField = async () => {
    if (isValid) {
      const itemId = fieldData.id;
      // eslint-disable-next-line no-param-reassign
      delete fieldData.id;
      await request(`/server/api/ticketsfields/${itemId}/`, 'update', { ...fieldData });
      fetchFields();
      resetModal();
    }
  };

  return (
    <>
      <div>
        <Button type="primary" size="large" onClick={toggleOpen}>Добавить</Button>
      </div>
      <Modal title={fieldUpdate ? 'Редактирование поля' : 'Добавление поля'} isOpen={isOpen} toggleOpen={resetModal} width="850px">
        <RenderInputs
          fieldData={fieldData}
          setFieldData={setFieldData}
          setIsValid={setIsValid}
          preloader={preloader}
          write={write}
          fieldUpdate={fieldUpdate}
          bookUpdate={bookUpdate}
          showError={showError}
        />
        <ModalButtons
          isValid={isValid}
          update={fieldUpdate}
          addFunc={addField}
          updateFunc={updateField}
          reset={resetModal}
        />
      </Modal>
    </>
  );
}

export default React.memo(NewField);
