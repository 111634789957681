import React, { useRef, useState } from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.min.css';
import locale from 'antd/es/locale/ru_RU';
import TableConfig from './TableConfig';
import { Title } from '../../../../../../../common/ui/Common';
import { getColumnSearchProps } from './getColumnSearchProps';
import { ReportBox } from '../../../../../components/report/ReportTableColumns';

function WorkPlaceList(props) {
  const { data, userKick } = props;

  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const input = useRef(null);

  const config = TableConfig(userKick, getColumnSearchProps({
    input,
    searchedColumn,
    searchText,
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    },
    handleReset(clearFilters) {
      clearFilters();
      setSearchText('');
    },
  }));

  return (
    data ? (
      <ReportBox>
        <Title>Список рабочих мест</Title>
        <Table
          rowKey="id"
          dataSource={data}
          columns={config}
          locale={locale.Table}
          bordered
          size="large"
        />
      </ReportBox>
    ) : (<div>Пусто</div>));
}

export default React.memo(WorkPlaceList);
