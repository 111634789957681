/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { Howl } from 'howler';
import { generateSoundList } from './audio-strings';
import parseNumber from './parseNumber';

class BoardProcessor {
  constructor(setSoundAction, setTicketsList, removeTickets = false, ignoreWpList = []) {
    this.currentTicket = {};
    this.ignoreWpList = ignoreWpList;
    this.ticketsHeap = [];
    this.ticketsList = [];
    this.inSoundAction = false;
    this.removeTickets = removeTickets;
    this.setTicketsList = setTicketsList;
    this.setSoundAction = setSoundAction;
  }

  processEvent = (eventData) => {
    const {
      event, id, number, wp_id, wp_fname: wplace, wp_soundtext: sound, zone_id: zone, wp_voice: voice,
    } = eventData;
    if (this.ignoreWpList.indexOf(wp_id) !== -1) return;
    const ticketObject = {
      id,
      number,
      wplace,
      sound: sound || 'startup klient TNUM k_oknu WNUM0',
      voice,
      zone,
      wNum: parseNumber(wplace),
    };
    switch (event) {
      case 'wСallProcTicket':
        if (!this.ticketsHeap.length && !this.inSoundAction) {
          this.ticketsList = this.filterQueue(this.ticketsList, ticketObject);
          this.setTicketsList(this.ticketsList);
          // update display queue if there are no tickets to play
          this.currentTicket = ticketObject;
          this.playSound();
        } else {
          if (this.currentTicket.id === ticketObject.id && this.isTicketInQueue(this.ticketsHeap, ticketObject)) return;
          this.ticketsHeap = this.filterQueue(this.ticketsHeap, ticketObject);
        }
        break;
      case 'qDeleteTicket':
      case 'qRefundTicket':
        if (this.removeTickets) {
          this.ticketsList = this.ticketsList.filter((each) => each.id !== id);
          this.setTicketsList(this.ticketsList);
          this.ticketsHeap = this.ticketsHeap.filter((each) => each.id !== id);
        }
        break;
      default:
    }
  };

  processHeap = () => {
    if (this.ticketsHeap.length && !this.inSoundAction) {
      const ticketObject = this.ticketsHeap.pop();
      this.currentTicket = ticketObject;
      this.ticketsList = this.filterQueue(this.ticketsList, ticketObject);
      this.setTicketsList(this.ticketsList);
      this.playSound();
    }
  };

  playSound = () => {
    const { sound } = this.currentTicket;
    const soundArray = generateSoundList(sound, this.currentTicket);
    // soundArray.unshift('/sounds/startupp.mp3'); // test for a wrong name of mp3 file
    this.makePlayer(soundArray, soundArray[0], 1);
  };

  makePlayer = (soundArray, currentSound, nextIndex) => {
    const currentIndex = nextIndex;
    this.inSoundAction = true;
    this.setSoundAction(true);
    axios({
      method: 'get',
      url: this.prepareSoundUrl(currentSound),
    })
      .then(() => {
        const player = new Howl({
          src: this.prepareSoundUrl(currentSound),
          autoplay: true,
          loop: false,
          volume: 1,
          onend: () => {
            player.unload();
            if (currentIndex === soundArray.length) {
              this.inSoundAction = false;
              this.setSoundAction(false);
              this.currentTicket = {};
              this.processHeap();
            } else {
              const nextIndexx = currentIndex + 1;
              this.makePlayer(soundArray, soundArray[nextIndex], nextIndexx);
            }
          },
        });
        player.play();
      })
      .catch(() => {
        console.error(`No sound ${currentSound}`);
        if (currentIndex === soundArray.length) {
          this.inSoundAction = false;
          this.setSoundAction(false);
          this.currentTicket = {};
          this.processHeap();
        } else {
          const nextIndexx = currentIndex + 1;
          this.makePlayer(soundArray, soundArray[nextIndex], nextIndexx);
        }
      });
  };

  prepareSoundUrl = (sound) => ((window.location.hostname === 'localhost') ? `https://demo.a-7.tech:3030${sound}` : sound);

  isTicketInQueue = (currentQueue, ticket) => (currentQueue.findIndex((each) => (each.id === ticket.id)) !== -1);

  filterQueue = (currentQueue, newTicket, length = 20) => {
    const filteredQueue = currentQueue.filter((each) => (each.id !== newTicket.id));
    return [].concat(newTicket, filteredQueue).slice(0, length);
    // Filter array for unique ticket. If repeat - set first. Limit only LENGTH elements.
  };
}

export default BoardProcessor;
