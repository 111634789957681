const initialState = {
  config: false,
};

// eslint-disable-next-line default-param-last
export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case 'SET_CONFIG':
      return { config: action.payload };
    default:
      return state;
  }
};
