import React from 'react';

import { Select } from 'antd';
import {
  FilterBoxTitle, FilterItemBox, FilterBox,
} from '../style';
// import MultiSelectNew from '../../../../../../components/multiselect/MultiSelectNew';

function QueueSelect({ changeFilter, filtersData }) {
  const MultiSelectItemList = (list) => {
    const ww = list.join(', ');
    if (ww === '') changeFilter('queue', 'all');
    else changeFilter('queue', ww);
  };

  return (
    <FilterBox Select>
      <FilterBoxTitle>Список услуг</FilterBoxTitle>
      <FilterItemBox>
        <Select
          mode="multiple"
          placeholder="Выберите услугу"
          className="multiselect"
          onChange={MultiSelectItemList}
          filterOption={
            (input, opt) => (opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            }
        >
          {filtersData.map((queue) => (
            <Select.Option
              key={queue.id}
              value={queue.id}
            >
              {queue.name}
            </Select.Option>
          ))}
        </Select>
      </FilterItemBox>
    </FilterBox>
    // <FilterBox Select>
    //   <FilterBoxTitle>Список услуг</FilterBoxTitle>
    //   <FilterItemBox>
    //     <MsFiltersItem>
    //       {dropdownConfig.items ? (
    //         <Dropdown
    //           config={dropdownConfig}
    //           clickHandler={changeOperetorFilter}
    //           replaceableTitle
    //         />
    //       ) : (null)}
    //     </MsFiltersItem>
    //   </FilterItemBox>
    // </FilterBox>
  );
}

export default QueueSelect;
