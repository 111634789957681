import React from 'react';

function Schedule({
  text, styles, setActiveEl, direction,
}) {
  const {
    fontSize3, color2, fontFamily2, textAlign,
  } = styles;
  const order = styles.order === 1 ? 1 : 0;
  const styleBorderColumn = {
    top: !order ? 'auto' : '0',
    bottom: order ? 'auto' : '0',
  };
  const styleBorderRow = {
    top: '50%',
    bottom: 'auto',
    right: order ? 'auto' : '0',
    left: !order ? 'auto' : '0',
    width: 1,
    height: '70%',
    transform: 'translateY(-50%)',
  };

  const aligns = {
    left: 'flext-start',
    right: 'flex-end',
  };

  return (
    <div className="layout__body__info__top__schedule" style={{ order, alignItems: aligns[textAlign] || 'center' }} onClick={() => setActiveEl('time')}>
      <div className="layout__body__info__top__schedule__border" style={direction ? styleBorderColumn : styleBorderRow} />
      <p style={{
        fontSize: fontSize3, color: color2, fontFamily: fontFamily2, margin: 0,
      }}
      >
        {text}
      </p>
    </div>
  );
}

export default Schedule;
