import React, { useEffect, useState } from 'react';
import ReportFilters from '../../../components/ReportFilters/ReportFilters';
import defaultFilters from '../../../components/ReportFilters/utils/defaultFilters';
import PrepareTable from '../../../components/PrepareTable';
import TableConfig from './TableConfig';

function PreEntryReport(props) {
  const { preloader } = props;
  const [queryParams, setQueryParams] = useState();

  const preparQueryParams = async (filters) => {
    const columns = TableConfig();
    const params = {
      columns: columns.columns,
      filters: filters || defaultFilters(),
      url: '/api/reports/build',
      type: 'preEntryReport',
    };
    setQueryParams(params);
  };

  useEffect(() => {
    preparQueryParams();
  }, []);

  return (queryParams ? (
    <>
      <ReportFilters
        showFilters={{
          main: ['reportRange', 'operators', 'queues'],
          others: ['tiketParamsFilters', 'servedTimeFilters'],
          orders: [],
        }}
        showPin
        showClientFio
        showClientPhone
        ShowClientMail
        showRegistred
        showPrior={false}
        paramsCallback={preparQueryParams}
        type="preEntryReport"
      />
      <PrepareTable
        keys="queue"
        type="QueueTable"
        {...queryParams}
        preloader={preloader}
      />
    </>
  ) : null);
}

export default PreEntryReport;
