import axios from 'axios';

export default function request(url, action = 'get', data = {}) {
  const body = { ...data };

  // Filter some unnecessary params
  delete body.created_by_id;
  delete body.created_by_username;
  delete body.created_on;
  delete body.modified_by_id;
  delete body.modified_by_username;
  delete body.modified_on;

  return new Promise(((resolve, reject) => {
    axios.post(`${url}${action !== 'no' ? `?action=${action}` : ''}`, body)
      .then((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
  }));
}
