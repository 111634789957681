import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { toast } from 'react-toastify';
import {
  Table, Button, Input, Space,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import 'antd/dist/antd.min.css';
import { EmptyList } from '../../../../common/ui/Common';
import Config from './TableConfig';
import Preloader from '../../../../pages/private/components/preloader/Preloader';
import DeleteWarning from '../../../../common/ui/Modal/DeleteWarning';
import request from '../../../../logic/utils/request';
import UserParams from './Parameters/UserParams';
import { useToggle } from '../../../../logic/hooks/useToggle';
import DeleteItem from '../../../../pages/private/utils/deleteItem';
import Filter from '../../Elements/Filter';

function UsersTable({
  userList,
  preloader,
  fetchUsers,
  setUserUpdate,
  setUserData,
  toggleOpen,
  showError,
  roles,
  write,
}) {
  const [showDelWarn, setShowDelWarn] = useToggle(false);
  const [params, setParams] = useToggle(false);
  const [userId, setUserId] = useState(false);
  const [userName, setUserName] = useState(false);
  const [open, setOpen] = useState(false);
  const [fieldList, setFieldList] = useState([]);
  const [usedDict, setUsedDict] = useState([]);
  const [defaultFilter, setDefaultFilter] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [valid, setValid] = useState(false);

  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const input = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex, searchItemText) => ({
    // eslint-disable-next-line react/no-unstable-nested-components
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={input}
          placeholder={`Найти ${searchItemText}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Очистить
          </Button>
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => input.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const fetchField = useCallback(async () => {
    const res = await request('/server/api/ticketsfields/', 'get');
    setFieldList([...res.data.data.filter((el) => el.type === 'text')]);
  }, []);

  const fetchFilters = async (id) => {
    const res = await request(
      '/server/api/usertxttfieldfilterattachment/',
      'get',
      {
        user_id: id,
      },
    );
    setDefaultFilter([...res.data.data]);
    return [...res.data.data];
  };

  useEffect(() => {
    fetchField();
  }, [fetchField]);

  const delUser = async () => {
    await DeleteItem({
      preloader,
      url: `/server/api/user/${userId}/`,
      fetchData: fetchUsers,
      setShowWarn: setShowDelWarn,
      setId: setUserId,
      showError,
    });
  };

  const handleTableIcon = async (e, row) => {
    const { dataset } = e.target;
    const {
      id,
      connectedRoles,
      hidden,
      blocked,
      fio,
      username,
      connectedQueues,
    } = row;
    if (dataset.name === 'del') {
      setUserId(id);
      setUserName(username);
      setShowDelWarn();
    } else if (dataset.name === 'update') {
      setUserUpdate(true);
      setUserData({
        id,
        connectedRoles,
        hidden,
        blocked,
        fio,
        username,
        connectedQueues,
      });
      toggleOpen();
    } else if (dataset.name === 'params') {
      setParams();
      setUserId(id);
    } else if (dataset.name === 'filter') {
      const filters = await fetchFilters(id);
      const obj = {};
      filters.forEach((el) => {
        if (obj[el.ticketfield_id]) {
          obj[el.ticketfield_id].push(el.textvalue);
        } else {
          obj[el.ticketfield_id] = [el.textvalue];
        }
      });
      const filtersA = Object.keys(obj).map((fid) => ({
        fid,
        fv: obj[fid],
      }));
      setDefaultFilter(filtersA);
      setUsedDict([...filtersA.map((el) => +el.fid)]);
      setFilterData({
        dictionary: {
          filtersA,
        },
      });
      setOpen(true);
      setUserId(id);
    }
  };

  const resetFilter = () => {
    setOpen();
    setUsedDict([]);
    setFilterData(null);
  };

  const SaveFilters = async () => {
    preloader();
    try {
      delete filterData.params;
      await request(`/server/api/wplace/${userId}`, 'update', filterData);
      fetchUsers();
      preloader();
      resetFilter();
      setValid(false);
    } catch (e) {
      preloader();
      showError(e.data.error);
    }
  };

  const changeFilterUser = async (dataSend, action, { fv, index }) => {
    try {
      await request(
        '/server/api/usertxttfieldfilterattachment/',
        action,
        { ...dataSend, user_id: userId },
      );
      const dictionary = {
        filtersA: [...filterData.dictionary.filtersA],
      };
      dictionary.filtersA[index] = {
        ...filterData.dictionary.filtersA[index],
        fv,
      };
      setFilterData({
        ...filterData,
        dictionary,
      });
    } catch (e) {
      toast.error(e.data.error.text.rus);
    }
  };

  const removeFilterUser = async (fid) => {
    const da = {
      fid,
    };
    await request('/server/api/usertxttfieldfilterattachment/', 'clean', da);
  };

  const Setting = Config(roles, write, getColumnSearchProps);
  const { columns } = Setting;

  const viewUsers = () => (userList.length ? (
    <Table
      rowKey="id"
      key="id"
      dataSource={userList}
      columns={columns}
      onRow={(row) => ({
        onClick: (e) => { handleTableIcon(e, row); },
      })}
    />
  ) : (
    <EmptyList> Пользователей нет </EmptyList>
  ));

  return (
    <>
      {(!userList || roles.length === 0) ? (<Preloader isOpen small />) : viewUsers()}
      <UserParams
        params={params}
        write={write}
        setParams={setParams}
        userId={userId}
        preloader={preloader}
      />
      <Filter
        open={open}
        user
        list={fieldList}
        dict={fieldList}
        setValid={setValid}
        valid={valid}
        defaultFilter={defaultFilter}
        toggle={() => setOpen(false)}
        data={filterData}
        changeFilterUser={changeFilterUser}
        removeFilterUser={removeFilterUser}
        reset={resetFilter}
        setData={setFilterData}
        setUsedDict={setUsedDict}
        save={SaveFilters}
        usedDict={usedDict}
      />
      <DeleteWarning
        name={userName}
        confirmDel={delUser}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </>
  );
}

export default React.memo(UsersTable);
