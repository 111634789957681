import React from 'react';
import RenderButtons from './renderButtons';
import BottomButtons from '../common/bottomButtons';
import Information from './information';
import TwoScreen from './twoScreen';
import HiddenBtn from './hiddenBtn';

function Home(props) {
  const {
    height, scene, style, startScreen, path, selectScreen,
  } = props;

  const { title, extraTitle, general } = style;
  const { twoScreen } = general;

  const multiScreenOrNone = scene.type === 'multiScreen' ? 2 : 0;

  const layout = scene.type === 'inputScreen' ? 1 : multiScreenOrNone;
  const info = scene.type === 'infoScreen';

  const b = { ...style.body, height };
  if (!b.backgroundImage) {
    delete b.backgroundImage;
  }

  const reg = startScreen ? !new RegExp(startScreen.split('/')[3]).test(path) : false;

  const isStartNotButtons = scene.type === 'startScreen' && scene.buttons.length === 0;

  const informationOrButtons = info
    ? <Information selectScreen={selectScreen} scene={scene} style={style} />
    : !isStartNotButtons && (
    <RenderButtons
      selectScreen={selectScreen}
      index={layout}
      {...props}
    />
    );

  return (
    <div className="layout__terminal" style={b}>
      <div style={title}>{scene.title}</div>
      <div style={extraTitle}>{scene.description}</div>
      <HiddenBtn />
      {twoScreen
        ? (
          <TwoScreen
            selectScreen={selectScreen}
            index={layout}
            {...props}
          />
        )
        : informationOrButtons}
      {!twoScreen && reg ? (
        <BottomButtons
          selectScreen={selectScreen}
          scene={scene}
          info={info}
          {...props}
        />
      ) : null}
    </div>
  );
}

export default Home;
