import React from 'react';
import FontSize from './modules/fontSize';

const fontSizes = ({ arr, dispatch, width }) => (
  arr.map((el) => (
    <FontSize
      element={el.parentName}
      width={width}
      value={el.value}
      dispatch={dispatch}
      rusName={el.title}
      paramName={el.paramName}
      key={el.title}
    />
  ))
);

export default fontSizes;
