import React from 'react';
import { FaPlus, FaDownload } from 'react-icons/fa';
import { Button, Space } from 'antd';

function Name({
  url, name, setData, size,
}) {
  const uploadFile = (event) => {
    const file = new FileReader();
    const { type } = event.target.files[0];
    file.onload = (e) => {
      const config = JSON.parse(e.target.result);
      setData({ ...config });
    };

    if (type === 'application/json') {
      file.readAsText(event.target.files[0]);
    }
  };

  return (
    <Space size={size || 'small'}>
      <Button type="primary" size="large">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <FaPlus />
          <span>Установить</span>
          <input type="file" style={{ display: 'none' }} onChange={uploadFile} />
        </label>
      </Button>
      <a href={url} download={name}>
        <Button type="primary" size="large">
          <FaDownload />
          <span>Скачать</span>
        </Button>
      </a>
    </Space>
  );
}

export default Name;
