import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './style.css';

function HiddenBtn() {
  const navigate = useNavigate();
  const allParams = useParams();
  const { id } = allParams;
  // const
  const [btns, setBtns] = useState({
    btn1: false,
    btn2: false,
    btn3: false,
  });
  const btnFn = (e) => {
    const curBtn = e.currentTarget.name;
    setBtns({
      ...btns,
      [curBtn]: true,
    });
  };
  useEffect(() => {
    const { btn1, btn2, btn3 } = btns;
    if (btn1 && btn2 && btn3) {
      navigate(`/terminal/${id}/service`);
    }
  }, [btns]);
  return (
    <>
      <button
        type="button"
        id="btn1"
        name="btn1"
        onClick={(e) => btnFn(e)}
      >
        1 кнопка
      </button>
      <button
        type="button"
        id="btn2"
        name="btn2"
        onClick={(e) => btnFn(e)}
      >
        2 кнопка
      </button>
      <button
        type="button"
        id="btn3"
        name="btn3"
        onClick={(e) => btnFn(e)}
      >
        3 кнопка
      </button>
    </>
  );
}

export default HiddenBtn;
