import { useEffect, useState } from 'react';
import getDropdownConfig from './DropdownConfig';
import { getWhere } from './utils';

export function useAllFilterDate({
  changeFilter, filld,
}) {
  const defaultFilterData = {
    comparator: 'none',
    value: '',
    from: '',
    before: '',
  };

  const [filterData, setFilterData] = useState(defaultFilterData);
  const [renderInput, setRenderInput] = useState('none');
  const DropdownConfig = getDropdownConfig();

  const changeFilterMark = (_, option) => {
    setFilterData(option.key === 'none' ? defaultFilterData : { ...filterData, comparator: option.value });
    setRenderInput(option.key);
  };

  const handleInput = (q, w) => {
    console.log('q === ', q);
    console.log('w === ', w);
    const value = q.format('DD.MM.YYYY HH:mm');

    if (w === 'rangeBefore') {
      setFilterData({ ...filterData, before: value });
    } else if (w === 'rangeFrom') {
      setFilterData({ ...filterData, from: value });
    } else {
      setFilterData({ ...filterData, value });
    }
  };

  useEffect(() => {
    console.log('filterData === ', filterData);
    console.log('filld === ', filld);

    changeFilter(filld, getWhere(filterData, filld));
  }, [filterData, changeFilter, filld]);

  const items = [
    { name: 'allCnt', type: 'noneDescription', value: filterData.value },
  ];

  const DuobleItems = [
    { name: 'rangeFrom', type: 'noneDescription', value: filterData.from },
    { name: 'rangeBefore', type: 'noneDescription', value: filterData.before },
  ];

  return {
    DropdownConfig,
    changeFilterMark,
    renderInput,
    DuobleItems,
    items,
    handleInput,
  };
}

export default null;
