import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    min-width: ${(p) => p.width || '150px'};
    max-width: ${(p) => p.width || '100%'};
    margin: ${(p) => p.m || 0};
    border-radius: 3px;
    text-align: ${(p) => p.align || 'left'};
    `;

export const SelectDiv = styled.div`
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    /* box-shadow: ${(p) => (p.active ? 'inset 0 1px 3px rgba(0,0,0,.1)' : '0 1px 3px rgba(0,0,0,.3)')}; */
    color: ${(p) => p.theme.lightText};
    background: ${(p) => (p.bg ? p.theme.bgTable : p.theme.bg)};
    border: 1px solid rgba(0,0,0,.1);
    width: 100%;
        white-space: nowrap;
    svg {
        margin-left: 15px;
        margin-top: 3px;
        fill: ${(p) => p.theme.accent};
        pointer-events: none;
    }
`;

export const SelectOptions = styled.div`
    max-height: ${(p) => p.maxHeight || '400px'};
    overflow-y: auto;
    position: absolute;
    border: 1px solid rgba(0,0,0,.1);
    z-index: 3;
    width: ${(p) => (p.w || '100%')};
    min-width: max-content;
    background: ${(p) => p.theme.bg};
    top: ${(p) => (p.bottom ? 'auto' : 0)};
    bottom: ${(p) => (p.bottom ? 0 : 'auto')};
    left: 0;
`;
export const Option = styled.div`
    cursor: pointer;
    transition: 0.1s ease-out;
    background-color: ${(p) => (p.bg ? p.theme.bgTable : p.theme.bg)};
    color: ${(p) => p.theme.text};
    border-bottom: 1px solid rgba(0,0,0,.1);
    &:last-child {
        border-bottom: none;
    }
    &:hover {
      background-color: ${(p) => p.theme.accent};
      border-color: transparent;
      color: ${(p) => p.theme.buttonHover}
    }
    width: max-content;
    min-width: 100%;
    padding: 10px 15px;
`;

export const CloseBlock = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 90%;
    height: 90%;
`;
