import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table } from 'antd';
import TableConfig from './TableConfig';
import ReportFilters from '../../../components/ReportFilters/ReportFilters';
import defaultFilters from '../../../components/ReportFilters/utils/defaultFilters';
import request from '../../../../../../../../logic/utils/request';

function SurveyReport() {
  const { columns: tableColumns } = TableConfig();

  const [surTableColumns, setSurTableColumns] = useState([...tableColumns]);
  const [tableData, setTableData] = useState([]);
  const [notTextFields, setNotTextFields] = useState([]);

  const fetchFields = async () => {
    const res = await request('/server/api/ticketsfields/', 'get');

    const fields = res.data.data;
    const newTableConfig = [...surTableColumns];
    const notTextF = [];
    fields.forEach((field) => {
      if (field.type !== 'text') notTextF.push(field.id);
      newTableConfig.push({
        title: field.rus_name,
        dataIndex: `fid-${field.id}`,
        width: 150,
      });
    });
    setNotTextFields(notTextF);
    setSurTableColumns(newTableConfig);
  };

  const fetch = async (filters) => {
    const token = window.localStorage.getItem('token');
    const res = await axios({
      method: 'post',
      url: '/api/reports/build',
      data: {
        token,
        filters: filters ? { ...filters } : { ...defaultFilters() },
        type: 'dictionaryReport',
      },
    });

    const dictionaryAnswers = await axios({
      method: 'post',
      url: '/api/reports/build',
      data: {
        token,
        filters: { ...defaultFilters() },
        type: 'dictionaryAnswersReport',
      },
    });

    const availableAnswers = dictionaryAnswers.data.answer;
    const answers = {};
    availableAnswers.forEach((ans) => {
      answers[Number(ans.id)] = ans.name;
    });

    const responseArr = res.data.answer;
    const resultArr = [];

    for (let i = 0; i < responseArr.length; i += 1) {
      if (
        responseArr[i].dictionary !== '[]'
        && responseArr[i].dictionary !== '[null]'
      ) {
        const dictObj = {};
        try {
          const dictVals = JSON.parse(responseArr[i].dictionary);
          dictVals.forEach((dv) => {
            dictObj[`fid-${dv.fid}`] = (notTextFields.includes(dv.fid)) ? dv.fv.map((fv) => answers[fv]).toString() : dv.fv.toString();
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
        resultArr.push({
          ...responseArr[i],
          ...dictObj,
        });
      }
    }
    setTableData(resultArr);
  };

  useEffect(() => {
    fetchFields();
  }, []);

  useEffect(() => {
    fetch();
  }, [notTextFields]);

  return (
    <>
      <ReportFilters
        showFilters={{
          main: ['reportRange', 'operators', 'queues'],
          others: [],
          orders: [],
        }}
        // paramsCallback={prepareQueryParams}
        paramsCallback={(filters) => {
          fetch(filters);
        }}
        type="dictionaryReport"
      />
      <Table
        dataSource={tableData}
        columns={surTableColumns}
        rowKey="ticketId"
        loading={!tableData}
        scroll={{ x: '100vw' }}
      />
    </>
  );
}

export default SurveyReport;
