import React, { useState } from 'react';
import { Input, Select } from 'antd';
import request from '../../../../../../../logic/utils/request';
import { useToggle } from '../../../../../../../logic/hooks/useToggle';

export const handjobData = [
  {
    label: 'Да',
    value: true,
  },
  {
    label: 'Нет',
    value: false,
  },
];

export function useTable({
  valuesList, fetchBook, setValuesList,
}) {
  const [showDelWarn, setShowDelWarn] = useToggle(false);
  const [valueId, setValueId] = useState(false);
  const [editCell, setEditCell] = useState();

  const delValue = async () => {
    await request(`/server/api/dictionaryvalue/${valueId}/`, 'delete');
    fetchBook();
    setShowDelWarn();
    setValueId(false);
  };

  const openModalToDeleteRow = (id) => {
    setValueId(id);
    setShowDelWarn();
  };

  const beforeSaveCell = async (row) => {
    try {
      await request(`/server/api/dictionaryvalue/${row.id}/`, 'update', row);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Ошибка обновления');
    }
  };

  const changeField = (id, fieldName, value) => {
    const row = valuesList.find((el) => el.id === id);
    beforeSaveCell(({ ...row, [fieldName]: value }));
    setValuesList(valuesList.map(
      (item) => (item.id === id ? ({ ...item, [fieldName]: value }) : item),
    ));
  };

  // eslint-disable-next-line
    const editableText = (fieldName) => (value, elem) => (
    editCell === `${fieldName}-${elem.id}`
      ? (
        <Input
          autoFocus
          defaultValue={value}
          onBlur={(e) => {
            changeField(elem.id, fieldName, e.target.value);
            setEditCell(null);
          }}
        />
      )
      : <span onClick={() => setEditCell(`${fieldName}-${elem.id}`)}>{value}</span>);

  // eslint-disable-next-line
    const editableSelect = (fieldName) => (value, elem) => (
    editCell === `${fieldName}-${elem.id}`
      ? (
        <Select
          style={{ width: 120 }}
          defaultValue={value}
          value={value}
          onChange={(val) => {
            changeField(elem.id, fieldName, val);
            setEditCell(null);
          }}
        >
          {handjobData.map((el) => (
            <Select.Option value={el.value}>{el.label}</Select.Option>
          ))}
        </Select>
      )
      : (
        <span onClick={() => setEditCell(`${fieldName}-${elem.id}`)}>
          {handjobData.find((item) => item.value === value).label}
        </span>
      )
  );

  return {
    editableText,
    editableSelect,
    delValue,
    showDelWarn,
    setShowDelWarn,
    openModalToDeleteRow,
  };
}
