import { withButtonsTamplate, DubleLineTemplate } from '../../../utils/formater';

export default function TableConfig(moreInfo = false) {
  return {
    columns: [
      {
        dataIndex: 'source',
        title: 'Источник',
        render: (cell, row) => (row.source === null || row.source === '' ? 'Не указан' : row.source),
      },
      {
        dataIndex: 'all_cnt',
        title: 'Обслужено',
        render: (cell, row) => withButtonsTamplate(row, moreInfo, 'WorkPlace', row.all_cnt, row.close_cnt, row.neyavka_cnt),
        sorter: (a, b) => a.all_cnt - b.all_cnt,
      },
      {
        dataIndex: '13',
        title: 'Длительность ожидания',
        render: (cell, row) => DubleLineTemplate('time', 'Общая:', row.total_wait_time, 'time', 'Средняя:', row.avg_wait_time),
      },
      {
        dataIndex: '12',
        title: 'Длительность обслуживания',
        render: (cell, row) => DubleLineTemplate('time', 'Общая:', row.total_proc_time, 'time', 'Средняя:', row.avg_proc_time),
      },
    ],
  };
}
