import {
  FaChartBar,
  FaUsers,
  FaSwatchbook,
  FaBuffer,
  FaChartLine,
  FaSitemap,
  FaGripHorizontal,
  FaCog,
  FaUserTie,
  FaUser,
  FaLaptop,
  FaWindowMaximize,
  FaMapSigns,
  FaTicketAlt,
  FaPalette,
  FaCloud,
  FaThumbsUp,
  FaCogs,
  FaQuestionCircle,
  FaList,
  FaTable,
  FaArchive,
  FaLink,
  FaTabletAlt,
} from 'react-icons/fa';

export default [{
  text: 'Статистика',
  link: '/monitoring/',
  icon: FaChartLine,
  key: '1',
  subItems: [{
    icon: FaChartBar,
    text: 'Мониторинг',
    link: '/monitoring/',
    key: '1-1',
  },
  {
    icon: FaThumbsUp,
    text: 'Оценочные опросы',
    link: '/qualityControl/',
    key: '1-2',
  },
  {
    icon: FaTable,
    text: 'Отчеты',
    link: '/reports/',
    key: '1-3',
  },
  {
    icon: FaArchive,
    text: 'Архив',
    link: '/archive/',
    key: '1-4',
  },
  ],
},
{
  text: 'Структура услуги',
  link: '/services/',
  icon: FaSitemap,
  key: '2',
  subItems: [{
    icon: FaUsers,
    text: 'Услуги',
    link: '/services/',
    key: '2-1',
  },
  {
    icon: FaSwatchbook,
    text: 'Список рабочих мест',
    link: '/workplaces/',
    key: '2-2',
  },
  {
    icon: FaBuffer,
    text: 'Дополнительные поля',
    link: '/fields/',
    key: '2-3',
  },
  {
    icon: FaBuffer,
    text: 'Группировки',
    link: '/groupings/',
    key: '2-4',
  },
  ],
},
{
  text: 'Элементы СУЭО',
  link: '/devices/',
  icon: FaGripHorizontal,
  key: '3',
  subItems: [{
    icon: FaLaptop,
    text: 'Внешние устройства',
    link: '/devices/',
    key: '3-1',
  },
  {
    icon: FaUser,
    text: 'Пользователи',
    link: '/users/',
    key: '3-2',
  },
  {
    icon: FaWindowMaximize,
    text: 'Табло оператора',
    link: '/digit-board/',
    key: '3-3',
  },
  {
    icon: FaMapSigns,
    text: 'Зоны обслуживания',
    link: '/handle-points/',
    key: '3-4',
  },
  //  {
  //    icon: FaMapSigns,
  //    text: 'Зоны обслуживания 222',
  //    link: '/zones/',
  //    key: '3-7',
  //  },
  {
    icon: FaTicketAlt,
    text: 'Конструктор талонов',
    link: '/tickets/',
    key: '3-5',
  },
  {
    icon: FaTabletAlt,
    text: 'Планшеты оценки качества',
    link: '/quality-tablet/',
    key: '3-6',
  },
  ],
},
{
  text: 'Настройки',
  link: '/custom/',
  icon: FaCog,
  key: '4',
  subItems: [{
    icon: FaPalette,
    text: 'Контент',
    link: '/custom/',
    key: '4-1',
  },
  {
    icon: FaCloud,
    text: 'Active Directory',
    link: '/sync/',
    key: '4-2',
  },
  {
    icon: FaCogs,
    text: 'Параметры сервера',
    link: '/params/',
    key: '4-3',
  },
  {
    icon: FaList,
    text: 'Лог действий',
    link: '/action-log/',
    key: '4-4',
  },
  {
    icon: FaList,
    text: 'Резервные копии',
    link: '/reserve/',
    key: '4-5',
  },
  {
    icon: FaLink,
    text: 'Сессии',
    link: '/session/',
    key: '4-6',
  },
  {
    icon: FaQuestionCircle,
    text: 'О системе',
    link: '/about/',
    key: '4-7',
  },
    // {
    //   icon: FaChess,
    //   text: 'Лотерея',
    //   link: '/lottery/',
    // },
  ],
},
{
  text: 'Оператор',
  link: '/operator/',
  icon: FaUserTie,
  subItems: [],
  key: '5',
},
];
