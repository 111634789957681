export default ({
  extraText, title, extraTitle, headerMain, headerSub,
}) => [
  {
    title: 'Заголовок в шапке', value: headerMain.textTransform, parentName: 'headerMain', paramName: 'textTransform',
  },
  {
    title: 'Подзаголовок в шапке', value: headerSub.textTransform, parentName: 'headerSub', paramName: 'textTransform',
  },
  {
    title: 'Заголовок', value: title.textTransform, parentName: 'title', paramName: 'textTransform',
  },
  {
    title: 'Подзаголовок', value: extraTitle.textTransform, parentName: 'extraTitle', paramName: 'textTransform',
  },
  {
    title: 'Дополнительный текст', value: extraText.textTransform, parentName: 'extraText', paramName: 'textTransform',
  },
];
