import React, { useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontsBox } from '../../../../../common/ui/Common';
import UploadFont from '../Upload';
import List from './list';

function Index({ showError, preloader }) {
  const [title, setTitle] = useState(undefined);
  const [fonts, setFonts] = useState([]);

  const getData = useCallback(async () => {
    preloader();
    try {
      const { data } = await axios({
        method: 'get',
        url: '/api/fonts',
      });
      setFonts(data);
      preloader();
    } catch (e) {
      preloader();
      if (e.data) showError(e.data.error);
    }
  }, [preloader, showError]);

  useEffect(() => {
    getData();
  }, [showError, getData]);

  const addFont = async (formData) => {
    preloader();
    try {
      await axios({
        method: 'post',
        url: '/api/fonts',
        data: formData,
      });
      toast.success('Шрифт добавлен');
      setTitle(undefined);
      window.location.reload();
    } catch ({ response }) {
      preloader();
      showError(response.data.error);
    }
  };

  const deleteFont = async (name) => {
    preloader();
    try {
      await axios({
        method: 'post',
        url: '/api/fonts/delete',
        headers: { 'content-type': 'application/json' },
        data: { name },
      });
      preloader();
      await getData();
      toast.success('Шриф удален');
    } catch ({ response }) {
      preloader();
      showError(response.data.error);
    }
  };

  const getFont = (name) => {
    window.open(`/api/fonts/download?name=${name}`);
  };

  return (
    <FontsBox>
      <UploadFont title={title} setTitle={setTitle} save={addFont} showError={showError} fileExtension={['ttf']} type="font" />
      <List fonts={fonts} deleteFont={deleteFont} getFont={getFont} />
    </FontsBox>
  );
}

export default Index;
