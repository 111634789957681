import React, { useEffect, useState } from 'react';
import { FaRedo, FaSave } from 'react-icons/fa';
import {
  FieldBox, Text, BtnReset, AbsoluteBox, BtnSave,
} from '../../../../../pages/private/subpages/queue/workplaces/Parameters/style';
import { Input } from '../../../../../common/ui/Common';
import SelectInput from '../../../../../pages/private/components/Input/SelectInput';

function Field({
  param, onSave, resetParam, write,
}) {
  if (param.id === 6) console.log('param === ', param);
  const [value, setValue] = useState(null);
  useEffect(() => {
    setValue(param.value);
  }, [param]);
  const reg = /\.|,/g;
  const regNumber = /\d/;
  const boolOptions = [
    { id: 1, name: 'Да' },
    { id: 0, name: 'Нет' },
  ];

  const renderFieldType = () => {
    const { param_type: paramType } = param;
    switch (paramType) {
      case 'boolean':
        return (
          <SelectInput
            disabled={!write}
            options={boolOptions}
            onChange={(e) => onSave(e.target.value, param.id, param.param_type)}
            value={Number(param.value)}
            noDefault
            name={param.value === '0' ? 'нет' : 'да'}
            multiple={false}
          />
        );
      case 'number':
        return (
          <Input
            disabled={!write}
            type="number"
            step="1"
            min="0"
            value={Number(value)}
            onKeyDown={(e) => (reg.test(e.key) || !regNumber.test(e.key))}
            onChange={(e) => setValue(Number(e.target.value))}
          />
        );
      default:
        return (
          <Input
            disabled={!write}
            type="text"
            value={value || ''}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) => setValue(e.target.value)}
          />
        );
    }
  };

  return (
    <FieldBox>
      <AbsoluteBox m={param.param_type === 'boolean'}>
        {write && param.reset && (
          <BtnReset onClick={() => resetParam(param.id)}><FaRedo /></BtnReset>
        )}
        {write && param.value !== value && (
          <BtnSave
            m={param.param_type === 'boolean'}
            onClick={() => onSave(value, param.id, param.param_type)}
          >
            <FaSave />
          </BtnSave>
        )}
      </AbsoluteBox>
      <Text>{param.description}</Text>
      {renderFieldType()}
    </FieldBox>
  );
}

export default Field;
