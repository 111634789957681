import moment from 'moment';
import IconFormatter from '../../../../../pages/private/utils/iconFormatter';

export default (users, write, searchPropsFactory) => [
  {
    dataIndex: 'user_id',
    title: 'Пользователь',
    ...searchPropsFactory('user_id', 'Пользователь'),
    render: (cell) => (users.some((el) => el.id === cell) ? users.find((el) => el.id === cell).username : ''),
  },
  {
    dataIndex: 'session',
    title: 'Сессия',
    ...searchPropsFactory('session', 'Сессия'),
  },
  {
    dataIndex: 'constant',
    title: 'Тип',
    ...searchPropsFactory('constant', 'Тип'),
    render: (cell) => (cell ? 'Постоянная' : 'Временная'),
  },
  {
    dataIndex: 'created_on',
    title: 'Дата создания',
    ...searchPropsFactory('created_on', 'Дата создания'),
    render: (cell) => moment(cell, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    dataIndex: 'Settings',
    title: '',
    width: 50,
    render: () => IconFormatter({
      filter: false, write, update: false, params: false,
    }),
  },
];
