import React from 'react';
import { FaCheck, FaDownload } from 'react-icons/fa';
import { BtnTable, IconBox } from '../../../common/ui/Common';
import { ReactComponent as Edit } from '../../../common/images/edit.svg';
import { ReactComponent as Settings } from '../../../common/images/settengs.svg';
import { ReactComponent as Update } from '../../../common/images/change.svg';
import { ReactComponent as Style } from '../../../common/images/style.svg';
import { ReactComponent as Trash } from '../../../common/images/bin.svg';
import { ReactComponent as Filter } from '../../../common/images/filter.svg';
import { ReactComponent as View } from '../../../common/images/view.svg';

export default function iconFormatter({
  filter, write, params, subscribe, styles, view, refresh, update = true, download = false,
}) {
  return (
    <IconBox>
      {update && <BtnTable data-name="update"><Edit /></BtnTable>}
      {download && <BtnTable data-name="download"><FaDownload size="14px" /></BtnTable>}
      {params && <BtnTable data-name="params"><Settings /></BtnTable>}
      {subscribe && <BtnTable data-name="subscribe"><FaCheck size="14px" /></BtnTable>}
      {filter && <BtnTable data-name="filter"><Filter /></BtnTable>}
      {view && <BtnTable data-name="view"><View /></BtnTable>}
      {refresh && <BtnTable data-name="refresh"><Update /></BtnTable>}
      {write && styles ? <BtnTable data-name="styles"><Style /></BtnTable> : null}
      {write && <BtnTable data-name="del" active><Trash /></BtnTable>}
    </IconBox>
  );
}
