import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import React from 'react';

export const getColumnSearchProps = ({
  input,
  handleSearch,
  handleReset,
  searchedColumn,
  searchText,
}) => (dataIndex, searchItemText) => ({
  filterDropdown: ({
    setSelectedKeys, selectedKeys, confirm, clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={input}
        placeholder={`Найти ${searchItemText}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Найти
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Очистить
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) => (record[dataIndex]
    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    : ''),
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => input.current.select(), 100);
    }
  },
  render: (text) => (searchedColumn === dataIndex ? (
    <Highlighter
      highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
    />
  ) : (
    text
  )),
});

export default null;
