import React, { useState, useCallback, useEffect } from 'react';
import request from '../../../../../../logic/utils/request';
import Input from '../../../../Elements/Input2/Input';
import RenderCheckList from './RenderCheckList';

function RenderInputs({
  questData, setQuestData, preloader, setIsValid,
}) {
  const [queue, setQueue] = useState([]);
  const [checkedQueue, setCheckedQueue] = useState([]);

  const fetchQueue = useCallback(async () => {
    preloader();
    const queueList = await request('/server/api/queue/', 'get');
    setQueue([...queueList.data.data]);
    preloader();
  }, [preloader]);

  useEffect(() => {
    fetchQueue();
  }, [fetchQueue]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    const validData = { ...questData, [name]: value };
    setQuestData({ ...validData });
    const { name: iV, displayname: pV, description: nV } = validData;
    if (iV && pV && nV) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const setValidCheckboxChange = (data) => {
    const { name: iV, displayname: pV, description: nV } = data;
    if (iV && pV && nV) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    setQuestData(data);
  };

  const {
    name, displayname, description, regulatoryTime,
  } = questData;
  const items = [
    {
      label: 'Наименование:', name: 'name', required: true, value: name || '',
    },
    {
      label: 'Вопрос:', name: 'displayname', required: true, value: displayname || '',
    },
    {
      label: 'Описание:', name: 'description', required: true, value: description || '',
    },
    {
      label: 'Регламентное время решения вопроса (сек):', name: 'regulatoryTime', value: regulatoryTime || '', type: 'number',
    },
  ];
  return (
    <>
      <div style={{ width: '386px' }}>
        {items.map((item) => (
          <Input
            width="100%"
            key={item.name}
            item={item}
            require={item.require}
            onChange={handleInput}
            type
          />
        ))}
      </div>
      <RenderCheckList
        setCheckedQueue={setCheckedQueue}
        checkedQueue={checkedQueue}
        setQuestData={setValidCheckboxChange}
        queue={queue}
        questData={questData}
      />
    </>

  );
}
export default RenderInputs;
