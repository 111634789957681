import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import List from './List';
import ContentItem from './ContentItem';

function MultiSelect({
  data, value, onSave,
}) {
  const [content, setContent] = useState([]);
  const [search, setSearch] = useState('');
  const [listData, setListData] = useState(data && data.length ? [...data] : []);
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [parent, setParent] = useState({ left: 0, top: 0 });
  const el = useRef(null);

  const addEl = (e) => {
    const { val } = e.target.dataset;
    let newVal;
    let arrIndex;
    let index;
    const parentPos = el.current.getBoundingClientRect();
    const childPos = e.target.getBoundingClientRect();
    const relativePos = {};
    relativePos.top = childPos.top - parentPos.top;
    relativePos.left = childPos.left - parentPos.left;
    setPosition(relativePos);
    setParent({ top: parentPos.top, left: parentPos.left });
    const newListData = listData.map((item, k) => item.map((it, i) => {
      if (it.value === val) {
        newVal = it;
        index = i;
        arrIndex = k;
      }
      return it;
    }));
    newListData[arrIndex].splice(index, 1); // delete element from array
    setListData(newListData);
    const newContent = [...content];
    newContent.push(newVal);
    setContent(newContent);
  };

  const delEl = useCallback((e) => {
    const { val } = e.currentTarget.dataset;
    let index;
    let arrIndex;
    let newVal;
    data.forEach((item, k) => {
      item.forEach((it, i) => {
        if (it.value === val) {
          index = i;
          arrIndex = k;
          newVal = it;
        }
      });
    });
    setListData((nowListData) => {
      nowListData[arrIndex].splice(index, 0, newVal);
      let newListData = nowListData;
      if (search) {
        newListData = nowListData.map((item) => item.filter((
          (it) => it
            .name
            .toLowerCase()
            .includes(search.toLowerCase())
        )));
      }
      return newListData;
    });
    setContent((nowContent) => nowContent.filter((item) => item.value !== val));
  }, [data, search]);

  useEffect(() => {
    if (search) {
      const newListData = data.map((item) => item.filter((
        (it) => it.name.toLowerCase().includes(search.toLowerCase())
      )));
      const filterList = newListData
        .map((item) => item.filter((it) => !content.find((ittem) => ittem.value === it.value)));
      setListData(filterList);
    } else if (data) {
      const filterList = data
        .map((item) => item.filter((it) => !content.find((ittem) => ittem.value === it.value)));
      setListData(filterList);
    }
  }, [search, data, content]);

  useEffect(() => {
    if (value) {
      const newValues = value.split(' ');
      const newContent = [];
      newValues.forEach((val) => {
        data.forEach((item) => {
          item.forEach((it) => {
            if (val === it.value) {
              newContent.push(it);
            }
          });
        });
      });
      const filterList = data.map((item) => item.filter((it) => {
        const itemm = newContent.find((ittem) => ittem.value === it.value);
        return !itemm;
      }));
      setListData(filterList);
      setContent(newContent);
    }
  }, [data, value]);

  return (
    <div ref={el} className="multiSelectBox">

      <form onSubmit={addEl}>
        <span>
          <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Поиск..." />
        </span>
      </form>
      <div className="multiSelectContentBox">
        <div className="box">
          {content.length > 0
            ? content.map((item) => (
              <ContentItem
                key={item.value}
                item={item}
                position={position}
                parent={parent}
                delEl={delEl}
              />
            ))
            : <p>Строка пуста. Начните набирать текст или выберите готовый из списка ниже.</p>}
        </div>
      </div>
      <List data={listData} addEl={addEl} />
      <button
        type="button"
        onClick={() => { onSave(content); }}
        className="save"
      >
        <span>Сохранить</span>
      </button>

    </div>
  );
}

export default MultiSelect;
