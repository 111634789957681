import React, { useState, useEffect, useCallback } from 'react';
import request from '../../../../../../logic/utils/request';
import IntervalsTable from './IntervalsTable';
import { useToggle } from '../../../../../../logic/hooks/useToggle';
import NewInterval from './NewInterval/NewInterval';

function Intervals({ preloader, showError, write }) {
  const initialData = {
    description: '',
    name: '',
    status: '1',
    timebegin: '1900.01.01 00:00',
    timeend: '1900.01.01 00:00',
  };
  const [intervalList, setIntevalList] = useState({ data: [], isFetching: false });
  const [intervalData, setIntervalData] = useState(initialData);
  const [intervalUpdate, setIntervalUpdate] = useState(false);
  const [isOpen, toggleOpen] = useToggle(false);

  const fetchIntervals = useCallback(async () => {
    setIntevalList({ data: [], isFetching: true });
    preloader();
    try {
      const res = await request('/server/api/dworkinterval/', 'get');
      preloader();
      setIntevalList({ data: res.data.data, isFetching: false });
    } catch (e) {
      setIntevalList({ data: [], isFetching: false });
      showError('Ошибка получения интервалов');
    }
  }, [showError, preloader]);

  useEffect(() => {
    fetchIntervals();
  }, [fetchIntervals]);

  return (
    <div>
      <NewInterval
        intervalData={intervalData}
        setIntervalData={setIntervalData}
        fetchIntervals={fetchIntervals}
        toggleOpen={toggleOpen}
        isOpen={isOpen}
        write={write}
        setIntervalUpdate={setIntervalUpdate}
        intervalUpdate={intervalUpdate}
        initialData={initialData}
        preloader={preloader}
      />
      <IntervalsTable
        intervalList={intervalList}
        showError={showError}
        fetchIntervals={fetchIntervals}
        toggleOpen={toggleOpen}
        write={write}
        preloader={preloader}
        setIntervalUpdate={setIntervalUpdate}
        setIntervalData={setIntervalData}
      />
    </div>
  );
}

export default Intervals;
