import React from 'react';

function Columns({
  styles,
  text,
  setActiveEl,
  ticketsList,
  edit,
}) {
  const {
    cols,
    columns,
    borderRadius,
    vertical,
    lines,
  } = styles;

  const {
    width,
    height,
    color1,
    color2,
    color3,
    color4,
    color5,
    color6,
    background1,
    background2,
    background3,
    background4,
    fontFamily1,
    fontFamily2,
    fontSize1,
    fontSize2,
    borderSize1,
    borderSize2,
  } = columns;

  const renderText = (value) => {
    if (+value === 0) {
      return '';
    }
    return value;
  };

  const renderColumns = () => {
    const colsArr = [];
    let stls = { border: `${borderSize1}px solid ${color5}` };
    let stlsBox = { background: '' };

    for (let i = 0; i < lines; i += 1) {
      const num = edit ? 'Ж 9999' : ticketsList[i]?.number || null;
      const empty = <span style={{ color: 'transparent' }}>none</span>;
      const win = edit ? ['1', '2', '3', '4'] : ticketsList[i]?.wNum || [empty, empty, empty, empty];
      if (i === 0) {
        stls = {
          ...stls,
          fontSize: `${fontSize2}px`,
          fontFamily: fontFamily2,
          color: color2,
        };
        stlsBox = {
          ...stlsBox,
          background: num ? background2 : background3,
        };
      } else if (i % 2 === 0) {
        stls = {
          ...stls, fontSize: `${fontSize2}px`, fontFamily: fontFamily2, color: color4,
        };
        stlsBox = {
          ...stlsBox, background: background4,
        };
      } else {
        stls = {
          ...stls, fontSize: `${fontSize2}px`, fontFamily: fontFamily2, color: color3,
        };
        stlsBox = {
          ...stlsBox, background: background3,
        };
      }
      colsArr.push(
        <div key={i} className="layout__body__columns__cell_box" style={stlsBox} onClick={() => setActiveEl('columns')}>
          <div className="layout__body__columns__cell" style={stls}>{num}</div>
          {cols >= 2 && <div className="layout__body__columns__cell" style={stls}>{renderText(win[0])}</div>}
          {cols > 2 && <div className="layout__body__columns__cell" style={stls}>{renderText(win[1])}</div>}
          {cols > 3 && <div className="layout__body__columns__cell" style={stls}>{renderText(win[2])}</div>}
        </div>,
      );
    }
    return colsArr;
  };

  const styleTitle = {
    fontSize: `${fontSize1}px`,
    background: background1,
    fontFamily: fontFamily1,
    color: color1,
    border: `${borderSize1}px solid ${color5}`,
  };

  return (
    <div
      className="layout__body__columns_box"
      style={{
        ...columns,
        borderRadius: borderRadius ? '12px' : '0',
        border: `${borderSize2}px solid ${color6}`,
        minWidth: (vertical || width === 'auto') ? '0' : width,
        maxWidth: (vertical || width === 'auto') ? '100%' : width,
        width: (vertical || width === 'auto') ? 'auto' : width,
        minHeight: (!vertical || height === 'auto') ? '0' : height,
        maxHeight: (!vertical || height === 'auto') ? '100%' : height,
        height: (!vertical || height === 'auto') ? 'auto' : height,
      }}
    >
      <div className="layout__body__columns__title" onClick={() => setActiveEl('columnsTitle')}>
        <div
          className="layout__body__columns__title__item"
          style={styleTitle}
        >
          {text.columns1}
        </div>
        {cols >= 2 && (
        <div
          className="layout__body__columns__title__item"
          style={styleTitle}
        >
          {text.columns2}
        </div>
        )}
        {cols > 2 && (
        <div
          className="layout__body__columns__title__item"
          style={styleTitle}
        >
          {text.columns3}
        </div>
        )}
        {cols > 3 && (
        <div
          className="layout__body__columns__title__item"
          style={styleTitle}
        >
          {text.columns4}
        </div>
        )}
      </div>
      {renderColumns()}
    </div>
  );
}

export default Columns;
