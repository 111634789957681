import React from 'react';
import SelectInput from './SelectInput/SelectInput';
import getLinks from '../../../../logic/utils/getLinks';
import { Label } from '../../../../common/ui/Common';

function InputField({
  item, onChange, left, children, m,
}) {
  const write = getLinks(true);
  const {
    value, type, name, options, label, readOnly, noDefault, className, required,
  } = item;

  const renderField = () => {
    switch (type) {
      case 'select':
        return (
          <SelectInput
            maxContent={item.maxContent}
            m={m}
            width={item.width}
            disabled={readOnly || !write}
            noDefault={noDefault}
            options={options}
            onChange={onChange}
            value={value}
            name={name}
            multiple={false}
          />
        );
      case 'checkbox':
        return (
          <p
            className="label"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <span style={{ order: left ? 1 : 0 }} className={children && 'common-settings-box__setting-box__label-icon settings__common__setting-box__label-icon'}>
              {label}
              {children && children}
            </span>
            <span className="check_box" onChange={(e) => write && onChange(e)} />
          </p>
        );
      default:
        return (
          <input
            className="input"
            autoComplete="new-password"
            type={type || 'text'}
            value={value}
            disabled={!write}
            min="0"
            maxLength={item.maxlength}
            onChange={(e) => {
              if (e.target.value.length > item.maxlength) return;
              onChange(e);
            }}
            name={name}
          />
        );
    }
  };

  return (
    <div className={`input_box ${className}`}>
      {type !== 'checkbox' && type !== 'noneDescription'
      && (
      <p className="label">
        <Label isRequired={required}>{label}</Label>
      </p>
      )}
      {renderField()}
    </div>
  );
}

export default InputField;
