import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import TerminalScreenButtons from './TerminalScreenButtons';
import { useScreenProviderContext } from '../use/useScreenProviderContext';

function TerminalScreen({ styleConfig }) {
  const {
    currentZone,
    getTicket,
    setShowPreloader,
    changeActiveScreen,
  } = useScreenProviderContext();

  const { config: { screens } } = currentZone;
  const startScreen = screens.find((el) => el.isStart);
  const [terminalScreen, setTerminalScreen] = useState(startScreen);
  const [pathMap, setPathMap] = useState([]);

  const { headTitle, title } = terminalScreen;

  const findScreen = (screenId) => screens.find((el) => el.screenId === screenId);

  const goHome = () => {
    setPathMap([]);
    setTerminalScreen(startScreen);
  };

  const goBack = () => {
    const newPathMap = [...pathMap];
    const backScreenId = newPathMap.pop();
    const screen = findScreen(backScreenId);
    setPathMap([...newPathMap]);
    setTerminalScreen(screen);
  };

  const goPath = async (path, params) => {
    if (path === 'print') {
      await getTicket(params);
    } else if (path === 'preEntry') {
      changeActiveScreen('preentry');
    } else {
      const screen = findScreen(path);
      setPathMap((currentPathMap) => [...currentPathMap, terminalScreen.screenId]);
      if (screen) {
        setTerminalScreen(screen);
      }
    }
  };

  useEffect(() => { setShowPreloader(false); }, []);

  return (
    <>
      <span className="websTeminalScreen__title">{headTitle}</span>
      <span className="websTeminalScreen__subTitle">{title}</span>
      <div className="websTeminalScreen__buttonsContainer">
        <TerminalScreenButtons styleConfig={styleConfig} screen={terminalScreen} goPath={goPath} />
      </div>
      {!terminalScreen.isStart && (
      <div className="websTeminalScreen__navigatinButtons">
        <Button type="primary" onClick={goHome}>
          На главную
        </Button>
        <Button type="primary" onClick={goBack}>
          Назад
        </Button>
      </div>
      )}
    </>
  );
}

export default TerminalScreen;
