import styled, { css, keyframes } from 'styled-components';
import { Button } from '../../common/ui/Common';

export const ButtonQueue = styled.button`
    background: linear-gradient(rgb(245, 94, 63), rgb(245, 94, 63));
    cursor: pointer;
    border: 2px solid rgb(245, 94, 63);
    color: rgb(255, 255, 255);
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-sizing: border-box;
    height: 40px;
    min-width: 400px;
    margin-bottom: 15px;
    &:focus {
      outline: none
    }
`;

export const QueueList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${(p) => p.theme.white};
  padding-top: 20px;
`;
function animationHover(color) {
  return keyframes`
    0% {
      box-shadow: 0 0 0 ${color};
    }
    100% {
      box-shadow: 0 0 0 3px ${color};
    }
  `;
}

export const ButtonAction = styled(Button)`
  margin-right: 20px;
  border-radius: 3px;
  background-color: ${(p) => p.bg || p.theme.green};
  color: ${(p) => p.color || p.theme.white};
  &:hover {
    animation: ${(p) => css`${animationHover(p.theme.bg)} 0.15s ease-out`};
  }
`;

export const TicketHeader = styled.h2`

`;

export const CurrentTicketInfo = styled.div`
  display: flex;
  padding: 20px 0;
`;

export const CurrentTicketData = styled.div`
  width: 70%;
`;

export const CurrentTicketTimers = styled.div`
  width: 30%;
  text-align: right;
`;

export const TimerLabel = styled.div`
  font-size: 20px;
`;

export const Timer = styled.div`
  font-size: 28px;
  font-weight: bold;
`;

export const ErrorMessage = styled.div`
  color: ${(p) => p.theme.accent};
`;
