import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { saveInLocalStorage } from '../utils';

export function useScreenProvider({ currentZone, satelliteId }) {
  //  Надо проверку, если есть активный талон в локалстораже,
  //  то не терминал, а борда, а если талон есть но он закрытый, то оценка качества
  const [currentScreen, setCurrentScreen] = useState('terminal');
  const [currentBoardStatus, setCurrentBoardStatus] = useState([]);
  const [showPreloader, setShowPreloader] = useState(true);

  const changeActiveScreen = (screen) => {
    setShowPreloader(false);
    setCurrentScreen(screen);
  };

  const getCurrentBoardStatus = () => {
    console.log(currentZone);
    return axios.post('/server/public/getLastCallTicketEvents', { data: { zoneId: currentZone.id } })
      .then(({ data: { events, error } }) => {
        if (error) return;
        setCurrentBoardStatus(
          events
            .map((el) => ({ tiketNumber: el.eqEvent.number, wplace: el.eqEvent.wp_fname }))
            .reverse(),
        );
        changeActiveScreen('board');
      });
  };

  const checkTicketStatus = async () => {
    await axios.post('/server/public/getDataForRestoreTicket', { idT: localStorage.getItem('currentTicketId') })
      .then(async ({ data: { info, body, error } }) => {
        if (error) return;
        const { status } = info;
        const {
          wpname, operatorfio, soundtext, position, placename,
        } = body;

        const placedesc = soundtext.split(' ')
          .filter((el) => el.includes('WNUM'))
          .map((el, index, arr) => ({ place: arr[index - 1], number: el }));

        saveInLocalStorage({
          placedesc: JSON.stringify(placedesc),
          wpname,
          operatorfio,
          position,
          placename,
        });
        if (status === 1 || status === 2) {
          changeActiveScreen('ticketProcess');
        } else if (status === 3) {
          changeActiveScreen('quality');
        } else if (status === 4 || status === 9) {
          changeActiveScreen('neyavka');
        } else {
          if (currentBoardStatus.length <= 0) await getCurrentBoardStatus();
          changeActiveScreen('board');
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem('currentTicketId')) {
      setShowPreloader(true);
      checkTicketStatus();
    }

    const intervalId = setInterval(() => {
      if (localStorage.getItem('currentTicketId')) checkTicketStatus();
    }, 5000); // check every 5 seconds whether a minute has passed since last sync
    return () => clearInterval(intervalId);
  }, [currentZone]);

  return {
    currentScreen,
    setShowPreloader,
    changeActiveScreen,
    currentBoardStatus,
    checkTicketStatus,
    showPreloader,
    currentZone,

    async getTicket(params, preentry = false) {
      setShowPreloader(true);

      const url = `/server/public/${preentry ? 'preorder/createTicketByPin' : 'createTicket'}`;

      const data = {
        zone_id: currentZone.id,
        satellite_id: satelliteId,
        q_id: !preentry ? params.value : null,
        pinCode: preentry ? params.pinCode : null,
      };

      axios.post(url, { data })
        .then(({ data: { ticket, ticketsBeforeYou, error } }) => {
          if (error) {
            toast.error(error.text.rus);
          } else {
            saveInLocalStorage({
              currentTicketId: ticket.id,
              currentTicketNumber: ticket.number,
              position: ticketsBeforeYou,
            });
            getCurrentBoardStatus();
          }
        })
        .finally(() => setShowPreloader(false));
    },
  };
}

export default null;
