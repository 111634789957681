export default ({ news }) => [
  {
    title: 'Текст', value: news.color1, paramName: 'color1', parentName: 'news',
  },
  {
    title: 'Рамка', value: news.color2, paramName: 'color2', parentName: 'news',
  },
  {
    title: 'Элементы<br />навигации', value: news.color3, paramName: 'color3', parentName: 'news',
  },
  {
    title: 'Фон', value: news.background, paramName: 'background', parentName: 'news',
  },
  {
    title: 'Цвет заголовка', value: news.color4, paramName: 'color4', parentName: 'news',
  },
];
