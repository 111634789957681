import React from 'react';
import { ConfigExportBox } from '../../../../../common/ui/Common';
import ImportExportConfig from '../../../../utils/importExportConfig';
import recurse from '../../../../utils/defaultConfigTerminal';
import defaultTerminal from '../DeviceStyle/TerminalStyle/defaultConfig';

const getUrl = (data) => (data ? URL.createObjectURL(new Blob(
  [
    JSON.stringify({
      ...data.config,
      style: data.config.style && recurse(defaultTerminal.style, data.config.style),
    }),
  ],
  { type: 'application/json' },
)) : null);

function Config({ data, setData, setIsValid }) {
  const uploadFile = (d) => {
    setData({ ...data, config: d });
    setIsValid(true);
  };

  return (
    <ConfigExportBox>
      <ImportExportConfig data={data} name={`${data.name}-${data.type}.json`} setData={uploadFile} url={getUrl(data)} />
    </ConfigExportBox>
  );
}

export default Config;
