import moment from 'moment';
import { List, Button } from 'antd';

const TableConfig = (getColumnSearchProps, handleDelete, openSurveyDrawer) => ({
  columns: [
    {
      dataIndex: 'id',
      title: 'ID анкеты',
      ...getColumnSearchProps('id', 'ID анкеты'),
      width: 120,
      sorter: (a, b) => (a.id - b.id),
    },
    {
      dataIndex: 'created_on',
      title: 'Дата добавления',
      width: 200,
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        const aToSec = new Date(moment(a.created_on, 'DD.MM.YYYY HH:mm:ss')).getTime();
        const bToSec = new Date(moment(b.created_on, 'DD.MM.YYYY HH:mm:ss')).getTime();
        return (aToSec - bToSec);
      },
      ...getColumnSearchProps('created_on', 'Дата добавления'),
    },
    {
      dataIndex: 'surveyname',
      title: 'Название анкеты',
      ...getColumnSearchProps('surveyname', 'Название анкеты'),
    },
    {
      dataIndex: 'connectedQuestions',
      title: 'Список прикрепленых вопросов',
      render: (_, row) => (
        <List
          size="small"
          dataSource={row.connectedQuestions}
          renderItem={(item) => (
            <List.Item>{item.questiontext}</List.Item>
          )}
        />
      ),
    },
    {
      dataIndex: '',
      title: 'Действия',
      width: 270,
      render: (_, row) => (
        <>
          <Button onClick={() => openSurveyDrawer(row)} size="large">Редактировать</Button>
          <Button
            onClick={() => handleDelete(row)}
            size="large"
            style={{ marginLeft: '8px' }}
            danger
          >
            Удалить
          </Button>
        </>
      ),
    },

  ],
});

export default TableConfig;
