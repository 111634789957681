import styled from 'styled-components';

export const ParamsBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldBox = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const Text = styled.p`
  margin-bottom: 10px;
  color: ${(p) => p.theme.text};
  font-size: 14px;
`;

export const BtnReset = styled.button`
  z-index: 4;
  cursor: pointer;
  background: ${(p) => p.theme.accent};
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 3px 3px 0;
  border: none;
  color: ${(p) => p.theme.text};
  svg path {
    fill: ${(p) => p.theme.buttonHover};
  }
  &:focus {
    outline: none;
  }
  right: 0;
  box-sizing: content-box;
  &:hover {
    box-shadow: ${(p) => `0 0 0 3px ${p.theme.accentl}`}
  }
`;

export const BtnSave = styled(BtnReset)`
  right: 42px;
  border-radius: 3px 0 0 3px;
  background: ${(p) => p.theme.green};
`;

export const AbsoluteBox = styled.div`
  position: absolute;
  bottom: ${(p) => (p.m ? 0 : '10px')};
  right: 0;
  width: max-content;
  display: flex;
`;
