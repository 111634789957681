import React from 'react';
import Accordion from '../components/Accordion';
import ReportRange from './ReportRange';
import WorkPlaceSelect from './WorkPlaceSelect';
import OperatorSelect from './OperatorSelect';
import QueueSelect from './QueueSelect';
import HistoryDateSelect from './HistoryDateSelect';
import IgnoreValue from './IgnoreValue';
import Dynamic from './Dynamic';
import Grouping from './Grouping';
import { ReportFilterItems } from '../style';

function MainFilters({
  changeFilter, showFilters, filtersData, groupingData, type,
}) {
  const filtersCmp = {
    reportRange: <ReportRange
      changeFilter={changeFilter}
      key={1}
      type={type}
    />,
    workplaces: <WorkPlaceSelect
      changeFilter={changeFilter}
      filtersData={filtersData.wplaces}
      key={2}
    />,
    operators: <OperatorSelect
      changeFilter={changeFilter}
      filtersData={filtersData.users}
      key={3}
    />,
    queues: <QueueSelect
      changeFilter={changeFilter}
      filtersData={filtersData.queues}
      key={4}
    />,
    historyDate: <HistoryDateSelect
      changeFilter={changeFilter}
      filtersData={filtersData.historyData}
      key={5}
    />,
    ignoreValue: <IgnoreValue
      changeFilter={changeFilter}
      filtersData={filtersData.historyData}
      key={6}
    />,
    dynamic: <Dynamic
      changeFilter={changeFilter}
      key={7}
    />,
    grouping: <Grouping
      changeFilter={changeFilter}
      filtersData={groupingData}
      key={8}
    />,
  };

  const renderFilterElements = () => (
    <ReportFilterItems>
      {showFilters.map((fitem) => (
        filtersCmp[fitem]
      ))}
    </ReportFilterItems>
  );

  return (
    <Accordion
      title="Основные фильтры"
      content={renderFilterElements()}
      letOpen
    />
  );
}

export default MainFilters;
