import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button, Typography,
} from 'antd';
import DataExportConfig from '../../../../../utils/DataExportConfig';

function ReportTitle(props) {
  const {
    ReportParams, name, type, pagination,
  } = props;
  const dataExportConfig = DataExportConfig(props);

  const openNotify = (typeNotify, message) => {
    if (typeNotify === 'success') {
      toast.success(`${message}`, 1000);
    } else {
      toast.error(`${message}`, 1000);
    }
  };

  const runDataExport = async (e) => {
    const token = window.localStorage.getItem('token');
    if (e === 'Excel') {
      let exportTableName;

      switch (ReportParams.TebleType) {
        case 'QuestionTable':
          exportTableName = 'questionTable';
          break;
        case 'RequestTable':
          exportTableName = 'requestReport';
          break;
        case 'DetailedReport':
          exportTableName = 'detailedReport';
          break;
        case 'ConsReport':
          exportTableName = 'consReport';
          break;
        default: break;
      }

      const exportTableParams = {
        type: exportTableName,
        token,
        sqlQuery: ReportParams.query,
        filters: {
          main: {
            startDate: pagination ? pagination.startDate : ReportParams.filters.main.startDate,
            endDate: pagination ? pagination.endDate : ReportParams.filters.main.endDate,
          },
        },
        items: ReportParams.items,
        TableConfig: ReportParams.TableConfig.columns,
        needToExport: true,
      };

      try {
        const answer = await axios({
          method: 'post',
          url: `/api/reports/?token=${token}`,
          data: exportTableParams,
        });
        const { statusText } = answer;
        if (statusText === 'OK') {
          const message = 'Отчет подготавливается для скачивания и появиться в разделе "Архив"';
          openNotify('success', message);
        }
      } catch (err) {
        openNotify('error', err);
      }
    } else if (e === 'pdf') {
      // eslint-disable-next-line no-alert
      alert('В работе');
    }
  };

  return (
    <>
      <Typography.Title level={2} style={{ textAlign: 'center', color: '#25547A' }}>
        {name}
      </Typography.Title>
      {(type === 'RequestTable' || type === 'DetailedReport')
      && (
      <div>
        <Button type="primary" size="large" onClick={() => runDataExport('Excel', dataExportConfig.option, '1', 'excel')}>
          Экспортировать
        </Button>
      </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return { reportConfig: state.reportConfig };
}

export default connect(mapStateToProps)(ReportTitle);
