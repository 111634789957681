import React, {
  useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Body, ErrorText, DontPrintTicketAlert,
} from '../../../../../../utils/style';
import Request from '../../../../request';
import { resetTicket, setPre } from '../../../../../../../store/actions';
import findError from '../../../../findError';
import getElements from './getElements';
import BottomButtons from '../../../../../common/bottomButtons';

function Canvas({
  style, data, config, dispatch, history, startScreen, errorUrl, needPrint,
}) {
  const {
    ticket,
  } = useSelector(
    (state) => state,
  );

  const { ticketInfo } = ticket;
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [finishDraw, setFinishDraw] = useState(false);
  const [isPrinted, setIsPrinted] = useState(false);
  const { number } = data.ticket;
  const styleTicket = JSON.parse(data.style);
  let firstTimeout;
  let printTimer = 15000;

  if (needPrint) printTimer = 15000;

  const printTicket = (printData) => {
    setIsPrinted(true);
    const { satelliteIp, satellitePort } = config;
    Request('/proxySatellite/gate/print', 'no', {
      ...printData,
      satelliteIp,
      satellitePort,
    })
      .then((res) => {
        if (!res.data.success) {
          setError('Ошибка печати. Проверьте принтер.');
        }
        localStorage.removeItem('emulatePreRecord');
        firstTimeout = setTimeout(() => {
          navigate(startScreen);
          dispatch(setPre({}));
          dispatch(resetTicket());
        }, 5000);
      })
      .catch((res) => {
        findError({
          res: res.response, history, dispatch, errorUrl, navigate,
        });
      });
  };

  const ref = useRef(null);

  useEffect(() => {
    const ctx = ref.current.getContext('2d');
    ref.current.width = 500;
    ref.current.height = 500;
    ctx.clearRect(0, 0, ctx.width, ctx.height);
    ctx.fillStyle = '#fff';
    ctx.rect(0, 0, 500, 500);
    ctx.fill();
    ctx.textBaseline = 'top';
    getElements({
      ctx, style: styleTicket, data, setFinishDraw, ref, number, ticketInfo,
    });
  }, [data, number, styleTicket]);

  let timeout;
  let printTimeout;

  useEffect(() => {
    if (finishDraw) {
      timeout = setTimeout(() => {
        const base64 = ref.current.toDataURL();
        const body = { data: base64.split(',')[1] };
        if (needPrint) printTicket(body);
        else {
          printTimeout = setTimeout(() => {
            navigate(startScreen);
            dispatch(resetTicket());
          }, printTimer);
        }
      }, 100);
    }
    return () => {
      clearTimeout(timeout);
      clearTimeout(firstTimeout);
      clearTimeout(printTimeout);
    };
  }, [finishDraw, ref.current]);

  return (
    <Body style={style.body}>
      {!needPrint
                && (
                <DontPrintTicketAlert>
                  <p>Запомните или сфотографируйте номер</p>
                </DontPrintTicketAlert>
                )}
      <canvas className="ticket" ref={ref} style={{ width: 500, height: 500, position: 'relative' }} />
      {error && <ErrorText>{error}</ErrorText>}
      {!needPrint
                && (
                <button
                  type="submit"
                  disabled={isPrinted}
                  style={{
                    ...style.button,
                    justifyContent: 'center',
                    display: 'flex',
                    marginTop: '20px',
                    width: '600px',
                  }}
                  className="print__button"
                  onClick={() => {
                    printTicket({ data: ref.current.toDataURL().split(',')[1] });
                  }}
                >
                  <p style={{ fontSize: '0.6em', lineHeight: '1.6em', textAlign: 'center' }}>
                    Если нужен бумажный талон
                    <br />
                    нажмите
                  </p>
                </button>
                )}
      <BottomButtons scene={{ help: false }} noBack />
    </Body>
  );
}

export default Canvas;
