import { toast } from 'react-toastify';
import request from '../../../logic/utils/request';

const DeleteItem = async ({
  preloader, url, showError, fetchData, setShowWarn, setId,
}) => {
  preloader();
  await request(url, 'delete')
    .then(() => {
      fetchData();
      setId(false);
      toast.success('Выполнено');
    })
    .catch((e) => {
      if (+e.data.error.code === 15) {
        toast.error('Перед удалением дополнительного поля удалите варианты ответа');
      } else {
        showError(e.data.error);
      }
    })
    .finally(() => {
      setShowWarn();
      preloader();
    });
};

export default DeleteItem;
