const initialState = {
  dictionary: [],
  fullname: '',
  email: '',
  phonenumber: '',
  q_id: null,
  pin: null,
};

export default initialState;
