export default ({ bottomButton }) => [
  {
    title: 'Назад', value: bottomButton.back.show, paramName: 'back.show', parentName: 'bottomButton',
  },
  {
    title: 'На главный экран', value: bottomButton.home.show, paramName: 'home.show', parentName: 'bottomButton',
  },
  {
    title: 'Информация', value: bottomButton.info.show, paramName: 'info.show', parentName: 'bottomButton',
  },
];
