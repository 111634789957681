import React from 'react';
import {
  BoardVideoBox, VideoBoxTitle, VideoRemoveButton, VideosBox, VideoList,
} from '../../../../styles/Custom';
import { ReactComponent as Trash } from '../../../../../common/images/bin.svg';
// import { ReactComponent as Check } from '../../../../images/chekbox.svg';

function List({ videos = [], deleteVideo }) {
  return (
    <VideoList>
      <p>Список загруженных изображений</p>
      <VideosBox>
        { videos.length !== 0 && videos.map((el) => (
          <BoardVideoBox key={el.id}>
            <img
              alt="video box"
              src={el.url}
              width="100%"
              height="auto"
              id="videoBox"
            />
            <VideoBoxTitle>
              <p>{el.name}</p>
              <VideoRemoveButton onClick={() => deleteVideo(el.id)} show>
                <Trash />
              </VideoRemoveButton>
            </VideoBoxTitle>
          </BoardVideoBox>
        ))}
      </VideosBox>
    </VideoList>
  );
}

export default List;
