import React from 'react';
import { Tabs } from 'antd';
import Intervals from './Intervals/Intervals';
import Days from './Days/Days';
import Weeks from './Weeks/Weeks';

function Schedule({
  preloader, showError, write,
}) {
  return (
    <Tabs>
      <Tabs.TabPane tab="Интервалы" key="1">
        <Intervals write={write} preloader={preloader} showError={showError} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Дни" key="2">
        <Days write={write} preloader={preloader} showError={showError} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Недели" key="3">
        <Weeks write={write} preloader={preloader} showError={showError} />
      </Tabs.TabPane>
    </Tabs>
  );
}

export default Schedule;
