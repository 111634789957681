import { withButtonsTamplate, DubleLineTemplate } from '../../../utils/formater';

export default function TableConfig(getColumnSearchProps) {
  return {
    columns: [
      {
        dataIndex: 'name',
        title: 'Название группы',
        ...getColumnSearchProps('name', 'название группы'),
      },
      {
        dataIndex: 'all_cnt',
        title: 'Обслужено',
        render: (cell, row) => withButtonsTamplate(row, 'WorkPlace', null, row.all_cnt, row.close_cnt, row.neyavka_cnt),
        sorter: (a, b) => a.all_cnt - b.all_cnt,
      },
      {
        dataIndex: '13',
        title: 'Длительность ожидания',
        render: (cell, row) => DubleLineTemplate('time', 'Общая:', row.total_wait_time, 'time', 'Средняя:', row.avg_wait_time),
      },
      {
        dataIndex: '12',
        title: 'Длительность обслуживания',
        render: (cell, row) => DubleLineTemplate('time', 'Общая:', row.total_proc_time, 'time', 'Средняя:', row.avg_proc_time),
      },
    ],
  };
}
