import React from 'react';

function List({ data, addEl }) {
  const renderList = () => data.map((item, i) => {
    const newList = item.map((it) => (
      <li
        key={it.value}
        onClick={addEl}
        className="listItem"
        data-val={it.value}
      >
        {it.name}
      </li>
    ));

    // eslint-disable-next-line react/no-array-index-key
    return <ul className="listBox" key={i}>{newList}</ul>;
  });

  return (
    <div className="multiListBox">{renderList()}</div>
  );
}

export default List;
