import { Select } from 'antd';
import React from 'react';

const font = ({
  value, dispatch, element, title, options, nameParams = 'fontFamily',
}) => {
  const big = nameParams.match(/\./);
  const onChange = (optValue) => {
    if (!big) {
      dispatch({
        type: 'params',
        name: element,
        param: nameParams,
        value: optValue || '',
      });
    } else {
      const arr = nameParams.split('.');
      dispatch({
        type: 'bigParams',
        parentName: element,
        name: arr[0],
        param: arr[1],
        value: optValue || '0',
      });
    }
  };
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <div>
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: title }} />
      <div>
        <Select
          onChange={onChange}
          value={(value === '0' || !value) ? null : value.trim()}
          style={{ width: '100%' }}
          filterOption={
            (input, opt) => (opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
          }
        >
          <Select.Option value={null}>По умолчанию</Select.Option>
          {options.map(({ id, name }) => <Select.Option key={id} value={id}>{name}</Select.Option>)}
        </Select>
      </div>
    </div>
  );
};

export default font;
