import React, { useState } from 'react';
import { Table } from 'antd';
import { EmptyList } from '../../../../../common/ui/Common';
import DeleteWarning from '../../../../../common/ui/Modal/DeleteWarning';
import Preloader from '../../../components/preloader/Preloader';
import { useToggle } from '../../../../../logic/hooks/useToggle';
import DeleteItem from '../../../utils/deleteItem';
import { TableConfig } from './TableConfig';
import { useSearchPropsFactory } from '../../../utils/useSearch';

function GroupingsTable({
  bunchList, preloader,
  fetchBunch, setFieldUpdate,
  setBunchData, toggleOpen,
  showError,
}) {
  const [showDelWarn, setShowDelWarn] = useToggle(false);
  const [fieldId, setFieldId] = useState(false);
  const [fieldName, setFieldName] = useState(false);
  const searchFactory = useSearchPropsFactory();

  const handleTableIcon = (e, row) => {
    const { dataset } = e.target;
    const {
      id, description, name, bunchType, bunchObjectsIds,
    } = row;

    if (dataset.name === 'del') {
      setFieldId(id);
      setFieldName(name);
      setShowDelWarn();
    } else if (dataset.name === 'update') {
      setFieldUpdate(true);
      setBunchData({
        id, description, name, bunchType, bunchObjectsIds,
      });
      toggleOpen();
    }
  };

  const delField = async () => {
    await DeleteItem({
      preloader, url: `/server/api/bunch/${fieldId}/`, fetchData: fetchBunch, setShowWarn: setShowDelWarn, setId: setFieldId, showError,
    });
  };

  const viewBunchesList = () => (bunchList.length
    ? (
      <Table
        columns={TableConfig(searchFactory)}
        dataSource={bunchList}
        onRow={(row) => ({
          onClick: (e) => { handleTableIcon(e, row); },
        })}
      />
    ) : <EmptyList>Полей нет</EmptyList>);

  return (
    <>
      {bunchList === null ? <Preloader isOpen small /> : viewBunchesList()}
      <DeleteWarning
        name={fieldName}
        confirmDel={delField}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </>
  );
}

export default React.memo(GroupingsTable);
