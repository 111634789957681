import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import moment from 'moment';
import Input from '../../../../../../../pages/private/components/Input/Input';
import Slider from '../../../../../Elements/Input/Slider';
import RenderCheckList from './RenderCheckList';

function RenderInputs({
  intervalData, setIntervalData, setIsValid, intervalUpdate, write,
}) {
  const {
    name, timebegin, timeend, description,
  } = intervalData;
  const [time, setTime] = useState({
    startHours: Number(moment(timebegin, 'YYYY.MM.DD HH:mm:ss').format('HH')) || 0,
    startMin: Number(moment(timebegin, 'YYYY.MM.DD HH:mm:ss').format('mm')) || 0,
    endHours: Number(moment(timeend, 'YYYY.MM.DD HH:mm:ss').format('HH')) || 0,
    endMin: Number(moment(timeend, 'YYYY.MM.DD HH:mm:ss').format('mm')) || 0,
  });

  useEffect(() => {
    if (intervalUpdate && name) {
      setIsValid(true);
    }
  }, [name, intervalUpdate, setIsValid]);

  const handleInput = (e) => {
    const { name: fieldName, value, type } = e.target;
    const validData = {
      ...intervalData,
      [fieldName]: type === 'checkbox' ? Number(value) + 1 : value,
    };

    setIntervalData({
      ...intervalData,
      [fieldName]: type === 'checkbox' ? Number(value) + 1 : value,
    });
    if (validData.name && validData.description) {
      setIsValid(true);
    }
  };

  const handleSlider = (value, intervalTime) => {
    const newTime = { ...time, [intervalTime]: Number(value) };
    setTime(newTime);
    const newTimebegin = `${(`0${newTime.startHours}`).slice(-2)}:${(`0${newTime.startMin}`).slice(-2)}`;
    const newTimeend = `${(`0${newTime.endHours}`).slice(-2)}:${(`0${newTime.endMin}`).slice(-2)}`;
    setIntervalData({ ...intervalData, timebegin: newTimebegin, timeend: newTimeend });
  };

  const items = [
    {
      label: 'Наименование:', name: 'name', value: name || '', required: true,
    },
    {
      label: 'Описание:', name: 'description', value: description || '', required: true,
    },
  ];

  const status = () => (intervalData.status === 2 ? 'Статус: Включен' : 'Статус: Выключен');

  return (
    <>
      {items.map((item) => (
        <Input
          key={item.name}
          item={item}
          onChange={handleInput}
          width="100%"
        />
      ))}
      {write ? (
        <RenderCheckList
          setData={setIntervalData}
          data={intervalData}
        />
      ) : status()}
      <div>
        Интервал:
        {write ? (
          <Tabs>
            <Tabs.TabPane tab={`Начало ${(`0${time.startHours}`).slice(-2)} : ${(`0${time.startMin}`).slice(-2)}`} key="start">
              <div style={{ width: '100%' }}>
                <Slider text="Часы" setData={handleSlider} max={23} value={time.startHours} name="startHours" />
                <Slider text="Минуты" minuts setData={handleSlider} max={55} step={5} value={time.startMin} name="startMin" />
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={`Конец ${(`0${time.endHours}`).slice(-2)} : ${(`0${time.endMin}`).slice(-2)}`} key="end">
              <div style={{ width: '100%' }}>
                <Slider text="Часы" setData={handleSlider} max={23} value={time.endHours} name="endHours" />
                <Slider text="Минуты" minuts setData={handleSlider} max={55} step={5} value={time.endMin} name="endMin" />
              </div>
            </Tabs.TabPane>
          </Tabs>
        ) : (
          <p className="dis-inline ml-1 mt-1">
            {time.startHours}
            :
            {time.startMin}
            {' '}
            -
            {' '}
            {time.endHours}
            :
            {time.endMin}
          </p>
        ) }
      </div>
    </>
  );
}
export default RenderInputs;
