import { textFilter } from 'react-bootstrap-table2-filter';
import moment from 'moment';
import { dateFormat, sortLabelFormatter } from '../../../../../utils/tableFormatters';
import iconFormatter from '../../../../../../pages/private/utils/iconFormatter';

export default function TableConfig(write, searchPropsFactory) {
  return {
    columns: [
      {
        dataIndex: 'name',
        title: 'Наименование',
        ...searchPropsFactory('name', 'наименованию'),
        filter: textFilter({
          className: 'customPlaceholder',
          placeholder: 'Наименование',
        }),
        headerFormatter: sortLabelFormatter,
      },
      {
        dataIndex: 'description',
        title: 'Описание',
        ...searchPropsFactory('description', 'описанию'),
      },
      {
        dataIndex: 'status',
        title: 'Статус',
        // ...searchPropsFactory('status', 'статусу'),
        sorter: (a, b) => a.status - b.status,
        render: (val) => (val === 1 ? 'Выключен' : 'Включен'),
      },
      {
        dataIndex: 'modified_by_username',
        title: 'Изменен',
        ...searchPropsFactory('modified_by_username', 'изменявшему'),
      },
      {
        dataIndex: 'modified_on',
        title: 'Когда изменен',
        ...searchPropsFactory('modified_on', 'дате/времени изменения'),
        render: (_, rec) => (
          <p>{moment(rec.modified_on).format('DD.MM.YYYY HH:mm:ss')}</p>
        ),
      },
      {
        dataIndex: 'timebegin',
        title: 'Начало',
        ...searchPropsFactory('timebegin', 'началу'),
        width: 100,
        render: (cell) => dateFormat(cell).split(' ')[1].split(':').splice(0, 2).join(':'),
      },
      {
        dataIndex: 'timeend',
        title: 'Конец',
        ...searchPropsFactory('timeend', 'Окончанию'),
        render: (cell) => dateFormat(cell).split(' ')[1].split(':').splice(0, 2).join(':'),
        width: 100,
      },
      {
        dataIndex: 'Settings',
        title: '',
        width: 100,
        render: () => iconFormatter({ filter: false, write, params: false }),
      },
    ],
  };
}
