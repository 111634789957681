import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Input, Row, Select, Space, Switch, InputNumber, Form,
} from 'antd';
import request from '../../../../../../logic/utils/request';

function RenderInputs({
  placeData, setPlaceData, preloader, setIsValid, zones,
}) {
  const [queue, setQueue] = useState([]);

  console.log('placeData === ', placeData);

  const fetchQueue = async () => {
    preloader();
    const queueList = await request('/server/api/queue/', 'get');
    setQueue([...queueList.data.data]);
    preloader();
  };

  useEffect(() => {
    fetchQueue();
  }, []);

  const handleInput = (e) => {
    console.log('handleInput === ', handleInput);
    const { name, value } = e.target;
    let validData;
    if (name === 'connectedZones') {
      if (value === '') {
        validData = { ...placeData, [name]: [] };
      } else {
        validData = { ...placeData, [name]: [Number(value)] };
      }
    } else {
      validData = { ...placeData, [name]: value };
    }
    const { name: iV, placename: pV, connectedZones: zV } = validData;
    setPlaceData({ ...validData });
    if (iV && pV && zV.length) {
      setIsValid(true, validData);
    } else {
      setIsValid(false, validData);
    }
  };

  const handleComplexPlace = (e) => {
    const { name, value } = e.target;
    const { placename: currentPlace } = placeData;
    const wnums = currentPlace.replace(/\D+/ig, ',').replace(/^,/, '').split(',');
    wnums[name.replace(/\D+/ig, '')] = value;

    const placenameData = wnums.filter(Boolean).join(',');
    const validData = { ...placeData, placename: placenameData };
    const { name: iV, placename: pV, connectedZones: zV } = validData;
    setPlaceData({ ...validData });
    setIsValid(iV && pV && zV.length, validData);
  };

  const zonesOptions = (
    zones && zones.map((item) => ({ id: item.id, name: item.name }))
  ) || [];

  const toggleAll = () => {
    const allQueuesConnected = [];
    queue.forEach((el) => {
      allQueuesConnected.push(el.id);
    });
    setPlaceData({ ...placeData, connectedQueues: allQueuesConnected });
    setIsValid(true, { ...placeData, connectedQueues: allQueuesConnected });
  };

  const unToggleAll = () => {
    setPlaceData({ ...placeData, connectedQueues: [] });
    setIsValid(false, { ...placeData, connectedQueues: [] });
  };

  const {
    connectedZones,
    description,
    disabled,
    name,
    placename,
  } = placeData;

  const wnums = placename.replace(/\D+/ig, ',').replace(/^,/, '').split(',');

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Input
        size="large"
        required
        value={name}
        name="name"
        addonBefore="Наименование:"
        onChange={handleInput}
      />
      <Space direction="vertical" size="small" style={{ width: '100%' }}>
        <InputNumber
          size="large"
          value={wnums[0]}
          type="number"
          name="wnum0"
          addonBefore="Параметр WNUM0:"
          onChange={(value) => {
            const e = {
              target: {
                name: 'wnum0',
                value,
              },
            };
            handleComplexPlace(e);
          }}
        />
        <InputNumber
          size="large"
          value={wnums[1]}
          type="number"
          name="wnum1"
          addonBefore="Параметр WNUM1:"
          onChange={(value) => {
            const e = {
              target: {
                name: 'wnum1',
                value,
              },
            };
            handleComplexPlace(e);
          }}
        />
        <InputNumber
          size="large"
          value={wnums[2]}
          type="number"
          name="wnum2"
          addonBefore="Параметр WNUM2:"
          onChange={(value) => {
            const e = {
              target: {
                name: 'wnum2',
                value,
              },
            };
            handleComplexPlace(e);
          }}
        />
      </Space>
      <Input
        size="large"
        value={description}
        name="description"
        addonBefore="Описание:"
        onChange={handleInput}
      />
      <Form.Item label="Подключенная зона">
        <Select
          size="large"
          style={{ width: '100%' }}
          value={connectedZones[0]}
          onChange={(value) => handleInput({ target: { value, name: 'connectedZones' } })}
        >
          {zonesOptions.map((item) => (
            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Space direction="horizontal">
        <Switch
          checked={!disabled}
          onChange={(value) => handleInput({
            target: {
              value: !value,
              name: 'disabled',
            },
          })}
        />
        {disabled ? 'Отключен' : 'Включен'}
      </Space>
      <Space direction="vertical" size="small" style={{ width: '100%' }}>
        <Row justify="space-between">
          <h3>Услуги:</h3>
          <Row>
            <Button onClick={toggleAll}>Включить все</Button>
            <Button style={{ marginLeft: '8px' }} onClick={unToggleAll}>Отключить все</Button>
          </Row>
        </Row>
        {queue.map((item) => (
          <Row style={{ width: '100%' }} key={item.id}>
            <Col span={22}>{item.name}</Col>
            <Col span={2}>
              <Switch
                checked={placeData.connectedQueues.includes(item.id)}
                onChange={(value) => {
                  const connectedQueues = value
                    ? [...placeData.connectedQueues, item.id]
                    : placeData.connectedQueues.filter((id) => id !== item.id);
                  setIsValid(true, { ...placeData, connectedQueues });
                  setPlaceData({ ...placeData, connectedQueues });
                }}
              />
            </Col>
          </Row>
        ))}
      </Space>
    </Space>
  );
}
export default RenderInputs;
