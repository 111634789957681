import React, { useContext } from 'react';
// components
import { Collapse } from 'antd'; // DOCS: https://ant.design/components/collapse/
import Colors from '../../../../../Components/Colors';
import Height from '../../../../../Components/Height';
import Font from './font';

// arr
import ArrColor from './components/color';
import ArrFontSize from './components/size';
import BoxShadow from '../../../../../Components/modules/BoxShadow';

import terminalCtx from '../../../../../../../../../../pages/public/terminal/context';

function Input() {
  const { dispatch, data } = useContext(terminalCtx);
  const { input, checkbox } = data;
  const arrColor = ArrColor({ input, checkbox });
  const arrFontSize = ArrFontSize({ input, checkbox });
  const { Panel } = Collapse;

  return (
    <Collapse className="customize-Collapse" ghost>
      <Panel header="Параметры текста" key="1">
        <Font checkbox={checkbox} input={input} dispatch={dispatch} />
      </Panel>

      <Panel header="Параметры элементов" key="2">
        <Height widthInput="160px" arr={arrFontSize} dispatch={dispatch}>
          <BoxShadow dispatch={dispatch} value={input.boxShadow} title="Тень</br>поля ввода" element="input" />
          <BoxShadow dispatch={dispatch} value={checkbox.boxShadow} title="Тень</br>окна выбора" element="checkbox" />
        </Height>
      </Panel>

      <Panel className="customize-Collapse-Colors" header="Цвет" key="3">
        <Colors arr={arrColor} dispatch={dispatch} />
      </Panel>
    </Collapse>
  );
}

export default Input;
