import React from 'react';
import { useSelector } from 'react-redux';
import Styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Body, BodyContainer } from '../../../utils/style';
import Request from '../request';
import { resetTicket } from '../../../../store/actions';
import getStyle from '../../../../utils/getStyle';
import findError from '../findError';

function RecoveryTicket({
  style, dispatch, match,
}) {
  const navigate = useNavigate();
  const { url } = match.params;
  const { errorUrl, id, headUrl } = useSelector((state) => state.config);
  const onClick = () => {
    Request('/server/public/getDataForRestoreTicket', { idT: url }, 'post')
      .then((res) => {
        findError({ res, navigate, errorUrl });
        dispatch(resetTicket(res.body));
        navigate(`${headUrl}/${id}/print`);
      });
  };

  const extraTitle = { ...style.extraTitle };
  const styleButton = getStyle(style.button, true);
  styleButton.marginTop = 50;
  extraTitle.marginBottom = 30;

  const Button = Styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  return (
    <BodyContainer style={style.body}>
      <Body>
        <div style={extraTitle}>
          Ваш талон закрыт или удален.
          <br />
          Возьмите пожалуйста новый.
        </div>
        <Button style={styleButton} onClick={onClick}>Восстановить</Button>
      </Body>
    </BodyContainer>
  );
}

export default RecoveryTicket;
