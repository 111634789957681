export default ({ button }) => [
  {
    title: 'Текст', data: button.text, parentName: 'button.text',
  },
  {
    title: 'Иконка', data: button.icon, parentName: 'button.icon',
  },
  {
    title: 'Кнопки', data: button, parentName: 'button',
  },
  {
    title: 'Кнопки печатать', data: button.bottom, parentName: 'button.bottom',
  },
];
