import { Store } from 'pullstate';
import moment from 'moment/moment';

const initState = {
  operator: 'all',
  workPlace: 'all',
  queue: 'all',
  close_cnt: 'all',
  groupinType: 'wp_id',
  bunch_id: 'all',
  all_cnt: 'all',
  neyavka_cnt: 'all',
  avg_wait_time: 'all',
  total_wait_time: 'all',
  avg_proc_time: 'all',
  total_proc_time: 'all',
  total_work_time: 'all',
  avg_work_time: 'all',
  mark1: 'all',
  mark2: 'all',
  mark3: 'all',
  mark4: 'all',
  mark5: 'all',
  markcnt: 'all',
  share_cnt: 'all',
  solved_questions: 'all',
  create_data: 'all',
  close_data: 'all',
  ticket_number: 'all',
  ticket_id: 'all',
  registered: 'all',
  status: 'all',
  prior: 'all',
  ticket_mark: 'all',
  dateType: 'day',
  totalproc: 'all',
  pincode: 'all',
  clientfio: 'all',
  clientmail: 'all',
  clientphone: 'all',
  dateTypeDescription: 'день',
  startDate: moment().startOf('month').format('DD.MM.YYYY HH:mm'),
  endDate: moment().endOf('day').format('DD.MM.YYYY HH:mm'),
  intervalReprotHistoryDate: 'all',
  orderArr: [],
  reportType: 'generalReport',
  reportTitle: 'По талонам',
};

const reportStore = new Store(initState);
export default reportStore;

export const updateReportStore = (values) => {
  console.log('values === ', values);
  const updatedValues = (s) => {
    Object.keys(values).forEach((key) => {
      s[key] = values[key];
    });
  };
  reportStore.update(updatedValues);
};

export const resetReportStore = () => {
  updateReportStore({ ...initState });
};

export const softResetReportStore = () => {
  const cState = reportStore.getRawState();
  const {
    startDate,
    endDate,
    reportTitle,
    reportType,
  } = cState;

  updateReportStore({
    startDate, endDate, reportTitle, reportType, ...initState,
  });
};

export const getReportRange = () => {
  const cState = reportStore.getRawState();
  const { startDate, endDate } = cState;
  return { startDate, endDate };
};

export const chnageReportTypeAndResetFilters = (values) => {
  const cState = reportStore.getRawState();
  const {
    startDate,
    endDate,
  } = cState;
  const jojoj = {
    ...initState, ...values, startDate, endDate,
  };
  console.log('jojoj === ', jojoj);
  updateReportStore({
    ...initState, ...values, startDate, endDate,
  });
};
