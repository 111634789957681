import React, {
  useState, useEffect, useReducer, useRef,
} from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  Button, Col, Row, Space, Typography,
} from 'antd';
import Icon from '@ant-design/icons';
import TerminalEditor from './TerminalEditor/TerminalEditor';
import defaultTerminal from './defaultConfig';
import TerminalCtx from '../../../../../../pages/public/terminal/context';
import { ReactComponent as BackArr } from '../../../../../../common/images/back.svg';
import recurse from '../../../../../utils/defaultConfigTerminal';
import TerminalPreview from '../../../../../../pages/public/terminal/Terminal';

function TerminalStyle(props) {
  const {
    deviceData, handleSave, id,
  } = props;
  const [fonts, setFonts] = useState([]);
  const [isMountReady, setIsMountReady] = useState(false);
  const { config } = deviceData;
  const [element, dispatchElement] = useReducer((state, action) => action.value, 'head');

  const conf = recurse(defaultTerminal.style, config.style);
  const navigate = useNavigate();

  const [data, dispatch] = useReducer((state, action) => {
    const {
      name, type, parentName, value, param, payload,
    } = action;
    switch (type) {
      case 'layout':
        return name === 'vertical'
          ? { ...state, head: { ...state.head, height: 1280, width: 1024 } }
          : { ...state, head: { ...state.head, height: 1080, width: 1920 } };
      case 'fonts':
        return { ...state, head: { ...state.head, fontFamily: value } };
      case 'delImage':
      case 'uploadImage':
        // eslint-disable-next-line no-case-declarations
        const arr = value.split('.');
        if (arr.length === 3) {
          const parent = arr[0];
          const extraParent = arr[1];
          const par = arr[2];
          return {
            ...state,
            [parent]: {
              ...state[parent],
              [extraParent]: {
                ...state[parent][extraParent],
                [par]: payload,
              },
            },
          };
        }
        return { ...state, [arr[0]]: { ...state[arr[0]], [arr[1]]: payload } };
      case 'uploadImage2':
        return {
          ...state,
          bottomButton: {
            ...state.bottomButton,
            [value]: {
              ...state.bottomButton[value],
              background: payload,
            },
          },
        };
      case 'text':
        return { ...state, [name]: { ...state[name], [param]: value } };
      case 'params':
        // eslint-disable-next-line no-case-declarations
        let newValue = !Number.isNaN(+value) ? +value : value;

        if (param === 'boxShadow' && !value.match(/px/)) {
          const arrShadow = state[name].boxShadow.split(' ');
          arrShadow.splice(3, 1, value);
          newValue = arrShadow.join(' ');
        }
        return { ...state, [name]: { ...state[name], [param]: newValue } };
      case 'bigParams':
        // eslint-disable-next-line no-case-declarations
        const newV = !Number.isNaN(+value) ? +value : value;
        return {
          ...state,
          [parentName]: {
            ...state[parentName],
            [name]: {
              ...state[parentName][name],
              [param]: newV,
            },
          },
        };
      case 'viewType':
        return { ...state, [name]: { ...state[name], [param]: value >= 4 ? 1 : value } };
      default:
        return state;
    }
  }, conf);

  const saveConfig = (e) => {
    e.preventDefault();
    const sendData = config || defaultTerminal;
    sendData.style = data;
    handleSave(sendData);
    toast.success('Выполнено');
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const ctx = {
    data,
    dispatch,
    element,
    dispatchElement,
    fonts,
    saveConfig,
  };

  const initStartParams = async () => {
    const fontsReq = await axios({
      method: 'get',
      url: '/api/fonts',
    });
    setFonts(fontsReq.data.map((el) => ({ id: el.name, name: el.name })));
    setIsMountReady(true);
  };

  useEffect(() => {
    initStartParams();
  }, []);

  const previewConfig = { ...deviceData, config: { ...deviceData.config, style: data } };

  const previewRef = useRef();
  const [scale, setScale] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      if (previewRef.current) {
        setScale(
          Math.min(
            previewRef.current.clientWidth / previewConfig.config.style.head.width,
            (window.innerHeight * 0.6) / previewConfig.config.style.head.height,
          ),
        );
      }
    }, 100);
  }, [previewRef.current, previewConfig.config.style.head.width, setScale]);

  return (isMountReady && (
  <TerminalCtx.Provider value={ctx}>
    <Space direction="vertical" size="middle">
      <Space direction="horizontal" size="small">
        <Button icon={<Icon component={BackArr} />} onClick={() => navigate('/devices/')}>
          Назад
        </Button>
        <Button
          type="primary"
          onClick={saveConfig}
        >
          Сохранить изменения
        </Button>
      </Space>
      <Typography.Title style={{ textAlign: 'center', color: '#25547A' }}>Стилизация регистратора</Typography.Title>
      <Row gutter={[20, 20]}>
        <Col span={8}>
          <TerminalEditor />
        </Col>
        <Col span={16}>
          <div
            ref={previewRef}
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <small>
              Масштаб 1x
              {(1 / scale).toFixed(2)}
            </small>
            <div style={{ transform: `scale(${scale})`, transformOrigin: 'left top' }}>
              {data.head !== undefined
            && <TerminalPreview id={id} config={previewConfig} />}
            </div>
          </div>
        </Col>
      </Row>
    </Space>
  </TerminalCtx.Provider>
  ));
}

export default React.memo(TerminalStyle);
