import React, {
  useState, useReducer, useEffect, useRef,
} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Button, Col, Row, Space, Typography,
} from 'antd';
import Icon from '@ant-design/icons';
import defaultConfig from './defaultConfig';
import BoardCtx from '../../../../../../pages/public/board/utils/BoardCtx';
import BoardPreview from './BoardPreview/BoardPreview';
import { ReactComponent as BackArr } from '../../../../../../common/images/back.svg';
import Editor from './BoardEditor/Editor';
import recureseBoardConfig from '../../../../../utils/recurseBoardConfig';

function BoardStyle({
  config, handleSave, showError,
}) {
  const [activeEl, setActiveEl] = useState('header');
  const [text, dispatchText] = useReducer((state, action) => ({
    ...state,
    [action.param]: action.value,
  }), config.text);
  const [fonts, setFonts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios({
      method: 'get',
      url: '/api/fonts',
    }).then((res) => setFonts(res.data.map((el) => ({ id: el.name, name: el.name }))));
  }, []);

  const newConfig = recureseBoardConfig(config, defaultConfig);

  const [layout, dispatch] = useReducer((state, action) => {
    const {
      name, param, type, value,
    } = action;
    switch (type) {
      case 'params':
        // eslint-disable-next-line no-case-declarations
        const newState = { ...state };
        if (param === 'vertical') newState.vid = false;
        if (name) {
          return {
            ...newState,
            [name]: {
              ...state[name],
              // eslint-disable-next-line no-restricted-globals
              [param]: !isNaN(value) ? +value : value,
            },
          };
        }
        return { ...newState, [param]: value };
      case 'fetchStyle':
        return { ...state, ...action.payload };
      case 'name':
        return { ...state, name: action.payload };
      case 'vertical':
        return { ...state, vertical: action.payload };
      case 'video':
        return { ...state, vid: action.payload };
      case 'clock':
        return { ...state, clock: action.payload };
      case 'borderRadius':
        return { ...state, borderRadius: action.payload };
      case 'cols':
        return { ...state, cols: action.payload };
      case 'font':
        return { ...state, font: action.payload };
      case 'element':
        return { ...state, [activeEl]: { ...state[activeEl], [action.value]: action.payload } };
      case 'uploadImage':
        return { ...state, images: { ...state.images, [action.value]: action.payload } };
      case 'delImage':
        return { ...state, images: { ...state.images, [action.value]: '' } };
      case 'background':
        return { ...state, background: action.payload };
      case 'delBackground':
        return { ...state, background: '' };
      default:
        return state;
    }
  }, { ...newConfig.layout });

  const handleConfig = () => {
    handleSave({ layout, text });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const ctx = {
    dispatch,
    layout,
    activeEl,
    setActiveEl,
    text,
    dispatchText,
    fonts,
    handleConfig,
    showError,
    elWidth: 720,
  };
  const previewRef = useRef();
  const [scale, setScale] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      if (previewRef.current) {
        setScale(
          Math.min(
            previewRef.current.clientWidth / layout.width,
            previewRef.current.clientHeight / layout.height,
          ),
        );
      }
    }, 100);
  }, [previewRef.current, layout.width, setScale]);

  return (
    <BoardCtx.Provider value={ctx}>
      <Space direction="vertical" size="small">
        <Space direction="horizontal" size="small">
          <Button icon={<Icon component={BackArr} />} onClick={() => navigate('/devices/')}>
            Назад
          </Button>
          <Button type="primary" onClick={handleConfig}>
            Сохранить изменения
          </Button>
        </Space>
        <Typography.Title style={{ textAlign: 'center', color: '#25547A' }}>Стилизация табло</Typography.Title>
        <Row gutter={[20, 20]}>
          <Col span={10}>
            <Editor />
          </Col>
          <Col span={14}>
            <div
              ref={previewRef}
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <small>
                Масштаб 1x
                {(1 / scale).toFixed(2)}
              </small>
              <div style={{ transform: `scale(${scale})`, transformOrigin: '0 0' }}>
                <BoardPreview />
              </div>
            </div>
          </Col>
        </Row>
      </Space>
    </BoardCtx.Provider>
  );
}

export default BoardStyle;
