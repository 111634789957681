export default function getStyle(elem, button = false, item) {
  const getPosition = (position) => {
    switch (position) {
      case 'absolute':
        return null;
      case 'top-right':
        return { top: 0, right: 0 };
      case 'bottom-right':
        return { bottom: 0, right: 0 };
      case 'bottom-left':
        return { bottom: 0, left: 0 };
      default:
        return { top: 0, left: 0 };
    }
  };
  const cloneElem = { ...elem };
  if (cloneElem.fontFamily) cloneElem.fontFamily = `'${cloneElem.fontFamily}'`;
  cloneElem.textTransform = cloneElem.textTransform && cloneElem.textTransform !== 'none'
    ? 'uppercase'
    : 'none';

  if (cloneElem.backgroundImage) {
    if (cloneElem.background) {
      cloneElem.backgroundImage = `${cloneElem.background}, url("${cloneElem.backgroundImage}")`;
      delete cloneElem.background;
    }
  }
  if (!cloneElem.backgroundImage) delete cloneElem.backgroundImage;
  let newElem = cloneElem;

  if (button) {
    if (item) {
      newElem.display = item.size === 'md' || item.size === 'sm' ? 'inline-flex' : 'flex';
      newElem.widthL = `${newElem.widthL}px`;
      newElem.widthS = `${newElem.widthS}px`;
      newElem.widthM = `${newElem.widthM}px`;
      newElem.heightS = `${newElem.heightS}px`;
      newElem.heightM = `${newElem.heightM}px`;
      newElem.heightL = `${newElem.heightL}px`;
      newElem.fontSizeL = `${newElem.fontSizeL}px`;
      newElem.fontSizeM = `${newElem.fontSizeM}px`;
      newElem.fontSizeS = `${newElem.fontSizeS}px`;
    }
  }
  if (newElem.position) {
    newElem = { ...newElem, ...getPosition(newElem.position) };
  }
  return newElem;
}
