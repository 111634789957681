import React from 'react';

function ChooseQueue(props) {
  const { queueList, chooseQueue } = props;
  return (

    <div style={{
      height: 'calc(100% - 30px)',
      overflow: 'auto',
      padding: '0 calc(50% - 400px)',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '20px',
      gridAutoRows: 'max-content',
    }}
    >
      {queueList.map((el) => (
        <div
          style={{ width: '100%' }}
          key={el.qId}
          className="item  terminal-prerecord__service"
          onClick={() => chooseQueue({ id: el.qId, name: el.qFullName })}
        >
          {el.qName}
        </div>
      ))}
    </div>
  );
}

export default ChooseQueue;
