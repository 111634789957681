import React, { useReducer } from 'react';
import moment from 'moment';
import md5 from 'md5';
import {
  Navigate,
  Route, Routes, useNavigate,
} from 'react-router-dom';
import 'moment/locale/ru';

import Queue from './scenes/Queue';
import Time from './scenes/Time';
import Month from './scenes/Month';
import Print from './scenes/Print';
import User from './scenes/User';

import reducer from './utils/reducer';
import request from './utils/request';

import Context from './utils/ctx';
import 'react-phone-input-2/lib/style.css';
import init from './utils/state';

moment.locale('ru');

function App() {
  const [state, dispatch] = useReducer(reducer, init);
  const navigate = useNavigate();

  const createTicket = async () => {
    const data = {
      calendarCellId: state.timeId,
      clientEmail: state.email,
      clientPhoneNumber: state.phone,
      clientFIO: state.user,
    };
    const ordered = {};
    Object.keys(data).sort().forEach((key) => {
      ordered[key] = data[key];
    });

    let hashString = '';
    Object.keys(ordered).forEach((el) => {
      hashString += data[el];
    });
    data.sign = md5(hashString);

    const answer = await request('/server/public/preorder/register', 'no', data);
    let d = 0;
    const m = state.syear[String(state.month).padStart(2, '0')];
    Object.keys(m).forEach((el) => {
      if (m[el].id === state.day) d = el;
    });
    const date = `${d}.${String(state.month).padStart(2, '0')}.${state.year} ${state.time}`;

    dispatch({
      type: 'add',
      value: {
        pin: answer.data.data.pinCode,
        date,
        service: state.queue.name,
      },
    });
    navigate('/preentry/print');
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const ctx = { ...state, dispatch, createTicket };

  return (
    <div className="queueBox">
      <Context.Provider value={ctx}>
        <Routes>
          <Route
            path="/"
            element={<Queue />}
          />
          <Route
            path="/day"
            element={<Month />}
          />
          <Route
            path="/time"
            element={<Time />}
          />
          <Route
            path="/user"
            element={<User />}
          />
          <Route
            path="/print"
            element={<Print />}
          />
          <Route path="*" element={<Navigate to="/preentry" />} />
        </Routes>
      </Context.Provider>
    </div>
  );
}

export default App;
