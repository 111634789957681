import React from 'react';

export const LoginContext = React.createContext({
  navigate: () => {},
  userLogin: () => {},
  setUserLogin: () => {},
  userPasswd: '',
  setUserPassword: '',
  office: [],
  setOffice: () => {},
  open: false,
  setOpen: () => {},
  alphaM: { current: null },
  terminals: { current: null },
  sueo: { current: null },
  animationForm: 0,
  setAnimationForm: () => {},
  isOpen: false,
  toggleOpen: () => {},
});

export default null;
