import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { HeaderLogo, HeaderLogoBox } from './HeaderStyles';
import logo from '../../../common/images/logo.png';
import navItemsList from './navItemsList';
import getLinks from '../../../logic/utils/getLinks';
import 'antd/dist/antd.css';

function SideNavigation() {
  const [selected, setSelected] = useState();
  const navigate = useNavigate();
  const arrLink = getLinks();
  const role = window.localStorage.getItem('role');

  const setClick = (option) => {
    setSelected(option.key);
  };

  return (
    role !== 'eq_controller' && (
    <Layout.Sider theme="light" width={250}>
      <HeaderLogoBox>
        <HeaderLogo onClick={() => { navigate('/monitoring/'); }} src={logo} />
      </HeaderLogoBox>
      <Menu mode="inline" theme="light" selectedKeys={[selected]}>
        {navItemsList
          .filter((item) => (arrLink.some((el) => el === item.link)))
          .map((item) => (item.subItems.length
            ? (
              <Menu.SubMenu
                key={item.key}
                title={(
                  <Link to={item.link}>
                    <Icon component={item.icon} />
                    <span className="nav-text">{item.text}</span>
                  </Link>
                    )}
                onTitleClick={() => {
                  setSelected(item.subItems[0].link);
                }}
              >
                {item.subItems.map((subItem) => (
                  <Menu.Item key={subItem.key} onClick={setClick}>
                    <Link to={subItem.link}>
                      <Icon component={subItem.icon} />
                      <span className="nav-text">{subItem.text}</span>
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            )
            : (
              <Menu.Item key={item.key} onClick={setClick}>
                <Link to={item.link}>
                  <Icon component={item.icon} />
                  <span className="nav-text">{item.text}</span>
                </Link>
              </Menu.Item>
            )))}
      </Menu>
    </Layout.Sider>
    )
  );
}

export default SideNavigation;
