import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 15px 35px;
  background: ${(p) => p.theme.bgTable};
  margin: 0 auto 30px;
  width: max-content;
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1);
  p {
    margin-bottom: 10px;
  }
  button {
    margin: 0 0 0 30px;
  }
  > div {
    display: flex;
  }
`;

export default Box;
