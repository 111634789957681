import React from 'react';
import {
  StarRaitingContainer,
  StarRatings,
  StarRatingsButton,
  StarRatingsTop,
} from '../../../../../components/report/ReportTableColumns';

export function markTemplate(markValue) {
  return (
    <StarRaitingContainer title="Оценка качества обслуживания">
      <StarRatings>
        <StarRatingsTop
          width={markValue}
        />
        <StarRatingsButton />
      </StarRatings>
    </StarRaitingContainer>
  );
}

export default null;
