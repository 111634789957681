export default ({
  clock, extraText, title, extraTitle, time,
}) => [
  {
    title: 'Заголовок', value: title.fontFamily, paramName: 'fontFamily', parentName: 'title',
  },
  {
    title: 'Подзаголовок', value: extraTitle.fontFamily, paramName: 'fontFamily', parentName: 'extraTitle',
  },
  {
    title: 'Дополнительный </br>текст', value: extraText.fontFamily, paramName: 'fontFamily', parentName: 'extraText',
  },
  {
    title: 'Дата', value: clock.fontFamily, paramName: 'fontFamily', parentName: 'clock',
  },
  {
    title: 'Время', value: time.fontFamily, paramName: 'fontFamily', parentName: 'time',
  },
];
