import React, { useState, useEffect } from 'react';
import { Btn, BtnsBox } from '../../../../../../../../common/ui/Common2';
import Modal from '../../../../../../../../common/ui/Modal/Modal';
import SqlQuery from './SqlQuery';
import getTableColumns from './TableConfig';
import ReportTable from './ReportTable';
import ShowText from './ShowText';
import { useToggle } from '../../../../../../../../logic/hooks/useToggle';
import SessionResultTable from '../expand/SessionResultTable';
import TableTitle from '../../DetailedReport/TableTitle';

const expandedRowRender = (record) => (
  <SessionResultTable sessionDataId={record.id} />
);

function RequestTable(props) {
  const { pagination } = props;
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [columnsFiltersList, setColumnsFiltersList] = useState({});
  const [modalData, setModalData] = useState({});
  const [isOpen, toggleOpen] = useToggle(false);
  const [modalWndtitle, setModalWndtitle] = useState('');
  const [downloadLink, setDownloadLink] = useState();
  const [downloadPhotoName, setDownloadPhotoName] = useState();

  const [query, setQuery] = useState();

  const fetchTableData = async () => {
    setLoading(true);
    const res = await SqlQuery(pagination.startDate, pagination.endDate);

    const uniqueSource = [];
    const uniqueOperetors = [];
    const sourceList = [];
    const operatorList = [];

    res.data.forEach((el) => {
      if (!uniqueSource.includes(el.source)) uniqueSource.push(el.source);
      if (!uniqueOperetors.includes(el.fio)) uniqueOperetors.push(el.fio);
    });

    uniqueSource.forEach((el) => {
      sourceList.push({ text: el, value: el });
    });

    uniqueOperetors.forEach((el) => {
      operatorList.push({ text: el, value: el });
    });
    setColumnsFiltersList({ operatorList, sourceList });
    setTableData(res.data);
    setQuery(res.queryExport);
    setLoading(false);
  };

  const handleTableIcon = (name, row) => {
    if (name === 'photo') {
      setModalData(
        <img style={{ width: '100%' }} src={row.photo_b64} alt="" />,
      );
      setModalWndtitle('Фотография посетителя');
      setDownloadLink(row.photo_b64);
      setDownloadPhotoName(`Photo-session${row.id}`);
      toggleOpen();
    } else if (name === 'micro') {
      setModalData(
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio style={{ width: '100%' }} controls>
          <source src={row.audiorecordpath} />
        </audio>,
      );
      setModalWndtitle('Аудио запись');
      setDownloadLink(row.audiorecordpath);
      toggleOpen();
    } else if (name === 'video') {
      console.log('row.videorecordpath === ', row.videorecordpath);
      setModalData(
        <video controls style={{ width: '100%' }}>
          <source src={row.videorecordpath} />
        </video>,
      );
      setModalWndtitle('Видео запись');
      setDownloadLink(row.videorecordpath);
      toggleOpen();
    } else if (name === 'comment') {
      setModalData(
        <ShowText item={row.textrecord} />,
      );
      setModalWndtitle('Комментарий посетителя');
      toggleOpen();
    }
  };

  const resetModal = () => {
    toggleOpen();
  };

  useEffect(() => {
    fetchTableData();
  }, [pagination]);

  const photoOfVisiter = modalWndtitle === 'Фотография посетителя'
    ? (
      <a href={downloadLink} download={downloadPhotoName}>
        <Btn valid margin="0 25px 0 0" bg="green">Сохранить</Btn>
      </a>
    )
    : (
      <a href={downloadLink} download>
        <Btn valid margin="0 25px 0 0" bg="green">Сохранить</Btn>
      </a>
    );

  return (
    <div>

      <Modal title={modalWndtitle} isOpen={isOpen} toggleOpen={resetModal}>
        {modalData}
        <BtnsBox>
          {modalWndtitle === 'Комментарий посетителя' ? null : photoOfVisiter}
          <Btn cancel onClick={() => toggleOpen()} bg="accent">Закрыть</Btn>
        </BtnsBox>
      </Modal>

      <TableTitle
        ReportParams={
            {
              items: tableData,
              query,
              TableConfig: {
                columns: getTableColumns(columnsFiltersList, handleTableIcon),
              },
              TebleType: 'RequestTable',
            }
        }
        name="Оценка качества"
        type="RequestTable"
        pagination={pagination}
      />

      <ReportTable
        rowKey="id"
        columns={getTableColumns(columnsFiltersList, handleTableIcon)}
        data={tableData}
        scroll={{ x: 2700 }}
        loading={loading}
        expandable={{
          expandedRowRender,
          rowExpandable: (record) => record.type === 'Опрос',
        }}
      />

    </div>
  );
}

export default RequestTable;
