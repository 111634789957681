import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Body, ButtonsNotFound } from '../../utils/style';

function Error({
  style, startScreen,
}) {
  console.log('startScreen === ', startScreen);
  const navigate = useNavigate();
  const timeout = useRef(null);
  const { errorText } = useSelector((state) => state.config);

  useEffect(() => {
    if (errorText !== 'Отсутствует соединение с сервером') {
      timeout.current = setTimeout(() => navigate(startScreen), 4000);
    }
    return () => clearTimeout(timeout.current);
  }, [errorText]);

  const st = { ...style.extraTitle };
  st.fontSize = 30;
  const b = { ...style.body };
  if (!b.backgroundImage) {
    delete b.backgroundImage;
  }
  return (
    <Body style={b}>
      <ButtonsNotFound>{errorText || 'Не задан стартовый экран'}</ButtonsNotFound>
    </Body>
  );
}

export default Error;
