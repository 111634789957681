import moment from 'moment';
import 'moment/locale/ru';

const dateFormat = 'DD.MM.YYYY HH:mm';

const initialState = {
  startDate: (moment().startOf('month')).format(dateFormat),
  endDate: (moment().endOf('day')).format(dateFormat),
  reportType: 'ticketReport', // '',
};

// eslint-disable-next-line default-param-last
export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case 'SET_REPORTS_CONFIG':
      return { ...action.payload };
    default:
      return state;
  }
};
