import React, { useContext } from 'react';
import { Collapse } from 'antd'; // DOCS: https://ant.design/components/collapse/
import BoardCtx from '../../../../../../../../../pages/public/board/utils/BoardCtx';
import FontFamily from '../../../../Components/modules/font';
import Height from '../../../../Components/modules/height';
import Text from '../../../../Components/Text';
import Colors from '../../../../Components/Colors';
import FontSize from '../../../../Components/FontSize';
import colors from './colors';
import texts from './texts';
import fontSize from './fontSize';
import MarginComponent from '../../../../Components/modules/Margin';

function Columns() {
  const {
    dispatch, layout, fonts, text, dispatchText,
  } = useContext(BoardCtx);

  const { Panel } = Collapse;
  const { cols, columns, vertical } = layout;
  const arrFontSize = fontSize({ columns });
  const arrColors = colors({ columns });
  const arrText = texts({ cols, text });

  const onRefresh = (param) => dispatch({
    type: 'params', name: 'columns', param, value: 'auto',
  });

  return (
    <Collapse className="customize-Collapse" ghost>
      <Panel className="customize-Collapse-Header-No-Top-Padding" header="Текст" key="1">
        <Text arr={arrText} dispatch={dispatchText} />
      </Panel>

      <Panel className="customize-Collapse-Header-No-Top-Padding" header="Параметры текста" key="2">
        <Collapse className="customize-Collapse" ghost>
          <Panel header="Размер текста" key="1">
            <FontSize arr={arrFontSize} dispatch={dispatch} />
          </Panel>

          <Panel header="Шрифт" key="2">
            <FontFamily options={fonts} title="Заголовок" element="columns" nameParams="fontFamily1" value={String(columns.fontFamily1)} dispatch={dispatch} />
            <FontFamily options={fonts} title="Текст в ячейке" element="columns" nameParams="fontFamily2" value={String(columns.fontFamily2)} dispatch={dispatch} />
          </Panel>
        </Collapse>
      </Panel>

      <Panel header="Параметры элементов" key="3">
        {!vertical && <Height refresh={() => onRefresh('width')} clear title="Ширина всех колонок" width="160px" element="columns" nameParams="width" value={columns.width} dispatch={dispatch} />}
        {vertical && <Height refresh={() => onRefresh('height')} clear title="Высота колонок" width="160px" element="columns" nameParams="height" value={columns.height} dispatch={dispatch} />}
        <Height title="Рамка ячейки" width="160px" element="columns" nameParams="borderSize1" value={columns.borderSize1} dispatch={dispatch} />
        <Height title="Рамка всего блока" width="160px" element="columns" nameParams="borderSize2" value={columns.borderSize2} dispatch={dispatch} />
        <MarginComponent data={columns} dispatch={dispatch} element="columns" name="margin" title="Отступы" />
      </Panel>

      <Panel className="customize-Collapse-Colors" header="Цвет" key="4">
        <Colors arr={arrColors} dispatch={dispatch} />
      </Panel>

    </Collapse>
  );
}

export default Columns;
