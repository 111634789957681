import React from 'react';

export function DubleLineTemplate2(value) {
  const style1 = {
    display: 'flex', justifycontent: 'space-between', height: '41px', alignItems: 'center',
  };
  const style2 = {
    display: 'flex', justifycontent: 'space-between', height: '41px', alignItems: 'center', marginTop: '5px',
  };
  const renderRows = () => (
    value.map((el, i) => (
      <div key={el.name} style={i === 0 ? (style1) : (style2)}>
        <div style={{ display: 'flex', width: '50%' }}>
          <div style={{ flex: '1' }}>{el.name}</div>
          <div style={{ width: '50px', fontWeight: '600' }}>{el.value}</div>
        </div>
        <div style={{ display: 'flex', width: '50%' }}>
          <div style={{ flex: '1' }}>{el.name2}</div>
          <div style={{ width: '50px', fontWeight: '600' }}>
            {el.value2}
            %
          </div>
        </div>
      </div>
    ))
  );

  return (<div>{renderRows()}</div>);
}

export default null;
