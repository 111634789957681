import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'antd';
import {
  FilterItemBox, FilterBox, FilterBoxTitle,
} from '../style';
// import MultiSelectNew from '../../../../../../components/multiselect/MultiSelectNew';
// import Dropdown from '../../../../../../components/dropdown/Dropdown';

const { SHOW_PARENT } = TreeSelect;

function Grouping({ changeFilter, filtersData }) {
  // const [dropdownConfig, setDropdownConfig] = useState({
  //   headerTitleText: 'По рабочим местам',
  //   options: {},
  // });

  // const getDefaultMsData = () => {
  //   const MsList = [];
  //   filtersData.forEach((el) => { if (el.bunchType === ('wp_id')) { MsList.push(el); } });
  //   setMsData(MsList);
  // };

  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    const data = [{
      title: 'По рабочим местам',
      value: 'wp_id',
      key: 'wp_id',
      children: [],
    },
    {
      title: 'По очередям',
      value: 'q_id',
      key: 'q_id',
      children: [],
    },
    {
      title: 'По операторам',
      value: 'modified_by_id',
      key: 'modified_by_id',
      children: [],
    }];
    filtersData.forEach((el) => {
      data.forEach((treeEl) => {
        if (el.bunchType === treeEl.value) {
          treeEl.children.push({
            title: el.name,
            value: el.id,
            key: el.id,
          });
        }
      });
    });

    data.forEach((el) => {
      if (el.children.length === 0) {
        el.disableCheckbox = true;
      }
    });

    setTreeData(data);

    // const items = [
    //   { key: 'wp_id', chackName: 'По рабочим местам', text: 'По рабочим местам' },
    //   { key: 'q_id', chackName: 'По очередям', text: 'По очередям' },
    //   { key: 'modified_by_id', chackName: 'По операторам', text: 'По операторам' },
    // ];
    // setDropdownConfig({ ...dropdownConfig, items });
    // getDefaultMsData();
  }, [filtersData]);

  const handleChange = (value, _, option) => {
    const groupList = [];
    if (option.checked) {
      option.allCheckedNodes.forEach((el) => {
        if (el.node.props.children.length > 0) {
          el.node.props.children.forEach((node) => {
            groupList.push(node.props.value);
          });
        } else {
          groupList.push(el.node.props.value);
        }
      });
    } else {
      option.allCheckedNodes.forEach((el) => {
        if (el.props.children.length > 0) {
          el.props.children.forEach((node) => {
            groupList.push(node.props.value);
          });
        } else {
          groupList.push(el.props.value);
        }
      });
    }
    const ww = groupList.join(', ');
    if (ww === '') changeFilter('bunch_id', 'all');
    else changeFilter('bunch_id', ww);
  };

  const tProps = {
    treeData,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Выберите группировку',
    style: {
      width: '100%',
    },
    onChange: handleChange,
  };

  return (
    <>
      <FilterBox Select>
        {/* <FilterBoxTitle>Тип группировки</FilterBoxTitle> */}
        {/* <FilterItemBox> */}
        {/*  /!* <MsFiltersItem> *!/ */}
        {/*  /!*  {dropdownConfig.items ? ( *!/ */}
        {/*  /!*    <Dropdown *!/ */}
        {/*  /!*      config={dropdownConfig} *!/ */}
        {/*  /!*      clickHandler={changeOperetorFilter} *!/ */}
        {/*  /!*      replaceableTitle *!/ */}
        {/*  /!*    /> *!/ */}
        {/*  /!*  ) : null} *!/ */}
        {/*  /!* </MsFiltersItem> *!/ */}
        {/*  <Select */}
        {/*    className="select" */}
        {/*    onChange={changeOperetorFilter} */}
        {/*  > */}
        {/*    {dropdownConfig.items && dropdownConfig.items.map((item) => ( */}
        {/*      <Select.Option key={item.key}>{item.text}</Select.Option> */}
        {/*    ))} */}
        {/*  </Select> */}
        {/* </FilterItemBox> */}
        <FilterBoxTitle>Группа</FilterBoxTitle>
        <FilterItemBox>
          <TreeSelect {...tProps} />
        </FilterItemBox>
      </FilterBox>
      {/* <FilterBox Select> */}
      {/*  <FilterBoxTitle>Группа</FilterBoxTitle> */}
      {/*  <FilterItemBox> */}
      {/*    <MsFiltersItem> */}
      {/*      <MultiSelectNew */}
      {/*        data={msData} */}
      {/*        selectedList={MultiSelectItemList} */}
      {/*        selectValuse="chackName" */}
      {/*        keys="id" */}
      {/*      /> */}
      {/*    </MsFiltersItem> */}
      {/*  </FilterItemBox> */}
      {/* </FilterBox> */}
    </>
  );
}

export default Grouping;
