import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import findPosition from '../../pages/private/components/Input/findPosition';

function ColorPicker({ color, handleColor, name }) {
  const [isOpen, setIsOpen] = useState(false);
  const [bottom, setBottom] = useState(false);
  const [bgColor, setBgColor] = useState(color);

  const popover = (isBottom) => ({
    position: 'absolute',
    zIndex: '2',
    top: isBottom ? 'auto' : 0,
    bottom: isBottom ? 0 : 'auto',
  });

  return (
    <div className="box__color__picker">
      <div
        className="box__color__picker__btn"
        color={color}
        onClick={(e) => {
          e.preventDefault();
          findPosition(e.target, setBottom);
          setIsOpen(true);
        }}
        style={{ background: bgColor }}
      />
      {isOpen && (
      <div key={123} style={popover(bottom)}>
        <div
          className="box__color__picker__close"
          onClick={() => { setIsOpen(false); }}
        />
        <SketchPicker
          onChange={(newColor) => {
            const { rgb } = newColor;
            const col = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
            handleColor({ type: 'element', value: name, payload: col });
            setBgColor(col);
          }}
          color={color}
        />
      </div>
      )}
    </div>
  );
}

export default ColorPicker;
