import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Field from './Field';
import request from '../../../../../../logic/utils/request';

function ParamsItems({
  defParams, newParams, placeId, fetchNewParams, write,
}) {
  const [multiData, setMultiData] = useState([]);
  const fetchData = async () => {
    const res = await axios({
      method: 'get',
      url: '/api/soundlist',
    });
    const newMultiData = [];
    newMultiData.push(res.data.sounds);
    newMultiData.push(res.data.variables);
    setMultiData(newMultiData);
  };

  useEffect(() => {
    fetchData();
    fetchNewParams();
  }, []);

  const handleRequest = (id, action, data) => {
    request(`/server/api/officeparamsbinding/${id}/`, action, data)
      .then(() => { fetchNewParams(); });
  };

  const setParam = (id, newValue) => {
    const data = {
      params: [{
        officeparam_id: id,
        overridevalue: newValue,
      }],
      wp_id: placeId,
    };

    newParams.forEach((item) => {
      data.params.push({
        officeparam_id: item.officeparam_id,
        overridevalue: item.overridevalue,
      });
    });
    request('/server/api/officeparamsbinding/', 'add', data)
      .then(() => {
        fetchNewParams();
      });
  };

  const onSave = (value, id, type) => {
    let defValue;
    let newValue = value;
    if (type === 'number' && value === '') newValue = 0;
    request(`/server/api/officeparams/${id}/`, 'get') // get default param
      .then((res) => {
        defValue = res.data.data[0].value;
        if (newParams.length) {
          let add = true;
          newParams.forEach((newItem) => {
            if (newItem.officeparam_id === id) {
              add = false; // if this input is already changed then update or delete
              if (defValue === newValue) {
                handleRequest(newItem.id, 'delete'); // delete existing binding
              } else {
                const data = {
                  overridevalue: newValue,
                };
                handleRequest(newItem.id, 'update', data); // update existing binding
              }
            }
          });
          if (add) { //  add new binding if newParams exists but this input hasnt been
            setParam(id, newValue); //  changed before
          }
        } else if (defValue !== newValue) { // add new binding if no newParams
          setParam(id, newValue);
        }
      });
  };

  const resetParam = (id) => {
    newParams.forEach((item) => {
      if (item.officeparam_id === id) {
        handleRequest(item.id, 'delete');
      }
    });
  };

  const renderParams = () => {
    if (newParams.length) {
      const cloneDefParams = [];
      return (defParams.map((defItem, i) => {
        cloneDefParams.push({ ...defItem });
        newParams.forEach((newItem) => {
          if (newItem.officeparam_id === defItem.id) {
            cloneDefParams[i].value = newItem.overridevalue; // rewrite defParam to binding
            cloneDefParams[i].reset = true; // set reset button
          }
        });
        return (
          <Field
            key={cloneDefParams[i].id}
            param={cloneDefParams[i]}
            write={write}
            onSave={onSave}
            resetParam={resetParam}
            multiData={multiData}
          />
        );
      }));
    }

    return defParams.map((defItem) => (
      <Field
        key={defItem.id}
        param={defItem}
        write={write}
        onSave={onSave}
        resetParam={resetParam}
        multiData={multiData}
      />
    ));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
      {renderParams()}
    </div>
  );
}

export default ParamsItems;
