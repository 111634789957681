import { Store } from 'pullstate';

const initState = {
  tickets: [],
};

const monitoringStore = new Store(initState);

export default monitoringStore;

export const updateMonitoringStore = (values) => {
  const updatedValues = (s) => {
    Object.keys(values).forEach((key) => {
      // eslint-disable-next-line no-param-reassign
      s[key] = values[key];
    });
  };
  monitoringStore.update(updatedValues);
};

export const resetMonitoringStore = () => {
  updateMonitoringStore({ ...initState });
};
