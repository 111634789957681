import React from 'react';
import styled from 'styled-components';
import SelectInput from '../../../../../../pages/private/components/Input/SelectInput';
import BgImage from '../../../Devices/DeviceStyle/Components/modules/uploadImage';

const Img = styled.img`
  width: auto;
  height: 40px;
  transition: 0.3s ease-out;
  display: inline-block;
`;
const ButtonDelete = styled.div`
  background: ${(p) => p.theme.accent};
  color: ${(p) => p.theme.buttonHover};
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 3px;
  &:hover {
    box-shadow: 0 0 0 3px ${(p) => p.theme.accentl};
  }
`;

const formats = {
  phone: 'Телефон',
  text: 'Текст',
};

export const maskInputScreen = () => ({
  dataField: 'params',
  text: 'Маска',
  editable: true,
  editorRenderer: (editorProps, value) => {
    const options = [
      { id: 'phone', name: 'Телефон' },
      { id: 'text', name: 'Текст' },
      { id: 'number', name: 'Цифры' },
    ];
    return (
      <SelectInput
        width="200px"
        value={value}
        noDefault
        onChange={(e) => editorProps.onUpdate({ name: 'value', value: e.target.value })}
        options={options}
      />
    );
  },
  formatter: (cell) => (formats[cell.value] || 'Цифры'),
});

export const typeInputScreenButton = ({ fieldsList }) => ({
  dataField: 'params',
  text: 'Значение справочника',
  editable: true,
  editorRenderer: (editorProps, value) => {
    const options = [];
    fieldsList.forEach((el) => options.push({ id: el.id, name: el.name }));
    return (
      <SelectInput
        value={value.value}
        name="params.fid"
        noDefault
        onChange={(e) => editorProps.onUpdate({ name: 'fid', value: e.target.value })}
        options={options}
      />
    );
  },
  formatter(cell) {
    if (!cell.fid) return 'Не выбрано';
    const item = fieldsList.find((el) => el.id === cell.fid);
    return item ? item.name : 0;
  },
});

export const icon = {
  dataField: 'icon',
  text: 'Иконка кнопки',
  editable: true,
  formatter: (cell) => {
    if (cell) {
      return <Img src={cell && cell.replace(/url\(|\)/, '')} alt="" />;
    }
    return 'Выберите файл';
  },
  editorRenderer: (editorProps, value) => (value ? (
    <ButtonDelete onClick={() => editorProps.onUpdate(false)}>
      Удалить
    </ButtonDelete>
  ) : (
    <BgImage
      dispatch={(e) => editorProps.onUpdate({ name: 'icon', value: e.payload })}
      element="icon"
      value={value}
      key={1}
      showText={false}
    />
  )),
};
