import React, { useState, useCallback, useEffect } from 'react';
import NewService from './NewService/NewService';
import request from '../../../../../logic/utils/request';
import ServiceListTable from './ServiceListTable';
import { PreloaderBox } from '../../../../../common/ui/Common';
import { useToggle } from '../../../../../logic/hooks/useToggle';

function Services({ preloader, showError, write }) {
  const initialData = {
    position: '', prefix: '', name: '', fullname: '', workWeek: '', prior: 0,
  };
  const [serviceList, setServiceList] = useState(null);
  const [isOpen, toggleOpen] = useToggle(false);
  const [serviceData, setServiceData] = useState({ ...initialData });
  const [serviceUpdate, setServiceUpdate] = useState(false);

  const fetchServices = useCallback(async () => {
    const { data } = await request('/server/api/queue/', 'get');
    setServiceList(data.data);
  }, []);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  return (
    <div style={{ width: '100%' }}>
      <NewService
        initialData={initialData}
        serviceData={serviceData}
        setServiceData={setServiceData}
        fetchServices={fetchServices}
        setServiceUpdate={setServiceUpdate}
        serviceUpdate={serviceUpdate}
        toggleOpen={toggleOpen}
        isOpen={isOpen}
        write={write}
        preloader={preloader}
        showError={showError}
      />
      <PreloaderBox>
        <ServiceListTable
          serviceList={serviceList}
          setServiceData={setServiceData}
          setServiceUpdate={setServiceUpdate}
          toggleOpen={toggleOpen}
          showError={showError}
          write={write}
          preloader={preloader}
          fetchServices={fetchServices}
        />
      </PreloaderBox>
    </div>
  );
}

export default Services;
