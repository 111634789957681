import React, { useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import AddButton from '../../../../../pages/private/utils/AddButton';
import {
  Input,
  InputGroup,
  InputSmallGroup,
  Label,
  Title,
} from '../../../../../common/ui/Common';
import { BtnRemove } from '../../../../../common/ui/Common2';

function RenderConfig({
  setData, data, setIsValid,
}) {
  const [items, setItems] = useState(
    data && data.config && data.config.digitParams
      ? data.config.digitParams
      : [],
  );

  const addItem = () => {
    const d = {
      digParName: '',
      digParValue: '',
    };
    setItems([...items, d]);
  };

  const onChange = (index, name, value) => {
    setItems((curr) => {
      const newA = [...curr];
      newA[index][name] = value;
      setIsValid(
        !newA.some((el) => el.digParName === '' || el.digParValue === ''),
      );
      setData({ ...data, config: { ...data.config, digitParams: [...newA] } });
      return newA;
    });
  };

  const removeItem = (index) => {
    setItems((curr) => {
      const newA = curr.filter((el, ind) => index !== ind);
      const d = { ...data, config: { ...data.config, digitParams: [...newA] } };
      setIsValid(d !== items);
      setData(d);
      return newA;
    });
  };

  const width = 'calc(50% - 7.5px)';
  return (
    <div>
      <Title>Параметры цифрового табло</Title>
      <AddButton toggle={addItem} />
      {items.length > 0 && (
        <div style={{ display: 'flex' }}>
          <Label style={{ width: '44%' }}>Имя параметра</Label>
          <Label>Значение параметра</Label>
        </div>
      )}
      {items.map((el, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <InputGroup key={index}>
          <InputSmallGroup width={width}>
            <Input
              m="0"
              value={el.digParName}
              onChange={(e) => onChange(index, 'digParName', e.target.value)}
            />
          </InputSmallGroup>
          <InputSmallGroup width={width}>
            <Input
              m="0"
              value={el.digParValue}
              onChange={(e) => onChange(index, 'digParValue', e.target.value)}
            />
          </InputSmallGroup>
          <BtnRemove onClick={() => removeItem(index)}>
            <FaTrash />
          </BtnRemove>
        </InputGroup>
      ))}
    </div>
  );
}

export default RenderConfig;
