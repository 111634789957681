import React from 'react';
import styled from 'styled-components';
import Preload from './preload';

const PreloaderBox = styled.div`
  position: ${(p) => (p.local ? 'relative' : 'fixed')};
  //position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999;
  max-height: ${(p) => (p.op ? '100vh' : '0')};
  opacity: ${(p) => (p.op ? 1 : 0)};
  transition: opacity 0.2s ease-out, max-height 0s;
  height: ${(p) => (p.local ? '150px' : '100%')};
  overflow: hidden;
`;

function Preloader({ isOpen, local }) {
  return (
    <PreloaderBox op={isOpen} local={local}>
      <Preload local={local} open={isOpen} />
    </PreloaderBox>
  );
}

export default Preloader;
