import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/ru';
import { DatePicker } from 'antd';
import ReportFilters from './ReportFilters/ReportFilters';
import {
  ReportParametrsBox, ReportParametrsTitle, DataPicersBox,
} from '../../../../../../../components/report/ReportMenu';
import filterStore, { updateReportStore } from '../../../../../../../../../logic/store/pullstate/filterStore';
import { Button } from '../../../../../../../../../common/ui/Common';

const dateFomat = 'DD.MM.YYYY HH:mm';

function DatePickers(props) {
  const { setView } = props;

  const reportStore = filterStore.useState((s) => s);
  const { startDate: storeStartDate, endDate: storeEndDate } = reportStore;
  const [startDate, setStartDate] = useState(storeStartDate);
  const [endDate, setEndDate] = useState(storeEndDate);

  const getReports = () => {
    setView(true);

    updateReportStore({
      startDate,
      endDate,
    });
  };

  const handleDataChange = (e) => {
    setStartDate(e[0].format(dateFomat));
    setEndDate(e[1].format(dateFomat));
  };

  return (
    <ReportParametrsBox>
      <ReportParametrsTitle>Параметры отчета</ReportParametrsTitle>
      <DataPicersBox>
        <DatePicker.RangePicker
          format={dateFomat}
          style={{ height: '100%' }}
          onChange={handleDataChange}
          defaultValue={[moment(startDate, dateFomat), moment(endDate, dateFomat)]}
          showTime
        />
        <ReportFilters
          reportType={reportStore.reportType}
        />
      </DataPicersBox>
      <Button
        style={{ margin: 0, marginTop: 30 }}
        onClick={getReports}
      >
        Загрузить отчёт
      </Button>
    </ReportParametrsBox>
  );
}

export default DatePickers;
