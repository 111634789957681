const videoValidator = (url) => {
  try {
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', url, false);
    xhr.send();

    return xhr.status === 200;
  } catch (err) {
    return false;
  }
};

export default videoValidator;
