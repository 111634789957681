import React, { useEffect, useRef, useState } from 'react';
import {
  Table, Button, Input, Space, Divider, Typography,
} from 'antd';
import locale from 'antd/es/locale/ru_RU';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import TableConfig from './TableConfig';
import request from '../../../../../../logic/utils/request';
import NewAnswerForm from './NewAnswerForm';

const { Text } = Typography;

const SortableItem = SortableElement((props) => (
  <tr {...props} />
));
const SortableBody = SortableContainer((props) => (
  <tbody {...props} />
));

function Answers(props) {
  const { data: questionData } = props;
  const {
    answers, id: questionId, questiontext, operator_mark: operatorMark,
  } = questionData;

  const [edditableRow, setEdditableRow] = useState('');
  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const input = useRef(null);

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (answers.length > 0) {
      answers.sort((a, b) => a.position - b.position);

      answers.forEach((el, index) => {
        el.position = index;
      });
      setDataSource(answers);
    }
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex, searchItemText) => ({
    // eslint-disable-next-line react/no-unstable-nested-components
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={input}
          placeholder={`Найти ${searchItemText}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Очистить
          </Button>
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => input.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const edditCansel = () => {
    setEdditableRow('');
  };

  const edditRow = (row) => {
    setEdditableRow(row.id);
  };

  const edditAnswer = async (textValue, wigthValue, answerId) => {
    const questionAnswer = dataSource;
    const edditAnswerIndex = questionAnswer.map((x) => x.id).indexOf(answerId);
    questionAnswer[edditAnswerIndex].weight = wigthValue;
    questionAnswer[edditAnswerIndex].answertxt = textValue;

    const newAnswersArray = {
      questiontext,
      operator_mark: operatorMark,
      correctAnswers: questionAnswer.map((el) => ({
        ...el,
      })),
    };
    try {
      const res = await request(`/server/api/qcquestion/${questionId}/`, 'update', newAnswersArray);
      const { data: { answers: resAnswers } } = res;
      resAnswers.sort((a, b) => a.position - b.position);

      resAnswers.forEach((el, index) => {
        el.position = index;
      });
      setDataSource(resAnswers);
    } catch (e) {
      console.log('e === ', e);
    }
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
        (el) => !!el,
      );

      const questionAnswer = newData;
      questionAnswer.forEach((el, index) => {
        el.position = index;
      });

      const newAnswersFromQuestionData = {
        questiontext,
        operator_mark: operatorMark,
        correctAnswers: questionAnswer.map((el) => ({
          ...el,
        })),
      };
      setDataSource(newData);
      try {
        await request(`/server/api/qcquestion/${questionId}/`, 'update', newAnswersFromQuestionData);
      } catch (e) {
        console.log('e === ', e);
      }
    }
  };

  // eslint-disable-next-line
  function DraggableContainer(props) {
    return (
      <SortableBody
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  }

  // eslint-disable-next-line
  function DraggableBodyRow({ className, style, ...restProps }) {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => x.position === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  }

  const deleteAnswer = async (id) => {
    const questionAnswer = dataSource;

    const deletedAnswerIndex = questionAnswer.map((x) => x.id).indexOf(id);
    questionAnswer.splice(deletedAnswerIndex, 1);
    questionAnswer.forEach((el, index) => {
      el.position = index;
    });

    const newAnswersFromQuestionData = {
      questiontext,
      operator_mark: operatorMark,
      correctAnswers: questionAnswer.map((el) => ({
        ...el,
      })),
    };
    try {
      const res = await request(`/server/api/qcquestion/${questionId}/`, 'update', newAnswersFromQuestionData);
      const { data: { answers: resAnswers } } = res;
      resAnswers.sort((a, b) => a.position - b.position);

      resAnswers.forEach((el, index) => {
        el.position = index;
      });
      setDataSource(resAnswers);
    } catch (e) {
      console.log('e === ', e);
    }
  };

  const columns = TableConfig(
    getColumnSearchProps,
    edditRow,
    edditableRow,
    edditCansel,
    edditAnswer,
    deleteAnswer,
  );

  return (
    <>
      <NewAnswerForm questionData={questionData} setDataSource={setDataSource} />

      <Table
        style={{ marginTop: '28px' }}
        rowKey="position"
        dataSource={dataSource}
        columns={columns.columns}
        size="middle"
        bordered
        locale={locale.Table}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        pagination={false}
      />
      <Divider />
      <Text type="secondary" style={{ marginBottom: '10px' }}>
        ** Обратите внимание, что порядок сортировки в этой таблице,
        влияет на отображение данных при дальнейшем использовании
      </Text>
    </>
  );
}

export default Answers;
