import React from 'react';
import SelectInput from '../../../../../../../../Elements/Input2/SelectInput/SelectInput';

const position = ({ button, dispatch }) => {
  const options = [
    [
      { id: 'flex-start', name: 'Сверху' },
      { id: 'center', name: 'по центру' },
      { id: 'flex-end', name: 'Снизу' },
    ],
    [
      { id: 'flex-start', name: 'Слева' },
      { id: 'center', name: 'по центру' },
      { id: 'flex-end', name: 'Справа' },
    ],
  ];
  const arr = [
    { title: 'Кнопки <br />вертикальное', value: button.alignItems, param: 'alignItems' },
    { title: 'Кнопки <br />горизонтальное', value: button.justifyContent, param: 'justifyContent' },
  ];
  return arr.map((el, index) => (
    <span className="box__color" key={el.title}>
      <span
        className="settings__styles__el__label"
        dangerouslySetInnerHTML={{ __html: el.title }}
      />
      <SelectInput
        noDefault
        width="180px"
        value={el.value}
        onChange={(e) => dispatch({
          type: 'params',
          name: 'button',
          param: el.param,
          value: e.target.value,
        })}
        options={options[index]}
      />
    </span>
  ));
};

export default position;
