import React from 'react';
import {
  DivWithoutStyles,
  LineValue,
  ReportLine,
  SomeLineColumn,
  StarRatings,
  StarRatingsButton,
  StarRatingsTop,
} from '../../../../../components/report/ReportTableColumns';

export function GeneralReportMarkTemplate(markValue) {
  return (markValue !== '0%'
    ? (
      <SomeLineColumn>
        <ReportLine>
          <LineValue>
            <StarRatings>
              <StarRatingsTop width={markValue} />
              <StarRatingsButton />
            </StarRatings>
          </LineValue>
        </ReportLine>
      </SomeLineColumn>
    )
    : (<DivWithoutStyles>Оценок нет</DivWithoutStyles>)
  );
}

export default null;
