import React from 'react';
import { useScreenProviderContext } from '../use/useScreenProviderContext';

function NeyavkaScreen({ styleConfig }) {
  const Ticket = localStorage.getItem('currentTicketNumber');
  const { bottomButton } = styleConfig;
  const { changeActiveScreen } = useScreenProviderContext();

  const goHome = () => {
    localStorage.clear();
    changeActiveScreen('terminal');
  };

  return (
    <div className="ticketProcess">
      <div className="ticketProcess__container">
        <p className="ticketProcess__ticketNumber">{Ticket}</p>
        <p className="ticketProcess__text">Ваш талон был вызван</p>
        <p className="ticketProcess__subText" style={{ marginTop: '8px' }}>и закрыт по неявке</p>
        <div className="ticketProcess__button">
          <button
            type="button"
            style={{ background: bottomButton.background }}
            onClick={goHome}
            className="websTeminalScreen__button button__bottom"
          >
            <img src={bottomButton.home.background} alt="home" />
            {' '}
            На главную
          </button>
        </div>
      </div>
    </div>
  );
}

export default NeyavkaScreen;
