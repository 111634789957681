import React, { useEffect, useState } from 'react';
import { Input, Space, Select } from 'antd';
import { Label } from '../../../../../../common/ui/Common';

function RenderInputs({
  fieldData, setFieldData, msData,
}) {
  const [msOptions, setMsOptions] = useState({
    data: [],
    selectValuse: '',
    keys: '',
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    const validData = { ...fieldData, [name]: value };
    if (name === 'bunchType') {
      setFieldData({ ...validData, bunchObjectsIds: [] });
    } else {
      setFieldData({ ...validData });
    }
  };

  useEffect(() => {
    switch (fieldData.bunchType) {
      case ('none'):
        setMsOptions({ data: [], selectValuse: '', keys: '' });
        break;
      case ('wp_id'):
        setMsOptions({ data: msData.wplaces, selectValuse: 'name', keys: 'id' });
        break;
      case ('modified_by_id'):
        setMsOptions({ data: msData.users, selectValuse: 'fio', keys: 'id' });
        break;
      case ('q_id'):
        setMsOptions({ data: msData.queues, selectValuse: 'fullname', keys: 'id' });
        break;
      default:
        setMsOptions({ data: [], selectValuse: '', keys: '' });
        break;
    }
  }, [fieldData, msData.wplaces, msData.users, msData.queues]);

  const typeOptions = () => [
    { name: 'Не выбрано', id: 'none' },
    { name: 'По рабочим местам', id: 'wp_id' },
    { name: 'По операторам', id: 'modified_by_id' },
    { name: 'По услугам', id: 'q_id' },
  ];

  const {
    name, description, bunchType, bunchObjectsIds,
  } = fieldData;

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Input
        required
        name="name"
        value={name}
        addonBefore="Имя:"
        onChange={handleInput}
        size="large"
      />
      <Input
        required
        name="description"
        value={description}
        onChange={handleInput}
        addonBefore="Описание:"
        size="large"
      />
      <Select
        size="large"
        style={{ width: '100%' }}
        value={bunchType}
        required
        onChange={(value) => handleInput({ target: { name: 'bunchType', value } })}
      >
        {typeOptions().map((option) => (
          <Select.Option value={option.id}>{option.name}</Select.Option>
        ))}
      </Select>
      <div>
        <Label isRequired>Группируемые объекты:</Label>
        <Select
          mode="multiple"
          size="large"
          style={{ width: '100%' }}
          value={bunchObjectsIds.map((obj) => obj.id)}
          required
          onChange={(value) => {
            handleInput({
              target: {
                name: 'bunchObjectsIds',
                value: msOptions.data.filter((item) => value.includes(item.id)),
              },
            });
          }}
        >
          {msOptions.data.map((option) => (
            <Select.Option value={option.id}>{option.name}</Select.Option>
          ))}
        </Select>
      </div>
    </Space>
  );
}
export default RenderInputs;
