import {
  Button, Col, Input, Radio, Row, Tabs, Upload, notification,
} from 'antd';
import React, { useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormTooltip from '../../Constructor/ScreenForm/FormTooltip';
import defaultValues from '../../../../utils/defaultValues';
import ButtonAction from './ButtonAction';
import zoneStore, { updateZoneStore } from '../../../Store';

function ButtonForm(props) {
  const {
    button, setIsEditing, screen, updateZone,
  } = props;

  const screens = zoneStore.useState((s) => s.config.screens);

  const [formData, setFormData] = useState({ ...button });
  const [isDisabled, setIsDisabled] = useState(true);

  const updateButton = (e) => {
    e.preventDefault();
    setIsEditing(false);

    const updatedButton = {
      ...button,
      ...formData,
      position: Number(formData.position),
    };

    const newButtons = screen.buttons.map((item) => {
      if (item.id === updatedButton.id) {
        return updatedButton;
      }
      return {
        ...item,
        position: Number(formData.position) === Number(item.position)
          ? button.position : item.position,
      };
    }).sort((a, b) => a.position - b.position);

    const updatedScreen = { ...screen, buttons: [...newButtons] };
    const newScreens = screens.map((item) => {
      if (item.screenId === updatedScreen.screenId) {
        return updatedScreen;
      }
      return item;
    });
    const { config } = zoneStore.getRawState();

    updateZoneStore({ ...zoneStore.getRawState(), config: { ...config, screens: newScreens } });
    updateZone('Кнопка обновлена');
  };

  const handleInput = (e, type = 'text') => {
    const { name, value } = e.target;

    const currentValue = type === 'text' ? {
      ...formData,
      [name]: value,
    } : {
      ...formData,
      [name]: value > screen.buttons.length || value < 1 ? screen.buttons.length : value,
    };

    if (currentValue.name) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    setFormData({ ...currentValue });
  };

  const resetButton = () => {
    const buttonData = {
      name: `Название ${screen.buttons.length}`,
      size: 'lg',
      path: '',
      extraText: '',
      params: { name: 'queue', value: 0 },
      id: button.id,
      icon: false,
      position: screen.buttons.length,
    };

    setFormData({ ...buttonData });
    setIsDisabled(false);
  };

  const handleUpload = (info) => {
    const file = [...info.fileList].slice(-1)[0];
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: 'Это не картинка' });
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      file.base64 = e.target.result;
      setFormData({ ...formData, icon: file.base64 });
    };
    reader.readAsDataURL(file.originFileObj);
    setIsDisabled(false);
  };

  const handleDeleteIcon = (e) => {
    e.stopPropagation();
    setFormData({ ...formData, icon: false });
    setIsDisabled(false);
  };

  const {
    name, extraText, size, position, icon,
  } = formData;

  const buttonActionsTabs = [
    {
      label: 'Переход на экран',
      key: 1,
      children: <ButtonAction
        type="screen"
        path={formData.path}
        params={formData.params}
        setFormData={setFormData}
        screen={screen}
        button={button}
        setIsDisabled={setIsDisabled}
      />,
    },
    {
      label: 'Печать талона',
      key: 2,
      children: <ButtonAction
        type="print"
        path={formData.path}
        params={formData.params}
        setFormData={setFormData}
        screen={screen}
        button={button}
        setIsDisabled={setIsDisabled}
      />,
    },
    {
      label: 'Другое',
      key: 3,
      children: <ButtonAction
        type="other"
        path={formData.path}
        params={formData.params}
        setFormData={setFormData}
        setIsDisabled={setIsDisabled}
      />,
    },
  ];

  return (
    <form onSubmit={isDisabled ? (e) => {
      e.preventDefault();
      setIsEditing(false);
    } : updateButton}
    >
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Row gutter={[0, 20]}>
            <Row style={{ width: '100%' }}>
              <Col span={18}>
                <Row gutter={[0, 20]}>
                  <Col span={20}>
                    <Row gutter={[0, 10]} style={{ width: '100%' }}>
                      <Col span={24}>
                        <span>
                          Текст кнопки
                        </span>
                        <FormTooltip text="Это заголовок экрана, который видит клиент" />
                      </Col>
                      <Col span={24}>
                        <Input
                          placeholder="Текст кнопки"
                          onChange={handleInput}
                          name="name"
                          value={name}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={20}>
                    <Row gutter={[0, 10]} style={{ width: '100%' }}>
                      <Col span={24}>
                        <span>
                          Дополнительный текст
                        </span>
                        <FormTooltip text="Это заголовок экрана, который видит клиент" />
                      </Col>
                      <Col span={24}>
                        <Input
                          placeholder="Дополнительный текст"
                          onChange={handleInput}
                          name="extraText"
                          value={extraText}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col style={{ marginLeft: 'auto' }}>
                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={() => false}
                  onChange={handleUpload}
                  className="button-icon"
                >
                  {icon ? (
                    <>
                      <img
                        src={icon}
                        alt="Иконка"
                        style={{ width: '100%' }}
                      />
                      <DeleteOutlined
                        onClick={handleDeleteIcon}
                        className="delete-icon"
                        style={{ fontSize: '150%', color: 'white' }}
                      />
                    </>
                  ) : (
                    <div>
                      <PlusOutlined />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Загрузить иконку
                      </div>
                    </div>
                  )}
                </Upload>
              </Col>
            </Row>
            <Row gutter={[0, 10]} style={{ width: '100%' }}>
              <Col span={24}>
                <span>
                  Позиция
                </span>
                <FormTooltip text="Это заголовок экрана, который видит клиент" />
              </Col>
              <Col span={15}>
                <Input
                  placeholder="Позиция"
                  onChange={(e) => handleInput(e, 'number')}
                  name="position"
                  value={position}
                  type="number"
                  min={1}
                  max={screen.buttons.length}
                />
              </Col>
            </Row>
            <Row gutter={[0, 10]} style={{ width: '100%' }}>
              <Col span={24}>
                <span>
                  Размер
                </span>
                <FormTooltip text="Это заголовок экрана, который видит клиент" />
              </Col>
              <Col span={24}>
                <Radio.Group
                  options={defaultValues.buttonSizes}
                  onChange={handleInput}
                  value={size}
                  optionType="button"
                  buttonStyle="solid"
                  name="size"
                />
              </Col>
            </Row>
            <Row style={{ width: '100%' }}>
              <Col span={24}>
                <span>
                  Действие при нажатии
                </span>
                <FormTooltip text="Это заголовок экрана, который видит клиент" />
              </Col>
              <Col span={24}>
                <Tabs
                  defaultActiveKey="1"
                  items={buttonActionsTabs}
                />
              </Col>
            </Row>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center" gutter={[10, 20]}>
            <Col>
              <Button type="primary" danger onClick={resetButton}>Сбросить</Button>
            </Col>
            <Col>
              <Button htmlType="submit" type="primary">Сохранить</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
}

export default ButtonForm;
