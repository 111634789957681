import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => (p.isStartNotButtons ? 'center' : null)};
`;
export const BoxButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: ${(p) => (p.img ? 'flex-start' : 'center')};
  p {
    width: 100%;
    padding: 0 15px;
    margin: 0;
    line-height: inherit;
    text-align: center;
  }
  span {
    width: 100%;
    padding: 0 15px;
    display: inline-block;
  }
`;

export const PolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  flex: 1;
  min-width: 100%;
  a {
    text-decoration: none;
  }
  .policyBox {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    display: flex;
    h2 {
      max-width: 90%;
    }
  }
  .policyBottomButtons {
    display: flex;
    justify-content: center;
  }
`;
export const ButtonContent = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  font-size: inherit;
  ${(p) => (p.contentPosition && !p.img ? 'center' : 'space-between')};
  justify-content: center;
  align-items: center;
  font-weight: inherit;
  flex-direction: ${(p) => (p.contentPosition ? 'column' : 'center')};
  div {
    font-weight: inherit;
    order: ${(p) => (p.iconPosition ? 1 : 0)};
    align-items: center;
    span {
      margin-top: 10px
    }
  }
  img {
    order: ${(p) => (!p.iconPosition ? 1 : 0)};
    min-height: ${(p) => `${p.heightIcon}px`};
    max-height: ${(p) => `${p.heightIcon}px`};
    width: ${(p) => (p.bottom ? '40%' : 'auto')};
    margin: 10px;
  }
  p {
    font-weight: inherit;
  }
  span {
    font-weight: inherit;
  }
`;
export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 30px;
  a {
    margin: 0 15px !important;
  }
`;
export const NextButton = styled.div`
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  width: max-content !important;
  height: 100px !important;
  font-size: 36px !important;
  box-sizing: border-box;
  margin: 30px auto;
`;
export const Checkbox = styled.div`
  position: relative;
  display: flex;
  background: ${({ arr, item, st }) => (!arr.some((el) => el === item.params.value)
    ? 'rgba(000.2)'
    : st.background)} !important;
  color: ${({ arr, item, st }) => (!arr.some((el) => el === item.params.value)
    ? '#fafafa'
    : st.background)} !important;
  box-shadow: ${({ arr, item, st }) => (!arr.some((el) => el === item.params.value)
    ? 'inset 0 0 15px 0 rgba(000.1)'
    : st.boxShadow)} !important;
  align-items: center;
  justify-content: center;
    }
    `;

export const InputGroup = styled.div`
  width: ${(p) => `${p?.input?.width}px`};
  height: ${(p) => `${p?.input?.height}px`};
  margin-bottom: ${({ input }) => input?.marginBottom}px;
  margin-top: ${({ input }) => input?.marginTop}px;
  font-weight: ${(p) => p?.input?.fontWeight} !important;
  label, input {
    font: inherit;
    color: inherit;
  }
  label {
    text-transform: ${({ input }) => (input?.textTransform)};
    font-size: ${({ input }) => input?.fontSize}px;
  }
  input {
    min-width: 200px;
    outline: none;
    border-radius: ${({ input }) => input?.borderRadius}px;
    border-color: ${({ input }) => input?.borderColor};
    border-width: ${({ input }) => input?.borderWidth}px;
    font-size: ${({ input }) => input?.fontSize}px;
    margin-right: ${({ input }) => input?.marginRight}px;
    margin-left: ${({ input }) => input?.marginLeft}px;
    background: ${({ input }) => input?.background};
    color: ${({ input }) => input?.color};
    box-shadow: ${({ input }) => input?.boxShadow};
    height: 100%;
    text-align: center;
    padding: 0 30px;
    border-style: solid;
    flex: 1;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputGroupModif = styled.div`
  width: ${(p) => `${p.input.width}px`};
  height: ${(p) => `${p.input.height}px`};
  margin-bottom: ${({ input }) => input.marginBottom}px;
  margin-top: ${({ input }) => input.marginTop}px;
  font-weight: ${(p) => p.input.fontWeight} !important;
  label, input {
    font: inherit;
    color: inherit;
  }
  label {
    text-transform: ${({ input }) => (input.textTransform)};
    font-size: ${({ input }) => input.fontSize}px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  input {
    max-width: 518px;
    min-width: 518px;
    outline: none;
    border-radius: ${({ input }) => input.borderRadius}px;
    border-color: ${({ input }) => input.borderColor};
    border-width: ${({ input }) => input.borderWidth}px;
    font-size: ${({ input }) => input.fontSize}px;
    margin-right: ${({ input }) => input.marginRight}px;
    margin-left: ${({ input }) => input.marginLeft}px;
    background: ${({ input }) => input.background};
    color: ${({ input }) => input.color};
    box-shadow: ${({ input }) => input.boxShadow};
    height: 100%;
    text-align: center;
    padding: 0 30px;
    border-style: solid;
    flex: 1;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InputBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  label input {
    height: 60px;
    line-height: 60px;
  }
  label {
    margin-right: 50px;
  }
  input {
    outline: none;
    border-radius: ${({ button }) => button.borderRadius / 2}px;
    border-color: ${({ button }) => button.borderColor};
    border-width: ${({ button }) => button.borderWidth}px;
    font-size: ${({ button }) => button.fontSize * 0.8}px;
    color: ${(button) => button.color};
    text-align: center;
    padding: 0 30px;
    border-style: solid;
    flex: 1;
  }
  font-size: 32px;
  font-family: inherit;
  font-weight: ${({ button }) => button.fontWeight};
`;
export const KeyboardContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  .ready {
    width: min-content;
    margin: 30px auto 0;
  }
`;
export const Element = styled.div`
  position: absolute;
  display: inline-table;
  width: max-content;
  transform: translate(
    ${({ align }) => (align ? '-50%' : `
$ {
  item.x
}
px `)},
    ${({ item }) => item.y}px
  );
  left: ${({ align }) => (align ? '50%' : 'auto')};
  padding: 10px;
`;
