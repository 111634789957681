import React, {
  useState, useEffect, useCallback,
} from 'react';
import moment from 'moment';
import md5 from 'md5';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import request from '../../request';
import getYear from '../../../../../../../../logic/utils/getYear';
import { QueueBox } from '../../../../utils/style';
import Month from './Month';
import InnerData from './InnerData';
import SelectTime from './Time';
import { setPre } from '../../../../../store/actions';
import './index.css';

function Record({ children }) {
  const dispatch = useDispatch();
  const { bottomButton, style } = useSelector((state) => ({
    bottomButton: state.style.bottomButton,
    config: state.config,
    style: state.style,
  }));
  const currentYear = moment().format('YYYY');
  const currentDay = moment().format('DD');
  const currentMonth = moment().format('MM');
  const [queue, setQueue] = useState([]);
  const [queueHasSlots, setQueueHasSlots] = useState(false);
  const [times, setTimes] = useState([]);
  const [cellId, setCellId] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState({ id: null });
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedDay, setSelectedDay] = useState(undefined);
  const [selectedTime, setSelectedTime] = useState(undefined);
  const [year, setYear] = useState(undefined);
  const [calendar, setCalendar] = useState(undefined);
  const { preEntryInput } = style.general;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const fetch = async () => {
    setIsLoading(true);
    const { data: { data } } = await request('/server/public/preorder/queues/', 'get');
    setQueue(data.filter((el) => el.qFreeSlots));
    setIsLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (selectedQueue) {
      getYear(selectedQueue.id, selectedYear)
        .then((res) => {
          setCalendar(res.year);
          setYear(res.selectedYear);
        });
      setSelectedDay(undefined);
    }
  }, [selectedQueue, selectedYear]);

  const fetchDays = useCallback(async () => {
    if (!selectedDay) return;
    const answer = await request(`/server/public/preorder/day/${selectedDay}?queueId=${selectedQueue.id}`);
    setTimes(answer.data.data);
  }, [selectedDay, selectedQueue.id]);

  useEffect(() => { fetchDays(); }, [fetchDays]);
  useEffect(() => { setSelectedDay(undefined); }, [selectedMonth]);

  const createTicket = async (contactData) => {
    const data = {
      calendarCellId: cellId,
      clientEmail: contactData.clientEmail || '',
      clientPhoneNumber: contactData.clientPhoneNumber ? `+7${contactData.clientPhoneNumber}` : '',
      clientFIO: contactData.clientFIO || '',
    };

    const ordered = {};
    Object.keys(data).sort().forEach((key) => {
      ordered[key] = data[key];
    });

    let hashString = '';
    Object.keys(ordered).forEach((el) => {
      hashString += data[el];
    });
    data.sign = md5(hashString);

    const answer = await request('/server/public/preorder/register', 'no', { data });
    let d = 0;
    const m = year[String(selectedMonth).padStart(2, '0')];
    Object.keys(m).forEach((el) => { if (m[el].id === selectedDay) d = el; });
    const date = `${d}.${String(selectedMonth).padStart(2, '0')}.${selectedYear} ${selectedTime}`;

    console.log('answer === ', answer);

    dispatch(setPre({
      pin: answer.data.data.pinCode,
      date,
      service: selectedQueue.name,
    }));

    navigate('../print');
  };
  const optionsQueue = queue.map((el) => ({ id: el.qId, name: el.qFullName }));

  const noInner = !preEntryInput ? true : preEntryInput && !selectedTime;

  useEffect(() => {
    if (selectedQueue.id != null) {
      const currQueue = queue.find((q) => q.qFullName === selectedQueue.name);
      if (currQueue.qFreeSlots >= 1) {
        setQueueHasSlots(true);
      } else {
        setQueueHasSlots(false);
      }
    }
  }, [selectedQueue]);

  const mounthOrTime = !selectedDay && selectedQueue && selectedMonth && year && selectedYear
    ? (
      <Month
        currentDay={currentDay}
        currentYear={currentYear}
        month={selectedMonth}
        setMonth={setSelectedMonth}
        setDay={setSelectedDay}
        year={year}
        style={bottomButton}
        setQueue={setSelectedQueue}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        currentMonth={currentMonth}
        selectedDay={selectedDay}
        calendar={calendar}
      />
    ) : selectedDay && noInner
    && (
      <SelectTime
        createTicket={createTicket}
        style={bottomButton}
        setDay={setSelectedDay}
        times={times}
        id={cellId}
        preEntryInput={preEntryInput}
        setId={(newCellId, time) => {
          setCellId(newCellId);
          setSelectedTime(time);
        }}
      />
    );

  return (
    <>
      <QueueBox>
        {!selectedQueue.id && (
        <>
          <h3>Выберите услугу</h3>
          <div className="terminal-prerecord__services">
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoading ? <p>Пожалуйста ожидайте</p> : (
              optionsQueue.length > 0 ? optionsQueue.map((el) => (
                <div
                  key={el.id}
                  className={`item ${selectedQueue === el.id && 'active'} terminal-prerecord__service`}
                  onClick={() => setSelectedQueue({ id: el.id, name: el.name })}
                >
                  {el.name}
                </div>
              ))
                : <p>Нет свободных слотов для записи</p>
            )}
          </div>
        </>
        )}
        { queueHasSlots ? mounthOrTime
          : selectedQueue.id != null && <p style={{ textAlign: 'center', fontSize: '30px' }}>Нет доступных слотов для записи</p>}
        {preEntryInput && selectedTime ? (
          <InnerData
            bottomStyle={bottomButton}
            createTicket={createTicket}
          />
        ) : null}
      </QueueBox>
      {children}
    </>
  );
}

export default Record;
