import React from 'react';
import { PolicyContainer } from '../../../../../utils/style';
import scanPolicyStore from '../../../../../../../../../logic/store/pullstate/scanPolicy';
import PolicyBB from './policyBB';

function Name({
  title, children, button, startScreen,
}) {
  const errorText = scanPolicyStore.useState((s) => s.errorText);

  return (
    <PolicyContainer>
      <div className="policyBox">
        <h2
          style={{ ...title, fontSize: title.fontSize * 0.8 }}
          dangerouslySetInnerHTML={{ __html: errorText }}
        />
      </div>
      {children}
      <PolicyBB button={button} startScreen={startScreen} />
    </PolicyContainer>
  );
}
export default Name;
