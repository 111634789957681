import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Drawer } from 'antd';
// import CardEdit from './cardEdit';
import SurveyWrapper from './SurveyWrapper';
import request from '../../../../../logic/utils/request';
import Questions from './Questions/Questions';

function Poll() {
  const [data, setData] = useState([]);
  const [drawerData, setDrawerData] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);

  const navigate = useNavigate();

  const onEdit = (editId) => {
    navigate(`/services/poll/${editId}`);
  };

  const getData = async () => {
    try {
      const res = await request('/server/public/surveys/');
      const { data: { data: tableData } } = res;
      tableData.forEach((el) => {
        el.created_on = moment(new Date(el.created_on)).format('DD.MM.YYYY HH:mm:ss');
        el.modified_on = moment(new Date(el.modified_on)).format('DD.MM.YYYY HH:mm:ss');
        el.connectedQuestions.sort((a, b) => a.position - b.position);
      });
      setData([...tableData]);
    } catch (e) {
      console.log('e === ', e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //  const update = async (updateItem, newItem) => {
  //    const d = {
  //      questiontext: updateItem.questiontext,
  //      operator_mark: updateItem.operator_mark,
  //      correctAnswers: updateItem.answers.map((el) => ({
  //        type: !Number.isNaN(el.answerint) ? el.type : 3,
  //        answerint: !Number.isNaN(el.answerint) ? +el.answerint : el.answerint,
  //        answertxt: el.answerint || el.answertxt,
  //        id: el.id,
  //        correct: el.correct,
  //      })),
  //    };
  //    try {
  //      await request(`/server/api/qcquestion/${updateItem.id}/`, 'update', d);
  //      toast.success('Выполнено');
  //      setItem(newItem);
  //    } catch (e) {
  //      showError(e.data.error);
  //    }
  //  };

  const deleteSurvey = async (surveyId) => {
    try {
      await request(`/server/api/qcsurvey/${surveyId}`, 'delete');
      toast.success('Выполнено');
      getData();
    } catch (e) {
      console.log('e === ', e);
    }
  };

  const openSurveyDrawer = (surveyDrawerData) => {
    setOpenDrawer(true);
    setDrawerData(surveyDrawerData);
  };

  return (
    <>
      <SurveyWrapper
        data={data}
        onEdit={onEdit}
        deleteSurvey={deleteSurvey}
        openSurveyDrawer={openSurveyDrawer}
        getData={getData}
        drawerData={drawerData}
      />
      {/* <CardEdit goBack={goBack} getData={getData} update={update} showError={showError} /> */}
      <Drawer
        title={`Вопросы анкеты ${drawerData?.surveyname}`}
        placement="right"
        onClose={() => { setOpenDrawer(false); }}
        visible={openDrawer}
        width={1024}
      >
        <Questions data={drawerData} getData={getData} />
      </Drawer>

    </>
  );
}

export default Poll;
