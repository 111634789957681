import React from 'react';
import {
  CardReportBox, CardReportTitle, CardReportBody,
  CardReportElement, CardReportDiscription, CardReportItem,
} from './Style';

export function BigCard({ info }) {
  const [
    titleText, firstElementDiscr, firstElementValue,
    secondElementDiscr, secondElementValue, thirdElementDiscr, thirdElementValue,
  ] = info;
  return (
    <CardReportBox>
      <CardReportTitle>{titleText}</CardReportTitle>
      <CardReportBody>
        <CardReportElement>
          <CardReportDiscription>{firstElementDiscr}</CardReportDiscription>
          <CardReportItem>{firstElementValue}</CardReportItem>
        </CardReportElement>
        <CardReportElement>
          <CardReportDiscription>{secondElementDiscr}</CardReportDiscription>
          <CardReportItem>{secondElementValue}</CardReportItem>
        </CardReportElement>
        <CardReportElement>
          <CardReportDiscription>{thirdElementDiscr}</CardReportDiscription>
          <CardReportItem>{thirdElementValue}</CardReportItem>
        </CardReportElement>
      </CardReportBody>
    </CardReportBox>
  );
}

export function SmallCard(titleText, value) {
  return (
    <CardReportBox>
      <CardReportTitle>{titleText}</CardReportTitle>
      <CardReportBody>
        <CardReportElement>
          <CardReportItem>{value}</CardReportItem>
        </CardReportElement>
      </CardReportBody>
    </CardReportBox>
  );
}
