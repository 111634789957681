import styled from 'styled-components';

export const AccordionBox = styled.div`
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0,0,0,.1);
    background: #fff;
`;

export const AccordionTitle = styled.div`
    display: flex;
    font-size: 20px;
    text-transform: uppercase;
    padding: 10px 15px;
    background: gainsboro;
`;

export const AccordionTitleText = styled.div`
    flex: 1;
`;

export const AccordionTitleIcon = styled.div`
  width: 25px;
  margin: ${(p) => (p.isTitle ? '0px 0px 0px 10px' : '0px 10px 0px 0px')};
  display: inline-grid;
  color: ${(p) => (p.isOpen ? 'red' : 'grey')}
  svg {
    transform: rotate(${(p) => (p.isOpen ? '90deg' : '0deg')});
    fill: ${(p) => (p.active ? 'white' : null)}
  }
`;

export const AccordionContent = styled.div`
    display: ${(p) => (p.isOpen ? 'flex' : 'none')};
    padding: 20px;
`;
