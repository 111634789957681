import { Route } from 'react-router-dom';
import React from 'react';
import Home from '../home/home';

export const getRoutes = ({
  config,
  startScreen,
  height,
  props,
}) => config.layoutData.map((scene) => {
  const path = scene.screenId;
  return (
    <Route
      key={scene.screenId}
      path={path}
      element={(
        <Home
          {...props}
          height={height}
          path={path}
          scene={scene}
          startScreen={startScreen}
        />
      )}
    />
  );
});

export default null;
