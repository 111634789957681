import React from 'react';
import FontFamily from './modules/font';

function FontFamilyComponent({
  arr, dispatch, text, width, widthInput, children, options,
}) {
  return (
    <div className="settings__styles__el">
      {text && <p>{text}</p>}
      {arr.map((el) => (
        <FontFamily
          width={width}
          widthInput={widthInput}
          element={el.parentName}
          options={options}
          nameParams={el.paramName}
          dispatch={dispatch}
          title={el.title}
          value={String(el.value)}
          key={el.title}
        />
      ))}
      {children}
    </div>
  );
}

export default FontFamilyComponent;
