export default function BasicSettings() {
  return ({
    label: 'Кол-во талонов',
    fill: false,
    lineTension: 0.05,
    backgroundColor: 'rgba(75,192,192,0.4)',
    borderColor: '#20a8d8',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: '#20a8d8',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: '#20a8d8',
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
  });
}
