export default ({ bottomButton }) => [
  {
    name: 'Изображение для кнопки "Назад"', img: bottomButton.back.background, value: 'bottomButton.back.background',
  },
  {
    name: 'Изображение для кнопки "На главный экран"', img: bottomButton.home.background, value: 'bottomButton.home.background',
  },
  {
    name: 'Изображение для кнопки "Информация"', img: bottomButton.info.background, value: 'bottomButton.info.background',
  },
];
