import React from 'react';
import Text from './modules/text';

const HeightComponent = ({
  arr, dispatch,
}) => (arr.map((el) => (
  <Text
    element={el.parentName}
    param={el.paramName}
    dispatch={dispatch}
    title={el.title}
    value={el.value}
    key={el.title}
  />
))
);

export default HeightComponent;
