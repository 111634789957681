import React, { useState } from 'react';
import {
  Button, Input, Form, Card,
} from 'antd';
import request from '../../../../../../logic/utils/request';

const { TextArea } = Input;

function NewQuestionForm(props) {
  const {
    setConnectedQuestions, surveyData, connectedQuestions, getData,
  } = props;
  const { surveyname, id: surveyId } = surveyData;
  const [quetionText, setQuestionText] = useState('');
  const addNewQuestion = async () => {
    const newQuest = {
      questiontext: quetionText,
      operator_mark: false,
      correctAnswers: [],
      position: connectedQuestions.length + 1,
    };
    console.log('newQuest === ', newQuest);
    try {
      const newQuestion = await request('/server/api/qcquestion/', 'add', newQuest);
      const { data: { id: newQuestionId } } = newQuestion;
      const newConnectedQuestionArray = [...connectedQuestions.map((el) => el.id)];

      newConnectedQuestionArray.push(newQuestionId);

      const test = [];

      newConnectedQuestionArray.forEach((el, idnex) => {
        test.push({
          question_id: el,
          position: idnex,
        });
      });

      const dataUpdate = {
        surveyname,
        connectedQuestions: test,
      };

      const addQuestionToSurveyRes = await request(`/server/api/qcsurvey/${surveyId}/`, 'update', dataUpdate);
      const { data: { survey: { questions } } } = addQuestionToSurveyRes;
      setConnectedQuestions(questions);
      setQuestionText('');
      getData();
    } catch (e) {
      console.log('e === ', e);
    }
  };

  return (
    <Card
      title="Добавить вопрос"
    >
      <Form>
        <Form.Item>
          <TextArea
            placeholder="Название вопроса"
            size="large"
            value={quetionText}
            onChange={(e) => { setQuestionText(e.target.value); }}
            defaultValue={quetionText}
          />
        </Form.Item>

        <Button type="primary" size="large" onClick={addNewQuestion} disabled={!quetionText.length > 0}>
          Добавить вопрос
        </Button>
      </Form>
    </Card>
  );
}

export default NewQuestionForm;
