import React from 'react';
import Modal from '../../../../common/ui/Modal/Modal';
import Body from './Body';
import ModalButtons from '../../../../common/ui/Modal/ModalButtons';

function Index(props) {
  const {
    open, toggle, save, reset, data, valid, user,
  } = props;

  return (
    <Modal
      width="800px"
      title={user ? 'Фильтры привязки значения текстового поля талона к пользователю' : 'Фильтры рабочего места'}
      toggleOpen={toggle}
      isOpen={open}
    >
      {data && <Body {...props} />}
      {!user && (
        <ModalButtons
          update
          isValid={valid}
          updateFunc={save}
          addFunc={() => { }}
          reset={reset}
        />
      )}
    </Modal>
  );
}

export default React.memo(Index);
