export default ({
  header, footer, logoABT, logoComp, button,
}) => [
  {
    title: 'Высота <br/>шапки', value: header.height, paramName: 'height', parentName: 'header',
  },
  {
    title: 'Высота <br/>подвала', value: footer.height, paramName: 'height', parentName: 'footer',
  },
  {
    title: 'Ширина контента<br/>кнопок', value: button.widthContent, paramName: 'widthContent', parentName: 'button',
  },
  {
    title: 'Высота <br/>логотипа А-БТ', value: logoABT.height, paramName: 'height', parentName: 'logoABT',
  },
  {
    title: 'Ширина <br/>логотипа А-БТ', value: logoABT.width, paramName: 'width', parentName: 'logoABT',
  },
  {
    title: 'Высота <br/>логотипа компании', value: logoComp.height, paramName: 'height', parentName: 'logoComp',
  },
  {
    title: 'Ширина <br/>логотипа компании', value: logoComp.width, paramName: 'width', parentName: 'logoComp',
  },
];
