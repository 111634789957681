export default ({ button }) => [
  {
    title: 'Маленькая', value: button.fontSizeS, paramName: 'fontSizeS', parentName: 'button',
  },
  {
    title: 'Средняя', value: button.fontSizeM, paramName: 'fontSizeM', parentName: 'button',
  },
  {
    title: 'Большая', value: button.fontSizeL, paramName: 'fontSizeL', parentName: 'button',
  },
];
