import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import { FontsBox } from '../../../../../common/ui/Common';
import Upload from './upload';
import List from './list';

function News() {
  const initState = {
    date: { value: undefined, title: 'Дата новости' },
    title: { value: undefined, title: 'Заголовок новости' },
    text: { value: undefined, title: 'Текст новости' },
  };
  const [data, setData] = useState(initState);
  const [news, setNews] = useState([]);
  const [valid, setValid] = useState(initState);

  useEffect(() => {
    setValid(Boolean(data.text.value));
  }, [data]);

  const getNews = useCallback(() => {
    axios({
      method: 'get',
      url: '/api/news',
    })
      .then((res) => setNews(res.data));
  }, []);

  useEffect(() => {
    getNews();
  }, [getNews]);

  const onSave = async () => {
    const d = {
      text: data.text.value,
      title: data.title.value,
      date: moment(data.date.value).format('DD.MM.YYYY'),
    };
    await axios({
      method: 'post',
      url: '/api/news',
      data: d,
    });
    getNews();
  };

  const onDelete = async (id) => {
    await axios({
      method: 'delete',
      url: '/api/news',
      data: {
        id,
      },
    });
    getNews();
  };

  return (
    <FontsBox>
      <Upload data={data} onSave={onSave} valid={valid} setData={setData} />
      <List news={news} onDelete={onDelete} />
    </FontsBox>
  );
}

export default News;
