import md5 from 'md5';
import axios from 'axios';

const registerSlot = async (baseUrl, {
  calendarCellId, clientphone, clientfio, clientmail, q_id: qId,
}) => {
  const reqUrl = `${baseUrl}/server/public/preorder/register`;
  const postData = {
    calendarCellId,
    q_id: qId,
  };

  postData.clientEmail = clientmail || '';
  postData.clientFIO = clientfio || '';
  postData.clientPhoneNumber = clientphone || '';

  const dataKeys = Object.keys(postData).sort();
  let signStr = '';
  dataKeys.forEach((key) => {
    signStr += String(postData[key]);
  });

  postData.sign = md5(signStr);

  const qReq = await axios.post(reqUrl, { data: postData });
  const { data: { status, error, data: qData } } = qReq;
  if (status === 'failed') {
    throw new Error(error.comment);
  }
  return qData;
};

export default registerSlot;
