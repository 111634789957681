import React, { useState } from 'react';
import { Button } from 'antd';
import { toast } from 'react-toastify';
import request from '../../../../../../logic/utils/request';
import Modal from '../../../../../../common/ui/Modal/Modal';
import RenderInputs from './RenderInputs';
import ModalButtons from '../../../../../../common/ui/Modal/ModalButtons';

function NewQuestion({
  fetchQuests, toggleOpen, isOpen, setQuestData, questData,
  setQuestUpdate, questUpdate, initialData, preloader,
}) {
  const [isValid, setIsValid] = useState(false);
  const resetModal = () => {
    toggleOpen();
    setQuestData({ ...initialData });
    setQuestUpdate(false);
  };

  const addQuest = async () => {
    if (isValid) {
      await request(
        '/server/api/servedquestion/',
        'add',
        { ...questData, regulatoryTime: Number(questData.regulatoryTime) },
      ).then(() => {
        fetchQuests();
        resetModal();
      }).catch((e) => {
        if (e.data.error && e.data.error.code === '300') {
          toast.error('Вопрос с таким наименованием уже существует');
        }
      });
    }
  };

  const updateQuest = async () => {
    if (isValid) {
      const itemId = questData.id;
      delete questData.id;
      await request(`/server/api/servedquestion/${itemId}/`, 'update', { ...questData });
      fetchQuests();
      resetModal();
    }
  };

  return (
    <>
      <Button type="primary" size="large" onClick={toggleOpen}>
        Добавить
      </Button>
      <Modal
        title={questUpdate ? 'Редактирование вопроса' : 'Добавление вопроса'}
        toggleOpen={resetModal}
        isOpen={isOpen}
      >
        <RenderInputs
          setIsValid={setIsValid}
          questData={questData}
          preloader={preloader}
          setQuestData={setQuestData}
        />
        <ModalButtons
          isValid={isValid}
          updateFunc={updateQuest}
          addFunc={addQuest}
          update={questUpdate}
          reset={resetModal}
        />
      </Modal>
    </>
  );
}

export default React.memo(NewQuestion);
