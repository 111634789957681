export default ({ button }) => [
  {
    title: 'Фон', value: button.background, paramName: 'background', parentName: 'button',
  },
  {
    title: 'Обводка', value: button.borderColor, paramName: 'borderColor', parentName: 'button',
  },
  {
    title: 'Текст', value: button.color, paramName: 'color', parentName: 'button',
  },
  {
    title: 'Тень', value: button.boxShadow.split(' ')[3], paramName: 'boxShadow', parentName: 'button',
  },
  {
    title: 'Фон </b> при активации', value: button.backgroundHover, paramName: 'backgroundHover', parentName: 'button',
  },
  {
    title: 'Цвет </b> при активации', value: button.colorHover, paramName: 'colorHover', parentName: 'button',
  },
  {
    title: 'Фон печати', value: button.backgroundBottom, paramName: 'backgroundBottom', parentName: 'button',
  },
];
