import React, { useState } from 'react';
import {
  CheckOutlined, DeleteOutlined, ClearOutlined, CopyOutlined, LoadingOutlined, SaveOutlined,
} from '@ant-design/icons';
import {
  Card, Form, Input, Statistic, message, Select, notification, Button, Popover,
} from 'antd';
import dayjs from 'dayjs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useMutation } from '@tanstack/react-query';
import preorderRest from '../../utils/preorderRest';

function SlotCard({
  slot, closeDrawer, refetchSlots, queues,
}) {
  const [clientData, setClientData] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const { registered, pastart_on: slotStart } = slot;
  const isPast = dayjs().isAfter(slotStart);
  const onCopy = () => {
    messageApi.open({
      type: 'success',
      content: 'пин-код скопирован',
    });
  };
  const { mutate: registerSlotMutate, isPending: isRegisterLoading } = useMutation({
    mutationFn: (
      variables,
    ) => preorderRest.registerSlot(variables),
    onSuccess: (data) => {
      slot.registered = true;
      slot.pincode = data.pinCode;
      refetchSlots();
      notification.success({
        message: !registered ? 'Слот зарегистрирован' : 'Слот обновлён',
      });
    },
    onError: () => {
      notification.error({
        message: 'Не удалось зарегистрировать слот',
      });
    },
  });
  const { mutate: rewriteSlotMutate, isPending: isRewriteLoading } = useMutation({
    mutationFn: (variables) => preorderRest.rewriteSlot(variables),
    onSuccess: (status) => {
      if (status === 'failed') {
        notification.error({
          message: 'Не удалось освободить слот',
        });
      } else {
        refetchSlots();
      }
    },
  });
  const { mutate: deleteSlotMutate, isPending: isDeleteLoading } = useMutation({
    mutationFn: (variables) => preorderRest.deleteSlot(variables),
    onSuccess: (status) => {
      if (status === 'failed') {
        notification.error({
          message: 'Не удалось удалить слот',
        });
      } else {
        refetchSlots();
        closeDrawer();
      }
    },
  });
  const { mutate: saveSlotMutation, isPending: isSaveLoading } = useMutation({
    mutationFn: (variables) => preorderRest.saveSlot(variables),
    onSuccess: (status) => {
      if (status === 'failed') {
        notification.error({
          message: 'Не удалось сохранить данные',
        });
      } else {
        notification.success({
          message: 'Данные сохранены',
        });
        refetchSlots();
      }
    },
  });

  if (slot.q_id === 0) {
    slot.q_id = undefined;
  }

  const handleInput = (_value, values) => {
    setClientData(values);
  };

  const registerSlot = () => {
    slot = { ...slot, ...clientData };
    registerSlotMutate(slot);
  };

  const clearSlot = () => {
    rewriteSlotMutate({ calendarCellId: slot.calendarCellId });
  };

  const saveSlot = () => {
    slot = { ...slot, ...clientData };
    saveSlotMutation(slot);
  };

  const deleteSlot = () => {
    deleteSlotMutate(slot.calendarCellId);
  };

  const actions = [
    <Popover content="Удалить слот">
      <Button onClick={deleteSlot} disabled={registered || isPast}>
        {isDeleteLoading ? <LoadingOutlined style={{ fontSize: 20 }} /> : <DeleteOutlined style={{ fontSize: 20 }} />}
      </Button>
    </Popover>,
    <Popover content="Сохранить данные">
      <Button loading={isSaveLoading} onClick={saveSlot} disabled={registered || isPast}>
        {isSaveLoading ? <LoadingOutlined style={{ fontSize: 20 }} /> : <SaveOutlined style={{ fontSize: 20 }} />}
      </Button>
    </Popover>,
  ];

  if (registered) {
    actions.push(
      <Popover content="Освободить слот">
        <Button onClick={clearSlot} disabled={isPast}>
          {
              isRewriteLoading
                ? <LoadingOutlined style={{ fontSize: 20 }} />
                : <ClearOutlined style={{ fontSize: 20 }} />
            }
        </Button>
      </Popover>,
    );
  } else {
    actions.push(
      <Popover content="Зарегистрировать слот">
        <Button
          onClick={registerSlot}
          disabled={(!slot.q_id && !clientData?.q_id) || isPast || registered}
        >
          {
              isRegisterLoading
                ? <LoadingOutlined style={{ fontSize: 20 }} />
                : <CheckOutlined style={{ fontSize: 20 }} />
            }
        </Button>
      </Popover>,
    );
  }

  return (
    <Card
      style={{ width: '340px' }}
      actions={actions}
    >
      <Form layout="vertical" onValuesChange={handleInput} initialValues={slot} disabled={isPast}>
        <Form.Item name="clientfio" label="ФИО">
          <Input />
        </Form.Item>
        <Form.Item name="clientphone" label="Телефон">
          <Input />
        </Form.Item>
        <Form.Item name="clientmail" label="email">
          <Input />
        </Form.Item>
        {!slot.q_id && (
          <Form.Item required name="q_id" label="Очередь">
            <Select placeholder="Выберите очеоедь">
              {queues.filter((el) => el.qId !== 0).map((queue) => (
                <Select.Option key={queue.qId}>{queue.qFullName}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
      {registered && (
        <div className="preorder__slot__pin">
          <Statistic
            formatter={(value) => value}
            title="пин-код"
            value={slot.pincode}
          />
          {registered && (
          <CopyToClipboard text={String(slot.pincode)} onCopy={onCopy}>
            <CopyOutlined style={{ fontSize: 24, marginBottom: 10 }} />
          </CopyToClipboard>
          )}
        </div>
      )}
      {contextHolder}
    </Card>
  );
}

export default SlotCard;
