import React, { useState, useMemo } from 'react';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import {
  InputBox, InputGroup, KeyboardContainer, NextButton,
} from '../../../../utils/style';
import ModalInput from './Modal';

function InnerData({ createTicket, bottomStyle }) {
  const { input, button } = useSelector((state) => state.config.config.style);
  const [id, setId] = useState(null);
  const [name, setName] = useState('');

  const [values, setValues] = useState(['', '', '']);

  const inputs = useMemo(() => [
    <InputGroup button={button} input={input} key="1">
      <span className="phoneLabel">Ваш телефон</span>
      <InputMask
        className={`phonenumber ${id === 1 && 'activeInput'}`}
        value={values[0]}
        mask="+7 (999) 999 99 99"
        alwaysShowMask
        onClick={() => {
          setName('phone');
          setId(1);
        }}
      />
    </InputGroup>,
    <InputGroup button={button} input={input} key="2">
      <span className="phoneLabel">Ваш e-mail</span>
      <input
        className={id === 2 && 'activeInput'}
        value={values[1]}
        onClick={() => {
          setId(2);
          setName('');
        }}
        onChange={(e) => setValues([values[0], e.target.value, values[2]])}
      />
    </InputGroup>,
    <InputGroup button={button} input={input} key="3">
      <span className="phoneLabel">ФИО</span>
      <input
        className={id === 3 && 'activeInput'}
        value={values[2]}
        onClick={() => {
          setId(3);
          setName('');
        }}
      />
    </InputGroup>,
  ], [values, button, id, input]);

  return id ? (
    <ModalInput
      input={input}
      id={id}
      values={values}
      setValues={setValues}
      styleButton={button}
      inputs={inputs}
      setId={setId}
      name={name}
    />
  )
    : (
      <KeyboardContainer>
        <InputBox button={input}>
          {inputs}
          <div className="flex" style={{ marginTop: 50 }}>
            <NextButton
              style={bottomStyle}
              onClick={() => createTicket({
                clientPhoneNumber: values[0],
                clientEmail: values[1],
                clientFIO: values[2],
              })}
            >
              {' '}
              Записаться
            </NextButton>
          </div>
        </InputBox>
      </KeyboardContainer>
    );
}

export default InnerData;
