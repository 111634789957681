import { Checkbox } from 'antd';
import React from 'react';

function PopConfirmButton(props) {
  const { isChecked, setIsChecked } = props;

  return (
    <div>
      <div>
        <strong>Удалить кнопку?</strong>
      </div>
      <Checkbox
        checked={isChecked}
        onChange={(e) => setIsChecked(e.target.checked)}
      >
        Больше не справшивать
      </Checkbox>
    </div>
  );
}

export default PopConfirmButton;
