import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/ru';
import { isAfter } from 'date-fns';
import {
  Card, Space, Typography, DatePicker,
} from 'antd';
import QuestionSelection from './QuestionSelection';

function Params({ params, activeSection }) {
  const { settings, setSettings } = params;

  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('day'));

  const handleDataChange = ({ sDate, eDate }) => {
    const defaultStartDate = startDate;
    const defaultEndDate = endDate;
    const newStartDate = sDate || defaultStartDate.toDate();
    let newEndDate = eDate || defaultEndDate.toDate();

    newEndDate = isAfter(newStartDate, newEndDate) ? newStartDate : newEndDate;
    const dateFormat = 'DD.MM.YYYY HH:mm';
    const time = moment(newStartDate);
    const endTime = moment(newEndDate);

    setStartDate(time);
    setEndDate(endTime);
    setSettings({
      ...settings,
      startDate: time.format(dateFormat),
      endDate: endTime.format(dateFormat),
    });
  };

  const handleQuestionChange = (qId) => {
    setSettings({
      ...settings,
      questId: qId,
    });
  };

  return (
    <Card>
      <Typography.Title level={4}>Параметры</Typography.Title>
      <Space direction="horizontal" size="middle" align="center">
        <DatePicker.RangePicker
          value={[moment(startDate), moment(endDate)]}
          onCalendarChange={([sDate, eDate]) => handleDataChange({ sDate, eDate })}
        />
        {activeSection === 'nps'
          ? (<QuestionSelection handleQuestionChange={handleQuestionChange} />) : null}
      </Space>
    </Card>
  );
}

export default Params;
