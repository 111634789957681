import React, { useState, useEffect } from 'react';
import { Select, Input as AntInput } from 'antd';
import getDropdownConfig from './DropdownConfig';
import { TestDiv, TestDiv2, TestDiv3 } from '../../style';
// import Dropdown from '../../../../../../../components/dropdown/Dropdown';
// import { Input } from '../../../../../../../../../common/ui/Common';

function AllFilter({
  changeFilter, filld, title, timeFilter, smallTitle, type,
}) {
  const defaultFilterData = {
    comparator: 'none',
    value: '',
    from: '',
    before: '',
  };
  const [filterData, setFilterData] = useState(defaultFilterData);
  const [renderInput, setRenderInput] = useState('none');
  const DropdownConfig = getDropdownConfig();

  const changeFilterMark = (_, option) => {
    if (option.key === 'none') {
      setFilterData(defaultFilterData);
    } else {
      setFilterData({ ...filterData, comparator: option.value });
    }
    setRenderInput(option.key);
  };

  const handleInput = (e) => {
    const { value, name } = e.target;
    if (name === 'rangeBefore') {
      setFilterData({ ...filterData, before: value });
    } else if (name === 'rangeFrom') {
      setFilterData({ ...filterData, from: value });
    } else {
      setFilterData({ ...filterData, value });
    }
  };

  useEffect(() => {
    let where;
    switch (filterData.comparator) {
      case 'range':
        if (filterData.before && filterData.from !== '') {
          where = `> ${filterData.from} ${timeFilter ? ('*60 * interval \'1 sec\'') : ('')} 
          and ${filld}${timeFilter ? ('::time') : ('')} < ${filterData.before}`;
        } else {
          where = 'all';
        }
        break;
      case 'none':
        where = 'all';
        break;
      default:
        if (filterData.value !== '') {
          where = `${filterData.comparator} ${filterData.value}`;
        } else {
          where = 'all';
        }
        break;
    }
    changeFilter(filld, where);
  }, [filterData]);

  const items = [
    { name: 'allCnt', type: 'noneDescription', value: filterData.value || '' },
  ];

  const DuobleItems = [
    { name: 'rangeFrom', type: 'noneDescription', value: filterData.from || '' },
    { name: 'rangeBefore', type: 'noneDescription', value: filterData.before || '' },
  ];

  const renderFilterInput = () => {
    switch (renderInput) {
      case 'none':
        return <div />;
      case 'range':
        return DuobleItems.map((item) => (
          <>
            <div
              key={item.name}
              style={{
                marginLeft: '10px', width: '90px', display: 'flex', position: 'relative', justifyContent: 'center',
              }}
            >
              {/* <Input */}
              {/*  type="number" */}
              {/*  onChange={handleInput} */}
              {/*  name={item.name} */}
              {/*  value={item.value} */}
              {/*  m="0px" */}
              {/* /> */}
              <AntInput
                type="number"
                onChange={handleInput}
                name={item.name}
              />
              {timeFilter ? (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 'smaller',
                  position: 'absolute',
                  padding: '5px 5px',
                  background: '#ffffff4d',
                  top: '-1em',
                }}
                >
                  Мин.
                </div>
              ) : null}
            </div>
            {item.name === 'rangeFrom' ? <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>И</div> : null}
          </>
        ));
      default:
        return items.map((item) => (
          <div
            key={item.name}
            style={{
              marginLeft: '10px', width: '90px', display: 'flex', position: 'relative', justifyContent: 'center',
            }}
          >
            {/* <Input */}
            {/*  type="number" */}
            {/*  onChange={handleInput} */}
            {/*  name={item.name} */}
            {/*  value={item.value} */}
            {/*  m="0px" */}
            {/* /> */}
            <AntInput
              type="number"
              onChange={handleInput}
              name={item.name}
            />
            {timeFilter ? (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 'smaller',
                position: 'absolute',
                padding: '5px 5px',
                background: '#ffffff4d',
                top: '-1em',
              }}
              >
                Мин.
              </div>
            ) : null}
          </div>
        ));
    }
  };

  return (
    <TestDiv2>
      <TestDiv typew={type}>
        {smallTitle ? (<div style={{ fontSize: '12px' }}>{title}</div>) : (
          <div>
            {' '}
            {title}
            {' '}
          </div>
        )}
        <div style={{ marginLeft: '10px' }}>
          {/* <Dropdown */}
          {/*  config={DropdownConfig} */}
          {/*  clickHandler={changeFilterMark} */}
          {/*  replaceableTitle */}
          {/* /> */}
          <Select defaultValue={DropdownConfig.items[0].name} className="select" onChange={changeFilterMark}>
            {DropdownConfig.items.map((el) => (
              <Select.Option key={el.key} value={el.name}>{el.text}</Select.Option>
            ))}
          </Select>
        </div>
      </TestDiv>
      <TestDiv3>{renderFilterInput()}</TestDiv3>
    </TestDiv2>
  );
}

export default AllFilter;
