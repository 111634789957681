import React from 'react';
import { dateFormat } from '../../../../../utils/tableFormatters';
import iconFormatter from '../../../../../../pages/private/utils/iconFormatter';

function IntervalFormatter(cell, intervals) {
  const { data } = intervals;
  return (
    <div className="intervalBox">
      {data.map((el) => cell.workIntervals.some((int) => int === el.id) && <div key={el.id} className="connectIntervals">{el.name}</div>)}
    </div>
  );
}

export default function TableConfig(intervals, write, searchPropsFactory) {
  return {
    columns: [
      {
        dataIndex: 'name',
        title: 'Наименование',
        ...searchPropsFactory('name', 'наименованию'),
      },
      {
        dataIndex: 'description',
        title: 'Описание',
        ...searchPropsFactory('name', 'наименованию'),
      },
      {
        dataIndex: 'modified_by_username',
        title: 'Изменен',
        ...searchPropsFactory('name', 'наименованию'),
      },
      {
        dataIndex: 'modified_on',
        title: 'Когда изменен',
        ...searchPropsFactory('modified_on', 'дате/времени изменения'),
        render: (value) => (
          <p>{dateFormat(value)}</p>
        ),
      },
      {
        dataIndex: 'connectIntervals',
        title: 'Подключенные интервалы',
        ...searchPropsFactory('connectIntervals', 'подключенным интервалам'),
        render: (cell, column) => IntervalFormatter(column, intervals),
      },
      {
        dataIndex: 'Settings',
        title: '',
        width: 100,
        render: () => iconFormatter({ filter: false, write, params: false }),
      },
    ],
  };
}
