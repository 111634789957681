import React from 'react';
import Modal from './Modal';
import {
  BtnsBox, Btn, ErrorText,
} from '../Common2';

function ErrorModal({ isError, errorText, showError }) {
  let error = null;
  if (typeof errorText === 'string') {
    error = errorText;
  }
  const { code, comment, text } = errorText;

  const getErrorText = () => {
    if (text) {
      if (text.rus) return text.rus;
      return text.eng;
    }
    return false;
  };

  const getErrorDiscription = () => {
    if (comment.search('tickets_field') !== -1 && Number(code) === Number(15)) {
      return 'Прежде чем удалить дополнительное поле с множественным выбором, необходимо удалить все прикреплённые варианты выбора.';
    }
    return comment;
  };

  return (
    <Modal title={text ? text.rus : ''} isOpen={isError} toggleOpen={() => showError('')}>
      <ErrorText>{getErrorText()}</ErrorText>
      <ErrorText>{comment && getErrorDiscription()}</ErrorText>
      <ErrorText>{code && `КОД ${code}`}</ErrorText>
      <ErrorText>{error && error}</ErrorText>
      <BtnsBox>
        <Btn valid onClick={() => showError('')}>ОК</Btn>
      </BtnsBox>
    </Modal>
  );
}
export default ErrorModal;
