import React from 'react';
import InputMask from 'react-input-mask';
import { InputGroup, InputGroupModif } from './style';

function Input(props) {
  const values = {
    phone: 1,
    text: 2,
  };

  const type = props.item.type || values[props?.item?.params?.value] || 0;
  return (
    <div>
      {/* eslint-disable */
      type === 1 ? 
        <PhoneInput {...props} />
        :
      type === 2 ?
        <TextInput {...props} />
        :
      type === 3 ?
        <DateInput {...props} />
        :
        <DefaultInput {...props} />
      }
    </div>
  );
}

export default Input;

function PhoneInput({
  item, clickInput, id, value, style,
}) {
  return (
    <InputGroup input={style}>
      {item.name && <span className="phoneLabel">{item.name}</span>}
      <InputMask
        className={`phonenumber ${id === 'phonenumber' && 'activeInput'}`}
        value={value}
        mask="+7 (999) 999 99 99"
        alwaysShowMask
        onClick={() => clickInput(id)}
      />
    </InputGroup>
  );
}

function TextInput({
 item, clickInput, id, value, style,
}) {
  return (
    <InputGroup input={style} lab={!item.name}>
      {item.name && <span>{item.name}</span>}
      <input
        type="text"
        readOnly
        onClick={() => clickInput(id)}
        value={value}
      />
    </InputGroup>
  );
}

function DateInput({
 item, clickInput, id, value, style,
}) {
  return (
    <InputGroupModif input={style}>
      {item.name && <span className="phoneLabel">{item.name}</span>}
      <InputMask
        className={`phonenumber ${id === 'phonenumber' && 'activeInput'}`}
        value={value}
        mask="99.99.9999"
        alwaysShowMask
        onClick={() => clickInput(id)}
      />
    </InputGroupModif>
  );
}

function DefaultInput({
  item, clickInput, id, value, style,
}) {
  return (
    <InputGroup name={item.name} input={style}>
      {/* eslint-disable-next-line */}
      {item.name && (<span>{item.name}:</span>)}
      <input
        type="number"
        readOnly
        onClick={() => clickInput(id)}
        value={value}
      />
    </InputGroup>
  );
}