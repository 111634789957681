import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import defaultFilters from '../../../components/ReportFilters/utils/defaultFilters';
import TableConfig from './TableConfig';
import PrepareTable from '../../../components/PrepareTable';
import ReportFilters from '../../../components/ReportFilters/ReportFilters';

function ClockwiseTable(props) {
  const [queryParams, setQueryParams] = useState();
  const { preloader } = props;

  const preparQueryParams = async (filters) => {
    const columns = TableConfig(); // Колонки для таблиц
    const params = {
      columns: columns.columns,
      filters: filters || defaultFilters(),
      url: '/api/reports/build',
      type: 'clockwiseReport',
    };
    setQueryParams(params);
  };

  useEffect(() => {
    preparQueryParams();
  }, [props]);

  return (queryParams ? (
    <>
      <ReportFilters
        showFilters={{
          main: ['reportRange', 'operators', 'workplaces', 'queues'],
          others: [],
          orders: ['ndate', 'all_cnt'],
        }}
        paramsCallback={preparQueryParams}
        type="clockwiseReport"
      />
      <PrepareTable
        keys="ndate"
        type="OperatorTable"
        {...queryParams}
        preloader={preloader}
      />
    </>
  ) : null);
}

function mapStateToProps(state) {
  return { reportConfig: state.reportConfig };
}

export default connect(mapStateToProps)(ClockwiseTable);
