import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ctx from '../utils/ctx';
import Preloader from './preloader';

function Times() {
  const {
    times, timeId, dispatch, queue,
  } = useContext(ctx);
  const navigate = useNavigate();

  useEffect(() => { if (!queue.id) navigate('/preentry'); }, [queue.id]);

  const goBack = () => {
    dispatch({ type: 'day', value: null });
    dispatch({ type: 'timeId', value: { timeId: null, time: null } });
    navigate(-1);
  };

  const onClick = (id, time) => {
    dispatch({ type: 'timeId', value: { timeId: id, time } });
    navigate('/preentry/user');
  };
  const finList = Object.keys(times)
    .map((el) => ({ time: times[el].calendarCellTime, id: times[el].calendarCellId }))
    .sort((a, b) => {
      const hA = +a.time.split(':')[0];
      const hB = +b.time.split(':')[0];
      const mA = +a.time.split(':')[1];
      const mB = +b.time.split(':')[1];
      if (hA === hB) {
        return mA > mB ? 1 : -1;
      }
      return hA > hB ? 1 : -1;
    });

  const isActive = times.find((time) => +time.calendarCellId === +timeId);

  return (
    <>
      <h3>
        <span onClick={goBack}>Назад</span>
        Выберите время
      </h3>
      {finList.length > 0 ? (
        <div className="timeBox">
          <ul>
            {finList.map((el) => (
              <li
                onClick={() => onClick(el.id, el.time)}
                className={`item ${isActive && isActive.calendarCellTime === el.time && 'active'}`}
                key={el.id}
              >
                {el.time}
              </li>
            ))}
          </ul>
        </div>
      ) : <Preloader />}
    </>
  );
}

export default Times;
