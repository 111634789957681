import React, { useCallback, useEffect, useState } from 'react';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InfoScreenEditor from '../InfoScreenEditor';
import Table from '../../../../../../../pages/private/components/table/Table';
import Config from './TableConfig';
import { BtnAdd } from '../../../../../../../common/ui/Common';
import randomId from '../../../../../../../logic/utils/randomId';
import request from '../../../../../../../logic/utils/request';

function BottomScreen({
  showError, screen, changeScreen, updateScreen, noText, screenList,
}) {
  const [queue, setQueue] = useState([]);

  const show = screen.type !== 'infoScreen';

  const fetch = useCallback(() => {
    try {
      const f = async () => {
        const res = await request('/server/api/queue/', 'get', {});
        setQueue(res.data.data);
      };
      f();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, []);

  useEffect(() => fetch(), [fetch]);

  const handleTableIcon = (e, row) => {
    const { name } = e.target.dataset;
    if (name === 'del') {
      screen.bottomBlock.buttons = screen.bottomBlock.buttons.filter((item) => item.id !== row.id);
      updateScreen(true);
    }
  };

  const afterSaveCell = (oldValue, newValue, row, column) => {
    screen.bottomBlock.buttons = screen.bottomBlock.buttons.map((item) => {
      if (item.id === row.id) {
        return { ...row, [column.dataField]: newValue };
      }
      return item;
    });
    updateScreen(true);
  };
  const { buttons } = screen.bottomBlock;

  const addBtn = () => {
    const buttonData = {
      name: `Название ${buttons.length + 1}`,
      size: 'lg',
      path: '',
      params: { name: 'queue', value: 0 },
      id: randomId(5),
      position: buttons.length + 1,
    };
    screen.bottomBlock.buttons.push(buttonData);
    updateScreen(true);
  };

  const { columns } = Config({
    screen, queue, screenList, noText,
  });
  return (
    <div style={{ marginTop: 100 }}>
      {!noText && <h2>Настройка нижнего блока</h2>}
      {show
        && (
        <>
          <h4>Кнопки</h4>
          <BtnAdd onClick={addBtn} add>
            <span>Добавить</span>
          </BtnAdd>
          {queue.length > 0 && (
          <Table
            keys="id"
            data={buttons || []}
            columns={columns}
            cellEdit={cellEditFactory({
              mode: 'click',
              blurToSave: true,
              afterSaveCell,
            })}
            handleIcon={handleTableIcon}
            pagination={paginationFactory()}
          />
          )}
        </>
        )}
      <h4>Верхний текст</h4>
      <InfoScreenEditor data={screen.bottomBlock.topText} setScreen={(value) => changeScreen(value, 'topText')} selector="topText" showError={showError} />
      <h4>Нижний текст</h4>
      <InfoScreenEditor data={screen.bottomBlock.bottomText} setScreen={(value) => changeScreen(value, 'bottomText')} selector="bottomText" showError={showError} />
    </div>
  );
}

export default BottomScreen;
