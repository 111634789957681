import { IconLogin, IconPass } from '../common/icons';

export function useStyleAndAnimations({
  alphaM,
  terminals,
  sueo,
  userLogin,
  userPasswd,
  animationForm,
  setAnimationForm,
}) {
  const mouseMove = (e) => {
    const mx = e.clientX;
    const wx = window.innerWidth;
    const x = (mx * 100) / wx / 100 - 0.5;
    const arr = [
      { el: alphaM.current, anim: 10 },
      { el: terminals.current, anim: 25 },
      { el: sueo.current, anim: 40 },
    ];

    arr.forEach((item) => { item.el.style.transform = `translate(calc(-50% - ${x * item.anim}px), -50%)`; });
  };

  const items = [
    {
      label: <IconLogin />, name: 'userLogin', value: userLogin || '',
    },
    {
      label: <IconPass />, name: 'userPasswd', type: 'password', value: userPasswd || '',
    },
  ];

  const modalStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  return {
    mouseMove, animationForm, setAnimationForm, items, modalStyle,
  };
}

export default null;
