import React, { useState } from 'react';
import {
  Button, Card, Col, Divider, Image, Popconfirm, Row, Tooltip,
} from 'antd';
import Meta from 'antd/lib/card/Meta';
import { CSSTransition } from 'react-transition-group';
import { DeleteFilled } from '@ant-design/icons';
import ButtonForm from '../ButtonForm';
import zoneStore, { updateZoneStore } from '../../../Store';
import PopConfirmButton from './PopConfirmButton';
import defaultValues from '../../../../utils/defaultValues';
import queueStore from '../../../Store/queueStore';

function ScreenButton(props) {
  const {
    button, screen, updateZone,
  } = props;

  const screens = zoneStore.useState((s) => s.config.screens);

  const [isEditing, setIsEditing] = useState(false);
  const [showButtonInfo, setShowButtonInfo] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  const doNotConfirm = Boolean(localStorage.getItem('doNotConfirmDeleteScreenButton'));

  const animationTimeout = 300;

  const deleteButton = () => {
    if (isChecked) {
      localStorage.setItem('doNotConfirmDeleteScreenButton', true);
    }
    const newButtons = screen.buttons.filter(({ id }) => id !== button.id);
    const updatedScreen = { ...screen, buttons: [...newButtons] };
    const newScreens = screens.map((item) => {
      if (item.screenId === updatedScreen.screenId) {
        return updatedScreen;
      }
      return item;
    });
    const { config } = zoneStore.getRawState();
    updateZoneStore({ ...zoneStore.getRawState(), config: { ...config, screens: newScreens } });
    updateZone('Кнопка удалена');
  };

  const renderButtonPath = () => {
    if (button.params.value) {
      return (
        <>
          <Divider />
          <Row justify="center">
            <Col>
              {button.params.value && (
              <div style={{ textAlign: 'center' }}>
                Очередь -
                {' '}
                <b>
                  {queueStore.getRawState().queue.find((item) => item.id
                  === button.params.value)?.fullname}
                </b>
              </div>
              )}
            </Col>
          </Row>
        </>
      );
    }
    const otherAction = defaultValues.buttonActions.find((item) => item.id === button.path);
    if (otherAction) {
      return (
        <>
          <Divider />
          <Row justify="center">
            <Col>
              <div style={{ textAlign: 'center' }}>
                <b>
                  {otherAction.name}
                </b>
              </div>
            </Col>
          </Row>
        </>
      );
    }
    if (button.path) {
      return (
        <>
          <Divider />
          <Row justify="center">
            <Col>
              <div style={{ textAlign: 'center' }}>
                <span>Экран - </span>
                <b>
                  {screens.find((item) => item.screenId === button.path)?.headTitle}
                </b>
              </div>
            </Col>
          </Row>
        </>
      );
    }
    return null;
  };

  return (
    <Card
      hoverable={!isEditing}
      key={button.id}
      style={{
        margin: '0 auto 15px auto',
        width: showButtonInfo ? '100%' : '400px',
        transition: '.7s ease',
      }}
      onClick={isEditing ? () => {} : () => setIsEditing(true)}
    >
      <CSSTransition
        in={showButtonInfo}
        timeout={!isEditing ? animationTimeout : 0}
        unmountOnExit
        classNames="button-info"
      >
        <>
          <Row justify="space-between" align="middle">
            {button.icon && (
            <Col span={4}>
              <Image src={button.icon} onClick={(e) => e.stopPropagation()} />
            </Col>
            )}
            <Col span={14}>
              <Meta title={button.name} description={button.extraText} className="button-name" />
            </Col>
            <Col span={5} onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                title={(
                  <PopConfirmButton
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                  />
                )}
                onConfirm={deleteButton}
                onCancel={() => setIsChecked(false)}
                disabled={doNotConfirm}
              >
                <Tooltip title="Удалить кнопку">
                  <Button
                    type="primary"
                    danger
                    onClick={doNotConfirm ? deleteButton : () => {}}
                  >
                    <DeleteFilled />
                  </Button>
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>
          {renderButtonPath()}
        </>
      </CSSTransition>
      <CSSTransition
        in={isEditing}
        timeout={animationTimeout}
        classNames="button-form"
        unmountOnExit
        onEnter={() => setShowButtonInfo(false)}
        onExited={() => setShowButtonInfo(true)}
      >
        <ButtonForm
          button={button}
          setIsEditing={setIsEditing}
          screen={screen}
          updateZone={updateZone}
        />
      </CSSTransition>
    </Card>
  );
}

export default ScreenButton;
