import React from 'react';
import GetCheckbox from './getCheckbox';
import GetButtons from './getButtons';
import GetInput from './getInput';

import {
  BigButtonsContainer, StyleButton, InputButton, InputButtonContainer,
} from '../../utils/style';

const RenderButtons = ({
  index, startScreen, scene, history, style, match, selectScreen,
}) => {
  const {
    bottomButton, button, checkbox,
  } = style;

  console.log('index === ', index);

  const buttons = () => {
    switch (index) {
      case 2:
        return (
          <BigButtonsContainer style={{ overflow: 'auto', alignItems: 'flex-start' }}>
            {' '}
            {/* Рендер мультивыбор */}
            <StyleButton width={checkbox.widthContent} start={startScreen} btns={scene.buttons}>
              <GetCheckbox scene={scene} />
            </StyleButton>
          </BigButtonsContainer>
        );
      case 1:
        return (
          <InputButtonContainer>
            {/* Рендер ввода дополнительной информации */}
            <InputButton start={startScreen} input btns={scene.buttons}>
              <GetInput
                selectScreen={selectScreen}
                scene={scene}
                match={match}
                styleBottom={bottomButton}
                history={history}
                styleButton={button}
              />
            </InputButton>
          </InputButtonContainer>
        );
      default:
        return (
          <BigButtonsContainer style={{ alignItems: button.alignItems }}>
            {' '}
            {/* Рендер кнопок */}
            <StyleButton
              width={button.widthContent}
              style={{
                justifyContent: button.justifyContent,
              }}
              start={startScreen}
              btns={scene.buttons}
            >
              <GetButtons
                selectScreen={selectScreen}
                scene={scene}
                style={button}
                history={history}
              />
            </StyleButton>
          </BigButtonsContainer>
        );
    }
  };

  return buttons();
};

export default RenderButtons;
