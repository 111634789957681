import React from 'react';
import { AppealBox } from '../../../../../../../../common/ui/Common2';

const ShowText = ({ item }) => {
  try {
    const data = JSON.parse(item);
    return (
      <AppealBox style={{ width: '300px' }}>
        {data.map((dItem) => (
          <div className="item" key={dItem.title}>
            <p>{dItem.title}</p>
            <p>{dItem.value}</p>
          </div>
        ))}
      </AppealBox>
    );
  } catch (e) {
    return item;
  }
};

export default ShowText;
