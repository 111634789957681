import React from 'react';
import { TicketVisual, Img } from '../../style';

function DraggableItem({
  ticket, elem, onClick, data,
}) {
  const getText = (text) => {
    if (text) {
      let item = text;
      item = item.replace(/\$FIELD(\d+)\$/g, (match, fvid) => {
        const cFv = data.fv.find((fel) => (fel.fvid === Number(fvid)));
        return cFv ? cFv.dvb : '';
      });
      item = item.replace(/\$DATE\$/g, data.dd);
      item = item.replace(/\$QL\$/g, data.ql);
      item = item.replace(/\$DD\$/g, data.dd);
      item = item.replace(/\$DT\$/g, data.tm);
      item = item.replace(/\$QS\$/g, data.qs);
      item = item.replace(/\$WIN\$/g, data.win);
      item = item.replace(/\$NUM\$/g, data.num);
      item = item.replace(/\$BC\$/g, data.bc);
      return item;
    }

    return null;
  };

  return (
    <TicketVisual
      onClick={() => onClick(elem)}
      t={`translate(${!ticket[elem].align ? ticket[elem].x : -50}${!ticket[elem].align ? 'px' : '%'}, ${ticket[elem].y}px)`}
      a={ticket[elem].align ? '50%' : ''}
      width={elem === 'code'}
    >
      <div
        style={{
          cursor: 'pointer',
          zIndex: 3,
          fontFamily: ticket[elem].fontFamily,
          fontSize: ticket[elem].fontSize,
          position: 'relative',
          fontWeight: 'bold',
        }}
        className="item"
      >
        {ticket[elem].url && ticket[elem].show
          ? (
            <Img
              src={ticket[elem].url}
              height={ticket[elem].height}
              alt=""
            />
          )
          : null}
        {ticket[elem].text !== null && ticket[elem].show
          ? getText(ticket[elem].textInput)
          : null}
      </div>
    </TicketVisual>
  );
}

export default DraggableItem;
