import React from 'react';
import moment from 'moment';
import {
  MountBox, Day, Mount, Title, WeekName, ShadowDiv,
} from './style';

const drawDay = (mount, modifiendDay) => {
  const daysArr = [];
  if (mount[0].dayOfWeek > 1) {
    for (let shadow = 0; shadow < mount[0].dayOfWeek - 1; shadow += 1) {
      daysArr.push(<ShadowDiv key={Math.random()} />);
    }
  }
  mount.forEach((d) => {
    const modifiedDay = d.isDayOfWeekForAllQ;
    // const modifiedDay = d.isHolidayForAllQ // все очереди в этот день не работают отметить
    const weekend = d.isWeekend;
    const mod = false;

    const noWeekEnd = modifiedDay ? mod.name : 'Рабочий';

    const day = (
      <Day
        title={weekend ? 'Выходной' : noWeekEnd}
        weekend={weekend}
        modified={modifiedDay}
        key={`day-${d.dayId}`}
        onClick={() => modifiendDay(d)}
      >
        {d.dayOfMonth}
      </Day>
    );
    daysArr.push(day);
  });
  return daysArr;
};

const DrawCalendar = ({
  cal, modifiendDay,
}) => {
  const getMountText = (i) => {
    const mount = {
      январь: 'Январь',
      февраль: 'Февраль',
      март: 'Март',
      апрель: 'Апрель',
      май: 'Май',
      июнь: 'Июнь',
      июль: 'Июль',
      август: 'Август',
      сентябрь: 'Сентябрь',
      октябрь: 'Октябрь',
      ноябрь: 'Ноябрь',
      декабрь: 'Декабрь',
    };
    return mount[moment(i, 'M').format('MMMM')];
  };

  const arrMount = Object.keys(cal).map((mount) => (
    <MountBox key={`mount${mount}`}>
      <Title>{getMountText(mount)}</Title>
      <WeekName>
        <div>Пн.</div>
        <div>Вт.</div>
        <div>Ср.</div>
        <div>Чт.</div>
        <div>Пт.</div>
        <div>Сб.</div>
        <div>Вс.</div>
      </WeekName>
      <Mount>
        {drawDay(cal[mount], modifiendDay)}
      </Mount>
    </MountBox>
  ));
  return arrMount;
};

export default React.memo(DrawCalendar);
