import React from 'react';
import { useSelector } from 'react-redux';
import { BottomButton, Container } from '../../utils/style';
import { useBottomButtons } from './useBottomButtons';

function BottomButtons(props) {
  const { arrButtons, styleButton } = useBottomButtons(props);

  const { selectScreen } = props;
  const { style } = useSelector((state) => ({
    style: state.style.bottomButton,
  }));
  const {
    position, top, left, textShow, iconShow, contentPosition, iconPosition,
  } = style;

  return (
    <Container
      style={{
        position: !position ? 'absolute' : 'static',
        top,
        left,
        width: !position ? 'min-content' : '100%',
      }}
    >
      {arrButtons.map((el) => (el.show ? (
        <BottomButton
          key={el.id}
          style={{
            ...styleButton,
            width: el.name ? 'min-content' : style.width,
            flexDirection: contentPosition ? 'column' : 'row',
          }}
          onClick={!selectScreen ? el.func : null}
        >
          {el.name
            ? <p>{el.name}</p>
            : (
              <>
                {Boolean(iconShow) && <img style={{ order: iconPosition ? 0 : 1, ...styleButton.icon }} src={el.icon} alt="" />}
                {Boolean(textShow) && <p style={{ order: !iconPosition ? 0 : 1 }}>{el.text}</p>}
              </>
            )}
        </BottomButton>
      ) : null))}
    </Container>
  );
}

export default BottomButtons;
