import styled, { keyframes } from 'styled-components';

export const BoardStyleBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LayoutsBox = styled.div`
  padding: 35px 0;
  width: 100%;
  > div {
    margin-bottom: 15px;
    display: flex;
  }
  h3 {
    margin-bottom: 30px;
  }
`;

export const Btn = styled.button`
  cursor: pointer;
  padding: 5px 15px;
  background: ${(p) => (p.active ? p.theme.accent : 'rgba(0, 0, 0, .1)')};
  box-shadow: ${(p) => (p.active ? 'none' : '0 1px 3px rgba(0,0,0,.1), 0 2px 4px rgba(0,0,0,.05)')};
  border: 0;
  color: ${(p) => (p.active ? p.theme.buttonHover : p.theme.text)};
  margin: 0 5px;
  font-size: 14px;
  flex: 1;
  min-height: 35px;
  word-wrap: normal;
  flex-grow: 1;
  white-space: nowrap;
  position: relative;
  clip-path: polygon(0 0, calc(100% - 15px) 0,100% 15px,100% 100%, 0 100%);
  p {
    /* transition: max-width 0.1s ease-out; */
    position: absolute;
    top: 1px;
    font-weight: normal;
    left: 1px;
    max-width: 100%;
    right: 1px;
    background: ${(p) => (p.active ? p.theme.accent : p.theme.bg)};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 15px;
    overflow: hidden;
    bottom: 1px;
    clip-path: polygon(0 0, calc(100% - 14px) 0,100% 14px,100% 100%, 0 100%);
    span {
      text-overflow: ellipsis;
      white-space: nowrap; /* Запрещаем перенос строк */
      overflow: hidden;
    }
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background: ${(p) => p.theme.accent};
    p {
      background: ${(p) => p.theme.accent};
      color: ${(p) => p.theme.buttonHover};
    }
  }
`;

export const BoardBox = styled.div`
  * {
    font-family: ${(props) => props.ff || 'Roboto'};
  }
`;

export const LabelTemplate = styled.label`
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  span {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;
export const LayoutSettingBox = styled.div`
  background: ${(p) => p.theme.bgTable};
  display: flex;
  flex-direction: column;
  padding: 15px 30px 30px;
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 0 15px rgba(0, 0, 0, 0.1);
  h2 {
    font-weight: bold;
    text-transform: uppercase;
    color: ${(p) => p.theme.accent};
    text-align: center;
    margin: 15px 0;
  }
`;

export const Cell = styled.div``;

function animationHover(color) {
  return keyframes`
    0% {
      box-shadow: 0 0 0 ${color};
    }
    100% {
      box-shadow: 0 0 0 3px ${color};
    }
  `;
}

export const BoardElementsBox = styled.div`
  display: flex;
  width: 100%;
  color: ${(p) => p.theme.text};
  margin: 0 -5px;
  flex-wrap: wrap;
`;

export const Title = styled.h4`
  margin: 30px 0 0 15px;
  text-transform: uppercase;
  color: ${(p) => p.theme.accent}
`;

export const StyleSettingsBox = styled.div`
  display: flex;
  justify-content: ${(p) => (p.center ? 'center' : 'flex-start')};
  flex-wrap: wrap;
  color: ${(p) => p.theme.lightText};
  width: calc(100% - 10px);
  margin-bottom: 120px;
  border-top: none;
  > div {
    padding-right: 15px;
  }
  > label {
    flex: 1;
    min-width: calc(25% - 20px);
    max-width: calc(25% - 20px);
    width: calc(25% - 30px);
    font-size: 14px;
    margin: 10px;
    padding: 5px;
    span {
      display: flex;
      margin: 5px 0;
    }
    input,
    select,
    textarea {
      width: 100%;
      height: 40px;
      font-size: 14px;
    }
    textarea {
      height: 80px;
      resize: none;
      border: 1px solid rgba(0,0,0,.1);
      padding: 10px 15px;
      background: ${(p) => p.theme.bg};
      color: ${(p) => p.theme.text};
      &:focus {
        outline: none;
        border-bottom: 2px solid ${(p) => p.theme.green};
      }
    }
  }
  .color_picker__box {
    width: 100%;
  }
`;
export const SettingLogo = styled.div`
  flex: 1;
  min-width: calc(25% - 20px);
  max-width: calc(25% - 20px);
  margin: ${(p) => (p.button ? '0' : '10px')};
  padding: ${(p) => (p.button ? '0' : '5px')};
  font-size: 14px;
  input {
    display: none;
  }
  span {
    display: flex;
    margin: 5px 0;
  }
  div {
    display: flex;
    width: 100%;
    height: 40px;
    font-size: 14px;
  }
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => p.theme.accent};
    color: ${(p) => p.theme.white};
    margin: 0;
    margin-right: ${(p) => p.mgr || 0};
    cursor: pointer;
    &:hover {
      animation: ${animationHover((p) => p.theme.accentl)} 0.3s ease-out;
    }
  }
`;

export const Label = styled.label`
  min-width: ${(p) => (p.delete ? '40px' : 'auto')} !important;
  flex: ${(p) => (p.delete ? 0 : 1)};
  color: ${(p) => p.theme.buttonHover};
  max-width: ${(p) => (p.full ? '120px' : '220px')}!important;
  transition: 0.5s;
`;

export const AlignBox = styled.div`
  position: ${(p) => (p.absolute ? 'absolute' : 'static')};
  top: ${(p) => (p.bottom ? 'auto' : 0)};
  bottom: ${(p) => (p.bottom ? '40px' : 'auto')};
  display: flex;
  width: ${(p) => p.width || '140px'};
  z-index: 3;
  flex-wrap: wrap;
  overflow: ${(p) => (p.hidden ? 'hidden' : 'visible')};
  border: 1px solid rgba(0,0,0,.1);
  background: ${(p) => p.theme.bg};
  input {
    width: 100% !important;
  }
`;

export const InputMarginBox = styled.div`
  position: relative;
  width: 100%;
  &:hover {
    .absoluteDescription {
      max-width: 100%;
      padding: 12px 15px;
      color: ${(p) => p.theme.buttonHover};
      opacity: 1;
    }
  }
  .absoluteDescription {
    overflow: hidden;
    z-index: 2;
    transition: 0.15s linear;
    opacity: 0;
    padding: 10px 0;
    position: absolute;
    max-width: 0;
    background-color: ${(p) => p.theme.accent};
    color: ${(p) => p.theme.buttonHover};
    top: 0;
    height: 100%;
    left: 100%;
  }
`;

export const InputMargin = styled.input`
  border: 1px solid transparent;
  margin-bottom: ${(p) => p.margin || 0};
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding: 10px 15px;
  background: ${(p) => p.theme.bg};
  width: ${(p) => p.width || 'auto'};
  color: ${(p) => p.theme.text};
  text-align: center;
  outline: none;
  &:focus {
    border-bottom: 2px solid ${(p) => p.theme.green};
  }
`;

export const AlignItem = styled.div`
   {
    width: 33.3%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 40px;
    color: ${(p) => (p.active ? p.theme.accent : p.theme.disable)};
    &:hover {
      color: ${(p) => p.theme.accent};
    }
  }
`;

export const ButtonChange = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(p) => p.width || '180px'};
  cursor: pointer;
  height: 40px;
  background-color: ${(p) => (p.active ? p.theme.accent : p.theme.bg)};
  color: ${(p) => (p.active ? p.theme.buttonHover : p.theme.text)};
  border: 1px solid rgba(0,0,0,.1);
`;
