import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Checkbox,
} from 'antd';
import defaultFilters from '../../../components/ReportFilters/utils/defaultFilters';
import TableConfig from './TableConfig';
import { getReportConfig } from '../../../../../../../../logic/store/redux/reports';
import PrepareTable from '../../../components/PrepareTable';
import ReportFilters from '../../../components/ReportFilters/ReportFilters';
import { getColumnSearchProps } from './getColumnSearchProps';

function WorkPlaceTable(props) {
  const [queryParams, setQueryParams] = useState();
  const { dispatch, preloader } = props;
  const { reportConfig } = useSelector((s) => s);

  const [hiddenZeroValues, setHiddenZeroValues] = useState(
    localStorage.getItem('hiddenZeroValues') ? localStorage.getItem('hiddenZeroValues') : true,
  );

  const moreInfo = useCallback((row, status, type) => {
    dispatch(getReportConfig(
      {
        ...reportConfig,
        reportType: 'ticketReport',
        rootReport: reportConfig.reportType,
        renderBackButton: true,
        rootTable: type,
        buttonType: status,
        activeRow: row,
      },
    ));
  }, [reportConfig, dispatch]);

  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const input = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const preparQueryParams = async (filters) => {
    const columns = TableConfig(
      moreInfo,
      getColumnSearchProps(input, handleSearch, handleReset, searchedColumn, searchText),
    ); // Колонки для таблиц
    const params = {
      columns: columns.columns,
      filters: filters || defaultFilters(),
      url: '/api/reports/build',
      type: 'workPlaceQuery',
    };
    setQueryParams(params);
  };

  useEffect(() => {
    preparQueryParams();
  }, []);

  return (queryParams ? (
    <>
      <ReportFilters
        showFilters={{
          main: ['reportRange', 'workplaces'],
          others: ['servedfilters', 'waitTimeFilters', 'procTimeFilter'],
          orders: ['workplace_name', 'all_cnt', 'close_cnt', 'neyavka_cnt', 'total_wait_time', 'avg_wait_time', 'total_proc_time', 'avg_proc_time'],
        }}
        hiddenZeroValues={hiddenZeroValues}
        paramsCallback={preparQueryParams}
        type="workPlaceQuery"
      />
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '10px 0',
      }}
      >
        <Checkbox
          defaultChecked={hiddenZeroValues}
          onChange={(e) => {
            setHiddenZeroValues(e.target.checked);
            localStorage.setItem('hiddenZeroValues', e.target.checked);
          }}
        >
          Скрыть нулевые значения
        </Checkbox>
      </div>

      <PrepareTable
        hiddenZeroValues={hiddenZeroValues}
        keys="workplace"
        type="WorkPlace"
        {...queryParams}
        preloader={preloader}
      />
    </>
  ) : null);
}

export default WorkPlaceTable;
