import React from 'react';
import TimerSchedule from './TimerSchedule';
import News from './News';
import Video from './Video';
import Schedule from './Schedule';

function VideoNewsSchedule({
  inSoundAction, styles, text, setActiveEl,
}) {
  console.log('styles === ', styles);
  const orderBox = styles.video.order === 1 ? 0 : 1;
  const orderVideo = styles.video.order === 1 ? 1 : 0;
  const orderSchedule = styles.news.order === 1 ? 1 : 0;
  return (
    <div className="layout__body__info_box">
      {(styles.tidings || styles.date) && (
      <div className="layout__body__info__top" style={{ order: orderBox, flexDirection: styles.news.flexDirection ? 'column' : 'row' }}>
        {styles.tidings
          && (
          <News
            text={text}
            setActiveEl={setActiveEl}
            layout={styles}
          />
          )}
        {styles.date && (
        <div
          className="layout__body__info__top__schedule_box"
          style={{
            ...styles.time,
            flexDirection: styles.time.flexDirection ? 'column' : 'row',
            minHeight: styles.time.height2,
            maxHeight: styles.time.height2 === 'auto' ? '100%' : styles.time.height2,
            height: styles.time.height2,
            background: styles.time.background,
            borderRadius: styles.borderRadius ? '12px' : 0,
            border: `${styles.time.borderSize1}px solid ${styles.time.color3}`,
            order: orderSchedule,
          }}
        >
          <TimerSchedule
            styles={styles.time}
            setActiveEl={setActiveEl}
          />
          <Schedule
            styles={styles.time}
            direction={styles.time.flexDirection}
            text={text.time1}
            setActiveEl={setActiveEl}
          />
        </div>
        )}
      </div>
      )}
      {styles.vid
      && (
      <Video
        order={orderVideo}
        inSoundAction={inSoundAction}
        layout={styles}
        text={text.video1}
        setActiveEl={setActiveEl}
      />
      )}
    </div>
  );
}

export default VideoNewsSchedule;
