import React from 'react';
import { ReactComponent as Trash } from '../../../../../common/images/bin.svg';
import { FontList } from '../../../../../common/ui/Common';

function List({ data = [], onDelete }) {
  return (
    <FontList news>
      <p>Список загруженных бегущих строк</p>
      <ul>
        {data?.map((el) => (
          <li key={el.id}>
            <div className="content">
              <p>{el.text}</p>
            </div>
            <div>
              <span onClick={() => onDelete(el.id)}><Trash /></span>
            </div>
          </li>
        ))}
      </ul>
    </FontList>
  );
}

export default List;
