import React from 'react';
import Color from './modules/color';

const Colors = ({ arr, dispatch, width }) => (
  arr.map((el) => (
    <Color
      width={width}
      element={el.parentName}
      value={el.value}
      dispatch={dispatch}
      rusName={el.title}
      paramName={el.paramName}
      key={el.title}
    />
  ))
);

export default Colors;
