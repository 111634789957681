import React, { useEffect, useContext } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import ctx from '../utils/ctx';

function User({ history }) {
  const {
    queue, user, phone, email, dispatch, createTicket,
  } = useContext(ctx);
  const navigate = useNavigate();

  useEffect(() => { if (!queue.id) navigate('/preentry'); }, queue.id);

  const emailValid = /\S+@\S+\.\S+/.test(email);
  const phoneValid = phone.length === 11;

  const valid = user && phoneValid && emailValid;

  const goBack = () => {
    dispatch({ type: 'timeId', value: { timeId: null, time: null } });
    history.goBack();
  };

  const contacts = [
    {
      label: 'Ваше ФИО:', param: 'user', value: user, type: 'text',
    },
    {
      label: 'Ваше E-mail:', param: 'email', value: email, type: 'email',
    },
    {
      label: 'Ваш телефон:', param: 'phone', type: 'phone', maxLength: '14', pattern: '[0-9]{1} [0-9]{3} [0-9]{3} [0-9]{4}',
    },
  ];

  return (
    <>
      <h3>
        <span onClick={goBack}>Назад</span>
        Контактные данные
      </h3>
      <form className="form">
        {contacts.map((el) => (
          <div key={el.label} className="inputGroup">
            <p>
              {el.label}
              <span>*</span>
            </p>
            {el.type === 'phone'
              ? (
                <PhoneInput
                  country="ru"
                  value={phone}
                  onChange={(value) => dispatch({ type: 'phone', value })}
                  isValid={(value) => value.length === 11}
                  inputProps={{
                    name: 'phone',
                    required: true,
                  }}
                />
              )
              : (
                <input
                  {...el}
                  onChange={(e) => dispatch({ type: el.param, value: e.target.value })}
                  required
                />
              )}
          </div>
        ))}
      </form>
      <div className="flex">
        {valid && <div className="nextButton" onClick={createTicket}> Записаться </div>}
      </div>
    </>
  );
}

export default User;
