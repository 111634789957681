import styled from 'styled-components';

export const KeyboardBox = styled.div`
    flex: 1;
    width: 100%;
  `;

export const KeyboardStyle = styled.div`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0;
    `;

export const KeyboardNumber = styled.div`
    max-width: 600px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0;
    flex: 1;
    `;

export const InputGroup = styled.div`
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;

export const KeyboardLine = styled.div`
    display: flex;
    flex: 1;
    min-width: 100%;
    justify-content: center;
    margin: 0 auto;
  `;
