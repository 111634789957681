import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store/store';
import './fonts/fonts.css';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
      error,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong.</h1>
          <div>{this.state.error.stack.split(' at ').map((line) => <div key={line}>{line}</div>)}</div>
          {/* <div>{this.state.info}</div> */}
        </div>

      );
    }
    return this.props.children;
  }
}

function Terminal({
  id, config, screen,
}) {
  const { id: paramsId } = useParams();

  return (
    <div
      className="devices__preview_box"
      style={{
        transformOrigin: 'left top',
      }}
    >
      <Provider store={store}>
        <ErrorBoundary>
          <App oldConfig={config} screen={screen} id={id || paramsId} />
        </ErrorBoundary>
      </Provider>
    </div>
  );
}

export default React.memo(Terminal);
