import React, { useState } from 'react';
import Modal from '../../../../../common/ui/Modal/Modal';
import request from '../../../../../logic/utils/request';
import RenderInputs from './RenderInputs';
import ModalButtons from '../../../../../common/ui/Modal/ModalButtons';
import AddButton from '../../../../../pages/private/utils/AddButton';

function NewUser({
  isOpen, toggleOpen, fetchUsers, userUpdate, setUserData,
  userData, initialData, setUserUpdate, preloader, showError, roles, write,
}) {
  const [isValid, setIsValid] = useState(false);

  const resetModal = () => {
    setUserData({ ...initialData });
    toggleOpen();
    setUserUpdate(false);
    setIsValid(false);
  };

  const addUser = async () => {
    if (isValid) {
      preloader();
      try {
        await request('/server/api/user/', 'add', { ...userData });
        fetchUsers();
      } catch (err) {
        showError(err.data.error);
      }
      resetModal();
      preloader();
    }
  };

  const updateUser = async () => {
    if (isValid) {
      const itemId = userData.id;
      console.log('userData === ', userData);
      // eslint-disable-next-line no-param-reassign
      delete userData.id;
      try {
        await request(`/server/api/user/${itemId}/`, 'update', { ...userData });
      } catch (e) {
        showError(e.data.error);
      }
      fetchUsers();
      resetModal();
    }
  };

  return (
    <>
      <AddButton toggle={toggleOpen} />
      <Modal title={userUpdate ? 'Редактирование пользователя' : 'Добавление Пользователя'} isOpen={isOpen} toggleOpen={resetModal}>
        <RenderInputs
          userData={userData}
          setUserData={setUserData}
          setIsValid={setIsValid}
          preloader={preloader}
          write={write}
          userUpdate={userUpdate}
          roles={roles}
        />
        <ModalButtons
          isValid={isValid}
          update={userUpdate}
          updateFunc={updateUser}
          addFunc={addUser}
          reset={resetModal}
        />
      </Modal>
    </>
  );
}

export default React.memo(NewUser);
