const config = {
};

export default function configReducer(state = config, action) {
  const { type, value, name } = action;
  switch (type) {
    case 'SET-CONFIG': {
      return {
        ...state,
        ...value,
      };
    }
    case 'SET-WS': {
      return {
        ...state,
        serverConnect: value,
      };
    }
    case 'SET-ERROR': {
      return {
        ...state,
        errorText: value,
      };
    }
    case 'SET-CONFIRM': {
      return {
        ...state,
        confirmText: value,
      };
    }
    case 'CHANGE-CONFIG': {
      return {
        ...state,
        [name]: value,
      };
    }
    default:
      return state;
  }
}
