import React, { useEffect, useRef, useState } from 'react';
import {
  Table, Button, Input, Space, Switch, Divider, Typography, Form,
} from 'antd';
import locale from 'antd/es/locale/ru_RU';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import TableConfig from './TableConfig';
import Answers from '../Answers/Answers';
import request from '../../../../../../logic/utils/request';
import NewQuestionForm from './NewQuestionForm';

const { Text } = Typography;

const SortableItem = SortableElement((props) => (
  <tr {...props} />
));
const SortableBody = SortableContainer((props) => (
  <tbody {...props} />
));

function Questions(props) {
  const { data, getData } = props;
  const [edditPosition, setEdditPosition] = useState(false);
  const {
    connectedQuestions: dConnectedQuestions, questiontext, surveyname, id: surveyId,
  } = data;

  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();

  const input = useRef(null);
  const [connectedQuestions, setConnectedQuestions] = useState([]);
  const [newQuestionText, setNewQuestionText] = useState(questiontext);

  useEffect(() => {
    dConnectedQuestions.sort((a, b) => a.position - b.position);

    dConnectedQuestions.forEach((el, index) => {
      el.position = index;
    });
    setConnectedQuestions(dConnectedQuestions);
  }, [data]);

  const [edditableRow, setEdditableRow] = useState(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex, searchItemText) => ({
    // eslint-disable-next-line react/no-unstable-nested-components
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={input}
          placeholder={`Найти ${searchItemText}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Очистить
          </Button>
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => input.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const edditCansel = () => {
    setEdditableRow(null);
  };

  const edditRow = (row) => {
    setEdditableRow(row.id);
  };

  const saveQuestionText = async () => {
    let currentAswers = [];

    connectedQuestions.forEach((el) => {
      if (el.id === edditableRow) {
        currentAswers = el.answers;
        el.questiontext = newQuestionText;
      }
    });

    const newData = {
      questiontext: newQuestionText,
      correctAnswers: currentAswers,
    };
    try {
      await request(`/server/api/qcquestion/${edditableRow}/`, 'update', newData);
      setConnectedQuestions(connectedQuestions);
      setEdditableRow('');
      getData();
    } catch (e) {
      console.log('e === ', e);
    }
  };

  const deleteQuestion = async (id) => {
    const newTableData = [...connectedQuestions];
    const deletedAnswerIndex = connectedQuestions.map((x) => x.id).indexOf(id);
    newTableData.splice(deletedAnswerIndex, 1);

    newTableData.forEach((el, index) => {
      el.position = index;
    });

    await request(`/server/api/qcquestion/${id}/`, 'delete');
    setConnectedQuestions(newTableData);
    getData();
  };

  const columns = TableConfig(
    getColumnSearchProps,
    edditRow,
    edditableRow,
    edditCansel,
    saveQuestionText,
    setNewQuestionText,
    newQuestionText,
    deleteQuestion,
    edditPosition,
  );

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(connectedQuestions.slice(), oldIndex, newIndex).filter(
        (el) => !!el,
      );

      const questionPisition = [];

      const questionsData = newData.map((el, index) => ({
        ...el,
        position: index,
      }));

      questionsData.forEach((currentQuestion, index) => {
        questionPisition.push({
          question_id: currentQuestion.id,
          position: index,
        });
      });

      const dataUpdate = {
        surveyname,
        connectedQuestions: questionPisition,
      };

      await request(`/server/api/qcsurvey/${surveyId}/`, 'update', dataUpdate);
      setConnectedQuestions(questionsData);
      getData();
    }
  };

  // eslint-disable-next-line
  function DraggableContainer(props) {
    return (
      <SortableBody
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  }

  // eslint-disable-next-line
    function DraggableContainer(props) {
    return (
      <SortableBody
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  }
  // eslint-disable-next-line
 function DraggableBodyRow({ className, style, ...restProps }) {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = connectedQuestions.findIndex((x) => x.position === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  }

  const onChange = (checked) => {
    setEdditPosition(checked);
  };

  return (
    <>
      <NewQuestionForm
        setConnectedQuestions={setConnectedQuestions}
        connectedQuestions={connectedQuestions}
        surveyData={data}
        getData={getData}
      />

      <Form.Item
        label="Редактировать сортировку"
        valuePropName="checked"
        style={{
          marginTop: '18px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Switch defaultChecked={edditPosition} disabled={edditableRow} onChange={onChange} />
      </Form.Item>

      <Table
        style={{ marginTop: '24px' }}
        rowKey={edditPosition ? 'position' : 'id'}
        dataSource={connectedQuestions}
        columns={columns.columns}
        size="middle"
        bordered
        locale={locale.Table}
        pagination={false}
      // eslint-disable-next-line react/no-unstable-nested-components
        expandIcon={({ expanded, onExpand, record }) => (expanded ? (
          <Button size="large" disabled={edditPosition} onClick={(e) => onExpand(record, e)}>Закрыть ответы</Button>
        ) : (
          <Button size="large" disabled={edditPosition} onClick={(e) => onExpand(record, e)}>Показать ответы</Button>
        ))}
        expandable={{
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (record) => <Answers data={record} />,
        }}
        components={
            edditPosition ? {
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            } : {}
        }

      />
      <Divider />
      <Text type="secondary">
        ** Обратите внимание, что порядок сортировки в этой таблице,
        влияет на отображение данных при дальнейшем использовании
      </Text>

    </>

  );
}

export default Questions;
