import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import colorDark from './common/ui/theme/ColorDark';
import colorLight from './common/ui/theme/Colors';
import getParamsFromUrl from './logic/utils/getParamsFromUrl';
import { saveInLocalStorage } from './pages/public/webservice/utils';
import request from './logic/utils/request';

export function useApp() {
  const theme = window.localStorage.getItem('theme');
  const [color, dispatch] = useReducer((state, action) => action, theme === 'dark' ? colorDark : colorLight);

  const urlParams = getParamsFromUrl(window.location.href);

  const { token } = urlParams;
  if (token) {
    const checkToken = async () => {
      const res = await axios.post(`/server/api/license/?action=get&token=${token}`);
      const getParams = request('/server/api/officeinfo/');
      getParams.then((paramsRes) => {
        const paramsData = paramsRes.data.data;
        paramsData.forEach((el) => {
          if (el.param === 'officeName') {
            window.localStorage.setItem('officeName', el.value);
            document.title = `${el.value} | AlphaQ`;
          }
        });
      });
      if (res.statusText === 'OK') {
        saveInLocalStorage({
          token,
          fio: 'Пользователь ЕСМ',
          login: 'ESM',
          role: 'eq_root',
        });
      }
    };
    checkToken();
  }

  const [ctx, setCtx] = useState({ color, dispatch });

  useEffect(() => {
    setCtx({ color, dispatch });
  }, [color, dispatch]);

  return {
    ctx,
    color,
  };
}

export default null;
