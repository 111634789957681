import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import 'moment/locale/ru';
import DounhnutsColorMap from '../../../../../../utils/DounghnutsColorMap';

function AvgWaitTimeDoughnut({ data: items }) {
  const [ReportParams, setReportParams] = useState();
  const { reportConfig } = useSelector((state) => state);

  const answerFnc = useCallback(() => {
    const AvgProcLabels = [];
    const AvgProcData = [];
    let allCntAvgProcDoughnut = 0;

    items.forEach((el) => {
      if (el[1] > 0) {
        AvgProcLabels.push(el[0]);
        AvgProcData.push(el[1]);
        allCntAvgProcDoughnut += Number(el[1]);
      }
    });

    const avgProcTime = allCntAvgProcDoughnut;
    const color = DounhnutsColorMap(AvgProcData.length);

    const testArr = [];
    const qwew = [];
    if (AvgProcLabels) {
      AvgProcLabels.forEach((el) => {
        const result = [];
        let fe = [];
        if (el.split(' ').length > 3) {
          el.split(' ').forEach((word) => {
            if (fe.length < 3) {
              fe.push(word);
            } else {
              result.push(fe);
              fe = [];
              fe.push(word);
            }
          });
        } else {
          testArr.push(el);
        }
        if (fe.length > 0) result.push(fe);
        if (result.length > 0) testArr.push(result);
      });

      testArr.forEach((el) => {
        if (Array.isArray(el)) {
          const ss = [];
          el.forEach((arr) => {
            ss.push(arr.join(' '));
          });
          qwew.push(ss);
        } else {
          qwew.push(el);
        }
      });
    }

    const doughnutData = {
      labels: qwew,
      datasets: [{
        data: AvgProcData,
        backgroundColor: color,
      },
      ],
    };

    const doughnutParams = {
      Data: doughnutData,
      AllCnt: avgProcTime,
    };

    setReportParams(doughnutParams);
  }, [items]);

  useEffect(() => {
    answerFnc();
  }, [answerFnc, reportConfig]);

  const needScroll = (value) => {
    if (value * 150 > 1290) return value * 150;
    return 1290;
  };

  return (ReportParams ? (

    <div style={{
      maxWidth: '1500px',
      width: '100%',
      overflowX: `${
        ReportParams.Data.labels.length * 150 > 1290 ? 'scroll' : 'hidden'
      }`,
      height: '100%',
      overflowY: 'hidden',
    }}
    >

      <div style={{
        height: '550px',
        width: `${needScroll(ReportParams.Data.labels.length)}px`,
      }}
      >
        <Bar
          data={ReportParams.Data}
          options={{
            maintainAspectRatio: false,
            plugins: {
              intersect: true,
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: (dataSet) => `Талонов закрыто: ${dataSet.raw}`,
                },
              },
            },
            scales: {
              x: {
                title: {
                  position: 'end',
                  display: true,
                },
              },
            },
          }}
          style={{ overflowX: 'scroll' }}
        />
        ;
      </div>
    </div>
  ) : null);
}

export default AvgWaitTimeDoughnut;
