import React from 'react';
import CheckBoxList from '../../../../../pages/private/components/CheckBoxList';

function RenderCheckList({
  wpList, setDeviceData, deviceData,
}) {
  const setData = (value) => setDeviceData((v) => ({ ...v, config: value }));

  const item = {
    label: 'Игнорируемые рабочие места:', name: 'ignoreWPList', options: wpList, checked: deviceData.config.ignoreWPList || [],
  };
  return (
    <div key={item.name}>
      <CheckBoxList
        item={item}
        data={deviceData.config}
        setData={setData}
      />
    </div>
  );
}
export default RenderCheckList;
