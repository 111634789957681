import React, { useState, useRef } from 'react';
import findPosition from '../../../../../../../pages/private/components/Input/findPosition';

function Margin({
  data, dispatch, element, name, title,
}) {
  const [bottom, setBottom] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    marginTop, marginLeft, marginBottom, marginRight,
  } = data;
  const {
    paddingTop, paddingLeft, paddingBottom, paddingRight,
  } = data;

  const box = useRef(null);
  const margin = name === 'margin';

  const onChange = ({ name: nameChanged, value }) => {
    if (!element.match(/\./)) {
      dispatch({
        type: 'params',
        name: element,
        param: nameChanged,
        value,
      });
    } else {
      const arr = element.split('.');
      dispatch({
        type: 'bigParams',
        parentName: arr[0],
        name: arr[1],
        param: nameChanged,
        value,
      });
    }
  };

  const toggle = () => {
    findPosition(box.current, setBottom);
    setOpen(!open);
  };

  return (
    <span className="box__color">
      {/* eslint-disable-next-line react/no-danger */}
      <span className="settings__styles__el__label" dangerouslySetInnerHTML={{ __html: title }} />
      <div className="settings__styles__el">
        <div
          className={`settings__btn_to_change ${open ? ' theme_light_accent text_theme_light_buttonHover' : ''}`}
          onClick={toggle}
        >
          {open ? 'Закрыть' : 'Изменить'}
        </div>
        <div ref={box} style={{ position: 'relative' }}>
          {open && (
          <div
            className="box__align"
            shadow
            style={{
              position: 'absolute',
              top: bottom ? 'auto' : 0,
              bottom: bottom ? '40px' : 'auto',
            }}
          >
            <div className="box__input_margin">
              <p> Сверху </p>
              <input
                type="number"
                onChange={(e) => onChange({ name: margin ? 'marginTop' : 'paddingTop', value: Number(e.target.value) })}
                value={margin ? marginTop : paddingTop}
              />
            </div>
            <div className="box__input_margin">
              <p> Справа </p>
              <input
                type="number"
                onChange={(e) => onChange({ name: margin ? 'marginRight' : 'paddingRight', value: Number(e.target.value) })}
                value={margin ? marginRight : paddingRight}
              />
            </div>
            <div className="box__input_margin">
              <p> Снизу </p>
              <input
                type="number"
                onChange={(e) => onChange({ name: margin ? 'marginBottom' : 'paddingBottom', value: Number(e.target.value) })}
                value={margin ? marginBottom : paddingBottom}
              />
            </div>
            <div className="box__input_margin">
              <p> Слева </p>
              <input
                type="number"
                onChange={(e) => onChange({ name: margin ? 'marginLeft' : 'paddingLeft', value: Number(e.target.value) })}
                value={margin ? marginLeft : paddingLeft}
              />
            </div>
          </div>
          )}
        </div>
      </div>
    </span>
  );
}

export default Margin;
