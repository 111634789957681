import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontsBox } from '../../../../../common/ui/Common';
import UploadForm from './uploadform';
import List from './list';

function Runninglain({ preloader }) {
  const [data, setData] = useState([]);
  const [lineText, setLineText] = useState('');

  const getData = async () => {
    preloader();
    try {
      const response = await axios({
        method: 'get',
        url: '/api/runninglines',
      });

      if (response.statusText) {
        const { data: respData } = response;
        setData(respData);
      }
      preloader();
    } catch (e) {
      preloader();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onSave = async () => {
    await axios({
      method: 'post',
      url: '/api/runninglines',
      data: { text: lineText },
    });
    setLineText('');
    toast.success('Выполнено');
    getData();
  };

  const onDelete = async (id) => {
    await axios({
      method: 'delete',
      url: '/api/runninglines',
      data: {
        id,
      },
    });
    toast.success('Выполнено');
    getData();
  };

  return (
    <FontsBox>
      <UploadForm onSave={onSave} setLineText={setLineText} lineText={lineText} />
      <List data={data} onDelete={onDelete} />
    </FontsBox>
  );
}
export default Runninglain;
