import React from 'react';
import { useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import scanPolicyStore from '../../../../../../../../logic/store/pullstate/scanPolicy';
import { BodyContainer } from '../../../../utils/style';

import ScanPolicyError from './policy/policyError';
import ScanPolicyScan from './policy/policyScan';
import ScanPolicyList from './policy/policyList';
import ScanPolicyInner from './policy/policyInner';
import ScanPolicyStart from './policy/start';

function ScanPolicy() {
  const { id: terminalId } = useParams();
  const rStore = useStore();
  const { style, config } = rStore.getState();
  const {
    headUrl, startScreen, satelliteIp, satellitePort,
  } = config;
  const { title, button, input } = style;
  const activeScreen = scanPolicyStore.useState((s) => s.activeScreen);

  const getContent = () => {
    const screens = {
      start: <ScanPolicyStart
        button={button}
        startScreen={startScreen}
        terminalId={terminalId}
        satelliteParams={{ satelliteIp, satellitePort }}
        headUrl={headUrl}
        input={input}
        title={title}
      />,
      inner: <ScanPolicyInner
        button={button}
        startScreen={startScreen}
        terminalId={terminalId}
        satelliteParams={{ satelliteIp, satellitePort }}
        headUrl={headUrl}
        input={input}
        title={title}
      />,
      list: <ScanPolicyList
        button={button}
        startScreen={startScreen}
        terminalId={terminalId}
        satelliteParams={{ satelliteIp, satellitePort }}
        headUrl={headUrl}
        input={input}
        title={title}
      />,
      scan: <ScanPolicyScan
        button={button}
        startScreen={startScreen}
        terminalId={terminalId}
        satelliteParams={{ satelliteIp, satellitePort }}
        headUrl={headUrl}
        input={input}
        title={title}
      />,
      error: <ScanPolicyError
        button={button}
        startScreen={startScreen}
        terminalId={terminalId}
        satelliteParams={{ satelliteIp, satellitePort }}
        headUrl={headUrl}
        input={input}
        title={title}
      />,
    };
    if (!(activeScreen in screens)) return <div>default</div>;
    return screens[activeScreen];
  };

  return (
    <BodyContainer style={style.body}>
      <div style={title}>Предварительная запись</div>
      {getContent()}
    </BodyContainer>
  );
}

export default ScanPolicy;
