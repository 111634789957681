import React from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import Table from '../../../../../../pages/private/components/table/Table';
import Config from './TableConfig';
import { NotButtons, TableBoxAnimation } from '../../../../../../pages/private/components/table/Table.style';

function ButtonsTable({
  screen, handleTableIcon, afterSaveCell, screenList, queue, fieldsList, dictOptions,
}) {
  const { buttons } = screen;
  const Setting = Config({
    screen, screenList, queue, dictOptions, fieldsList,
  });
  const { columns } = Setting;

  return (
    <TableBoxAnimation show={Boolean(buttons)}>
      {buttons ? (
        <Table
          keys="id"
          data={buttons}
          columns={columns}
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            afterSaveCell,
          })}
          handleIcon={handleTableIcon}
          pagination={paginationFactory()}
        />
      )
        : <NotButtons>Кнопок нет</NotButtons>}
    </TableBoxAnimation>
  );
}

export default ButtonsTable;
