const DounhnutsColorMap = (colorCnt) => {
  // const baseColor = ['#00FF00', '#ADFF2F', '#FFFF00', '#FF0000', '#B22222'];
  const baseColor = ['#ff6384', '#36a2eb', '#ffce56', '#4bc0c0', '#9966ff', '#ff9f40'];

  if (colorCnt <= 5) {
    return baseColor;
  }
  let startDecColor = 65280;
  for (let i = 0; i < colorCnt; i += 1) {
    if (i > 4) {
      startDecColor += 10309;
      let hexString = startDecColor.toString(16);
      if (hexString.length < 6) {
        const step = 6 - hexString.length;
        for (let r = 0; r < step; r += 1) {
          hexString = 0 + hexString;
        }
      }
      baseColor.push(`#${hexString}`);
    }
  }
  return baseColor;
};

export default DounhnutsColorMap;
