/* eslint-disable */
import React, {
  useContext, useEffect, useState, useCallback, Component
} from 'react';
import axios from 'axios';
import useRouter from 'use-react-router';
import Header from './components/Header';
import BoardCtx from './utils/BoardCtx';
import Body from './components/body/Body';
import Footer from './components/Footer';
import WebSocketClient from './utils/WebSocketClient';
import BoardProcessor from './utils/BoardProcessor';
import { useLocation, useParams } from 'react-router-dom';

const location = window.location.href;

const debug = (location.indexOf('localhost') !== -1);
const serverUrl = debug ? '' : '';
const wsHost = !debug ? window.location.hostname : 'demo.a-7.tech';
const wsPort = !debug ? window.location.port : '48088';
const secure = (window.location.protocol === 'https');


class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = {
        hasError: false,
        error: null,
      };
    }
  
    componentDidCatch(error) {
      this.setState({
        hasError: true,
        error,
      });
    }
  
    render() {
      if (this.state.hasError) {
        return (
          <div>
            <h1>Something went wrong.</h1>
            <div>{this.state.error.stack.split(' at ').map((line) => <div key={line}>{line}</div>)}</div>
            {/* <div>{this.state.info}</div> */}
          </div>
  
        );
      }
      return this.props.children;
    }
  }


const Layout = () => {
  const [config, setConfig] = useState({});
  const [inSoundAction, setSoundAction] = useState(false);
  const [ticketsList, setTicketsList] = useState([]);
  const { layout, setActiveEl, text } = useContext(BoardCtx);
  const { id } = useParams();
  const location = useLocation();
  const getConfigUrl = `${serverUrl}/server/public/satellite/${id}/?action=get`;
  const edit = location.pathname.indexOf('devices') >= 0;
  // const edit = match.path.indexOf('devices') !== 0;

  const initWebSocket = async () => { };
  const errorWebSocket = () => { };

  const getConfig = async () => {
    try {
      const configResponse = await axios({
        method: 'post',
        url: getConfigUrl,
      });
      const { data: { data: configData } } = configResponse;
      if (configData[0]) {
        const { config: satelliteConfig } = configData[0];
        setConfig(satelliteConfig);

        const { ignoreWPList, removeTickets } = satelliteConfig;
        const boardProcessor = new BoardProcessor(setSoundAction, setTicketsList, removeTickets, ignoreWPList);
        const webSocketClient = new WebSocketClient(wsHost, wsPort, boardProcessor.processEvent, initWebSocket, errorWebSocket);
        webSocketClient.waitForSocketConnection();
      }
    } catch (e) {
      setTimeout(getConfig, 3000);
    }
  };



  useEffect(() => {
    if (edit) {
      setConfig({ layout, setActiveEl, text });
    } else {
      getConfig();
    }
  }, [layout, text, edit, setActiveEl]);





  return (
    <>
    <ErrorBoundary>
      {config.layout
        ? (
          <div
            className="layout_box"
            style={{
              width: Number(config.layout.width),
              height: Number(config.layout.height),
              backgroundImage: `url(${layout.images?.background})`
            }}
          >
            <Header layout={config.layout} text={config.text} />
            <Body
              layout={config.layout}
              text={config.text}
              ticketsList={ticketsList}
              edit={edit}
              inSoundAction={inSoundAction}
            />
            <Footer layout={config.layout} text={config.text} />
          </div>
        )
        : <div>Конфигурация не получена</div>}
        </ErrorBoundary>
    </>

  );
};

export default Layout;
