import axios from 'axios';
import { toast } from 'react-toastify';
import cleanLocalStorage from './cleanLocalStorage';

export default function request(url, action = 'get', data = {}, query = '', limit = 10, offset = 0, sort = {}, filter = {}, config = false) {
  let body = { data };
  // Filter some unnecessary params
  delete body.data.created_by_id;
  delete body.data.created_by_username;
  delete body.data.created_on;
  delete body.data.modified_by_id;
  delete body.data.modified_by_username;
  delete body.data.modified_on;

  if (!config) {
    Object.keys(body.data).forEach((key) => {
      if (typeof (body.data[key]) === 'string') body.data[key] = body.data[key].trim();
    });
  }

  if (action === 'get') {
    body = {
      ...body, limit, offset, sort, filter,
    };
  }

  return new Promise(((resolve, reject) => {
    const token = window.localStorage.getItem('token');
    axios({
      method: 'post',
      url: `${url}${action !== 'no' ? `?action=${action}` : ''}&token=${token}${query ? `&${query}` : ''}`,
      data: config ? { config: body.data } : { ...body },
    })
      .then((res) => {
        const { data: resData } = res;
        if (resData.error && (resData.error.code === '204' || resData.error.code === '202')) {
          cleanLocalStorage();
        }
        if (resData.error) {
          // обработка ошибок
          toast.warn(resData.error);
          reject(res);
        } else {
          resolve(res);
        }
      }, (err) => {
        cleanLocalStorage();
        reject(err);
      });
  }));
}
