import React, { useState } from 'react';
import { registerLocale } from 'react-datepicker';
import moment from 'moment';
import ruDate from 'date-fns/locale/ru';
import {
  DatePicker, Row, Col, Space, Button, Typography, Input,
} from 'antd';
import ComboBox from '../../Elements/Input/ComboBox';
import { useToggle } from '../../../../logic/hooks/useToggle';

registerLocale('ru-RU', ruDate);

function Filter({ handleFilters, userList }) {
  const [filterList, setFilterList] = useState([]);
  const [dates, setDates] = useState({ start: null, end: null });
  const [clearFilters, setClearFilters] = useToggle(false);
  const [comment, setComment] = useState('');

  const actions = {
    0: 'Неизвестное действие',
    // 1: 'Авторизация',
    // 2: 'Отключение',
    3: 'Создание объекта на сервере',
    4: 'Удаление объекта на сервере',
    5: 'Изменение объекта на сервере',
    6: 'Изменение параметров сервера',
    7: 'Вход по веб',
    8: 'Выход по веб (или удаление сессии по окончанию времени жизни сессии)',
  };

  const users = {};
  if (userList) {
    userList.forEach((user) => {
      users[Number(user.id)] = user.username;
    });
  }

  const clear = () => {
    setDates({ start: null, end: null });
    handleFilters([]);
    setComment('');
    setClearFilters();
  };

  const find = (ar) => (rm) => (rm.column === ar.column && ar.comparator === rm.comparator);
  const filter = (changedFilters) => (ar) => !changedFilters.find(find(ar));

  const changeFilters = (changedFilters) => {
    const oldFilters = filterList.filter(filter(changedFilters));
    const newFilters = [].concat(oldFilters, changedFilters.filter((f) => f.value !== null));
    setFilterList(newFilters);
  };

  const handleCombo = (value, column) => {
    changeFilters([{
      column,
      value,
      comparator: '=',
    }]);
  };

  const dateChange = ({ startDate, endDate }) => {
    const { start: defaultStartDate, end: defaultEndDate } = dates;
    const start = startDate || defaultStartDate;
    const end = endDate || defaultEndDate;
    setDates({ start, end });
    const filterDate = [];
    if (start) filterDate.push({ column: 'created_on::DATE', value: start.startOf('day').format('YYYY-MM-DD'), comparator: '>=' });
    if (end) filterDate.push({ column: 'created_on::DATE', value: end.endOf('day').format('YYYY-MM-DD'), comparator: '<=' });
    changeFilters(filterDate);
  };

  const handleComment = (e) => {
    const { value } = e.target;
    setComment(value);
    changeFilters([{ column: 'comment', value: (value !== '') ? `%${value}%` : null, comparator: 'LIKE' }]);
  };

  const { start, end } = dates;
  return (
    <div>
      <Typography.Title level={3} style={{ textAlign: 'center', marginBottom: 20 }}>
        Фильтрация
      </Typography.Title>
      <Row style={{ width: '100%' }} gutter={['middle', 'middle']}>
        <Col span={6}>
          <div>Дата</div>
          <DatePicker.RangePicker
            value={[start && moment(start), end && moment(end)]}
            onCalendarChange={([startDate, endDate]) => dateChange({ startDate, endDate })}
          />
        </Col>
        <Col span={6}>
          <div>Пользователи</div>
          <ComboBox list={users} setData={handleCombo} column="created_by_id" clear={clearFilters} placeholder="Выберите пользователя" />
        </Col>
        <Col span={6}>
          <div>Действия</div>
          <ComboBox list={actions} setData={handleCombo} column="action" clear={clearFilters} placeholder="Выберите действие" />
        </Col>
        <Col span={6}>
          <div>Комментарий</div>
          <Input style={{ fontSize: 12 }} type="text" onChange={handleComment} value={comment} placeholder="Введите комментарий" />
        </Col>
      </Row>
      <Space style={{ justifyContent: 'center', width: '100%', marginTop: '20px' }} direction="horizontal" size="small" align="center">
        <Button type="primary" onClick={() => handleFilters(filterList)}>Применить</Button>
        <Button onClick={clear}>Сбросить</Button>
      </Space>
    </div>
  );
}

export default Filter;
