import React, { useState } from 'react';
import {
  Button, Checkbox, Form, notification, Select,
} from 'antd';
import { useMutation } from '@tanstack/react-query';
import preorderRest from '../../utils/preorderRest';

function DeleteSlot({
  queues, dayId, refetch, closeDrawer,
}) {
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [forAll, setForAll] = useState(false);
  const { mutate } = useMutation({
    mutationFn: (variables) => preorderRest.deleteSlots(variables),
    onSuccess: (status) => {
      if (status === 'failed') {
        notification.error({
          message: 'Не удалось удалить слоты',
        });
      } else {
        notification.success({
          message: 'Слоты успешно удалены',
        });
        refetch();
        closeDrawer();
      }
    },
    onError: () => {
      notification.error({
        message: 'Не удалось удалить слоты',
      });
    },
  });

  const handleSelect = (values) => {
    setSelectedQueues(values);
  };

  const handleCheck = (e) => {
    setForAll(e.target.checked);
  };

  const deleteSlots = () => {
    const qArr = [...selectedQueues];
    if (forAll) {
      queues?.forEach((el) => {
        if (el.qId !== 0) {
          qArr.push(el.qId);
        }
      });
    }
    mutate({ dayId, data: { queues: qArr } });
  };

  return (
    <Form style={{ width: '600px' }} layout="vertical">
      <Form.Item style={{ width: '100%' }} label="Список услуг для удаления слотов">
        <Select optionFilterProp="label" onChange={handleSelect} mode="multiple">
          {queues?.map((queue) => (
            <Select.Option
              key={queue.qId}
              value={Number(queue.qId)}
              label={queue.qFullName}
            >
              {queue.qFullName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Checkbox onChange={handleCheck}>
          Удалить все слоты по всем услугам
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Button disabled={selectedQueues.length === 0 && !forAll} onClick={deleteSlots} type="primary">
          Удалить слоты
        </Button>
      </Form.Item>
    </Form>
  );
}

export default DeleteSlot;
