import React from 'react';
import ColorPicker from '../../../../../../utils/colorPicker';
import GradientPicker from '../../../../../../utils/gradient';

const color = ({
  element, dispatch, rusName, paramName, value,
}) => {
  const handleColor = ({ payload }) => dispatch({
    type: 'params',
    name: element,
    param: paramName,
    value: payload,
  });

  return (
    <span className="box__color">
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: rusName }} className="settings__styles__el__label" />
      {/color/.test(paramName) || paramName === 'boxShadow' || paramName === 'borderColor'
        ? (
          <ColorPicker
            handleColor={handleColor}
            name={paramName}
            color={value}
          />
        )
        : (
          <GradientPicker
            colorBg={value}
            handleGradient={handleColor}
            name="background"
          />
        )}
    </span>
  );
};

export default color;
