import React from 'react';

export default React.createContext({
  dispatch: () => {},
  layout: {},
  layouts: [],
  fonts: [],
  activeEl: '',
  setActiveEl: () => {},
  activeLayout: '',
  setActiveLayout: () => {},
  text: {},
  setText: () => {},
  handleSave: () => {},
  elWidth: 0,
});
