/* eslint-disable prefer-destructuring */
import React from 'react';
import {
  CardReportBody,
  CardReportBox,
  CardReportDiscription,
  CardReportElement,
  CardReportItem,
  CardReportTitle,
  Qq,
} from '../../../../../components/report/ReportTableColumns';

export function CardReportTemplate(data) {
//  let close = 0;
//  let turnout = 0;
//  let all = 0;
//  let remove = 0;
//  let service = '00:00:00';
//  let waiting = '00:00:00';

  //  if (data.length > 0) {
  //    all = data[0][1];
  //    close = data[0][2];
  //    turnout = data[0][3];
  //    remove = data[0][4];
  //    service = data[0][5];
  //    waiting = data[0][6];
  //  }

  return (
    <Qq m="0 0 50px 0">
      <CardReportBox>
        <CardReportTitle>Обслужено талонов</CardReportTitle>
        <CardReportBody>
          <CardReportElement>
            <CardReportDiscription>Закрыто</CardReportDiscription>
            <CardReportItem>{data && (data[2] || 0)}</CardReportItem>
          </CardReportElement>
          <CardReportElement>
            <CardReportDiscription>Неявка</CardReportDiscription>
            <CardReportItem>{data && (data[3] || 0)}</CardReportItem>
          </CardReportElement>
          <CardReportElement>
            <CardReportDiscription>Всего</CardReportDiscription>
            <CardReportItem>{data && (data[1] || 0)}</CardReportItem>
          </CardReportElement>
        </CardReportBody>
      </CardReportBox>

      <CardReportBox>
        <CardReportTitle>Среднее время</CardReportTitle>
        <CardReportBody>
          <CardReportElement>
            <CardReportDiscription>обслуживания</CardReportDiscription>
            <CardReportItem>{(data && (data[5] !== null ? data[5] : '00:00:00')) || 0}</CardReportItem>
          </CardReportElement>
          <CardReportElement>
            <CardReportDiscription>ожидания</CardReportDiscription>
            <CardReportItem>{(data && (data[6] !== null ? data[6] : '00:00:00')) || 0}</CardReportItem>
          </CardReportElement>
        </CardReportBody>
      </CardReportBox>

      <CardReportBox>
        <CardReportTitle>Удалено талонов</CardReportTitle>
        <CardReportBody>
          <CardReportElement>
            <CardReportDiscription>Системой</CardReportDiscription>
            <CardReportItem>{data && (data[4] || 0)}</CardReportItem>
          </CardReportElement>
        </CardReportBody>
      </CardReportBox>
    </Qq>

  );
}

export default null;
