import React, { useState, useEffect, useCallback } from 'react';
import {
  Button, Col, Row, Select,
} from 'antd';
import Icon from '@ant-design/icons';
import request from '../../../../../logic/utils/request';
import { ReactComponent as Trash } from '../../../../../common/images/del.svg';

const RenderCheckList = ({
  zoneData, setZoneData, preloader,
}) => {
  const [bookList, setBookList] = useState([]);
  const { params } = zoneData.config;

  const fetchBooks = useCallback(async () => {
    preloader();
    try {
      const books = await request('/server/api/dictionary/', 'get');
      const fields = await request('/server/api/ticketsfields/', 'get');
      const bookSelect = [];
      fields.data.data.forEach((field) => {
        books.data.data.forEach((book) => {
          if (field.type === 'select' && book.id === field.dict_id) {
            bookSelect.push(book);
          }
        });
      });
      setBookList(bookSelect);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('err', err);
    }
    preloader();
  }, [preloader]);

  useEffect(() => {
    fetchBooks();
  }, [fetchBooks]);

  const options = bookList.map((el) => ({ id: el.id, name: el.name }));

  const onChange = (value, id, name) => {
    if (name === 'fv') {
      params[id][name] = [value];
    } else {
      params[id][name] = value;
    }
    const data = { ...zoneData, config: { ...zoneData.config, params } };
    setZoneData(data);
  };

  const deleteItem = (index) => {
    params.splice(index, 1);
    setZoneData({ ...zoneData, config: { ...zoneData.config, params } });
  };

  const getSelect = (fid, fv, index) => {
    const ops = [];
    bookList.forEach(
      (book) => book.id === fid
        && book.values.forEach(
          (el) => ops.push({ id: el.id, name: el.name }),
        ),
    );
    return (
      <Select
        name={index}
        labelInValue
        style={{ width: '100%' }}
        value={{
          value: fv[0],
          label: ops.find((opt) => opt.id === fv[0])?.name || 'Значение',
        }}
        onChange={({ value }) => onChange(value, index, 'fv')}
        filterOption={
          (input, opt) => (opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
        }
      >
        {ops.map(({ id, name }) => (<Select.Option value={id}>{name}</Select.Option>))}
      </Select>
    );
  };

  return (
    params.map((item, index) => (
      <Row key={item.fid} gutter={[10, 10]}>
        <Col span={11}>
          <Select
            name={index}
            labelInValue
            style={{ width: '100%' }}
            onChange={({ value }) => onChange(value, index, 'fid')}
            value={{
              value: item.fid,
              label: options.find((opt) => opt.id === item.fid)?.name || 'Дополнительное поле',
            }}
            filterOption={
              (input, opt) => (opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            }
          >
            {options.map(({ id, name }) => (<Select.Option value={id}>{name}</Select.Option>))}
          </Select>
        </Col>
        <Col span={11}>
          {item.fid && getSelect(item.fid, item.fv, index)}
        </Col>
        <Col span={2}>
          <Button onClick={() => deleteItem(index)} shape="circle" icon={<Icon component={Trash} />} />
        </Col>
      </Row>
    ))
  );
};
export default RenderCheckList;
