import { resetTicket, setTextError } from '../../../store/actions';
import store from '../../../store/store';

const { dispatch } = store;

export default function findError({
  res, navigate, url, errorUrl,
}) {
  if (res.status === 504) {
    navigate(errorUrl);
    dispatch(setTextError('Нет связи с устройством.<br/> Обратитесь к администратору'));
  }
  const { data } = res;
  const { error } = data;

  if (error) {
    const { code, text } = error;
    switch (code) {
      case '55':
        navigate(errorUrl);
        dispatch(setTextError('Рабочий день услуги закончился. Обратитесь к Администратору.'));
        break;
      case '7':
        navigate(`/recoveryTicket/${url}`);
        break;
      default:
        navigate(errorUrl);
        dispatch(setTextError(text.rus));
        dispatch(resetTicket());
    }
    return error;
  }
  if (!res.data.style) {
    dispatch(setTextError('Не создан стиль для талона. Обратитесь к администратору.'));
    navigate(errorUrl);
  }

  return error;
}
