import Request from '../../../../../../../../../../logic/utils/request';

async function doQuest(reportType, setQueueList) {
  const query = ' select id, name, fullname from eq.tqueue order by id';

  const res = await Request('/server/api/genreportfromquery/', 'get', {
    querySql: `${query}`,
  });

  const answer = res.data.data;
  const items = [{ key: 'all', name: 'Все', text: 'Все' }];

  answer.forEach((e) => {
    items.push({ key: e[0], name: e[1], text: e[2] });
  });

  const conf = {
    headerTitleText: 'Все',
    items,
  };

  setQueueList(conf);
}

export default doQuest;
