import React, { useState } from 'react';
import {
  Button,
  Checkbox, DatePicker, Form, notification, Select,
} from 'antd';
import { useMutation } from '@tanstack/react-query';
import preorderRest from '../../utils/preorderRest';

function CopySlot({
  queues, dayId, refetch, closeDrawer,
}) {
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [forAll, setForAll] = useState(false);
  const [dateInterval, setDateInterval] = useState();
  const [overwrite, setOverwrite] = useState(false);

  const { mutate } = useMutation({
    mutationFn: (variables) => preorderRest.copySlot(variables),
    onSuccess: (status) => {
      if (status === 'failed') {
        notification.error({
          message: 'Не удалось скопировать слоты',
        });
      } else {
        notification.success({
          message: 'Слоты успешно скопированы',
        });
        refetch();
        closeDrawer();
      }
    },
    onError: () => {
      notification.error({
        message: 'Не удалось скопировать слоты',
      });
    },
  });

  const selectQueues = (values) => {
    setSelectedQueues(values);
  };

  const selectDate = (values) => {
    if (values && values[0] && values[1]) {
      const dates = { start: values[0].format('YYYY.MM.DD'), end: values[1].format('YYYY.MM.DD') };
      setDateInterval(dates);
    }
  };

  const copySlots = () => {
    const qArr = [...selectedQueues];
    if (forAll) {
      queues?.forEach((queue) => {
        if (queue.qId !== 0) {
          qArr.push(Number(queue.qId));
        }
      });
    }
    const data = {
      dayId,
      data: {
        begin: dateInterval?.start,
        end: dateInterval?.end,
        queues: qArr,
        replace: overwrite,
      },
    };
    mutate(data);
  };

  return (
    <Form style={{ width: '600px' }} layout="vertical">
      <Form.Item style={{ width: '100%' }} label="Список услуг для копирования слотов">
        <Select optionFilterProp="label" disabled={forAll} onChange={selectQueues} mode="multiple">
          {queues && queues.map((queue) => (
            <Select.Option key={queue.qId} value={Number(queue.qId)} label={queue.qFullName}>
              {queue.qFullName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={forAll}
          onChange={(e) => setForAll(e.target.checked)}
        >
          Скопировать все слоты по всем услугам
        </Checkbox>
      </Form.Item>
      <Form.Item style={{ width: '100%' }} label="Интервал дат для копирования">
        <DatePicker.RangePicker
          onChange={selectDate}
          style={{ width: '100%' }}
          format="DD.MM.YYYY"
        />
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={overwrite}
          onChange={(e) => setOverwrite(e.target.checked)}
        >
          Перезаписывать слоты
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Button
          onClick={copySlots}
          type="primary"
          disabled={!dateInterval || (selectedQueues.length === 0 && !forAll)}
        >
          Копировать слоты
        </Button>
      </Form.Item>
    </Form>
  );
}

export default CopySlot;
