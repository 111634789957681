import React from 'react';
import TicketHistoryTable from './TicketHistoryTable';

export default {
  renderer: (row) => (
    <TicketHistoryTable
      ticketId={row.ticket_id}
    />
  ),
  showExpandColumn: true,
  expandByColumnOnly: false,
};
