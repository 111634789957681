import {
  useCallback, useEffect,
} from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import getToken from '../../../../logic/utils/getToken';
import request from '../../../../logic/utils/request';
import { useStateAndRefs } from './useStateAndRefs';

export function useLogin() {
  const stateAndRefs = useStateAndRefs();

  const fetchParams = useCallback(() => {
    if (!getToken()) return;
    const getParams = request('/server/api/officeinfo/');
    getParams
      .then((res) => {
        const paramsData = res.data.data;
        const isValid = paramsData.every((el) => Boolean(el.value));
        paramsData.forEach((el) => {
          if (el.param === 'officeName') {
            window.localStorage.setItem('officeName', el.value);
            document.title = `${el.value} | AlphaQ`;
          }
        });
        if (isValid) {
          stateAndRefs.setAnimationForm(1);
          stateAndRefs.navigate(
            window.localStorage.getItem('role') === 'eq_reporter'
              ? '/reports/'
              : '/monitoring/',
          );
        } else {
          stateAndRefs.setOffice(res.data.data);
          stateAndRefs.setOpen(true);
        }
      });
  }, [stateAndRefs.navigate]);

  useEffect(() => {
    fetchParams();
  }, [fetchParams]);

  return {
    ...stateAndRefs,
    resetModal() {
      stateAndRefs.toggleOpen();
    },
    async handleLogin(e) {
      e.preventDefault();
      const userInfo = { userLogin: stateAndRefs.userLogin, userPasswd: stateAndRefs.userPasswd };
      axios({ method: 'post', url: '/server/public/login', data: userInfo })
        .then((res) => {
          const { data } = res;
          if (res.data.userRoles !== 'eq_operator') {
            if (res.data.error) {
              toast.error(res.data.error.text.rus);
            } else {
              window.localStorage.setItem('token', data.session);
              window.localStorage.setItem('fio', data.userFio);
              window.localStorage.setItem('login', data.userLogin);
              window.localStorage.setItem('role', data.userRoles);
              fetchParams();
            }
          } else {
            stateAndRefs.toggleOpen();
          }
        });
    },
    handleInput(e) {
      const { name, value } = e.target;
      if (name === 'userLogin') {
        stateAndRefs.setUserLogin(value);
      } else {
        stateAndRefs.setUserPassword(value);
      }
    },
    async save() {
      try {
        const sendData = {};
        stateAndRefs.office.forEach((el) => {
          sendData[el.param] = el.value;
        });
        await request('/server/api/officeinfo/', 'update', sendData);
        stateAndRefs.navigate('/monitoring/');
      } catch (e) {
        toast.error('Не удалось установить параметры офиса', 5000);
      }
    },
  };
}

export default useLogin;
