import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import getYear from '../../../../../../../logic/utils/getYear';
import Month from '../preEntry/record/Month';
import SelectTime from '../preEntry/record/Time';
import InnerData from '../preEntry/record/InnerData';
import request from '../request';
import { setPre } from '../../../../store/actions';
import './index.css';
import { QueueBox } from '../../../utils/style';
import ChooseQueue from './ChooseQueue';

function NewPreRecord({ children }) {
  const dispatch = useDispatch();
  const { bottomButton } = useSelector((state) => ({
    bottomButton: state.style.bottomButton,
    config: state.config,
    style: state.style,
  }));

  const navigate = useNavigate();
  const [currentScreen, setCurrentScreen] = useState();

  const currentDate = useMemo(() => ({
    year: moment().format('YYYY'),
    day: moment().format('DD'),
    month: moment().format('MM'),
  }), []);

  const [selectedQueue, setSelectedQueue] = useState({ id: null });
  const [selectedYear, setSelectedYear] = useState(currentDate.year);
  const [selectedMonth, setSelectedMonth] = useState(currentDate.month);
  const [selectedDay, setSelectedDay] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [loaded, setLoaded] = useState(false);
  const [cellId, setCellId] = useState(false);
  const [year, setYear] = useState();
  const [calendar, setCalendar] = useState();
  const [times, setTimes] = useState([]);
  const [preEntryActive, setPreEntryActive] = useState(true);
  const [queueList, setQueueList] = useState([]);
  const [preEntryError, setPreEntryError] = useState(false);

  const createTicket = async (contactData) => {
    const data = {
      calendarCellId: cellId,
      clientEmail: contactData.clientEmail || '',
      clientPhoneNumber: contactData.clientPhoneNumber ? `+7${contactData.clientPhoneNumber}` : '',
      clientFIO: contactData.clientFIO || '',
      q_id: selectedQueue.id,
    };

    const hashString = Object.keys(data)
      .sort()
      .reduce((acc, key) => acc + data[key], '');

    data.sign = md5(hashString);

    const answer = await request('/server/public/preorder/register', 'no', { data });

    const monthData = year[String(selectedMonth).padStart(2, '0')];
    const day = Object.keys(monthData).find((el) => monthData[el].id === selectedDay) || 0;
    const date = `${day}.${String(selectedMonth).padStart(2, '0')}.${selectedYear} ${selectedTime}`;

    dispatch(setPre({
      pin: answer.data.data.pinCode,
      date,
      service: selectedQueue.name,
    }));

    navigate('../print');
  };

  const fetchQueue = async () => {
    try {
      setLoaded(true);
      const { data: { data } } = await request('/server/public/preorder/queues/', 'get');

      if (data.length > 0) {
        const res = await getYear('0', `${selectedYear}`);

        if (res?.data?.status === 'failed') {
          setPreEntryError(true);
          return;
        }

        setCalendar(res.year);
        setYear(res.selectedYear);
        setCurrentScreen('chooseDay');
        setQueueList(data);
      } else {
        setPreEntryActive(true);
      }
    } finally {
      setLoaded(false);
    }
  };

  useEffect(() => {
    fetchQueue();
  }, [selectedYear]);

  const selectDay = async (dayId) => {
    const { data: { data } } = await request(`/server/public/preorder/day/${dayId}?queueId=0`);
    setTimes(data);
    setSelectedDay(dayId);
    setCurrentScreen('chooseTime');
  };

  const selectTime = (newCellId, time) => {
    setCellId(newCellId);
    setSelectedTime(time);
    setCurrentScreen('chooseQueue');
  };

  const chooseQueue = (obj) => {
    setSelectedQueue(obj);
    setCurrentScreen('clientInfo');
  };

  const screens = useMemo(() => ({
    chooseDay: <Month
      newPreEntrty
      currentDay={currentDate.day}
      currentYear={currentDate.year}
      month={selectedMonth}
      setMonth={setSelectedMonth}
      setDay={setSelectedDay}
      selectDay={selectDay}
      year={year}
      style={bottomButton}
      setQueue={setSelectedQueue}
      selectedYear={selectedYear}
      setSelectedYear={setSelectedYear}
      currentMonth={currentDate.month}
      selectedDay={selectedDay}
      calendar={calendar}
    />,
    chooseTime: <SelectTime
      createTicket={createTicket}
      style={bottomButton}
      setDay={setSelectedDay}
      times={times}
      id={cellId}
      setId={(newCellId, time) => {
        setCellId(newCellId);
        setSelectedTime(time);
      }}
      newPreEntrty
      selectTime={selectTime}
    />,
    chooseQueue: <ChooseQueue
      queueList={queueList}
      chooseQueue={chooseQueue}
    />,
    clientInfo: <InnerData
      bottomStyle={bottomButton}
      createTicket={createTicket}
    />,
  }), [bottomButton, calendar, cellId, createTicket, currentDate, selectedDay, selectedMonth, selectedYear, times, year]);

  const renderScreen = () => screens[currentScreen];

  const loadingContent = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '1080px',
        margin: '0 auto',
      }}
    >
      {Array.from({ length: 6 }).map((_, i) => (
      // eslint-disable-next-line
        <Skeleton.Input key={`skeleton-${Date.now()}-${i}`} active size="large" block />
      ))}
    </div>
  );

  const errorContent = (
    <div
      style={{
        display: 'flex',
        margin: '0 auto',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {preEntryError
        ? 'Нет слотов для предварительной записи'
        : 'Нет доступных очередь для записи. Обратитесь к администратору.'}
    </div>
  );

  let content;
  if (loaded) {
    content = loadingContent;
  } else if (preEntryError || !preEntryActive) {
    content = errorContent;
  } else {
    content = renderScreen();
  }

  return (
    <>
      <QueueBox>
        {content}
      </QueueBox>
      {children}
    </>
  );
}

export default NewPreRecord;
