import React, { useCallback, useEffect, useState } from 'react';
import ButtonsTable from './ButtonsTable';
import randomId from '../../../../../../logic/utils/randomId';
import {
  BtnAdd, ButtonBoxPoints, PreloaderBox, Title,
} from '../../../../../../common/ui/Common';
import request from '../../../../../../logic/utils/request';

function RenderButtons({
  screen,
  updateScreen,
  screenList,
  preloader,
  dictOptions,
}) {
  const { buttons, type, currentTicketField } = screen;
  const [queue, setQueue] = useState(null);
  const [fieldsList, setFieldsList] = useState([]);

  const fetchServices = useCallback(async () => {
    preloader();
    const res = await request('/server/api/queue/', 'get', {});
    setQueue(res.data.data);
    preloader();
  }, [preloader]);

  const fetchFields = useCallback(async (valid) => {
    const res = await request('/server/api/ticketsfields/', 'get');
    if (valid) {
      setFieldsList(res.data.data);
    } else {
      setFieldsList(res.data.data.filter((el) => el.type === 'text'));
    }
  }, []);

  useEffect(() => {
    fetchServices();
    const reg = /input|multi|dict/.test(type);
    if (reg && fieldsList.length === 0) {
      fetchFields(type === 'dictScreen');
    }
  }, [type, fieldsList.length, fetchServices, fetchFields]);

  const addBtn = () => {
    const field = fieldsList.find((el) => el.id === currentTicketField);
    let paramsName;
    if (type !== 'dictScreen' && type !== 'multiScreen' && type !== 'inputScreen') {
      paramsName = 'queue';
    } else {
      paramsName = 'dictionary';
    }
    const buttonData = {
      name: `Название ${buttons.length + 1}`,
      size: 'lg',
      path: '',
      params: { name: paramsName, value: 0 },
      id: randomId(5),
      position: buttons.length + 1,
      name_eng: '',
      extraText_eng: '',
    };
    buttonData.params = type === 'dictScreen'
      ? {
        name: paramsName,
        value: 0,
        fid: field.dict_id,
      }
      : buttonData.params;
    screen.buttons.push(buttonData);
    updateScreen(true);
  };

  const handleTableIcon = (e, row) => {
    console.log('123213');
    const { name } = e.target.dataset;
    if (name === 'del') {
      screen.buttons = screen.buttons.filter((item) => item.id !== row.id);
      updateScreen(true);
    }
  };

  const afterSaveCell = (oldValue, newValue, row, column) => {
    screen.buttons = screen.buttons.map((item) => {
      if (item.id === row.id) {
        if (screen.screenId === newValue) {
          row.path = '';
        }
        if (column.dataField === 'icon') {
          row[newValue.name] = newValue.value;
        }
        if (column.dataField === 'params') {
          let name = 'queue';
          if (type === 'dictScreen' || type === 'multiScreen') {
            name = 'dictionary';
          }

          if (newValue.name) {
            row.params = oldValue;
            row.params[newValue.name] = newValue.value;
          } else {
            row.params = { name, value: newValue, fid: oldValue.fid };
          }
          return row;
        }
        return row;
      }
      return item;
    });

    updateScreen(true);
  };

  return (
    <ButtonBoxPoints>
      <Title>Кнопки</Title>
      <BtnAdd onClick={addBtn} add>
        <span>Добавить</span>
      </BtnAdd>
      <PreloaderBox height="60px">
        {queue && (
        <ButtonsTable
          screen={screen}
          screenList={screenList}
          handleTableIcon={handleTableIcon}
          afterSaveCell={afterSaveCell}
          queue={queue}
          dictOptions={dictOptions}
          fieldsList={fieldsList}
        />
        )}
      </PreloaderBox>
    </ButtonBoxPoints>
  );
}
export default RenderButtons;
