import React from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

function TimeBar(props) {
  console.log('');

  const { data, graphData } = props;

  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.display = 'flex';
      listContainer.style.flexDirection = 'column';
      listContainer.style.margin = '0';
      listContainer.style.padding = '0';

      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item) => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';

        li.onclick = () => {
          const { type } = chart.config;
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = `${item.lineWidth}px`;
        boxSpan.style.display = 'block';
        boxSpan.style.flexShrink = '0';
        boxSpan.style.height = '20px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '20px';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = 0;
        textContainer.style.padding = 0;
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
      },
      legend: {
        display: false,
      },
      htmlLegend: {
        containerID: 'legend-container',
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (dataSet) => {
            console.log('dataSet === ', dataSet);
            const str = dataSet.formattedValue;
            console.log('str === ', str);
            const seconds = parseFloat(+str.replace(',', '.')) * 60;

            return `${dataSet.dataset.label} ${moment().startOf('day')
              .seconds(seconds)
              .format('HH:mm:ss')}`;
          },
        },
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: true,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: false,
        ticks: {
          callback: (label) => {
            const seconds = label * 60;
            return `${moment().startOf('day')
              .seconds(seconds)
              .format('HH:mm:ss')}`;
          },
        },
      },
    },
  };

  console.log('graphData === ', graphData);
  console.log('data === ', data);

  return (
    <Bar
      data={data}
      options={options}
      style={{ overflowX: 'scroll' }}
      plugins={[htmlLegendPlugin]}
      redraw
    />
  );
}

export default React.memo(TimeBar);
