import React from 'react';
import { Button, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CaretLeftFilled } from '@ant-design/icons';
import zoneStore from '../../Store';
import TerminalName from './TooltipTitle';

function Title() {
  const navigate = useNavigate();

  const {
    id, name, longname, description,
  } = zoneStore.useState((s) => s);

  return (
    <h2 className="zone-title">
      <Tooltip title="Вернуться назад">
        <Button
          onClick={() => navigate(-1)}
          style={{ position: 'absolute', left: 0 }}
          type="primary"
        >
          <CaretLeftFilled style={{ fontSize: '130%', verticalAlign: 'middle' }} />
        </Button>
      </Tooltip>
      Конструктор зоны -
      {' '}
      <Tooltip
        placement="bottom"
        title={(
          <TerminalName
            id={id}
            longname={longname}
            description={description}
          />
          )}
      >
        <span className="zone-name">{name}</span>
      </Tooltip>
    </h2>
  );
}

export default Title;
