import React, { useEffect, useContext } from 'react';
import moment from 'moment';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ctx from '../utils/ctx';
import Preloader from './preloader';
import request from '../utils/request';

function Month() {
  const {
    queue, year, month, cday, day, syear, dispatch,
  } = useContext(ctx);
  const navigate = useNavigate();

  useEffect(() => { if (!queue.id) navigate('/preentry'); }, queue.id);

  const mom = moment(`${year}.${month}.${cday}`, 'YYYY:MM:DD');
  const offset = moment(`${year}.${month}.01`, 'YYYY:MM:DD').isoWeekday();
  const countDays = mom.daysInMonth();
  const monthName = mom.format('MMMM');
  const arrDays = [];
  const arrNameDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

  for (let i = 1; i < offset; i += 1) {
    arrDays.push(<li key={i + 100} />);
  }

  const fetchDays = async (fetchDay) => {
    const answer = await request(`/server/public/preorder/day/${fetchDay}?queueId=${queue.id}`);
    dispatch({ type: 'times', value: answer.data.data });
  };

  if (syear) {
    for (let b = 1; b <= countDays; b += 1) {
      const m = moment(month, 'M').format('MM');
      const d = moment(b, 'D').format('DD');
      const isOpen = syear[m] && syear[m][d] ? syear[m][d].count : false;

      const dayComponent = (
        <li
          key={b}
          onClick={() => {
            if (isOpen) {
              dispatch({ type: 'day', value: syear[m][d].id });
              if (syear[m][d].id) fetchDays(syear[m][d].id);
              navigate('/preentry/time');
            }
          }}
          className={`item ${day === b ? 'active' : ''} ${isOpen ? 'open' : ''}`}
        >
          {b}
        </li>
      );

      arrDays.push(dayComponent);
    }
  }

  const changeMonth = (number) => {
    if (+month + number === 13) {
      dispatch({ type: 'month', value: 1 });
      dispatch({ type: 'year', value: +year + 1 });
    } else if (+month + number === 0) {
      dispatch({ type: 'year', value: +year - 1 });
      dispatch({ type: 'month', value: 12 });
    } else {
      dispatch({ type: 'month', value: +month + number });
    }
  };

  const goBack = () => {
    dispatch({ type: 'queue', value: { timeId: null, name: null } });
    navigate(-1);
  };

  return (
    <>
      <h3>
        <span onClick={goBack}>Назад</span>
        Выберите день
      </h3>
      {syear ? (
        <div className="monthBox">
          <div className="filterBox center">
            {moment().format('YYYY.MM') !== `${year}.${month}`
            && <span onClick={() => changeMonth(-1)}><FaChevronLeft /></span> }
            <p>
              {monthName}
              ,
              {' '}
              {year}
            </p>
            <span onClick={() => changeMonth(1)}><FaChevronRight /></span>
          </div>
          {arrNameDays.map((el) => <li key={el} className="title">{el}</li>)}
          {arrDays}
        </div>
      ) : <Preloader />}
    </>
  );
}

export default Month;
