import React from 'react';

function Big({
  styles,
  setActiveEl,
  text,
  edit,
  ticketsList,
}) {
  const {
    big,
    cols,
    vertical,
    borderRadius,
  } = styles;
  const {
    fontSize1,
    fontSize2,
    color1,
    background,
    color2,
    borderSize1,
    color3,
    fontFamily1,
    fontFamily2,
    height,
    alternate,
  } = big;

  const win = edit ? ['1', '2', '3'] : ticketsList[0]?.wNum || [];

  const renderText = (value) => {
    if (+value === 0) {
      return '';
    }
    return value;
  };

  return (
    <div
      className={`layout__body__main_box ${alternate ? 'alternate' : ''} `}
      style={{
        ...big,
        background,
        height: (!vertical || height === 'auto') ? 'auto' : height,
        flex: 1,
        flexDirection: (alternate) ? 'row' : 'column',
        borderRadius: borderRadius ? '12px' : 0,
        border: `${borderSize1}px solid ${color3}`,
      }}
    >
      <div className={`layout__body__main__client ${alternate ? 'alternate' : ''} `}>
        <p
          className="layout__body__main__title"
          style={{
            fontSize: fontSize1,
            fontFamily: fontFamily1,
            color: color1,
          }}
          onClick={() => { setActiveEl('bigTitle'); }}
        >
          {text.columns1}
        </p>
        <p
          style={{
            fontSize: fontSize2,
            color: color2,
            fontFamily: fontFamily2,
          }}
          onClick={() => setActiveEl('big')}
        >
          {edit ? 'Ж 9999' : ticketsList[0]?.number || null}
        </p>
      </div>
      {cols >= 2 && (
      <div className={`layout__body__main__win__item ${alternate ? 'alternate' : ''} `}>
        <p
          className="layout__body__main__title"
          style={{
            fontSize: fontSize1,
            color: color1,
            fontFamily: fontFamily1,
          }}
          onClick={() => { setActiveEl('bigTitle'); }}
        >
          {text.columns2}
        </p>
        <p
          style={{
            fontFamily: fontFamily2,
            fontSize: fontSize2,
            color: color2,
          }}
          onClick={() => setActiveEl('big')}
        >
          {renderText(win[0])}
        </p>
      </div>
      )}
      {cols > 2 && (
      <div className={`layout__body__main__win__item ${alternate ? 'alternate' : ''} `}>
        <p className="layout__body__main__title" style={{ fontSize: fontSize1, color: color1, fontFamily: fontFamily1 }} onClick={() => { setActiveEl('bigTitle'); }}>
          {text.columns3}
        </p>
        <p
          style={{
            fontSize: fontSize2, color: color2, fontFamily: fontFamily2,
          }}
          onClick={() => setActiveEl('big')}
        >
          {renderText(win[1])}
        </p>
      </div>
      )}
      {cols > 3 && (
      <div className="layout__body__main__win__item">
        <p className="layout__body__main__title" style={{ fontSize: fontSize1, color: color1, fontFamily: fontFamily1 }} onClick={() => { setActiveEl('bigTitle'); }}>
          {text.columns4}
        </p>
        <p
          style={{
            fontSize: fontSize2, color: color2, fontFamily: fontFamily2,
          }}
          onClick={() => setActiveEl('big')}
        >
          {renderText(win[2])}
        </p>
      </div>
      )}
    </div>
  );
}

export default Big;
