import React from 'react';
import Order from './modules/order';

function MarginComponent({ arr, dispatch }) {
  return (
    <>
      {arr.map((el) => (
        <Order
          element={el.parentName}
          dispatch={dispatch}
          title={el.title}
          value={el.value}
          key={el.title}
        />
      ))}
    </>
  );
}

export default MarginComponent;
