import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from 'antd';
import TicketList from './TicketList';

function Ticket() {
  const navigate = useNavigate();
  return (
    <>
      <Typography.Title style={{ textAlign: 'center', color: '#25547A' }}>Конструктор талонов</Typography.Title>
      <div>
        <Button type="primary" size="large" onClick={() => navigate('/tickets/ticketConstructor/')}>
          Добавить
        </Button>
      </div>
      <TicketList />
    </>
  );
}

export default Ticket;
