import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select } from 'antd';

function QuestionSelection({ handleQuestionChange }) {
  const [dropdownItems, setDropdownItems] = useState([]);
  const fetchQuestionList = async () => {
    const items = [];
    const url = '/api/reports/build';
    const type = 'qualityQueueList';
    const token = window.localStorage.getItem('token');
    const res = await axios({
      method: 'post',
      url,
      data: {
        token,
        type,
        filters: { main: { } },
      },
    });

    res.data.answer.forEach((e) => {
      items.push({ key: e.id, text: e.questiontext });
    });
    setDropdownItems(items);

    handleQuestionChange(items[0].key);
  };

  const handleSelect = (_, option) => {
    handleQuestionChange(option.key);
  };

  useEffect(() => {
    fetchQuestionList();
  }, []);

  return (
    <div style={{ marginLeft: '15px' }}>
      {dropdownItems.length !== 0
        && (
        <Select onSelect={handleSelect} defaultValue={dropdownItems[0].key} style={{ width: '240px' }}>
          {dropdownItems.map((item) => (
            <Select.Option key={item.key} value={item.key}>{item.text}</Select.Option>
          ))}
        </Select>
        )}
    </div>
  );
}

export default QuestionSelection;
