import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  BigButtonsContainer, BoxButtonContent, ButtonContent, StyleButton,
} from '../../../utils/style';

function PreEntry(props) {
  const { children, newPreEntry } = props;
  const { headUrl, id } = useSelector((state) => state.config);

  const { style } = useSelector((state) => ({ style: state.style.button }));
  const navigate = useNavigate();

  const buttons = [
    { title: 'Записаться', path: `${headUrl}/${id}/preRecord` },
    { title: 'Получить талон', path: `${headUrl}/${id}/pinCode` },
  ];

  const newPreEntryButtons = [
    { title: 'Записаться', path: `${headUrl}/${id}/newPreRecord` },
    { title: 'Получить талон', path: `${headUrl}/${id}/pinCode` },
  ];

  const renderButtons = () => {
    if (newPreEntry) {
      return newPreEntryButtons.map((el) => (
        <div key={el.path} style={style} onClick={() => navigate(el.path)}>
          <ButtonContent>
            <BoxButtonContent>
              <p>{el.title}</p>
            </BoxButtonContent>
          </ButtonContent>
        </div>
      ));
    }

    return buttons.map((el) => (
      <div key={el.path} style={style} onClick={() => navigate(el.path)}>
        <ButtonContent>
          <BoxButtonContent>
            <p>{el.title}</p>
          </BoxButtonContent>
        </ButtonContent>
      </div>
    ));
  };

  return (
    <>
      <BigButtonsContainer style={{ alignItems: style.alignItems }}>

        <StyleButton width={style.widthContent} style={{ justifyContent: style.justifyContent }}>
          {renderButtons()}
        </StyleButton>
      </BigButtonsContainer>
      {children}
    </>
  );
}

export default PreEntry;
