import React from 'react';
import {
  Button, Checkbox, Input, Select,
} from 'antd';
import Icon from '@ant-design/icons';
import { FaPlus, FaTrash } from 'react-icons/fa';

export function TableFontsConfig(change, fonts, deleteItem, scrollPosition, openModal) {
  return [
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: 'Текст',
      dataIndex: 'textInput',
      key: 'textInput',
      width: 300,
      render: (textInput, font) => (
        <Input
          value={textInput}
          onChange={(e) => change(font.key, e.target.value, 'textInput')}
          addonAfter={(
            <Icon onClick={() => openModal(font.key)} component={FaPlus} />
          )}
        />
      ),
    },
    {
      title: 'Шрифт',
      dataIndex: 'fontFamily',
      key: 'fontFamily',
      render: (fontFamily, font) => (
        <Select
          value={fontFamily}
          onChange={(value) => change(font.key, value, 'fontFamily')}
          filterOption={
            (input, opt) => (opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
          }
        >
          {fonts.map((el) => (
            <Select.Option key={el.name} value={el.name}>{el.name}</Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Размер шрифта',
      dataIndex: 'fontSize',
      key: 'fontSize',
      render: (fontSize, font) => (
        <Select
          value={fontSize}
          onChange={(value) => change(font.key, value, 'fontSize')}
        >
          {new Array(180)
            .fill()
            .map((_, index) => index + 1)
            .map((index) => (
              <Select.Option key={index} value={index}>{index}</Select.Option>
            ))}
        </Select>
      ),
    },
    {
      title: 'Вырав. по центру',
      dataIndex: 'align',
      key: 'align',
      width: 60,
      render: (align, font) => (
        <Checkbox
          checked={align}
          onChange={(e) => change(font.key, e.target.checked, 'align')}
        />
      ),
    },
    {
      title: 'X',
      dataIndex: 'x',
      key: 'x',
      render: (x, font) => (
        <Input
          type="number"
          size="small"
          value={x}
          style={{ width: 70 }}
          onChange={(e) => change(font.key, e.target.value, 'x')}
        />
      ),
    },
    {
      title: 'Y',
      dataIndex: 'y',
      key: 'y',
      render: (y, font) => (
        <Input
          type="number"
          size="small"
          value={y}
          style={{ width: 70 }}
          onChange={(e) => change(font.key, e.target.value, 'y')}
        />
      ),
    },
    {
      title: 'Показать',
      dataIndex: 'show',
      key: 'show',
      render: (show, font) => (
        <Checkbox
          checked={show}
          onChange={(e) => change(font.key, e.target.checked, 'show')}
        />
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (_, font) => (
        <Button
          shape="circle"
          type="primary"
          icon={<Icon component={FaTrash} />}
          onClick={() => deleteItem(font.key)}
        />
      ),
    },
  ];
}

export default null;
