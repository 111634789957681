import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Title } from '../../../../../../common/ui/Common';
import { ReportBox } from '../../../../components/report/ReportTableColumns';

function Graphic(props) {
  const { data: graphicData } = props;
  const closed = graphicData.map((hour) => hour.closed);

  const aeroflot = true;

  if (aeroflot) {
    closed.splice(0, 10);
    closed.splice(12, 3);
  }

  const getLabels = () => {
    if (aeroflot) {
      return [
        '09:00-10:00',
        '10:00-11:00',
        '11:00-12:00',
        '12:00-13:00',
        '13:00-14:00',
        '14:00-15:00',
        '15:00-16:00',
        '16:00-17:00',
        '17:00-18:00',
        '18:00-19:00',
        '19:00-20:00',
        '20:00-21:00',
      ];
    }
    return [
      '00:00-00:00',
      '00:00-01:00',
      '01:00-02:00',
      '02:00-03:00',
      '03:00-04:00',
      '04:00-05:00',
      '05:00-06:00',
      '06:00-07:00',
      '07:00-08:00',
      '08:00-09:00',
      '09:00-10:00',
      '10:00-11:00',
      '11:00-12:00',
      '12:00-13:00',
      '13:00-14:00',
      '14:00-15:00',
      '15:00-16:00',
      '16:00-17:00',
      '17:00-18:00',
      '18:00-19:00',
      '19:00-20:00',
      '20:00-21:00',
      '21:00-22:00',
      '22:00-23:00',
      '23:00-00:00',
    ];
  };

  const graph = {
    labels: getLabels(),
    datasets: [{
      label: 'Количество обслуженых талонов',
      fill: false,
      lineTension: 0.05,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: '#20a8d8',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#20a8d8',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#20a8d8',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: closed,
    }],
  };

  return (
    <ReportBox>
      <Title>Почасовое распределение обслуженных талонов за текущий день</Title>
      <div style={{ height: '300px', marginBottom: 100 }}>
        <Bar
          data={graph}
          height={100}
          options={{
            maintainAspectRatio: false,
          }}
        />
      </div>
    </ReportBox>
  );
}

export default React.memo(Graphic);
