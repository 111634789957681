import React, { useState } from 'react';
import { Card, Drawer } from 'antd';
import zoneStore from '../../Store';
import ScreenForm from '../Constructor/ScreenForm';
import Screen from './Screen';
import defaultValues from '../../../utils/defaultValues';
import searchByKey from '../../../../statistics/reports/utils/seachByKey';
import { updateSchemeStore } from '../../Store/schemeStore';

function NewScheme() {
  const screens = zoneStore.useState((s) => s.config.screens);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [changingScreen, setChangingScreen] = useState(null);

  const toggleDrawer = (type, screen = null) => {
    if (type === 'close') {
      setChangingScreen(null);
      setIsDrawerOpen(false);
    } else {
      setChangingScreen(screen);
      setIsDrawerOpen(true);
    }
  };

  const indexOfParam = (array, param, item) => array
    .map((mapItem) => mapItem[param])
    .indexOf(item[param]);
  const filter = (param) => (item, pos, arr) => indexOfParam(arr, param, item) === pos;
  const removeDuplicates = (a, param) => a.filter(filter(param));

  const addScreenItem = (screenPath, parent, val) => {
    const screenList = screens;

    let nextScreen = {};

    const parents = [...parent.parents];
    const queue = [...parent.queue];

    nextScreen = screenList.find((item) => item.screenId === screenPath);
    nextScreen = searchByKey(screenList, 'screenId', screenPath);

    const {
      scanScrenNode,
      preScreenNode,
      preRecordNode,
      pinCodeNode,
      printScreenNode,
    } = defaultValues.screenNodes;

    // дефолтные экраны
    const scanScreen = { ...scanScrenNode, parents };
    const preScreen = { ...preScreenNode, parents };
    const preRecord = { ...preRecordNode, parents };
    const pinCode = { ...pinCodeNode, parents };
    const printScreen = { ...printScreenNode, parents };

    let treeNode = {};

    if (val) {
      queue.push(val);
    }
    parents.push(parent.id);

    switch (screenPath) {
      case 'print':
        treeNode = printScreen;
        break;
      case 'scan':
        treeNode = scanScreen;
        break;
      case 'preEntry':
        treeNode = preScreen;
        break;
      case 'pinCode':
        treeNode = pinCode;
        break;
      case 'preRecord':
        treeNode = preRecord;
        break;
      default:
        treeNode = {
          title: nextScreen.title,
          headTitle: nextScreen.headTitle,
          id: nextScreen.screenId,
          type: nextScreen.type,
          screenPath: nextScreen.screenPath,
          parents,
          queue,
          nodes: [],
        };
        if (parent.parents.indexOf(screenPath) === -1) {
          // eslint-disable-next-line no-use-before-define
          treeNode.nodes = mapScreenItems(nextScreen, treeNode);
        }
        break;
    }

    // если есть очередь - экран на печать
    if (val) {
      treeNode = printScreen;
    }

    return treeNode;
  };

  const mapScreenItems = (screen, parent) => {
    const items = [];
    const {
      buttons: screenButtons, bottomBlock, type, screenPath,
    } = screen;

    const buttons = type === 'infoScreen' ? bottomBlock.buttons : screenButtons;

    if (screenPath !== undefined && screenPath !== '') {
      const treeNode = addScreenItem(screenPath, parent);
      items.push(treeNode);
    } else if (buttons) {
      buttons.forEach((button) => {
        const isQueue = button.params.name === 'queue';
        if ((button.path === '' || button.path === undefined) && !button.params.value) return;

        const treeNode = addScreenItem(
          button.path,
          parent,
          isQueue ? button.params.value : null,
        );
        items.push(treeNode);
      });
    }

    return items;
  };

  const renderScreenItems = (screen) => {
    const {
      nodes, id, title, parents,
    } = screen;
    // данные экрана для дочерних компонентов
    const screenData = screens.find((item) => item.screenId === id);

    const elements = [];
    // зацикленная схема
    if (parents.indexOf(id) > -1) {
      return (
        <Card
          style={{
            background: 'red',
            margin: '10px',
            color: '#fff',
          }}
          key={`err-${id}`}
        >
          {title}
        </Card>
      );
    }

    // карточка экрана
    const titleContainer = screenData ? (
      <Screen
        screen={screenData}
        toggleDrawer={toggleDrawer}
        key={id}
        className="screen-arrow"
      />
    ) : (
      <Card
        style={{
          margin: '10px',
          background: '#607D8B',
          color: '#fff',
        }}
        key={id}
      >
        {title}
      </Card>
    );

    if (nodes.length > 0) {
      const noDuplicateNodes = removeDuplicates(nodes, 'id');
      noDuplicateNodes.forEach((node) => {
        const nextContainer = renderScreenItems(node);
        elements.push(nextContainer);
      });
    }

    // контейнер ноды, который идет после экрана родителя
    const screenNodesContainer = (
      <div
        style={{
          display: 'flex',
          flexDirection: nodes.length > 0 ? 'column' : 'row',
          justifyContent: 'center',
        }}
        key={`node-${id}`}
      >
        {elements}
      </div>
    );

    const items = [titleContainer, screenNodesContainer];

    // нода, которая содержит родителя и дочерние ноды
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'stretch',
        }}
        key={id}
      >
        {items}
      </div>
    );
  };

  const startScreen = screens.find((screen) => screen.isStart);

  // первая нода
  const treeNode = {
    title: startScreen.title,
    headTitle: startScreen.headTitle,
    screenPath: startScreen.screenPath,
    id: startScreen.screenId,
    type: startScreen.type,
    parents: [],
    queue: [],
    nodes: [],
  };

  treeNode.nodes = mapScreenItems(startScreen, treeNode);

  updateSchemeStore({ treeNode });

  return (
    <div>
      {screens.length > 0 && renderScreenItems(treeNode)}
      <Drawer
        destroyOnClose
        title={`Изменение экрана - ${changingScreen?.screenId}`}
        open={isDrawerOpen}
        onClose={() => toggleDrawer('close')}
      >
        <ScreenForm initialData={changingScreen} toggleDrawer={toggleDrawer} type="edit" />
      </Drawer>
    </div>
  );
}

export default NewScheme;
