export default ({ bottomButton }) => [
  {
    title: 'Фон', value: bottomButton.background, paramName: 'background', parentName: 'bottomButton',
  },
  {
    title: 'Обводка', value: bottomButton.borderColor, paramName: 'borderColor', parentName: 'bottomButton',
  },
  {
    title: 'Тень', value: bottomButton.boxShadow.split(' ')[3], paramName: 'boxShadow', parentName: 'bottomButton',
  },
];
