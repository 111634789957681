import React from 'react';
import TerminalScreen from './TerminalScreen';
import BoardScreen from './BoardScreen';
import QualityScreen from './QualityScreen';
import NeyavkaScreen from './NeyavkaScreen';
import TicketProcess from './TicketProcess';
import PreentryScreen from './PreentryScreen';
import kagocelPreloader from '../images/kagocelpreloader.gif';
import { useScreenProviderContext } from '../use/useScreenProviderContext';

function ScreensProvider({ styleConfig }) {
  const { logoComp: { backgroundImage: logo = '' }, header } = styleConfig;
  const { currentScreen, showPreloader } = useScreenProviderContext();

  return (
    <div className="websTeminalScreen">
      <div className="websTeminalScreen__header" style={{ background: header.background }}>
        <div className="websTeminalScreen__logo">
          {/* TODO Логотип не всегда корректно приходит с сервера */}
          <img src={logo} alt="logo" />
        </div>
        <div className="websTeminalScreen__phone">Вебсервис</div>
      </div>
      {showPreloader && (
      <div className="videoPreloader">
        <img src={kagocelPreloader} alt="preloader" />
      </div>
      )}
      {currentScreen === 'terminal' && (<TerminalScreen styleConfig={styleConfig} />)}
      {currentScreen === 'board' && (<BoardScreen styleConfig={styleConfig} />)}
      {currentScreen === 'quality' && (<QualityScreen />)}
      {currentScreen === 'ticketProcess' && (<TicketProcess />)}
      {currentScreen === 'preentry' && (<PreentryScreen styleConfig={styleConfig} />)}
      {currentScreen === 'neyavka' && (<NeyavkaScreen styleConfig={styleConfig} />)}

      {/* Если currentScreen не равен ниодному из выше перечисленных то Nothing */}
      {['terminal', 'board', 'quality', 'ticketProcess', 'preentry', 'neyavka'].includes(currentScreen) || 'Nothing'}
    </div>
  );
}

export default ScreensProvider;
