import defaultLogo from '../../../../../../common/images/logo.png';

export default {
  text: {
    footer1: 'Вас приветствует компания "А-БТ"',
    time1: 'Мы работаем: \nс 9-00 до 19-00',
    header1: 'Техническая поддержка: \n+7 (495) 249 00 50',
    news1: 'Новости',
    columns1: 'Клиент',
    columns2: 'Окно',
    columns3: 'Окно3',
    columns4: 'Окно4',
    video1: 'Видео Отсутствует',
  },
  layout: {
    vid: true,
    window: true,
    tidings: false,
    date: true,
    lines: 5,
    cols: 2,
    vertical: false,
    height: 1080,
    width: 1920,
    clock: false,
    borderRadius: false,
    font: '',
    images: {
      background: '',
      headerBg: '',
      headerLogo: defaultLogo,
    },
    header: {
      background: '#235657',
      color1: '#fff',
      fontSize1: 32,
      order: 0,
      color2: '#fff',
      fontSize2: 32,
      fontSize3: 32,
      fontWeight1: 400,
      height: 100,
    },
    columns: {
      color1: '#235657',
      color2: '#000',
      color3: '#000',
      color4: '#ccc',
      color5: '#ccc',
      color6: '#ccc',
      background1: 'linear-gradient(to bottom, rgba(231, 240, 245, 1),rgba(197, 205, 209, 1))',
      background2: 'linear-gradient(to bottom, rgba(140, 170, 174, 1),rgba(84, 125, 126, 1))',
      background3: 'linear-gradient(to bottom, rgba(140, 170, 174, 1),rgba(84, 125, 126, 1))',
      background4: 'linear-gradient(to bottom, rgba(140, 170, 174, 1),rgba(84, 125, 126, 1))',
      order: 0,
      fontSize1: '32',
      fontSize2: '32',
      borderSize1: '1',
      borderSize2: '0',
      fontWeight1: 400,
      fontWeight2: 400,
    },
    big: {
      background: 'linear-gradient(to bottom, rgba(241, 242, 241, 1),rgba(213, 212, 212, 1))',
      fontSize1: 54,
      fontSize2: 106,
      fontSize3: 110,
      borderSize1: 1,
      color1: '#c55a09',
      color2: '#c55a09',
      color3: '#ccc',
      fontWeight1: 400,
      fontWeight2: 400,
    },
    logo: {
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
      marginLeft: 0,
    },
    video: {
      background: 'linear-gradient(to bottom, rgba(241, 242, 241, 1),rgba(213, 212, 212, 1))',
      fontSize1: 40,
      color1: '#000',
      color2: '#ccc',
      order: 1,
      fontWeight1: 400,
      borderSize1: 1,
      volume: 0.5,
    },
    news: {
      background: 'linear-gradient(to bottom, rgba(241, 242, 241, 1),rgba(213, 212, 212, 1))',
      fontSize1: 32,
      fontSize2: 32,
      fontSize4: 32,
      color1: '#235657',
      color2: '#235657',
      color3: '#235657',
      color4: '#235657',
      fontWeight1: 400,
      fontWeight2: 400,
      fontWeight4: 400,
      borderSize1: 1,
    },
    time: {
      color1: '#235657',
      color2: '#235657',
      color3: '#235657',
      fontSize1: 42,
      fontSize2: 32,
      fontSize3: 22,
      background: 'linear-gradient(to bottom, rgba(241, 242, 241, 1),rgba(213, 212, 212, 1))',
      fontWeight1: 400,
      fontWeight2: 400,
      borderSize1: '1',
      bold: 700,
    },
    footer: {
      background: '#d5d3d4',
      fontSize1: 40,
      color1: '#000',
      order: 1,
      fontWeight1: 400,
      height: 100,
    },
  },
};
