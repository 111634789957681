import React from 'react';
import { Select } from 'antd';
import {
  FilterBoxTitle, FilterBox,
} from '../style';
// import MultiSelectNew from '../../../../../../components/multiselect/MultiSelectNew';

function OperatorSelect({ changeFilter, filtersData }) {
  const MultiSelectItemList = (list) => {
    const ww = list.join(', ');
    if (ww === '') changeFilter('operator', 'all');
    else changeFilter('operator', ww);
  };

  return (

    <FilterBox Select>
      <FilterBoxTitle>Список операторов</FilterBoxTitle>
      <div style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        padding: '10px',
      }}
      >
        <Select
          mode="multiple"
          placeholder="Выберите оператора"
          className="multiselect"
          onChange={MultiSelectItemList}
          filterOption={
            (input, opt) => (opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            }
        >
          {filtersData.map((operator) => (
            <Select.Option
              key={operator.id}
              value={operator.id}
            >
              {operator.fio}
            </Select.Option>
          ))}
        </Select>
      </div>
    </FilterBox>
  );
}

export default OperatorSelect;
