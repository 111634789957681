import React from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as Excel } from '../../../common/images/excel.svg';

export default function DataExportConfig(options) {
  return {
    headerTitleText: 'Экспортировать',
    option: options,
    items: [
      {
        key: '1',
        name: 'excel',
        text: 'Excel',
        icon: <Icon component={Excel} style={{ fontSize: 26 }} />,
      },
    ],
  };
}
