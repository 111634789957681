import { Store } from 'pullstate';

const defaultState = {};

const queueStore = new Store(defaultState);

export default queueStore;

export const updateQueueStore = (values) => {
  // console.log('UPDATE STORE', values);
  const updatedValues = (s) => {
    Object.keys(values).forEach((key) => {
      s[key] = values[key];
    });
  };
  queueStore.update(updatedValues);
};

export const resetStore = () => {
  updateQueueStore({ ...defaultState });
};
