import React, { useContext } from 'react';
import BoardCtx from '../../../../../../../pages/public/board/utils/BoardCtx';
import Board from '../../../../../../../pages/public/board/Board';

function BoardPreview() {
  const { layout } = useContext(BoardCtx);
  return (
    <div style={{ width: Number(layout.width) }}>
      {layout.header && (<Board />)}
    </div>
  );
}

export default React.memo(BoardPreview);
