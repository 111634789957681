import moment from 'moment';
import defaultTicket from './defaultTicket';

const init = {
  day: null,
  month: moment().format('MM'),
  year: moment().format('YYYY'),
  syear: null,
  times: [],
  loading: false,
  queue: {},
  queueList: [],
  timeId: null,
  cday: moment().format('DD'),
  cmonth: moment().format('MM'),
  ticket: defaultTicket,
  calendar: {},
  user: null,
  phone: '',
  email: null,
};

export default init;
