import React, { useContext } from 'react';
import { Collapse } from 'antd'; // DOCS: https://ant.design/components/collapse/
import BoardCtx from '../../../../../../../../../pages/public/board/utils/BoardCtx';
import colors from './color';
import FontFamily from '../../../../Components/modules/font';
import FontSize from '../../../../Components/modules/fontSize';
import VolumeVideo from '../../../../Components/modules/volume';
import TextComponent from '../../../../Components/modules/text';
import Height from '../../../../Components/modules/height';
import Colors from '../../../../Components/Colors';
import MarginComponent from '../../../../Components/modules/Margin';
import Order from '../../../../Components/modules/order';

function Video() {
  const {
    dispatch, layout, fonts, text, dispatchText,
  } = useContext(BoardCtx);
  const { video } = layout;
  const { Panel } = Collapse;
  const arrColors = colors({ video });

  const onRefresh = () => dispatch({
    type: 'params', name: 'video', param: 'height', value: 'auto',
  });

  return (
    <Collapse className="customize-Collapse" ghost>
      <Panel className="customize-Collapse-Header-No-Top-Padding" header="Текст" key="1">
        <TextComponent title="Окно видео" element="text" param="video1" value={text.video1} dispatch={dispatchText} />
        <Collapse className="customize-Collapse" ghost />
      </Panel>

      <Panel header="Параметры текста" key="2">
        <FontFamily title="Шрифт" options={fonts} element="video" nameParams="fontFamily" value={video.fontFamily} dispatch={dispatch} />
        <FontSize rusName="Размер шрифта" value={video.fontSize1} element="video" paramName="fontSize1" dispatch={dispatch} />
      </Panel>

      <Panel header="Параметры элементов" key="3">
        <VolumeVideo rusName="Громкость видео" value={video.volume} element="video" paramName="volume" dispatch={dispatch} width="160px" />
        <Height clear refresh={onRefresh} title="Высота видео" width="160px" element="video" nameParams="height" value={video.height} dispatch={dispatch} />
        <Height title="Толщина рамки" width="160px" element="video" nameParams="borderSize1" value={video.borderSize1} dispatch={dispatch} />
        <Order element="video" dispatch={dispatch} value={video.order} title="Позиция" />
        <MarginComponent data={video} dispatch={dispatch} element="video" name="margin" title="Отступы" />
      </Panel>

      <Panel className="customize-Collapse-Colors" header="Цвет" key="4">
        <Colors arr={arrColors} dispatch={dispatch} />
      </Panel>
    </Collapse>
  );
}

export default Video;
