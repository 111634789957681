import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { clearBeforeDataScene, resetTicket, setInfoScreen } from '../../../store/actions';
import store from '../../../store/store';

const { dispatch } = store;

export function useBottomButtons(props) {
  const { scene, noBack } = props;

  const navigate = useNavigate();

  const {
    style, headUrl, id, startScreen,
  } = useSelector((state) => ({
    config: state.config,
    style: state.style.bottomButton,
    ...state.config,
  }));
  const { styleShow } = style;

  const linksStyle = (name) => ({
    id: name,
    icon: style[name].background,
    text: style[name].text,
    show: style[name].show,
  });

  const home = {
    ...linksStyle('home'),
    func: () => {
      navigate(startScreen);
      dispatch(resetTicket());
    },
  };
  const back = {
    ...linksStyle('back'),
    func: () => {
      dispatch(clearBeforeDataScene(scene.screenId));
      navigate(-1);
    },
  };
  const info = {
    ...linksStyle('info'),
    func: () => {
      dispatch(setInfoScreen(scene.help));
      navigate(`${headUrl}/${id}/infoScene/`);
    },
  };

  const next = {
    id: 'next',
    func: () => navigate(`../${scene.screenPath}`),
    name: style.ready.text,
    show: true,
  };

  const styleButton = { ...style, position: 'static' };
  const reg = /multiScreen|inputScreen/;
  const arrButtons = [
    noBack || back,
    home,
    scene.help && info,
    reg.test(scene.type) && next,
  ];

  if (!styleShow) {
    delete styleButton.background;
    delete styleButton.boxShadow;
    styleButton.borderWidth = 0;
  }

  return {
    arrButtons: arrButtons.filter((link) => !!link),
    styleButton,
  };
}

export default null;
