import React, { useState, useEffect } from 'react';
import request from '../../../../../../logic/utils/request';
import DayTable from './DayTable';
import { useToggle } from '../../../../../../logic/hooks/useToggle';
import NewDay from './NewDay/NewDay';

function Days({ preloader, showError, write }) {
  const initialData = {
    description: '',
    name: '',
    workIntervals: [],
  };
  const [dayList, setDayList] = useState({ data: [], isFetching: false });
  const [intervalList, setIntervalList] = useState({ data: [] });
  const [dayData, setDayData] = useState(initialData);
  const [dayUpdate, setDayUpdate] = useState(false);
  const [isOpen, toggleOpen] = useToggle(false);

  const fetchData = async () => {
    setDayList({ data: [] });
    preloader();
    try {
      const res = await request('/server/api/dworkday/', 'get');
      const interval = await request('/server/api/dworkinterval/', 'get');
      setIntervalList({ data: interval.data.data, isFetching: true });
      setDayList({ data: res.data.data });
      preloader();
    } catch (e) {
      setDayList({ data: [], isFetching: false });
      showError('Ошибка получения интервалов');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <NewDay
        dayData={dayData}
        setDayData={setDayData}
        fetchData={fetchData}
        toggleOpen={toggleOpen}
        intervalList={intervalList}
        isOpen={isOpen}
        write={write}
        setDayUpdate={setDayUpdate}
        dayUpdate={dayUpdate}
        initialData={initialData}
        preloader={preloader}
      />
      <DayTable
        dayList={dayList}
        intervalList={intervalList}
        fetchDay={fetchData}
        showError={showError}
        setDayUpdate={setDayUpdate}
        setDayData={setDayData}
        toggleOpen={toggleOpen}
        write={write}
        preloader={preloader}
      />
    </div>
  );
}

export default Days;
