import React from 'react';
import SelectInput from '../../../../../Elements/Input2/SelectInput/SelectInput';

function PositionContent({
  element, value, dispatch, title, icon, paramName,
}) {
  const option1 = [
    { id: 1, name: 'Вертикальное' },
    { id: 0, name: 'Горизонтальное' },
  ];
  const option2 = [
    { id: 1, name: 'До текста' },
    { id: 0, name: 'После текста' },
  ];
  const options = icon ? option2 : option1;
  return (
    <span className="box__color">
      {/* eslint-disable-next-line react/no-danger */}
      <span className="settings__styles__el__label" dangerouslySetInnerHTML={{ __html: title }} />
      <SelectInput
        noDefault
        width="180px"
        value={+value}
        onChange={(e) => dispatch({
          type: 'params',
          name: element,
          param: paramName,
          value: e.target.value,
        })}
        options={options}
      />
    </span>
  );
}

export default PositionContent;
