import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Title, PreloaderBox } from '../../../../../common/ui/Common';
import request from '../../../../../logic/utils/request';
import { useToggle } from '../../../../../logic/hooks/useToggle';
import GroupingsTable from './GroupingsTable';
import NewGrouping from './NewGrouping/NewGrouping';

function Groupings({ preloader, showError, write }) {
  const initialData = {
    description: '',
    name: '',
    bunchType: 'none',
    bunchObjectsIds: [],
  };

  const [bunchList, setBunchList] = useState(null);
  const [bunchData, setBunchData] = useState(initialData);
  const [fieldUpdate, setFieldUpdate] = useState(false);
  const [msData, setMsData] = useState(null);
  const [isOpen, toggleOpen] = useToggle(false);

  const fetchMsData = useCallback(async () => {
    const res = await axios.get('/api/queueobjects/', 'get');
    setMsData(res.data);
  }, []);

  const fetchBunch = useCallback(async () => {
    const res = await request('/server/api/bunch/', 'get');
    setBunchList(res.data.data);
  }, []);

  useEffect(() => {
    fetchBunch();
    fetchMsData();
  }, [fetchMsData, fetchBunch]);

  return (
    <>
      <Title>Группировки</Title>
      <NewGrouping
        initialData={initialData}
        preloader={preloader}
        toggleOpen={toggleOpen}
        msData={msData}
        write={write}
        isOpen={isOpen}
        fetchBunch={fetchBunch}
        bunchData={bunchData}
        setBunchData={setBunchData}
        setFieldUpdate={setFieldUpdate}
        fieldUpdate={fieldUpdate}
        showError={showError}
      />
      <PreloaderBox>
        <GroupingsTable
          bunchList={bunchList}
          fetchBunch={fetchBunch}
          preloader={preloader}
          toggleOpen={toggleOpen}
          write={write}
          showError={showError}
          setBunchData={setBunchData}
          setFieldUpdate={setFieldUpdate}
        />
      </PreloaderBox>
    </>
  );
}

export default Groupings;
