import React from 'react';
import {
  Form, Input, Select,
} from 'antd';
import BookValues from './BookValues/BookValues';

function RenderInputs({
  fieldData, setFieldData, setIsValid, bookUpdate, preloader, showError, write,
}) {
  const handleInput = (e) => {
    const { name, value } = e.target;
    const validData = { ...fieldData, [name]: value };
    const { name: iV, rus_name: pV, type } = validData;
    setFieldData({ ...validData });
    if (iV && pV && type) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const options = [
    { name: 'Справочник с одиночным выбором', id: 'select' },
    { name: 'Справочник с множественным выбором', id: 'multiselect' },
    { name: 'Текстовое поле', id: 'text' },
  ];

  const {
    name, rus_name: rusName, description, type, dict_id: dictId,
  } = fieldData;

  return (
    <div style={{ width: '100%', marginBottom: '20px' }}>
      <Form>
        <Form.Item
          label="Имя"
          name="name"
          rules={[{ required: true, message: 'Введите имя' }]}
          initialValue={name}
        >
          <Input name="name" onChange={handleInput} />
        </Form.Item>
        <Form.Item
          label="Наименование"
          name="rus_name"
          initialValue={rusName}
          rules={[{ required: true, message: 'Введите наименование' }]}
        >
          <Input name="rus_name" onChange={handleInput} />
        </Form.Item>
        <Form.Item
          label="Описание"
          initialValue={description}
          name="description"
        >
          <Input name="description" onChange={handleInput} />
        </Form.Item>
        <Form.Item
          initialValue={type}
          name="type"
          label="Тип"
          rules={[{ required: true, message: 'Выберете тип' }]}
        >
          <Select onChange={(value) => handleInput({ target: { name: 'type', value } })}>
            {options.map((option) => (
              <Select.Option value={option.id}>{option.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      {bookUpdate && (
        <BookValues
          write={write}
          bookId={dictId}
          preloader={preloader}
          showError={showError}
        />
      )}
    </div>
  );
}
export default RenderInputs;
