import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useToggle } from '../../../../logic/hooks/useToggle';

export function useStateAndRefs() {
  const navigate = useNavigate();
  const [userLogin, setUserLogin] = useState('');
  const [userPasswd, setUserPassword] = useState('');
  const [office, setOffice] = useState([]);
  const [open, setOpen] = useState(false);
  const alphaM = useRef(null);
  const terminals = useRef(null);
  const sueo = useRef(null);
  const [animationForm, setAnimationForm] = useState(0);
  const [isOpen, toggleOpen] = useToggle(false);

  return {
    navigate,
    userLogin,
    setUserLogin,
    userPasswd,
    setUserPassword,
    office,
    setOffice,
    open,
    setOpen,
    alphaM,
    terminals,
    sueo,
    animationForm,
    setAnimationForm,
    isOpen,
    toggleOpen,
  };
}

export default null;
