import React, { useState } from 'react';
import { Tabs } from 'antd';
import { Title } from '../../../../common/ui/Common';
import ServiceList from './ServiceList/ServiceList';
import Schedule from './Schedule/Schedule';
import Questions from './Questions/Questions';
import Calendar from './Calendar/Calendar';
import Poll from './Poll';
// import PreEntryOld from './PreEntryOld';
import PreEntry from './PreEntry';

function Services({ ...props }) {
  const [tab, setTab] = useState('1');
  return (
    <>
      <Title>Услуги</Title>
      <Tabs activeKey={tab} onChange={setTab} destroyInactiveTabPane>
        <Tabs.TabPane tab="Список услуг" key="1">
          <ServiceList {...props} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Расписание услуги" key="2">
          <Schedule {...props} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Тематики услуг" key="3">
          <Questions {...props} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Анкетирование" key="4">
          <Poll {...props} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Календарь" key="5">
          <Calendar {...props} />
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="Предварительная запись" key="6"> */}
        {/*  <PreEntryOld {...props} /> */}
        {/* </Tabs.TabPane> */}
        <Tabs.TabPane tab="Предварительная запись" key="6">
          <PreEntry {...props} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
export default Services;
