import React, { useEffect, useState } from 'react';
import { FaRedo, FaSave } from 'react-icons/fa';
import {
  FieldBox, Text, BtnReset, BtnSave, AbsoluteBox,
} from '../../../../../../pages/private/subpages/queue/workplaces/Parameters/style';
import { Input } from '../../../../../../common/ui/Common';
import SelectInput from '../../../../../../pages/private/components/Input/SelectInput';

function Field({
  param, onSave, resetParam, write,
}) {
  const [value, setValue] = useState(null);
  useEffect(() => {
    setValue(param.value);
  }, [param]);
  // const reg = /\.|,/g;
  // const regNumber = /\d/;

  const options = [
    { id: 0, name: 'Нет' },
    { id: 1, name: 'Да' },
  ];

  const handleInput = (e) => {
    setValue(param.param_type === 'number' ? +e.target.value : e.target.value);
  };

  return (
    <FieldBox>
      <AbsoluteBox m={param.param_type === 'boolean'}>
        {write && param.reset && (
          <BtnReset onClick={() => resetParam(param.id)}><FaRedo /></BtnReset>
        )}
        {write && param.value !== value && <BtnSave m={param.param_type === 'boolean'} onClick={() => onSave(value, param.id, param.param_type)}><FaSave /></BtnSave>}
      </AbsoluteBox>
      <Text>{param.description}</Text>
      { value !== null && param.param_type === 'boolean'
        ? (
          <SelectInput
            disabled={!write}
            options={options}
            noDefault
            value={Number(param.value)}
            onChange={(e) => onSave(e.target.value, param.id)}
          />
        ) : (
          <Input
            type="text"
            disabled={!write}
            step="1"
            min="0"
            value={value || ''}
            onChange={handleInput}
          />
        )}
    </FieldBox>
  );
}
export default Field;
