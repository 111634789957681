export default function TableConfig(getColumnSearchProps) {
  return {
    columns: [
      {
        dataIndex: 'fullname',
        title: 'Очередь',
        ...getColumnSearchProps('fullname', 'очередь'),
      },
      {
        dataIndex: 'work_day',
        title: 'Рабочих дней',
        sorter: (a, b) => b.work_day - a.work_day,
        sortDirections: ['descend', 'ascend'],
        render: (cell) => {
          if (cell !== null) return cell;
          return '-';
        },
      },
      {
        title: 'Талонов обработано',
        children: [
          {
            dataIndex: 'all_cnt',
            title: 'Всего',
            sorter: (a, b) => a.all_cnt - b.all_cnt,
            sortDirections: ['descend', 'ascend'],
            render: (cell) => {
              if (cell !== null) return cell;
              return '-';
            },
          },
          {
            dataIndex: 'all_cnt_pd',
            title: 'В день',
            sorter: (a, b) => a.all_cnt_pd - b.all_cnt_pd,
            sortDirections: ['descend', 'ascend'],
            render: (cell) => {
              if (cell !== null) return cell;
              return '-';
            },
          },
        ],
      },
      {
        title: 'Закрытых',
        children: [
          {
            dataIndex: 'close_cnt',
            title: 'Всего',
            sorter: (a, b) => a.close_cnt - b.close_cnt,
            sortDirections: ['descend', 'ascend'],
            render: (cell) => {
              if (cell !== null) return cell;
              return '-';
            },
          },
          {
            dataIndex: 'close_cnt_pd',
            title: 'В день',
            sorter: (a, b) => a.close_cnt_pd - b.close_cnt_pd,
            sortDirections: ['descend', 'ascend'],
            render: (cell) => {
              if (cell !== null) return cell;
              return '-';
            },
          },
        ],
      },
      {
        dataIndex: '5',
        title: 'Закрытых по неявке',
        children: [
          {
            dataIndex: 'none_cnt',
            title: 'Всего',
            sorter: (a, b) => a.none_cnt - b.none_cnt,
            sortDirections: ['descend', 'ascend'],
            render: (cell) => {
              if (cell !== null) return cell;
              return '-';
            },
          },
          {
            dataIndex: 'none_cnt_pd',
            title: 'В день',
            sorter: (a, b) => a.none_cnt_pd - b.none_cnt_pd,
            sortDirections: ['descend', 'ascend'],
            render: (cell) => {
              if (cell !== null) return cell;
              return '-';
            },
          },
        ],
      },
      {
        dataIndex: '9',
        isDummyField: true,
        title: 'Удаленные системой',
        children: [
          {
            dataIndex: 'sys_close_cnt',
            title: 'Всего',
            sorter: (a, b) => a.sys_close_cnt - b.sys_close_cnt,
            sortDirections: ['descend', 'ascend'],
            render: (cell) => {
              if (cell !== null) return cell;
              return '-';
            },
          },
          {
            dataIndex: 'sys_close_pd',
            title: 'В день',
            sorter: (a, b) => a.sys_close_pd - b.sys_close_pd,
            sortDirections: ['descend', 'ascend'],
            render: (cell) => {
              if (cell !== null) return cell;
              return '-';
            },
          },
        ],
      },
    ],
  };
}
