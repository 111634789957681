import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Request from '../../../../../logic/utils/request';
import InputSelect from '../../../../../pages/private/components/Input/SelectInput';
import { Input } from '../../../../../common/ui/Common';
import {
  CalendarWrapper, DefaultWeekBox, CalendarBody, ShadowChevron, CalendarYear, CalendarBox, InputBox,
} from './style';
import DrawCalendar from './DrawCalendar';
import Modal from '../../../../../common/ui/Modal/Modal';
import ModalButtons from '../../../../../common/ui/Modal/ModalButtons';

function Calendar({ write, preloader, showError }) {
  const weekDayName = [
    { name: 'Не выбрано', val: 0 },
    { name: 'Понедельник', val: 1 },
    { name: 'Вторник', val: 2 },
    { name: 'Среда', val: 3 },
    { name: 'Четверг', val: 4 },
    { name: 'Пятница', val: 5 },
    { name: 'Суббота', val: 6 },
    { name: 'Воскресение', val: 7 },
  ];
  const [open, setOpen] = useState(false);
  const [calendar, setCalendar] = useState(false);

  const [date, setDate] = useState({
    year: moment().year(),
    monthNumber: 1,
    dayOfMonth: 1,
    isDayOfWeekForAllQ: 0,
  });

  const currentYear = moment().year();

  const optionsModal = weekDayName.map((el) => ({ name: el.name, id: el.val }));

  const getCalendar = useCallback(async () => {
    preloader();
    try {
      const year = date.year ? `year=${date.year}` : '';
      await Request('/server/api/dcalendar/', 'get', {}, year)
        .then((res) => res.data.data)
        .then((data) => {
          const cal = {};
          data.forEach((item) => {
            cal[item.monthNumber] = data.filter((el) => el.monthNumber === item.monthNumber);
          });
          setCalendar(cal);
        });
      preloader();
    } catch (e) {
      preloader();
      showError(e.data.error);
    }
  }, [preloader, showError, date.year]);

  useEffect(() => {
    getCalendar();
  }, [getCalendar]);

  const openModal = (d) => {
    const {
      dayOfMonth, monthNumber, isDayOfWeekForAllQ, dayComment, dayId,
    } = d;
    setDate({
      ...date, dayOfMonth, monthNumber, isDayOfWeekForAllQ, dayComment, dayId,
    });
    setOpen(true);
  };

  const setChange = ({ name, value }) => {
    const { dayOfMonth, monthNumber } = date;
    const editedMonth = calendar[monthNumber]
      .map((el) => (el.dayOfMonth === dayOfMonth ? { ...el, [name]: value } : el));
    const NewCalendar = { ...calendar, [monthNumber]: editedMonth };
    setCalendar(NewCalendar);
  };

  const modifiedDay = ({ target: { input, value } }) => {
    if (input) {
      setChange({ name: 'dayComment', value });
      setDate({ ...date, dayComment: value });
    } else {
      setChange({ name: 'isDayOfWeekForAllQ', value });
      setDate({ ...date, isDayOfWeekForAllQ: value });
    }
  };

  const saveChange = () => {
    const {
      isHolidayForAllQ = 0, isDayOfWeekForAllQ, dayComment, dayId,
    } = date;
    const body = {
      isHolidayForAllQ,
      isDayOfWeekForAllQ,
      dayComment,
    };
    Request(`/server/api/dcalendar/${dayId}/`, 'update', body)
      .then(() => {
        setOpen(false);
        toast.success('Выполнено');
      });
  };

  const changeYear = (num) => {
    setDate({
      ...date,
      year: date.year + num,
    });
  };
  const max = +currentYear + 50;
  const options = [];
  for (let i = +currentYear; i <= max; i += 1) {
    options.push({ id: i, name: i });
  }

  return (
    <CalendarWrapper>
      <DefaultWeekBox />
      <CalendarBox>
        <CalendarYear>
          <h1>
            {currentYear < date.year
              ? <FaChevronLeft onClick={() => changeYear(-1)} />
              : <ShadowChevron />}
            <InputSelect align="center" maxHeight="400px" noDefault value={date.year} options={options} onChange={({ target: { value } }) => setDate({ ...date, year: value })} />
            <FaChevronRight onClick={() => changeYear(+1)} />
          </h1>
        </CalendarYear>
        <CalendarBody>
          <DrawCalendar cal={calendar} modifiendDay={openModal} date={date} />
        </CalendarBody>
      </CalendarBox>
      <Modal
        title={`Изменение дня - ${date.dayOfMonth}.${moment(date.monthNumber, 'MM').format('MM')}`}
        isOpen={open}
        toggleOpen={() => setOpen(false)}
      >
        <InputBox>
          <p>День недели</p>
          {Object.keys(calendar).length > 0 && <InputSelect disabled={!write} noDefault onChange={modifiedDay} value={date.isDayOfWeekForAllQ} width="100%" options={optionsModal} />}
        </InputBox>
        <InputBox>
          <p>Комментарий</p>
          {Object.keys(calendar).length > 0 && <Input disabled={!write} onChange={(e) => modifiedDay({ target: { input: true, value: e.target.value } })} value={date.dayComment} width="100%" />}
        </InputBox>
        <ModalButtons isValid update updateFunc={saveChange} reset={() => setOpen(false)} />
      </Modal>
    </CalendarWrapper>
  );
}

export default Calendar;
