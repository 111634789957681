export function getWhere({
  before, from, value, comparator,
}, filld) {
  if (filld === 'create_data' || 'close_data') {
    if (value === '') return 'all';
    return `${comparator} '${value}'::date`;
  }
  switch (comparator) {
    case 'range':
      if (before && from !== '') {
        return `> '${from}'::timestamp and ${filld}::timestamp < '${before}'::timestamp`;
      }
      return 'all';
    case 'none':
      return 'all';
    default:
      if (value !== '') {
        return `${comparator} '${value}'::timestamp`;
      }
      return 'all';
  }
}

export default null;
