import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import useInterval from '../../utils/useInterval';
import { ButtonQueue, QueueList } from '../../styles/Operator';

function QueuesList(props) {
  const { wsClient, singleButton = false, setCurrentTicket } = props;
  const [queueList, setQueueList] = useState([]);
  const [totalTickets, setTotalTickets] = useState(0);

  const getQueueData = useCallback(async () => {
    try {
      const qData = await wsClient.sendMessage({
        command: 'rmoGetQueuesInfo',
      });
      const { queues } = qData;
      let tTickets = 0;
      queues.forEach((q) => {
        if (q.name !== 'WPLACES') tTickets += q.tickets.length;
      });
      if (singleButton) {
        setQueueList(queues.filter((q) => q.name === 'WPLACES'));
      } else {
        setQueueList(queues);
      }
      setTotalTickets(tTickets);
    } catch (e) {
      const { text: { rus } } = e;
      toast.error(rus, 3000);
      // setError(rus);
    }
  }, [singleButton, wsClient]);

  const callTicket = async (qid = null) => {
    try {
      const command = {
        command: 'rmoCalledTicket',
      };
      if (qid > 0) {
        command.idQ = qid;
      }
      const ticketRequest = await wsClient.sendMessage(command);
      const { ticketId, queueName, ticketNumber } = ticketRequest;
      if (ticketId) {
        try {
          const rmoTakingTicketRequest = await wsClient.sendMessage({
            command: 'rmoTakingTicket',
          });
          const { status } = rmoTakingTicketRequest;
          if (status === 'ok') {
            setCurrentTicket({
              ticketId,
              ticketNumber,
              queueName,
            });
          } else {
            throw new Error('Error taking ticket');
          }
        } catch (e) {
          setCurrentTicket({});
          const { text: { rus } } = e;
          toast.error(rus, 3000);
          // setError(rus);
        }
      }
    } catch (e) {
      const { text: { rus } } = e;
      toast.error(rus, 3000);
      // setError(rus);
    }
  };

  useEffect(() => {
    getQueueData();
  }, [getQueueData]);

  useInterval(() => {
    getQueueData();
  }, 5000);

  return (
    queueList.length > 0
      ? (
        <QueueList>
          {queueList.map((queue) => (
            <ButtonQueue key={queue.name} onClick={() => callTicket(queue.id)}>
              {`${queue.fname} (${queue.tickets.length})`}
            </ButtonQueue>
          ))}
          <ButtonQueue onClick={() => callTicket()}>
            {`Следующий (${totalTickets})`}
          </ButtonQueue>
        </QueueList>
      ) : <div>Получение данных...</div>
  );
}
export default QueuesList;
