import React from 'react';
import {
  FaAlignLeft, FaAlignCenter, FaAlignRight,
} from 'react-icons/fa';

const align = ({
  value, title, element, dispatch, paramName = 'textAlign',
}) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className="box__color">
    {/* eslint-disable-next-line react/no-danger */}
    <span className="settings__styles__el__label" dangerouslySetInnerHTML={{ __html: title }} />
    <div className="box__align">
      <div
        className="box__align__item"
        onClick={() => dispatch({
          type: 'params',
          name: element,
          param: paramName,
          value: 'left',
        })}
        style={{ color: value === 'left' ? 'black' : '#a7a7a7' }}
      >
        <FaAlignLeft />
      </div>
      <div
        className="box__align__item"
        onClick={() => dispatch({
          type: 'params',
          name: element,
          param: paramName,
          value: 'center',
        })}
        style={{ color: value === 'center' ? 'black' : '#a7a7a7' }}
      >
        <FaAlignCenter />
      </div>
      <div
        className="box__align__item"
        onClick={() => dispatch({
          type: 'params',
          name: element,
          param: paramName,
          value: 'right',
        })}
        style={{ color: value === 'right' ? 'black' : '#a7a7a7' }}
      >
        <FaAlignRight />
      </div>
    </div>
  </label>
);

export default align;
