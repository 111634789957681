import React from 'react';
import { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { ReactComponent as Edit } from '../../../../../../../../../common/images/edit.svg';
import c from '../../../../../../../../../common/ui/theme/Colors';
import { HistoryTableStatusTemplate } from '../../../../utils/formater';

function priceFormatter(column, colIndex, { filterElement }) {
  return (
    <div className="filterBoxTable">
      { filterElement }
      {column.dataField !== '14' && <Edit style={{ margin: '2px 10px 0 0' }} fill={c.main} /> }
    </div>
  );
}

function operatorTmpl(cell, row) {
  if (row[6] === '') {
    return (
      <div>
        <span>{row[5]}</span>
      </div>
    );
  }
  return (
    <div>
      <span>{row[6]}</span>
    </div>
  );
}

function workPlaceTmpl(cell, row) {
  if (row[18] !== null) {
    return (
      <div>
        <span>{row[18]}</span>
      </div>
    );
  }
  return (
    <div />
  );
}

export default function TableConfig() {
  const statusList = {
    0: 'Талон создан',
    1: 'Вызван на обработку',
    2: 'Процесс обработки',
    3: 'Закрыт',
    4: 'Закрыт по неявке',
    5: 'Переведен в другую очередь',
    6: 'Решение отложено',
    7: 'Возвращен в очередь',
    8: 'Ручная обработка диспетчером',
    9: 'Удален системой',
    10: 'Смена очереди',
    11: 'Переведен на рабочее место',
    12: 'Начато решение вопроса',
    13: 'Завершено решение вопроса.',
  };
  return {
    columns: [{
      dataField: '1',
      text: 'Дата выполнения',
      sort: true,
      editable: false,
      headerStyle: {
        width: 205,
      },
      filter: textFilter({
        className: 'customPlaceholder',
        placeholder: 'Дата выполнения',
      }),
      headerFormatter: priceFormatter,
    },
    {
      dataField: '5',
      isDummyField: true,
      text: 'Оператор',
      editable: false,
      formatter: operatorTmpl,
      filter: textFilter({
        className: 'customPlaceholder',
        placeholder: 'Оператор',
      }),
      headerFormatter: priceFormatter,
    },
    {
      dataField: '18',
      isDummyField: true,
      text: 'Рабочее место',
      editable: false,
      formatter: workPlaceTmpl,
    },
    {
      dataField: '4',
      text: 'Очередь',
      editable: false,
    },
    {
      dataField: '15',
      text: 'Длительность обслуживания',
      sort: true,
      editable: false,
      headerStyle: {
        width: 165,
      },
    },
    {
      dataField: '16',
      text: 'Комментарий',
      editable: false,
    },
    {
      dataField: '14',
      text: 'Статус',
      formatter: (cell) => HistoryTableStatusTemplate(cell),
      editable: false,
      headerFormatter: priceFormatter,
      filter: selectFilter({
        options: statusList,
        placeholder: 'Статус',
      }),
      headerStyle: {
        width: 185,
      },
    }],
  };
}
