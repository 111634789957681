import React from 'react';
import { Button } from 'antd';
import randomId from '../../../../logic/utils/randomId';

const TerminalScreenButtons = ({ screen, goPath, styleConfig }) => {
  const { buttons } = screen;
  const { button } = styleConfig;

  const renderExtraText = (text) => {
    const spans = text.split('//');
    return spans.map((el) => (
      <div key={randomId()} className="button__subText" style={{ color: button.color }}>{el}</div>
    ));
  };

  return buttons.map((btn) => (
    <Button
      type="primary"
      size="large"
      key={btn.id}
      onClick={() => goPath(btn.path, btn.params)}
    >
      {btn.icon && <img src={btn.icon} alt="" />}
      {btn.name}
      {btn.extraText && <div className="button__extraContainer">{renderExtraText(btn.extraText)}</div>}
    </Button>
  ));
};

export default TerminalScreenButtons;
