import React from 'react';
import { CheckBox, InputCheck, Check } from '../../../../common/ui/Common';

function CheckBoxComp({
  value, onChange, checked, name,
}) {
  return (
    <CheckBox>
      <InputCheck
        type="checkbox"
        value={value}
        name={name}
        onChange={() => onChange({ target: { name, value: !value, type: 'checkbox' } })}
        checked={checked}
      />
      <Check />
    </CheckBox>
  );
}

export default CheckBoxComp;
