export default [{
  write: false,
  links: ['/operator/'],
  name: 'eq_operator',
},
{
  write: true,
  links: [
    '/monitoring/',
    '/qualityControl/',
    '/about/',
    '/changelist/',
    '/action-log/',
    '/archive/',
    '/fields/',
    '/groupings/',
    '/services/',
    '/services/:name/',
    '/services/:name/:ticketId',
    '/params/',
    '/workplaces/',
    '/digit-board/',
    '/tickets/',
    '/tickets/ticketConstructor/:id',
    '/custom/',
    '/custom/:id',
    '/reserve/',
    '/operator/',
    '/tickets/',
    '/operator/',
    '/quality-tablet/',
    '/devices/',
    '/devices/:id',
    '/handle-points/',
    '/handle-points/:id',
    '/handle-points/:id/:screenId',
    '/zones/',
    '/zones/:id/',
    '/users/',
    '/reports/',
    '/pre-entry/',
    '/sync/',
    '/lottery/',
    '/session/',
    '/service/service-list/',
    '/services/pre-entry',
  ],
  name: 'eq_root',
},
{
  write: true,
  links: [
    '/monitoring/',
    '/qualityControl/',
    '/about',
    '/action-log/',
    '/archive/',
    '/fields/',
    '/groupings/',
    '/services/',
    '/params/',
    '/workplaces/',
    '/digit-board/',
    '/tickets/',
    '/tickets/ticketConstructor/:id',
    '/custom/',
    '/operator/',
    '/quality-tablet/',
    '/tickets/',
    '/operator/',
    '/devices/',
    '/devices/:id',
    '/handle-points/',
    '/handle-points/:id',
    '/handle-points/:id/:screenId',
    '/zones/',
    '/zones/:id/',
    '/users/',
    '/reports/',
  ],
  name: 'eq_admin',
},
{
  write: false,
  links: [
    '/monitoring/',
    '/reports/',
    '/archive/',
    '/qualityControl/',
    '/quality-tablet/',
    '/about',
    '/action-log/',
    '/fields/',
    '/groupings/',
    '/services/',
    '/params/',
    '/workplaces/',
    '/digit-board/',
    '/devices/',
    '/devices/:id',
    '/handle-points/',
    '/handle-points/:id',
    '/handle-points/:id/:screenId',
    '/zones/',
    '/zones/:id/',
    '/users/',
  ],
  name: 'eq_manager',
},
{
  write: false,
  links: ['/reports/'],
  name: 'eq_reporter',
},
{
  write: false,
  links: ['/monitoring/'],
  name: 'eq_controller',
},
// {
//  write: false,
//  links: ['/qualityControl/'],
//  name: 'eq_controller',
// },
];
