const Hyphenation = (context, text, x, yParam, maxWidth, lineHeight, fontSize) => {
  const words = text.split(' ');
  let line = '';
  let y = yParam;
  for (let n = 0; n < words.length; n += 1) {
    const testLine = `${line + words[n]} `;
    const metrics = context.measureText(testLine);
    const testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      context.fillText(line, x, y);
      line = `${words[n]} `;
      y = +y + +fontSize * lineHeight;
    } else {
      line = testLine;
    }
  }
  context.fillText(line, x, y);
};

export default Hyphenation;
