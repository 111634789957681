import React from 'react';
import Input from '../../../../../pages/private/components/Input/Input';

function RenderInputs({
  displayData, setDisplayData, setIsValid,
}) {
  const handleInput = (e) => {
    const { name, value } = e.target;
    const newValue = value === ' ' ? '' : value;
    const validData = { ...displayData, [name]: newValue };
    const {
      name: iV, address: pV,
    } = validData;

    setDisplayData({ ...validData });
    if (iV && pV) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const {
    name, address, number, enabled, type,
  } = displayData;

  const options = [
    { id: 'abt_matrix', name: 'abt_matrix' },
    { id: 'akme_udp', name: 'akme_udp' },
    { id: 'yanic_udp', name: 'yanic_udp' },
    { id: 'neuroniq_tcp', name: 'neuroniq_tcp' },
    { id: 'leto', name: 'leto' },
    { id: 'pcs', name: 'pcs' },
    { id: 'android_socket', name: 'android_socket' },
    { id: 'pcs_3tab', name: 'pcs_3tab' },
    { id: 'neuroniq', name: 'neuroniq' },
    { id: 'akis_serial', name: 'akis_serial' },
    { id: 'abit_serial', name: 'abit_serial' },
    { id: 'akis_udp', name: 'akis_udp' },
    { id: 'alniko_udp', name: 'alniko_udp' },
    { id: 'saratov', name: 'saratov' },
  ];

  const items = [
    { label: 'Наименование:', name: 'name', value: name || '' },
    { label: 'IP адрес:', name: 'address', value: address || '' },
    { label: 'Номер:', name: 'number', value: number },
    {
      label: 'Тип:', name: 'type', value: type, type: 'select', options, noDefault: true, m: '0 0 15px',
    },
    {
      label: 'Включен', name: 'enabled', type: 'checkbox', value: enabled || false,
    },
  ];

  return (
    <>
      {items.map((item) => (
        <Input
          key={item.name}
          item={item}
          m={item.m}
          onChange={handleInput}
        />
      ))}
    </>

  );
}
export default RenderInputs;
