import React, { useContext } from 'react';
import { Collapse } from 'antd'; // DOCS: https://ant.design/components/collapse/
import BoardCtx from '../../../../../../../../../pages/public/board/utils/BoardCtx';
import Height from '../../../../Components/modules/height';
import FontFamily from '../../../../Components/FontFamily';
import FontSize from '../../../../Components/FontSize';
import Colors from '../../../../Components/Colors';
import colors from './colors';
import fontSize from './fontSize';
import fontFamily from './fontFamily';
import TextComponent from '../../../../Components/modules/text';
import MarginComponent from '../../../../Components/modules/Margin';
import Order from '../../../../Components/modules/order';
import PositionContent from '../../../../Components/modules/positionContent';
import Align from '../../../../Components/modules/align';

function News() {
  const {
    dispatch, layout, fonts, dispatchText, text,
  } = useContext(BoardCtx);
  const { news } = layout;
  const { Panel } = Collapse;

  const arrColors = colors({ news });
  const arrFontSize = fontSize({ news });
  const arrFont = fontFamily({ news });

  return (
    <Collapse className="customize-Collapse" ghost>
      <Panel className="customize-Collapse-Header-No-Top-Padding" header="Текст" key="1">
        <TextComponent title='Заголовок раздела "Новости"' element="text" param="news1" value={text.news1} dispatch={dispatchText} />
      </Panel>

      <Panel className="customize-Collapse-Header-No-Top-Padding" header="Параметры текста" key="2">
        <Collapse className="customize-Collapse" ghost>
          <Panel header="Размер" key="1">
            <FontSize arr={arrFontSize} dispatch={dispatch} />
          </Panel>
          <Panel header="Шрифт" key="2">
            <FontFamily options={fonts} arr={arrFont} dispatch={dispatch} />
          </Panel>
          <Align
            element="news"
            value={news.textAlign}
            dispatch={dispatch}
            title="Выравнивание текста"
            paramName="textAlign"
          />
        </Collapse>
      </Panel>

      <Panel header="Параметры элементов" key="3">
        <Height title="Толщина рамки" width="160px" element="news" nameParams="borderSize1" value={news.borderSize1} dispatch={dispatch} />
        <Order element="news" dispatch={dispatch} value={news.order} title="Позиция" />
        <PositionContent element="news" dispatch={dispatch} paramName="flexDirection" value={news.flexDirection} title="Ориентация" />
        <MarginComponent data={news} dispatch={dispatch} element="news" name="margin" title="Отступы" />
      </Panel>

      <Panel className="customize-Collapse-Colors" header="Цвет" key="4">
        <Colors arr={arrColors} dispatch={dispatch} />
      </Panel>
    </Collapse>
  );
}

export default News;
