import React, { useState, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { ReactComponent as Picture } from '../../../../../../../common/images/picture.svg';
import Close from '../../../../../../../common/images/close.png';

function UploadImages({ items, smart, dispatch = () => { } }) {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(smart);
  const [arrDiff, setDiff] = useState([true, true, true, true]);

  const diff = 120 / 409;

  const uploadLogo = (e, value, isDrop) => {
    let file;
    if (isDrop) {
      const dt = e.dataTransfer;
      const { files } = dt;
      // eslint-disable-next-line prefer-destructuring
      file = files[0];
    } else {
      // eslint-disable-next-line prefer-destructuring
      file = e.target.files[0];
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (() => (evt) => {
      const image = evt.target;
      if (image.error === null) {
        dispatch({ type: 'uploadImage', payload: image.result, value });
      } else {
        // eslint-disable-next-line no-console
        console.log('error upload image');
      }
    })(file);
  };

  const handleActive = (e, el) => {
    e.preventDefault();
    if (active !== el) setActive(el);
  };
  const handleDrop = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    setActive(false);
    uploadLogo(e, value, 'drop');
  };

  useEffect(() => {
    const arrPromise = [];
    items.forEach((el) => {
      arrPromise.push(new Promise((resolve) => {
        if (el.img) {
          const img = new Image();
          img.onload = () => {
            resolve(diff < img.height / img.width);
          };
          img.src = el.img;
        } else {
          resolve(true);
        }
      }));
    });
    Promise.all(arrPromise).then((values) => {
      setDiff(values);
    });
  }, [items, diff]);

  const renderItems = () => items.map((item, index) => (
    <div key={item.value} className="upload__item">
      <p className="upload__item__title">
        {item.name}
      </p>
      <div className="upload__item__content">
        <div className="upload__label__box">
          <div>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              htmlFor={`input${item.value}`}
              onDragEnter={(e) => handleActive(e, item.value)}
              onDragOver={(e) => handleActive(e, item.value)}
              onDragLeave={(e) => handleActive(e, false)}
              onDrop={(e) => handleDrop(e, item.value)}
              className="upload__label__el"
              style={{ backgroundColor: active === item.value ? 'orange' : 'transparent' }}
            >
              <span>Перетащите изображение сюда</span>
              <span>Или нажмите здесь для загрузки изображения</span>
            </label>
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              id={`input${item.value}`}
              onChange={(e) => uploadLogo(e, item.value)}
            />
          </div>
        </div>
        <div className={`upload__label__box__preview ${arrDiff[index] && 'vertical'}`}>
          {item.img && (
            <div
              className="upload__label__el__delete"
              onClick={() => dispatch({ type: 'delImage', value: item.value, payload: 'none' })}
            >
              <img src={Close} alt="" />
            </div>
          )}
          {item.img ? <img src={item.img} alt="" /> : <p>изображение не загружено</p>}
        </div>
      </div>
    </div>
  ));

  return (
    <div className={`settings__images_upload ${smart}`}>
      {!smart && (
      <button type="button" className={`settings__images_upload__btn ${open ? 'open' : 'hide'}`} onClick={() => setOpen(!open)}>
        <Picture className="left" />
        <p>Загрузите изображения</p>
        <FaChevronDown className="right" />
      </button>
      )}
      {open && <div className="settings__labels">{renderItems()}</div>}
    </div>
  );
}

export default UploadImages;
