import React from 'react';
import { ReactComponent as Download } from '../../../../../common/images/download.svg';
import { ReactComponent as Trash } from '../../../../../common/images/bin.svg';
import { FontList } from '../../../../../common/ui/Common';

function List({ fonts = [], getFont, deleteFont }) {
  return (
    <FontList>
      <p>Список загруженных шрифтов</p>
      <ul>
        {fonts.map((el) => (
          <li key={el.id}>
            <p>{el.name}</p>
            <div>
              <span className="green" onClick={() => getFont(el.name)}><Download /></span>
              <span onClick={() => deleteFont(el.name)}><Trash /></span>
            </div>
          </li>
        ))}
      </ul>
    </FontList>
  );
}

export default List;
