import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import {
  Combo, FilterList, Filter, FilterInput,
} from '../../../styles/ComboBox';

function ComboBox({
  list, setData, column, clear, placeholder,
}) {
  const [val, setVal] = useState('');
  const [fullList, setFullList] = useState([]);
  const [isFilter, setIsFilter] = useState(false);

  useEffect(() => {
    setVal('');
  }, [clear]);

  const handleSearch = (e) => {
    const { value } = e.target;
    const lowerValue = value.toLowerCase();
    setVal(value);
    Object.filter = (obj, predicate) => Object.keys(obj)
      .filter((key) => predicate(obj[key]))
      .reduce((res, key) => ({ ...res, [key]: obj[key] }), {});

    const filtered = Object.filter(list, (item) => item.toLowerCase().indexOf(lowerValue) !== -1);
    const someArr = [];

    const filteredArr = Object.values(filtered);
    const filterKeys = Object.keys(filtered);
    filterKeys.forEach((item, i) => {
      someArr.push({ id: item, name: filteredArr[i] });
    });
    setFullList(someArr);
  };

  const pickOption = (e) => {
    const { id } = e.target.dataset;
    setVal(e.target.outerText);
    setData(Number(id), column);
  };

  const closeFilter = () => {
    setTimeout(() => {
      setIsFilter(false);
    }, 200);
  };

  const resetFilter = () => {
    setVal('');
    setData(null, column);
  };

  const renderOptions = () => fullList.map((item) => (
    <Filter
      key={item.id}
      onClick={pickOption}
      data-id={item.id}
    >
      {item.name}
    </Filter>
  ));

  return (
    <Combo>
      <div style={{ position: 'relative' }}>
        <FilterInput
          type="text"
          value={val}
          onChange={handleSearch}
          onBlur={closeFilter}
          onClick={(e) => { setIsFilter(true); handleSearch(e); }}
          placeholder={placeholder}
        />
        {val && (
        <span onClick={resetFilter}>
          <FaTimes />
        </span>
        )}
      </div>
      <FilterList show={isFilter}>
        {renderOptions()}
      </FilterList>
    </Combo>
  );
}

export default ComboBox;
