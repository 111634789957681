import React from 'react';
import Position from './modules/position';
import PositionContent from './modules/positionContent';

function Positions({
  arr, dispatch, content, children,
}) {
  return (
    <>
      {arr.map((el) => (content
        ? (
          <PositionContent
            element={el.parentName}
            value={el.value}
            dispatch={dispatch}
            icon={el.icon}
            paramName={el.paramName}
            title={el.title}
            key={el.title}
          />
        )
        : (
          <Position
            element={el.parentName}
            value={el.value}
            dispatch={dispatch}
            title={el.title}
            key={el.title}
          />
        )))}
      {children}
    </>
  );
}

export default Positions;
