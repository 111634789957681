import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SelectedInput from './selectedInput';
import {
  InputBox, KeyboardContainer,
} from '../../utils/style';
import Input from '../../../../../../common/ui/Input/input';

function GetInput(props) {
  const navigate = useNavigate();

  const { ticket, style } = useSelector((state) => ({ ...state, style: state.style.input }));

  const {
    scene, history, styleButton, styleBottom, selectScreen, id,
  } = props;

  const { buttons } = scene;

  const [inputId, setInputId] = useState(undefined);
  const [data, setData] = useState({});

  let extraId = 0;
  if (!selectScreen) extraId = inputId;

  const getStartData = () => {
    const startData = {};
    // eslint-disable-next-line no-return-assign
    buttons.forEach((el) => startData[el.params.fid] = '');
    // eslint-disable-next-line no-return-assign
    ticket.dictionary.forEach((dict) => {
      if (dict.input) startData[dict.fid] = dict.fv;
    });

    setData(startData);
  };

  useEffect(() => {
    getStartData();
  }, [inputId]);

  const clickInput = (propId) => {
    if (selectScreen) return;
    setInputId(propId);
  };

  const onNext = (back) => {
    if (back) history.goBack();
    if (!id) navigate(scene.screenPath);
  };

  return inputId
    ? (
      <SelectedInput
        history={history}
        setInputId={setInputId}
        screenPath={scene.screenPath}
        style={style}
        matchId={extraId}
        styleBottom={styleBottom}
        data={data}
        onNext={onNext}
        styleButton={styleButton}
        button={buttons.find((el) => +el.params.fid === +inputId)}
      />
    )
    : (
      <KeyboardContainer>
        <InputBox button={style} style={{ marginBottom: 50 }}>
          {buttons.map((btn) => (
            <Input
              style={style}
              styleButton={styleButton}
              key={btn.id}
              item={btn}
              value={data[btn?.params?.fid]}
              id={btn.params.fid}
              buttonId={extraId}
              data={data}
              clickInput={clickInput}
            />
          ))}
        </InputBox>
      </KeyboardContainer>
    );
}

export default React.memo(GetInput);
