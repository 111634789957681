import React, { useState, useEffect, useCallback } from 'react';
import request from '../../../../logic/utils/request';
import ZonesTable from './ZonesTable';
import NewZone from './NewZone/NewZone';
import { Title, ContentBox, PreloaderBox } from '../../../../common/ui/Common';
import { useToggle } from '../../../../logic/hooks/useToggle';

function HandlePoints({ preloader, showError }) {
  const initialData = {
    name: '', longname: '', description: '', config: { params: [], screens: [] },
  };
  const [zoneList, setZoneList] = useState(null);
  const [zoneData, setZoneData] = useState(initialData);
  const [isOpen, toggleOpen] = useToggle(false);
  const [zoneUpdate, setZoneUpdate] = useState(false);

  const fetchZones = useCallback(async () => {
    const res = await request('/server/api/zone/', 'get');
    setZoneList(res.data.data);
  }, []);

  useEffect(() => {
    fetchZones();
  }, [fetchZones]);

  return (
    <>
      <Title>Зоны обслуживания</Title>
      <ContentBox>
        <NewZone
          initialData={initialData}
          zoneData={zoneData}
          setZoneData={setZoneData}
          toggleOpen={toggleOpen}
          isOpen={isOpen}
          zoneUpdate={zoneUpdate}
          setZoneUpdate={setZoneUpdate}
          fetchZones={fetchZones}
          showError={showError}
          preloader={preloader}
        />
        <PreloaderBox>
          <ZonesTable
            zoneList={zoneList}
            setZoneUpdate={setZoneUpdate}
            setZoneData={setZoneData}
            toggleOpen={toggleOpen}
            preloader={preloader}
            fetchZones={fetchZones}
            showError={showError}
          />
        </PreloaderBox>
      </ContentBox>
    </>
  );
}

export default HandlePoints;
