/* eslint-disable no-shadow */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';

import axios from 'axios';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  Button, Checkbox, DatePicker, Select, Space,
} from 'antd';
import moment from 'moment';
import TimeBar from './TimeBar';
import {
  FilterBox,
  FilterBoxTitle,
  FilterItemBox,
  ReportFiltersBox,
} from '../../../../components/ReportFilters/style';
import 'moment/locale/ru';
import filterStore, { updateReportStore } from '../../../../../../../../../logic/store/pullstate/filterStore';
import request from '../../../../../../../../../logic/utils/request';

const { RangePicker } = DatePicker;
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);
export function cut(input, words) {
  input = input.split(' ');
  const l = input.length;
  const ret = [];
  for (let i = 0; i < l; i += words) {
    ret.push(input.splice(0, words));
  }
  return ret.map((item) => item.join(' '));
}

const dateFormat = 'DD.MM.YYYY HH:mm';
function FirstChart() {
  const reportStore = filterStore.useState((s) => s);
  const { startDate: storeStartDate, endDate: storeEndDate } = reportStore;

  const [graphData, setGraphData] = useState();
  const [serviceList, setServiceList] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [isFullGraph, setIsFullGraph] = useState(true);

  const [graphParams, setGraphParams] = useState();

  const fetchChartData = async (startDateInput, endDateInput, queuesInput) => {
    // TODO
    const token = window.localStorage.getItem('token');

    const res = await axios({
      method: 'post',
      url: '/api/chart/timeconsumption/',
      data: {
        token,
        // startDate: '01.03.2022 00:00:00',
        // endDate: '01.03.2024 23:59:59',
        // queues: 'all',
        startDate: startDateInput,
        endDate: endDateInput,
        queues: queuesInput,
      },
    });
    setGraphData(res.data);

    // https://www.chartjs.org/docs/latest/samples/bar/stacked.html

    // avgproc : 164 -- Среднее время обслуживания
    // avgwait : 8036 -- Среднее время ожидания
    // fullname: "Геологические изыскания на местности и исследование
    // сопротивления грунтов в лаборатории"
    // maxDurationServicesTicket: 120  -- Максимальное допустимое время обслуживания
    // maxDurationWaitTicket: 7200 -- Максимальное допустимове время ожидания
    // fullname: "Геологические изыскания на местности и исследования"
    // q_id: 17
    // totalcnt: 50
  };

  const fetchServices = async () => {
    const { data } = await request('/server/api/queue/', 'get');
    setServiceData(data.data);
  };

  useEffect(() => {
    fetchChartData(storeStartDate, storeEndDate, 'all');
    fetchServices();
  }, []);

  useEffect(() => {
    setGraphParams({
      labels: graphData?.data.map((item) => {
        if (item.fullname.length > 35) {
          return cut(item.fullname, 4);
        }
        return item.fullname;
      }),
      datasets: [
        {
          label: 'Максимальное допустимое время обслуживания',
          data: graphData?.data.map((item) => (item.maxDurationServicesTicket / 60)),
          backgroundColor: 'rgba(75, 192, 192, .3)',
          borderColor: 'rgba(75, 192, 192)',
          borderWidth: 2,
          stack: 'Stack 0',
        },
        {
          label: 'Среднее время обслуживания',
          data: graphData?.data.map((item) => (item.avgproc / 60)),
          backgroundColor: graphData?.data.map((item) => {
            if (item.maxDurationServicesTicket > item.avgproc) {
              return 'rgb(134,236,77)';
            }
            return 'rgb(222,110,110)';
          }),
          stack: 'Stack 0',
        },
        {
          label: 'Максимальное допустимое время ожидания',
          data: graphData?.data.map((item) => (item.maxDurationWaitTicket / 60)),
          backgroundColor: 'rgba(75, 192, 192, .3)',
          borderColor: 'rgba(75, 192, 192)',
          borderWidth: 2,
          stack: 'Stack 1',
        },
        {
          label: 'Среднее время ожидания',
          data: graphData?.data.map((item) => (item.avgwait / 60)),
          backgroundColor: graphData?.data.map((item) => {
            if (item.maxDurationWaitTicket > item.avgwait) {
              return 'rgb(134,236,77)';
            }
            return 'rgb(222,110,110)';
          }),
          stack: 'Stack 1',
        },
      ],
    });
  }, [graphData]);

  const handleSelectChange = (value) => {
    setServiceList(value);
  };

  const handleFilter = () => {
    if (serviceList.length > 0) {
      fetchChartData(storeStartDate, storeEndDate, serviceList);
    } else {
      fetchChartData(storeStartDate, storeEndDate, 'all');
    }
  };

  const rangePickerChange = (value, dateString) => {
    updateReportStore({
      startDate: dateString[0],
      endDate: dateString[1],
    });
  };

  return (
    <>
      <ReportFiltersBox>
        <div style={{ display: 'flex' }}>
          <FilterBox style={{ flexShrink: 0 }}>
            <FilterBoxTitle>Период</FilterBoxTitle>
            <FilterItemBox>
              <RangePicker
                showTime
                onChange={rangePickerChange}
                format={dateFormat}
                defaultValue={[moment(storeStartDate, dateFormat), moment(storeEndDate, dateFormat)]}
                style={{ width: '100%' }}
              />
            </FilterItemBox>
          </FilterBox>
          <FilterBox style={{ width: '100%' }}>
            <FilterBoxTitle>Услуги</FilterBoxTitle>
            <FilterItemBox>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Выберите услуги"
                onChange={handleSelectChange}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={serviceData?.map((item) => ({
                  value: item.id,
                  label: item.fullname,
                }))}
              />
            </FilterItemBox>
          </FilterBox>
        </div>
        <Space style={{ margin: '15px auto 0 auto', display: 'block' }} size="small" direction="horizontal">
          <Button size="large" type="primary" onClick={handleFilter}>Применить</Button>
        </Space>
      </ReportFiltersBox>

      <ReportFiltersBox>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div id="legend-container" />
          <Checkbox
            checked={isFullGraph}
            onChange={() => setIsFullGraph(!isFullGraph)}
          >
            Общий вид
          </Checkbox>
        </div>
        <div style={{ maxWidth: '1500px', width: '100%', overflowX: 'scroll' }}>
          <div style={{
            height: '700px',
            width: `${graphData?.data.length > 4 && !isFullGraph
              ? `${graphData?.data?.length * 375}px` : '100%'}`,
          }}
          >
            {graphParams && <TimeBar data={graphParams} graphData={graphData} />}

          </div>
        </div>
      </ReportFiltersBox>
    </>
  );
}

export default FirstChart;
