import React from 'react';
import Height from './modules/height';

function HeightComponent({
  arr, dispatch, text, width, widthInput, children,
}) {
  return (
    <>
      {text && <p>{text}</p>}
      {arr.map((el) => (
        <Height
          width={width}
          widthInput={widthInput}
          element={el.parentName}
          nameParams={el.paramName}
          dispatch={dispatch}
          title={el.title}
          value={el.value}
          key={el.title}
        />
      ))}
      {children}
    </>
  );
}

export default HeightComponent;
