import React, { useState, useEffect, useCallback } from 'react';
import NewQuestion from './NewQuestion/NewQuestion';
import request from '../../../../../logic/utils/request';
import QuestionsTable from './QuestionsTable';
import { PreloaderBox } from '../../../../../common/ui/Common';
import { useToggle } from '../../../../../logic/hooks/useToggle';

function Questions(props) {
  const { preloader, write, showError } = props;
  const initialData = {
    name: '', displayname: '', description: '', connectedQueues: [], regulatoryTime: '',
  };
  const [questList, setQuestList] = useState([]);
  const [queueList, setQueueList] = useState([]);
  const [isOpen, toggleOpen] = useToggle(false);
  const [questData, setQuestData] = useState({ ...initialData });
  const [questUpdate, setQuestUpdate] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchQuests = useCallback(async () => {
    preloader();
    try {
      await request('/server/api/servedquestion/', 'get')
        .then((res) => {
          setQuestList(res.data.data);
        });
      await request('/server/api/queue/', 'get')
        .then((res) => { setQueueList(res.data.data); });
      preloader();
      setIsLoaded(true);
    } catch (e) {
      preloader();
      setIsLoaded(true);
    }
  }, [preloader]);

  useEffect(() => {
    fetchQuests();
  }, [fetchQuests]);

  return (
    <div style={{ width: '100%' }}>
      <NewQuestion
        initialData={initialData}
        questData={questData}
        setQuestData={setQuestData}
        fetchQuests={fetchQuests}
        setQuestUpdate={setQuestUpdate}
        questUpdate={questUpdate}
        toggleOpen={toggleOpen}
        isOpen={isOpen}
        write={write}
        preloader={preloader}
      />
      <PreloaderBox>
        {isLoaded
        && (
        <QuestionsTable
          questList={questList}
          queueList={queueList}
          setQuestData={setQuestData}
          showError={showError}
          setQuestUpdate={setQuestUpdate}
          toggleOpen={toggleOpen}
          write={write}
          preloader={preloader}
          fetchQuests={fetchQuests}
        />
        )}
      </PreloaderBox>
    </div>
  );
}

export default Questions;
