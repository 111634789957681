import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';
import axios from 'axios';
import Carousel from './Carousel';

function News({ text, setActiveEl, layout }) {
  const [news, setNews] = useState([]);
  const [height, setHeight] = useState('100%');
  const [width, setWidth] = useState('100%');
  const container = useRef(null);
  //  const { layout } = useContext(BoardCtx);

  console.log('layout === ', layout);

  const {
    background,
    fontSize2,
    fontSize4,
    color1,
    fontSize1,
    color2,
    color3,
    color4,
    borderSize1,
    fontFamily1,
    fontFamily2,
    fontWeight4,
    fontFamily4,
  } = layout.news;

  const order = layout.news === 1 ? 0 : 1;
  const fetchNews = useCallback(async () => {
    const res = await axios({
      method: 'get',
      url: '/api/news',
    });
    setNews(res.data);
  }, []);

  useEffect(() => {
    setHeight(container.current ? container.current.clientHeight : '100%');
    setWidth(container.current ? container.current.clientWidth : '100%');
  }, [container, layout]);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  return (
    <div
      className="layout__body__info__top__news_box"
      style={{
        ...layout.news,
        background,
        border: `${borderSize1}px solid ${color2}`,
        borderRadius: layout.borderRadius ? '12px' : 0,
        order,
      }}
      ref={container}
      onClick={() => setActiveEl('news')}
    >
      <div style={{
        padding: '5px 20px', color: color4, fontSize: fontSize4, fontWeight: fontWeight4, fontFamily: fontFamily4,
      }}
      >
        { text.news1 }
      </div>
      <Carousel
        color={color1}
        fontSize1={fontSize1}
        fontSize2={fontSize2}
        fontFamily1={fontFamily1}
        fontFamily2={fontFamily2}
        arrows={false}
        autoPlayInterval={3000}
        height={height}
        width={width}
        navColor={color3}
        news={news}
        layout={layout}
      />
    </div>
  );
}
export default News;
