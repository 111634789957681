import styled, {
  createGlobalStyle,
  keyframes,
  css,
} from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  /* TRANSITIONS FOR ROUTES STYLES */
  .infoPreEntryText {
  margin-bottom: 50px;
    span {
      margin-left: 5px;
      color: ${(p) => p.theme.buttonHover};
      background: ${(p) => p.theme.accent};
      border-radius: 5px;
    }
  }
  .addButton {
    background: ${(p) => p.theme.accent};
    width: max-content;
    color: white;
    padding: 0.5em 2em;
    &:hover {
      color: white;
    }
  }
  .fade-enter {
    opacity: 0;
  }
  ::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 5px;
    background-color: ${(p) => p.theme.hover};
  }
  
  ::-webkit-scrollbar
  {
    width: 5px;
    height: 5px;
    background-color: ${(p) => p.theme.hover};
  }
  
  ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.3);
    background-color: ${(p) => p.theme.accent};
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: 300ms ease-in;
  }

  p {
    margin-bottom: 0;
  }
  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: 300ms ease-in;
  }

  .transitionGroup{
    height: 100%;
    width: 100%;
    position: relative;
  }
  .tox-notification {
    display: none;
  }

  html,body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
  }

  * {
    font-weight: 100;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
  }
  strong {
    font-weight: bold !important;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 800 !important;
    span {
      font: inherit
    }
  }
  h3, h2 {
    color: ${(p) => p.theme.lightText};
    margin-bottom: 30px;
  }
  h4 {
    color: ${(p) => p.theme.lightText};
    margin-bottom: 20px;
     margin-top: 20px;
  }
  .mt-1 {
    margin-top: 1rem;
  }
  .ml-1 {
    margin-left: 1rem;
  }
  .dis-inline {
    display: inline-block;
  }
 
  .recordBox {
    display: flex;
    flex-wrap: wrap;
    max-height: 500px;
    padding-right: 30px;
    overflow: auto;
  }
  .infoTimeBox {
    flex-wrap: nowrap;
    flex-direction: column;
    padding-right: 10px;
    .recordItem {
      height: auto;
      // width: 100%;
      width: 390px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      p {
        margin-bottom: 5px;
      }
      .time {
        padding: 0;
      }
      .timeMiniBox {
        position: relative;
        display: flex;
        flex-direction: column;

        .saveBtn {
          position: absolute;
          right: -58px;
          bottom: 5px;
          background: #fc4c02;
          width: 45px;
          display: flex;
          cursor: pointer;
          padding: 5px;
          justify-content: center;
          border-radius: 3px;
          svg {
            fill: ${(p) => p.theme.buttonHover};
          }
        }
        p {
          min-width: 100px;
          padding: 0 5px 0 10px;
          font-size: 14px;
          margin-bottom: 0;
        }
        .timeInputBox {
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          &:last-child {
            margin-bottom: 0;
          }
          svg {
           fill: ${(p) => p.theme.accent};
           cursor: text;
          }
        }
        input {
          border: 1px solid rgba(0,0,0,.05);
          padding: 0 15px;
          width: 100%;
          height: 30px;
          &:focus {
            border: none;
            border-bottom: 1px solid ${(p) => p.theme.accent};
            outline: none;
            border-radius: 3px;
           }
        }
      }
    }
    .infoBtn {
      display: flex;
    }
  }
  .calendarButtonBox {
    width: 100%;
    display: flex;
    margin: 30px -5px 0;
  }
  .recordItem {
    display: flex;
    align-items: center;
    margin: 5px;
    height: 40px;
    border: 1px solid ${(p) => p.theme.disable};
    padding: 5px;
    &.clear {
      background: ${(p) => p.theme.accent};
      color: ${(p) => p.theme.buttonHover};
      padding: 5px 20px;
      cursor: pointer;
    }
    &.green {
      background: ${(p) => p.theme.green};
    }
    .amount {
      font-size: 12px;
      padding: 3px 7px;
      border-radius: 50%;
      background: ${(p) => p.theme.accent};
      color: ${(p) => p.theme.buttonHover};
    }
    p {
      padding: 0 25px;
      margin-bottom: 0;
    }
    span {
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        height: 20px;
        width: 20px;
        fill: ${(p) => p.theme.accent};
      }
    }
    .delete {
      margin-left: 5px;
    }
    &.registered {
      border: 1px solid ${(p) => p.theme.disable};
      span {
        background: ${(p) => p.theme.disable};
        cursor: no-drop;
      }
    }
  }
  .intervalBox {
    overflow: hidden;
    max-width: 450px;
    padding: 2px 0;
  }
  .filterBoxTable {
    svg {
      cursor: pointer;
    }
  }
  .tableTD td:first-child {
    padding-left: 15px;
  }
  .connectIntervals {
    width: max-content !important; 
    display: inline-block;
    margin: 2px;
    padding: 3px 5px;
    border-radius: 3px;
    box-shadow: 0 0 3px rgba(0,0,0,.1);
    span {
      margin-left: 5px;
      color: ${(p) => p.theme.accent};
    }
  }
  .react-datepicker {
    box-shadow: 0 1px 3px rgba(0,0,0,.1), 0 5px 8px rgba(0,0,0,.1);
    background-color: ${(p) => p.theme.bgTable} !important;
    border: none !important;
    border-radius: 3px !important;
    overflow: hidden !important;
  }
  .react-datepicker__header {
    border-radius: 0 !important;
  }
  
  .react-datepicker__day {
    color: ${(p) => p.theme.text} !important;
    &:hover {
      color: ${(p) => p.theme.buttonHover} !important;
      background: ${(p) => p.theme.accent} !important;  
    }
    &.react-datepicker__day--in-range, &.react-datepicker__day--selected {
      background: ${(p) => p.theme.accent} !important;  
      color: ${(p) => p.theme.buttonHover} !important;
      border-radius: 3px !important;
    }
    &.react-datepicker__day--disabled:hover, &.react-datepicker__day--disabled {
      //background: ${(p) => p.theme.disabled} !important;
      border-radius: 0 !important;
      background: ${(p) => p.theme.disable} !important;
      color: ${(p) => p.theme.text} !important;
      //display: none !important;
    }
  }
  .react-datepicker__time-list-item--selected, .react-datepicker__time-list-item:hover {
    background: ${(p) => p.theme.accent} !important;
    color: ${(p) => p.theme.buttonHover} !important;
  }
  .react-datepicker__time-container {
    border: none !important;
  }
  
.loader {
  animation: loader-turn 1s linear infinite;
  padding: 0.5rem;
  max-width: 60px;
  width: 100%;
}
@keyframes loader-turn {
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

.loader__value {
  animation: loader-stroke 1s linear infinite;
  fill: none;
  stroke-dasharray: 63;
  stroke-dashoffset: 63;
  stroke-linecap: round;
  stroke-width: 4;
}

@keyframes loader-stroke {
  8.3333333333% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 63;
  }
}

.animationTerminal {
  opacity: 0;
}
@keyframes animForm {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.react-datepicker__input-container input {
  border: 1px solid rgba(0,0,0,.1);
  background: ${(p) => p.theme.bgTable};
  color: ${(p) => p.theme.text};
  padding: 10px 15px;
  &:focus {
    outline: none;
    border-bottom: ${(p) => `1px solid ${p.theme.green}`}
  }
}
  
.mt1 {
  margin-top: 1rem;
}

.animationForm {
  animation: animForm 0.3s ease-in forwards;
  opacity: 0;
}

.warningToastify {
  background: ${(p) => p.theme.accent || '#FC4C02'};
}
.headerTable {
  background: ${(p) => p.theme.white};
  &:first-child {
    border-radius: 3px 0 0 0;
  }
  &:last-child {
    border-radius: 0 3px 0 0;
  }
  border-bottom: 1px solid ${(p) => p.theme.accent}
}

`;

export const GlobalBox = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${(p) => p.theme.bg};
  overflow: auto;
  .tabHeader {
    text-decoration: none !important;
    color: ${(p) => p.theme.buttonText};
    opacity: 1 !important;
    transition: 0.1s ease-out !important;
    border: 1px solid rgba(0,0,0,.1);
    border-bottom: none;
    margin-right: 5px;
      white-space: nowrap;
    &:hover {
      background: ${(p) => p.theme.buttonAccent};
      color: ${(p) => p.theme.buttonHover};
    }
  }
  .tabHeader.activeTab {
    background: ${(p) => p.theme.buttonAccent};
    color: ${(p) => p.theme.buttonHover};
  }
  .tabHeaderBox {
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: none;
    width: min-content;
  }
  .tabsBox{
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
  .tabBorder {
    width: 100%;
    margin-bottom: 50px;
    border-bottom: 1px solid rgba(0,0,0,.1);
  }
`;

export const ContentBox = styled.div``;

export const MainConent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 30px 10px 20px 30px;
  background: ${(p) => p.theme.bg};
  height: auto;
`;

export const SortIconBox = styled.div`
  position: absolute;
  display: flex;
  right: 10px;
  top: 2px;
  cursor:pointer;
  svg {
    position: relative;
  }
`;

export const AddFontBox = styled.form`
  flex-wrap: wrap;
  width: 420px;
  border-radius: 3px;
  margin-right: 120px;
  display: flex;
  flex-direction: column;
  h2 {
    margin-bottom: 15px;
  }
  .flex {
    align-items: flex-end;
  }
  label {
    min-height: 40px;
    margin-bottom: ${(p) => (p.arr ? '40px' : '20px')};
    input[type="text"] {
      height: 40px;
      width: 100%;
      outline: none;
      padding: 0 15px;
      border: 1px solid rgba(0,0,0,.1);
    }
    textarea {
      padding: 10px 15px;
      width: 420px;
      height: 100px;
      resize: none;
      border: 1px solid rgba(0,0,0,.1);
      outline: none;
    }
    .file {
      width: 250px;
    }
  }
  .save {
    padding: 10px 25px;
    border: 1px solid rgba(0,0,0,.1);
    transition: 0.1s ease-out;
    background: ${(p) => p.theme.accent};
    color: ${(p) => p.theme.buttonHover};
    width: min-content;
    cursor: pointer;
  }
`;

export const Save = styled.div`
  padding: 0 25px;
  height: 40px;
  cursor:pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.accent};
  color: ${(p) => p.theme.buttonHover};
`;

export const LayoutBox = styled.div`
  display: flex;
  flex-direction: column;
  /* flex: 1 0 auto; */
  background: ${(p) => p.theme.bg};
  height: auto;
  min-height: 100vh;
  width: calc(100vw - 300px);
`;

function animationHover(add, w, p) {
  const pxWidth = w ? `${w}px` : '120px';

  return keyframes`
    0% {
      box-shadow: 0 0 0 ${p.green ? p.theme.greenl : p.theme.accentl};
    }
    100% {
      box-shadow: 0 0 0 3px ${p.green ? p.theme.greenl : p.theme.accentl};
      max-width: ${add ? pxWidth : 'auto'};
    }
  `;
}

export const BtnTable = styled.button`
  position: relative;
  width: 35px;
  height: 35px;
  border: 0;
  margin: ${(p) => p.m};
  border-radius: 5px;
  padding: 7px;
  margin: ${(p) => p.m || '0 0 0 0'};
  cursor: ${(p) => (p.hNone ? 'default' : 'pointer')};
  transition: 0.12s ease-in;
  background: transparent;
  &:focus {
    outline: none;
  }
  svg {
    width: 100%;
    height: auto;
    pointer-events: none;
    path {
      fill: ${(p) => p.theme.accent};
    }
  }
  &:last-child{
    margin-right: 0;
  }
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const ReportBigBox = styled.div`
  color: ${(p) => p.theme.text};
  width: 100%;
`;

export const ConfigExportBox = styled.div`
  display: flex;
  a {
    text-decoration: none;
  }
  button {
    margin-right: 30px;
  }
`;

export const Button = styled.button`
  max-width: 200px;
  padding: 8px 16px;
  border-radius: 3px;
  background: ${(p) => p.theme.accent};
  border: 0;
  color: ${(p) => p.theme.buttonHover};
  font-size: 16px;
  margin-bottom: 25px;
  cursor: pointer;
`;

export const styleBtnAdd = css`
  max-width: ${(p) => (p.login ? 'auto' : '40px')};
  //width: 100%;
  height: 40px;
  min-width: 200px;
  line-height: 0.8;
  background: ${(p) => (p.green ? p.theme.green : p.theme.accent)};
  border: 0;
  color: ${(p) => p.theme.buttonHover};
  font-size: 16px;
  margin: ${(p) => (p.margin || '35px 0 15px')};
  cursor: pointer;
  outline: none;
  display: ${(p) => (p.show ? 'inline-flex' : 'flex')};
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  svg {
    min-width: 20px;
  }
  span {
    margin-left: 15px;
    white-space: nowrap;
    font-size: 14px;
  }
  &:hover {  
    animation: ${(p) => animationHover(p.add, p.width, p)} 0.3s ease-out forwards;
  }
`;

export const BtnAdd = styled.button`
  ${styleBtnAdd};
  display: flex;
  min-width: ${(p) => (p.fix ? `${p.fix}px` : '200px')};
  background: ${(p) => (p.color ? p.theme[p.color] : p.theme.accent)};
  margin: 30px 0 15px;
  label {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 25px;
    align-items: center;
    color: ${(p) => p.theme.buttonHover}
  }
  input {
    height: 0;
    width: 0;
  }
  &.dis {
   background: ${(p) => p.theme.disable};
  }
`;

export const BtnAbsolute = styled(BtnAdd)`
  height: 40px;
  top: ${(p) => p.top || 0};
  cursor: pointer;
  margin: 0;
  z-index: 2;
`;

export const AbsoluteBox = styled.div`
  margin: 30px 0;
  height: 40px;
  top: 0;
  left: ${(p) => p.left || '310px'};
  display: flex;
  button {
    margin-right: 30px;
  }
  a {
    color: ${(p) => p.theme.buttonHover};
    text-decoration: none;
  }
`;

export const Title = styled.h1`
  color: ${(p) => p.theme.main};
  position: relative;
  text-transform: uppercase;
  font-size: 24px;
  font-family: Roboto;
  font-weight: bold;
  text-align: center;
  //color: ${(p) => p.theme.white};
  padding: 8px 16px;
  margin-bottom: 25px;
  span {
    color: ${(p) => p.theme.accent};
    font-family: Roboto;
    font-weight: bold;
  }
`;

export const ToBox = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const LoginOperatorBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    border-bottom: 0;
  }
`;

export const InputSmallGroup = styled.div`
  width: ${(p) => p.width || '100%'};
  display: flex;
`;

export const ButtonBack = styled.div`
  color: ${(p) => p.theme.buttonHover};
  position: absolute;
  text-transform: uppercase;
  font-size: 24px;
  font-family: Roboto;
  font-weight: bold;
  text-align: center;
  top: ${(p) => (p.reportTable ? 'none;' : '0;')}
  left: 0;
  background-color: ${(p) => p.theme.accent};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
  svg path {
    fill: ${(p) => p.theme.buttonHover};
  }
  &:hover {
    animation: ${(p) => css`${animationHover(false, '', p)} 0.3s ease-out forwards`};
  }
`;

export const ButtonBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  > button {
    margin: 5px 0;
  }
`;

export const InputDopBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 100%;
  }
`;

export const InputBox = styled.div`
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : '16px')};

  &.small {
    width: 33.3%;
    float: left;
    padding-right: 15px;
  }

  &.medium {
    width: 44%;
    float: left;
    margin-right: 2%;
  }
`;

export const FontsBox = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: ${(p) => (p.column ? 'column' : 'row')};
  p {
    margin-bottom: 15px;
    font-weight: normal;
  }
`;

export const FontList = styled.div`
  min-width: 380px;
  max-width: 380px;
  ul {
    display: flex;
    max-height: 600px;
    overflow: auto;
    flex-direction: column;
    padding-right: 45px;
    li {
      min-width: 100%;
      height: ${(p) => (p.news ? '100%' : '40px')};
      display: flex;
      margin-bottom: ${(p) => (p.news ? '20px' : '0')};
      justify-content: space-between;
      align-items: center;
      .date {
        min-width: 300px;
        display: flex;
        margin-top: 10px;
        justify-content: flex-end;
      }
      .content {
        display: flex;
        flex-direction: column;
      }
      .titleNews {
        margin-bottom: 5px;
        font-weight: normal;
      }
      div {
        display: flex;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 40px;
        cursor:pointer;
      }
      p {
        margin-bottom: 0;
        flex: 1;
        padding-right: 15px;
        font-weight: 100;
      }
      svg {
        width: 20px;
        height: 20px;
        fill: ${(p) => p.theme.accent};
      }
    }
  }
`;

export const GraphicBox = styled.div`
  margin-top: 100px;
`;

export const Label = styled.div`
  margin-bottom: 10px;
  color: ${(p) => p.theme.text};
  display: flex;
  flex-direction: ${(p) => (p.column ? 'column' : 'row')};
  font-size: 14px;
  span {
    color: ${(p) => p.theme.accent};
  }
  &:after {
    content: '*';
    font-size: 16px;
    color: red;
    opacity: ${(props) => (props.isRequired ? 1 : 0)};
  }
`;

export const ButtonBoxPoints = styled.div`
color: ${(p) => p.theme.text};

`;

export const ExtraField = styled.div`
padding: 30px 15px;
box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
margin-bottom: 30px;
p {
  margin-bottom: 10px;
}
`;

export const TicketButtonBox = styled.div`
svg path {
  fill: ${(p) => (p.active ? p.theme.accent : p.theme.bg)};
}
`;

export const Input = styled.input`
  border: 1px solid rgba(0, 0, 0, .1);

  transition: border 0.1s ease-out;
  text-align: ${(p) => p.textAlign || 'left'};
  background-color: ${(p) => (p.disabled || p.fill || p.theme.bg)};
  color: ${(p) => (!p.disabled ? p.theme.lightText : p.theme.disable)};
  padding: ${(p) => (p.file ? '7px 15px' : '10px 15px')};
  margin: ${(p) => p.m || '0 0 10px'};
  min-width: ${(p) => p.width};
  max-width: ${(p) => p.width || '100%'};
  font-size: ${(p) => p.size || 'inherit'};
  &:focus {
    outline: none;
    border-bottom: 1px solid ${(p) => p.theme.disable};
  }
`;

export const Select = styled.select``;

export const PreloaderBox = styled.div`
  position: relative;
  width: 100%;
  min-height: ${(p) => (p.height ? p.height : '150px')};
  table {
    width: 100%;
  }
`;

export const List = styled.div`
display: flex;
flex-direction: column;
box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
margin-bottom: 15px;
border-radius: 3px;
background: ${(p) => p.theme.bg};
height: 100%;
max-height: ${(p) => (p.full ? '100%' : '200px')};
overflow-y: auto;
`;

export const CheckLabel = styled.label`
padding: 6px;
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
font-size: 16px;
  &:nth-child(2n) {
  background-color: ${(p) => p.theme.lmain};
}
`;

export const FlexColumn = styled.div`
display: flex;
flex-direction: column;
`;

export const FlexBetween = styled.div`
display: flex;
justify-content: space-between;
`;

export const FlexCenter = styled.div`
display: flex;
justify-content: center;
`;

export const EmptyList = styled.div`
font-size: 30px;
width: 100%;
color: ${(p) => p.theme.text};
display: flex;
justify-content: center;
align-items: center;
`;

export const LabelText = styled.p`
color: ${(p) => p.theme.text};
`;

export const StructureBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 25px;
`;

export const StructureSmallBox = styled.div`
flex-direction: ${(p) => (p.column ? 'column' : 'row')};
display: flex;
`;

export const StructureItem = styled.div`
display: flex;
align-items: stretch;
`;

const structureTitlePrint = (p) => (p.print ? p.theme.accent : p.theme.bgTable);

export const StructureTitle = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
margin: 5px;
margin-right: 15px;
position: relative;
padding: 10px 25px;
border-radius: 3px;
transition: 0.1s ease-out;
background: ${(p) => (p.error ? 'red' : structureTitlePrint(p))};
color: ${(p) => (p.print || p.error ? p.theme.buttonHover : p.theme.text)};
cursor: ${(p) => (p.print ? 'default' : 'pointer')};
box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
  &:hover {
  box-shadow: ${(p) => (p.print ? 'none' : `0 0 0 3px ${p.theme.accentl}`)};
    &:after {
    filter: ${(p) => `drop-shadow(4px 0 0 ${p.theme.accentl})`};
  }
}
  &:after {
  position: absolute;
  content: "";
  display: ${(p) => (p.print ? 'none' : 'flex')};
  top: 50%;
  right: -14px;
  transform: translateY(-50%);
  //width: 15px;
  box-sizing: border-box;
  transition: 0.1s ease-out;
  //height: 15px;
  border: 7.5px solid transparent;
  z-index: 2;
  filter: drop-shadow(2px 0 1px rgba(0, 0, 0, .05));
  border-left: ${(p) => `7.5px solid ${p.theme.bgTable}`};
}
`;

export const CheckBox = styled.label`
position: relative;
display: inline-block;
margin-left: 10px;
width: 40px;
height: 26px;
input{
  opacity: 0;
  width: 0;
  height: 0;
}
`;

export const Check = styled.span`
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #ccc;
border-radius: 15px;
-webkit-transition: .4s;
transition: .4s;
  &:before{
  position: absolute;
  content: "";
  border-radius: 50%;
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
`;

export const InputCheck = styled.input`
  &:checked + ${Check} {
  background-color: ${(p) => p.theme.accent};
}
  &:focus + ${Check} {
  box-shadow: 0 0 1px ${(p) => p.theme.accent};
}
  &:checked + ${Check}:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}
`;

export const SliderBox = styled.div`
position: relative;
display: flex;
flex-direction: column;
align-items: center;
`;

export const TitleSlider = styled.p`
width: 100%;
text-align: center;
margin-bottom: 15px;
`;

export const SelectGroup = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 25px;
p {
  color: ${(p) => p.theme.text};
  margin-bottom: 10px;
}
`;

export const Circle = styled.circle`
stroke: ${(p) => p.theme.accent};
animation: loader-stroke 6s linear infinite;
fill: none;
stroke-dasharray: 63;
stroke-dashoffset: 63;
stroke-linecap: round;
stroke-width: 4;
`;

export const InputRange = styled.input`
display: flex;
align-items: flex-start;
outline: none;
-webkit-appearance: none;
height: 25px;
margin-bottom: 15px;
width: ${(p) => (p.minuts ? '300px' : '100%')};
cursor: pointer;
  &::-webkit-slider-runnable-track {
  width: 100%;
  height: 100%;
  cursor: pointer;
  //background: #a4b162;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0);
}
  &::-webkit-slider-thumb {
  border-radius: 50%;
  position: relative;
  width: 23px;
  height: 25px;
  top: -4px;
  background: ${(p) => p.theme.accent};
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 999;
}
`;

export const Datalist = styled.datalist`
position: absolute;
display: flex;
height: 25px;
justify-content: space-between;
top: 0;
padding: 0 0;
width: ${(p) => (p.minuts ? '300px' : 'calc(100%-2px)')};
/* disable text selection */
-webkit-user-select: none; /* Safari */
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+/Edge */
user-select: none; /* Standard */
/* disable click events */
pointer-events: none;
`;

export const RangeOption = styled.option`
width: 23px;
height: 25px;
flex: 1;
display: flex;
align-items: center;
justify-content: center;
position: relative;
top: 0;
min-height: 10px;
line-height: 40px;
font-size: 14px;
color: ${(p) => p.theme.text};
background: ${(p) => p.theme.bg};
border-radius: 50%;
box-shadow: 0 2px 3px rgba(0, 0, 0, .1);
  &:hover {
  background: ${(p) => p.theme.buttonHover};
}
`;

export const IconBox = styled.div`
display: flex;
`;
