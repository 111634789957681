import React, { useState, useEffect } from 'react';
import request from '../../../../../../logic/utils/request';
import Input from '../../../../../../pages/private/components/Input/Input';

const RenderInputs = ({
  handleInput, serviceData, setServiceData, preloader, serviceUpdate,
}) => {
  const [weeks, setWeeks] = useState(null);

  useEffect(() => {
    preloader();
    request('/server/api/dworkweek/', 'get')
      .then((res) => {
        const workWeek = {
          workWeekId: res.data.data[0].id,
          workWeekName: res.data.data[0].name,
        };
        if (!serviceUpdate) setServiceData((v) => ({ ...v, workWeek }));
        setWeeks(res.data.data);
        preloader();
      });
  }, [serviceUpdate, preloader, setServiceData]);

  const {
    position, name, fullname, prefix, workWeek,
  } = serviceData;
  const weekOptions = (
    weeks ? weeks.map((item) => (
      { id: item.id, name: item.name }
    )) : []
  );

  const items = [
    {
      label: 'Префикс', name: 'prefix', value: prefix, required: true, maxlength: 4,
    },
    {
      label: 'Позиция', name: 'position', value: position, required: true, type: 'number', maxlength: 9,
    },
    {
      label: 'Короткое название', name: 'name', value: name, required: true,
    },
    {
      label: 'Полное название', name: 'fullname', value: fullname, required: true,
    },
    {
      label: 'Рабочая неделя:', name: 'workWeek', value: workWeek.workWeekId || '', type: 'select', options: weekOptions, noDefault: true, required: true, m: '0 0 10px',
    },
    // {
    //  label: 'Приоритет', name: 'prior', value: prior, type: 'number',
    // },
  ];
  return items.map((item) => (
    <Input
      width="100%"
      key={item.name}
      item={item}
      m={item.m}
      onChange={handleInput}
    />
  ));
};
export default RenderInputs;
