import React, { useState, useEffect } from 'react';
import moment from 'moment';

function TimerSchedule({
  styles, setActiveEl,
}) {
  const getCurrentDate = () => ({
    time: moment().format('LT'),
    date: moment().format('DD/MM/YYYY'),
  });

  const order = styles.order === 1 ? 0 : 1;
  const [timeConfig, setTimeConfig] = useState(getCurrentDate());
  const { time, date } = timeConfig;

  useEffect(() => {
    const timerMin = setInterval(() => {
      setTimeConfig(getCurrentDate());
    }, 1000);
    return () => clearInterval(timerMin);
  }, []);

  const {
    fontSize1, color1, fontSize2, fontFamily1, height1, alignItems, bold,
  } = styles;

  const aligns = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };

  const timeStyle = {
    order,
    maxHeight: height1 === 'auto' ? '100%' : height1,
    minHeight: height1 === '100%' ? 0 : height1,
    alignItems: aligns[alignItems] || 'center',
  };

  return (
    <div className="layout__timer" style={timeStyle} onClick={() => setActiveEl('time')}>
      <p
        style={{
          fontSize: fontSize1,
          fontFamily: fontFamily1,
          fontWeight: bold,
          margin: 0,
          color: color1,
        }}
      >
        {time}
      </p>
      <p style={{
        fontSize: fontSize2,
        fontFamily: fontFamily1,
        color: color1,
        margin: 0,
      }}
      >
        {date}
      </p>
    </div>
  );
}

export default TimerSchedule;
