import React from 'react';
import {
  InputRange, RangeOption, Datalist, SliderBox, TitleSlider,
} from '../../../../common/ui/Common';

function Slider({
  max = 23, step = 1, setData, value, minuts, text, name,
}) {
  const renderSteps = () => {
    const stepsArr = [];
    const iterator = Number(max) / Number(step);
    for (let i = 0; i <= iterator; i += 1) {
      stepsArr.push(<RangeOption key={i}>{i * step}</RangeOption>);
    }
    return stepsArr;
  };

  const handleSlider = (e) => {
    setData(e.target.value, name);
  };

  return (
    <>
      <TitleSlider>{text}</TitleSlider>
      <SliderBox>
        <InputRange minuts={minuts} onChange={handleSlider} value={value} type="range" min="0" max={max} step={step} list="steplist" />
        <Datalist minuts={minuts} id="steplist">
          {renderSteps()}
        </Datalist>
      </SliderBox>
    </>
  );
}

export default Slider;
