import React, { useState, useRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import {
  Container, SelectDiv, SelectOptions, Option, CloseBlock,
} from './Style';
import findPosition from './findPosition';

function Select({
  options = [],
  value,
  onChange,
  name,
  noDefault,
  m,
  width,
  maxHeight,
  readOnly,
  bg,
  disabled,
  maxContent,
  align,
}) {
  const [active, setActive] = useState(false);
  const [bottom, setBottom] = useState(true);
  const sel = useRef(null);
  const activeElement = options.find((el) => el.id === value);

  const Change = (nameChange, id, e) => {
    const body = { target: { name: nameChange, value: id }, e };
    onChange(body);
    setActive(!active);
  };

  const getValue = () => {
    let selectValue = 'Не выбрано';
    if (noDefault) {
      if (activeElement) {
        if (activeElement.name) selectValue = activeElement.name;
      } else {
        selectValue = options[0] ? options[0].name : 'Не выбрано';
      }
    } else if (activeElement) {
      if (activeElement.name) selectValue = activeElement.name;
    }

    return selectValue;
  };

  return (
    <Container align={align} m={m} width={width}>
      <SelectDiv
        data-test="clickItem"
        ref={sel}
        bg={bg}
        onClick={() => {
          findPosition(sel.current, setBottom, 200);
          setActive((prevState) => !prevState);
        }}
      >
        <span data-test="defaultValue">{getValue()}</span>
        <FaChevronDown />
      </SelectDiv>
      {!disabled && active && options && !readOnly ? (
        <>
          <CloseBlock onClick={() => setActive(false)} />
          <SelectOptions data-test="selectedMenu" bottom={bottom} w={maxContent} maxHeight={maxHeight}>
            {!noDefault && <Option bg={bg} onClick={(e) => Change(name, '', e)}>Не выбрано</Option>}
            {options.map((el) => (
              <Option
                bg={bg}
                key={el.id}
                onClick={(e) => {
                  Change(name, el.id, e);
                }}
              >
                {el.name}
              </Option>
            ))}
          </SelectOptions>
        </>
      ) : null}
    </Container>

  );
}

export default React.memo(Select);
