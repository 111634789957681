import React, { useState } from 'react';
import { BtnAdd, LoginOperatorBox } from '../../../common/ui/Common';
import SelectInput from '../../../pages/private/components/Input/SelectInput';

function LoginPanel(props) {
  const { wplaces, rmoLogin } = props;

  const [selectedWplace, setSelectedWplace] = useState(null);

  const login = () => {
    rmoLogin(selectedWplace);
  };

  const options = wplaces.map((wp) => ({ id: wp, name: wp }));
  const onChange = ({ target }) => setSelectedWplace(target.value);

  return (
    <LoginOperatorBox>
      <SelectInput m="0 30px 0 0" options={options} onChange={onChange} value={selectedWplace} name="login" multiple={false} />
      <BtnAdd style={{ margin: '0' }} onClick={login} login show>
        Вход
      </BtnAdd>
    </LoginOperatorBox>
  );
}
export default LoginPanel;
