const init = {
  service: '',
  date: '',
  pin: '',
};

export default function ticketReducer(state = { ...init }, action) {
  const { type, payload } = action;
  switch (type) {
    case 'SET-PRE': {
      return {
        ...payload,
      };
    }
    case 'RESET-PRE': {
      return {
        ...init,
      };
    }

    default:
      return state;
  }
}
