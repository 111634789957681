import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../../../../common/ui/Input/input';
import {
  Body, BodyContainer, InputBox, KeyboardContainer, NextButton,
} from '../../../utils/style';
import Keyboard from '../../common/keyboard';
import { resetTicket } from '../../../../store/actions';
import Store from '../../../../store/store';

const { dispatch } = Store;

function PinCode({ children }) {
  const { style, config } = useSelector((state) => state);
  const { id, headUrl } = config;
  const [pin, setPin] = useState('');
  const [inputId, setId] = useState('input');
  const { title, button, input } = style;
  const navigate = useNavigate();

  const item = {
    name: '',
    params: {
      fid: 'input',
      value: 'number',
    },
  };

  const clickInput = (value) => setId(value);

  const onNext = () => {
    const data = {
      zone_id: config.idZone,
      pinCode: +pin,
      satellite_id: config.id,
    };
    dispatch(resetTicket(data));
    navigate(`${headUrl}/${id}/print`);
  };

  return (
    <BodyContainer style={style.body}>
      <Body>
        <div style={title}>Введите пин-код</div>
        <KeyboardContainer height>
          <InputBox button={button} style={{ flex: 0, margin: '30px 0' }}>
            <Input
              item={item}
              height={70}
              style={input}
              width="50%"
              value={pin}
              id={inputId}
              clickInput={clickInput}
            />
          </InputBox>
          <Keyboard
            styleButton={button}
            setValue={(e) => setPin(e)}
            value={pin}
            id={inputId}
          />
        </KeyboardContainer>
        {pin.length >= 4 && (
          <NextButton
            style={{
              ...style.bottomButton,
            }}
            onClick={onNext}
          >
            Готово
          </NextButton>
        )}
      </Body>
      {children}
    </BodyContainer>
  );
}

export default PinCode;
