import React from 'react';
import { Button } from 'antd';
// import { BtnAdd } from '../../../common/ui/Common';
import getLinks from '../../../logic/utils/getLinks';

function AddButton({ toggle, text, ...props }) {
  const write = getLinks(true);
  if (write) {
    return (
      <Button type="primary" size="large" onClick={toggle} {...props}>
        {' '}
        {text || 'Добавить'}
        {' '}
      </Button>
    );
  }

  return null;
}

export default React.memo(AddButton);
