export default ({ header }) => [
  {
    title: 'Текст справа', value: header.color1, paramName: 'color1', parentName: 'header',
  },
  {
    title: 'Заголовок', value: header.colorHeaderMain, paramName: 'colorHeaderMain', parentName: 'header',
  },
  {
    title: 'Подзаголовок', value: header.colorHeaderSub, paramName: 'colorHeaderSub', parentName: 'header',
  },
  {
    title: 'Фон', value: header.background, paramName: 'background', parentName: 'header',
  },
  {
    title: 'Дата и время', value: header.color2, paramName: 'color2', parentName: 'header',
  },
];
