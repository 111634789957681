import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

import Videos from './Videos';
import News from './News';
import Fonts from './Fonts';
import Images from './Images/Images';
import Runninglain from './Runninglain';
import { Title } from '../../../../common/ui/Common';

function Custom(props) {
  const { showError } = props;
  const [renderGoBack, setRenderGoBack] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    setRenderGoBack(params.get('goBack'));
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="devices__box">
        <Title>ЗАГРУЗКА КОНТЕНТА</Title>
      </div>
      <Tabs>
        <Tabs.TabPane key="Шрифты" tab="Шрифты">
          <Fonts {...props} name="Шрифты" link="/custom/" showError={showError} renderGoBack={renderGoBack} goBack={goBack} />
        </Tabs.TabPane>
        <Tabs.TabPane key="Видео" tab="Видео">
          <Videos {...props} name="Видео" link="/custom/videos" showError={showError} renderGoBack={renderGoBack} goBack={goBack} />
        </Tabs.TabPane>
        <Tabs.TabPane key="Изображения" tab="Изображения">
          <Images {...props} name="Изображения" link="/custom/images" showError={showError} renderGoBack={renderGoBack} goBack={goBack} />
        </Tabs.TabPane>
        <Tabs.TabPane key="Новости" tab="Новости">
          <News {...props} name="Новости" link="/custom/news" showError={showError} />
        </Tabs.TabPane>
        <Tabs.TabPane key="Бегущая" tab="Бегущая строка">
          <Runninglain {...props} name="Бегущая строка" link="/custom/runningline" showError={showError} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default Custom;
