import React from 'react';
import { Button, Space } from 'antd';
import getLinks from '../../../logic/utils/getLinks';

function ModalButtons({
  isValid, update, updateFunc, addFunc, reset, notCancel, isWrite,
}) {
  const write = isWrite || getLinks(true);

  if (write) {
    return (
      <Space direction="horizontal" size="middle">
        <Button
          size="large"
          type="primary"
          disabled={!isValid}
          onClick={isValid && update ? updateFunc : addFunc}
        >
          Сохранить
        </Button>
        {!notCancel && <Button size="large" onClick={reset}>Отменить</Button>}
      </Space>
    );
  }
  return null;
}

export default React.memo(ModalButtons);
