export default ({ button }) => [
  {
    title: 'Большой кнопки', value: button.textAlignL, paramName: 'textAlignL', parentName: 'button',
  },
  {
    title: 'Средней кнопки', value: button.textAlignM, paramName: 'textAlignM', parentName: 'button',
  },
  {
    title: 'Маленькой кнопки', value: button.textAlignS, paramName: 'textAlignS', parentName: 'button',
  },
];
