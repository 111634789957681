function TerminalName(props) {
  const {
    id, longname, description,
  } = props;

  return (
    <ul>
      <li>
        Id -
        {' '}
        <b>{id}</b>
      </li>
      {longname && (
      <li>
        Наименование -
        {' '}
        <b>{longname}</b>
      </li>
      )}
      {description && (
      <li>
        Описание -
        {' '}
        <b>{description}</b>
      </li>
      )}
    </ul>
  );
}

export default TerminalName;
