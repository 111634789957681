import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import ReportTitle from '../../../../../../quality-control/Elements/DetailedReport/TableTitle';
import { ReportBox } from '../../../../../../../../components/report/ReportTableColumns';
import AvgWaitTimeQuery from './SqlAvgWaitTime';
import AvgProcTimeQuery from './SqlAvgProcTime';

function AvgTimeGraphic(props) {
  const { view, startDate, endDate } = props;

  const { reportConfig } = useSelector((state) => state);
  const [graphicData, setGraphicData] = useState();

  const BasicSettings = {
    fill: false,
    lineTension: 0.05,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
  };

  const fetchGraphicData = async () => {
    const labels = [];
    const qq = [];
    const ww = [];
    const { dataObjects: pTimeDataObjects } = await AvgProcTimeQuery(startDate, endDate);
    const { dataObjects: wTimeDataObjects } = await AvgWaitTimeQuery(startDate, endDate);

    wTimeDataObjects.forEach((el) => {
      labels.push(el[0]);
      qq.push(el[3]);
    });

    const testArr = [];
    const qwew = [];
    if (labels) {
      labels.forEach((el) => {
        const result = [];
        let fe = [];
        if (el.split(' ').length > 5) {
          el.split(' ').forEach((word) => {
            if (fe.length < 5) {
              fe.push(word);
            } else {
              result.push(fe);
              fe = [];
              fe.push(word);
            }
          });
        } else {
          testArr.push(el);
        }
        if (fe.length > 0) result.push(fe);
        if (result.length > 0) testArr.push(result);
      });

      testArr.forEach((el) => {
        if (Array.isArray(el)) {
          const ss = [];
          el.forEach((arr) => {
            ss.push(arr.join(' '));
          });
          qwew.push(ss);
        } else {
          qwew.push(el);
        }
      });
    }

    pTimeDataObjects.forEach((el) => {
      ww.push(el[3]);
    });

    const allData = {
      ...BasicSettings,
      label: 'Ожидания',
      data: qq,
      pointHoverBackgroundColor: '#7486fc',
      pointBorderColor: '#7486fc',
      borderColor: '#7486fc',
      backgroundColor: 'rgba(3, 82, 252, 0.4)',
    };

    const noneData = {
      ...BasicSettings,
      label: 'Обслуживания',
      data: ww,
      pointHoverBackgroundColor: '#f75454',
      pointBorderColor: '#f75454',
      borderColor: '#f75454',
      backgroundColor: 'rgba(252, 5, 5, 0.4)',
    };

    const ReportSettings = {
      labels: qwew,
      datasets: [allData, noneData],
    };

    const doughnutParams = {
      Data: ReportSettings,
      options: {},
    };

    setGraphicData(doughnutParams);
  };

  useEffect(() => {
    if (view) {
      fetchGraphicData();
    }
  }, [reportConfig, view, endDate, startDate]);

  const optionsGrapg = {
    maintainAspectRatio: false,

    plugins: {
      title: {
        display: true,
      },

      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (dataSet) => {
            const str = dataSet.formattedValue;
            const seconds = str.replace(/\s/g, '');

            const label = `${dataSet.dataset.label}: ${moment.utc(seconds * 1000).format('HH:mm:ss')}`;
            return label;
          },
        },
      },

    },

    scales: {
      y: {
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true,
          callback: (value) => (`${Math.round(value / 60)} мин.`),
        },
      },
    },

  };

  return (
    <ReportBox>
      <ReportTitle name="Среднее время по очередям" />
      {graphicData
          && (
            <div style={{
              maxWidth: '1500px', width: '100%', overflowX: 'scroll', height: '100%', overflowY: 'hidden',
            }}
            >
              <div style={{
                height: '550px',
                width: `${graphicData.Data.labels.length * 150}px`,
              }}
              >
                <Bar
                  data={graphicData.Data}
                  height={300}
                  options={optionsGrapg}
                  style={{ overflowX: 'scroll' }}
                />
                ;
              </div>
            </div>
          )}
    </ReportBox>
  );
}

// export default AvgTimeGraphic;

export default AvgTimeGraphic;
