import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SqlQuery from './SqlQuery';
import TableConfig from './TableConfig';
import ReportTitle from '../ReportTitle';
import Table from '../../../../../../components/table/Table';
import { PreloaderBox } from '../../../../../../../../common/ui/Common';
import Preloader from '../../../../../../components/preloader/Preloader';

function MarkTable() {
  const [ReportParams, setReportParams] = useState();
  const { reportConfig, preloader } = useSelector((state) => state);
  const {
    startDate, endDate, anyId, buttonType, markText,
  } = reportConfig;

  useEffect(() => {
    if (ReportParams) preloader();
    SqlQuery(startDate, endDate, anyId, buttonType).then((resp) => {
      const { dataObjects, query } = resp;
      const params = {
        items: dataObjects,
        query,
        TableConfig: TableConfig(),
      };
      setReportParams(params);
      if (ReportParams) preloader();
    });
  }, [reportConfig]);

  return (
    <>
      <ReportTitle
        item={ReportParams ? ReportParams.items.length : null}
        name={ReportParams ? `обслуженные талоны оператором ${ReportParams.items[0][4]} c оценкой ${markText}` : null}
        query={ReportParams ? ReportParams.query : null}
      />
      { ReportParams ? (
        <Table
          keys="3"
          data={ReportParams.items}
          columns={ReportParams.TableConfig.columns}
        />
      )
        : (
          <PreloaderBox>
            <Preloader isOpen small />
          </PreloaderBox>
        )}
    </>
  );
}

export default MarkTable;
