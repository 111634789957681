import React, { useState } from 'react';
import { Table } from 'antd';
import Config from './TableConfig';
import DeleteWarning from '../../../../../../common/ui/Modal/DeleteWarning';
import { useToggle } from '../../../../../../logic/hooks/useToggle';
import DeleteItem from '../../../../../../pages/private/utils/deleteItem';
import { useSearchPropsFactory } from '../../../../../../pages/private/utils/useSearch';

function IntervalsTable({
  intervalList,
  setIntervalUpdate,
  setIntervalData,
  toggleOpen,
  preloader,
  fetchIntervals,
  write,
  showError,
}) {
  const [intervalId, setIntervalId] = useState(false);
  const [intervalName, setIntervalName] = useState(false);
  const [showDelWarn, setShowDelWarn] = useToggle(false);

  const delInterval = async () => {
    await DeleteItem({
      preloader, url: `/server/api/dworkinterval/${intervalId}/`, fetchData: fetchIntervals, setShowWarn: setShowDelWarn, setId: setIntervalId, showError,
    });
  };

  const handleTableIcon = (e, row) => {
    const { dataset } = e.target;
    const {
      id, name, status, timebegin, timeend, description,
    } = row;
    if (dataset.name === 'del') {
      setIntervalId(id);
      setIntervalName(name);
      setShowDelWarn();
    } else if (dataset.name === 'update') {
      setIntervalUpdate(true);
      setIntervalData({
        id, name, status, timebegin, timeend, description,
      });
      toggleOpen();
    }
  };
  const Setting = Config(write, useSearchPropsFactory());
  const { columns } = Setting;

  return (
    <>
      {!!intervalList.data?.length
      && (
        <Table
          locale={{
            triggerDesc: 'Сначала выключенные',
            triggerAsc: 'Без сортировки',
            cancelSort: 'Сначала включенные',
          }}
          columns={columns}
          dataSource={intervalList.data || []}
          onRow={(row) => ({
            onClick: (e) => { handleTableIcon(e, row); },
          })}
        />
      )}
      <DeleteWarning
        name={intervalName}
        confirmDel={delInterval}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </>
  );
}

export default React.memo(IntervalsTable);
