import React from 'react';
import SelectInput from '../../../../../Elements/Input2/SelectInput/SelectInput';

const changeView = ({ dispatch, value }) => {
  const options = [
    { id: 0, name: '00.00.00' },
    { id: 1, name: '00-00-00' },
    { id: 2, name: '00/00/00' },
  ];
  return (
    <span className="box__color">
      <SelectInput
        options={options}
        onChange={(e) => dispatch({
          type: 'viewType',
          name: 'clock',
          param: 'viewType',
          value: Number(e.target.value),
        })}
        noDefault
        value={value}
      />
    </span>

  );
};

export default changeView;
