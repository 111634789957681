import React, { useState } from 'react';
import {
  Button, Card, Row, Space, Typography,
} from 'antd';
import request from '../../../../../../logic/utils/request';
import Preloader from '../../../../components/preloader/Preloader';
import Loader from './loader';

const { Title, Text } = Typography;

function UpdateBlock(props) {
  const [inLoad, setInLoad] = useState(false);

  const {
    title, cVersion, lVersion, type, loading,
  } = props;

  const update = async (appType) => {
    const res = await request('/update', 'post', {
      type: appType,
    });
    const { success } = res.data;
    setInLoad(true);
    if (success) {
      setInLoad(false);
      window.location.reload();
    }
  };

  return (
    <>
      <Preloader isOpen={inLoad} small={false} />
      <Card>
        <Title type="primary" level={5}>{title}</Title>
        {loading
          ? (
            <Text type="secondary">
              <Space direction="vertical" size="small" style={{ width: '100%' }}>
                <Row justify="space-between">
                  Текущая:
                  <span>{(cVersion) && cVersion}</span>
                </Row>
                <Row justify="space-between">
                  Актуальная:
                  <span>{(lVersion) && lVersion}</span>
                </Row>
                {(cVersion !== lVersion && lVersion && lVersion !== 'Невозможно получить') && <Button type="primary" onClick={() => update(type)}>Обновить</Button>}
              </Space>
            </Text>
          )
          : <Loader /> }
      </Card>
    </>
  );
}
export default UpdateBlock;
