import React, { useState } from 'react';
import {
  Button, Checkbox, Form, notification, Select,
} from 'antd';
import { useMutation } from '@tanstack/react-query';
import preorderRest from '../../utils/preorderRest';

function RewriteSlot({
  queues, dayId, refetch, closeDrawer,
}) {
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [forAll, setForAll] = useState(false);
  const { mutate } = useMutation({
    mutationFn: (variables) => preorderRest.rewriteSlots(variables),
    onSuccess: () => {
      notification.success({
        message: 'Слоты успешно перезаписаны',
      });
      refetch();
      closeDrawer();
    },
    onError: () => {
      notification.error({
        message: 'Не удалось перезаписать слоты',
      });
    },
  });

  const handleSelect = (values) => {
    setSelectedQueues(values);
  };

  const handleCheck = (e) => {
    setForAll(e.target.checked);
  };

  const rewriteSlots = () => {
    const qArr = [...selectedQueues];
    if (forAll) {
      queues?.forEach((el) => {
        if (el.qId !== 0) {
          qArr.push(el.qId);
        }
      });
    }
    mutate({ dayId, data: { queues: qArr } });
  };

  return (
    <Form style={{ width: '600px' }} layout="vertical">
      <Form.Item style={{ width: '100%' }} label="Список услуг для перезаписи слотов">
        <Select onChange={handleSelect} mode="multiple">
          {queues?.map((queue) => (
            <Select.Option key={queue.qId} value={Number(queue.qId)}>
              {queue.qFullName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Checkbox onChange={handleCheck}>
          Перезаписать все слоты по всем услугам
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Button onClick={rewriteSlots} disabled={selectedQueues.length === 0 && !forAll} type="primary">
          Перезаписать слоты
        </Button>
      </Form.Item>
    </Form>
  );
}

export default RewriteSlot;
