import React, { useEffect } from 'react';
import { Button, Input, Space } from 'antd';
import RenderCheckList from './RenderCheckList';

function RenderInputs({
  zoneData, setZoneData, preloader, setIsValid, zoneUpdate,
}) {
  useEffect(() => {
    const { name } = zoneData;
    if (zoneUpdate && name) {
      setIsValid(true);
    }
  }, [setIsValid, zoneData, zoneUpdate]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    const validData = { ...zoneData, [name]: value };
    const {
      name: iV, longname: pV,
    } = validData;
    setZoneData({ ...validData });
    if (iV && pV) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const {
    name, longname, description,
  } = zoneData;

  const addParams = () => {
    const { params } = zoneData.config;
    params.push({ fid: '', fv: [] });
    setZoneData({ ...zoneData, config: { ...zoneData.config, params } });
  };

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Input
        required
        addonBefore="Имя:"
        name="name"
        value={name || ''}
        onChange={handleInput}
      />
      <Input
        required
        addonBefore="Наименование:"
        name="longname"
        value={longname || ''}
        onChange={handleInput}
      />
      <Input
        required
        addonBefore="Описание:"
        name="description"
        value={description || ''}
        onChange={handleInput}
      />

      <Button onClick={addParams}>
        <span>Добавить поле</span>
      </Button>
      <RenderCheckList
        setZoneData={setZoneData}
        zoneData={zoneData}
        zoneUpdate={zoneUpdate}
        preloader={preloader}
      />
    </Space>

  );
}
export default RenderInputs;
