import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BodyContainer, Img } from '../../../utils/style';
import Request from '../request';
import { recoveryTicket, resetTicket } from '../../../../store/actions';
import findError from '../findError';
import img from './scan.png';

function Scan({
  style, startScreen, history, child,
}) {
  const inp = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { errorUrl } = useSelector((state) => state.config);

  useEffect(() => {
    inp.current.focus();
    const Timeout = setTimeout(() => {
      navigate(startScreen);
      dispatch(resetTicket());
    }, 10000);
    return () => clearTimeout(Timeout);
  }, [startScreen, history]);

  const { title } = style;
  const extraTitle = { ...style.extraTitle };
  extraTitle.fontSize = 30;

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = inp.current;

    Request('/server/public/getTicket', { ticketId: value }, 'post')
      .then((res) => {
        const err = findError({
          res, navigate, startScreen, id: inp.current.value, errorUrl,
        });
        if (!err) {
          dispatch(recoveryTicket({ value: res.body }));
          navigate(`/ticketInfo/${res.body.id}`);
        }
      });
  };

  return (
    <BodyContainer style={style.body}>
      <form onSubmit={onSubmit} style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={title}>Сканирование</div>
        <div style={extraTitle}>Поднесите билет к сканеру на расстояние 10-15 см</div>

        <Img scan={img} />

        <input ref={inp} style={{ opacity: 0, height: 0, padding: 0 }} />
        {child}
      </form>
    </BodyContainer>
  );
}

export default Scan;
