import { Input } from 'antd';
import React, { useRef } from 'react';
import { AddFontBox } from '../../../../common/ui/Common';
import checkFileExtension from '../../../utils/checkFileExtension';

function UploadFont({
  save, title, setTitle, showError, fileExtension, type,
}) {
  const uploaderFileList = {
    font: {
      name: 'шрифта',
      append: 'font',
    },
    video: {
      name: 'видео',
      append: 'video',
    },
    image: {
      name: 'изображения',
      append: 'image',
    },
  };

  const form = useRef(null);
  const upload = (e, isDrop) => {
    let file;
    if (isDrop) {
      const dt = e.dataTransfer;
      const { files } = dt;
      [file] = files;
    } else {
      [file] = e.target.files;
    }

    if (checkFileExtension(file.name, fileExtension)) {
      const { elements } = form.current;
      const formData = new FormData();
      formData.append('name', elements.name.value);
      formData.append(uploaderFileList[type].append, file);
      save(formData);
    } else {
      showError('Данный формат файла не поддерживается');
    }
  };

  const clearInputValue = (e) => {
    e.target.value = '';
  };

  const handleDrop = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    upload(e, value);
  };

  const handleActive = (e) => {
    e.preventDefault();
  };

  const cases = { шрифта: 'шрифт', видео: 'видео', изображения: 'изображение' };

  return (
    <AddFontBox enctype="multipart/form-data" ref={form}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <Input
        placeholder={`Введите название ${uploaderFileList[type].name}`}
        name="name"
        value={title}
        type="text"
        onChange={(e) => setTitle(e.target.value)}
      />

      {title ? (
        <div className="upload-item_content upload__item__content file">
          <div className="upload__label__box">
            <div>
              <label
                htmlFor="input"
                onDragEnter={(e) => handleActive(e)}
                onDragOver={(e) => handleActive(e)}
                onDragLeave={(e) => handleActive(e)}
                onDrop={(e) => handleDrop(e, true)}
                className="upload-label upload__label__el"
              >
                <span>
                  Перетащите
                  {' '}
                  {cases[uploaderFileList[type].name]}
                  {' '}
                  сюда
                </span>
                <span>
                  Или нажмите здесь для загрузки
                  {' '}
                  {uploaderFileList[type].name}
                </span>
              </label>
              <input
                type="file"
                style={{ display: 'none' }}
                onClick={(e) => clearInputValue(e)}
                id="input"
                onChange={(e) => handleDrop(e)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="upload-item_content upload__item__content file">
          <div className="upload__label__box">
            <div>
              <div style={{ paddingTop: 10 }}>
                Для загрузки
                {' '}
                {uploaderFileList[type].name}
                {' '}
                введите название
              </div>
            </div>
          </div>
        </div>
      )}
    </AddFontBox>
  );
}

export default UploadFont;
