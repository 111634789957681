import React from 'react';
import { Table, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import TableConfig from './TableConfig';
import api from '../utils/api';

function ZonesTable({
  data, fetchZones, setZoneData, setIsOpen,
}) {
  const navigate = useNavigate();

  async function deleteZone(id) {
    const res = await api.deleteZone(id);
    if (res.status) {
      notification.success({
        message: 'Зона успешно удалена',
      });
      fetchZones();
    } else {
      notification.error({
        message: res.errMessage,
      });
    }
  }

  const handleTableIcon = (e, row) => {
    const { dataset: { name: event } } = e.target;

    const { id: zoneId } = row;

    if (event === 'del') deleteZone(zoneId);
    if (event === 'styles') navigate(`/zone/${zoneId}`);
    if (event === 'update') {
      setIsOpen(true);
      setZoneData(row);
    }
  };

  return (
    <Table
      columns={TableConfig()}
      rowKey="id"
      dataSource={data}
      onRow={(row) => ({
        onClick: (e) => { handleTableIcon(e, row); },
      })}
    />
  );
}

export default ZonesTable;
