import React from 'react';
import { Table } from 'antd';
import Config from './TableConfig';
import Preloader from '../../../../pages/private/components/preloader/Preloader';
import { useSearchPropsFactory } from '../../../../pages/private/utils/useSearch';

function ActionLogTable({
  userList, actionList, pagination,
}) {
  const users = {};
  if (userList) {
    userList.forEach((user) => {
      users[Number(user.id)] = user.username;
    });
  }
  const Setting = Config(users, useSearchPropsFactory());

  const { columns } = Setting;

  const viewParams = actionList === undefined
    ? <div>Параметров нет</div> : (
      <Table
        dataSource={actionList}
        columns={columns}
        pagination={{
          position: pagination.page,
          current: pagination.page,
          pageSize: pagination.sizePerPage,
          total: pagination.totalSize,
          onShowSizeChange: pagination.onSizePerPageChange,
          onChange: pagination.onPageChange,
        }}
        scroll={{ x: true }}
      />
    );

  return (
    actionList === null || userList === null ? <Preloader isOpen small /> : viewParams
  );
}

export default React.memo(ActionLogTable);
