import React from 'react';

const rowFormatter2 = (cell, row) => {
  const { all_cnt: allCnt } = row;
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>{allCnt}</div>
  );
};

export default function TableConfig(getColumnSearchProps) {
  return {
    columns: [
      {
        dataIndex: 'queue_name',
        title: 'Очередь',
        ...getColumnSearchProps('queue_name', 'очередь'),
      },
      {
        dataIndex: 'somekey',
        title: 'Кол-во талонов со временем обслуживания',
        children: [
          {
            title: '< 2 мин.',
            dataIndex: 'less_2_min',
            key: 'less_2_min',
          },
          {
            title: '< 4 мин.',
            dataIndex: 'less_4_min',
            key: 'less_4_min',
          },
          {
            title: '< 6 мин.',
            dataIndex: 'less_6_min',
            key: 'less_6_min',
          },
          {
            title: '< 8 мин.',
            dataIndex: 'less_8_min',
            key: 'less_8_min',
          },
          {
            title: '< 10 мин.',
            dataIndex: 'less_10_min',
            key: 'less_10_min',
          },
          {
            title: '< 12 мин.',
            dataIndex: 'less_12_min',
            key: 'less_12_min',
          },
          {
            title: '< 14 мин.',
            dataIndex: 'less_14_min',
            key: 'less_14_min',
          },
          {
            title: '< 16 мин.',
            dataIndex: 'less_16_min',
            key: 'less_16_min',
          },
          {
            title: '< 18 мин.',
            dataIndex: 'less_18_min',
            key: 'less_18_min',
          },
          {
            title: '< 20 мин.',
            dataIndex: 'less_20_min',
            key: 'less_20_min',
          },
          {
            title: '> 20 мин.',
            dataIndex: 'more_20_min',
            key: 'more_20_min',
          },
        ],
        // render: (cell, row) => rowFormatter(cell, row),
      },
      {
        dataIndex: 'all_cnt',
        title: 'Всего клиентов обслужено',
        render: (cell, row) => rowFormatter2(cell, row),
        sorter: (a, b) => a.all_cnt - b.all_cnt,
      },
    ],
  };
}
