import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../../../../common/ui/Modal/Modal';
import request from '../../../../../logic/utils/request';
import RenderInputs from './RenderInputs';
import AddButton from '../../../../../pages/private/utils/AddButton';
import ModalButtons from '../../../../../common/ui/Modal/ModalButtons';
import RenderConfig from './RenderConfig';

function NewDisplay({
  isOpen,
  toggleOpen,
  fetchDisplays,
  setDisplayUpdate,
  setDisplayData,
  displayData,
  initialData,
  preloader,
  displayUpdate,
  showError,
}) {
  const [isValid, setIsValid] = useState(false);

  const resetModal = () => {
    setDisplayData({
      ...initialData,
    });
    toggleOpen();
    setDisplayUpdate(false);
    setIsValid(false);
  };

  const addDisplay = async () => {
    if (isValid) {
      try {
        await request('/server/api/digitboard/', 'add', {
          ...displayData,
        });
        toast.success('Выполнено', 1000);
        fetchDisplays();
      } catch (err) {
        showError(err.data.error);
      }
      resetModal();
    }
  };

  const updateDisplay = async () => {
    if (isValid) {
      const itemId = displayData.id;
      // eslint-disable-next-line no-param-reassign
      delete displayData.id;
      await request(`/server/api/digitboard/${itemId}/`, 'update', {
        ...displayData,
      });
      toast.success('Выполнено', 1000);
      resetModal();
      fetchDisplays();
    }
  };
  return (
    <>
      <AddButton toggle={toggleOpen} />
      {' '}
      <Modal
        title={displayUpdate ? 'Редактирование табло' : 'Добавление табло'}
        isOpen={isOpen}
        toggleOpen={resetModal}
      >
        <RenderInputs
          displayData={displayData}
          setDisplayData={setDisplayData}
          setIsValid={setIsValid}
          preloader={preloader}
          displayUpdate={displayUpdate}
        />
        <RenderConfig
          data={displayData}
          setData={setDisplayData}
          setIsValid={setIsValid}
          update={displayUpdate}
        />
        <ModalButtons
          isValid={isValid}
          update={displayUpdate}
          updateFunc={updateDisplay}
          addFunc={addDisplay}
          reset={resetModal}
        />
      </Modal>
    </>
  );
}

export default React.memo(NewDisplay);
