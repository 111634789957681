import React, { useState, useRef } from 'react';
import { Table } from 'antd';
import { Title } from '../../../../../../../common/ui/Common';
import TableConfig from './TableConfig';
import { getColumnSearchProps } from './getColumnSearchProps';
import { ReportBox } from '../../../../../components/report/ReportTableColumns';

function QueuesList(props) {
  const { data } = props;
  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const input = useRef(null);

  const config = TableConfig(getColumnSearchProps({
    input,
    searchedColumn,
    searchText,
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    },
    handleReset(clearFilters) {
      clearFilters();
      setSearchText('');
    },
  }));

  return (
    data ? (
      <ReportBox>
        <Title>Список очередей/услуг</Title>
        <div>
          <Table
            rowKey="id"
            dataSource={data}
            columns={config.columns}
            size="large"
            bordered
          />
        </div>
      </ReportBox>
    ) : (<div>Пусто</div>)
  );
}

export default QueuesList;
