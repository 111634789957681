import React, { useState } from 'react';
import { Button, Space } from 'antd';
import Modal from '../../../../../../../common/ui/Modal/Modal';
import request from '../../../../../../../logic/utils/request';
import RenderInputs from './RenderInputs';

function NewWeek({
  isOpen, toggleOpen, fetchData, weekUpdate,
  weekData, setWeekData, setWeekUpdate, preloader, dayList, initialData, write,
}) {
  const [isValid, setIsValid] = useState(false);

  const resetModal = () => {
    setWeekData({ ...initialData });
    toggleOpen();
    setIsValid('');
    setWeekUpdate(false);
  };

  const addWeek = async () => {
    if (isValid) {
      preloader();
      await request('/server/api/dworkweek/', 'add', weekData);
      fetchData();
      resetModal();
      preloader();
    }
  };

  const updateWeek = async () => {
    if (isValid) {
      const { id, ...body } = weekData;

      preloader();
      await request(`/server/api/dworkweek/${id}/`, 'update', body);
      fetchData();
      resetModal();
      preloader();
    }
  };

  const setData = (...props) => {
    setWeekData(...props);
    setIsValid(true);
  };

  return (
    <>
      {write && (
        <Button type="primary" size="large" onClick={toggleOpen}>
          Добавить
        </Button>
      )}
      <Modal title={weekUpdate ? 'Редактирование недели' : 'Добавление недели'} isOpen={isOpen} toggleOpen={resetModal}>
        <RenderInputs
          weekUpdate={weekUpdate}
          weekData={weekData}
          dayList={dayList}
          write={write}
          setWeekData={setData}
        />
        {write && (
          <Space direction="horizontal" size="large" style={{ justifyContent: 'center', width: '100%' }}>
            <Button type="primary" onClick={weekUpdate ? updateWeek : addWeek}>
              Сохранить
            </Button>
            <Button onClick={resetModal} cancel>Отменить</Button>
          </Space>
        )}
      </Modal>
    </>
  );
}

export default React.memo(NewWeek);
