import { Store } from 'pullstate';

const initState = {
  records: [],
  activeScreen: 'start',
  errorText: '',
};

const scanPolicyStore = new Store(initState);
export default scanPolicyStore;

export const updateScanPolicyStore = (values) => {
  const updatedValues = (s) => {
    Object.keys(values).forEach((key) => {
      // eslint-disable-next-line no-param-reassign
      s[key] = values[key];
    });
  };
  scanPolicyStore.update(updatedValues);
};

export const resetScanPolicyStore = () => {
  updateScanPolicyStore({ ...initState });
};
