import styled from 'styled-components';

export const FilterBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(50% - 3px);
  width: 100%;    
  padding: 35px 20px 15px;
  margin-bottom: 30px;
  box-shadow: inset 0 0 3px rgba(0,0,0,.05);
  border-radius: 4px;
  &:nth-child(2n) {
    border-left: none
  }
`;
export const FiltersBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const ItemsBox = styled.div`
  display: flex;
  margin: 0 -2.5px;
  flex-wrap: wrap;
`;
export const InputBox = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(p) => p.theme.accent};
    max-width: ${(p) => (p.show ? '40px' : 0)};
    width: 100%;
    overflow: hidden;
    height: 40px;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.1s ease-out;
    svg path {
      fill: ${(p) => p.theme.buttonHover}
    }
    &:hover {
      box-shadow: 0 0 0 3px ${(p) => p.theme.accentl};
    }
  }
`;
export const TextItem = styled.li`
  background: ${(p) => (p.active ? p.theme.accent : p.theme.bgTable)};
  color: ${(p) => (!p.active ? p.theme.text : p.theme.buttonHover)};
  border-radius: 3px;
  height: 35px;
  padding: 0 40px 0 10px;
  display: flex;
  align-items: center;
  margin: 2.5px;
  flex-wrap: wrap;
  position: relative;
  span {
    position: absolute;
    color: ${(p) => p.theme.accent};
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    top: 8px;
    transition: 0.15s ease-out;
    height: 20px;
    cursor: pointer;
    border-radius: 50%;
    background: ${(p) => p.theme.buttonHover};
    right: 5px;
  }
`;
export const SelectItem = styled.div`
  margin: 5px 0;
  cursor: pointer;
  padding: 10px 25px;
  color: ${(p) => (p.active ? p.theme.buttonHover : p.theme.text)};
  border-radius: 3px;
  box-shadow: ${(p) => (p.active ? '0 0 3px rgba(0,0,0,.1)' : 'inset 0 0 3px rgba(0,0,0,.1)')};
  background: ${(p) => (p.active ? p.theme.accent : p.theme.bg)};
  transition: 0.15s ease-out;
  &:hover {
    background: ${(p) => p.theme.accentl};
  }
`;
