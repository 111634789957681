import Request from '../../../../../../../../../../logic/utils/request';

export default async function SqlAvgWaitTime(time, endTime) {
  const query = `select qt.fullname, qt.deleted, coalesce(ft.avg_wait_time, '0') as avg_wait_time, ft.avg_none_format_time from (
    (select id, fullname, deleted from eq.tqueue) qt left join (select q_id,
    (sum(totalwait) / count(distinct ticket_id)) * interval '1 sec' as avg_wait_time,
    (sum(totalwait) / count(distinct ticket_id)) as avg_none_format_time
    from eq.ttickethistory where modified_on::timestamp >= '${time}' and modified_on::timestamp <= '${endTime}' and status in(3, 4)
    group by q_id) ft on qt.id = ft.q_id)  where qt.deleted = false order by avg_none_format_time desc nulls last`;

  const dataObjects = [];
  const res = await Request('/server/api/genreportfromquery/', 'get', {
    querySql: `${query}`,
  });

  const answer = res.data.data;
  answer.forEach((v) => {
    dataObjects.push({ ...v });
  });

  return { dataObjects, query };
}
