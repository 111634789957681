import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { FilterBox, FilterBoxTitle, FilterItemBox } from '../style';
import OrderElement from './OrderElement';
import OrderRulesElement from './OrderRulesElement';

function Column({
  column, tasks, id, changeFilter,
}) {
  return (
    <FilterBox key={id} Order>
      <FilterBoxTitle>{column.title}</FilterBoxTitle>
      <Droppable droppableId={id}>
        {(provided) => (
          <FilterItemBox
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {tasks.map((task, index) => {
              if (id === 'orderRules') {
                return (
                  <OrderRulesElement
                    index={index}
                    task={task}
                    key={task.id}
                    changeFilter={changeFilter}
                  />
                );
              }
              return (<OrderElement index={index} task={task} key={task.id} />
              );
            })}
            {provided.placeholder}
          </FilterItemBox>
        )}
      </Droppable>
    </FilterBox>
  );
}

export default Column;
