import React from 'react';
import { useSelector } from 'react-redux';
import { TwoScreenBox, BottomBox } from '../../utils/style';
import RenderButtons from './renderButtons';
import Information from './information';

function TwoScreen({ ...props }) {
  const { style } = props;
  const { layoutData, ticket } = useSelector((state) => ({
    layoutData: state.config.config.layoutData,
    startPath: state.config.startPath,
    ticket: state.ticket,
  }));

  const styleBlock = {
    background: style.button.backgroundBottom,
    maxHeight: style.button.bottomHeight,
    minHeight: style.button.bottomHeight,
  };
  const item = layoutData.find((el) => el.type === 'startScreen');
  let bottomBlock = '';

  if (item) {
    let pathId = '';
    item.buttons.forEach((el) => {
      if (el.params.value === ticket.q_id) pathId = el.path;
    });
    if (pathId) {
      bottomBlock = layoutData.find((el) => el.screenId === pathId).bottomBlock;
    } else {
      bottomBlock = item.bottomBlock;
    }
  }

  const notButtons = item.buttons.length === 0;

  if (bottomBlock) {
    return (
      <TwoScreenBox style={{ maxHeight: notButtons ? styleBlock.maxHeight : null }}>
        {!notButtons && <div><RenderButtons {...props} /></div>}

        <BottomBox style={styleBlock}>
          <Information {...props} buttons={bottomBlock.buttons} bottomBlock={bottomBlock} />
        </BottomBox>

      </TwoScreenBox>
    );
  }
  return null;
}

export default TwoScreen;
