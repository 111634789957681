import React, { useState, useEffect } from 'react';
import { Input as AntInput } from 'antd';
// import { Input } from '../../../../../../../../../common/ui/Common';
import { TestDiv, TestDiv2, TestDiv3 } from '../../style';

function AllFilterInput({
  changeFilter, filld, title, smallTitle, stringValue,
}) {
  const defaultFilterData = {
    value: '',
  };
  const [filterData, setFilterData] = useState(defaultFilterData);

  const handleInput = (e) => {
    const { value } = e.target;
    setFilterData({ ...filterData, value });
  };

  useEffect(() => {
    const where = `${filterData.value}`;
    if (where !== '') {
      if (stringValue) {
        changeFilter(filld, `= '${where}'`);
      } else {
        changeFilter(filld, `= ${where}`);
      }
    } else {
      changeFilter(filld, 'all');
    }
  }, [filterData, changeFilter, filld]);

  return (
    <TestDiv2>
      <TestDiv>
        {smallTitle ? (<div style={{ fontSize: '12px' }}>{title}</div>) : (
          <div>
            {' '}
            {title}
            {' '}
          </div>
        )}
        <div
          style={{
            marginLeft: '10px', width: '200px', display: 'flex', position: 'relative', justifyContent: 'center',
          }}
        >
          {/* <Input */}
          {/*  type="text" */}
          {/*  onChange={handleInput} */}
          {/*  value={filterData.value} */}
          {/*  m="0px" */}
          {/* /> */}
          <AntInput onChange={handleInput} />
        </div>
      </TestDiv>
      <TestDiv3 />
    </TestDiv2>

  );
}

export default AllFilterInput;
