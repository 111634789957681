async function requestNotificationPermission() {
  try {
    // Проверяем поддержку нотификаций
    if (!('Notification' in window)) {
      console.warn('Этот браузер не поддерживает десктопные уведомления');
      return false;
    }

    // Если разрешения уже получены, возвращаем true
    if (Notification.permission === 'granted') {
      return true;
    }

    // Если разрешения были отклонены ранее, возвращаем false
    if (Notification.permission === 'denied') {
      console.warn('Пользователь отклонил разрешение на уведомления');
      return false;
    }

    // Запрашиваем разрешение
    const permission = await Notification.requestPermission();
    return permission === 'granted';
  } catch (error) {
    console.error('Ошибка при запросе разрешений на уведомления:', error);
    return false;
  }
}

function showNotification(title, options) {
  if (Notification.permission === 'granted') {
    try {
      const isMonitoringPage = window.location.pathname.includes('/monitoring');
      const notification = new Notification(title, {
        ...options,
        icon: '/admin/favicon.png',
        requireInteraction: false,
      });

      // Обработчик клика по нотификации только если мы не на странице мониторинга
      if (!isMonitoringPage) {
        notification.onclick = (event) => {
          event.preventDefault();
          window.focus();
          window.location.href = '/monitoring/';
        };
      }
    } catch (error) {
      console.error('Ошибка при показе уведомления:', error);
    }
  }
}

export { requestNotificationPermission, showNotification };
