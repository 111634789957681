import React from 'react';
import { Circle } from '../../../../../../common/ui/Common';

export default function loader() {
  return (
    <svg className="loader" viewBox="0 0 24 24">
      <Circle cx="12" cy="12" r="10" />
    </svg>
  );
}
