import { useCallback } from 'react';
import SqlQuery from './SqlQuery';
import BasicBarReportSettings from '../BasicBarReportSettings';

export function useAnswerFnc({
  setLoader,
  startDate,
  endDate,
  setReportParams,
}) {
  return useCallback(async () => {
    setLoader(true);
    const barData = await SqlQuery(startDate, endDate);
    const BasicSettings = BasicBarReportSettings();

    const allData = {
      ...BasicSettings,
      label: 'Всего создано',
      data: barData.count_crate,
      pointHoverBackgroundColor: '#7486fc',
      pointBorderColor: '#7486fc',
      borderColor: '#7486fc',
      backgroundColor: 'rgba(3, 82, 252, 0.4)',
    };

    const noneData = {
      ...BasicSettings,
      label: 'Закрыто по неявке',
      data: barData.count_neyavka,
      pointHoverBackgroundColor: '#f75454',
      pointBorderColor: '#f75454',
      borderColor: '#f75454',
      backgroundColor: 'rgba(252, 5, 5, 0.4)',
    };

    const createData = {
      ...BasicSettings,
      label: 'Удалено системой',
      data: barData.count_del,
      pointHoverBackgroundColor: '#019421',
      pointBorderColor: '#019421',
      borderColor: '#019421',
      backgroundColor: 'rgba(2, 179, 40, 0.4)',
    };

    const closeData = {
      ...BasicSettings,
      label: 'Закрытых',
      data: barData.count_close,
      pointHoverBackgroundColor: '#20a8d8',
      pointBorderColor: '#20a8d8',
      borderColor: '#20a8d8',
      backgroundColor: 'rgba(75, 192, 192, 0.4)',
    };
    // createDatanoneData
    const ReportSettings = {
      labels: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
      datasets: [allData, closeData, createData, noneData],
    };
    setReportParams(ReportSettings);
    setLoader(null);
  }, [endDate, startDate]);
}

export default null;
