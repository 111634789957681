import React, { useState } from 'react';
import { Button, Space } from 'antd';
import Modal from '../../../../../../../common/ui/Modal/Modal';
import request from '../../../../../../../logic/utils/request';
import RenderInputs from './RenderInputs';

function NewInterval({
  isOpen, toggleOpen, fetchIntervals, intervalUpdate, setIntervalData,
  intervalData, initialData, setIntervalUpdate, preloader, write,
}) {
  const [isValid, setIsValid] = useState(false);

  const resetModal = () => {
    setIntervalData({ ...initialData });
    toggleOpen();
    setIsValid('');
    setIntervalUpdate(false);
  };

  const addInterval = async () => {
    if (isValid) {
      preloader();
      await request('/server/api/dworkinterval/', 'add', intervalData);
      fetchIntervals();
      resetModal();
      preloader();
    }
  };

  const updateInterval = async () => {
    if (isValid) {
      const { id, ...body } = intervalData;
      preloader();
      await request(`/server/api/dworkinterval/${id}/`, 'update', body);
      fetchIntervals();
      resetModal();
      preloader();
    }
  };

  return (
    <>
      {write && (
        <Button type="primary" size="large" onClick={toggleOpen}>
          Добавить
        </Button>
      )}
      <Modal title={intervalUpdate ? 'Редактирование интервала' : 'Добавление интервала'} isOpen={isOpen} toggleOpen={resetModal}>
        <div style={{ width: '100%' }}>
          <RenderInputs
            intervalUpdate={intervalUpdate}
            intervalData={intervalData}
            write={write}
            setIntervalData={setIntervalData}
            setIsValid={setIsValid}
          />
        </div>
        {write && (
          <Space direction="horizontal" size="large" style={{ justifyContent: 'center', width: '100%' }}>
            <Button type="primary" onClick={intervalUpdate ? updateInterval : addInterval}>
              Сохранить
            </Button>
            <Button onClick={resetModal} cancel>Отменить</Button>
          </Space>
        )}
      </Modal>
    </>
  );
}

export default React.memo(NewInterval);
