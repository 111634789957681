import styled from 'styled-components';

export const CardReportBox = styled.div`
  flex-basis: calc(33.3% - 15px);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  margin: 0 7.5px 15px;
  width: 100%;
  position: relative;
`;

export const CardReportTitle = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 15px;
  background-color: ${(p) => p.theme.main};
  color: ${(p) => p.theme.buttonHover};
  margin-bottom: 0;
  text-align: center;
  @media(max-width: 1450px) {
    font-size: 14px;
  }
`;

export const CardReportBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CardReportElement = styled.div`
  background-color: ${(p) => p.theme.bgTable};
  color: ${(p) => p.theme.text};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  font-size: 26px;
  width: 100%;
`;

export const CardReportDiscription = styled.div`
  padding: 5px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: Roboto;
  font-weight: 100;
  position: relative;
  text-align: center;
  color: ${(p) => p.theme.bgButton};
  background-color: ${(p) => p.theme.accent};
  &:after {
    position: absolute;
    content: '';
    bottom: -19px;
    left: 50%;
    transform: translateX(-50%);
    border: 10px solid transparent;
    border-top: 10px solid ${(p) => p.theme.accent};
  }
  @media(max-width: 1450px) {
    font-size: 10px;
  }
`;

export const CardReportItem = styled.div`
  padding: 15px 10px;
  text-align: center;
  font-family: Roboto;
  font-weight: 800;
`;
