import styled, { keyframes, css } from 'styled-components';
import c from '../../../../common/ui/theme/Colors';

export const SettingsBox = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
  `;
export const TicketConstructorHead = styled.div`
    display: flex;
    flex-direction: column;
  `;
export const TicketConstructorName = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    color: ${(p) => p.theme.text};
    input {
      margin-top: 15px;
      background: ${(p) => p.theme.bgTable};
    }
  `;
export const TicketFieldBox = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${(p) => (p.width ? `${p.width}px` : 'auto')};
  `;
export const ImgContainerSettings = styled.div`
    background: ${(p) => p.theme.bgTable};
    color: ${(p) => p.theme.text};
    display: flex;
    max-width: ${(p) => p.width || '100%'};
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    padding: 0 0 15px;
    margin: ${(p) => p.m || 0};
  `;
export const HeadArrLine = styled.div`
    background: ${(p) => p.theme.bgTable};
    border-bottom: 2px solid ${(p) => p.theme.accent};
    margin-bottom: 15px;
    display: flex;
    padding: 0 15px;
    //justify-content: space-between;
  `;
export const InputFile = styled.input`
    opacity: 0;
    height: 0;
    width: 0;
    //justify-content: space-between;
  `;
export const SpanSelect = styled.span`
    padding: 11px;
    width: 100%;
    background-color: ${(p) => p.theme.accent};
    color: ${(p) => p.theme.buttonHover};
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    transition: 0.15s ease-out;
    &:hover {
      box-shadow: ${(p) => `0 0 0 3px ${p.theme.accentl}`} ;
    }
  `;
export const Label = styled.label`
    width: 30%;
  `;
export const Item = styled.div`
    padding: 20px 0;
    display: flex;
    width: ${(p) => p.width || 'auto'};
    flex-direction: column;
  `;
export const TableRow = styled.div`
    display: flex;
    padding: 0 15px;
    justify-content: flex-start;
    position: relative;
    &:after {
      position: absolute;
      content: '';
      left: -15px;
      top: 3px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: ${(p) => (p.active ? c.accent : '')};
    }
  `;
export const Name = styled.div`
    min-width: 35%;
  `;
export const DontImg = styled.div`
    min-width: 30%;
    display: flex;
    padding: 10px 10px 0;
  `;
const HoverAnimGreen = (color) => keyframes`
  0% {
    box-shadow: 0 0 0 ${color}
  }
  100% {
    box-shadow: 0 0 0 5px ${color}
  }
`;
export const AlingItem = styled.div`
    min-width: 45px;
    background-color: ${(p) => p.color};
    margin: 0 7px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    background: ${(p) => (p.active ? p.theme.accent : p.theme.disable)};
    svg path {
      fill: ${(p) => p.theme.buttonHover};
    }
    &:hover {
      animation: ${(p) => css`${HoverAnimGreen(p.theme.accentl)} 0.3s ease-out`};
    }
  `;

export const LastIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    min-width: 45px;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 10px;
    height: inherit;
    margin-bottom: 10px;
    background: ${(p) => (p.active ? p.theme.accent : p.theme.disable)};
    svg path {
      fill: ${(p) => p.theme.buttonHover};
    }
    &:hover {
      animation: ${HoverAnimGreen} 0.3s ease-out;
    }
  `;
export const EditButton = styled.div`
    display: flex;
    top: 0;
    right: 0;
    position: absolute;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 10px;
    height: inherit;
    margin-bottom: 10px;
    background: ${(p) => p.theme.accent};
    svg path {
      fill: ${(p) => p.theme.buttonHover};
    }
  `;
export const BodySettingModal = styled.div`
    display: flex;
    flex-direction: column;
  `;
export const TicketVisual = styled.div`
    position: absolute;
    display: inline-table;
    width: ${(p) => (p.width ? '250px' : 'max-content')};
    transform: ${(p) => p.t};
    left: ${(p) => p.a};
    max-width: 470px;
    text-align: center;
    img {
      width: ${(p) => (p.width ? '100%' : 'auto')};
    }
  `;
export const TicketConstructorBox = styled.div`
    position: absolute;
    top: ${(p) => p.top};
    right: ${(p) => p.right};
    background-color: ${(p) => p.theme.buttonHover};
    transform: scale(${(p) => p.scale});
    box-shadow: 0 1px 3px rgba(0,0,0,.1), 0 10px 20px rgba(0,0,0,.05);
    border-radius: 5px;
    width: 500px;
    height: 500px;
  `;
export const Img = styled.img`
    object-fit: cover;
    object-position: center;
    
  `;
export const ItemModal = styled.div`
    display: flex;
    padding: 10px 15px;
    border-radius: 3px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transition: 0.3s ease-out;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    margin-bottom: 2px;
    background: ${(p) => p.theme.bg};
    color: ${(p) => p.theme.text};
    svg path {
      fill: ${(p) => (p.active ? p.theme.accent : p.theme.disable)};
    }
    &:hover {
      svg path {
        fill: ${(p) => p.theme.accent};
      }
    }
  `;
