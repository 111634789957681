import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Container, BottomButton, InputBox, KeyboardContainer,
} from '../../utils/style';
import Input from '../../../../../../common/ui/Input/input';
import Keyboard from '../common/keyboard';

import { changeDictionary } from '../../../store/actions';
import store from '../../../store/store';

const { dispatch } = store;

function SelectedInput(props) {
  const {
    data, button, screenPath, styleButton, style, styleBottom, matchId, setInputId,
  } = props;
  const { dictionary } = useSelector((state) => state.ticket);
  const el = dictionary.find((dict) => dict.fid === +matchId);
  const [value, setValue] = useState(el ? el.fv : '');
  const {
    position, left, top, styleShow,
  } = styleBottom;

  const onClick = () => {
    dispatch(changeDictionary({
      fid: +matchId, fv: value, to: screenPath, input: true,
    }));
    setInputId(null);
  };
  const newStyle = { ...styleBottom };
  if (position === 'absolute') {
    newStyle.position = 'static';
  }
  if (!styleShow) {
    delete newStyle.background;
    delete newStyle.boxShadow;
    newStyle.borderWidth = 0;
  }

  // TODO problem here
  return (
    <KeyboardContainer between>
      <KeyboardContainer>
        <InputBox button={style}>
          <Input
            style={style}
            key={button.id}
            item={button}
            value={value}
            id={button.params.fid}
            buttonId={button.id}
            data={data}
          />
          <Keyboard
            styleButton={styleButton}
            login={50}
            id={button.params.value}
            width={styleButton.width}
            setValue={setValue}
            value={value}
          />
        </InputBox>
      </KeyboardContainer>
      {matchId
      && (
        <Container style={{
          position, top, left, width: position === 'absolute' ? 'min-content' : '100%',
        }}
        >
          <BottomButton style={{ ...newStyle, minWidth: '150px' }} onClick={onClick}>
            <p>Далее</p>
          </BottomButton>
        </Container>
      )}
    </KeyboardContainer>
  );
}

export default React.memo(SelectedInput);
