import React from 'react';
import SelectInput from '../../../../../Elements/Input2/SelectInput/SelectInput';

const position = ({
  element, value, dispatch, title,
}) => {
  const options = [
    { id: 'top-right', name: 'Шапка, справа' },
    { id: 'top-left', name: 'Шапка, слева' },
    { id: 'bottom-right', name: 'Подвал, справа' },
    { id: 'bottom-left', name: 'Подвал, слева' },
  ];
  return (
    <span className="box__color">
      <span
        className="settings__styles__el__label"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <SelectInput
        noDefault
        width="180px"
        value={value}
        onChange={(e) => dispatch({
          type: 'params',
          name: element,
          param: 'position',
          value: e.target.value,
        })}
        options={options}
      />
    </span>
  );
};

export default position;
