import React, { useState } from 'react';
import moment from 'moment';
import useInterval from '../../utils/useInterval';
import {
  ButtonAction,
  ButtonGroup,
  CurrentTicketInfo,
  CurrentTicketData,
  CurrentTicketTimers, Timer, TimerLabel, TicketHeader,
  ErrorMessage,
} from '../../styles/Operator';
import c from '../../../common/ui/theme/Colors';

function ActiveTicket(props) {
  const { wsClient, currentTicket, setCurrentTicket } = props;
  const { ticketNumber, queueName } = currentTicket;

  const [currentTicketTime, setCurrentTicketTime] = useState(0);

  const [error, setError] = useState([]);

  useInterval(() => {
    setCurrentTicketTime(currentTicketTime + 1);
  }, 1000);

  const ticketAction = async (action) => {
    try {
      const tData = await wsClient.sendMessage({
        command: action,
      });
      const { status, message } = tData;
      if (status === 'ok') {
        setCurrentTicket(null);
      } else {
        throw new Error(message);
      }
    } catch (e) {
      const { text: { rus } } = e;
      setError(rus);
    }
  };

  const formatTimer = (time) => moment.utc(time * 1000).format('HH:mm:ss');

  return (
    <>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <TicketHeader>
        {`Текущий талон - ${ticketNumber} / Очередь - ${queueName}`}
      </TicketHeader>
      <CurrentTicketInfo>
        <CurrentTicketData />
        <CurrentTicketTimers>
          <TimerLabel>Время обслуживания:</TimerLabel>
          <Timer>
            {formatTimer(currentTicketTime)}
          </Timer>
        </CurrentTicketTimers>
      </CurrentTicketInfo>
      <ButtonGroup>
        <ButtonAction bg={c.green} onClick={() => { ticketAction('rmoDeleteTicket'); }}>Закрыть</ButtonAction>
        <ButtonAction bg={c.accent} onClick={() => { ticketAction('rmoWaitDecision'); }}>Отложить</ButtonAction>
        <ButtonAction bg={c.green} onClick={() => { ticketAction('rmoRefundTicket'); }}>Вернуть в очередь</ButtonAction>
      </ButtonGroup>
    </>
  );
}
export default ActiveTicket;
