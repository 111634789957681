import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import NpsCard from './NpsCard';

function NpsReport({ settings, preloader }) {
  const [reportData, setReportData] = useState(undefined);
  const { startDate, endDate, questId } = settings;

  const [showPreloader, setShowPreloader] = useState(false);

  const fetchReportData = async () => {
    preloader(true);
    try {
      const url = '/api/reports/build';
      const type = 'npsReport';
      const token = window.localStorage.getItem('token');
      const res = await axios({
        method: 'post',
        url,
        data: {
          token,
          filters: { main: { startDate, endDate } },
          type,
        },
      });

      for (let i = 0; i < res.data.answer.length; i += 1) {
        if (res.data.answer[i].id === questId) {
          setReportData(res.data.answer[i]);
          break;
        }
      }
      preloader(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      preloader(false);
    }
  };

  useEffect(() => {
    fetchReportData();
  }, [settings]);

  const fix = (ref) => {
    setShowPreloader(true);
    const barcodeSvg = ref.current;
    html2canvas(barcodeSvg).then((canvas) => {
      const imgData = canvas.toDataURL(
        'image/png',
      );
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('landscape', 'pt', 'a3');
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save(`отчет НПС ${startDate} - ${endDate}.pdf`);
    });
    setShowPreloader(false);
  };

  return (
    <div>
      {showPreloader && <div style={{ fontSize: '500px' }}>Ожидайте отчет формируется</div>}
      {reportData && <NpsCard data={reportData} test={fix} />}
    </div>
  );
}

export default NpsReport;
