import Request from '../../../../../../../../logic/utils/request';

async function SqlQuery(time, endTime, operatorId, MarkValue) {
  const query = `select distinct aa.ticket_id, aa.number, aa.modified_by_id, to_char(aa.created_on, 'DD.MM.YYYY hh24:MI') as create_data,
  tu.fio, aa.mark, to_char(aa.mark_create_data, 'DD.MM.YYYY hh24:MI') as mark_create_data
  from (select th.*, tm.mark, tm.created_on as mark_create_data
  from (( select * from eq.ttickethistory) th
  left join (select * from eq.tticketmarks) tm on
  th.ticket_id = tm.ticket_id and th.modified_by_id = tm.user_id)
  where mark = '${MarkValue}' and th.modified_by_id = '${operatorId}' 
  and th.modified_on::timestamp >= '${time}' and th.modified_on::timestamp <= '${endTime}')aa,
  (select * from eq.tuser) tu where tu.id = aa.modified_by_id`;

  const dataObjects = [];
  const res = await Request('/server/api/genreportfromquery/', 'get', {
    querySql: `${query}`,
  });

  const answer = res.data.data;
  answer.forEach((v) => {
    if (v[2] !== null) {
      dataObjects.push({ ...v });
    }
  });

  return { dataObjects, query };
}

export default SqlQuery;
