import React, {
  useState, useEffect, useRef,
} from 'react';
import {
  Table, Input, Button, Space,
} from 'antd';
import 'antd/dist/antd.min.css';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import SqlQuery from './SqlQuery';
import TableConfig from './TableConfig';
import ReportTitle from '../../../ReportTitle';
import Preloader from '../../../../../../../../components/preloader/Preloader';
import { PreloaderBox } from '../../../../../../../../../../common/ui/Common';
import { ReportBox } from '../../../../../../../../components/report/ReportTableColumns';

function Operator(props) {
  const {
    view, loader, setLoader, startDate, endDate,
  } = props;

  const [ReportParams, setReportParams] = useState();

  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const input = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex, searchItemText) => ({
    // eslint-disable-next-line react/no-unstable-nested-components
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={input}
          placeholder={`Найти ${searchItemText}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Найти
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Очистить
          </Button>
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => input.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  useEffect(() => {
    if (view) {
      setLoader(true);
      SqlQuery(startDate, endDate).then((resp) => {
        const { question, data } = resp;
        const params = {
          items: data,
          question,
          TableConfig: TableConfig(getColumnSearchProps),
        };
        setReportParams(params);
        setLoader(null);
      });
    }
  }, [endDate, startDate, view]);

  return (
    <ReportBox>
      <ReportTitle
        item={ReportParams ? ReportParams.items.length : null}
        name="операторы"
        exportDisable
      />
      { ReportParams
          && (
          <Table
            keys="user_id"
            rowKey="user_id"
            dataSource={ReportParams.items}
            columns={ReportParams.TableConfig.columns}
            size="large"
            bordered
          />
          )}
      { loader
          && (
            <PreloaderBox>
              <Preloader isOpen small />
            </PreloaderBox>
          )}
    </ReportBox>
  );
}

export default Operator;
