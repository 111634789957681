import React, { useState, useCallback, useEffect } from 'react';
import request from '../../../../../logic/utils/request';
import { Title, PreloaderBox } from '../../../../../common/ui/Common';
import FieldsTable from './table/FieldsTable';
import { useToggle } from '../../../../../logic/hooks/useToggle';
import NewField from './new-field/NewField';

const initialData = {
  description: '',
  name: '',
  rus_name: '',
  type: '',
};

function Fields({ preloader, showError, write }) {
  const [fieldList, setFieldList] = useState(null);
  const [fieldData, setFieldData] = useState(initialData);
  const [fieldUpdate, setFieldUpdate] = useState(false);
  const [bookUpdate, setBookUpdate] = useState(false);
  const [isOpen, toggleOpen] = useToggle(false);

  const fetchFields = useCallback(async () => {
    const res = await request('/server/api/ticketsfields/', 'get');
    setFieldList(res.data.data);
  }, []);

  useEffect(() => {
    fetchFields();
  }, [fetchFields]);

  return (
    <>
      <Title>Дополнительные поля</Title>
      <NewField
        initialData={initialData}
        preloader={preloader}
        toggleOpen={toggleOpen}
        write={write}
        isOpen={isOpen}
        fetchFields={fetchFields}
        fieldData={fieldData}
        setFieldData={setFieldData}
        setFieldUpdate={setFieldUpdate}
        fieldUpdate={fieldUpdate}
        bookUpdate={bookUpdate}
        setBookUpdate={setBookUpdate}
        showError={showError}
      />
      <PreloaderBox>
        <FieldsTable
          fieldList={fieldList}
          fetchFields={fetchFields}
          preloader={preloader}
          setFieldUpdate={setFieldUpdate}
          setFieldData={setFieldData}
          toggleOpen={toggleOpen}
          write={write}
          setBookUpdate={setBookUpdate}
          showError={showError}
        />
      </PreloaderBox>
    </>
  );
}
export default Fields;
