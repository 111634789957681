import Request from '../../../../../../../../logic/utils/request';

export default async function SqlQuery(selectedData) {
  const query = `select count(ss.ticket_id) as cnt,
  ss.data_hh from (select tt.* from (select
  to_char(ht.modified_on, 'HH24') as data_hh,
  to_char(ht.modified_on, 'DD.MM.YYYY') as data_dd_mm_yyyy,
  ht.modified_on,
  ht.status,
  ht.ticket_id
  from eq.ttickethistory ht) tt 
  where tt.data_dd_mm_yyyy = '${selectedData}'
  and tt.status = '0') ss
  group by data_hh order by data_hh`;

  const dataObjects = [];
  const res = await Request('/server/api/genreportfromquery/', 'get', {
    querySql: `${query}`,
  });

  const answer = res.data.data;
  answer.forEach((v) => {
    dataObjects.push({ ...v });
  });

  return { dataObjects, query };
}
