/* eslint-disable */
class WebSocketClient {
  ipAddress = null;

  port = null;

  secure = false;

  webSocket = null;

  isConnecting = false;

  isConnected = false;

  isWaiting = false;

  lastMessage = null;

  shouldReconnect = false;

  responseDeffers = {};

  requestId = 1;

  eventCallback = null;

  keepaliveTimer = null;

  constructor(ip, port, eventCallback = (msg) => console.log(msg), initCallback = (msg) => console.log(msg), errorCallback = (msg) => console.log(msg)) {
    this.ipAddress = ip;
    this.port = port;
    this.eventCallback = eventCallback;
    this.initCallback = initCallback;
    this.errorCallback = errorCallback;
    this.connect();
  }

  connect = () => {
    if (!this.ipAddress || this.ipAddress === 'NOT_SET') {
      return;
    }
    this.isConnecting = true;
    this.isWaiting = false;
    try {
      if (window.location.protocol !== 'https:') {
        this.webSocket = new WebSocket(`ws://${this.ipAddress}:${this.port}/websocket`);
      } else {
        this.webSocket = new WebSocket(`wss://${this.ipAddress}:${this.port}/`);
      }
      this.webSocket.onopen = this._onConnectionOpen;
      this.webSocket.onclose = this._onConnectionClose;
      this.webSocket.onmessage = this._onMessage;
      this.webSocket.onerror = this._onConnectionError;
      this.shouldReconnect = true;
    } catch (err) {
      this.disconnect();
    }
  }

  disconnect = () => {
    this.shouldReconnect = false;
    console.log('Socket disconnect');
    this.webSocket.close();
  }

  waitForSocketConnection = () => {
    setTimeout(
      () => {
        const { readyState } = this.webSocket;
        if (readyState === 1) {
          console.log('Connection is made');
        } else {
          console.log('Wait for connection...');
          this.waitForSocketConnection();
        }
      }, 100,
    ); // wait 100 miliseconds for the connection...
  };

  _onConnectionOpen = () => {
    this.isConnecting = false;
    this.isConnected = true;
    this.keepaliveTimer = setInterval(() => this.webSocket.send('keepalive'), 4000);
    this.initCallback(this.webSocket);
  }

  _onConnectionError = () => {
    this.isConnecting = false;
    this.isConnected = false;
    console.log('Connection error');
    // this.errorCallback();
    // this.shouldReconnect = true;
    // if (this.shouldReconnect) {
    //   clearTimeout(this._timer);
    //   this._timer = setTimeout(() => {
    //     this.connect();
    //   }, 2000);
    // }
  }

  _onConnectionClose = () => {
    clearInterval(this.keepaliveTimer);
    this.isConnecting = false;
    this.isConnected = false;
    console.log('Connection close');
    this.errorCallback();
    if (this.shouldReconnect) {
      clearTimeout(this._timer);
      this._timer = setTimeout(() => {
        this.connect();
      }, 2000);
    }
  }

  _onMessage = (msgText) => {
    let { data: msg } = msgText;

    if (msg.indexOf('Disconnected, due to inactivity.') !== -1) return;
    if (msg.indexOf('keepalive_to_client') !== -1) return;
    if (msg.indexOf('keepalive:serv_ws_ok') !== -1) return;

    msg = JSON.parse(msg);
    const { msid, eqEvent } = msg;
    if (eqEvent) this.eventCallback(eqEvent);
    if (!msid) return;
    if (msg.status === 'ok') this.resolveRequest(msid, msg);
    else this.rejectRequest(msid, msg.error);
  }

  sendMessage = (msg) => {
    const { command } = msg;
    const msid = command;
    this.requestId += 1;
    const reqId = `${msid}-${this.requestId}`;
    const sendData = msg;
    sendData.msid = reqId;
    return new Promise((resolve, reject) => {
      this.responseDeffers[reqId] = {
        resolve,
        reject,
      };
      if (this.webSocket.readyState === this.webSocket.OPEN) {
        this.webSocket.send(JSON.stringify(sendData));
      } else {
        reject(new Error('Websocket connection is closed'));
      }
    });
  }

  resolveRequest = (msid, message) => {
    this.responseDeffers[msid].resolve(message);
    delete this.responseDeffers[msid];
  }

  rejectRequest = (msid, message) => {
    this.responseDeffers[msid].reject(message);
  }
}

export default WebSocketClient;
