import Request from '../../../../../../../../../../logic/utils/request';

export default async function SqlQuery(time, endTime) {
  const dataObjects = [];
  const query = `select
  ot.*, tc.part_cnt, days.work_day,
  (tc.part_cnt / days.work_day) as tpd,
  (tc.work_time * interval '1 sec') as work_time, mt.avg_mark, mt.mark_cnt, tc.work_time
  from (
    select
    ut.id as user_id,
    ut.username,
    ut.fio as user_fio
    from
    eq.tuser ut,
    eq.tuaroleuser urt
    where
    ut.id = urt.user_id
    and urt.role_id = 4 and ut.deleted = false) ot
  left join (
  select act.modified_by_id,
  count(act.ticket_id) as part_cnt,
  sum(act.action_time) as work_time
  from
  (
  select
  ticket_id,
  count(status) as action_cnt,
  modified_by_id,
  sum(procforsecs) as action_time
  from
  eq.ttickethistory
  where
  status != 0
  and ticket_id in (
  select
  ticket_id
  from
  eq.ttickethistory
  where
  status in (3,
  4, 9))
  and modified_on::timestamp >= '${time}'
  and modified_on::timestamp <= '${endTime}'
  group by
  ticket_id,
  modified_by_id ) act
  group by
  act.modified_by_id) tc on
  tc.modified_by_id = ot.user_id
  left join (
  select
  ss.modified_by_id,
  count (distinct(ss.days)) as work_day
  from
  (
  select
  modified_by_id,
  to_char(modified_on, 'DD.MM.YYYY') as days
  from
  eq.ttickethistory
  where
  modified_on::timestamp >= '${time}'
  and modified_on::timestamp <= '${endTime}') ss
  group by
  modified_by_id) days on
  days.modified_by_id = ot.user_id
  left join (
  select user_id,
  count(mark) as mark_cnt,
  sum(mark) as mark_sum,
  (sum(mark)*1.0/ count(mark)*1.0) as avg_mark
  from
  eq.tticketmarks
  where
  modified_on::timestamp >= '${time}'
  and modified_on::timestamp <= '${endTime}'
  and ticket_id in (
  select
  ticket_id
  from
  eq.ttickethistory
  where
  status in (3,
  4, 9))
  group by
  user_id) mt on
  ot.user_id = mt.user_id`;

  const res = await Request('/server/api/genreportfromquery/', 'get', {
    querySql: `${query}`,
  });

  const PrepearServerAnswer = (answer) => {
    const data = [];
    const { columnNames, data: answerData } = answer;

    answerData.forEach((dv) => {
      const rElem = {};
      dv.forEach((v, idx) => {
        rElem[columnNames[idx]] = v;
      });
      data.push(rElem);
    });

    return data;
  };

  const data = PrepearServerAnswer(res.data);
  return { dataObjects, query, data };
}
