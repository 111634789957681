import React, { useState, useCallback } from 'react';
import { Table } from 'antd';
import { EmptyList } from '../../../../../common/ui/Common';
import Config from './TableConfig';
import Preloader from '../../../components/preloader/Preloader';
import DeleteWarning from '../../../../../common/ui/Modal/DeleteWarning';
import PlaceParams from './Parameters/PlaceParams';
import request from '../../../../../logic/utils/request';
import { useToggle } from '../../../../../logic/hooks/useToggle';
import Filter from '../../../../../old/views/Elements/Filter';
import DeleteItem from '../../../utils/deleteItem';
import { useSearchPropsFactory } from '../../../utils/useSearch';

function WorkPlacesTable({
  placeList,
  preloader,
  fetchPlaces,
  setPlaceUpdate,
  setPlaceData,
  toggleOpen,
  zones,
  showError,
  write,
  fieldList,
  dictList,
}) {
  const [showDelWarn, setShowDelWarn] = useToggle(false);
  const [params, setParams] = useToggle(false);
  const [placeId, setPlaceId] = useState(false);
  const [placeName, setPlaceName] = useState(false);
  const [openFilter, setOpenFilter] = useToggle(false);
  const [filterData, setFilterData] = useState(null);
  const [valid, setValid] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState(false);
  const [usedDict, setUsedDict] = useState([]);

  const delPlace = async () => {
    await DeleteItem({
      preloader,
      url: `/server/api/wplace/${placeId}`,
      fetchData: fetchPlaces,
      setShowWarn: setShowDelWarn,
      setId: setPlaceId,
      showError,
    });
  };

  const handleTableIcon = (e, row) => {
    const { dataset } = e.target;

    const {
      id, connectedQueues, connectedZones, description,
      dictionary, disabled, name, placename, soundtext,
    } = row;
    if (dataset.name === 'del') {
      setPlaceId(id);
      setPlaceName(name);
      setShowDelWarn();
    } else if (dataset.name === 'update') {
      setPlaceUpdate(true);
      setPlaceData({
        id,
        connectedQueues,
        connectedZones,
        description,
        dictionary,
        disabled,
        name,
        placename,
        soundtext,
      });
      toggleOpen();
    } else if (dataset.name === 'params') {
      setParams();
      setPlaceId(id);
    } else if (dataset.name === 'filter') {
      const item = { ...placeList.find((el) => el.id === id) };
      const oldA = [...item.dictionary.filtersA];
      setUsedDict([...oldA.map((el) => el.fid)]);
      item.dictionary = {};
      item.dictionary.filtersA = oldA;
      const newFiltersA = item.dictionary.filtersA.map((el) => el);
      item.dictionary.filtersA = [...newFiltersA];

      setDefaultFilter(newFiltersA);
      setFilterData(item);
      setOpenFilter();
      setPlaceId(id);
    }
  };
  const resetFilter = useCallback(() => {
    setOpenFilter();
    setUsedDict([]);
    setFilterData(null);
  }, [setOpenFilter]);

  const SaveFilters = useCallback(async () => {
    preloader();
    try {
      delete filterData.params;
      delete filterData.bound_user_fio;
      delete filterData.bound_user_id;
      console.log(filterData);
      await request(`/server/api/wplace/${placeId}`, 'update', filterData);
      fetchPlaces();
      preloader();
      resetFilter();
      setValid(false);
    } catch (e) {
      preloader();
      showError(e.data.error);
    }
  }, [filterData, fetchPlaces, preloader, showError, placeId, resetFilter]);

  const Setting = Config(zones, useSearchPropsFactory());
  const { columns } = Setting;
  const viewPlaceList = () => (
    placeList.length
      ? (
        <Table
          columns={columns}
          dataSource={placeList}
          rowKey="id"
          onRow={(row) => ({
            onClick: (e) => { handleTableIcon(e, row); },
          })}
        />
      ) : <EmptyList>Рабочих мест нет</EmptyList>
  );

  return (
    <>
      {!placeList ? <Preloader isOpen small /> : viewPlaceList()}
      <PlaceParams
        params={params}
        write={write}
        setParams={setParams}
        placeId={placeId}
        preloader={preloader}
      />
      <Filter
        open={openFilter}
        list={fieldList}
        dict={dictList}
        setValid={setValid}
        valid={valid}
        defaultFilter={defaultFilter}
        toggle={setOpenFilter}
        data={filterData}
        reset={resetFilter}
        setData={setFilterData}
        setUsedDict={setUsedDict}
        save={SaveFilters}
        usedDict={usedDict}
      />
      <DeleteWarning
        name={placeName}
        confirmDel={delPlace}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </>
  );
}

export default React.memo(WorkPlacesTable);
