import React, { useEffect, useState } from 'react';
import StarRatings from 'react-star-ratings';
import axios from 'axios';
import { useScreenProviderContext } from '../use/useScreenProviderContext';

function QualityScreen() {
  const [quality, setQuality] = useState(0);
  const {
    changeActiveScreen: setCurrentScreen,
  } = useScreenProviderContext();

  const sendQuality = async () => {
    const ticketId = localStorage.getItem('currentTicketId');
    const wplaceId = localStorage.getItem('wplaceId');

    const tiketStatusRes = await axios.post('/server/public/getDataForRestoreTicket', { idT: ticketId });
    if (tiketStatusRes.statusText === 'OK') {
      const { data: { info: { status } } } = tiketStatusRes;
      if (status === 3) {
        await axios.post(
          '/server/public/setMarkTicket',
          {
            data: {
              ticketId,
              ticketMark: quality,
            },
          },
        );
      } else {
        await axios.post(
          '/server/public/setMark',
          {
            data: {
              wplaceId,
              ticketMark: quality,
            },
          },
        );
      }
    }
    localStorage.clear();
    setCurrentScreen('terminal');
  };

  useEffect(() => {
    if (quality > 0) sendQuality();
  }, [quality]);

  return (
    <div className="ticketProcess">
      <div className="ticketProcess__container">
        <p className="ticketProcess__subText">Пожалуста оцените качество обслуживания</p>
        <div className="ticketProcess__button">
          <StarRatings
            rating={quality}
            starRatedColor="yellow"
            starHoverColor="yellow"
            changeRating={setQuality}
            numberOfStars={5}
            starDimension="45px"
            name="rating"
          />
        </div>
      </div>
    </div>
  );
}

export default QualityScreen;
