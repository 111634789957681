import React, { useState, useCallback, useEffect } from 'react';
import Modal from '../../../../../common/ui/Modal/Modal';
import request from '../../../../../logic/utils/request';
import ParamsItems from './ParamsItems';

function UserParams({
  params, setParams, userId, preloader, write,
}) {
  const [newParams, setNewParams] = useState([]);
  const [defParams, setDefParams] = useState([]);

  const fetchNewParams = useCallback(async () => {
    const res = await request('/server/api/officeparamsbinding/', 'get', { user_id: userId });
    setNewParams([...res.data.data]);
  }, [userId]);

  const fetchDefParams = useCallback(async () => {
    preloader();
    const res = await request('/server/api/officeparams/', 'get');
    const defaultParams = res.data.data.filter((item) => item.applicability.indexOf('user') >= 0);
    setDefParams([...defaultParams]);
    preloader();
  }, [preloader]);

  useEffect(() => {
    if (params) {
      fetchDefParams();
      fetchNewParams();
    }
  }, [params, fetchNewParams, fetchDefParams]);

  return (
    <Modal title="Параметры пользователя" toggleOpen={setParams} isOpen={params}>
      { defParams.length
        ? (
          <ParamsItems
            write={write}
            defParams={defParams}
            newParams={newParams}
            userId={userId}
            fetchNewParams={fetchNewParams}
            fetchDefParams={fetchDefParams}
          />
        )
        : <p>Нет параметров для пользователей</p>}
    </Modal>
  );
}

export default React.memo(UserParams);
