import React, { useContext } from 'react';
import { Collapse } from 'antd'; // DOCS: https://ant.design/components/collapse/
import BoardCtx from '../../../../../../../../../pages/public/board/utils/BoardCtx';
import fontSize from './fontSize';
import colors from './color';
import FontFamily from '../../../../Components/modules/font';
import FontSize from '../../../../Components/FontSize';
import TextComponent from '../../../../Components/modules/text';
import Height from '../../../../Components/modules/height';
import Uppercase from '../../../../Components/modules/uppercase';
import Colors from '../../../../Components/Colors';
import MarginComponent from '../../../../Components/modules/Margin';

function Header() {
  const {
    dispatch, layout, fonts, text, dispatchText,
  } = useContext(BoardCtx);
  const { header, logo } = layout;
  const {
    fontFamily, order, height, positionBottom,
  } = header;
  const { Panel } = Collapse;

  const onRefresh = () => dispatch({
    type: 'params', name: 'header', param: 'height', value: 'auto',
  });

  const headerClocks = layout.clock ? 3 : 2;
  const headerFSLength = layout.clock ? 3 : 1;
  const arrFontSize = fontSize({ header }).slice(0, 2 + headerFSLength);
  const arrColors = colors({ header }).slice(0, 2 + headerClocks);

  return (
    <Collapse className="customize-Collapse" ghost>
      <Panel className="customize-Collapse-Header-No-Top-Padding" header="Текст" key="1">
        <TextComponent title="Заголовок в шапке" element="text" param="headerMain" value={text.headerMain} dispatch={dispatchText} />
        <TextComponent title="Подзаголовок в шапке" element="text" param="headerSub" value={text.headerSub} dispatch={dispatchText} />
        <TextComponent title="Текст в шапке справа" element="text" param="header1" value={text.header1} dispatch={dispatchText} />
      </Panel>

      <Panel className="customize-Collapse-Header-No-Top-Padding" header="Параметры текста" key="2">
        <Collapse className="customize-Collapse" ghost>
          <Panel header="Размер" key="1">
            <FontSize arr={arrFontSize} dispatch={dispatch} />
          </Panel>
        </Collapse>
        <FontFamily options={fonts} title="Шрифт" element="header" value={fontFamily} dispatch={dispatch} />
      </Panel>

      <Panel header="Параметры элементов" key="3">
        <Uppercase title="Логотип справа" element="header" nameParams="order" value={order} dispatch={dispatch} />
        <Uppercase title="Шапка снизу" element="header" nameParams="positionBottom" value={positionBottom} dispatch={dispatch} />
        <Uppercase title="Бегущая строка в шапке" element="header" nameParams="runstring" value={header.runstring} dispatch={dispatch} />
        <Height clear refresh={onRefresh} title="Высота шапки" width="160px" element="header" nameParams="height" value={height} dispatch={dispatch} />
        <MarginComponent data={header} dispatch={dispatch} element="header" name="padding" title="Отступы" />
        <MarginComponent data={logo} dispatch={dispatch} element="logo" name="padding" title="Отступы логотипа" />
      </Panel>

      <Panel className="customize-Collapse-Colors" header="Цвет" key="4">
        <Colors arr={arrColors} dispatch={dispatch} />
      </Panel>
    </Collapse>
  );
}

export default Header;
