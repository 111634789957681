export default ({ footer }) => [
  {
    title: 'Текст', value: footer.color1, paramName: 'color1', parentName: 'footer',
  },
  {
    title: 'Фон', value: footer.background, paramName: 'background', parentName: 'footer',
  },
  {
    title: 'Время и дата', value: footer.color2, paramName: 'color2', parentName: 'footer',
  },
];
