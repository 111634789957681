import React, { useContext } from 'react';

// components
import { Collapse } from 'antd'; // DOCS: https://ant.design/components/collapse/
import Colors from '../../../../../Components/Colors';
import Height from '../../../../../Components/Height';
import PositionOption from '../../../../../Components/PositionOption';
import UppercaseComponent from '../../../../../Components/Uppercase';
import Margin from '../../../../../Components/Margin';
import BoxShadow from '../../../../../Components/modules/BoxShadow';
// import FontWeight from '../../../../../Components/modules/fontWeight';
import Uppercase from '../../../../../Components/modules/uppercase';
import HeightComponent from '../../../../../Components/modules/height';
import Position from './position';

import ArrColor from './components/colors';
import ArrSize from './components/size';
import ArrPosition from './components/position';
import ArrButtons from './components/buttons';
import ArrCapability from './components/capability';
import ArrImages from './components/arrImages';
import ArrMargin from './components/arrMargin';
import UploadImages from '../../../../../BoardStyle2/BoardEditor/UploadImages';
import FontFamily from '../../../../../Components/modules/font';
import terminalCtx from '../../../../../../../../../../pages/public/terminal/context';

function Button() {
  const { fonts, dispatch, data } = useContext(terminalCtx);
  const { bottomButton } = data;
  const buttons = ArrButtons({ bottomButton });
  const colors = ArrColor({ bottomButton });
  const size = ArrSize({ bottomButton });
  const arrPosition = ArrPosition({ bottomButton });
  const arrCapability = ArrCapability({ bottomButton });
  const arrImages = ArrImages({ bottomButton });
  const arrMargin = ArrMargin({ bottomButton });
  const { Panel } = Collapse;

  const onChange = (e, param) => {
    dispatch({
      type: 'params',
      name: 'bottomButton',
      param,
      value: +e.target.value,
    });
  };

  return (
    <Collapse className="customize-Collapse" ghost>
      <Panel header="Кнопки" key="1">
        <UppercaseComponent noText="true" arr={buttons} dispatch={dispatch} />
      </Panel>

      <Panel header="Возможности" key="2">
        <UppercaseComponent noText="true" arr={arrCapability} dispatch={dispatch} />
      </Panel>

      <Panel header="Расположение" key="3">
        <Position onChange={onChange} noText="true" bottomButton={bottomButton} />
      </Panel>

      <Panel header="Отступы" key="4">
        <Margin arr={arrMargin} dispatch={dispatch} />
      </Panel>

      <Panel header="Параметры текста" key="5">
        <Uppercase dispatch={dispatch} nameParams="textTransform" title="Текст заглавными буквами" element="bottomButton" value={bottomButton.textTransform} />
        <FontFamily options={fonts} title="Шрифт" element="bottomButton" value={bottomButton.fontFamily} dispatch={dispatch} />
        <HeightComponent dispatch={dispatch} value={bottomButton.fontSize} widthInput="140px" element="bottomButton" title="Размер шрифта" nameParams="fontSize" />
      </Panel>

      <Panel header="Параметры элементов" key="6">
        <Height widthInput="160px" arr={size} dispatch={dispatch}>
          <BoxShadow dispatch={dispatch} value={bottomButton.boxShadow} title="Тень" element="bottomButton" />
        </Height>
        <PositionOption content arr={arrPosition} dispatch={dispatch} />
      </Panel>

      <Panel header="Изображения для кнопок" key="7">
        <UploadImages smart items={arrImages} dispatch={dispatch} />
      </Panel>

      <Panel header="Цвет" key="8">
        <Colors width="auto" arr={colors} dispatch={dispatch} />
      </Panel>
    </Collapse>
  );
}

export default Button;
