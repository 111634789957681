import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import axios from 'axios';
import { Table } from 'antd';
import 'antd/dist/antd.min.css';
import locale from 'antd/es/locale/ru_RU';
import IntervalTable from '../ReportList/ReportType/IntervalReport/IntervalTable';
import Preloader from '../../../../components/preloader/Preloader';

function PrepareTable(props) {
  const {
    filters: { main }, keys, columns, type, url, hiddenZeroValues,
  } = props;

  const [tableData, setTableData] = useState([]);
  const [tableParams, setTableParams] = useState();
  const [showPreloader, setShowPreloader] = useState(true);

  const [pagination, setPagination] = useState({
    page: 1,
    sizePerPage: 10,
  });

  const isMounted = useRef(false);

  const filterZeroValue = (answer, key) => {
    const result = [];
    answer.forEach((el) => {
      if (el[key] !== '0') result.push(el);
    });
    return result;
  };

  const getHiddenKey = (reportType) => {
    if (reportType === 'operatorReport') return 'all_cnt';
    if (reportType === 'queueReport') return 'all_cnt';
    if (reportType === 'workPlaceQuery') return 'all_cnt';
    return null;
  };

  const onSizePerPageChange = useCallback((sizePerPageNumber, pageNumber) => {
    setPagination({
      ...pagination,
      sizePerPage: sizePerPageNumber,
      page: pageNumber,
    });
  }, [pagination]);

  const onPageChange = useCallback((pageNumber, sizePerPageNumber) => {
    setPagination({
      ...pagination,
      sizePerPage: sizePerPageNumber,
      page: pageNumber,
    });
  }, []);

  const testFunc = async (page) => {
    const limit = 'all';
    const offset = 'all';

    const filters = {
      ...main, limit, offset,
    };

    const token = window.localStorage.getItem('token');
    setShowPreloader(true);
    const res = await axios({
      method: 'post',
      url,
      data: {
        token,
        filters: { main: { ...filters } },
        type,
      },
    });
    if (type === 'intervalReport') {
      const { data: { answer } } = res;
      const ww = { answer };
      setTableParams(ww);
    } else {
      const { data: { answer } } = res;
      const allItemCnt = answer[0] ? Number(answer[0].total_rows_count) : 0;
      setTableData(answer);
      const ww = {
        pagination: {
          current: page,
          pageSize: pagination.sizePerPage,
          total: allItemCnt,
          onChange: onPageChange,
          onShowSizeChange: onSizePerPageChange,
          showSizeChanger: true,
        },
        items: hiddenZeroValues ? filterZeroValue(answer, getHiddenKey(type)) : [...answer],
      };
      setTableParams(ww);
    }
    setShowPreloader(false);
    isMounted.current = true;
  };

  useEffect(() => {
    testFunc(1);
  }, [main]);

  useEffect(() => {
    if (isMounted.current) testFunc(pagination.page);
  }, [pagination]);

  useEffect(() => {
    setTableParams({
      ...tableParams,
      items: hiddenZeroValues ? filterZeroValue(tableData, getHiddenKey(type)) : [...tableData],
    });
  }, [hiddenZeroValues]);

  const buildItervalTable = () => (
    tableParams.answer.myData.length !== 0 ? (
      <IntervalTable
        data={tableParams.answer}
      />
    ) : (<div className="itervalReprotBox">По Вашему запросу ничего не найдено</div>));

  const renderTable = () => {
    if (type === 'ticketReport') {
      return (
        <Table
          locale={locale.Table}
          columns={columns}
          dataSource={tableParams.items}
          rowKey={keys}
          bordered
          scroll={{ x: 2800 }}
        //  loading={loading}
        //  handleChange={handleChange}
        //   pagination={{
        //     ...tableParams.pagination,
        //     locale: locale.Pagination,
        //   }}
        />
      );
    }
    return (
      <Table
        locale={locale.Table}
        rowKey={keys}
        dataSource={tableParams.items}
        columns={columns}
        bordered
        // pagination={tableParams.pagination}
      />
    );
  };

  const preloader = () => (showPreloader ? (<Preloader isOpen={showPreloader} />) : renderTable());

  return tableParams && (type === 'intervalReport' ? (buildItervalTable()) : preloader());
}

export default PrepareTable;
