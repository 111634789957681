import React from 'react';

const text = ({
  value, element, title, dispatch, param = 'text',
}) => (
  <div className="settings__styles__el text">
    {title && <p>{title}</p> }
    <textarea
      onChange={(e) => dispatch({
        type: 'text',
        name: element,
        param,
        value: e.target.value,
      })}
      value={value}
    />
  </div>
);

export default text;
