import React from 'react';
import CheckBoxList from '../../../../../../../pages/private/components/CheckBoxList';

const RenderCheckList = ({ data, setData }) => {
  const options = [
    { id: 2, name: 'Включен' },
    { id: 1, name: 'Выключен' },
  ];

  const items = [
    {
      label: 'Статус:', name: 'status', options, checked: [data.status],
    },
  ];

  return (
    items.map((item) => (
      <div key={item.name}>
        <CheckBoxList
          only
          item={item}
          data={data}
          setData={setData}
        />
      </div>
    ))
  );
};
export default RenderCheckList;
