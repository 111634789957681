import React, { useContext } from 'react';
import Columns from './Columns';
import Big from './Big';
import VideoNewsSchedule from './VideoNewsSchedule';
import BoardCtx from '../../utils/BoardCtx';

function Body({
  layout, text, ticketsList, edit, inSoundAction,
}) {
  const {
    setActiveEl,
  } = useContext(BoardCtx);
  const {
    vertical, footer, window, tidings, date, vid, invert,
  } = layout;

  const renderContent = () => (invert ? (
    <>
      <div style={{ display: 'flex', flex: '1' }}>
        {window && (
          <Big
            styles={layout}
            text={text}
            setActiveEl={setActiveEl}
            ticketsList={ticketsList}
            edit={edit}
          />
        )}
        {(tidings || date || vid) && (
        <VideoNewsSchedule
          styles={layout}
          inSoundAction={inSoundAction}
          text={text}
          setActiveEl={setActiveEl}
        />
        )}
      </div>
      <Columns
        styles={layout}
        text={text}
        setActiveEl={setActiveEl}
        ticketsList={ticketsList}
        edit={edit}
      />
    </>
  ) : (
    <>
      <Columns
        styles={layout}
        text={text}
        setActiveEl={setActiveEl}
        ticketsList={ticketsList}
        edit={edit}
      />
      <div style={{ display: 'flex', flex: '1' }}>
        {window && (
          <Big
            styles={layout}
            text={text}
            setActiveEl={setActiveEl}
            ticketsList={ticketsList}
            edit={edit}
          />
        )}
        {(tidings || date || vid) && (
        <VideoNewsSchedule
          styles={layout}
          inSoundAction={inSoundAction}
          text={text}
          setActiveEl={setActiveEl}
        />
        )}
      </div>
    </>
  ));

  return (
    <div
      className="layout__body_box"
      style={{ flexDirection: vertical ? 'column' : 'row', order: !footer.order ? 2 : 1 }}
    >
      {renderContent()}
    </div>
  );
}

export default Body;
