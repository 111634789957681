import React, { useState } from 'react';
import {
  Button, Card, Col, Drawer, Empty, Input, Row,
} from 'antd';
import Meta from 'antd/lib/card/Meta';
import zoneStore from '../../../Store';
import defaultValues from '../../../../utils/defaultValues';
import queueStore from '../../../Store/queueStore';
import ScreenForm from '../../Constructor/ScreenForm';
import QueueForm from './QueueForm';
import schemeStore from '../../../Store/schemeStore';

function ButtonAction(props) {
  const {
    type, path, params, setFormData,
    screen = {}, button = {}, setIsDisabled,
  } = props;

  const { nodes: treeNodes } = schemeStore.useState((s) => s.treeNode);

  // поиск родительских нодов
  const findSameNodeById = (nodes, id) => {
    const sameNodes = [];

    // поиск вложенных нодов
    const pushSameNodes = (items) => {
      for (const item of items) {
        if (item.id === id) {
          sameNodes.push(item);
        }

        if (item.nodes?.length) {
          pushSameNodes(item.nodes);
        }
      }
    };

    pushSameNodes(nodes);

    if (sameNodes.length === 0) {
      return null;
    }

    const maxParentNode = sameNodes.reduce((maxElement, currentElement) => {
      const maxParentsLength = maxElement.parents?.length || 0;
      const currentParentsLength = currentElement.parents?.length || 0;

      return currentParentsLength > maxParentsLength ? currentElement : maxElement;
    });

    return maxParentNode;
  };

  const parents = findSameNodeById(treeNodes, screen.screenId)?.parents;

  const screens = zoneStore.useState((s) => s.config.screens)
    .filter((item) => (item.screenId !== screen.screenId) && !parents?.includes(item.screenId));
  const queue = queueStore.useState(((s) => s.queue));
  const { buttonActions } = defaultValues;

  const [searchValue, setSearchValue] = useState('');
  const [isPathCreating, setIsPathCreating] = useState(false);

  // идентификация ключей объекта в зависимости от типа (экран, печать, другое)
  const currentId = type === 'screen' ? 'screenId' : 'id';
  const currentPath = type === 'print' ? params.value : path;

  const handleInput = (e) => {
    const { value } = e.target;

    setSearchValue(value);
  };

  const filteredScreens = screens.filter((item) => item.headTitle.toLowerCase()
    .includes(searchValue.toLowerCase()));

  const filteredQueue = queue.filter((item) => item.fullname.toLowerCase()
    .includes(searchValue.toLowerCase()));

  const filteredButtonActions = buttonActions.filter((item) => item.name.toLowerCase()
    .includes(searchValue.toLowerCase()));

  const handleCardClick = (item) => {
    // const changeFormState = (prev) => {
    //   const pathValue = item[currentId] === path ? '' : item[currentId];
    //   const paramsValue = item[currentId] === params.value ? '' : item[currentId];

    //   const currentKey = type === 'print' ? 'params' : 'path';
    //   const currentValue = type === 'print' ? {
    //     ...prev.params,
    //     value: paramsValue,
    //   } : pathValue;

    //   return {
    //     ...prev,
    //     [currentKey]: currentValue,
    //   };
    // };

    // setFormData(changeFormState);

    if (type === 'screen') {
      setFormData((prev) => ({
        ...prev,
        path: item.screenId === path ? '' : item.screenId,
      }));
    }
    if (type === 'print') {
      setFormData((prev) => ({
        ...prev,
        params: {
          ...prev.params,
          value: item.id === params.value ? '' : item.id,
        },
      }));
    }
    if (type === 'other') {
      setFormData((prev) => ({
        ...prev,
        path: item.id === path ? '' : item.id,
      }));
    }
    setIsDisabled(false);
  };

  const toggleDrawer = (toggleType) => {
    if (toggleType === 'open') {
      setIsPathCreating(true);
    } else {
      setIsPathCreating(false);
    }
  };

  const dataType = [
    { id: 'screen', arr: filteredScreens },
    { id: 'print', arr: filteredQueue },
    { id: 'other', arr: filteredButtonActions },
  ].find((item) => item.id === type).arr;

  const checkNameToDisplay = () => {
    switch (type) {
      case 'screen':
        return 'headTitle';
      case 'print':
        return 'fullname';
      case 'other':
        return 'name';
      default:
        return '';
    }
  };

  return (
    <>
      <Row gutter={[0, 5]}>
        <Col span={24}>
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <Input placeholder="Поиск" value={searchValue} onChange={handleInput} />
            </Col>
            {type !== 'other' && (
            <Col>
              <Button type="primary" block onClick={() => toggleDrawer('open')}>Добавить и привязать</Button>
            </Col>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Row
            gutter={[10, 10]}
            style={{
              maxHeight: '400px',
              overflowY: 'auto',
              padding: '7px 0 7px 0',
              width: '100%',
            }}
          >
            {dataType.length > 0 ? dataType.map((item) => (
              <Col span={12} key={item[currentId]}>
                <Card
                  hoverable
                  onClick={() => handleCardClick(item)}
                  className={currentPath === item[currentId] ? 'card-selected' : ''}
                >
                  <Meta title={item[checkNameToDisplay()]} />
                </Card>
              </Col>
            )) : (
              <Col span={24}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Ничего не найдено" />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Drawer
        title={`Добавление и привязка ${type === 'screen' ? 'экрана' : 'очереди'}`}
        open={isPathCreating}
        onClose={() => setIsPathCreating(false)}
        destroyOnClose
      >
        {type === 'screen' ? (
          <ScreenForm
            type="link"
            toggleDrawer={toggleDrawer}
            button={button}
            screen={screen}
            setUpdatedButton={setFormData}
          />
        ) : (
          <QueueForm
            screen={screen}
            button={button}
            toggleDrawer={toggleDrawer}
            setUpdatedButton={setFormData}
          />
        )}
      </Drawer>
    </>
  );
}

export default ButtonAction;
