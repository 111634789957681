import React from 'react';
import { BoxButtonContent, ButtonContent } from '../../../../../utils/style';

function PoliceButton({
  el, style, handeleClick, name,
}) {
  return (
    <button
      type="button"
      onClick={() => handeleClick(name)}
      onDragStart={() => false}
      to={el.link}
      key={el.link}
      style={style}
    >
      <ButtonContent>
        <BoxButtonContent>
          <p style={{ ...style.text, textAlign: style.textAlign }}>{el.text}</p>
        </BoxButtonContent>
      </ButtonContent>
    </button>
  );
}

export default PoliceButton;
