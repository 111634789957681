import React, {
  useState, useEffect,
} from 'react';
import 'antd/dist/antd.min.css';
import defaultFilters from '../../../components/ReportFilters/utils/defaultFilters';
import TableConfig from './TableConfig';
import PrepareTable from '../../../components/PrepareTable';
import ReportFilters from '../../../components/ReportFilters/ReportFilters';

function OperatorLogi(props) {
  const [queryParams, setQueryParams] = useState();
  const { preloader } = props;

  const preparQueryParams = async (filters) => {
    const columns = TableConfig(); // Колонки для таблиц
    const params = {
      columns: columns.columns,
      filters: filters || defaultFilters(),
      url: '/api/reports/build',
      type: 'operatorLogi',
    };
    setQueryParams(params);
  };

  useEffect(() => {
    preparQueryParams();
  }, []);

  return (queryParams ? (
    <>
      <ReportFilters
        showFilters={{
          main: ['reportRange', 'operators', 'workplaces', 'queues'],
          others: ['tiketParamsFilters'],
          orders: ['created_on'],
        }}
        showTicketId
        showStatus={false}
        showMark={false}
        showPrior={false}
        showAction
        paramsCallback={preparQueryParams}
        type="operatorLogi"
      />
      <PrepareTable
        keys="key"
        type="OperatorTable"
        {...queryParams}
        preloader={preloader}
      />
    </>
  ) : null);
}

export default OperatorLogi;
