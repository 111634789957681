import React, { useState, useEffect, useCallback } from 'react';
import request from '../../../../../../logic/utils/request';
import DayTable from './WeekTable';
import { useToggle } from '../../../../../../logic/hooks/useToggle';
import NewWeek from './NewWeek/NewWeek';

function Weeks({ preloader, showError, write }) {
  const initialData = {
    description: '',
    name: '',
    friday_id: 0,
    monday_id: 0,
    saturday_id: 0,
    sunday_id: 0,
    thursday_id: 0,
    tuesday_id: 0,
    wednesday_id: 0,
  };
  const [dayList, setDayList] = useState({ data: [], isFetching: false });
  const [weekList, setWeekList] = useState({ data: [] });
  const [weekData, setWeekData] = useState(initialData);
  const [weekUpdate, setWeekUpdate] = useState(false);
  const [isOpen, toggleOpen] = useToggle(false);

  const fetchData = useCallback(async () => {
    setDayList({ data: [] });
    preloader();
    try {
      const res = await request('/server/api/dworkweek/', 'get');
      const days = await request('/server/api/dworkday/', 'get');
      setDayList({ data: days.data.data, isFetching: true });
      setWeekList({ data: res.data.data });
      preloader();
    } catch (e) {
      setDayList({ data: [], isFetching: false });
      showError('Ошибка получения дня или недели');
    }
  }, [preloader, showError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <NewWeek
        weekData={weekData}
        setWeekData={setWeekData}
        fetchData={fetchData}
        toggleOpen={toggleOpen}
        dayList={dayList}
        isOpen={isOpen}
        write={write}
        setWeekUpdate={setWeekUpdate}
        weekUpdate={weekUpdate}
        initialData={initialData}
        preloader={preloader}
      />
      <DayTable
        dayList={dayList}
        weekList={weekList}
        fetchDay={fetchData}
        showError={showError}
        setWeekUpdate={setWeekUpdate}
        setWeekData={setWeekData}
        toggleOpen={toggleOpen}
        write={write}
        preloader={preloader}
      />
    </div>
  );
}

export default Weeks;
