import React, { useContext } from 'react';
import { Space } from 'antd';
import Layouts from './Layouts';
import UploadImages from '../../BoardStyle2/BoardEditor/UploadImages';
import Context from '../../../../../../../pages/public/terminal/context';
import TerminalElements from './Editor/TerminalElements';
import StyleSettings from './Editor/Elements/StyleSettings';

function TerminalEditor() {
  const { data, dispatch } = useContext(Context);

  const {
    header, footer, head, logoComp,
  } = data;
  const items = [
    { name: 'Фоновое изображение', value: 'head.backgroundImage', img: head.backgroundImage },
    { name: 'Фоновое изображение шапки', value: 'header.backgroundImage', img: header.backgroundImage },
    { name: 'Фоновое изображение подвала', value: 'footer.backgroundImage', img: footer.backgroundImage },
    { name: 'Логотип компании', value: 'logoComp.backgroundImage', img: logoComp.backgroundImage },
  ];
  return (
    <Space direction="vertical" size="large">
      <Layouts />
      <UploadImages dispatch={dispatch} items={items} />
      <TerminalElements />
      <StyleSettings />
    </Space>
  );
}

export default TerminalEditor;
