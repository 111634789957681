import { Button, Input, Table } from 'antd';
import { SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const TableConfig = (
  getColumnSearchProps,
  edditRow,
  edditableRow,
  edditCansel,
  saveQuestionText,
  setNewQuestionText,
  newQuestionText,
  deleteQuestion,
  edditPosition,
) => {
  const columns = [
    {
      title: '№',
      dataIndex: 'position',
      width: 30,
      render: (cell) => cell + 1,
    },
    {
      dataIndex: 'questiontext',
      title: 'Вопрос',
      ...getColumnSearchProps('questiontext', 'Вопрос'),
      render: (cell, row) => {
        if (edditableRow === row.id) {
          return (
            <TextArea
              defaultValue={cell}
              onChange={(e) => setNewQuestionText(e.target.value)}
              size="large"
            />
          );
        }
        return cell;
      },
    },
    Table.EXPAND_COLUMN,
    {
      dataIndex: '',
      title: 'Действия',
      width: 270,
      render: (_, row) => (
        edditableRow && edditableRow === row.id
          ? (
            <>
              <Button
                onClick={() => saveQuestionText()}
                size="large"
                disabled={edditPosition}
              >
                Сохранить
              </Button>
              <Button
                onClick={() => edditCansel()}
                size="large"
                disabled={edditPosition}
                style={{ marginLeft: '8px' }}
              >
                Сбросить
              </Button>
            </>
          )
          : (
            <>
              <Button
                onClick={() => edditRow(row)}
                size="large"
                disabled={edditPosition}
              >
                Редактировать
              </Button>
              <Button
                onClick={() => deleteQuestion(row.id)}
                size="large"
                style={{ marginLeft: '8px' }}
                danger
                disabled={edditPosition}
              >
                Удалить
              </Button>
            </>
          )
      ),
    },
  ];

  if (edditPosition) {
    columns.unshift({
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    });
  }

  return ({ columns });
};

export default TableConfig;
