import React from 'react';
import QueueTable from './QueueReport/QueueTable';
import WorkPlaceTable from './WorkPlaceReport/WorkPlaceTable';
import OperatorTable from './OperatorReport/OperatorTable';
import TicketTable from './TicketReport/TicketTable';
import MarkTable from './MarkReport/MarkTable';
import GeneralReport from './GeneralReport/GeneralReport';
import LineReport from './LineReport/LineReport';
import ClockwiseTable from './ClockwiseReport/ClockwiseTable';
import IntervalReport from './IntervalReport/IntervalReport';
import DynamicsServiceTime from './DynamicsServiceTime/DynamicsServiceTime';
import GroupReport from './GroupReport/GroupReport';
import OperatorLogi from './OperatorLogi/OperatorLogi';
import SurveyReport from './SurveyReport/SurveyReport';
import PreEntryReport from './PreEntryReport/PreEntryReport';
import SourceReport from './SourceReport/SourceReport';
import SolvedQuestionReport from './SolvedQuestionReport/SolvedQuestionReport';
import filterStore from '../../../../../../../logic/store/pullstate/filterStore';
import TimeСonsumption from './Charts/TimeСonsumption';
import TicketClosePieChart from './Charts/TicketClosePieChart';

function ReportBuilder(props) {
  const {
    preloader, view, loader, setLoader, setView,
  } = props;

  const reportConfig = filterStore.useState((s) => s);
  const { reportType } = reportConfig;

  const reportObj = {
    lineReport: <LineReport />,
    clockwiseReport: <ClockwiseTable preloader={preloader} />,
    groupReport: <GroupReport preloader={preloader} />,
    ticketReport: <TicketTable preloader={preloader} />,
    operatorReport: <OperatorTable preloader={preloader} />,
    queueReport: <QueueTable preloader={preloader} />,
    workPlaceReport: <WorkPlaceTable preloader={preloader} />,
    markReport: <MarkTable preloader={preloader} />,
    intervalReport: <IntervalReport preloader={preloader} />,
    dynamicsServiceTime: <DynamicsServiceTime preloader={preloader} />,
    operatorLogi: <OperatorLogi preloader={preloader} />,
    surveyReport: <SurveyReport preloader={preloader} />,
    preEntryReport: <PreEntryReport preloader={preloader} />,
    sourceReport: <SourceReport preloader={preloader} />,
    solvedQuestionReport: <SolvedQuestionReport preloader={preloader} />,
    generalReport: <GeneralReport
      preloader={preloader}
      view={view}
      loader={loader}
      setLoader={setLoader}
      setView={setView}
    />,

    timeСonsumption: <TimeСonsumption />,
    ticketClosePieChart: <TicketClosePieChart />,
  };
  return reportObj[reportType];
}

export default ReportBuilder;
