import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/ru';
import { isAfter } from 'date-fns';
import { DatePicker } from 'antd';
import {
  FilterBox, FilterBoxTitle, FilterItemBox,
} from '../style';
import filterStore, { updateReportStore } from '../../../../../../../../logic/store/pullstate/filterStore';

function ReportRange({ changeFilter }) {
  const reportConfig = filterStore.useState((s) => s);

  console.log('reportConfig === ', reportConfig);
  const handleDataChange = (range) => {
    const newStartDate = range ? range[0] : moment().startOf('month').toDate();
    let newEndDate = range ? range[1] : moment().toDate();

    newEndDate = isAfter(newStartDate, newEndDate) ? newStartDate : newEndDate;

    const time = moment(newStartDate);
    const endTime = moment(newEndDate);
    const dateFormat = 'DD.MM.YYYY HH:mm';

    updateReportStore({
      startDate: time.format(dateFormat),
      endDate: endTime.format(dateFormat),
    });
    changeFilter('startDate', time.format(dateFormat));
    changeFilter('endDate', endTime.format(dateFormat));
  };

  return (
    <FilterBox>
      <FilterBoxTitle>Период</FilterBoxTitle>
      <FilterItemBox range>
        <DatePicker.RangePicker
          format="DD.MM.YYYY HH:mm"
          style={{ height: '100%' }}
          onChange={handleDataChange}
          defaultValue={[moment(reportConfig.startDate, 'DD.MM.YYYY HH:mm'), moment(reportConfig.endDate, 'DD.MM.YYYY HH:mm')]}
          showTime
        />
      </FilterItemBox>
    </FilterBox>
  );
}

export default ReportRange;
