import React from 'react';
import FonSize from '../../../../../Components/modules/fontSize';
import Uppercase from '../../../../../Components/modules/uppercase';
import Margin from '../../../../../Components/modules/Margin';

function Font({ input, checkbox, dispatch }) {
  return (
    <div className="settings__styles__el">
      <div className="element_box">
        <Uppercase dispatch={dispatch} element="input" value={input.textTransform} nameParams="textTransform" title="Текст заглавными буквами </br> поля ввода" width />
        <Uppercase dispatch={dispatch} element="checkbox" value={checkbox.textTransform} nameParams="textTransform" title="Текст заглавными буквами </br> окна выбора" width />
        <FonSize dispatch={dispatch} element="input" value={input.fontSize} paramName="fontSize" rusName="Размер шрифта<br /> поля ввода" width />
        <FonSize dispatch={dispatch} element="checkbox" value={checkbox.fontSize} paramName="fontSize" rusName="Размер шрифта<br /> окна выбора" width />
        <Margin data={input} dispatch={dispatch} element="input" name="margin" title="Поле ввода" />
        <Margin data={checkbox} dispatch={dispatch} element="checkbox" name="margin" title="Окно выбора" />
      </div>
    </div>
  );
}

export default Font;
