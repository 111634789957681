import React from 'react';

function Header(props) {
  const { header, headerMain, headerSub } = props;
  const st = { backgroundSize: 'cover', backgroundRepeat: 'no-repeat' };

  return (
    <div
      className="layout__header_box"
      style={{
        ...header, st, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
      }}
    >
      <p
        style={{
          fontSize: headerMain.fontSize,
          color: headerMain.color,
          textTransform: headerMain.textTransform,
        }}
      >
        {headerMain.text}
      </p>
      <p
        style={{
          fontSize: headerSub.fontSize,
          color: headerSub.color,
          textTransform: headerSub.textTransform,
        }}
      >
        {headerSub.text}
      </p>
    </div>
  );
}

export default Header;
