import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../utils/api';
import Title from './views/Title';
import Constructor from './views/Constructor';
import zoneStore, { updateZoneStore } from './Store';
import { updateQueueStore } from './Store/queueStore';
import NewScheme from './views/Scheme/newScheme';

function CurrentZone() {
  const zoneId = Number(useParams().id);
  const [isLoading, setIsLoading] = useState(true);
  const screenList = zoneStore.useState(((s) => s?.config?.screens));

  async function fetchZone() {
    const { data: zoneData, status: zoneStatus } = await api.getCurrentZone(zoneId);
    const { data: queueData, status: queueStatus } = await api.getQueue();

    if (zoneStatus && queueStatus) {
      updateZoneStore({ ...zoneData });
      updateQueueStore({ queue: queueData });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchZone();
  }, []);

  return isLoading
    ? (
      <div>Загрузка...</div>)
    : (
      <>
        <Title />
        {screenList.length > 0 && <NewScheme />}
        <Constructor />
      </>
    );
}

export default CurrentZone;
