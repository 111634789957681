import React, { useState, useEffect } from 'react';
import request from '../../../../logic/utils/request';
import { Title, ContentBox, PreloaderBox } from '../../../../common/ui/Common';
import UsersTable from './UsersTable';
import { useToggle } from '../../../../logic/hooks/useToggle';
import NewUser from './NewUser/NewUser';

function Users({ preloader, showError, write }) {
  const initialData = {
    connectedRoles: [],
    hidden: false,
    blocked: false,
    fio: '',
    pwd: '',
    username: '',
    connectedQueues: [],
  };

  const [userList, setUserList] = useState(null);
  const [userData, setUserData] = useState(initialData);
  const [userUpdate, setUserUpdate] = useState(false);
  const [isOpen, toggleOpen] = useToggle(false);
  const [roles, setRoles] = useState([]);

  const fetchUsers = async () => {
    const res = await request('/server/api/user/', 'get');
    setUserList(res.data.data);
  };

  const fetchRoles = async () => {
    const roleList = await request('/server/api/role/', 'get');
    const role = window.localStorage.getItem('role').indexOf('eq_root') >= 0;
    let newRoles = [...roleList.data.data];
    if (!role) newRoles = roleList.data.data.filter((el) => el.name !== 'eq_root');

    setRoles([...newRoles]);
  };

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);

  return (
    <>
      <Title>Пользователи</Title>
      <ContentBox>
        <NewUser
          initialData={initialData}
          preloader={preloader}
          toggleOpen={toggleOpen}
          isOpen={isOpen}
          write={write}
          fetchUsers={fetchUsers}
          userData={userData}
          setUserData={setUserData}
          setUserUpdate={setUserUpdate}
          userUpdate={userUpdate}
          showError={showError}
          roles={roles}
        />
        <PreloaderBox>
          <UsersTable
            userList={userList}
            fetchUsers={fetchUsers}
            preloader={preloader}
            write={write}
            setUserUpdate={setUserUpdate}
            setUserData={setUserData}
            toggleOpen={toggleOpen}
            showError={showError}
            roles={roles}
          />
        </PreloaderBox>
      </ContentBox>
    </>
  );
}
export default Users;
