/* eslint-disable no-mixed-operators */
/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  Button, DatePicker, Select, Space,
} from 'antd';
import moment from 'moment/moment';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  FilterBox, FilterBoxTitle, FilterItemBox, ReportFiltersBox,
} from '../../../../components/ReportFilters/style';
import filterStore, { updateReportStore } from '../../../../../../../../../logic/store/pullstate/filterStore';
import request from '../../../../../../../../../logic/utils/request';
import DounhnutsColorMap from '../../../../utils/DounghnutsColorMap';

const { RangePicker } = DatePicker;

ChartJS.register(ChartDataLabels);
ChartJS.register(ArcElement, Tooltip, Legend);

const dateFormat = 'DD.MM.YYYY HH:mm';

function TicketClosePieChart() {
  const reportStore = filterStore.useState((s) => s);
  const { startDate: storeStartDate, endDate: storeEndDate } = reportStore;

  const [graphData, setGraphData] = useState();
  const [serviceData, setServiceData] = useState();

  const [serviceList, setServiceList] = useState([]);

  const [closeTypeSelect, setCloseTypeSelect] = useState('all');
  const token = window.localStorage.getItem('token');
  const fetchChartData = async (startDateInput, endDateInput, queuesInput, closeTypeInput) => {
    const res = await axios({
      method: 'post',
      url: '/api/chart/ticketclosepiechart/',
      data: {
        token,
        startDate: startDateInput,
        endDate: endDateInput,
        queues: queuesInput, // или all
        closeType: closeTypeInput, // возможно преедать 3 - закрытые или 4 - закрытые по неявке или all -
      },
    });
    setGraphData(res?.data.data.queues.sort((a, b) => b.totalcnt - a.totalcnt));
  };
  const fetchServices = async () => {
    const { data } = await request('/server/api/queue/', 'get');
    setServiceData(data.data);
  };

  useEffect(() => {
    fetchChartData(storeStartDate, storeEndDate, 'all', closeTypeSelect);
    fetchServices();
  }, []);

  const IncreaseLegendMargin = {
    beforeInit(chart) {
      // Get a reference to the original fit function
      const origFit = chart.legend.fit;
      chart.legend.fit = function fit() {
        origFit.bind(chart.legend)();
        // Change the height to any desired value
        this.height += 40;
      };
    },
  };

  const labels = graphData?.map((item) => item.fullname);

  const testArr = [];
  const qwew = [];
  if (labels) {
    labels.forEach((el) => {
      const result = [];
      let fe = [];
      if (el.split(' ').length > 5) {
        el.split(' ').forEach((word) => {
          if (fe.length < 5) {
            fe.push(word);
          } else {
            result.push(fe);
            fe = [];
            fe.push(word);
          }
        });
      } else {
        testArr.push(el);
      }
      if (fe.length > 0) result.push(fe);
      if (result.length > 0) testArr.push(result);
    });

    testArr.forEach((el) => {
      if (Array.isArray(el)) {
        const ss = [];
        el.forEach((arr) => {
          ss.push(arr.join(' '));
        });
        qwew.push(ss);
      } else {
        qwew.push(el);
      }
    });
  }

  const data = {
    labels: qwew,
    datasets: [
      {
        label: 'Количество талонов',
        data: graphData?.map((item) => item.totalcnt),
        backgroundColor: DounhnutsColorMap(graphData?.length),
        borderWidth: 1,
      },
    ],
  };

  const handleServiceListChange = (value) => {
    setServiceList(value);
  };
  const handleCloseTypeChange = (value) => {
    setCloseTypeSelect(value);
  };
  const handleFilter = () => {
    if (serviceList.length > 0) {
      fetchChartData(storeStartDate, storeEndDate, serviceList, closeTypeSelect);
    } else {
      fetchChartData(storeStartDate, storeEndDate, 'all', closeTypeSelect);
    }
  };
  const rangePickerChange = (value, dateString) => {
    updateReportStore({
      startDate: dateString[0],
      endDate: dateString[1],
    });
  };
  const handleSortChange = (value) => {
    if (value === 'asc') {
      setGraphData([...graphData.sort((a, b) => a.totalcnt - b.totalcnt)]);
    } else if (value === 'desc') {
      setGraphData([...graphData.sort((a, b) => b.totalcnt - a.totalcnt)]);
    }
  };

  return (
    <>
      <ReportFiltersBox>
        <div style={{ display: 'flex' }}>
          <FilterBox style={{ flexShrink: 0 }}>
            <FilterBoxTitle>Период</FilterBoxTitle>
            <FilterItemBox>
              <RangePicker
                showTime
                onChange={rangePickerChange}
                format={dateFormat}
                defaultValue={[moment(storeStartDate, dateFormat), moment(storeEndDate, dateFormat)]}
                style={{ width: '100%' }}
              />
            </FilterItemBox>
          </FilterBox>
          <FilterBox style={{ width: '100%' }}>
            <FilterBoxTitle>Услуги</FilterBoxTitle>
            <FilterItemBox>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Выберите услуги"
                onChange={handleServiceListChange}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={serviceData?.map((item) => ({
                  value: item.id,
                  label: item.fullname,
                }))}
              />
            </FilterItemBox>
          </FilterBox>
          <FilterBox style={{ width: '100%' }}>
            <FilterBoxTitle>Тип закрытия талона</FilterBoxTitle>
            <FilterItemBox>
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Выберите тип закрытия"
                onChange={handleCloseTypeChange}
                defaultValue="all"
              >
                <Select.Option key="all" value="all">Все</Select.Option>
                <Select.Option key="3" value="3">Закрытые</Select.Option>
                <Select.Option key="4" value="4">Закрытые по няевке</Select.Option>
              </Select>
            </FilterItemBox>
          </FilterBox>
          <FilterBox style={{ width: '100%' }}>
            <FilterBoxTitle>Сортировать по</FilterBoxTitle>
            <FilterItemBox>
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Выберите тип закрытия"
                onChange={handleSortChange}
                defaultValue="desc"
              >
                <Select.Option key="asc" value="asc">По возрастанию</Select.Option>
                <Select.Option key="desc" value="desc">По убыванию</Select.Option>
              </Select>
            </FilterItemBox>
          </FilterBox>
        </div>
        <Space style={{ margin: '15px auto 0 auto', display: 'block' }} size="small" direction="horizontal">
          <Button size="large" type="primary" onClick={handleFilter}>Применить</Button>
        </Space>

      </ReportFiltersBox>
      <ReportFiltersBox>
        {data.labels.length
          && (
          <div style={{ height: `${data.labels.length * 60 < 380 ? 380 : data.labels.length * 60}px` }}>
            <Bar
              data={data}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                indexAxis: 'y',
                plugins: {
                  maintainAspectRatio: false,
                  responsive: true,
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label(context) {
                        const hiddens = context.chart._hiddenIndices;
                        let total = 0;
                        const datapoints = context.dataset.data;
                        datapoints.forEach((val, i) => {
                          if (hiddens[i] !== undefined) {
                            if (!hiddens[i]) total += val;
                            else total += val;
                          } else total += val;
                        });
                        const percentage = `${(context.formattedValue / total * 100).toFixed(1)}%`;
                        const out = `Закрыто талонов: ${datapoints[context.dataIndex]} (${percentage})`;
                        return out;
                      },
                    },
                  },
                },
              }}
              plugins={[ChartDataLabels, IncreaseLegendMargin]}
            />
          </div>
          )}
      </ReportFiltersBox>
    </>
  );
}

export default TicketClosePieChart;
