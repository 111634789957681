import React, { useEffect, useState } from 'react';
import WebSocketClient from '../../board/utils/WebSocketClient';
import {
  limitFilter, wsHost, wsPort, debug, declOfNum,
} from '../utils';
import { useScreenProviderContext } from '../use/useScreenProviderContext';

function BoardScreen({
  styleConfig,
}) {
  const {
    changeActiveScreen: setCurrentScreen,
    currentBoardStatus,
    checkTicketStatus,
  } = useScreenProviderContext();

  const position = localStorage.getItem('position');
  const MyTicketNumber = localStorage.getItem('currentTicketNumber');
  const [ticketList, setTicketList] = useState(currentBoardStatus);
  const { button } = styleConfig;

  const changeBoardList = ({ number, wp_fname: wplace }) => {
    setTicketList((oldTicketList) => (
      [
        { tiketNumber: number, wplace },
        ...oldTicketList,
      ].filter(limitFilter(6))
    ));
  };

  const messageInit = async ({ id, event }) => {
    if (event === 'wСallProcTicket') {
      await checkTicketStatus();
      if (id === +localStorage.getItem('currentTicketId')) {
        setCurrentScreen('ticketProcess');
      } else {
        changeBoardList({ id, event });
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-new
    const boardWs = new WebSocketClient(
      wsHost,
      wsPort,
      !debug,
      messageInit,
    );
    return () => {
      if (boardWs) boardWs.disconnect();
    };
  }, []);

  const renderBoard = () => {
    // Если в списке меньше 5-и элементов, то дополняем его null
    const tList = ticketList;
    if (tList.length < 5) {
      for (let i = tList.length; i < 5; i += 1) {
        tList.push(null);
      }
    }
    return tList.map((el, index) => (
      <div
        key={el && el.tiketNumber}
        className="boardScreen__tabloRow"
        style={{
          background: el && index === 0 ? '#0076FF' : button.background,
        }}
      >
        <span style={{
          color: el && index === 0 ? '#ffffff' : button.color,
        }}
        >
          {el && el.tiketNumber}
        </span>
        <span style={{
          color: el && index === 0 ? '#ffffff' : button.color,
        }}
        >
          {el && el.wplace.split(',')[0]}
        </span>
      </div>
    ));
  };

  return (
    <div className="boardScreen">
      <p className="boardScreen__title">
        Ваш талон:
        {MyTicketNumber}
        <br />
        {' '}
        {+position === 0 ? 'Вы следующий' : `Перед вами: ${position} ${declOfNum(position, ['человек', 'человека', 'человек'])}`}
      </p>
      <div className="boardScreen__container">
        <div className="boardScreen__tablo">
          <div className="boardScreen__tabloHeader">
            <span>Талон</span>
            <span>Окно</span>
          </div>
          <div className="boardScreen__tabloBody">
            {renderBoard()}
          </div>
        </div>
      </div>
      {/* <div>Тут на всякий место для бегущей строки или чет впилим</div> */}
    </div>
  );
}

export default BoardScreen;
