import React, { useState, useEffect } from 'react';
import QueuesList from './QueuesList';
import ActiveTicket from './ActiveTicket';

function OperatorPanel(props) {
  const { wsClient } = props;
  const [currentTicket, setCurrentTicket] = useState(null);
  useEffect(() => {

  }, []);

  return (
    currentTicket !== null
      ? (
        <ActiveTicket
          wsClient={wsClient}
          currentTicket={currentTicket}
          setCurrentTicket={setCurrentTicket}
        />
      )
      : (
        <QueuesList
          wsClient={wsClient}
          singleButton={false}
          setCurrentTicket={setCurrentTicket}
        />
      )
  );
}
export default OperatorPanel;
