import Request from '../../../../../../../../../logic/utils/request';

export default async function SqlQuery(time, endTime) {
  const dataObjects = [];
  const query = `select count(id),
(sum(count_close) + sum(count_neyavka)) as cnt,
sum(count_close) as close_cnt,
sum(count_neyavka) as none_cnt,
sum(count_del) as del_cnt,
(sum(totalproc) / (sum(count_close) + sum(count_neyavka)) * interval '1 sec') as avg_proc_time,
(sum(totalwait) / (sum(count_close) + sum(count_neyavka)) * interval '1 sec') as avg_wait_time
from
(select distinct(ticket_id) as id,
case when status = 3 then 1 else 0 end as count_close,
case when status = 4 then 1 else 0 end as count_neyavka,
case when status = 9 then 1 else 0 end as count_del,
case when status = 9 then 0 else totalproc end as totalproc,
case when status = 9 then 0 else totalwait end as totalwait
from eq.ttickethistory where modified_on::timestamp >= '${time}' 
and modified_on::timestamp <= '${endTime}' and status in (3,4,9)) t`;

  const res = await Request('/server/api/genreportfromquery/', 'get', {
    querySql: `${query}`,
  });

  const answer = res.data.data;

  answer.forEach((v) => {
    dataObjects.push({ ...v });
  });

  return { dataObjects, query };
}
