class WebSocketClient {
  ipAddress = null;

  port = null;

  webSocket = null;

  isConnecting = false;

  isConnected = false;

  isWaiting = false;

  shouldReconnect = false;

  responseDeffers = {};

  requestId = 1;

  reconnectTimeout = 3000;

  constructor(ip, port, isSecure, onConnect = () => {}, onSocketError = () => {}) {
    this.ipAddress = ip;
    this.port = port;
    this.isSecure = isSecure;
    this.onConnect = onConnect;
    this.onSocketError = onSocketError;
    this.connect();
  }

  connect = () => {
    if (!this.ipAddress || this.ipAddress === 'NOT_SET') {
      return;
    }
    this.isConnecting = true;
    this.isWaiting = false;
    try {
      this.webSocket = new WebSocket(`${this.isSecure ? 'wss://' : 'ws://'}${this.ipAddress}:${this.port}/`);
      this.webSocket.onopen = this._onConnectionOpen;
      this.webSocket.onclose = this._onConnectionClose;
      this.webSocket.onmessage = this._onMessage;
      this.webSocket.onerror = this._onConnectionError;
      this.shouldReconnect = true;
    } catch (err) {
      this.disconnect();
    }
  };

  disconnect = () => {
    this.shouldReconnect = false;
    if (this.webSocket) {
      this.webSocket.close();
    }
    this.onSocketError();
  };

  _onConnectionOpen = () => {
    this.isConnecting = false;
    this.isConnected = true;
    this.onConnect();
  };

  _onConnectionError = () => {
    this.isConnecting = false;
    this.isConnected = false;
    this.onSocketError();
    if (this.shouldReconnect) {
      clearTimeout(this._timer);
      this._timer = setTimeout(() => {
        this.connect();
      }, this.reconnectTimeout);
    }
  };

  _onConnectionClose = () => {
    this.isConnecting = false;
    this.isConnected = false;
    this.onSocketError();
    if (this.shouldReconnect) {
      clearTimeout(this._timer);
      this._timer = setTimeout(() => {
        this.connect();
      }, this.reconnectTimeout);
    }
  };

  _onMessage = (msgText) => {
    let { data: msg } = msgText;
    if (!msg) return null;
    try {
      msg = JSON.parse(msg);
      const { msid } = msg;
      if (!msid) return null;
      if (msg.status === 'ok') this.resolveRequest(msid, msg);
      else this.rejectRequest(msid, msg.error);
      return null;
    } catch (e) {
      return true;
    }
  };

  sendMessage = (msg) => {
    const { command } = msg;
    const msid = command;
    this.requestId += 1;
    const reqId = `${msid}-${this.requestId}`;
    const sendData = msg;
    sendData.msid = reqId;
    return new Promise((resolve, reject) => {
      this.responseDeffers[reqId] = {
        resolve,
        reject,
      };
      if (this.webSocket.readyState === this.webSocket.OPEN) {
        this.webSocket.send(JSON.stringify(sendData));
      } else {
        reject(new Error('Websocket connection is closed'));
      }
    });
  };

  sendKeepAlive = () => {
    if (this.webSocket.readyState === this.webSocket.OPEN) {
      this.webSocket.send('keepalive');
    }
  };

  resolveRequest = (msid, message) => {
    this.responseDeffers[msid].resolve(message);
    delete this.responseDeffers[msid];
  };

  rejectRequest = (msid, message) => {
    this.responseDeffers[msid].reject(message);
  };
}

export default WebSocketClient;
