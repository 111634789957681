import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Button, Space } from 'antd';
import {
  ReportFiltersBox, ReportFiltersTitle,
} from './style';
import MainFilters from './MainFilters/MainFilters';
import DopFilters from './DopFilters/DopFilters';
import Orders from './Orders/Orders';
import request from '../../../../../../../logic/utils/request';
import filterStore from '../../../../../../../logic/store/pullstate/filterStore';

function ReportFilters({
  paramsCallback,
  showFilters,
  type,
  showStatus = true,
  showPin = false,
  showClientFio = false,
  showClientPhone = false,
  showClientMail = false,
  showAction = false,
  showPrior = true,
  showMark = true,
  showRegistred = false,
  showTicketId = false,
  hiddenZeroValues,
}) {
  const [filtersData, setFiltersData] = useState();
  const [groupingData, setGroupingData] = useState();

  const openNotify = (typeNotify, message) => {
    if (typeNotify === 'success') {
      toast.success(`${message}`, 1000);
    } else {
      toast.error(`${message}`, 1000);
    }
  };

  const exportReport = async () => {
    const filterParams = filterStore.getRawState();

    const filters = {
      main: {
        ...filterParams,
        limit: 'all',
        offset: 'all',
      },
    };
    try {
      const token = window.localStorage.getItem('token');
      const url = '/api/reports/build';
      const res = await axios({
        method: 'post',
        url,
        data: {
          token,
          filters: { ...filters },
          type,
          exports: 'true',
          hiddenZeroValues,
        },
      });
      const message = 'Отчет подготавливается для скачивания и появится в разделе "Архив"';
      if (res.data.answer.success) {
        openNotify('success', message);
      }
    } catch (err) {
      openNotify('error', err);
    }
  };

  const buildReport = () => {
    const filterParams = filterStore.getRawState();
    const filters = {
      main: {
        ...filterParams,
      },
    };

    paramsCallback(filters);
  };

  const fetchFiltersData = async () => {
    const res = await axios.get('/api/queueobjects/', 'get');
    const groupingRes = await request('/server/api/bunch/', 'get');
    setGroupingData(groupingRes.data.data);
    setFiltersData(res.data);
  };

  const changeFilter = (filter, value) => {
    if (value !== '') {
      filterStore.update((s) => { s[filter] = value; });
    }
  };

  const changeOrder = (order, value) => {
    filterStore.update((s) => { s[order] = value; });
  };

  useEffect(() => {
    fetchFiltersData();
  }, []);

  return (
    <ReportFiltersBox>
      <ReportFiltersTitle>Параметры отчета</ReportFiltersTitle>
      {filtersData ? (
        <Space direction="vertical" style={{ width: '100%' }}>
          <MainFilters
            changeFilter={changeFilter}
            showFilters={showFilters.main}
            filtersData={filtersData}
            groupingData={groupingData}
            type={type}
          />
          {showFilters.others.length > 0 ? (
            <DopFilters
              changeFilter={changeFilter}
              showFilters={showFilters.others}
              showPin={showPin}
              showClientFio={showClientFio}
              showClientPhone={showClientPhone}
              showClientMail={showClientMail}
              showPrior={showPrior}
              showMark={showMark}
              showStatus={showStatus}
              showAction={showAction}
              showRegistred={showRegistred}
              showTicketId={showTicketId}
            />
          ) : null}
          {showFilters.orders.length > 0 ? (
            <Orders
              changeFilter={changeOrder}
              showFilters={showFilters.orders}
            />
          ) : null}
          <Space style={{ marginTop: 15 }} size="small" direction="horizontal">
            <Button size="large" type="primary" onClick={buildReport}>Применить</Button>
            <Button size="large" type="primary" onClick={exportReport}>Экспортировать</Button>
          </Space>
        </Space>
      ) : (<div> Загрузка... </div>)}

    </ReportFiltersBox>
  );
}
export default ReportFilters;
