import request from '../../../../../../logic/utils/request';

async function getZones() {
  try {
    const res = await request('/server/api/zone/', 'get');
    return res.data.data;
  } catch (e) {
    return e;
  }
}

async function getCurrentZone(zoneId) {
  try {
    const res = await request(`/server/api/zone/${zoneId}`, 'get');
    return {
      status: true,
      data: res.data.data,
    };
  } catch (e) {
    const errMessage = e?.data?.error?.text?.rus || 'Что-то пошло не так :(';
    return {
      status: false,
      errMessage,
    };
  }
}

async function addZone(data) {
  try {
    await request('/server/api/zone/', 'add', { ...data });
    return { status: true };
  } catch (e) {
    let errMessage = e.data.error.text.rus;
    if (Number(e.data.error.code) === 300) {
      errMessage = 'Зона с таким именем уже существует';
    }
    return {
      status: false,
      errMessage,
    };
  }
}

async function updateZone(data) {
  const zoneId = data.id;
  delete data.id;
  try {
    await request(`/server/api/zone/${zoneId}/`, 'update', { ...data });
    return {
      status: true,
    };
  } catch (e) {
    const errMessage = e.data.error.text.rus;
    return {
      status: false,
      errMessage,
    };
  }
}

async function deleteZone(zoneId) {
  try {
    await request(`/server/api/zone/${zoneId}`, 'delete');
    return { status: true };
  } catch (e) {
    let errMessage = e.data.error.text.rus;
    if (Number(e.data.error.code) === 300) {
      errMessage = 'Зона с таким именем уже существует';
    }
    return {
      status: false,
      errMessage,
    };
  }
}

async function getQueue() {
  try {
    const res = await request('/server/api/queue/', 'get');
    return res.data;
  } catch (e) {
    return e;
  }
}

async function addQueue(data) {
  try {
    const res = await request('/server/api/queue/', 'add', { ...data });
    return {
      createdQueueId: res.data.data.newCreatedId,
      status: true,
    };
  } catch (e) {
    return {
      status: false,
      errMessage: e.data.error.text.rus,
    };
  }
}

async function getWorkWeeks() {
  try {
    const res = await request('/server/api/dworkweek/', 'get');
    return res.data;
  } catch (e) {
    return e;
  }
}

const api = {
  getZones,
  getCurrentZone,
  addZone,
  updateZone,
  deleteZone,
  getQueue,
  addQueue,
  getWorkWeeks,
};

export default api;
