import React, { useState } from 'react';
import { Button, Space } from 'antd';
import Modal from '../../../../../common/ui/Modal/Modal';
import request from '../../../../../logic/utils/request';
import RenderInputs from './RenderInputs';

function NewZone({
  isOpen, toggleOpen, fetchZones, zoneUpdate, setZoneData,
  zoneData, initialData, setZoneUpdate, preloader, showError, roles,
}) {
  const [isValid, setIsValid] = useState(false);

  const resetModal = () => {
    toggleOpen();
    setZoneUpdate(false);
    setZoneData(initialData);
    setIsValid(false);
  };

  const addZone = async () => {
    const data = {
      ...zoneData,
      config: {
        ...zoneData.config,
        params: zoneData.config.params.filter((el) => el.fid !== 0),
      },
    };
    if (isValid) {
      await request('/server/api/zone/', 'add', { ...data })
        .catch((err) => { showError(err.data.error); });
      fetchZones();
      resetModal();
    }
  };

  const updateZone = async () => {
    if (isValid) {
      const itemId = zoneData.id;
      // eslint-disable-next-line no-param-reassign
      delete zoneData.id;
      const data = {
        ...zoneData,
        config: {
          ...zoneData.config,
          params: zoneData.config.params.filter((el) => el.fid && el.fv.length > 0),
        },
      };
      await request(`/server/api/zone/${itemId}/`, 'update', { ...data });
      fetchZones();
      resetModal();
    }
  };

  return (
    <>
      <Button type="primary" size="large" onClick={toggleOpen}>Добавить</Button>
      <Modal title={zoneUpdate ? 'Редактирование зоны обслуживания' : 'Добавление зоны обслуживания'} isOpen={isOpen} toggleOpen={resetModal}>
        <Space style={{ width: '100%' }} direction="vertical" size="large">
          <RenderInputs
            zoneData={zoneData}
            setZoneData={setZoneData}
            setIsValid={setIsValid}
            preloader={preloader}
            zoneUpdate={zoneUpdate}
            roles={roles}
          />
          <Space style={{ justifyContent: 'center', width: '100%' }} size="small">
            <Button
              disabled={!isValid}
              onClick={zoneUpdate ? updateZone : addZone}
              size="large"
              type="primary"
            >
              Сохранить
            </Button>
            <Button size="large" onClick={resetModal}>Отменить</Button>
          </Space>
        </Space>
      </Modal>
    </>
  );
}

export default React.memo(NewZone);
