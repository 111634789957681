/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useContext } from 'react';
import BoardCtx from '../utils/BoardCtx';
import TimerHeader from './TimerHeader';

function Footer({ layout, text }) {
  const { setActiveEl } = useContext(BoardCtx);
  const { footer, clockString, header } = layout;
  const {
    fontSize1, order, color1, fontFamily, height,
  } = footer;

  const styleFooter = {
    ...footer,
    color: color1,
    fontSize: fontSize1,
    order: !order ? 1 : 2,
    margin: order === 0 ? '0.5% 0 0' : '0',
    minHeight: +height,
    maxHeight: +height,
  };

  // marquee text too long
  // delete marquee -> make custom scrolling area
  if (!header.runstring) {
    return (
      <div
        className="layout__footer_box"
        onClick={() => setActiveEl('footer')}
        style={styleFooter}
      >
        <marquee lassName="marquee">
          <span style={{ fontFamily, display: 'flex', alignItems: 'center' }}>
            {text.footer1}
            {clockString && (<TimerHeader inline styles={layout.footer} />)}
          </span>
        </marquee>
      </div>
    );
  }
  return null;
}

export default Footer;
