import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Upload from '../Upload';
import List from './list';
import { FontsBox } from '../../../../../common/ui/Common';

function BoardVideos({ showError, preloader }) {
  const [title, setTitle] = useState(undefined);
  const [videos, setVideos] = useState([]);

  const getVideos = useCallback(async () => {
    preloader();
    try {
      axios({
        method: 'get',
        url: '/api/videos',
      })
        .then((res) => setVideos(res.data));
      preloader();
    } catch (e) {
      preloader();
      showError(e.data.error);
    }
  }, [showError, preloader]);

  useEffect(() => {
    getVideos();
  }, [getVideos]);

  const uploadVideo = async (formData) => {
    preloader();
    const res = await axios({
      method: 'post',
      url: '/api/videos',
      data: formData,
    });

    const { data, data: { success } } = res;

    if (success) {
      preloader();
      setTitle('');
      getVideos();
      toast.success('Добавлено');
    } else {
      preloader();
      showError(data.error);
    }
  };

  const deleteVideo = async (id) => {
    preloader();
    try {
      await axios({
        method: 'delete',
        url: '/api/videos',
        data: {
          id,
        },
      });
      preloader();
      getVideos();
      toast.success('Удалено');
    } catch (e) {
      preloader();
      showError(e.data.text);
    }
  };
  const setShow = async (id) => {
    preloader();
    try {
      await axios({
        method: 'patch',
        url: '/api/videos',
        data: {
          id,
        },
      });
      preloader();
      getVideos();
      toast.success('Выполнено');
    } catch (e) {
      preloader();
      showError(e.data.text);
    }
  };

  return (
    <FontsBox>
      <Upload title={title} setTitle={setTitle} save={uploadVideo} isVideo showError={showError} fileExtension={['mp4']} type="video" />
      <List deleteVideo={deleteVideo} setShow={setShow} videos={videos} />
    </FontsBox>
  );
}

export default BoardVideos;
