import React, { useContext } from 'react';
import TimerHeader from './TimerHeader';
import BoardCtx from '../utils/BoardCtx';

function Header({ layout, text }) {
  const { setActiveEl } = useContext(BoardCtx);
  const {
    header, footer, logo, clockString,
  } = layout;
  const { logotype } = footer;
  const {
    background,
    order,
    fontSize1,
    color1,
    vertical,
    fontWeight1,
    height,
    color2,
    fontSize2,
    fontSize3,
    fontFamily,
    positionBottom,
    colorHeaderMain,
    colorHeaderSub,
    fontSizeHeaderMain,
    fontSizeHeaderSub,
  } = header;

  const headerBg = {
    background,
  };
  if (layout.images.headerBg) {
    headerBg.background = `center / contain no-repeat  url(${layout.images.headerBg})`;
  }

  const orderMargin = order ? '0 0 0 40px' : '0 40px 0 0';

  const styleHeaderText = {
    fontSize: fontSize1,
    color: color1,
    order: order ? 1 : 0,
    margin: layout.clock ? orderMargin : 0,
    fontFamily,
    fontWeight: fontWeight1,
    textAlign: order ? 'left' : 'right',
  };
  const styleHeaderMain = {
    fontSize: fontSizeHeaderMain,
    color: colorHeaderMain,
    fontFamily,
  };
  const styleHeaderSub = {
    fontSize: fontSizeHeaderSub,
    color: colorHeaderSub,
    fontFamily,
  };
  const styleHeader = {
    ...layout.header,
    ...headerBg,
    margin: vertical ? '0 0 10px 0' : '0',
    order: positionBottom ? 2 : 0,
    minHeight: height,
    maxHeight: height,
  };

  return (layout.header && (
  <div
    className="layout__header_box"
    style={styleHeader}
    onClick={() => setActiveEl('header')}
  >
    <div className="layout__header__logo" style={{ order: order ? 1 : 0, height: '100%' }}>
      {!logotype && <img src={layout.images.headerLogo} alt="" style={logo} />}
    </div>
    <div style={{
      display: 'flex', flex: '1 1', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
    }}
    >
      <p style={styleHeaderMain}>{text.headerMain}</p>
      <p style={styleHeaderSub}>{text.headerSub}</p>
    </div>

    {Boolean(header.runstring) && (
    <div
      className="marquee"
    >
      <span
        style={{
          ...footer, fontFamily, fontSize: footer.fontSize1, color: footer.color1,
        }}
      >
        {text.footer1}
        {clockString
          && (
            <TimerHeader
              withDots={layout.clockDot}
              styles={layout.footer}
              timeFZ={fontSize2}
              order={order}
              dateFZ={fontSize3}
              color={color2}
            />
          )}
      </span>
    </div>
    )}

    <div className="layout__header__timer">
      <p
        className="layout__header__text"
        style={styleHeaderText}
      >
        {text.header1}
      </p>
      {layout.clock
            && (
            <TimerHeader
              withDots={layout.clockDot}
              styles={layout.header}
              timeFZ={fontSize2}
              order={order}
              dateFZ={fontSize3}
              color={color2}
            />
            )}
    </div>
  </div>
  ));
}

export default Header;
