import React from 'react';
import {
  FilterBox, FilterBoxTitle, FilterItemBox, FilterLineBox,
} from '../style';
import AllFilter from '../utils/ServedFilters/AllFilter';

function MarkFilter({ changeFilter }) {
  return (
    <FilterBox>
      <FilterBoxTitle>Оценка</FilterBoxTitle>
      <FilterItemBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="markcnt"
            title="Всего"
          />
        </FilterLineBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="mark5"
            title="Отлично"
          />
        </FilterLineBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="mark4"
            title="Хорошо"
          />
        </FilterLineBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="mark3"
            title="Нормально"
            smallTitle="true"
          />
        </FilterLineBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="mark2"
            title="Плохо"
          />
        </FilterLineBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="mark1"
            title="Очeнь
          плохо"
          />
        </FilterLineBox>
      </FilterItemBox>
    </FilterBox>
  );
}
export default MarkFilter;
