import React, { useState } from 'react';
import { Table } from 'antd';
import Config from './TableConfig';
import DeleteWarning from '../../../../../../common/ui/Modal/DeleteWarning';
import { useToggle } from '../../../../../../logic/hooks/useToggle';
import DeleteItem from '../../../../../../pages/private/utils/deleteItem';
import { useSearchPropsFactory } from '../../../../../../pages/private/utils/useSearch';

function DayTable({
  dayList,
  setDayUpdate,
  setDayData,
  toggleOpen,
  preloader,
  fetchDay,
  intervalList,
  showError,
  write,
}) {
  const [dayId, setDayId] = useState(false);
  const [dayName, setDayName] = useState(false);
  const [showDelWarn, setShowDelWarn] = useToggle(false);

  const delDay = async () => {
    await DeleteItem({
      preloader, url: `/server/api/dworkday/${dayId}/`, fetchData: fetchDay, setShowWarn: setShowDelWarn, setId: setDayId, showError,
    });
  };

  const handleTableIcon = (e, row) => {
    const { dataset } = e.target;
    const {
      id, name, description, workIntervals,
    } = row;
    if (dataset.name === 'del') {
      setDayId(id);
      setDayName(name);
      setShowDelWarn();
    } else if (dataset.name === 'update') {
      setDayUpdate(true);
      setDayData({
        id, name, description, workIntervals,
      });
      toggleOpen();
    }
  };

  const Setting = Config(intervalList, write, useSearchPropsFactory());
  const { columns } = Setting;

  return (
    <>
      {!!dayList.data?.length
        && (
          <Table
            dataSource={dayList.data}
            columns={columns}
            onRow={(row) => ({
              onClick: (e) => { handleTableIcon(e, row); },
            })}
          />
        )}
      <DeleteWarning
        name={dayName}
        confirmDel={delDay}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </>
  );
}

export default React.memo(DayTable);
