import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  Table, Button, Input, Space,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import 'antd/dist/antd.min.css';
import { useNavigate } from 'react-router-dom';
import { EmptyList } from '../../../../common/ui/Common';
import Config from './TableConfig';
import DeleteWarning from '../../../../common/ui/Modal/DeleteWarning';
import { useToggle } from '../../../../logic/hooks/useToggle';
import DeleteItem from '../../../../pages/private/utils/deleteItem';

function DevicesTable({
  deviceList,
  deviceListLoaded,
  setDeviceUpdate,
  setDeviceData,
  toggleOpen, preloader, fetchDevices, showError, write,
}) {
  const [deviceId, setDeviceId] = useState(false);
  const [deviceName, setDeviceName] = useState(false);
  const [showDelWarn, setShowDelWarn] = useToggle(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const input = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex, searchItemText) => ({
    // eslint-disable-next-line react/no-unstable-nested-components
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={input}
          placeholder={`Найти ${searchItemText}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Очистить
          </Button>
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => input.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const delDevice = async () => {
    await DeleteItem({
      preloader, url: `/server/api/satellite/${deviceId}/`, fetchData: fetchDevices, setShowWarn: setShowDelWarn, setId: setDeviceId, showError,
    });
  };

  const sendConfig = (id, deviceData, refresh) => {
    const { origin } = window.location;
    const {
      type, ipaddr, port, uuid,
    } = deviceData;
    let urlPage = `${origin}/satellite/${type}/?id=${id}`;
    if (type === 'board' || type === 'terminal') {
      urlPage = `${origin}/${type}/${id}/`;
    }
    let sUri = `/proxySatellite/gate/window/${uuid}`;
    if (!uuid) {
      sUri = '/proxySatellite/gate/config'; // old satellite
    }
    try {
      axios({
        method: 'post',
        url: sUri,
        data: {
          urlPage,
          satelliteIp: ipaddr,
          satellitePort: port,
        },
      });
      if (refresh) {
        toast.success('Выполнено');
      }
    } catch (e) {
      showError(e.data.error);
    }
  };

  const handleTableIcon = (e, row) => {
    console.log('123123');
    const { dataset } = e.target;
    const {
      id, name, ipaddr, type, port, configured, zone, uuid,
    } = row;
    if (dataset.name === 'del') {
      setDeviceId(id);
      setDeviceName(name);
      setShowDelWarn();
    } else if (dataset.name === 'update') {
      setDeviceUpdate(true);
      setDeviceData({
        id, name, ipaddr, type, port, config: {}, configured, zoneId: zone ? zone.zoneId : '', uuid,
      });
      toggleOpen();
    } else if (dataset.name === 'styles') {
      navigate(`/devices/${id}/`);
    } else if (dataset.name === 'view') {
      window.open(`/${type}/${id}/`);
    } else if (dataset.name === 'refresh') {
      sendConfig(id, {
        type, ipaddr, port, uuid,
      }, true);
    }
  };
  const Setting = Config(write, getColumnSearchProps);
  const { columns } = Setting;

  return (
    <>
      { deviceListLoaded && (
        deviceList.length > 0 ? (
          <Table
            rowKey="id"
            keys={deviceList.forEach((item, index) => item[index])}
            dataSource={deviceList}
            columns={columns}
            onRow={(row) => ({
              onClick: (e) => { handleTableIcon(e, row); },
            })}
          />
        ) : <EmptyList>Внешних устройств нет</EmptyList>
      )}
      <DeleteWarning
        name={deviceName}
        confirmDel={delDevice}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </>
  );
}

export default React.memo(DevicesTable);
