import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import request from '../../../../../logic/utils/request';
import BoardStyle from './BoardStyle2/BoardStyle';
import TerminalStyle from './TerminalStyle2/TerminalStyle';
import '../../../../styles/board2/boardStyle.scss';

function DeviceStyle(props) {
  const { preloader, showError } = props;
  const [deviceData, setDeviceData] = useState({});
  const { id } = useParams();

  const fetchDevice = async () => {
    preloader();
    try {
      const res = await request(`/server/api/satellite/${id}/`, 'get');
      setDeviceData(res.data.data);
    } catch (e) {
      showError(e.data.error);
    }
    preloader();
  };

  const handleSave = async (config) => {
    let zoneId = '';
    if (deviceData.zone) {
      zoneId = deviceData.zone.zoneId;
    }
    delete deviceData.zone;
    const newDeviceData = { ...deviceData, zoneId, config: { ...deviceData.config, ...config } };
    preloader();
    try {
      await request(`/server/api/satellite/${id}`, 'update', newDeviceData);
      toast.success('Выполнено');
      fetchDevice();
    } catch (e) {
      showError(e.data.error);
    }
    preloader();
  };

  useEffect(() => {
    fetchDevice();
  }, []);

  const { type } = deviceData;

  return (
    <>
      {type === 'board' || type === 'board-lottery' ? <BoardStyle config={deviceData.config} handleSave={handleSave} showError={showError} /> : null}
      {(type === 'terminal') && <TerminalStyle deviceData={deviceData} id={id} handleSave={handleSave} />}
    </>
  );
}

export default DeviceStyle;
