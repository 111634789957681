import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import BackToRootReport from '../../../utils/BackToRootReport';
import SqlQuery from './SqlQuery';
import BasicSettings from '../GraphicReport/Graphic/BasicSettings';

function LineReport() {
  const [ReportParams, setReportParams] = useState([]);
  const { reportConfig } = useSelector((state) => state);

  const answerFnc = async () => {
    const lineData = await SqlQuery(reportConfig.activeRow);
    const settings = BasicSettings();
    const dataGraph = {};
    for (let i = 0; i < 7; i += 1) {
      dataGraph[i] = 0;
    }
    lineData.dataObjects.forEach((el) => {
      dataGraph[Number(el[1])] = Number(el[0]);
    });

    const ticketGraphicSettings = {
      labels: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
      datasets: [{
        ...settings,
        data: Object.values(dataGraph),
      }],
    };
    setReportParams(ticketGraphicSettings);
  };

  useEffect(() => {
    answerFnc();
  }, [reportConfig]);

  return (
    <>
      <BackToRootReport />
      <Line
        data={ReportParams}
        height={175}
        options={{
          maintainAspectRatio: false,
          intersect: true,
        }}
      />
    </>
  );
}

export default LineReport;
