import styled from 'styled-components';

export const SwitchButton = styled.div`
  padding: 10px 0;
  flex: 1;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  border: none;
  color: ${(props) => (props.activeStyle ? props.theme.buttonHover : props.theme.buttonText)};
  background-color: ${(props) => (props.activeStyle ? props.theme.accent : props.theme.buttonHover)} ;
  font-weight: 100;
  transform: none;
  outline: 0;
  transition: .1s;
  &:hover {
    color: ${(p) => p.theme.buttonHover};
    background-color: ${(p) => p.theme.accent};
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  border-radius: 3px;
  width: 90vw;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 30px;
  box-shadow: 0 1px 3px rgba(0,0,0,.1), 0 4px 10px rgba(0,0,0,.08);
  justify-content: space-between;
`;

export const ReportParametrsBox = styled.div`
  padding: 25px;
  margin-top: 25px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0,0,0,.1);
  background: #fff;
  margin-bottom: 50px;
`;

export const ReportParametrsTitle = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 15px;
  margin-bottom: 0;
  text-align: center;
  border-radius: 3px;
`;

export const DataPicersBox = styled.div`
  display: flex;
  
`;

export const DatePicersItem = styled.div`
  display: flex;
  align-items: baseline;
  margin-left: ${(p) => (p.left ? '25px' : 'none')}
`;

export const FilterBox = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: baseline;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  margin-left: 25px;
  width: 100%;
`;

export const FilterSelection = styled.div`
  margin-left: 8px;
  width: 100%;
  flex: 1;
`;
