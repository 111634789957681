export default {
  main: '#25547A',
  dmain: '#0b1117',
  lmain: 'rgba(209, 208, 241, 0.1)',
  green: '#a4c328',
  greenl: 'rgba(213, 255, 184, 0.2)',
  accent: '#607d8b',
  accentl: 'rgba(252, 76, 2, 0.2)',
  bg: '#fbfbfb',
  bgTable: '#fff',
  bgButton: '#fff',
  table2n: '#f5f7f3',
  lightText: '#454545',
  disable: '#cbcbcb',
  disableText: '#0d1a28',
  text: '#0d1a28',
  buttonAccent: '#607d8b',
  buttonHover: '#fff',
  buttonText: '#25547A',
  blue: '#81c4ca',
  red: '#ff1100',
};
