import { DubleLineTemplate } from '../../../utils/formater';

export default function TableConfig() {
  return {
    columns: [
      {
        title: 'Дата обслуживания',
        width: 270,
        fixed: true,
        render: (cell, row) => DubleLineTemplate(
          'time',
          'Начало:',
          row.create_data,
          'time',
          'Окончание:',
          row.close_data,
        ),
      },
      {
        title: '№ талона',
        fixed: true,
        width: 100,
        dataIndex: 'ticket_number',
      },
      {
        title: 'Оператор',
        fixed: true,
        width: 100,
        dataIndex: 'user_fio',
      },
      {
        title: 'Очередь',
        fixed: false,
        width: 250,
        dataIndex: 'queue_name',
      },
    ],
  };
}
