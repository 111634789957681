import React from 'react';
import {
  AccordionBox, AccordionTitle, AccordionContent, AccordionTitleText, AccordionTitleIcon,
} from './Accordion.style';
import { ReactComponent as RightArrow } from '../../../../../../../../common/images/right-arrow.svg';
import { useToggle } from '../../../../../../../../logic/hooks/useToggle';

function Accordion({ title, content, letOpen }) {
  const [isOpen, toggle] = useToggle(!!letOpen);

  return (
    <AccordionBox>
      <AccordionTitle onClick={() => toggle()}>
        <AccordionTitleText>
          {title}
        </AccordionTitleText>
        <AccordionTitleIcon isOpen={isOpen}>
          <RightArrow />
        </AccordionTitleIcon>
      </AccordionTitle>
      <AccordionContent isOpen={isOpen}>{content}</AccordionContent>
    </AccordionBox>
  );
}

export default Accordion;
