import React, { useState } from 'react';
import { Table } from 'antd';
import { EmptyList } from '../../../../../../common/ui/Common';
import Preloader from '../../../../components/preloader/Preloader';
import DeleteWarning from '../../../../../../common/ui/Modal/DeleteWarning';
import { useToggle } from '../../../../../../logic/hooks/useToggle';
import DeleteItem from '../../../../utils/deleteItem';
import { useSearchPropsFactory } from '../../../../utils/useSearch';
import { TableConfig } from './TableConfig';

function FieldsTable({
  fieldList, preloader, fetchFields, setFieldUpdate, setFieldData, toggleOpen,
  setBookUpdate, showError,
}) {
  const [showDelWarn, setShowDelWarn] = useToggle(false);
  const [fieldId, setFieldId] = useState(false);
  const [fieldName, setFieldName] = useState(false);
  const searchPropsFactory = useSearchPropsFactory();

  const delField = async () => {
    await DeleteItem({
      preloader, url: `/server/api/ticketsfields/${fieldId}/`, fetchData: fetchFields, setShowWarn: setShowDelWarn, setId: setFieldId, showError,
    });
  };

  const handleTableIcon = (e, row) => {
    const { dataset } = e.target;
    const {
      id, description, name, rus_name: rusName, type,
    } = row;
    if (dataset.name === 'del') {
      setFieldId(id);
      setFieldName(name);
      setShowDelWarn();
    } else if (dataset.name === 'update') {
      setFieldUpdate(true);
      if (type !== 'text') setBookUpdate(true);
      const dictId = type !== 'text' ? row.dict_id : null;
      setFieldData({
        dict_id: dictId, id, description, name, rus_name: rusName, type,
      });
      toggleOpen();
    }
  };

  const viewFieldList = () => (fieldList.length
    ? (
      <Table
        columns={TableConfig(searchPropsFactory)}
        onRow={(row) => ({
          onClick: (e) => { handleTableIcon(e, row); },
        })}
        dataSource={fieldList}
      />
    ) : <EmptyList>Полей нет</EmptyList>);

  return (
    <>
      {!fieldList ? <Preloader isOpen small /> : viewFieldList()}
      <DeleteWarning
        name={fieldName}
        confirmDel={delField}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </>
  );
}

export default React.memo(FieldsTable);
