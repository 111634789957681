import React from 'react';
import DraggableItem from './components/DraggableItem';
import { TicketConstructorBox } from '../style';

export default function ConstructorDraggable(props) {
  const { ticket } = props;
  const a = window.innerWidth;
  const b = a / 500;
  let scale;
  if (b > 2.6) {
    scale = 0.9;
  } else if (b > 2.45) {
    scale = 0.8;
  } else if (b > 2.2) {
    scale = 0.7;
  } else {
    scale = 0.6;
  }

  let right;
  let top;
  if (window.innerWidth < 1200) {
    right = '-8%';
    top = '5%';
  } else {
    right = '5%';
    top = '-5%';
  }
  return (
    <TicketConstructorBox scale={scale} right={right} top={top}>
      {Object.keys(ticket || {}).map((elem) => <DraggableItem {...props} key={elem} elem={elem} />)}
    </TicketConstructorBox>
  );
}
