const style = {};

export default function styleReducer(state = style, action) {
  const { type, value } = action;
  switch (type) {
    case 'SET-STYLE': {
      return {
        ...state,
        ...value,
      };
    }
    default:
      return state;
  }
}
