import React from 'react';
import {
  Input, DatePicker, Space, Button,
} from 'antd';

function Upload({
  setData, data, valid, onSave,
}) {
  const { date, title, text } = data;

  const handleDataChange = ({ sDate }) => {
    setData({ ...data, date: { ...data.date, value: sDate } });
  };

  return (
    <form enctype="multipart/form-data" arr>
      <Space size="middle" style={{ width: 400, marginRight: 15 }} direction="vertical">
        <Input
          placeholder={title.title}
          value={title.value}
          type="text"
          onChange={(e) => setData({ ...data, title: { ...data.title, value: e.target.value } })}
        />

        <Input.TextArea
          placeholder={text.title}
          value={text.value}
          onChange={(e) => setData({
            ...data,
            text: {
              ...data.text,
              value: e.target.value,
            },
          })}
        />
        <DatePicker
          placeholder={date.title}
          value={date.value}
          onChange={(sDate) => handleDataChange({ sDate })}
        />
        {valid && <Button type="primary" onClick={onSave}>Сохранить</Button>}
      </Space>
    </form>
  );
}

export default Upload;
