import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import c from '../../common/ui/theme/Colors';
import { ReactComponent as Edit } from '../../common/images/edit.svg';
import { ReactComponent as False } from '../../common/images/false.svg';
import { ReactComponent as True } from '../../common/images/true.svg';

const dateFormat = (cell) => moment(cell, 'YYYY.MM.DD HH:mm:ss').format('DD.MM.YYYY HH:mm:ss');

function placeholder(column, colIndex, { filterElement }) {
  return (
    <div className="filterBoxTable">
      { filterElement }
    </div>
  );
}

function sortLabelFormatter(column, colIndex, { filterElement }) {
  return (
    <div className="filterBoxTable">
      {column.dataField !== 'Settings' && <Edit style={{ margin: '2px 10px 0 0' }} fill={c.main} /> }
      { filterElement }
    </div>
  );
}

function checkFormatter(value) {
  return (value.toString() === 'true'
    ? <Button type="primary" shape="circle" icon={<Icon component={True} />} />
    : <Button type="primary" shape="circle" icon={<Icon component={False} />} />
  );
}

export {
  dateFormat, placeholder, checkFormatter, sortLabelFormatter,
};
