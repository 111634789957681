import React, { useContext } from 'react';
import { Collapse } from 'antd'; // DOCS: https://ant.design/components/collapse/
import BoardCtx from '../../../../../../../../../pages/public/board/utils/BoardCtx';
import colors from './color';
import FontFamily from '../../../../Components/modules/font';
import FontSize from '../../../../Components/modules/fontSize';
import TextComponent from '../../../../Components/modules/text';
import Height from '../../../../Components/modules/height';
import Colors from '../../../../Components/Colors';
import MarginComponent from '../../../../Components/modules/Margin';

function Ticket() {
  const {
    dispatch, layout, fonts, text, dispatchText,
  } = useContext(BoardCtx);
  const { Panel } = Collapse;
  const { footer, clockString } = layout;
  const arrColors = colors({ footer });
  if (!clockString) arrColors.splice(2, 1);
  return (
    <Collapse className="customize-Collapse" ghost>
      <Panel className="customize-Collapse-TextBox" header="Текст" key="1">
        <TextComponent element="text" param="footer1" value={text.footer1} dispatch={dispatchText} />
      </Panel>

      <Panel header="Параметры текста" key="2">
        <FontFamily title="Шрифт" options={fonts} element="footer" value={String(footer.fontFamily)} dispatch={dispatch} />
        <FontSize rusName="Размер шрифта" element="footer" paramName="fontSize1" value={footer.fontSize1} dispatch={dispatch} />
        {clockString && <FontSize rusName="Размер шрифта<br/> время" element="footer" paramName="fontSize2" value={footer.fontSize2} dispatch={dispatch} />}
        {clockString && <FontSize rusName="Размер шрифта<br/> даты" element="footer" paramName="fontSize3" value={footer.fontSize3} dispatch={dispatch} />}
        <Height title="Высота строки" width="160px" element="footer" nameParams="height" value={footer.height} dispatch={dispatch} />
        <MarginComponent data={footer} dispatch={dispatch} element="footer" name="margin" title="Отступы" />
      </Panel>

      <Panel className="customize-Collapse-Colors" header="Цвет" key="3">
        <Colors arr={arrColors} dispatch={dispatch} />
      </Panel>
    </Collapse>
  );
}

export default Ticket;
