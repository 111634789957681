import React from 'react';
import {
  ColumnButton,
  ColumnButtonNotActive,
  LineDescription,
  ReportLine,
  SomeLineColumn,
} from '../../../../../components/report/ReportTableColumns';

export function withButtonsTamplate(
  row,
  moreInfo,
  tableName,
  firstItemParam,
  secondItemParam,
  thirdItemParam,
) {
  const firstItem = +firstItemParam || 0;
  const secondItem = +secondItemParam || 0;
  const thirdItem = +thirdItemParam || 0;

  return (
    <SomeLineColumn>
      <ReportLine key={11}>
        <LineDescription>Всего</LineDescription>
        {firstItem === 0
          ? (
            <ColumnButtonNotActive
              disabled={firstItem === 0}
            >
              {firstItem}
            </ColumnButtonNotActive>
          )
          : (
            <ColumnButton
              key={21}
              disabled={firstItem === 0}
              notActive={firstItem === 0}
            >
              {firstItem}
            </ColumnButton>
          )}
      </ReportLine>
      <ReportLine key={12}>
        <LineDescription>Закрыто</LineDescription>
        {secondItem === 0
          ? (
            <ColumnButtonNotActive
              disabled={secondItem === 0}
            >
              {secondItem}
            </ColumnButtonNotActive>
          )
          : (
            <ColumnButton
              key={22}
              disabled={secondItem === 0}
              notActive={secondItem === 0}
              // onClick={() => moreInfo(row, 'closed', tableName)}
            >
              {secondItem}
            </ColumnButton>
          )}
      </ReportLine>
      <ReportLine key={13}>
        <LineDescription>Закрыто по неявке</LineDescription>
        {thirdItem === 0
          ? (
            <ColumnButtonNotActive
              disabled={thirdItem === 0}
            >
              {thirdItem}
            </ColumnButtonNotActive>
          )
          : (
            <ColumnButton
              key={23}
              disabled={thirdItem === 0}
              notActive={thirdItem === 0}
              // onClick={() => moreInfo(row, 'nonAppearance', tableName)}
            >
              {thirdItem}
            </ColumnButton>
          )}
      </ReportLine>
    </SomeLineColumn>
  );
}

export default null;
