import React from 'react';
import Align from './modules/align';

const Aligns = ({ arr, dispatch }) => (
  arr.map((el) => (
    <Align
      element={el.parentName}
      value={el.value}
      dispatch={dispatch}
      title={el.title}
      paramName={el.paramName}
      key={el.title}
    />
  ))
);

export default Aligns;
