import { MenuOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber } from 'antd';
import { SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const TableConfig = (
  getColumnSearchProps,
  edditRow,
  edditableRow,
  edditCansel,
  edditAnswer,
  deleteAnswer,
) => {
  let newAnswerText = '';
  let newAnswerWigth = '';

  const handleSave = (row) => {
    const newText = newAnswerText.length > 0 ? newAnswerText : row.answertxt;
    const newWeight = newAnswerWigth !== '' ? Number(newAnswerWigth) : Number(row.weight);
    edditAnswer(newText, newWeight, row.id);
  };

  return {
    columns: [
      {
        title: '',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
      },
      {
        title: '№',
        dataIndex: 'position',
        width: 30,
        render: (cell) => cell + 1,
      },
      {
        dataIndex: 'answertxt',
        title: 'Текст ответа',
        key: 'answertxt',
        sorter: (a, b) => (a.id - b.id),
        className: 'drag-visible',
        ...getColumnSearchProps('answertxt', 'Ответ'),
        render: (cell, row) => {
          if (edditableRow === row.id) {
            return (
              <Input
                defaultValue={cell}
                onChange={(e) => {
                  newAnswerText = e.target.value;
                }}
                size="large"
              />
            );
          }
          return cell;
        },
      },
      {
        dataIndex: 'weight',
        title: 'Числовой эквивалент',
        ...getColumnSearchProps('answertxt', 'Ответ'),
        width: 120,
        sorter: (a, b) => (a.id - b.id),
        render: (cell, row) => {
          if (edditableRow === row.id) {
            return (
              <InputNumber
                defaultValue={cell}
                min={0}
                max={10}
                onChange={(e) => {
                  newAnswerWigth = e;
                }}
                size="large"
              />
            );
          }
          return cell;
        },
      },
      {
        dataIndex: '',
        title: 'Действия',
        width: 270,
        render: (_, row) => (
          edditableRow && edditableRow === row.id
            ? (
              <>
                <Button onClick={() => handleSave(row)} size="large">Сохранить</Button>
                <Button onClick={() => edditCansel()} size="large" style={{ marginLeft: '8px' }}>Сбросить</Button>
              </>
            )
            : (
              <>
                <Button onClick={() => edditRow(row)} size="large">Редактировать</Button>
                <Button
                  onClick={() => deleteAnswer(row.id)}
                  size="large"
                  style={{ marginLeft: '8px' }}
                  danger
                >
                  Удалить
                </Button>
              </>
            )
        ),
      },

    ],
  };
};

export default TableConfig;
