// eslint-disable
import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  Button, Col, Input, Row, Space, Table, Typography,
} from 'antd';
import Icon from '@ant-design/icons';
import ImportExportConfig from '../../../../utils/importExportConfig';
import ModalSettings from './components/Modal';
import ConstrDragg from './ConstructorDraggable';
import Request from '../../../../../logic/utils/request';
import { TableFontsConfig } from './TableFontsConfig';
import { TableImagesConfig } from './TableImagesConfig';

function ConstructorSettings({
  name, setName, ticket, data, setTicket, idSat,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [acName, setAcName] = useState('');
  const [fonts, setFonts] = useState([]);

  let url;

  const changeImage = (value, type) => {
    const newData = { ...ticket };
    newData[type].url = value;
    setTicket(newData);
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: '/api/fonts',
    }).then((res) => setFonts(res.data));
  }, []);

  const changePosition = (val, type) => {
    let value = +val;
    let limitParam;
    switch (type) {
      case 'code':
        limitParam = ticket.code.height;
        break;
      case 'date':
        limitParam = ticket.date.fontSize;
        break;
      case 'extraText':
        limitParam = ticket.extraText.fontSize;
        break;
      case 'logotype':
        limitParam = ticket.logotype.height;
        break;
      case 'number':
        limitParam = ticket.number.fontSize;
        break;
      case 'window':
        limitParam = ticket.window.fontSize;
        break;
      default:
        limitParam = null;
    }
    if (type.includes('Дополнительный текст')) {
      limitParam = ticket[`${type}`].fontSize;
    }
    const limit = 500 - limitParam;
    if (+val > limit) {
      value = limit;
      toast.error(`Значение должно быть в пределах от 0 до ${limit}`, 1000);
    }
    if (+val < 0) value = 0;
    return value;
  };

  const change = (type, value, paramName) => {
    const newData = { ...ticket };
    if (paramName === 'y') {
      value = changePosition(value, type);
    }
    newData[type][paramName] = value;
    if (paramName === 'text') {
      newData[type].textInput = value;
    }
    setTicket(newData);
  };

  const addField = () => {
    const field = Object.keys(ticket);
    const obj = ticket[field[field.length - 1]]?.name || 'Дополнительный текст';
    let nameField;
    if (obj.indexOf('Дополнительный текст') >= 0) {
      // eslint-disable-next-line prefer-destructuring
      nameField = obj.split(' ')[2];
      if (nameField) {
        nameField = `Дополнительный текст ${+nameField + 1}`;
      } else {
        nameField = 'Дополнительный текст 1';
      }
    } else {
      nameField = 'Дополнительный текст';
    }
    const item = {
      x: 0, y: 0, fontSize: 18, fontFamily: 'Roboto', align: '', text: 'Текст', name: nameField, show: true, textInput: 'Текст',
    };
    const newTicket = { ...ticket };
    newTicket[nameField] = item;
    setTicket(newTicket);
  };

  const removeField = (item) => {
    const newTicket = { ...ticket };
    delete newTicket[item];
    setTicket(newTicket);
  };

  const openModal = (nameModal) => {
    setOpen(!open);
    setAcName(nameModal);
  };

  const toggle = () => setOpen(!open);

  const addInputField = (_, fvid) => {
    const newTicket = { ...ticket };
    const re = Number.isNaN(+fvid) ? ` $${fvid}$` : ` $FIELD${fvid}$`;
    const text = ticket[acName].textInput;
    if (text.indexOf(re) >= 0) {
      newTicket[acName].textInput = ticket[acName].textInput.replace(re, '');
    } else {
      newTicket[acName].textInput = `${ticket[acName].textInput}${re}`;
    }
    setTicket(newTicket);
  };

  const scrollPosition = (obj) => {
    if (ticket[obj.name].align && obj.param === 'x') return;
    change(obj.name, changePosition(+obj.value), obj.param);
  };

  const sendServer = () => {
    if (!name) return;
    const dataSend = {};
    dataSend.config = ticket;
    dataSend.connected_queues = [];
    dataSend.name = name;
    const path = idSat ? `/server/api/tickettemplate/${idSat || ''}` : `/server/api/tickettemplate/${idSat}`;
    Request(path, idSat ? 'update' : 'add', dataSend)
      .then(() => {
        navigate(-1);
        toast.success('Выполнено', 1000);
      });
  };

  if (ticket) {
    const blobStyle = new Blob([JSON.stringify(ticket)], { type: 'application/json' });
    url = URL.createObjectURL(blobStyle);
  }
  const arrLine = Object
    .keys(ticket || {})
    .filter((key) => ticket[key].name !== 'Штрихкод' && ticket[key].name !== 'Логотип')
    .map((key) => ({ ...ticket[key], key }));
  const arrImg = Object
    .keys(ticket || {})
    .filter((key) => ticket[key].name === 'Штрихкод' || ticket[key].name === 'Логотип')
    .map((key) => ({ ...ticket[key], key }));

  const imagesColumns = TableImagesConfig(change, changeImage, scrollPosition);
  const fontsColumns = TableFontsConfig(change, fonts, removeField, scrollPosition, openModal);

  return (
    <Row>
      <Col span={8}>
        {data && <ConstrDragg ticket={ticket} data={data} />}
      </Col>
      <Col span={16}>
        <Space direction="vertical" size="middle">
          <Input
            addonBefore="Наименование:"
            type="text"
            size="large"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Space direction="horizontal">
            <Button
              type="primary"
              size="large"
              onClick={sendServer}
            >
              Сохранить
            </Button>
            <ImportExportConfig name="ticketItem-template.json" setData={setTicket} url={url} />
          </Space>
          <Space style={{ maxHeight: '60vh', overflow: 'auto' }} direction="vertical" size="small">
            <Typography.Title level={4}>Изображения</Typography.Title>
            <Table
              columns={imagesColumns}
              dataSource={arrImg}
              rowKey="name"
            />
            <Space direction="horizontal" size="small" align="end">
              <Typography.Title style={{ marginBottom: '0' }} level={4}>Текст</Typography.Title>
              <Button
                shape="circle"
                type="primary"
                onClick={addField}
                icon={<Icon component={FaPlus} />}
              />
            </Space>
            <Table
              columns={fontsColumns}
              dataSource={arrLine}
              rowKey="name"
            //  scroll={{ x: 1500 }}
            />
          </Space>
        </Space>
      </Col>
      {open && (
        <ModalSettings
          data={data}
          open={open}
          activeElem={acName}
          ticket={ticket}
          toggle={toggle}
          change={addInputField}
        />
      )}
    </Row>
  );
}

export default ConstructorSettings;
