import Moment from 'moment/moment';
import getWplace from './getWplace';

const GetText = (text, data, number) => {
  const { ticket, connectedWplaces, ticketsBeforeYou } = data;
  console.log(ticket);
  const { dictionary } = ticket;
  let newText = text;
  dictionary.forEach((item) => {
    const findText = `$FIELD${item.fid}$`;
    if (text.indexOf(findText) !== -1) {
      newText = text.replace(findText, item.fvNames[0]);
    }
  });
  if (dictionary.length < 1) {
    const regPattern = /\$FIELD+[a-zA-Z1-9_]+\$/g;
    newText = newText.replace(regPattern, '');
  }
  newText = newText.replace('$QS$', ticket.qShortName);
  newText = newText.replace('$QL$', ticket.qFullName);
  newText = newText.replace('$BC$', ticketsBeforeYou);
  newText = newText.replace('$DD$', Moment(ticket.created_on, 'YYYY.MM.DD HH:mm:ss').format('DD.MM.YYYY'));
  newText = newText.replace('$DT$', Moment(ticket.created_on, 'YYYY.MM.DD HH:mm:ss').format('HH:mm'));
  // newText = newText.replace('$PN$', ticket)

  const wplace = getWplace(connectedWplaces) ? newText.replace('$WIN$', getWplace(connectedWplaces)) : '';
  newText = newText.match('$WIN$') ? wplace : newText;
  newText = newText.replace(/\$NUM\$/g, number);

  const reg = /\$.*?\$/; // Если не заменилось значение
  if (reg.test(newText)) {
    newText = '';
  }

  return newText;
};

export default GetText;
