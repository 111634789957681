import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from 'antd';
import { EmptyList } from '../../../../common/ui/Common';
import Config from './TableConfig';
import Preloader from '../../../../pages/private/components/preloader/Preloader';
import DeleteWarning from '../../../../common/ui/Modal/DeleteWarning';
import { useToggle } from '../../../../logic/hooks/useToggle';
import DeleteItem from '../../../../pages/private/utils/deleteItem';
import { useSearchPropsFactory } from '../../../../pages/private/utils/useSearch';

function ZonesTable({
  zoneList, setZoneUpdate, setZoneData, toggleOpen, preloader, fetchZones, showError,
}) {
  const [zoneId, setZoneId] = useState(false);
  const [zoneName, setZoneName] = useState(false);
  const [showDelWarn, setShowDelWarn] = useToggle(false);
  const navigate = useNavigate();

  const delZone = async () => {
    await DeleteItem({
      preloader, url: `/server/api/zone/${zoneId}/`, fetchData: fetchZones, setShowWarn: setShowDelWarn, setId: setZoneId, showError,
    });
  };

  const handleTableIcon = (e, row) => {
    const { dataset } = e.target;
    const {
      id, name, longname, description, config,
    } = row;
    if (dataset.name === 'del') {
      setZoneId(id);
      setZoneName(name);
      setShowDelWarn();
    } else if (dataset.name === 'update') {
      setZoneUpdate(true);
      setZoneData({
        id, name, longname, description, config,
      });
      setZoneId(id);
      toggleOpen();
    } else if (dataset.name === 'styles') {
      navigate(`/handle-points/${id}`);
    }
  };

  const Setting = Config(useSearchPropsFactory());
  const { columns } = Setting;

  const zoneListView = () => (zoneList.length ? (
    <Table
      dataSource={zoneList}
      columns={columns}
      onRow={(row) => ({
        onClick: (e) => { handleTableIcon(e, row); },
      })}
    />
  ) : <EmptyList>Зон нет</EmptyList>);

  return (
    <>
      {!zoneList ? <Preloader isOpen small /> : zoneListView()}
      <DeleteWarning
        name={zoneName}
        confirmDel={delZone}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </>
  );
}

export default React.memo(ZonesTable);
