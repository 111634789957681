import dState from './state';

function reducer(state, action) {
  const { value, type } = action;
  switch (type) {
    case 'queueList':
      return { ...state, queueList: value };
    case 'add':
      return { ...state, ticket: { ...value } };
    case 'timeId':
      return { ...state, ...value };
    case 'clear':
      return { count: state.count - 1 };
    case 'clearTicket':
      return { ...dState };
    default:
      return { ...state, [type]: value };
  }
}

export default reducer;
