import React, { useState } from 'react';
import { Table } from 'antd';
import Config from './TableConfig';
import DeleteWarning from '../../../../../../common/ui/Modal/DeleteWarning';
import { useToggle } from '../../../../../../logic/hooks/useToggle';
import DeleteItem from '../../../../../../pages/private/utils/deleteItem';
import { useSearchPropsFactory } from '../../../../../../pages/private/utils/useSearch';

function WeekTable({
  weekList, setWeekUpdate, setWeekData, toggleOpen, preloader, fetchDay, dayList, write, showError,
}) {
  const [weekId, setWeekId] = useState(false);
  const [weekName, setWeekName] = useState(false);
  const [showDelWarn, setShowDelWarn] = useToggle(false);

  const delWeek = async () => {
    await DeleteItem({
      preloader, url: `/server/api/dworkweek/${weekId}/`, fetchData: fetchDay, setShowWarn: setShowDelWarn, setId: setWeekId, showError,
    });
  };

  const handleTableIcon = (e, row) => {
    const { dataset } = e.target;
    const {
      id,
      name,
    } = row;
    if (dataset.name === 'del') {
      setWeekId(id);
      setWeekName(name);
      setShowDelWarn();
    } else if (dataset.name === 'update') {
      setWeekUpdate(true);
      setWeekData(row);
      toggleOpen();
    }
  };

  const Setting = Config(dayList, write, useSearchPropsFactory());
  const { columns } = Setting;
  return (
    <>
      {!!weekList.data?.length
        && (
          <Table
            dataSource={weekList.data}
            columns={columns}
            onRow={(row) => ({
              onClick: (e) => { handleTableIcon(e, row); },
            })}
          />
        )}
      <DeleteWarning
        name={weekName}
        confirmDel={delWeek}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </>
  );
}

export default React.memo(WeekTable);
