import React from 'react';
import Buttons from './buttons';

function Information(props) {
  const {
    scene, style, bottomBlock,
  } = props;
  const block = bottomBlock || scene.bottomBlock || scene;
  return (
    <div style={{ flex: 1, width: '100%' }}>
      <div style={{ flex: 1 }} dangerouslySetInnerHTML={{ __html: block.topText || block.data }} />
      <div dangerouslySetInnerHTML={{ __html: block.bottomText }} />
      {block.buttons && <Buttons buttons={scene.buttons} style={style.button} />}
    </div>
  );
}

export default Information;
