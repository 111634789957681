import React, { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Context from '../utils/ctx';
import Preloader from './preloader';
import getYear from '../utils/getYear';
import request from '../utils/request';
import useInterval from '../utils/useInterval';

function Queue() {
  const {
    queue, queueList, loading, year, dispatch,
  } = useContext(Context);
  const optionsQueue = queueList.map((el) => ({ id: el.qId, name: el.qFullName }));
  const navigate = useNavigate();

  const onClick = async (el) => {
    dispatch({ type: 'queue', value: { id: el.id, name: el.name } });
    if (queue) {
      await getYear(el.id, year)
        .then((res) => {
          dispatch({ type: 'calendar', value: res.year });
          dispatch({ type: 'syear', value: res.selectedYear });
        });
    }
    navigate('/preentry/day');
  };

  const fetchQueue = useCallback(async () => {
    const { data: { data } } = await request('/server/public/preorder/queues/', 'get');

    dispatch({ type: 'loading', value: true });
    dispatch({ type: 'queueList', value: data.filter((el) => el.qFreeSlots) });
  }, []);

  useEffect(() => { fetchQueue(); }, [fetchQueue]);

  useInterval(() => {
    // Your custom logic here
    fetchQueue();
  }, 5000);

  const viewQueueList = queueList.length > 0
    ? (
      <div className="box">
        {optionsQueue.map((el) => (
          <div
            key={el.id}
            className={`item ${queue === el.id && 'active'}`}
            onClick={() => onClick(el)}
          >
            {el.name}
          </div>
        ))}
      </div>
    )
    : <p>Нет свободных слотов для записи.</p>;

  return (
    <>
      <h3>Выберите услугу</h3>
      {loading
        ? viewQueueList
        : <Preloader />}
    </>
  );
}

export default Queue;
