export default ({
  input, checkbox,
}) => [
  {
    title: 'Фон</br>поля ввода', value: input.background, paramName: 'background', parentName: 'input',
  },
  {
    title: 'Обводка</br>поля ввода', value: input.borderColor, paramName: 'borderColor', parentName: 'input',
  },
  {
    title: 'Текст</br>поля ввода', value: input.color, paramName: 'color', parentName: 'input',
  },
  {
    title: 'Тень</br>поля ввода', value: input.boxShadow.split(' ')[3], paramName: 'boxShadow', parentName: 'input',
  },
  {
    title: 'Фон</br>окна выбора', value: checkbox.background, paramName: 'background', parentName: 'checkbox',
  },
  {
    title: 'Обводка</br>окна выбора', value: checkbox.borderColor, paramName: 'borderColor', parentName: 'checkbox',
  },
  {
    title: 'Тень</br>окна выбора', value: checkbox.boxShadow.split(' ')[3], paramName: 'boxShadow', parentName: 'checkbox',
  },
];
