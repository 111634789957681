import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import defaultFilters from '../../../components/ReportFilters/utils/defaultFilters';
import PrepareTable from '../../../components/PrepareTable';
import ReportFilters from '../../../components/ReportFilters/ReportFilters';

function IntervalReport(props) {
  const [queryParams, setQueryParams] = useState();
  const { reportConfig } = useSelector((state) => state);
  const { preloader } = props;

  const preparQueryParams = useCallback(async (filters) => {
    const params = {
      filters: filters || defaultFilters(),
      columns: [],
      url: '/api/reports/build',
      type: 'intervalReport',
    };
    setQueryParams(params);
  }, []);

  useEffect(() => {
    preparQueryParams();
  }, [reportConfig, preparQueryParams]);

  return (queryParams ? (
    <>
      <ReportFilters
        showFilters={{
          main: ['reportRange', 'historyDate', 'ignoreValue'],
          others: [],
          orders: [],
        }}
        paramsCallback={preparQueryParams}
        type="intervalReport"
      />
      <PrepareTable
        keys="intervalReport"
        type="intervalReport"
        {...queryParams}
        preloader={preloader}
      />
    </>
  ) : null);
}

export default IntervalReport;
