import React, {
  useEffect, useState, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getConfig, getStyle } from './utils/getConfig';
import Preloader from './components/preload';
import Scene from './components/scene/scene';
import { resetTicket } from './store/actions';
import Store from './store/store';
import { resetScanPolicyStore } from '../../../logic/store/pullstate/scanPolicy';
import Header from './components/Header';
import ExtraInfo from './components/Extra';

const { dispatch } = Store;

function App({ screen, ...props }) {
  const { oldConfig, id } = props;
  const { style, config } = useSelector((state) => state);
  const { startScreen, headUrl } = config;
  const { errorText } = config;
  const [notZoneId, setNotZoneId] = useState(false);
  const timer = useRef(false);
  const {
    head, header, extraText, phone, clock, logoABT, logoComp, footer, time, headerMain, headerSub,
  } = style;
  const location = useLocation();
  const navigate = useNavigate();

  const dragStart = (e) => e.preventDefault();

  const goToStart = () => {
    if (oldConfig) return;
    clearTimeout(timer.current);
    if (startScreen) {
      timer.current = setTimeout(() => {
        const { pathname } = location;
        if (errorText !== 'Отсутствует соединение с сервером' && pathname !== startScreen) {
          navigate(startScreen);
          dispatch(resetTicket());
        }
        goToStart();
        resetScanPolicyStore();
        localStorage.removeItem('emulatePreRecord');
        navigate(startScreen);
      }, 60 * 2 * 1000);
    }
  };

  useEffect(() => {
    if (oldConfig) getStyle({ style: oldConfig.config.style });
  }, [oldConfig]);

  useEffect(() => {
    goToStart();
  }, [errorText, startScreen]);

  useEffect(() => {
    getConfig({
      id, dataProps: oldConfig, setNotZoneId, navigate, location,
    });
    document.body.addEventListener('dragstart', dragStart);
    document.body.addEventListener('click', goToStart);
    return () => {
      document.body.removeEventListener('dragstart', dragStart);
      document.body.removeEventListener('click', goToStart);
      clearTimeout(timer);
    };
  }, []);

  if (logoABT) delete logoABT.backgroundImage;
  const st = { backgroundSize: 'cover', backgroundRepeat: 'no-repeat' };
  if (head && config.config && !notZoneId) {
    const bodyHeight = style.head.height - header.height - footer.height;
    return (
      <div
        className="layout_box"
        style={{
          width: style.head.width, height: style.head.height, fontFamily: style.head.fontFamily, backgroundImage: `url(${style.head.backgroundImage})`,
        }}
      >
        <Header header={header} headerMain={headerMain} headerSub={headerSub} />

        <ExtraInfo
          extraText={extraText}
          clock={clock}
          time={time}
          phone={phone}
          logoABT={logoABT}
          logoComp={logoComp}
        />

        {headUrl && startScreen && (
        <Scene
          height={bodyHeight}
          config={config.config}
          satelliteParams={{ satelliteId: config.satelliteId, satellitePort: config.satellitePort }}
          startScreen={startScreen}
          headUrl={headUrl}
          selectScreen={screen}
          id={id}
          params={config.defaultParams}
          style={style}
        />
        )}
        {!startScreen && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              fontSize: 'large',
              fontWeight: 'bold',
            }}
          >
            Стартовый экран не указан
          </div>
        )}
        <div style={{ ...footer, st }} />
      </div>
    );
  }
  return <Preloader notZoneId={notZoneId} />;
}

export default App;
