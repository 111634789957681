export default function TableConfig() {
  return {
    columns: [
      {
        dataIndex: 'created_on',
        title: 'Дата действия',
      },
      {
        dataIndex: 'ticket_id',
        title: 'Id талона',
      },
      {
        dataIndex: 'ticket_number',
        title: '№ талона',
      },
      {
        dataIndex: 'fio',
        title: 'Оператор',
      },
      {
        dataIndex: 'name',
        title: 'Рабочее место',
      },
      {
        dataIndex: 'qname',
        title: 'Очередь',
      },
      {
        dataIndex: 'action',
        title: 'Событие',
      },
    ],
  };
}
