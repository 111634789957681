import { combineReducers, createStore } from 'redux';

import ticket from './reducers/ticket';
import infoTicket from './reducers/infoTicket';
import infoScreen from './reducers/infoScreen';
import config from './reducers/config';
import style from './reducers/style';
import preEntry from './reducers/preEntry';

const red = combineReducers({
  ticket,
  infoTicket,
  infoScreen,
  config,
  style,
  preEntry,
});

const store = createStore(red);
export default store;
