export default ({
  title, extraTitle, phone, extraText, clock, time,
}) => [
  {
    title: 'Заголовок', value: title.textAlign, parentName: 'title',
  },
  {
    title: 'Подзаголовок', value: extraTitle.textAlign, parentName: 'extraTitle',
  },
  {
    title: 'Телефон', value: phone.textAlign, parentName: 'phone',
  },
  {
    title: 'Дополнительный <br/>текст', value: extraText.textAlign, parentName: 'extraText',
  },
  {
    title: 'Дата', value: clock.textAlign, parentName: 'clock',
  },
  {
    title: 'Время', value: time.textAlign, parentName: 'time',
  },
];
