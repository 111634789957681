import React, {
  useEffect, useState, useCallback,
} from 'react';

function Carousel({
  initialSlide, children, autoPlayInterval, arrows,
  dots, transitionDuration, transitionTimingFunction, carouselClass,
  dotsBoxClass, arrowsBoxClass, navColor, news,
  color, fontSize1, fontFamily1, height, width,
}) {
  const [items, setItems] = useState([]);
  const [slide, setSlide] = useState(initialSlide);
  const [dragging, setDragging] = useState(null);
  const [sliding, setSliding] = useState(false);
  const [offset, setOffset] = useState(0);
  const [transition, setTransition] = useState(true);
  const [duration, setDuration] = useState(3000);
  const [noStart, setNoStart] = useState(false);

  const changeSlide = useCallback((sld) => {
    if (document.hidden) return;
    if (news.length + 1 > sld) {
      setSlide(sld);
    } else {
      setSlide(0);
    }
    setSliding(true);
    setDragging(null);
    setOffset(0);
  }, [news]);

  useEffect(() => {
    if (slide === 0) {
      setNoStart(true);
      setTransition(false);
    } else {
      setTransition(true);
    }
  }, [slide, items]);

  useEffect(() => {
    if (slide === 0 && noStart) {
      setDuration(10);
    } else {
      setDuration(autoPlayInterval);
    }
  }, [slide, items, autoPlayInterval, noStart]);

  useEffect(() => {
    setDuration(autoPlayInterval);
  }, [autoPlayInterval]);

  useEffect(() => {
    let timer;
    const newItems = [...news];
    setItems(newItems);
    if (newItems.length > 1 && duration > 0) {
      timer = window.setInterval(() => changeSlide(slide + 1), duration);
    }
    return () => {
      clearInterval(timer);
    };
  }, [slide, dragging, duration, changeSlide, children, news]);

  const onDraggingStart = (event) => {
    if (event.touches) {
      setDragging({
        x: event.touches[0].pageX,
        y: event.touches[0].pageY,
      });
      setOffset(0);
    }
  };

  const onDraggingMove = (event) => {
    if (sliding || !dragging || !event.touches) return;
    const x = event.touches[0].pageX;
    const y = event.touches[0].pageY;
    const newOffset = x - dragging.x;
    if (Math.abs(y - dragging.y) < Math.abs(offset)) event.preventDefault();
    setOffset(newOffset);
  };

  const onDraggingEnd = (event) => {
    const offsetSlide = (offset > 0 ? slide - 1 : slide + 1);

    const sliderWidth = event.currentTarget.clientWidth;
    if (!dragging) return;
    const target = Math.abs(offset) > sliderWidth / 5 ? offsetSlide : slide;

    if (Math.abs(Math.abs(dragging.x) - event.nativeEvent.changedTouches[0].pageX) > 5) {
      setDragging(null);
      changeSlide(target);
    }
  };

  const onClick = (event) => {
    if (Math.abs(offset) < 25) return;
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopPropagation();
  };

  const goPrevSlide = () => changeSlide(slide - 1);
  const goNextSlide = () => changeSlide(slide + 1);

  const enabled = items.length > 1;

  return (
    <div
      className={carouselClass}
      style={{
        position: 'relative',
        overflowX: 'hidden',
        touchAction: 'pan-y pinch-zoom',
        maxWidth: width,
        maxHeight: height,
      }}
    >
      <ul
        role="presentation"
        style={{
          listStyleType: 'none',
          position: 'absolute',
          padding: 0,
          margin: 0,
          display: 'flex',
          transitionProperty: sliding ? 'transform' : 'none',
          transform: enabled
            ? `translateX(-${(slide / (items.length + 1)) * 100}%)`
            : 'translateX(0)',
          transitionDuration: transition ? `${transitionDuration}s` : '0s',
          transitionTimingFunction,
          contain: 'layout',
          willChange: 'transform',
        }}
        onTouchStart={onDraggingStart}
        onTouchMove={onDraggingMove}
        onTouchEnd={onDraggingEnd}
        onTouchCancel={onDraggingEnd}
        onClick={onClick}
      >
        {enabled ? [...items, items[0]].map((item, index) => {
          const minWidth = width || 0;
          return (
            <li key={slide} aria-current={slide === index} style={{ minWidth }}>
              <div key={Math.random()} style={{ position: 'relative', padding: '0 20px' }}>
                <p style={{ fontSize: fontSize1, color, fontFamily: fontFamily1 }}>
                  {item.text}
                </p>
              </div>
            </li>
          );
        })
          || <li>{children}</li> : null}
        {items.length === 1 && <li>{children}</li>}
      </ul>
      {items.length > 1 && dots && (
        <ol className={dotsBoxClass}>
          {items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li role="presentation" key={index} aria-current={slide === index + 1} onClick={() => changeSlide(index + 1)} style={{ backgroundColor: navColor }}>
              {index + 1}
            </li>
          ))}
        </ol>
      )}
      {enabled && arrows && (
        <div className={arrowsBoxClass}>
          <button type="button" className="carousel__arrow__prev" onClick={goPrevSlide} />
          <button type="button" className="carousel__arrow__next" onClick={goNextSlide} />
        </div>
      )}
    </div>
  );
}

Carousel.defaultProps = {
  carouselClass: 'carousel',
  initialSlide: 0,
  transitionTimingFunction: 'ease-in-out',
  transitionDuration: 0.5,
  autoPlayInterval: 3000,
  arrows: true,
  dots: true,
  news: [],
  dotsBoxClass: 'carousel__dots_box',
  arrowsBoxClass: 'carousel__arrow_box',
  nextArrClass: 'carousel__arrow__next',
  prevArrClass: 'carousel__arrow__prev',
};

export default Carousel;
