import React, { useState } from 'react';
import {
  Button, Input, Card, Form, InputNumber, Row, Col,
} from 'antd';
import request from '../../../../../../logic/utils/request';

function NewAnswerForm(props) {
  const { questionData, setDataSource } = props;
  const {
    operator_mark: operatorMark, questiontext, id, answers,
  } = questionData;
  const [newAnswerText, setNewAnswerText] = useState('');
  const [newAnswerWeight, setNewAnswerWeight] = useState(0);

  const addNewAnswer = async () => {
    const questionAnswer = answers;
    const newAnswerPosition = questionAnswer.length + 1;
    questionAnswer.push(
      {
        type: 3,
        position: newAnswerPosition,
        answertxt: newAnswerText,
        weight: newAnswerWeight,
      },
    );

    const newAnswersFromQuestionData = {
      questiontext,
      operator_mark: operatorMark,
      correctAnswers: questionAnswer.map((el) => ({
        ...el,
      })),
    };
    try {
      const res = await request(`/server/api/qcquestion/${id}/`, 'update', newAnswersFromQuestionData);
      const { data: { answers: resAnswers } } = res;
      resAnswers.sort((a, b) => a.position - b.position);

      resAnswers.forEach((el, index) => {
        el.position = index;
      });
      setDataSource(resAnswers);
    } catch (e) {
      console.log('e === ', e);
    }
    setNewAnswerText('');
    setNewAnswerWeight(0);
  };

  return (
    <Card
      title="Добавить ответ"
    >
      <Form>
        <Form.Item>
          <Input.Group size="large">
            <Row gutter={8}>
              <Col span={12}>
                <Input
                  placeholder="Текст ответа"
                  size="large"
                  value={newAnswerText}
                  onChange={(e) => { setNewAnswerText(e.target.value); }}
                  defaultValue={newAnswerText}
                />
              </Col>
              <Col span={12}>
                <InputNumber
                  size="large"
                  min={0}
                  max={10}
                  defaultValue={newAnswerWeight}
                  value={newAnswerWeight}
                  onChange={(e) => {
                    setNewAnswerWeight(e);
                  }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Form.Item>
        <Button
          type="primary"
          size="large"
          onClick={addNewAnswer}
          disabled={!newAnswerText.length > 0}
        >
          Добавить ответ
        </Button>
      </Form>
    </Card>
  );
}

export default NewAnswerForm;
