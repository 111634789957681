export default ({ time }) => [
  {
    title: 'Текст', value: time.color2, paramName: 'color2', parentName: 'time',
  },
  {
    title: 'Рамка', value: time.color3, paramName: 'color3', parentName: 'time',
  },
  {
    title: 'Фон', value: time.background, paramName: 'background', parentName: 'time',
  },
  {
    title: 'Дата и время', value: time.color1, paramName: 'color1', parentName: 'time',
  },
];
