import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import {
  FilterBox, SelectItem, InputBox, TextItem, ItemsBox,
} from '../../Queue/WorkPlaces/style';
import Select from '../../../../pages/private/components/Input/SelectInput';
import { BtnTable, Input } from '../../../../common/ui/Common';
import { ReactComponent as Trash } from '../../../../common/images/del.svg';

function FilterItem(props) {
  const {
    item, dict, onChange, index, list, removeFilter, user,
  } = props;

  const [value, setValue] = useState('');

  let dictionary = {};

  const findSelect = (arr, it) => arr.some((el) => String(el) === String(it.id));
  const dopOptions = [];
  if (item.fid) {
    dictionary = dict.find((elem) => elem.id === item.fid);
    if (user) {
      item.fv.forEach((el) => dopOptions.push(el));
    } else {
      dictionary.values.forEach((elem) => dopOptions.push({ id: elem.id, name: elem.name }));
    }
  }
  const options = [];
  if (user) {
    dict.forEach((el) => options.push({ id: +el.id, name: el.name }));
  } else {
    list.forEach((el) => {
      const option = dict.find((dictEl) => dictEl.id === el.dict_id);
      options.push({ id: option.id, name: option.name });
    });
  }

  const addText = () => {
    onChange({ el: value, action: 1, index });
    setValue('');
  };

  return (
    <FilterBox style={{ maxWidth: '100%' }} key={index}>
      <div>
        <Select
          m="0 0 10px 0"
          noDefault
          name="fid"
          value={item.fid}
          options={options}
          onChange={
            ({
              target: {
                value: newValue,
                name,
              },
            }) => onChange({
              index,
              value: newValue,
              name,
            })
          }
        />
        {!user && item.fid && dopOptions.map((element) => (
          <SelectItem
            key={element.id}
            onClick={() => onChange({
              index, value: element.id, name: 'fv',
            })}
            active={findSelect(item.fv, element)}
          >
            {element.name}
          </SelectItem>
        ))}
        <InputBox show={value.length > 0}>
          {user && (
          <Input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{ minWidth: value.length > 0 ? 'calc(100% - 40px)' : '100%' }}
            placeholder="Введите текст"
          />
          )}
          {value.length > 0 && <span onClick={addText}><FaPlus /></span>}
        </InputBox>
        <ItemsBox>
          {user && item.fid && dopOptions.map((el) => (
            <TextItem
              key={el.fid}
              active={findSelect(item.fv, { id: el })}
            >
              <p>{el}</p>
              <span onClick={() => onChange({ el, index })}>×</span>
            </TextItem>
          ))}
        </ItemsBox>
      </div>
      <BtnTable m="15px 0 0" onClick={() => removeFilter(item)} data-name="del" active><Trash /></BtnTable>
    </FilterBox>
  );
}

export default FilterItem;
