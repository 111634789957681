import React, { useEffect, useState, useCallback } from 'react';
import getParamsFromUrl from '../../../logic/utils/getParamsFromUrl';
import Request from '../../../logic/utils/request';
import ScreensProvider from './Screens/ScreenProvider';
import { ScreenProviderContext } from './use/useScreenProviderContext';
import { useScreenProvider } from './use/useScreenProvider';

function Webservice() {
  document.title = 'Вебсервис';

  const { satelliteid: satelliteId } = getParamsFromUrl(window.location.href);
  const [currentZone, setCurrentZone] = useState(null);
  const [styleConfig, setStyleConfig] = useState({});

  const getZone = useCallback(async () => {
    const satelliteRes = await Request(`/server/public/satellite/${satelliteId}/`);

    if (satelliteRes.statusText === 'OK') {
      const { data: { data } } = satelliteRes;
      const asnwer = data[0];
      const idZone = asnwer.zone.zoneId;
      const { style } = asnwer.config;
      const zoneRes = await Request('/server/public/getZone/', 'no', { idZone });
      const { data: { zone } } = zoneRes;
      setStyleConfig(style);
      setCurrentZone(zone);
    }
  }, [satelliteId]);

  useEffect(() => {
    getZone();
  }, [getZone]);

  const context = useScreenProvider({ currentZone, satelliteId });

  return (
    <ScreenProviderContext.Provider value={context}>
      <div className="websWrapper">
        {currentZone && (
          <ScreensProvider styleConfig={styleConfig} />
        )}
      </div>
    </ScreenProviderContext.Provider>
  );
}

export default Webservice;
