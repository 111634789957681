import React from 'react';
import {
  LineDescription, LineValue, ReportLine, SomeLineColumn,
} from '../../../../../components/report/ReportTableColumns';

export function TripleLineTemplate(
  firstItemName,
  firstItemValueProp,
  secondItemName,
  secondItemValueProp,
  triItemName,
  triItemValue,
) {
  let firstItemValue = firstItemValueProp;
  if (firstItemValueProp === null) {
    if ((firstItemName.toLowerCase().indexOf('время') !== -1) || (firstItemName.indexOf('Средняя') !== -1) || (firstItemName.indexOf('Общая') !== -1)) {
      firstItemValue = '00:00:00';
    } else {
      firstItemValue = 0;
    }
  }
  let secondItemValue = secondItemValueProp;
  if (secondItemValueProp === null) {
    if ((secondItemName.toLowerCase().indexOf('время') !== -1) || (secondItemName.indexOf('Средняя') !== -1) || (secondItemName.indexOf('Общая') !== -1)) {
      secondItemValue = '00:00:00';
    } else {
      secondItemValue = 0;
    }
  }

  return (
    <SomeLineColumn>
      <ReportLine key={firstItemName}>
        <LineDescription>{firstItemName}</LineDescription>
        <LineValue>{firstItemValue}</LineValue>
      </ReportLine>
      <ReportLine key={secondItemName}>
        <LineDescription>{secondItemName}</LineDescription>
        <LineValue>{secondItemValue}</LineValue>
      </ReportLine>
      <ReportLine key={triItemName}>
        <LineDescription>{triItemName}</LineDescription>
        <LineValue>{triItemValue}</LineValue>
      </ReportLine>
    </SomeLineColumn>
  );
}

export default null;
