import React from 'react';
import SelectInput from '../../../../../Elements/Input2/SelectInput/SelectInput';

function Order({
  element, dispatch, value, title,
}) {
  return (
    <span className="box__color">
      {/* eslint-disable-next-line react/no-danger */}
      <span className="settings__styles__el__label" dangerouslySetInnerHTML={{ __html: title }} />
      <SelectInput
        noDefault
        options={[
          { id: 0, name: 1 },
          { id: 1, name: 2 },
        ]}
        size="16px"
        onChange={(e) => dispatch({
          type: 'params',
          name: element,
          param: 'order',
          value: Number(e.target.value),
        })}
        value={value}
      />
    </span>
  );
}

export default Order;
