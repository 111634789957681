import React from 'react';
import { useSelector } from 'react-redux';
import { NextButton } from '../../../../utils/style';

function Times({
  times, setId, id, style, createTicket, preEntryInput, newPreEntrty, selectTime,
}) {
  const arrTime = {};
  const {
    head, footer, header, bottomButton,
  } = useSelector((state) => state.config.config.style);
  const containerHeight = head.height
    - footer.height
    - header.height
    - bottomButton.height
    - bottomButton.marginBottom
    - 370;
  // eslint-disable-next-line no-return-assign
  times.forEach((el) => arrTime[el.calendarCellTime] = {
    id: el.calendarCellId,
    time: el.calendarCellTime,
  });
  const isActive = times.find((time) => +time.calendarCellId === +id);

  return (
    <>
      <h3>Выберите время</h3>
      <div className="Box times" style={{ maxHeight: containerHeight }}>
        <ul>
          {Object.keys(arrTime).sort((a, b) => (a > b ? 1 : -1)).map((el) => (
            <li
              onClick={() => (newPreEntrty ? selectTime(arrTime[el].id, arrTime[el].time)
                : setId(arrTime[el].id, arrTime[el].time))}
              className={`item ${isActive && isActive.calendarCellTime === el && 'active'}`}
              key={el}
            >
              {el}
            </li>
          ))}
        </ul>
      </div>
      {!preEntryInput && (
      <div className="flex">
        {id && <NextButton style={style} onClick={createTicket}> Записаться </NextButton>}
      </div>
      )}
    </>
  );
}

export default Times;
