import React from 'react';
import { ReactComponent as Edit } from '../../../../../common/images/edit.svg';
import c from '../../../../../common/ui/theme/Colors';
import { BtnTable } from '../../../../../common/ui/Common';
import { ReactComponent as Download } from '../../../../../common/images/download.svg';
import { ReactComponent as Trash } from '../../../../../common/images/del.svg';

export const headerFormatter = (column, colIndex, { filterElement }) => (

  <div className="filterBoxTable">
    {column.dataField !== 'type' && column.dataField !== 'status' ? <Edit style={{ margin: '2px 10px 0 0' }} fill={c.main} /> : null }
    { filterElement }
  </div>
);

export function playButtonTempl(row) {
  const { fileUrl, status } = row;
  return (
    <div style={{ display: 'flex' }}>
      <a href={fileUrl} target="_blank" rel="noopener noreferrer">
        <BtnTable data-name="download" type="button" disabled={status !== 1}><Download /></BtnTable>
      </a>
      <div style={{ marginLeft: '10px' }}>
        <BtnTable data-name="del" active><Trash /></BtnTable>
      </div>
    </div>

  );
}

export const reportTypeList = {
  exactlyOperator: 'Отчет по оператору',
  exactlyQueue: 'Отчет по очереди',
  exactlyWorkplace: 'Отчет по рабочему месту',
  ticketReport: 'Отчет по талонам',
  queueReport: 'Отчет по очередям',
  operatorReport: 'Отчет по операторам',
  workPlaceQuery: 'Отчет по рабочим местам',
  ticketHistoryReport: 'Отчет история талона',
  markReport: 'Отчет по оценкам',
  clockwiseReport: 'Почасовой отчет',
  dynamicsServiceTimeReport: 'Отчет по динамике времени обслуживания по услугам',
  //  operatorLogi: 'Отчет по входам операторов',
  dictionaryReport: 'Отчет по опросам',
  requestReport: 'Отчет по оценкам',
  sourceReport: 'Отчет по "источнику"',
  solvedQuestionReport: 'Отчет по решенным вопросам',
  // intervalReport: 'За интервал',
};

export const statusText = {
  0: 'Подготовка',
  1: 'Готов для скачивания',
};
