import { Table, Tabs } from 'antd';
import { useState } from 'react';
import Value from '../Value/Value';

function NewTable({
  groups, paramList, showError, multiData,
}) {
  const [activeGroup, setActiveGroup] = useState(0);
  console.log(activeGroup);
  const columns = [
    {
      title: 'Параметр',
      dataIndex: 'param',
      key: 'param',
      width: 320,
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Значение',
      dataIndex: 'value',
      key: 'value',
      render: (_, row) => (
        <Value
          type={row.param_type}
          val={row.value}
          showError={showError}
          data={row}
          multiData={multiData}
          par={row.param}
        />
      ),
    },
  ];
  return (

    <Tabs
      tabPosition="left"
      onChange={(key) => setActiveGroup(key)}
      items={groups.map((item, id) => ({
        label: item,
        key: id,
        children: <Table rowKey="param" columns={columns} dataSource={paramList.filter((param) => param.group_name === item)} />,
      }))}
    />
  );
}

export default NewTable;
