import { DeleteFilled, EditFilled } from '@ant-design/icons';
import {
  Button, Col, Row, Tooltip, Typography,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';

function ScreenTitle(props) {
  const { screen, toggleDrawer, deleteScreen } = props;
  const { Text } = Typography;

  return (
    <>
      <Row
        gutter={[20, 0]}
        justify="center"
        style={{ marginBottom: '10px' }}
      >
        <Col>
          <Tooltip title="Редактирование экрана">
            <Button type="primary" onClick={() => toggleDrawer('open', screen)}>
              <EditFilled />
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title="Удаление экрана">
            <Button type="primary" danger onClick={deleteScreen}>
              <DeleteFilled />
            </Button>
          </Tooltip>
        </Col>
      </Row>
      {screen?.title && (
        <Row justify="center">
          <Col>
            <Title style={{ textAlign: 'center' }} level={3}>{screen.title}</Title>
          </Col>
        </Row>
      )}
      {screen?.headTitle && (
        <Row justify="center">
          <Col style={{ textAlign: 'center' }}>
            <Text>
              {screen.headTitle}
            </Text>
          </Col>
        </Row>
      )}
    </>
  );
}

export default ScreenTitle;
