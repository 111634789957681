import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/ru_RU';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import reducers from './logic/store/redux/reducers';
import createLogger from './logic/logger';
import './common/ui/fonts/fonts.scss';
import ThemeCtx from './common/ui/theme/themeCtx';
import { GlobalBox, GlobalStyle } from './common/ui/Common';
import './common/ui/common.scss';
import Terminal from './pages/public/terminal/Terminal';
import Board from './pages/public/board/Board';
import Login from './pages/public/login/Login';
import PreEntry from './old/views/Control/Devices/DeviceStyle/PreEntry/App';
import Webservice from './pages/public/webservice/Webservice';
import Layout from './pages/private/Layout';
import { useApp } from './useApp';
import './index.css';
import getParamsFromUrl from './logic/utils/getParamsFromUrl';

const queryClient = new QueryClient();

createLogger(false);
const createStoreWithMiddleware = applyMiddleware()(createStore);

const urlParams = getParamsFromUrl(window.location.href);
const { token } = urlParams;

const chekToken = async () => {
  const res = await axios.post(`/server/api/license/?action=get&token=${token}`);

  if (res.statusText === 'OK') {
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('fio', 'Пользователь ЕСМ');
    window.localStorage.setItem('login', 'ESM');
    window.localStorage.setItem('role', 'eq_root');
  }
};

if (token) {
  await chekToken();
}

function App() {
  const {
    color,
    ctx,
  } = useApp();
  if (window.localStorage.getItem('officeName')) {
    document.title = `${window.localStorage.getItem('officeName')} | AlphaQ`;
  }
  return (
    <Provider store={createStoreWithMiddleware(reducers)}>
      <ThemeProvider theme={color}>
        <GlobalBox>
          <GlobalStyle />
          <ToastContainer autoClose={3000} />
          <ThemeCtx.Provider value={ctx}>
            <Routes>
              <Route
                path="/login/"
                element={<Login />}
              />
              <Route
                path="/terminal/:id/"
                element={<Terminal />}
              />
              <Route
                path="/terminal/:id/*"
                element={<Terminal />}
              />
              <Route
                path="/board/:id/"
                element={<Board />}
              />
              <Route
                path="/preentry"
                element={<PreEntry />}
              />
              <Route
                path="/webservice"
                element={<Webservice />}
              />
              <Route
                path="*"
                element={<Layout />}
              />
            </Routes>
          </ThemeCtx.Provider>
        </GlobalBox>
      </ThemeProvider>
    </Provider>
  );
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
      error,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong.</h1>
          <div>{this.state.error.stack.split(' at ').map((line) => <div key={line}>{line}</div>)}</div>
          {/* <div>{this.state.info}</div> */}
        </div>

      );
    }
    return this.props.children;
  }
}

const container = createRoot(document.getElementById('root'));
container.render(

  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ConfigProvider locale={locale}>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </ErrorBoundary>,

);
