export default ({ columns }) => [
  {
    title: 'Заголовок', value: columns.color1, paramName: 'color1', parentName: 'columns',
  },
  {
    title: 'Первая строка', value: columns.color2, paramName: 'color2', parentName: 'columns',
  },
  {
    title: 'Четная строка', value: columns.color3, paramName: 'color3', parentName: 'columns',
  },
  {
    title: 'Нечетная строка', value: columns.color4, paramName: 'color4', parentName: 'columns',
  },
  {
    title: 'Внутренняя рамка', value: columns.color5, paramName: 'color5', parentName: 'columns',
  },
  {
    title: 'Весь блок', value: columns.color6, paramName: 'color6', parentName: 'columns',
  },
  {
    title: 'Фон<br />Заголовка', value: columns.background1, paramName: 'background1', parentName: 'columns',
  },
  {
    title: 'Фон<br />Первой строки', value: columns.background2, paramName: 'background2', parentName: 'columns',
  },
  {
    title: 'Фон<br />Четной строки', value: columns.background3, paramName: 'background3', parentName: 'columns',
  },
  {
    title: 'Фон<br />Нечетной строки', value: columns.background4, paramName: 'background4', parentName: 'columns',
  },
];
