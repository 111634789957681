const changeStyle = ({ style, item }) => {
  const newStyle = { ...style };
  // eslint-disable-next-line no-nested-ternary
  newStyle.width = item.size === 'md'
    ? newStyle.widthM
    : item.size === 'sm'
      ? newStyle.widthS
      : newStyle.widthL || newStyle.width;
  // eslint-disable-next-line no-nested-ternary
  newStyle.height = item.size === 'md'
    ? newStyle.heightM
    : item.size === 'sm'
      ? newStyle.heightS
      : newStyle.heightL || newStyle.height;
  // eslint-disable-next-line no-nested-ternary
  newStyle.fontSize = item.size === 'md'
    ? newStyle.fontSizeM
    : item.size === 'sm'
      ? newStyle.fontSizeS
      : newStyle.fontSizeL || newStyle.fontSize;
  // eslint-disable-next-line no-nested-ternary
  newStyle.textAlign = item.size === 'md'
    ? newStyle.textAlignM
    : item.size === 'sm'
      ? newStyle.textAlignS
      : newStyle.textAlignL || newStyle.fontSize;
  return newStyle;
};

export default changeStyle;
