import React from 'react';
import { toast } from 'react-toastify';
import { dateFormat } from '../../../utils/tableFormatters';
import iconFormatter from '../../../../pages/private/utils/iconFormatter';

const copyLink = (string) => {
  let textarea;
  let result;

  try {
    textarea = document.createElement('textarea');
    textarea.setAttribute('readonly', true);
    textarea.setAttribute('contenteditable', true);
    textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = string;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand('copy');
    toast.success('Скопировано', 1000);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    result = null;
  } finally {
    document.body.removeChild(textarea);
  }

  // manual copy fallback using prompt
  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
    result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
    if (!result) {
      return false;
    }
  }
  return true;
};

export default function TableConfig(write, getColumnSearchProps) {
  return {
    columns: [
      {
        dataIndex: 'id',
        title: 'ID',
        searchText: 'ID',
        ...getColumnSearchProps('id', 'ID'),
      },
      {
        dataIndex: 'name',
        title: 'Наименование',
        ...getColumnSearchProps('name', 'наименование'),
      },
      {
        dataIndex: 'ipaddr',
        title: 'IP',
        ...getColumnSearchProps('ipaddr', 'IP'),
      },
      {
        dataIndex: 'port',
        title: 'Порт',
      },
      {
        dataIndex: 'type',
        title: 'Тип',
      },
      {
        dataIndex: 'zone',
        title: 'Зона обслуживания',
        render: (v) => (v ? v.zoneName : 'Зона не выбрана'),
        // ...getColumnSearchProps('zone', эону обслуживанияэ),
      },
      {
        dataIndex: 'created_on',
        title: 'Дата создания',
        render: dateFormat,
      },
      {
        dataIndex: 'status',
        title: 'Версия ПО',
      },
      {
        isDummyField: true,
        title: 'Вебсервис',
        render: (row) => {
          if (row.type === 'terminal') {
            return (
              <div className="webservicecopylink" onClick={() => { copyLink(`${window.location.protocol}//${window.location.host}/webservice?satelliteid=${row.id}`); }}>
                Скопировать ссылку
              </div>
            );
          }
          return null;
        },
      },
      {
        dataIndex: 'Settings',
        title: ' ',
        render: () => iconFormatter({
          write, view: true, styles: true, refresh: true,
        }),
      },
    ],
  };
}
