import ticket from '../defaultTicket';

export default function ticketReducer(
  state = {
    ...ticket,
    dictionary: [...ticket.dictionary],
  },
  action,
) {
  const { type, name, value } = action;
  console.log('action ===  ', action);
  switch (type) {
    case 'CHANGE-TICKET': {
      return {
        ...state,
        [name]: value,
      };
    }
    case 'RESET-TICKET': {
      localStorage.removeItem('emulatePreRecord');
      return {
        ...value,
      };
    }
    case 'CLEAR-DICTIONARY': {
      const { dictionary } = state;
      const newDict = [];
      if (dictionary) dictionary.forEach((dict) => dict.to !== value && newDict.push(dict));
      return {
        ...state,
        dictionary: [...newDict],
      };
    }

    case 'ADD-DICTIONARY': {
      const { dictionary } = state;
      console.log('state === ', state);
      const el = dictionary.find((dict) => +dict.fid === +value.fid);
      if (el) {
        dictionary.splice(dictionary.indexOf(el), 1, value);
      } else {
        dictionary.push(value);
      }
      return {
        ...state,
        dictionary,
      };
    }

    default:
      return state;
  }
}
