import Request from './request';
import Store from '../store/store';

import {
  setConfig,
  setStyle,
  setTextError,
} from '../store/actions';
import getStyle from './getStyle';

const { dispatch } = Store;

let web = true;
const connect = (headUrl, id, navigate) => {
  Request('/server/ping', 'no')
    .then(() => {
      if (!web) {
        dispatch(setTextError(''));
        if (!web) navigate(`${headUrl}/${id}/1`);
      }

      web = true;
    })
    .catch(() => {
      if (web) {
        dispatch(setTextError('Отсутствует соединение с сервером'));
        navigate(`${headUrl}/${id}/error/`);
      }
      web = false;
    });
};

const connetWebSocket = (headUrl, id, navigate) => {
  connect(headUrl, id, navigate);
  setInterval(() => connect(headUrl, id, navigate), 10000);
};

const setStyleFunction = ({ style }) => {
  const { width, height } = style.head;
  const diffW = window.innerWidth / width;
  const diffH = window.innerHeight / height;

  // eslint-disable-next-line no-param-reassign
  delete style.phone.position;
  const newStyle = {};
  // eslint-disable-next-line no-return-assign
  Object.keys(style).forEach((el) => newStyle[el] = getStyle(style[el], false, false));
  newStyle.diffW = diffW;
  newStyle.diffH = diffH;

  dispatch(setStyle(newStyle));
};

// eslint-disable-next-line consistent-return
const getConfig = async ({
  id, dataProps, setNotZoneId, navigate, location,
}) => {
  let bigConfig = { ...dataProps };
  if (!dataProps) {
    try {
      const d = await Request(`/server/public/satellite/${id}/`);
      // eslint-disable-next-line prefer-destructuring
      bigConfig = d.data.data[0];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
  const { config } = bigConfig;
  if (!bigConfig.zone) {
    setNotZoneId(true);
    return;
  }
  const { zoneId } = bigConfig.zone;
  try {
    const getZone = await Request('/server/public/getZone/', 'no', { data: { idZone: zoneId } });
    const headUrl = /terminal/.test(location.pathname) ? '/terminal' : '/devices';
    const { screens, params } = getZone.data.zone.config;
    bigConfig.config.layoutData = screens;
    let startScreen = '';

    screens.forEach((el) => {
      if (el.isStart) {
        startScreen = `${headUrl}/${id}/${el.screenId}`;
      }
    });
    // changed push to replace cause of bug
    if (startScreen) navigate(startScreen);

    if (!config.style.general) config.style.general = { twoScreen: false };
    const { twoScreen } = config.style.general;

    const newConfig = {
      connect: true,
      socketConnect: true,
      config,
      startScreen,
      headUrl,
      twoScreen,
      satelliteIp: bigConfig.ipaddr,
      satellitePort: bigConfig.port,
      defaultParams: params.slice(0),
      errorUrl: `${headUrl}/${id}/error/`,
      confirmUrl: `${headUrl}/${id}/confirm/`,
      idZone: zoneId,
      id,
      isLoad: true,
    };
    dispatch(setConfig(newConfig));
    if (!dataProps) setStyleFunction({ style: config.style });
    if (!dataProps) connetWebSocket(headUrl, id, navigate);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log({ e });
  }
};

export { getConfig, setStyleFunction as getStyle };
