export default ({
  title, extraTitle, phone, extraText, clock, time,
}) => [
  {
    title: 'Заголовок', data: title, parentName: 'title',
  },
  {
    title: 'Подзаголовок', data: extraTitle, parentName: 'extraTitle',
  },
  {
    title: 'Телефон', data: phone, parentName: 'phone',
  },
  {
    title: 'Дополнительный <br/>текст', data: extraText, parentName: 'extraText',
  },
  {
    title: 'Дата', data: clock, parentName: 'clock',
  },
  {
    title: 'Время', data: time, parentName: 'time',
  },
];
