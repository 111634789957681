import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import Modal from '../../../../../../common/ui/Modal/Modal';
import request from '../../../../../../logic/utils/request';
import RenderInputs from './RenderInputs';
import ModalButtons from '../../../../../../common/ui/Modal/ModalButtons';

function NewWorkPlace({
  isOpen, toggleOpen, fetchPlaces, placeUpdate, setPlaceData,
  placeData, initialData, setPlaceUpdate, preloader, zones, showError,
}) {
  const [isValid, setIsValid] = useState(false);
  const resetModal = () => {
    setPlaceData({ ...initialData });
    toggleOpen();
    setPlaceUpdate(false);
    setIsValid(false);
  };

  const addPlace = async () => {
    if (isValid) {
      preloader();
      try {
        await request('/server/api/wplace/', 'add', { ...placeData });
        toast.success('Выполнено');
        fetchPlaces();
        resetModal();
      } catch (e) {
        showError(e.data.error);
      }
      preloader();
    }
  };

  const updatePlace = async () => {
    if (isValid) {
      const { id, ...body } = placeData;
      preloader();
      try {
        await request(`/server/api/wplace/${id}/`, 'update', body);
        toast.success('Выполнено');
        fetchPlaces();
        resetModal();
      } catch (e) {
        showError(e.data.error);
      }
      preloader();
    }
  };

  const setValidCheck = (val, validData) => {
    if (validData.name !== '' && validData.placename !== '' && validData.connectedZones.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  return (
    <>
      <div>
        <Button type="primary" size="large" onClick={toggleOpen}>Добавить</Button>
      </div>
      <Modal title={placeUpdate ? 'Редактирование рабочего места' : 'Добавление рабочего места'} isOpen={isOpen} toggleOpen={resetModal}>
        <RenderInputs
          placeData={placeData}
          setPlaceData={setPlaceData}
          setIsValid={setValidCheck}
          preloader={preloader}
          placeUpdate={placeUpdate}
          zones={zones}
        />
        <ModalButtons
          isValid={isValid}
          update={placeUpdate}
          updateFunc={updatePlace}
          addFunc={addPlace}
          reset={resetModal}
        />
      </Modal>
    </>
  );
}

export default React.memo(NewWorkPlace);
