import React, { useContext } from 'react';

// components
import { Collapse } from 'antd'; // DOCS: https://ant.design/components/collapse/
import Colors from '../../../../../Components/Colors';
import Height from '../../../../../Components/Height';
import PositionOptions from '../../../../../Components/PositionOption';
import Margin from '../../../../../Components/Margin';

// array
import ArrColor from './components/color';
import ArrFontSize from './components/fontSize';
import ArrPosition from './components/positionOptions';
import ArrMargin from './components/margin';
import Position from './position';

import Context from '../../../../../../../../../../pages/public/terminal/context';

function Head() {
  const { data, dispatch } = useContext(Context);
  const {
    header, body, footer, logoComp, logoABT, button,
  } = data;
  const { Panel } = Collapse;

  const colors = ArrColor({ header, footer, body });
  const fontSize = ArrFontSize({
    header, footer, logoComp, logoABT, button,
  });

  const arrPosition = ArrPosition({ logoComp, logoABT });
  const arrMargin = ArrMargin({ logoComp, logoABT });

  return (
    <Collapse className="customize-Collapse" ghost>
      <Panel header="Варианты размещения" key="1">
        <PositionOptions arr={arrPosition} dispatch={dispatch}>
          <Position button={button} dispatch={dispatch} />
        </PositionOptions>
      </Panel>

      <Panel header="Размеры" key="2">
        <Height arr={fontSize} dispatch={dispatch} />
      </Panel>

      <Panel header="Внешние отступы" key="3">
        <Margin arr={arrMargin} dispatch={dispatch} />
      </Panel>

      <Panel className="customize-Collapse-Colors" header="Цвет" key="4">
        <Colors arr={colors} dispatch={dispatch} />
      </Panel>
    </Collapse>
  );
}

export default Head;
