import Access from '../../pages/private/router-function/Access';

export default function getLinks(right) {
  let role = '';
  try {
    role = window.localStorage.getItem('role');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  if (role) {
    role = role.split(',');
  } else {
    role = [];
  }

  const links = [];
  let write = false;
  if (right) {
    Access.forEach((el) => {
      const w = role.some((elem) => elem === el.name && el.write);
      if (w) write = w;
    });
    return write;
  }
  Access.forEach((el) => {
    role.forEach((elem) => {
      if (elem === el.name) {
        el.links.forEach((e) => !links.some((link) => link === e) && links.push(e));
      }
    });
  });
  if (links[0] === '/operator/') {
    links.splice(0, 1);
    links.push('/operator/');
  }
  if (links.indexOf('/operator/') >= 0 && !role.indexOf('eq_operator')) {
    links.splice(links.indexOf('/operator/'), 1);
    links.push('/operator/');
  }
  return links;
}
