import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import defaultFilters from '../../../components/ReportFilters/utils/defaultFilters';
import TableConfig from './TableConfig';
import PrepareTable from '../../../components/PrepareTable';
import ReportFilters from '../../../components/ReportFilters/ReportFilters';

function DynamicsServiceTime(props) {
  const [queryParams, setQueryParams] = useState();
  const { preloader } = props;
  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const input = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex, searchItemText) => ({
    // eslint-disable-next-line react/no-unstable-nested-components
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={input}
          placeholder={`Найти ${searchItemText}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Найти
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Очистить
          </Button>
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => input.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const preparQueryParams = async (filters) => {
    const columns = TableConfig(getColumnSearchProps); // Колонки для таблиц
    const params = {
      columns: columns.columns,
      filters: filters || defaultFilters(),
      url: '/api/reports/build',
      type: 'dynamicsServiceTimeReport',
    };
    setQueryParams(params);
  };

  useEffect(() => {
    preparQueryParams();
  }, [props]);

  return (
    queryParams ? (
      <>
        <ReportFilters
          showFilters={{
            main: ['dynamic'],
            others: [],
            orders: [],
          }}
          paramsCallback={preparQueryParams}
          type="dynamicsServiceTimeReport"
        />
        <PrepareTable
          keys="qId"
          type="DynamicsServiceTable"
          {...queryParams}
          preloader={preloader}
        />
      </>
    ) : null
  );
}

export default DynamicsServiceTime;
