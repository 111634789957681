import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import request from '../../../../logic/utils/request';
import { Title, ContentBox, PreloaderBox } from '../../../../common/ui/Common';
import ActionLogTable from './ActionLogTable';
import Filter from './Filter';

function ActionLog({ preloader }) {
  const defaultTableCofig = {
    page: 1,
    sizePerPage: 10,
    sortField: 'created_on',
    sortOrder: 'desc',
    totalSize: 0,
  };
  const [tableParams, setTableParams] = useState(defaultTableCofig);
  const [userList, setUserList] = useState(null);
  const [actionList, setActionList] = useState(null);
  const [filters, setFilters] = useState(null);

  const fetchUsers = useCallback(async () => {
    const res = await request('/server/api/user/', 'get');
    setUserList(res.data.data);
  }, []);

  const fetchActions = useCallback(async () => {
    const offset = tableParams.sizePerPage * (tableParams.page - 1);
    const sortObject = { field: tableParams.sortField, dir: tableParams.sortOrder };
    preloader();
    const res = await request('/server/api/useraction/', 'get', { }, '', tableParams.sizePerPage, offset, sortObject, filters || []);
    console.log('res === ', res);

    preloader();
    setActionList(res.data.data);
    setTableParams((v) => ({ ...v, totalSize: res.data.total }));
  }, [
    filters,
    preloader,
    tableParams.page,
    tableParams.sizePerPage,
    tableParams.sortField,
    tableParams.sortOrder,
  ]);

  const onSizePerPageChange = (pageNumber, sizePerPageNumber) => {
    setTableParams({
      ...tableParams,
      sizePerPage: sizePerPageNumber,
      page: pageNumber,
    });
  };

  const onPageChange = (pageNumber, sizePerPageNumber) => {
    setTableParams({
      ...tableParams,
      sizePerPage: sizePerPageNumber,
      page: pageNumber,
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (filters) fetchActions();
  }, [filters, fetchActions]);

  useEffect(() => {
    fetchActions();
  }, [
    tableParams.page,
    tableParams.sizePerPage,
    tableParams.sortField,
    tableParams.sortOrder,
    fetchActions,
  ]);

  const handleFilters = (filterList) => {
    setFilters(filterList);
    setTableParams({ ...tableParams, page: 1 });
  };

  const pagination = {
    page: tableParams.page,
    sizePerPage: tableParams.sizePerPage,
    totalSize: tableParams.totalSize,
    onPageChange,
    onSizePerPageChange,
  };

  // eslint-disable-next-line no-unused-vars
  const exportLogi = async () => {
    console.log('filters === ', filters);
    const params = {
      columns: [
        {
          dataIndex: 'created_on',
          title: 'Время',
        },
        {
          dataIndex: 'created_by_id',
          title: 'Пользователь',
        },
        {
          dataIndex: 'action',
          title: 'Выберите действие',
        },
        {
          dataIndex: 'comment',
          title: 'Выберите комментарий',
        },
      ],
      filters,
    };

    const token = window.localStorage.getItem('token');
    const url = '/api/reports/newexport';
    const res = await axios({
      method: 'post',
      url,
      data: {
        token,
        filters: { ...filters },
        type: 'userAction',
        exports: 'true',
      },
    });
    console.log('res === ', res);

    console.log('params === ', params);
  };

  const users = {};
  if (userList) {
    userList.forEach((user) => {
      users[Number(user.id)] = user.username;
    });
  }
  return (
    <>
      <Title>Лог действий</Title>
      {/* <button type="button" onClick={exportLogi}>Експортировать</button> */}
      <ContentBox>
        <Filter
          userList={userList}
          handleFilters={handleFilters}
        />
        <PreloaderBox>
          <ActionLogTable
            userList={userList}
            actionList={actionList}
            setUserList={setUserList}
            preloader={preloader}
            pagination={pagination}
          />
        </PreloaderBox>
      </ContentBox>
    </>
  );
}

export default ActionLog;
