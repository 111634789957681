import React from 'react';
import logo from '../../../../../../../common/images/logo.png';

function Preloader() {
  return (
    <div className="preloader">
      <div className="lines" />
      <img src={logo} alt="" />
    </div>
  );
}

export default Preloader;
