import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SqlQuery from './SqlQuery';
import TableConfig from './TableConfig';
import ReportTitle from '../../ReportTitle';
import Table from '../../../../../../../components/table/Table';
import { PreloaderBox } from '../../../../../../../../../common/ui/Common';
import Preloader from '../../../../../../../components/preloader/Preloader';

function TicketHistoryTable({ ticketId }) {
  const [ReportParams, setReportParams] = useState();
  const { reportConfig } = useSelector((state) => state);
  useEffect(() => {
    SqlQuery(ticketId).then((resp) => {
      const { dataObjects, query } = resp;
      const params = {
        items: dataObjects,
        query,
        TableConfig: TableConfig(),
      };
      setReportParams(params);
    });
  }, [reportConfig, ticketId]);

  return (ReportParams !== undefined
    ? (
      <PreloaderBox>
        <ReportTitle
          item={ReportParams.items.length}
          name={`история талона № ${ReportParams.items[0][3]}`}
          query={ReportParams ? ReportParams.query : null}
        />
        <Table
          keys="1"
          data={ReportParams.items}
          columns={ReportParams.TableConfig.columns}
          disablePagination
        />
      </PreloaderBox>
    )
    : <Preloader isOpen small />
  );
}

export default TicketHistoryTable;
