import React from 'react';
import { ReactComponent as Edit } from '../../../../../../../../common/images/edit.svg';
import c from '../../../../../../../../common/ui/theme/Colors';
import sortCaret from '../../../utils/sortCaret';

function priceFormatter(column, colIndex, { filterElement, sortElement }, sort) {
  return (
    <div className={`filterBoxTable ${sort ? 'sort' : null}`}>
      {column.dataField === '3' || column.dataField === '4' || column.dataField === '6' ? null : (<Edit style={{ margin: '2px 10px 0 0' }} fill={c.main} />) }
      { filterElement || column.text }
      { sortElement }
    </div>
  );
}

const headerFormatter = (
  column,
  colIndex,
  {
    filterElement,
    sortElement,
  },
) => priceFormatter(
  column,
  colIndex,
  { filterElement, sortElement },
  true,
);

export default function TableConfig() {
  return {
    columns: [
      {
        dataField: '3',
        text: 'Вопрос',
        sort: false,
        editable: false,
        headerFormatter,
      },
      {
        dataField: '6',
        text: 'Тип вопроса',
        sort: false,
        editable: false,
        formatter: (cell) => (cell === '2' ? 'Цифровой' : 'Текстовый'),
        headerFormatter,
        headerStyle: () => ({ width: '200px' }),
      },
      {
        dataField: '4',
        text: 'Ответ',
        sortCaret,
        editable: false,
        sort: true,
        headerFormatter,
        headerStyle: () => ({ width: '100px' }),
      },
    ],
  };
}
