import styled from 'styled-components';

export const CalendarWrapper = styled.div`
  width: 100%;
`;
export const DefaultWeekBox = styled.div`
  flex: 1;
  display: flex;
`;
export const CalendarYear = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  h1 {
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.text};
  }
  h1 svg {
    padding: 0 15px;
    width: 45px;
    cursor: pointer;
    &:hover {
      path {
        fill: ${(p) => p.theme.accent};
      }
    }
    path {
      fill: ${(p) => p.theme.accentl};
    }
  }
`;
export const CalendarBody = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const ShadowChevron = styled.div`
  width: 45px;
`;
export const MountBox = styled.div`
  //margin: 7.5px;
  overflow: hidden;
  margin: 5px;
  width: calc(100% / 4 - 10px);
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  &:nth-child(2n) {
    background-color: ${(p) => p.theme.bgTable};
  }
`;
export const Mount = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Title = styled.div`
  padding: 5px 0;
  text-align: center;
  background-color: ${(p) => p.theme.buttonHover};
`;
export const ShadowDiv = styled.div`
  width: calc(100% / 7);
  background: transparent;
`;
export const InputBox = styled.div`
   display: flex;
   flex-direction: column;
   margin-bottom: 15px;
   p {
    color: ${(p) => p.theme.text};
    margin-bottom: 10px;
   }
`;

const noModifiedDay = (p) => (p.weekend ? p.theme.accentl : 'transparent');

export const Day = styled.div`
  text-align: center;
  width: calc(100% / 7);
  padding: 4px;
  background-color: ${(p) => (p.modified ? p.theme.green : noModifiedDay(p))};
  cursor: pointer;
  transition: 0.10s ease-in;
  color: ${(p) => (p.modified ? p.theme.buttonHover : p.theme.text)};
  &:hover {
    color: #fff;
    background-color: ${(p) => p.theme.accent};
  }
`;
export const WeekName = styled.div`
  display: flex;
  background: ${(p) => p.theme.buttonHover};
  border-bottom: ${(p) => `2px solid ${p.theme.accent}`};
  div {
    text-align: center;
    width: calc(100% / 7);
    padding: 3px;
  }
`;
export const CalendarBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;
