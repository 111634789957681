import React from 'react';

export default React.createContext({
  dispatch: () => { },
  dispatchElement: () => { },
  data: {},
  fonts: [],
  element: '',
  layout: '',
  ticket: {},
  saveConfig: () => { },
});
