import React, { useState } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { PolicyContainer } from '../../../../../utils/style';
import Input from '../../../../../../../../../common/ui/Input/input';
import Keyboard from '../../../../common/keyboard';
import PolicyBB from './policyBB';
import { updateScanPolicyStore } from '../../../../../../../../../logic/store/pullstate/scanPolicy';

function PolicyInner({
  input, button, startScreen,
}) {
  const [data, setData] = useState({ number: '', date: '' });

  const Authorization = async () => {
    const { number, date } = data;
    const bDate = moment(date, 'DDMMYYYY').format('YYYY-MM-DD');
    Axios.get(
      `/medsoft?polisNumber=${number}&birthDate=${bDate}`,
      {
        timeout: 15000,
      },
    )
      .then((response) => {
        if (response.status === 200) {
          updateScanPolicyStore({ activeScreen: 'list', records: response.data });
        } else {
          updateScanPolicyStore({ activeScreen: 'error', errorText: 'Мы сожалеем,<br/> сервер временно недоступен' });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          switch (err.response.data.errorMessage.eng) {
            case 'Validation failed':
              updateScanPolicyStore({ activeScreen: 'error', errorText: 'Мы сожалеем,<br/> данный полис не найден' });
              break;
            case 'posts not found':
              updateScanPolicyStore({ activeScreen: 'error', errorText: 'Записи на сегодня<br/> по данному полису отсутствуют' });
              break;
            default:
              updateScanPolicyStore({ activeScreen: 'error', errorText: err.response.data.errorMessage.rus });
          }
        } else {
          updateScanPolicyStore({ activeScreen: 'error', errorText: 'Мы сожалеем,<br/> сервер временно недоступен' });
        }
      });
  };

  const [sInput, setInput] = useState({ type: 'number', value: '' });

  const inputs = [
    {
      name: 'Номер полиса', param: 'number', type: 2, typeKey: 'number',
    },
    {
      name: 'Дата', param: 'date', type: 3, typeKey: 'number',
    },
  ];

  const setValue = (value) => {
    setData((v) => ({ ...v, [sInput.param]: value }));
  };

  const onClickInput = (param, type) => {
    setInput({ param, type });
  };

  return (
    <PolicyContainer>
      <div className="policyBox">
        {inputs.map((el) => (
          <Input
            key={el.param}
            item={el}
            style={input}
            value={data[el.param]}
            id={el.param}
            type={el.type}
            clickInput={(v) => onClickInput(v, el.typeKey)}
          />
        ))}
        <Keyboard
          styleButton={button}
          login={50}
          id={sInput.type}
          width={button.width}
          setValue={setValue}
          value={data[sInput.param]}
          polisy
        />
      </div>
      <PolicyBB startScreen={startScreen} button={button} isInner Authorization={Authorization} />
    </PolicyContainer>
  );
}

export default PolicyInner;
