export default ({ header }) => [
  {
    title: 'Заголовок', value: header.fontSizeHeaderMain, paramName: 'fontSizeHeaderMain', parentName: 'header',
  },
  {
    title: 'Подзаголовок', value: header.fontSizeHeaderSub, paramName: 'fontSizeHeaderSub', parentName: 'header',
  },
  {
    title: 'Текст справа', value: header.fontSize1, paramName: 'fontSize1', parentName: 'header',
  },
  {
    title: 'Время', value: header.fontSize2, paramName: 'fontSize2', parentName: 'header',
  },
  {
    title: 'Дата', value: header.fontSize3, paramName: 'fontSize3', parentName: 'header',
  },
];
