import React, {
  useState,
} from 'react';
import { Table } from 'antd';
import { EmptyList } from '../../../../common/ui/Common';
import Config from './TableConfig';
import Preloader from '../../../../pages/private/components/preloader/Preloader';
import DeleteWarning from '../../../../common/ui/Modal/DeleteWarning';
import { useToggle } from '../../../../logic/hooks/useToggle';
import DeleteItem from '../../../../pages/private/utils/deleteItem';
import { useSearchPropsFactory } from '../../../../pages/private/utils/useSearch';

function DisplaysTable({
  displayList,
  setDisplayUpdate,
  setDisplayData,
  toggleOpen,
  preloader,
  fetchDisplays,
  showError,
}) {
  const [displayId, setDisplayId] = useState(false);
  const [displayName, setDisplayName] = useState(false);
  const [showDelWarn, setShowDelWarn] = useToggle(false);
  const searchPropsFactory = useSearchPropsFactory();

  const delDisplay = async () => {
    await DeleteItem({
      preloader,
      url: `/server/api/digitboard/${displayId}/`,
      fetchData: fetchDisplays,
      setShowWarn: setShowDelWarn,
      setId: setDisplayId,
      showError,
    });
  };

  const handleTableIcon = (e, row) => {
    const {
      dataset,
    } = e.target;
    const {
      id,
      name,
      address,
      number,
      enabled,
      type,
      config,
    } = row;
    if (dataset.name === 'del') {
      setDisplayId(id);
      setDisplayName(name);
      setShowDelWarn();
    } else if (dataset.name === 'update') {
      setDisplayUpdate(true);
      setDisplayData({
        id,
        name,
        address,
        number,
        enabled,
        type,
        config,
      });
      toggleOpen();
    }
  };

  const Setting = Config(searchPropsFactory);
  const {
    columns,
  } = Setting;

  const viewDisplays = () => (displayList.length
    ? (
      <Table
        dataSource={displayList}
        handleIcon={handleTableIcon}
        columns={columns}
        rowKey="id"
        onRow={(row) => ({
          onClick: (e) => { handleTableIcon(e, row); },
        })}
      />
    ) : <EmptyList> Внешних устройств нет </EmptyList>);

  return (
    <>
      {!displayList
        ? (<Preloader isOpen small />)
        : viewDisplays()}
      {' '}
      <DeleteWarning
        confirmDel={delDisplay}
        name={displayName}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </>
  );
}

export default React.memo(DisplaysTable);
