import React, { useState, useEffect } from 'react';
import moment from 'moment';

const getCurrentDate = () => ({
  time: moment().format('LT'),
  date: moment().format('DD/MM/YYYY'),
  dotDate: moment().format('DD.MM.YYYY'),
});

function TimerHeader({ styles, inline, withDots }) {
  const {
    fontSize3: dateFZ,
    fontSize2: timeFZ,
    fontFamily,
    color2,
  } = styles;

  const [timeConfig, setTimeConfig] = useState(getCurrentDate());
  const { time, date, dotDate } = timeConfig;

  useEffect(() => {
    const timerMin = setInterval(() => {
      setTimeConfig(getCurrentDate());
    }, 1000);
    return () => clearInterval(timerMin);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: `${inline ? 'row' : 'column'} nowrap`,
        padding: '0 10px',
        gap: 10,
      }}
    >
      <div
        style={{
          fontSize: `${timeFZ}px`,
          fontFamily,
          color: color2,
          height: `${Number(timeFZ)}px`,
        }}
      >
        {time}
      </div>
      <div style={{ fontSize: `${dateFZ}px`, fontFamily, color: color2 }}>
        {withDots ? dotDate : date }
      </div>
    </div>
  );
}

export default TimerHeader;
