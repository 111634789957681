import React from 'react';

const fontSize = ({
  dispatch, element, value, paramName, rusName,
}) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className="box__color">
    {/* eslint-disable-next-line react/no-danger */}
    <span className="settings__styles__el__label" dangerouslySetInnerHTML={{ __html: rusName }} />
    <input
      className="input"
      style={{
        textAlign: 'center',
        fontSize: '16px',
        width: 'auto',
        margin: 0,
      }}
      onChange={(e) => dispatch({
        type: 'params',
        name: element,
        param: paramName,
        value: Number(e.target.value),
      })}
      value={value}
    />
  </label>
);

export default fontSize;
