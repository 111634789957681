import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Button, Space, Tabs } from 'antd';
import { useNavigate } from 'react-router-dom';
import Modal from '../../../../../common/ui/Modal/Modal';
import Config from './Config';
import Settings from './Settings';
import request from '../../../../../logic/utils/request';
import ModalButtons from '../../../../../common/ui/Modal/ModalButtons';

function NewDevice({
  isOpen,
  toggleOpen,
  fetchDevices,
  deviceUpdate,
  setDeviceData,
  deviceData,
  initialData,
  setDeviceUpdate,
  preloader,
  showError,
}) {
  const [isValid, setIsValid] = useState(false);
  const [display, setDisplay] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const {
      ipaddr: iV, port: pV, name: nV, type: tV,
    } = deviceData;
    if (iV && pV && nV && tV) {
      setIsValid(true);
    }
  }, [deviceData, setIsValid]);
  const fetchDevice = useCallback(async () => {
    if (!deviceData.id) {
      return;
    }
    preloader();
    try {
      const res = await request(`/server/api/satellite/${deviceData.id}/`, 'get');
      res.data.data.zoneId = res.data.data.zone ? res.data.data.zone.zoneId : null;
      setDeviceData(res.data.data);
    } catch (e) {
      showError(e.data.error);
    }
    preloader();
  }, [deviceData.id, preloader, showError, setDeviceData]);

  useEffect(() => {
    fetchDevice();
  }, [fetchDevice]);

  const resetModal = () => {
    setDeviceData({ ...initialData });
    toggleOpen();
    setDeviceUpdate(false);
    setIsValid(false);
  };

  const sendConfig = async (id) => {
    const { origin } = window.location;
    const {
      type, ipaddr, port, config, uuid,
    } = deviceData;
    let urlPage = `${origin}/satellite/${type}/?id=${id}`;
    if (type === 'board' || type === 'terminal') {
      urlPage = `${origin}/${type}/${id}/`;
    }
    let sUri = `/proxySatellite/gate/window/${uuid}`;
    if (!uuid) {
      sUri = '/proxySatellite/gate/config'; // old satellite
    }
    try {
      await axios({
        method: 'post',
        url: sUri,
        data: {
          urlPage,
          satelliteIp: ipaddr,
          satellitePort: port,
          alwaysOnTop: config.alwaysOnTop,
        },
      });
    } catch (e) {
      showError(e.data.error);
    }
  };

  const addDevice = async () => {
    const newDevice = { ...deviceData };
    if (isValid) {
      preloader();
      try {
        if (newDevice.type === 'tablet') {
          const res = await axios({
            method: 'post',
            url: '/server/public/surveys',
            data: {},
          });
          newDevice.survey_id = res.data.data[0].id;
        }
        const resp = await request('/server/api/satellite/', 'add', { ...newDevice });
        await sendConfig(resp.data.data.newCreatedId);
        navigate(`/devices/${resp.data.data.newCreatedId}/1`);
      } catch (e) {
        showError(e.data.error);
      }
      preloader();
    }
  };

  const updateDevice = async () => {
    if (isValid) {
      const itemId = deviceData.id;
      // eslint-disable-next-line no-param-reassign
      delete deviceData.id;
      preloader();
      const res = await request(`/server/api/satellite/${itemId}/`, 'update', { ...deviceData });
      sendConfig(res.data.data.updatedId);
      fetchDevices();
      resetModal();
      preloader();
    }
  };

  return (
    <>
      <Button type="primary" size="large" onClick={toggleOpen}>Добавить</Button>
      <Modal title={deviceUpdate ? 'Редактирование устройства' : 'Добавление устройства'} isOpen={isOpen} toggleOpen={resetModal}>
        <Space style={{ width: '100%' }} direction="vertical" size="middle">
          <Tabs style={{ width: '100%' }}>
            <Tabs.TabPane tab="Основные настройки" key="settings">
              <Settings
                name="Основные настройки"
                setDeviceData={setDeviceData}
                deviceData={deviceData}
                setIsValid={setIsValid}
                preloader={preloader}
                showError={showError}
                display={display}
                setDisplay={setDisplay}
              />
            </Tabs.TabPane>
            {(deviceData.config.style || deviceData.config.layout)
              && (
                <Tabs.TabPane tab="Конфигурация" key="config">
                  <Config
                    setIsValid={setIsValid}
                    data={deviceData}
                    setData={setDeviceData}
                    name="Конфигурация"
                  />
                </Tabs.TabPane>
              )}
          </Tabs>
          <ModalButtons
            isValid={isValid}
            update={deviceUpdate}
            updateFunc={updateDevice}
            addFunc={addDevice}
            reset={resetModal}
          />
        </Space>
      </Modal>
    </>
  );
}

export default NewDevice;
