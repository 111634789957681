import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ModalBackground, ModalBox, ModalHeader, ModalTitle, ModalBody, ModalContentBox,
} from '../Common2';
import getLinks from '../../../logic/utils/getLinks';

const duration = 300;

function Portal({
  onClose, title, children, className, width,
}) {
  const write = getLinks(true);
  const modalMarkup = (
    <ModalBackground className={className} onMouseDown={onClose}>
      <ModalBox width={width} onMouseDown={(e) => e.stopPropagation()}>
        <ModalContentBox width={width}>
          <ModalHeader>
            <ModalTitle>{write ? title : 'Просмотр'}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {children}
          </ModalBody>
        </ModalContentBox>
      </ModalBox>
    </ModalBackground>
  );

  return ReactDOM.createPortal(modalMarkup, document.body);
}

Portal.propTypes = {
  /**
   * Child nodes
   */
  children: PropTypes.node.isRequired,
  /**
   * Close handler for modal button
   */
  onClose: PropTypes.func,
  /**
   * Title text to show in header
   */
  title: PropTypes.string.isRequired,
};

Portal.defaultProps = {
  onClose: null,
};

export default Portal;

export const ModalWithTransitionStyles = styled(Portal)`
  &.modal-transition-enter {
    opacity: 0;
  }
  &.modal-transition-enter-active {
    transition: opacity ${duration}ms;
    opacity: 1;
  }
  &.modal-transition-exit {
    opacity: 1;
  }
  &.modal-transition-exit-active {
    transition: opacity ${duration}ms;
    opacity: 0;
  }
  .tabHeader {
    transition: 0.1s ease-out !important;
    &:hover {
      background: ${(p) => p.theme.buttonAccent};
      color: ${(p) => p.theme.buttonHover};
      opacity: 1 !important;
    }
   }
  .tabHeader.activeTab {
    background: ${(p) => p.theme.buttonAccent};
    color: ${(p) => p.theme.buttonHover};
  }
`;
