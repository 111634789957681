const DropdownConfig = () => ({
  headerTitleText: 'Начиная с меньшего',
  options: {},
  items: [
    { key: '0', name: 'ASC', text: 'Начиная с меньшего' },
    { key: '1', name: 'DESC', text: 'Начиная с большего' },
  ],
}
);

export default DropdownConfig;
