import React, {
  useState, useEffect,
} from 'react';
import { connect } from 'react-redux';
import { Checkbox } from 'antd';
import defaultFilters from '../../../components/ReportFilters/utils/defaultFilters';
import TableConfig from './TableConfig';
import PrepareTable from '../../../components/PrepareTable';
import ReportFilters from '../../../components/ReportFilters/ReportFilters';
// import { getColumnSearchProps } from './getColumnsSearchProps';
// import request from '../../../../../../../../logic/utils/request';

function QueueTable(props) {
  const [queryParams, setQueryParams] = useState();
  //  const [reportMinMaxTime, setReportMinMaxTime] = useState();
  const { preloader } = props;

  const [hiddenZeroValues, setHiddenZeroValues] = useState(
    localStorage.getItem('hiddenZeroValues') ? localStorage.getItem('hiddenZeroValues') : true,
  );

  //  const [searchText, setSearchText] = useState();
  //  const [searchedColumn, setSearchedColumn] = useState();
  //  const input = useRef(null);

  //  const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //    confirm();
  //    setSearchText(selectedKeys[0]);
  //    setSearchedColumn(dataIndex);
  //  };

  //  const handleReset = (clearFilters) => {
  //    clearFilters();
  //    setSearchText('');
  //  };

  const preparQueryParams = async (filters) => {
    // if (reportMinMaxTime?.min) {
    const { columns } = TableConfig();

    console.log('columns === ', columns);
    const params = {
      columns,
      filters: filters || defaultFilters(),
      url: '/api/reports/build',
      type: 'queueReport',
    };
    setQueryParams(params);
    // }
  };

  //  const getServerParams = async () => {
  //    const res = await request('/server/api/officeparams/');
  //    const { data: { data: paramsData } } = res;
  //    const time = {};
  //    paramsData.forEach((el) => {
  //      if (el.param === 'qeueReportMaxTime') time.max = el.value;
  //      if (el.param === 'qeueReportMinTime') time.min = el.value;
  //    });
  //    setReportMinMaxTime(time);
  //  };

  //  console.log('setReportMinMaxTime === ', reportMinMaxTime);

  //  useEffect(() => {
  //    getServerParams();
  //  }, []);

  useEffect(() => {
    preparQueryParams();
  }, []);

  return (
    queryParams ? (
      <>
        <ReportFilters
          showFilters={{
            main: ['reportRange', 'queues'],
            others: ['servedfilters', 'waitTimeFilters', 'procTimeFilter', 'actionFilters'],
            orders: ['queue_name', 'all_cnt', 'close_cnt', 'neyavka_cnt', 'total_wait_time', 'avg_wait_time', 'total_proc_time', 'avg_proc_time'],
          }}
          hiddenZeroValues={hiddenZeroValues}
          paramsCallback={preparQueryParams}
          type="queueReport"
        />

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '10px 0',
        }}
        >
          <Checkbox
            defaultChecked={hiddenZeroValues}
            onChange={(e) => {
              setHiddenZeroValues(e.target.checked);
              localStorage.setItem('hiddenZeroValues', e.target.checked);
            }}
          >
            Скрыть нулевые значения
          </Checkbox>
        </div>

        <PrepareTable
          hiddenZeroValues={hiddenZeroValues}
          keys="queue"
          type="QueueTable"
          {...queryParams}
          preloader={preloader}
        />
      </>
    ) : null

  );
}

function mapStateToProps(state) {
  return { reportConfig: state.reportConfig };
}

export default connect(mapStateToProps)(QueueTable);
