import iconFormatter from '../../../utils/iconFormatter';
import { useSearchPropsFactory } from '../../../utils/useSearch';

export default function TableConfig() {
  const searchPropsFactory = useSearchPropsFactory();

  return [
    {
      dataIndex: 'id',
      title: 'ID',
      key: 'id',
      ...searchPropsFactory('id', 'id'),
    },
    {
      dataIndex: 'name',
      title: 'Имя',
      key: 'name',
      ...searchPropsFactory('name', 'Имя'),
    },
    {
      dataIndex: 'longname',
      title: 'Наименование',
      ...searchPropsFactory('longname', 'Наименование'),
    },
    {
      dataIndex: 'description',
      title: 'Описание',
      ...searchPropsFactory('description', 'Описание'),
    },
    {
      dataIndex: 'Settings',
      width: 150,
      render: () => iconFormatter({
        filter: false, write: true, params: false, styles: true,
      }),
    },
  ];
}
