import React, { useEffect, useState } from 'react';
import {
  Drawer, Button, Row, Col,
} from 'antd';
import ZonesTable from './ZonesTable';
import { Title } from '../../../../common/ui/Common';
import defaultValues from './utils/defaultValues';
import api from './utils/api';
import ZoneForm from './ZoneForm';

function Zones() {
  const [zonesList, setZoneList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [zoneData, setZoneData] = useState(defaultValues.zone);

  const fetchZones = async () => {
    const data = await api.getZones();
    setZoneList(data);
  };

  useEffect(() => {
    fetchZones();
  }, []);

  const closeDrawer = () => {
    setIsOpen(false);
    setZoneData(defaultValues.zone);
  };

  return (
    <>
      <Title>ЗОНЫ ОБСЛУЖИВАНИЯ</Title>
      <Row>
        <Col span={4}>
          <Button onClick={() => setIsOpen(true)} size="large" type="primary" block>Добавить</Button>
        </Col>
      </Row>
      <Drawer
        destroyOnClose
        open={isOpen}
        onClose={closeDrawer}
        title={zoneData?.id ? `РЕДАКТИРОВАНИЕ ЗОНЫ ОБСЛУЖИВАНИЯ - ${zoneData.id}` : 'ДОБАВЛЕНИЕ ЗОНЫ ОБСЛУЖИВАНИЯ'}
      >
        <ZoneForm
          zoneData={zoneData}
          setIsOpen={setIsOpen}
          fetchZones={fetchZones}
        />
      </Drawer>
      <ZonesTable
        data={zonesList}
        fetchZones={fetchZones}
        setZoneData={setZoneData}
        setIsOpen={setIsOpen}
      />
    </>
  );
}

export default Zones;
