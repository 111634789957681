/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useRef } from 'react';
import {
  Button, Input, Space, Table,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import locale from 'antd/es/locale/ru_RU';
import Config from './TableConfig';
import DeleteWarning from '../../../../../common/ui/Modal/DeleteWarning';
import { useToggle } from '../../../../../logic/hooks/useToggle';
import DeleteItem from '../../../../../pages/private/utils/deleteItem';

function QuestionsTable({
  questList, setQuestData,
  setQuestUpdate, toggleOpen, preloader, fetchQuests, queueList, showError,
}) {
  const [showDelWarn, setShowDelWarn] = useToggle(false);
  const [serviceId, setServiceId] = useState(false);
  const [serviceName, setServiceName] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder="Введи для поиска"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Найти
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Сбросить
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter:
    (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const delService = async () => {
    await DeleteItem({
      preloader,
      url: `/server/api/servedquestion/${serviceId}`,
      fetchData: fetchQuests,
      setShowWarn: setShowDelWarn,
      setId: setServiceId,
      showError,
    });
  };

  const deleteQuestion = (row) => {
    const { id, name } = row;
    setServiceId(id);
    setServiceName(name);
    setShowDelWarn();
  };

  const updateQuestion = (row) => {
    const {
      id, description, name, displayname, connectedQueues, regulatoryTime,
    } = row;
    setQuestData({
      id, description, name, displayname, connectedQueues, regulatoryTime,
    });
    setQuestUpdate(true);
    toggleOpen();
  };

  const Setting = Config(queueList, deleteQuestion, updateQuestion, getColumnSearchProps);
  const { columns } = Setting;

  return (
    <>
      {questList.length > 0
          && (
            <Table columns={columns} dataSource={questList} locale={locale.Table} rowKey="id" />
          )}
      <DeleteWarning
        name={serviceName}
        confirmDel={delService}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </>
  );
}

export default React.memo(QuestionsTable);
