export default ({ video }) => [
  {
    title: 'Текст', value: video.color1, paramName: 'color1', parentName: 'video',
  },
  {
    title: 'Рамка', value: video.color2, paramName: 'color2', parentName: 'video',
  },
  {
    title: 'Фон', value: video.background, paramName: 'background', parentName: 'video',
  },
];
