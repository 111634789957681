const getConfig = (o1, o2) => {
  const newObject = JSON.parse(JSON.stringify(o1));
  const recurse = (obj1, obj2) => {
    Object.keys(obj1).forEach((el) => {
      const elem = obj1[el];
      if (obj2[el] !== undefined) {
        if (typeof elem === 'object') {
          recurse(elem, obj2[el]);
        } else {
          // eslint-disable-next-line no-param-reassign
          obj1[el] = obj2[el];
        }
      }
    });
  };
  recurse(newObject, o2);
  return newObject;
};

export default getConfig;
