import React, { useContext, useMemo } from 'react';
import BoardCtx from '../../../../../../../../pages/public/board/utils/BoardCtx';
import Header from './header';
import Ticker from './footer';
import Columns from './columns';
import Big from './big';
import Time from './time';
import Video from './video';
import News from './news';

function StyleSettings() {
  const { activeEl, layout, text } = useContext(BoardCtx);

  const renderSettings = useMemo(() => {
    switch (activeEl) {
      case 'header':
        return <Header />;
      case 'footer':
        return <Ticker />;
      case 'columns':
        return <Columns />;
      case 'big':
        return <Big />;
      case 'time':
        return <Time />;
      case 'news':
        return <News />;
      case 'video':
        return <Video />;
      default:
        return null;
    }
  }, [activeEl]);

  return (
    <div className="settings__styles">
      {layout[activeEl] && text && renderSettings}
    </div>
  );
}
export default StyleSettings;
