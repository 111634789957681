import { useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import axios from 'axios';
import request from '../../../../../logic/utils/request';

export function useAbout() {
  const [info, setInfo] = useState({});
  const [time, setTime] = useState({});

  const intervalBox = useRef(null);

  const timer = (data) => {
    const { eqServerLoadedOn } = data;
    const currentTime = moment();
    const durat = moment.duration(currentTime.diff(moment(eqServerLoadedOn, 'YYYY.MM.DD HH:mm:ss')));
    const days = durat.get('days');
    const hours = durat.get('hours');
    const min = durat.get('minutes');
    const sec = durat.get('seconds');
    setTime({
      days, hours, min, sec,
    });
  };

  const fetchInfo = async () => {
    const res = await request('/server/public/getVersions/', 'post', {});
    const fVersions = await axios.get('/versions');
    const { data: { versions } = undefined } = fVersions;
    const { latest: fLatest = {}, current: fCurrent = {} } = versions;
    intervalBox.current = setInterval(() => timer(res.data.data), 1000);
    setInfo({ ...res.data.data, fLatest, fCurrent });
  };

  useEffect(() => {
    fetchInfo();
    return () => {
      clearInterval(intervalBox.current);
    };
  }, []);

  return {
    ...time,
    ...info,
    info,
    arrData: [
      { title: 'Версия B-сервера', text: 'version_baq' },
      { title: 'Версия F-сервера', text: 'fCurrent', extra: 'server' },
      { title: 'Версия CL библиотек', text: 'version_cl' },
      { title: 'Версия базы данных', text: 'baqServerDbVersion' },
      { title: 'Последнее обновление БД', text: 'dbLastUpgradedOn', date: true },
    ],
    viewNoDate(el) {
      return el.extra && info[el.text]
        ? info[el.text][el.extra]
        : info[el.text];
    },
  };
}

export default null;
