import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Title } from '../../../common/ui/Common';
import WebSocketClient from '../../utils/WebSocketClient';
import LoginPanel from './LoginPanel';
import OperatorPanel from './OperatorPanel';
import useInterval from '../../utils/useInterval';
import { ErrorMessage } from '../../styles/Operator';

function Operator() {
  const [wsConnected, setWsConnected] = useState(false);
  const [wsClient, setWsClient] = useState(null);
  const [wplacesList, setWplacesList] = useState([]);
  const [rmoConnected, setRmoConnected] = useState(false);
  const [rmoSignedIn, setRmoSignedIn] = useState(false);
  const [error, setError] = useState([]);

  const { hostname, port, protocol } = window.location;
  const debug = (hostname.indexOf('localhost') > -1);
  const wsHost = (debug) ? 'localhost' : hostname;
  const wsPort = (debug) ? '3030' : port;
  const isSecure = (debug) ? true : (protocol === 'https:');
  const rmoAuth = () => {
    setWsConnected(true);
  };

  const rmoLogout = () => {
    setWsConnected(false);
    setRmoConnected(false);
    setRmoSignedIn(false);
    setWplacesList([]);
  };

  const rmoConnect = useCallback(async (clientType) => {
    const rmo = await wsClient.sendMessage({
      command: 'cWSSetClientType',
      clientType,
    });
    setRmoConnected((rmo.status === 'ok'));
    return (rmo.status === 'ok');
  }, [wsClient]);

  const getWplaces = useCallback(async () => {
    // eslint-disable-next-line no-console
    console.info('Try to get WPLACEs');
    const rmoData = await wsClient.sendMessage({
      command: 'rmoGetLoginsAndWplaces',
    });
    const { wplaces } = rmoData;
    setWplacesList(wplaces);
  }, [wsClient]);

  useEffect(() => {
    const newWsClient = new WebSocketClient(wsHost, wsPort, isSecure, rmoAuth, rmoLogout);
    setWsClient(newWsClient);
    return () => newWsClient.disconnect();
  }, [isSecure, wsHost, wsPort]);

  useEffect(() => {
    if (wsConnected && !rmoConnected) {
      rmoConnect('rmo');
    } else if (rmoConnected && wsConnected) {
      getWplaces();
    }
  }, [wsConnected, rmoConnected, getWplaces, rmoConnect]);

  useInterval(() => {
    if (wsConnected) {
      wsClient.sendKeepAlive();
    }
  }, 5000);

  const rmoLogin = async (wplace) => {
    try {
      const rmoLoginRequest = await wsClient.sendMessage({
        command: 'rmoLoginClientWithHttpToken',
        userWplace: wplace,
        httpSessionToken: window.localStorage.getItem('token'),
      });
      const { status, message } = rmoLoginRequest;
      if (status === 'ok') {
        // setWplaceId(rmoLoginRequest.wplaceId);
        setRmoSignedIn(true);
        setError('');
      } else {
        throw new Error(message);
      }
    } catch (e) {
      const { text: { rus } } = e;
      toast.error(rus, 3000);
    }
  };

  const viewConnected = rmoSignedIn
    ? <OperatorPanel wsClient={wsClient} />
    : <LoginPanel wplaces={wplacesList} rmoLogin={rmoLogin} />;

  return (
    <>
      <Title>Пульт оператора</Title>
      <a className="addButton" href="/downloads/operator/aq-operator-windows.zip" download target="_blank">Скачать пульт оператора</a>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {rmoConnected
        ? viewConnected
        : 'Нет подключения к серверу'}
    </>
  );
}

export default Operator;
