import React, { useState, useEffect } from 'react';
import CheckBoxList from '../../../../../../../pages/private/components/CheckBoxList';

const RenderCheckList = ({
  dayData, setDayData, intervals,
}) => {
  const { workIntervals } = dayData;
  const [checkedQueue, setCheckedDay] = useState([]);

  useEffect(() => {
    setCheckedDay([...workIntervals]);
  }, [workIntervals]);

  const items = [
    {
      label: 'Интервалы:', name: 'workIntervals', options: intervals.data, checked: checkedQueue,
    },
  ];

  return (
    items.map((item) => (
      <div key={item.name}>
        <CheckBoxList
          item={item}
          data={dayData}
          setData={setDayData}
        />
      </div>
    ))
  );
};
export default RenderCheckList;
