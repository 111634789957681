import React from 'react';
import { useSelector } from 'react-redux';
import { Body, BodyContainer, TextHelp } from '../../utils/style';
import BottomButtons from '../common/bottomButtons';

export default function InfoScene({
  style, history, startScreen,
}) {
  const info = useSelector((state) => state.infoScreen.value);
  const text = info ? info.replace(/\/n/g, '<br/>') : null; // Обработка текста
  const extraTitle = { ...style.extraTitle };
  extraTitle.marginBottom = 50;
  extraTitle.width = style.button.width;
  return (
    <BodyContainer style={style.body}>
      <Body>
        <TextHelp style={extraTitle}>{text}</TextHelp>
        {BottomButtons({
          history, style: style.bottomButton, scene: {}, startScreen,
        })}
      </Body>
    </BodyContainer>
  );
}
