import React from 'react';
import Select from '../../../../../../../pages/private/components/Input/SelectInput';

import { SelectGroup, Input, Label } from '../../../../../../../common/ui/Common';

function RenderInputs({
  weekData, setWeekData, dayList, write,
}) {
  const handleInput = (e) => {
    const { name, value } = e.target;
    const validData = { ...weekData, [name]: value || 0 };
    setWeekData({ ...validData });
  };
  const items = [
    {
      label: 'Наименование:', input: true, value: weekData.name || '', id: 'name', required: true,
    },
    {
      label: 'Описание:', input: true, value: weekData.description || '', id: 'description', required: true,
    },
    { label: 'Понедельник:', id: 'monday_id' },
    { label: 'Вторник:', id: 'tuesday_id' },
    { label: 'Среда:', id: 'wednesday_id' },
    { label: 'Четверг:', id: 'thursday_id' },
    { label: 'Пятница:', id: 'friday_id' },
    { label: 'Суббота:', id: 'saturday_id' },
    { label: 'Воскресение:', id: 'sunday_id' },
  ];

  const options = dayList.data.map((el) => ({ id: el.id, name: el.name }));
  return (
    <>
      {items.map((item) => (
        item.input === true
          ? (
            <SelectGroup key={item.label}>
              <Label isRequired={item.required}>{item.label}</Label>
              <Input
                disabled={!write}
                value={item.value}
                onChange={(e) => handleInput({
                  target: {
                    name: item.id,
                    value: e.target.value,
                  },
                })}
              />
            </SelectGroup>
          )
          : (
            <SelectGroup key={item.label}>
              <Label isRequired={item.required}>{item.label}</Label>
              <Select
                item={item}
                disabled={!write}
                name={item.id}
                value={weekData[item.id]}
                options={options}
                onChange={handleInput}
              />
            </SelectGroup>
          )))}

    </>
  );
}
export default RenderInputs;
