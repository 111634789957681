import React from 'react';
import {
  Wrapper, Loader, Logo, LocalWrapper, LogoBox,
} from '../../../../common/ui/Common3';

export function Animate({ notZoneId }) {
  return (
    <LogoBox>
      <Loader />
      <Logo />
      {notZoneId && <p>Отсутствует зона обслуживания.</p>}
    </LogoBox>
  );
}

function Preload({
  print, notZoneId, local, open,
}) {
  return local
    ? (
      <LocalWrapper>
        <Animate notZoneId={notZoneId} />
      </LocalWrapper>
    )
    : (
      <Wrapper op={print ? true : open} print={print} notZoneId={notZoneId}>
        <Animate notZoneId={notZoneId} />
      </Wrapper>
    );
}
export default Preload;
