import styled from 'styled-components';

export const TableBox = styled.div`
  position: relative;
  margin-bottom: 100px;
  .table {
    width: 100%;
    border-spacing: 0;
    padding-bottom: 30px;
  }
  .sr-only {
    display: none;
  }

  .table thead th{
    padding: ${(p) => (p.doubleColumn ? '0px 0px 0px 0px' : '20px 0px')}; 
    font-family: Roboto !important;
    font-size: 16px !important;
    color: ${(p) => p.theme.lightText} !important;
    vertical-align: bottom;
    border-bottom: 1px solid ${(p) => p.theme.disable};
    text-align: left;
    font-weight: normal;
    outline: 0;
   div {
    font-family: inherit;
   }
  }
  
  .table thead th input{
    border: 0;
    font-weight: normal;
    border-bottom: 2px solid ${(p) => p.theme.accent};
    color: ${(p) => p.theme.accent} !important;
    font-size: 14px !important;
    background: ${(p) => p.theme.white};
    width: 100%;
    margin: -5px 0;
    &::placeholder {
      font-size: 14px !important;
      font-family: Roboto;
      color: ${(p) => p.theme.main} !important;
    }
  }
  
  .table tbody td {
    height: 50px;
    font-size: 14px;
    color: ${(p) => p.theme.text};
    border-bottom: 1px dashed rgba(0,0,0,.1);
  }

  .react-bootstrap-table-pagination{
    display: flex;
    justify-content: space-between;
  }

  #pageDropDown {
    background: ${(p) => p.theme.bg};
    border: 1px solid rgba(0,0,0,.2);
    padding: 10px 10px;
    margin-left: 25px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    outline: 0;
    color: ${(p) => p.theme.text};
    .span {
      display: none !important;
    }
  }
  .dropdown-menu {
    font-size: 14px;
    width: 50px !important;
  }
  .dropdown-menu.open {
    background: ${(p) => p.theme.bg};
    overflow: hidden;
    border: 1px solid rgba(0,0,0,.2);
    .dropdown-item {
      padding: 10px 15px;
      background-color: ${(p) => p.theme.bg};
      a {
          text-decoration: none;
      }
      &:hover {
        background-color: ${(p) => p.theme.accent};
        a {
          color: ${(p) => p.theme.buttonHover} !important;
          outline: none;
        }
      }
    }
  }
  .react-bs-table-sizePerPage-dropdown{
    position: relative;
  }

  .react-bs-table-sizePerPage-dropdown ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -250%;
    width: 100%;
    background: ${(p) => p.theme.white};
    border: ${(p) => `1px solid ${p.theme.gray}`};
    transition: .1s;
  }

  .react-bs-table-sizePerPage-dropdown ul.show{
    visibility: visible;
    opacity: 1;
    z-index: 999;
  }

  .react-bs-table-sizePerPage-dropdown ul li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .react-bs-table-sizePerPage-dropdown .dropdown-menu.open .dropdown-item {
    padding: 0;
    margin: 0;
  }

  .react-bs-table-sizePerPage-dropdown ul li a {
    padding: 10px 15px;
    color: ${(p) => p.theme.dmain} !important;
  }

  .pagination {
    display: flex;
  }

  .pagination li a{
    margin-left: 5px;
    position: relative;
    display: block;
    padding: .52rem .75rem .49rem .75rem;
    line-height: 1.25;
    color: ${(p) => p.theme.text};
    background-color: ${(p) => p.theme.white};
    border: 1px solid rgba(0,0,0,.2);
    &:hover {
      background: ${(p) => p.theme.accent};
      color: ${(p) => p.theme.buttonHover}
    }
  }

  .page-link{
    padding: 6px 10px 5px 10px !important;
    color: ${(p) => p.theme.main};
    text-decoration: none;
  }
  .page-item.active .page-link{
    border: 1px solid transparent;
    color: ${(p) => p.theme.buttonHover};
    text-decoration: none;
    background: ${(p) => p.theme.accent};
  }

  .table thead th .filterBoxTable {
    position: relative;
    svg {
      position: absolute;
      right: 0;
      height: 15px;
      width: 15px;
    }
    svg path {
      fill: ${(p) => p.theme.accent};
    }
    input {
      font-size: 18px;
      height: 24px;
      outline: 0;
      border-bottom: 2px solid transparent;
      background: transparent;
      
      &::-webkit-input-placeholder {
        font-size: 16px!important;
        transition: .3s;
      }
      &:focus::-webkit-input-placeholder { 
        opacity: 0; 
      }
      &:focus:-moz-placeholder { 
        opacity: 0;
      } 
      &:focus:-ms-input-placeholder { 
        opacity: 0; 
      }
      &:focus{
        border-bottom: 1px solid ${(p) => p.theme.white};
      }
    }
    select {
      position: absolute;
      top: -25px;
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      color: ${(p) => p.theme.text};
      background-clip: padding-box;
      background: ${(p) => p.theme.bg};
      border: none;
      box-shadow: 0 1px 3px rgba(0,0,0,.1);
      border-radius: 3px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      option {
        &:hover {
          background-color: ${(p) => p.theme.accent};
        }
      }
      &:focus {
        outline: none;
      }
    }
    &.sort {
      min-height: 10px;
      input {
        max-width: calc(100% - 30px);
      }
      svg {
        right: 30px;
      }
      .sortIcon {
        path, rect {
          fill: none;
          stroke: ${(p) => p.theme.accent};
        }
        .cls-3 {
          fill: ${(p) => p.theme.accent};
        }
        right: 0;
        transform: rotate(180deg);
        transition: all .8s ease-in-out
      }
    }
  }
  .tableTD {
    background-color: ${(p) => p.theme.white};
    &:nth-child(2n) {
      background: ${(p) => p.theme.table2n};
    }
  }
  .react-bootstrap-table-editing-cell {
    > div {
      max-width: 100%;
    }
  }
`;

export const PaginationBox = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  align-items: center;
  margin-top: 15px;
  font-size: 12px;
  top: ${(p) => (p.bottom ? 'auto' : '-67px')};
  bottom: ${(p) => (p.bottom ? '-50px' : 'auto')};
`;

export const TableItem = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  input{
    width: 100%;
    border: 1px solid transparent;
    border-radius: 3px;
    padding: 0 15px;
    background: ${(p) => p.theme.bg};
    color: ${(p) => p.theme.text};
    box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
    &[type=checkbox] {
      box-shadow: none;
      height: 20px;
      padding: 0;
    }
    &:focus {
      outline: none;
    }
  }
  &:after {
    content: attr(data-title); /* Выводим текст */
    position: absolute; /* Абсолютное позиционирование */
    display: flex;
    align-items: center;
    justify-content:center;
    left: 0; right: 0; top: -50px; /* Положение подсказки */
    word-break:break-all;
    z-index: 1; /* Отображаем подсказку поверх других элементов */
    background: ${(p) => p.theme.accent}; /* Полупрозрачный цвет фона */
    color: ${(p) => p.theme.buttonHover}; /* Цвет текста */
    text-align: center; /* Выравнивание текста по центру */
    font-size: 13px; /* Размер текста подсказки */
    padding: 5px 10px; /* Поля */
    //border: 1px solid #333; /* Параметры рамки */
    border-radius: 3px;
    height: 35px;
    overflow-wrap:break-word;
    transition: 0.1s ease-out;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
   }
   &:before{
      content: '';
      z-index: 2;
      position: absolute;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
      border: 12px solid transparent;
      border-top: 12px solid ${(p) => p.theme.accent};
      opacity: 0;
      transition: 0.1s ease-out;
      visibility: hidden;
   }
`;

export const NotButtons = styled.div`
  padding: 15px 25px;
`;
export const TableBoxAnimation = styled.div`
  opacity: ${(p) => (p.show ? 1 : 0)};
  transition: 0.5s ease-out 0.5s;
`;
