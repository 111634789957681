function getDefaultZone() {
  return {
    name: '', longname: '', description: '', config: { params: [], screens: [] },
  };
}

function getDefaultScreen() {
  return { buttons: [], bottomBlock: { buttons: [], topText: '', bottomText: '' } };
}

function getDefaultFormScreenValues() {
  return {
    screenSubType: '', headTitle: '', title: '', description: '', type: 'startScreen', isStart: false,
  };
}

function getDefaultFormQueueValues() {
  return {
    fullname: '', name: '', prefix: '', position: '', workWeek: { workWeekId: '', workWeekName: '' },
  };
}

function getDefaultScreenTypes() {
  return [
    { value: 'startScreen', label: 'Начальный' },
    { value: 'dictScreen', label: 'Одиночного выбора' },
    { value: 'inputScreen', label: 'С полями ввода' },
    { value: 'btnScreen', label: 'С кнопками' },
    { value: 'multiScreen', label: 'Множественного выбора' },
  ];
}

function getDefaultButtonSizes() {
  return [
    { value: 'sm', label: 'Маленькая' },
    { value: 'md', label: 'Средняя' },
    { value: 'lg', label: 'Большая' },
  ];
}

function getDefaultButtonActions() {
  return [
    { name: 'Сканирование', id: 'scan' },
    { name: 'Предварительная запись', id: 'preEntry' },
    { name: 'Эмуляция предварительной записи', id: 'preRecord' },
    { name: 'Предварительная запись с универсальными слотами', id: 'newPreEntry' },
    { name: 'Ввод пин-кода', id: 'pinCode' },
  ];
}

function getDefaultScreenNodes() {
  return {
    scanScrenNode: {
      title: 'Сканирование',
      headTitle: 'Сканирование',
      id: 'scan',
      type: 'scan',
      parents: [],
      screenPath: '',
      nodes: [],
    },
    preScreenNode: {
      title: 'Предварительная запись',
      headTitle: 'Предварительная запись',
      id: 'preEntry',
      type: 'preEntry',
      parents: [],
      screenPath: '',
      nodes: [],
    },
    preRecordNode: {
      title: 'Запись ПЗ',
      headTitle: 'Запись ПЗ',
      id: 'preRecord',
      type: 'preRecord',
      parents: [],
      screenPath: '',
      nodes: [],
    },
    pinCodeNode: {
      title: 'Ввод пин-кода',
      headTitle: 'Ввод пин-кода',
      id: 'pinCode',
      type: 'pinCode',
      parents: [],
      screenPath: '',
      nodes: [],
    },
    printScreenNode: {
      title: 'Печать',
      headTitle: 'Печать',
      id: 'print',
      type: 'print',
      parents: [],
      screenPath: '',
      nodes: [],
    },
  };
}

const defaultValues = {
  zone: getDefaultZone(),
  screen: getDefaultScreen(),
  screenForm: getDefaultFormScreenValues(),
  screenTypes: getDefaultScreenTypes(),
  buttonSizes: getDefaultButtonSizes(),
  buttonActions: getDefaultButtonActions(),
  queueForm: getDefaultFormQueueValues(),
  screenNodes: getDefaultScreenNodes(),
};

export default defaultValues;
