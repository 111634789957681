import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

function EditorComponent({
  data, height, setData, selector, minHeight, handleFocus = () => { }, fonts,
}) {
  return (
    <Editor
      value={data}
      // apiKey="ferbxo2i5dzep5bkr3ijc3ex5a5fffycvhnqmvsmqd9f66z5"
      init={{
        menubar: true,
        branding: false,
        resize: false,
        max_width: 800,
        min_height: minHeight,
        max_height: height,
        language: 'ru',
        selector: `.${selector}`,
        content_css: ['/tinymce/tiny.default.css', '/fonts/fonts.css'],
        relative_urls: false,
        plugins: [
          'lists',
          'autoresize',
          'searchreplace',
          'table',
          'code',
          'wordcount',
          'noneditable',
          'lineHeight',
        ],
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar: [
          'undo redo | bold italic underline | fontselect fontsizeselect | formatselect blockquote |',
          'forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link | image | searchreplace | table ',
        ],
        style_formats_merge: true,
        table_default_attributes: {
          border: '2',
        },
        table_class_list: [
          { title: 'None', value: '' },
          { title: 'Dog', value: 'dog' },
          { title: 'Cat', value: 'cat' },
        ],
        image_advtab: true,
        valid_elements: 'p[style],strong,em,span[style],a[href],ul,ol,li,br,img[src|style|alt],table,td,tr,th,h1[style],h2[style],h3[style]',
        valid_styles: {
          '*': 'font-size,font-family,color,text-decoration,text-align,border, line-height, padding-left, padding-right, margin,border-style,border-width,margin-left,margin-right,margin-top,margin-bottom,width,height,float',
        },
        fontsize_formats: '11px 12px 14px 16px 18px 20px 21px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 45px 56px 60px 72px 78px 80px',
        powerpaste_allow_local_images: true,
        powerpaste_word_import: 'clean',
        powerpaste_html_import: 'clean',
        font_formats: fonts,
        style_formats: [
          {
            title: 'Line Height',
            items: [
              { title: 'Normal Line Height', inline: 'span', styles: { 'line-height': '100%' } },
              { title: 'Line Height + 10%', inline: 'span', styles: { 'line-height': '110%' } },
              { title: 'Line Height + 20%', inline: 'span', styles: { 'line-height': '120%' } },
              { title: 'Line Height + 30%', inline: 'span', styles: { 'line-height': '130%' } },
              { title: 'Line Height + 40%', inline: 'span', styles: { 'line-height': '140%' } },
              { title: 'Line Height + 50%', inline: 'span', styles: { 'line-height': '150%' } },
              { title: 'Line Height + 60%', inline: 'span', styles: { 'line-height': '160%' } },
            ],
          },
        ],
        setup: (ed) => {
          ed.on('init', () => {
            handleFocus();
          });
        },
      }}
      onEditorChange={(context) => {
        setData(context);
      }}
    />
  );
}

export default EditorComponent;
