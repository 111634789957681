import React, { useContext, useMemo } from 'react';
import Context from '../../../../../../../../../pages/public/terminal/context';
import HeadSettings from './Head';
import TextSettings from './Text';
import ButtonSettings from './Button';
import NavigationSettings from './Navigation';
import InputSettings from './Input';

function StyleSettings() {
  const { element, data, dispatch } = useContext(Context);

  const renderSettings = useMemo(() => {
    switch (element) {
      case 'head':
        return <HeadSettings data={data} dispatch={dispatch} />;
      case 'text':
        return <TextSettings data={data} dispatch={dispatch} />;
      case 'button':
        return <ButtonSettings data={data} dispatch={dispatch} />;
      case 'navigation':
        return <NavigationSettings data={data} dispatch={dispatch} />;
      case 'input':
        return <InputSettings data={data} dispatch={dispatch} />;
      default:
        return null;
    }
  }, [element]);

  return (
    <div className="settings__styles">
      {element && renderSettings}
    </div>
  );
}

export default StyleSettings;
