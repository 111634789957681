export default ({ news }) => [
  {
    title: 'Окно текста', value: news.fontSize1, paramName: 'fontSize1', parentName: 'news',
  },
  {
    title: 'Дата', value: news.fontSize2, paramName: 'fontSize2', parentName: 'news',
  },
  {
    title: 'Заголовок', value: news.fontSize4, paramName: 'fontSize4', parentName: 'news',
  },
];
