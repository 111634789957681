import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';

function ContentItem({
  item, position, parent, delEl,
}) {
  const el = useRef(null);
  const [pos, setPos] = useState({ left: null, top: null });

  const handlePosition = useCallback(() => {
    const childPos = el.current.getBoundingClientRect();
    const relativePos = {};
    relativePos.top = childPos.top - parent.top;
    relativePos.left = childPos.left - parent.left;
    const coordTop = position.top - relativePos.top;
    const coordLeft = position.left - relativePos.left;
    setPos({ top: coordTop, left: coordLeft });
  }, [el, parent, position]);

  useEffect(() => {
    handlePosition();
  }, [handlePosition]);

  const inEffect = `
  @keyframes react-fade-in {
    0%   { transform: translate(${pos.left}px, ${pos.top}px); }
    100% { transform: translate(0px, 0px); }
  }
`;

  return (
    <>
      <style>{inEffect}</style>
      <li
        onClick={delEl}
        ref={el}
        data-val={item.value}
        className="contentItem"
      >
        {item.name}
        <div data-name="del" className="del">Удалить</div>
      </li>
    </>
  );
}

export default React.memo(ContentItem);
