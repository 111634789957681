import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import mat from '../../../../common/images/mat.png';
import sueo from '../../../../common/images/sueo.png';
import terminals from '../../../../common/images/terminals.png';

export const LoginBox = styled.div`
  transform-style: preserve-3d;
  perspective: 900px;
  perspective-origin: 0;
  position: fixed;
  top: ${(props) => (props.animate ? '-100vh' : 0)};
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  z-index: 9;
  p {
    position: absolute;
    top: 50px;
    padding: 15px 45px;
    color: white;
    border-radius: 3px;
  }
`;

const AnimSvg = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.2);
  } 
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
export const SVG = styled.svg`
  opacity: 0;
  height: 80px;
  animation: ${AnimSvg} 0.15s ease-in 0.8s forwards;
  position: absolute;
  top: 31.5%;
  left: 38.5%;
`;
export const StyleBox = styled.div`
  position: fixed;
  overflow: hidden;
  top: 50px;
  max-height: ${(p) => (p.open ? '200px' : 0)};
  right: 50px;
  //box-shadow: 0 3px 6px rgba(0,0,0,.1);
  // background-color: ${(p) => p.theme.bgTable};
  border-radius: 3px;
  display: flex;
  z-index: 5;
  opacity: ${(p) => (p.open ? 1 : 0)};
  //padding: ${(p) => (p.open ? '15px 15px' : '0')};
  flex-direction: column;
  transition: 0.15s ease-out;
`;
export const Item = styled.div`
  cursor: pointer;
  padding: 5px 15px;
  font-size: 14px;
  margin: 5px 0;
  border-radius: 3px;
  background: ${(p) => (p.active ? p.theme.accent : p.theme.bgTable)};
  color: ${(p) => (p.active ? p.theme.buttonHover : p.theme.main)};
  border: 1px solid rgba(0,0,0,.1);
`;

export const LogoutLink = styled(Link)`
  color: ${(p) => p.theme.accent};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;
const LoginFormAnim = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
}
`;
export const LoginForm = styled.form`
  opacity: 0;
  height: 350px;
  z-index: 5;
  transition: 0.3s ease-in;
  display: flex;
  color: white;
  animation: ${LoginFormAnim} 0.3s ease-in forwards 0.3s;
  flex-direction: column;
  font-family: Roboto;
  h1 {
    text-transform:uppercase;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
    color: ${(p) => p.theme.buttonHover}
  }
  h2 {
    margin-bottom: 10px;
    font-weight:bold; 
    text-transform: uppercase;
    text-align: center;
    color: ${(p) => p.theme.buttonHover}
  }
  h3 {
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 40px;
    text-align: center;
    color: ${(p) => p.theme.buttonHover}
  }
  
  h1, h2 {
    font-size: calc( (100vw - 1366px)/(1920 - 1366) * (24 - 18) + 18px);
  }
`;

export const Btn = styled.button`
  cursor: pointer;
  margin: 0 auto;
  //width: 100%;
  color: white;
  text-align: center;
  border: 0;
  top: 0;
  padding: 10px 35px;
  background-color: #4dbd74;
  font-size: 17px;
  position: relative;
  transition: 0.1s ease-in;
  border-radius: 3px;
  margin-top: 10px;
  //clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 93% 100%);
  text-transform: uppercase;
  font-family: Roboto;
  font-weight: 800;
  &:focus {
    outline: none;
  }
  &:hover {
  }
`;
export const LogoutBox = styled.div`
  min-width: max-content;
  margin-left: 30px;
  display: flex;
  justify-content: flex-end;
`;
export const UserName = styled.p`
  display: flex;
  align-items: center;
  //width: fit-content;

  > svg {
    margin: 0 15px;
    height: 20px;
    width: 20px;
  }
  span {
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    width: max-content;
  }
`;

const Login = keyframes`
  0% {
    opacity: 1;
  } 100% {
    opacity: 0;
  }
`;

export const FormBox = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8%;
  top: 6%;
  background: #171d2b;
  /* border-right: 3px solid rgba(255, 77, 0, 1); */
  width: 31.2%;
  height: 100vh;
  z-index: 4;
  animation: ${LoginFormAnim} 0.3s ease-out forwards;
  &.login {
    opacity: 1;
    animation: ${Login} 0.3s ease-out forwards 0.8s;
  }
  img {
    height: 100px;
    width: auto;
    margin: 0 auto 30px;
  }
`;
export const Terminals = styled.div`
  position: absolute;
  top: 70%;
  left: 64%;
  transform: translate(-50%, -50%);
  height: 70%;
  width: 50%;
  background: url(${terminals}) no-repeat 50%/contain;
  animation: ${LoginFormAnim} 0.5s ease-in-out forwards 0.5s;
  opacity: 0;
  z-index: 2;
  &.login {
    opacity: 1;
    animation: ${Login} 0.3s ease-out forwards 0.2s !important;
  }
`;

export const AlphaMat = styled.div`
  position: absolute;
  width: 400px;
  height: 803px;
  top: 70%;
  left: 42%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background: url(${mat}) no-repeat 50%/contain;
  animation: ${LoginFormAnim} 0.3s ease-in-out forwards 0.2s;
  opacity: 0;
  &.login {
    opacity: 1;
    animation: ${Login} 0.3s ease-out forwards 0.5s !important;
  }
  @media(max-width: 1366px) {
    height: 600px;
    width: 300px;
  }
`;

export const Sueo = styled(AlphaMat)`
  background: green;
  background: url(${sueo}) no-repeat 50%/contain;
  z-index: 1;
  left: 85%;
  animation: ${LoginFormAnim} 1s ease-out forwards 1s;
  opacity: 0;
  &.login {
    opacity: 1;
    animation: ${Login} 0.3s ease-out forwards !important;
  }
`;
