import moment from 'moment';
import iconFormatter from '../../../utils/iconFormatter';

const TypesDictionary = {
  wp_id: {
    text: 'По рабочим местам',
    bunchObjectsIdsMap: (item) => item.name,
  },
  modified_by_id: {
    text: 'По операторам',
    bunchObjectsIdsMap: (item) => item.fullname,
  },
  q_id: {
    text: 'По услугам',
    bunchObjectsIdsMap: (item) => item.fio,
  },
};
export default null;
export function TableConfig(searchPropsFactory) {
  return [
    {
      dataIndex: 'name',
      key: 'name',
      ...searchPropsFactory('name', 'Название'),
      title: 'Название',
    },
    {
      dataIndex: 'description',
      key: 'description',
      ...searchPropsFactory('description', 'Описание'),
      title: 'Описание',
    },
    {
      dataIndex: 'bunchType',
      key: 'bunchType',
      title: 'Тип',
      render: (bunchType) => TypesDictionary[bunchType].text,
    },
    {
      dataIndex: 'bunchObjectsIds',
      key: 'bunchObjectsIds',
      title: 'Группируемые объекты',
      render: (_, item) => TypesDictionary[item.bunchType].bunchObjectsIdsMap(item.bunchObjectsIds),
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_on',
      key: 'created_on',
      ...searchPropsFactory('created_on', 'Дата создания'),
      render: (value) => (
        <p>{moment(value).format('DD.MM.YYYY HH:mm:ss')}</p>
      ),
    },
    {
      dataIndex: 'modified_by_username',
      key: 'modified_by_username',
      title: 'Изменен',
    },
    {
      dataIndex: 'modified_on',
      key: 'modified_on',
      title: 'Когда изменен',
      render: (value) => (
        <p>{moment(value).format('DD.MM.YYYY HH:mm:ss')}</p>
      ),
    },
    {
      dataIndex: 'Settings',
      title: '',
      key: 'Settings',
      render: () => (
        iconFormatter({ write: true, update: true })
      ),
    },
  ];
}
