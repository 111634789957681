import React from 'react';

import { textFilter } from 'react-bootstrap-table2-filter';
import { ReactComponent as Edit } from '../../../../../../../../common/images/edit.svg';
import c from '../../../../../../../../common/ui/theme/Colors';
import { DubleLineTemplate, markTemplate } from '../../../utils/formater';

function priceFormatter(column, colIndex, { filterElement }) {
  return (
    <div className="filterBoxTable">
      {column.dataField !== 'Settings' && <Edit style={{ margin: '2px 10px 0 0' }} fill={c.main} /> }
      { filterElement }
    </div>
  );
}

function TableConfig() {
  return {
    columns: [
      {
        dataField: '4',
        text: 'Оператор',
        sort: true,
        editable: false,
      }, {
        dataField: '1',
        text: 'Номер талона',
        sort: true,
        editable: false,
        headerFormatter: priceFormatter,
        filter: textFilter({
          className: 'customPlaceholder',
          placeholder: 'Номер талона',
        }),
      }, {
        dataField: '001',
        isDummyField: true,
        text: 'Дата',
        sort: true,
        editable: false,
        formatter: (cell, row) => DubleLineTemplate('time', 'Создание талона:', row[3], 'time', 'Получение оценки:', row[6]),
      }, {
        dataField: '002',
        isDummyField: true,
        text: 'Оценка',
        sort: true,
        editable: false,
        formatter: (cell, row) => markTemplate(`${row[5] * 20}%`),
        headerStyle: {
          width: 180,
        },
      },
    ],
  };
}

export default TableConfig;
