import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Editor from '../../../../../utils/tinymce';

function InfoScreenEditor({
  data, setScreen, showError, minHeight, selector, height,
}) {
  const [fonts, setFonts] = useState('sans-serif');

  const getFonts = useCallback(async () => {
    try {
      const response = await axios({
        method: 'get',
        url: '/api/fonts',
      });
      const nameFonts = [];
      response.data.forEach((el) => nameFonts.push(el.name));
      setFonts(`${nameFonts.join(';')}`);
    } catch (e) {
      showError(e.data.error);
    }
  }, [showError]);

  useEffect(() => {
    getFonts();
  }, [getFonts]);

  return (
    <div className={selector || 'selector'}>
      <Editor setData={setScreen} minHeight={minHeight || 500} data={data} height={height} selector={selector || 'selector'} fonts={fonts} />
    </div>
  );
}

export default InfoScreenEditor;
