export default ({ time }) => [
  {
    title: 'Время', value: time.fontSize1, paramName: 'fontSize1', parentName: 'time',
  },
  {
    title: 'Дата', value: time.fontSize2, paramName: 'fontSize2', parentName: 'time',
  },
  {
    title: 'Текст', value: time.fontSize3, paramName: 'fontSize3', parentName: 'time',
  },
];
