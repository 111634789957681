import styled from 'styled-components';

export const LayoutContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  
  & .ant-page-header-heading-left {
    width: 100%
  }
`;

export default null;
