export default ({ header, footer, body }) => [
  {
    title: 'Фон <br/>шапки', value: header.background, paramName: 'background', parentName: 'header',
  },
  {
    title: 'Фон <br/>подвала', value: footer.background, paramName: 'background', parentName: 'footer',
  },
  {
    title: 'Фон <br/>осн. экрана', value: body.background, paramName: 'background', parentName: 'body',
  },
];
