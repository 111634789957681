export default ({ news }) => [
  {
    title: 'Текст', value: news.fontFamily1, paramName: 'fontFamily1', parentName: 'news',
  },
  {
    title: 'Дата', value: news.fontFamily2, paramName: 'fontFamily2', parentName: 'news',
  },
  {
    title: 'Заголовок', value: news.fontFamily4, paramName: 'fontFamily4', parentName: 'news',
  },
];
