import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { ReportRangeItem, ColorCell } from './style';

function IntervalTable({ data }) {
  const [reportData, setReportData] = useState();

  const buildReportData = useCallback(() => {
    const { historyParams } = data;
    let difference;
    let summtime = 0;

    const myData = data.myData.map((element) => {
      summtime += +element.nonf_time;
      if (element.nonf_time <= 600) {
        return { ...element, color: '#50c878' };
      } if (element.nonf_time >= 601 && element.nonf_time <= 900) {
        return { ...element, color: '#ffdb58' };
      } if (element.nonf_time >= 901 && element.nonf_time <= 1200) {
        return { ...element, color: '#f36223' };
      } if (element.nonf_time >= 1201 && element.nonf_time < 1800) {
        return { ...element, color: '#ff2400' };
      } if (element.nonf_time >= 1801) {
        return { ...element, color: '#7b3f00' };
      }
      return element;
    });

    if (historyParams) {
      difference = myData[myData.length - 1].nonf_time - historyParams[0].nonf_time;
    } else {
      difference = myData[myData.length - 1].nonf_time - myData[0].nonf_time;
    }
    const width = (myData.length * 100) + ((myData.length + 1) * 3) + 167;

    const IntervalAvgTime = summtime / myData.length;

    setReportData({
      IntervalAvgTime: moment.unix(IntervalAvgTime).utc().format('HH:mm:ss'),
      difference: moment.unix(Math.abs(difference)).utc().format('HH:mm:ss'),
      historyAvg: historyParams ? moment.unix(Math.abs(historyParams[0].nonf_time)).utc().format('HH:mm:ss') : moment.unix(Math.abs(myData[0].nonf_time)).utc().format('HH:mm:ss'),
      historyDate: historyParams ? historyParams[0].date : myData[0].date,
      width,
      items: myData,
    });
  }, [data]);

  useEffect(() => {
    buildReportData();
  }, [data, buildReportData]);

  const renderCells = () => (
    reportData.items.map((element) => (
      <ColorCell color={element.color} key={element.date}>
        <div className="bodyCell">{element.avt_time}</div>
      </ColorCell>
    ))
  );

  const renderDateDescription = () => (
    reportData.items.map((element) => (
      <div className="redCell" key={element.date}>
        <div className="bodyCell">{element.date}</div>
      </div>
    ))
  );

  return (reportData ? (
    <div className="itervalReprotBox">
      <div className="intervalReport_bodyBox">

        {renderDateDescription()}
        <div className="redCell">
          <div className="historyDateDesc">
            <div>Историческая дата:</div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>{reportData.historyDate}</div>
          </div>
        </div>
      </div>
      <div className="title" style={{ width: 'fit-content', fontSize: '20px' }}>

        <ReportRangeItem style={{ width: `${reportData.width}px` }} width={reportData.width}>Среднее время ожидания к специалисту</ReportRangeItem>
        <div className="titleCell intervalReprot_title_avgTime">Значение за период</div>
        <div className="titleCell intervalReprot_title_deference">Изменение за период</div>
      </div>
      <div className="intervalReport_bodyBox">

        <div className="dataCell">
          {renderCells()}
          <ColorCell>
            <div className="bodyCellBig">{reportData.historyAvg}</div>
          </ColorCell>
        </div>
        <div className="bodyCell intervalReprot_title_avgTime">{reportData.IntervalAvgTime}</div>
        <div className="bodyCell intervalReprot_title_deference">{reportData.difference}</div>

      </div>
    </div>
  ) : <div>Загрузка...</div>);
}

export default IntervalTable;
