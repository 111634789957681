import React, { useContext } from 'react';

export const ScreenProviderContext = React.createContext({
  currentScreen: 'terminal',
  setShowPreloader: () => {},
  changeActiveScreen: () => {},
  currentBoardStatus: [],
  checkTicketStatus: () => {},
  showPreloader: true,
  currentZone: null,
  getTicket: () => {},
});

export function useScreenProviderContext() {
  return useContext(ScreenProviderContext);
}
