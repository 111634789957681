import React, { useState, useEffect, useCallback } from 'react';
import { SketchPicker } from 'react-color';
import gradientParser from 'gradient-parser';
import { useToggle } from '../../logic/hooks/useToggle';
import findPosition from '../../pages/private/components/Input/findPosition';

function Gradient({ colorBg, handleGradient, name }) {
  const initialData = {
    type: 'linear-gradient',
    orientation: 'to bottom',
    color: 'rgba(255,255,255, 1)',
    nextColor: 'rgba(255,255,255, 1)',
    gradient: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(255,255,255, 1))',
  };
  const [isOpen, setIsOpen] = useToggle(false);
  const [data, setData] = useState(initialData);
  const [bottom, setBottom] = useState(false);
  const {
    type, orientation, color, nextColor,
  } = data;
  const [bgColor, setBgColor] = useState(colorBg);

  const setColors = useCallback(() => {
    if (colorBg.indexOf('gradient') !== -1) {
      const {
        colorStops,
        orientation: newOrient,
        type: newType,
      } = gradientParser.parse(colorBg)[0];
      const colorOne = colorStops[0].value;
      const colorTwo = colorStops[1].value;
      const newColor = `rgba(${colorOne[0]}, ${colorOne[1]}, ${colorOne[2]}, ${colorOne[3]})`;
      const newNextColor = `rgba(${colorTwo[0]}, ${colorTwo[1]}, ${colorTwo[2]}, ${colorTwo[3]})`;
      setData((v) => ({
        ...v, color: newColor, nextColor: newNextColor, orientation: `to ${newOrient.value}`, type: newType,
      }));
    }
  }, [colorBg]);

  useEffect(() => {
    setColors(colorBg);
  }, [colorBg, setColors]);

  const handleColor = (colors) => {
    const {
      r, g, b, a,
    } = colors.rgb;
    const newColor = `rgba(${r}, ${g}, ${b}, ${a})`;
    const gradient = `${type}(${orientation}, ${newColor}, ${nextColor})`;
    handleGradient({ type: 'element', value: name, payload: gradient });
    setData({ ...data, color: newColor, gradient });
    setBgColor(gradient);
  };

  const handleNextColor = (colors) => {
    const {
      r, g, b, a,
    } = colors.rgb;

    const newNextColor = `rgba(${r}, ${g}, ${b}, ${a})`;
    const gradient = `${type}(${orientation}, ${color}, ${newNextColor})`;
    handleGradient({ type: 'element', value: name, payload: gradient });
    setData({ ...data, nextColor: newNextColor, gradient });
  };

  const handleOrientation = (e) => {
    if (e.target.dataset.name) {
      const newOrient = e.target.dataset.name;
      const gradient = `${type}(${newOrient}, ${color}, ${nextColor})`;
      handleGradient({ type: 'element', value: name, payload: gradient });
      setData({ ...data, orientation: newOrient });
    }
  };

  const handleReverse = (e) => {
    e.stopPropagation();
    const gradient = `${type}(${orientation}, ${nextColor}, ${color})`;
    handleGradient({ type: 'element', value: name, payload: gradient });
  };

  return (
    <div className="box__color__picker">
      <div
        className="box__color__picker__btn"
        onClick={(e) => {
          findPosition(e.target, setBottom, 500);
          setIsOpen();
        }}
        style={{ background: bgColor }}
      />
      {isOpen
          && (
            <div
              className="box__color__gradient"
              style={{
                top: bottom ? 'auto' : 0,
                bottom: bottom ? 0 : 'auto',
              }}
            >
              <div
                className="box__color__picker__close"
                onClick={(e) => { e.stopPropagation(); setIsOpen(); }}
              />
              <div className="box__color__gradient__popup">
                <div>
                  <p>Направление:</p>
                  <ul onClick={handleOrientation}>
                    <li className="box__color__gradient__btn" active={orientation === 'to bottom'} data-name="to bottom">Вниз</li>
                    <li className="box__color__gradient__btn" active={orientation === 'to right'} data-name="to right">Вправо</li>
                    <li className="box__color__gradient__btn" active={orientation === 'to right bottom'} data-name="to right bottom">Под углом</li>
                    <li className="box__color__gradient__btn" onClick={handleReverse}>Наоборот</li>
                  </ul>
                </div>
                <div>
                  <p>Цвет 1:</p>
                  <SketchPicker onChange={handleColor} color={color} />
                </div>
                <div>
                  <p>Цвет 2:</p>
                  <SketchPicker onChange={handleNextColor} color={nextColor} />
                </div>
              </div>
            </div>
          )}
    </div>
  );
}

export default Gradient;
