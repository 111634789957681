import {
  Card, Col, Divider, Row, notification,
} from 'antd';
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import Meta from 'antd/lib/card/Meta';
import randomId from '../../../../../../../../logic/utils/randomId';
import zoneStore, { updateZoneStore } from '../../../Store';
import api from '../../../../utils/api';
import ScreenTitle from './ScreenTitle';
import ScreenButton from '../ScreenButton';

function Screen(props) {
  const { screen, toggleDrawer, className } = props;

  const screens = zoneStore.useState((s) => s.config.screens);

  const updateZone = async (message) => {
    const res = await api.updateZone({ ...zoneStore.getRawState() });

    if (res.status) {
      notification.success({
        message,
      });
    } else {
      notification.error({
        message: res.errMessage,
      });
    }
  };

  const addScreenButton = () => {
    const buttonData = {
      name: `Название ${screen.buttons.length + 1}`,
      size: 'lg',
      path: '',
      icon: false,
      params: { name: 'queue', value: 0 },
      id: randomId(5),
      position: screen.buttons.length + 1,
    };

    const updatedScreen = { ...screen, buttons: [...screen.buttons, buttonData] };
    const newScreens = screens.map((item) => {
      if (item.screenId === updatedScreen.screenId) {
        return updatedScreen;
      }
      return item;
    });
    const { config } = zoneStore.getRawState();

    updateZoneStore({ ...zoneStore.getRawState(), config: { ...config, screens: newScreens } });
    updateZone('Кнопка добавлена');
  };

  const deleteScreen = () => {
    const newScreens = screens.filter((item) => item.screenId !== screen.screenId);
    const { config } = zoneStore.getRawState();

    updateZoneStore({ ...zoneStore.getRawState(), config: { ...config, screens: newScreens } });
    updateZone('Экран удалён');
  };

  return (
    <Card
      style={{
        margin: '7px',
        width: 'fit-content',
        maxWidth: '450px',
      }}
      title={(
        <ScreenTitle
          screen={screen}
          toggleDrawer={toggleDrawer}
          deleteScreen={deleteScreen}
        />
      )}
      key={screen.screenId}
      className={className}
    >
      {screen.buttons.length > 0 && (
      <>
        {screen.buttons
          .map((button) => (
            <ScreenButton
              button={button}
              screen={screen}
              updateZone={updateZone}
              key={button.id}
            />
          ))}
        <Divider />
      </>
      )}
      <Row justify="center">
        <Card onClick={addScreenButton} style={{ width: '100%' }} hoverable>
          <Row align="middle">
            <Col style={{ marginRight: '5px' }}>
              <PlusOutlined style={{ fontSize: '130%', verticalAlign: 'middle' }} />
            </Col>
            <Col>
              <Meta title="Добавить кнопку" />
            </Col>
          </Row>
        </Card>
      </Row>
    </Card>
  );
}

export default Screen;
