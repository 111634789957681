import React, { useState, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Accordion from '../components/Accordion';
import FieldDescription from './FieldDescription';
import searchByKey from '../../../utils/seachByKey';
import Column from './Column';
import { ReactComponent as Drop } from '../../../../../../../../common/images/drop.svg';

function Orders({ changeFilter, showFilters }) {
  const OrderElement = {};
  const [orderValues, setOrderValues] = useState([]);

  showFilters.forEach((oirderItem, i) => {
    OrderElement[oirderItem] = {
      id: i,
      fild: oirderItem,
      FieldDescription: FieldDescription(oirderItem),
    };
  });

  const [possibleToSortArea, setPossibleToSortArea] = useState({
    task: { ...OrderElement },
    columns: {
      sort: {
        id: 'choose',
        title: 'Доступные поля',
        fieldIds: [...Object.keys(OrderElement)],
      },
      orderRules: {
        id: 'rules',
        title: 'Правила сортировки',
        fieldIds: [],
      },
    },
    columnOrder: ['sort', 'orderRules'],
  });

  const getOrderValue = (key) => {
    let value = `${key} ASC`;
    switch (key) {
      case 'create_data':
      case 'created_on':
      case 'close_data':
        value = `${key}::timestamp ASC`;
        break;
      case 'ndate': value = `${key}::date ASC`; // or 'В процессе обработки'
        break;
      default: break;
    }
    return value;
  };

  const onDragEnd = (result) => {
    try {
      const { destination, source, draggableId } = result;
      const orderV = [];

      if (!destination) return null;
      if (destination.droppableId === source.droppableId
        && destination.index === source.index
      ) {
        return null;
      }

      const startColumn = possibleToSortArea.columns[source.droppableId];
      const finishColumn = possibleToSortArea.columns[destination.droppableId];

      if (startColumn === finishColumn) {
        const newOrdersIds = Array.from(startColumn.fieldIds);
        newOrdersIds.splice(source.index, 1);
        newOrdersIds.splice(destination.index, 0, draggableId);

        const newColumn = {
          ...startColumn,
          fieldIds: newOrdersIds,
        };

        const newState = {
          ...possibleToSortArea,
          columns: {
            ...possibleToSortArea.columns,
            [source.droppableId]: newColumn,
          },
        };

        const { orderRules: { fieldIds } } = newState.columns;

        fieldIds.forEach((qEl) => {
          const qV = searchByKey(orderValues, 'okey', qEl);

          if (!qV) {
            orderV.push({
              okey: qEl,
              ovalue: `${qEl} ASC`,
            });
          } else {
            orderV.push({
              okey: qEl,
              ovalue: qV.ovalue,
            });
          }
        });

        setOrderValues(orderV);
        return setPossibleToSortArea(newState);
      }
      const startOrdersIds = Array.from(startColumn.fieldIds);
      startOrdersIds.splice(source.index, 1);

      const newStartOrdersIds = {
        ...startColumn,
        fieldIds: startOrdersIds,
      };

      const finistOrdersIds = Array.from(finishColumn.fieldIds);
      finistOrdersIds.splice(destination.index, 0, draggableId);

      const newFinishOrdersIds = {
        ...finishColumn,
        fieldIds: finistOrdersIds,
      };

      const newState = {
        ...possibleToSortArea,
        columns: {
          ...possibleToSortArea.columns,
          [source.droppableId]: newStartOrdersIds,
          [destination.droppableId]: newFinishOrdersIds,
        },
      };

      const { orderRules: { fieldIds } } = newState.columns;

      fieldIds.forEach((qEl) => {
        const qV = searchByKey(orderValues, 'okey', qEl);

        if (!qV) {
          orderV.push({
            okey: qEl,
            ovalue: getOrderValue(qEl),
          });
        } else {
          orderV.push({
            okey: qEl,
            ovalue: qV.ovalue,
          });
        }
      });
      setOrderValues(orderV);
      return setPossibleToSortArea(newState);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
    return null;
  };

  const handleChangeOrder = (key, value) => {
    const orderV = [...orderValues];

    orderV.forEach((el) => {
      if (el.okey === key) {
        // eslint-disable-next-line no-param-reassign
        el.ovalue = `${el.okey}` === 'create_data'
        || `${el.okey}` === 'close_data'
          ? `${key}::timestamp ${value}` : `${key} ${value}`;
      }
      // eslint-disable-next-line no-param-reassign
      if (el.okey === 'ndate') el.ovalue = `${key}::date ${value}`;
    });
    setOrderValues(orderV);
  };

  useEffect(() => {
    const order = [];
    orderValues.forEach((element) => order.push(element.ovalue));
    changeFilter('orderArr', order);
  }, [orderValues]);

  const renderFilterElements = () => (
    <DragDropContext
      onDragEnd={onDragEnd}
    >
      {' '}
      {
        possibleToSortArea.columnOrder.map((element, index) => {
          const column = possibleToSortArea.columns[element];
          const field = column.fieldIds.map((el) => possibleToSortArea.task[el]);
          return (
            <div key={element} style={{ display: 'flex' }}>
              <Column
                id={element}
                column={column}
                tasks={field}
                key={element}
                changeFilter={handleChangeOrder}
              />
              {index === 0 ? (<Drop style={{ width: '60px', fill: '#fc4c02' }} />) : null}
            </div>
          );
        })
}
    </DragDropContext>
  );

  return (
    <Accordion
      title="Сортировка"
      content={renderFilterElements()}
    />
  );
}

export default Orders;
