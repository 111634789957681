import React, { useState, useEffect } from 'react';
import RequestTable from './table/RequestTable';

function Request({ settings, preloader }) {
  const [tableSettings, setTableSettings] = useState(null);

  const [params, setParams] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: null,
    sortOrder: 'desc',
    filters: 'none',
  });

  const onSizePerPageChange = (sizePerPageNumber, pageNumber) => {
    setParams((v) => ({
      ...v,
      sizePerPage: sizePerPageNumber,
      page: pageNumber,
    }));
  };

  const onPageChange = (pageNumber, sizePerPageNumber) => {
    setParams((v) => ({
      ...v,
      sizePerPage: sizePerPageNumber,
      page: pageNumber,
    }));
  };

  const onTableChange = (type, newState) => {
    let { sortOrder } = newState;
    const { page, sizePerPage, filters } = newState;
    if (params.sortOrder === sortOrder && sortOrder === 'desc') {
      sortOrder = 'asc';
    } else {
      sortOrder = 'desc';
    }
    setParams((v) => ({
      ...v,
      page,
      sizePerPage,
      sortOrder,
      filters,
    }));
  };

  useEffect(() => {
    const pagination = {
      page: params.page,
      sizePerPage: params.sizePerPage,
      totalSize: params.totalSize,
      sortOrder: params.sortOrder,
      filters: params.filters,
      onPageChange,
      onSizePerPageChange,
      onTableChange,
      startDate: settings.startDate,
      endDate: settings.endDate,
    };
    setTableSettings(pagination);
  }, [params, settings]);

  return (
    tableSettings && (
    <RequestTable
      pagination={tableSettings}
      preloader={preloader}
    />
    )
  );
}

export default Request;
