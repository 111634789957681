import React from 'react';
import {
  FilterBox, FilterBoxTitle, FilterItemBox, FilterLineBox,
} from '../style';
import AllFilter from '../utils/ServedFilters/AllFilter';

function ProcTimeFilter({ changeFilter }) {
  return (
    <FilterBox>
      <FilterBoxTitle>Длительность обслуживания</FilterBoxTitle>
      <FilterItemBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="total_proc_time"
            title="Общая"
            timeFilter="timeFilter"
          />
        </FilterLineBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="avg_proc_time"
            title="Средняя"
            timeFilter="timeFilter"
          />
        </FilterLineBox>
      </FilterItemBox>
    </FilterBox>
  );
}
export default ProcTimeFilter;
