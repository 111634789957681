import React, { useState } from 'react';
import { Button, Input } from 'antd';
import request from '../../../../../../../logic/utils/request';
import { BtnsBox } from '../../../../../../../common/ui/Common2';
import Modal from '../../../../../../../common/ui/Modal/Modal';
import { useToggle } from '../../../../../../../logic/hooks/useToggle';

function NewField({ preloader, showError }) {
  const [licence, setLicence] = useState('');
  const [isOpen, toggleOpen] = useToggle(false);

  const resetModal = () => {
    toggleOpen();
    setLicence('');
  };
  const handleUpdate = async () => {
    preloader();
    try {
      await request('/server/api/licence/', 'update', { licBody: licence });
    } catch (err) {
      showError(err.data.error);
    }
    resetModal();
    preloader();
  };

  return (
    <>
      <Button
        size="large"
        type="primary"
        onClick={toggleOpen}
      >
        Обновить лицензию
      </Button>
      <Modal title="Обновление лицензии" isOpen={isOpen} toggleOpen={resetModal}>
        <div>
          <Input.TextArea
            name=""
            id=""
            cols="30"
            rows="10"
            onChange={(e) => setLicence(e.target.value)}
            style={{ resize: 'none', width: '100%', padding: 6 }}
            placeholder="Введите лицензию..."
          />
        </div>
        <BtnsBox>
          <Button
            size="large"
            type="primary"
            disabled={!licence}
            onClick={handleUpdate}
          >
            Сохранить
          </Button>
          <Button
            size="large"
            onClick={resetModal}
          >
            Отменить
          </Button>
        </BtnsBox>
      </Modal>
    </>
  );
}

export default React.memo(NewField);
