const ClearHistory = () => {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('fio');
  window.localStorage.removeItem('login');
  window.localStorage.removeItem('role');
  window.localStorage.removeItem('userId');
  window.localStorage.removeItem('fullname');
};

export default ClearHistory;
