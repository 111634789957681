import React, { useState, useEffect, useRef } from 'react';
import { FaCog, FaCheckDouble } from 'react-icons/fa';
import Styled from 'styled-components';
import { Button, Switch } from 'antd';
import Icon from '@ant-design/icons';
import {
  CheckLabel, List, LabelText, CheckBox, ButtonBox,
} from '../../../common/ui/Common';
import { useToggle } from '../../../logic/hooks/useToggle';
import getLinks from '../../../logic/utils/getLinks';

const Text = Styled.div`
  display: inline-block;
  margin-right: 10px;
  color: ${(p) => p.theme.text};
`;

const HeadBox = Styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: space-between;
`;

function CheckBoxList({
  item, data, setData, only,
}) {
  const { name, options, checked } = item;
  const [check, setCheck] = useState([...checked]);
  const [filteredOptions, setFilteredOptions] = useState(null);
  const [openList, setOpenList] = useToggle(true);
  const write = getLinks(true);
  const searchValue = useRef();

  const showList = () => {
    setOpenList();
  };

  const filterOptions = () => {
    if (searchValue.current.value !== '') {
      if (Object.keys(options[0]).includes('printname')) {
        setFilteredOptions(options.filter(((filterItem) => filterItem
          .printname
          .toUpperCase()
          .includes(searchValue.current.value.toUpperCase())
        )));
      } else {
        setFilteredOptions(options.filter(((filterItem) => filterItem
          .name
          .toUpperCase()
          .includes(searchValue.current.value.toUpperCase())
        )));
      }
    } else {
      setFilteredOptions(null);
    }
  };

  useEffect(() => {
    setFilteredOptions(null);
    searchValue.current.value = '';
    setCheck([...checked]);
  }, [checked]);

  const handleCheckOnly = ({ target: { value } }) => {
    const val = Number(value);
    const checkArr = [...check];

    const checkIndex = checkArr.indexOf(val) >= 0;

    if (!checkIndex) {
      setData({ ...data, [name]: val });
      setCheck([val]);
    }
  };

  const handleCheck = (e) => {
    const numValue = Number(e.target.value);
    let newValues = [];
    const checkArr = [...check];

    const checkIndex = checkArr.indexOf(numValue);
    if (checkIndex === -1) {
      newValues = [...checkArr, numValue];

      setCheck([...newValues]);

      setData({ ...data, [name]: newValues });
    } else {
      checkArr.splice(checkIndex, 1);
      setData({ ...data, [name]: checkArr });
      setCheck([...checkArr]);
    }
  };

  const checkAll = () => {
    let allOptions;
    if (filteredOptions) {
      allOptions = filteredOptions.map((optionItem) => optionItem.id);
    } else {
      allOptions = options.map((optionItem) => optionItem.id);
    }
    if (check.length === allOptions.length) {
      setCheck([]);
      setData({ ...data, [name]: [] });
    } else {
      setCheck([...allOptions]);
      setData({ ...data, [name]: allOptions });
    }
  };

  const writeCheck = only ? handleCheckOnly : handleCheck;

  const renderOptions = (array) => array.map((option) => (
    <CheckLabel key={option.id}>
      <LabelText>{option.printname || option.name}</LabelText>
      <CheckBox>
        <Switch
          onChange={() => (write ? writeCheck({ target: { value: option.id, name: 'connectedQueues' } }) : null)}
          checked={check.some((que) => Number(que) === option.id)}
        />
      </CheckBox>
    </CheckLabel>
  ));

  return (
    <>
      <HeadBox>
        <Text>{item.label}</Text>
        <ButtonBox>
          <Button onClick={showList} type="primary" icon={<Icon component={FaCog} />}>
            {openList ? 'Закрыть' : 'Раскрыть'}
          </Button>
          {write && openList && !only ? (
            <Button onClick={checkAll} type="primary" icon={<Icon component={FaCheckDouble} />}>
              Отметить все
            </Button>
          ) : null}
        </ButtonBox>
      </HeadBox>
      {openList && (
        <>
          <input className="MultiSelect__Search" onChange={filterOptions} ref={searchValue} type="text" placeholder="Введите для поиска" />
          <List>
            {renderOptions(filteredOptions || options)}
          </List>
        </>
      )}
    </>
  );
}

export default CheckBoxList;
