import React, { useState, useEffect, useCallback } from 'react';
import {
  Col, Input, Row, Space, Switch,
} from 'antd';
import request from '../../../../../logic/utils/request';
import RenderCheckList from './RenderCheckList';

function RenderInputs({
  userData, setUserData, preloader, setIsValid, userUpdate, roles,
}) {
  const [queue, setQueue] = useState([]);
  const [pwd, setPwd] = useState('');
  const [pwd2, setPwd2] = useState('');

  const fetchQueue = useCallback(async () => {
    preloader();
    const queueList = await request('/server/api/queue/', 'get');
    setQueue([...queueList.data.data]);
    preloader();
  }, [preloader]);

  useEffect(() => {
    fetchQueue();
  }, [fetchQueue]);

  useEffect(() => {
    const { username } = userData;
    if (userUpdate && username && pwd === pwd2) {
      setIsValid(true);
    }
  }, [pwd, pwd2, setIsValid, userData, userUpdate]);

  const handleInput = (e) => {
    const { name, value, type } = e.target;
    let validData = { ...userData };
    let repeatPwd = pwd2;
    let currentPwd = pwd;
    if (name === 'pwd2') {
      repeatPwd = value;
      setPwd2(value);
    } else if (name === 'pwd') {
      currentPwd = value;
      setPwd(value);
      setUserData({ ...validData, [name]: value });
    } else {
      if (type === 'checkbox') {
        validData = { ...userData, [name]: JSON.parse(value) }; // convert string to boolean
      } else {
        validData = { ...userData, [name]: value };
      }
      setUserData({ ...validData });
    }
    const { username, fio } = validData;
    if (username && currentPwd && currentPwd === repeatPwd && fio.trim()) { // valid for add user
      setIsValid(true);
    } else if (userUpdate && username && currentPwd === repeatPwd) { // valid for update user
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const {
    username, fio, hidden, blocked,
  } = userData;

  return (
    <Space direction="vertical" size="middle">
      <Row gutter={15}>
        <Col span={12}>
          <Space direction="vertical" size="middle">
            <Input
              addonBefore="Логин"
              value={username || ''}
              required
              name="username"
              onChange={handleInput}
            />
            <Input.Password
              addonBefore="Пароль"
              value={pwd || ''}
              required
              name="pwd"
              onChange={handleInput}
            />
            <Input.Password
              addonBefore="Повторите пароль"
              value={pwd2 || ''}
              required
              name="pwd2"
              onChange={handleInput}
            />
            <Input
              addonBefore="ФИО"
              value={fio || ''}
              required
              name="fio"
              onChange={handleInput}
            />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" size="large">
            <Space direction="small" size="small">
              <Switch
                checked={hidden}
                onChange={() => handleInput({ target: { type: 'checkbox', value: !hidden, name: 'hidden' } })}
              />
              Запрет работы на АРМ
            </Space>
            <Space direction="small" size="small">
              <Switch
                checked={blocked}
                onChange={() => handleInput({ target: { type: 'checkbox', value: !blocked, name: 'blocked' } })}
              />
              Запрет входа через Панель администрирования
            </Space>
          </Space>
        </Col>
      </Row>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 30,
        }}
      >
        <RenderCheckList
          setUserData={setUserData}
          queue={queue}
          roles={roles}
          userData={userData}
          userUpdate={userUpdate}
        />
      </div>
      {pwd !== pwd2 && <p style={{ color: 'red', textAlign: 'center' }}>Пароли не совпадают</p>}

    </Space>

  );
}
export default RenderInputs;
