export default ({ big }) => [
  {
    title: 'Заголовок', value: big.color1, paramName: 'color1', parentName: 'big',
  },
  {
    title: 'Текст', value: big.color2, paramName: 'color2', parentName: 'big',
  },
  {
    title: 'Рамка', value: big.color3, paramName: 'color3', parentName: 'big',
  },
  {
    title: 'Фон', value: big.background, paramName: 'background', parentName: 'big',
  },
];
