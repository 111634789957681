import styled, { keyframes } from 'styled-components';

export const AppealBox = styled.div`
  .item {
    display: flex;
    justify-content: space-between;
    padding: 15px 25px;
    &.center {
      justify-content: center;
    }
    &:nth-child(2n) {
      background: rgba(0,0,0,.05);
    }
    &.noBg {
      min-height: 80px;
    }
  }
`;
export const ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 33;
  overflow-y: auto;
`;

export const PreloaderBackground = styled(ModalBackground)`
  background: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const AnimModal = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
`;
export const ModalBox = styled.div`
  max-width: ${(p) => p.width || '600px'};
  background: ${(p) => p.theme.white};
  border-radius: 3px;
  //overflow: hidden;
  animation: ${AnimModal} 0.15s ease-out forwards;
  z-index: 33;
  margin: 30px auto;
`;

export const ModalContentBox = styled.div`
  width: 100%;
  flex-direction: column;
  position: relative;
`;

export const ModalHeader = styled.div`
  border-radius: 3px 3px 0 0;
  position: relative;
  display: flex;
  background: ${(p) => p.theme.main};
  font-size: 0.6875rem;
  line-height: 1.2;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: white;
  padding: 8px 20px;
`;

export const ModalTitle = styled.h2`
  font-family: Roboto;
  font-weight: 800;
  width: 100%;
  margin: 0;
  font-size: 18px;
  color: white;
  padding: 5px 0;
  text-transform: uppercase;
  text-align: center;
`;

export const ModalBody = styled.div`
  padding: 20px;
  background-color: ${(p) => p.theme.bgTable};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Input = styled.input`
  border: 1px solid ${(p) => p.theme.gray};
  padding: 6px 12px;
  margin-bottom: 20px;
`;

export const Select = styled.select``;

export const BtnsBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
const animationHover = (color) => keyframes`
    0% {
      box-shadow: 0 0 0 ${color};
    }
    100% {
      box-shadow: 0 0 0 3px ${color};
    }
  `;

const validBtnColor = (p) => (p.valid ? p.theme.green : p.theme.disable);
const cancelBtnColor = (p) => (p.cancel ? p.theme.accentl : 'transparent');

export const Btn = styled.button`
  cursor: ${(p) => ((p.cancel || p.valid) ? 'pointer' : 'default')};
  background: ${(p) => (p.cancel ? p.theme.accent : validBtnColor(p))};
  color: ${(p) => ((p.valid || p.cancel) ? p.theme.buttonHover : p.theme.disableText)};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  border: 0;
  padding: 10px 16px;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
  &:hover {  
    animation: ${(p) => animationHover(p.valid ? p.theme.greenl : cancelBtnColor(p))} 0.15s ease-out forwards;
  }
`;
export const BtnRemove = styled(Btn)`
  background: ${(p) => (p.remove ? p.theme.red : p.theme.accent)};
  color: ${(p) => p.theme.buttonHover};
  margin: 0 15px;
  cursor: pointer;
`;

export const ErrorText = styled.p`
  text-align: center;
  overflow: auto;
  max-height: 700px;
`;
