import React, { useState, useEffect } from 'react';
import { FaRedo, FaSave } from 'react-icons/fa';
import {
  Button, Col, Input, Row, Switch, Space,
} from 'antd';
import Icon, { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import MultiSelect from './MultiSelect/MultiSelect';

function Field({
  param, onSave, resetParam, multiData,
}) {
  const [value, setValue] = useState(null);
  useEffect(() => {
    setValue(param.value);
  }, [param]);
  const reg = /\.|,/g;
  const regNumber = /\d/;

  const handleMultiSelect = (newVal) => {
    const newParsed = newVal.map((item) => item.value);
    const joined = newParsed.join(' ');
    onSave(joined, param.id, param.param_type);
  };

  const notMultiSelect = () => (
    param.param_type === 'boolean'
      ? (
        <Row gutter="small" align="center">
          <Col span={3}>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={!!(+(param.value || '0'))}
              onChange={(checked) => {
                onSave(+checked, param.id);
              }}
            />
          </Col>
          <Col span={21}>
            {param.description}
          </Col>
        </Row>
      )
      : (
        <>
          <div>{param.description}</div>
          <Input
            type="text"
            step="1"
            min="0"
            value={value || ''}
            onKeyDown={(e) => (e.key === 'Backspace' ? true : (param.param_type === 'number' && (reg.test(e.key) || !regNumber.test(e.key))) && e.preventDefault())}
            onChange={(e) => setValue(e.target.value)}
          />
        </>
      )
  );

  return (
    <Row style={{ alignItems: 'center' }}>
      <Col span={20}>
        {param.param_type === 'soundstring' && value !== null
          ? (
            <>
              <div>{param.description}</div>
              <MultiSelect data={multiData} value={param.value} onSave={handleMultiSelect} />
            </>
          )
          : notMultiSelect()}
      </Col>
      <Col span={4}>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'flex-end' }}>
          {param.value !== value && <Button shape="circle" icon={<Icon component={FaSave} />} onClick={() => onSave(value, param.id, param.param_type)} />}
          {param.reset && <Button shape="circle" icon={<Icon component={FaRedo} />} onClick={() => resetParam(param.id)} />}
        </Space>
      </Col>
    </Row>
  );
}
export default Field;
