import React from 'react';
import { connect } from 'react-redux';
import { FaArrowLeft } from 'react-icons/fa';
import { getReportConfig } from '../../../../../../logic/store/redux/reports';
import { ButtonBack } from '../../../../components/table/TableTitle';

function BackToRootReport(props) {
  const changeReportType = () => {
    const { dispatch, reportConfig } = props;
    const {
      startDate, endDate, rootReport,
    } = reportConfig;
    dispatch(getReportConfig(
      {
        startDate,
        endDate,
        reportType: rootReport,
      },
    ));
  };

  return (
    <ButtonBack onClick={changeReportType} reportTable><FaArrowLeft size="14px" /></ButtonBack>
  );
}

function mapStateToProps(state) {
  return { reportConfig: state.reportConfig };
}

export default connect(mapStateToProps)(BackToRootReport);
