const FieldDescription = (fields) => {
  const fieldMap = {
    workplace_name: 'Рабочее место',
    all_cnt: 'Обслужено: Всего',
    close_cnt: 'Обслужено: Закрыто',
    created_on: 'Дата действия',
    neyavka_cnt: 'Обслужено: Закрыто по неявке',
    total_wait_time: 'Общая длительность ожидания',
    avg_wait_time: 'Средняя длительность ожидания',
    total_proc_time: 'Общая длительность обслуживания',
    avg_proc_time: 'Средняя длительность обслуживания',
    queue_name: 'Очередь',
    user_fio: 'Оператор',
    name: 'Название группы',
    total_work_time: 'Общее время работы',
    avg_work_time: 'Средняя время обслуживания',
    share_cnt: 'Талонов обработано',
    solved_questions: 'Кол-во решенных вопросов',
    mark1: 'Oценка: Очень плохо',
    mark2: 'Oценка: Плохо',
    mark3: 'Oценка: Нормально',
    mark4: 'Oценка: Хорошо',
    mark5: 'Oценка: Отлично',
    markavg: 'Средняя оценка',
    markcnt: 'Всего оценок',
    create_data: 'Дата обслуживания: Начало',
    close_data: 'Дата обслуживания: Окончание',
    ticket_number: '№ Талона',
    prior: 'Приоритет',
    ticket_mark: 'Оц кчств ослдвн',
    status: 'Статус',
    ndate: 'Дата',
  };

  return fieldMap[fields];
};

export default FieldDescription;
