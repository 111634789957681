import React from 'react';
import Modal from './Modal';
import { BtnsBox, BtnRemove } from '../Common2';

function DeleteWarning({
  confirmDel, toggleOpen, isOpen, name,
}) {
  return (
    <Modal title={`Удалить - ${name} ?`} isOpen={isOpen} toggleOpen={toggleOpen}>
      <BtnsBox>
        <BtnRemove valid onClick={confirmDel} remove>Удалить</BtnRemove>
        <BtnRemove cancel onClick={toggleOpen}>Отмена</BtnRemove>
      </BtnsBox>
    </Modal>
  );
}

export default DeleteWarning;
