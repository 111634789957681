import moment from 'moment';
import { GeneralReportMarkTemplate } from '../../../../../utils/formater';

export default function TableConfig(getColumnSearchProps) {
  return {
    columns: [
      {
        dataIndex: 'user_fio',
        title: 'Оператор',
        ...getColumnSearchProps('user_fio', 'оператора'),
      },
      {
        dataIndex: 'work_time',
        title: 'Время обслуживания талонов',
        sorter: (a, b) => a.work_time - b.work_time,
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'descend',
        render: (cell) => {
          if (cell !== null) return moment.utc(cell * 1000).format('HH:mm:ss');
          return '-';
        },
      },
      {
        dataIndex: 'work_day',
        title: 'Рабочих дней',
        sorter: (a, b) => a.work_day - b.work_day,
        sortDirections: ['descend', 'ascend'],
        render: (cell) => {
          if (cell !== null) return cell;
          return '-';
        },
      },
      {
        title: 'Талонов обработано',
        children: [
          {
            dataIndex: 'part_cnt',
            title: 'Всего',
            sorter: (a, b) => a.part_cnt - b.part_cnt,
            sortDirections: ['descend', 'ascend'],
            render: (cell) => {
              if (cell !== null) return cell;
              return '-';
            },
          },
          {
            dataIndex: 'tpd',
            title: 'В день',
            sorter: (a, b) => a.tpd - b.tpd,
            sortDirections: ['descend', 'ascend'],
            render: (cell) => {
              if (cell !== null) return cell;
              return '-';
            },
          },
        ],
      },
      {
        title: 'Оценка',
        children: [
          {
            dataIndex: 'mark_cnt',
            title: 'Всего',
            sorter: (a, b) => a.mark_cnt - b.mark_cnt,
            sortDirections: ['descend', 'ascend'],
            render: (cell) => {
              if (cell !== null) return cell;
              return '-';
            },
          },
          {
            dataIndex: 'avg_mark',
            title: 'Средняя',
            sorter: (a, b) => a.avg_mark - b.avg_mark,
            sortDirections: ['descend', 'ascend'],
            render: (cell) => {
              if (cell !== null) return GeneralReportMarkTemplate(`${cell * 20}%`);
              return '-';
            },
          },
        ],
      },
    ],
  };
}
