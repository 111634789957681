export default function TableConfig() {
  return {
    columns: [
      {
        dataIndex: 'queuename',
        title: 'Услуга',
      },
      {
        dataIndex: 'questname',
        title: 'Вопрос',
      },
      {
        dataIndex: 'cnt',
        title: 'Общее кол-во',
      },
      {
        dataIndex: 'sumformat',
        title: 'Общее время',
      },
      {
        dataIndex: 'avgformat',
        title: 'Среднее время',
      },
    ],
  };
}
