import React from 'react';
import { FaSave } from 'react-icons/fa';
import { connect } from 'react-redux';
import {
  Input, Select, Typography, Row, Col, Button, Checkbox, Space,
} from 'antd';
import Icon from '@ant-design/icons';

function RenderInputs({
  screenList, screenTypes,
  setScreen, nowScreen, dictOptions,
  updateScreen, isValid, addScreen,
  // eslint-disable-next-line no-unused-vars
  create, setScreenList, setInfoScreen,
}) {
  const handleInput = (e) => {
    const { name, value } = e.target;
    const validData = { ...nowScreen, [name]: value };
    setInfoScreen(name === 'type' && value === 'infoScreen');
    setScreen(validData);
    if (validData.headTitle && validData.type) {
      // setIsValid(true);
    }
  };

  const inputScreenTypes = ['inputScreen', 'multiScreen', 'addressScreen', 'periodScreen'];

  const inputOptions = [
    ...screenList.map((item) => ({
      id: item.screenId,
      name: item.headTitle,
    })),
    { id: 'print', name: 'На печать' },
  ];

  const ChangeCheck = () => {
    setScreenList((l) => l.map((el) => ({ ...el, isStart: false })));
    setScreen((v) => ({ ...v, isStart: !v.isStart }));
    // setIsValid(true);
  };

  const renderValidScreen = () => (create ? addScreen() : updateScreen());

  return (
    <Space direction="vertical" size="large">
      {nowScreen
          && (
            <Row gutter={[10, 10]}>
              <Col span={6}>
                <Typography.Text>Наименование:</Typography.Text>
                <Input
                  required
                  name="headTitle"
                  value={nowScreen.headTitle}
                  onChange={handleInput}
                />
                <Typography.Text type="secondary">
                  <small>Наименование - предназначено для удобного выбора экрана</small>
                </Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text>Заголовок экрана:</Typography.Text>
                <Input
                  name="title"
                  value={nowScreen.title}
                  onChange={handleInput}
                />
                <Typography.Text type="secondary">
                  <small>Текст на экране</small>
                </Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text>Подзаголовок:</Typography.Text>
                <Input
                  required
                  name="description"
                  value={nowScreen.description}
                  onChange={handleInput}
                />
                <Typography.Text type="secondary">
                  <small>Дополнительный текст на экране</small>
                </Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text>Тип экрана:</Typography.Text>
                <Select
                  style={{ width: '100%' }}
                  value={nowScreen.type}
                  onChange={(value) => handleInput({ target: { value, name: 'type' } })}
                  filterOption={
                    (input, opt) => (
                      opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    )
                  }
                >
                  {screenTypes.map((screenType) => (
                    <Select.Option value={screenType.id}>{screenType.name}</Select.Option>
                  ))}
                </Select>
                <Typography.Text type="secondary">
                  <small>Разные типы экранов</small>
                </Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text>Идентификатор экрана:</Typography.Text>
                <Input
                  required
                  name="screenSubType"
                  value={nowScreen.screenSubType}
                  onChange={handleInput}
                />
                <Typography.Text type="secondary">
                  <small>Идентфиккатор для кастомной логики работы экрана</small>
                </Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text>Перевод заголовока экрана:</Typography.Text>
                <Input
                  name="headTitle_eng"
                  value={nowScreen.headTitle_eng}
                  onChange={handleInput}
                />
                <Typography.Text type="secondary">
                  <small>Текст на экране второй язык</small>
                </Typography.Text>
              </Col>
              <Col span={nowScreen.type === 'multiScreen' ? 12 : 18} />
              {(inputScreenTypes.includes(nowScreen.type)) && (
                <Col span={6}>
                  <Typography.Text>Выберите доп. поле:</Typography.Text>
                  <Select
                    style={{ width: '100%' }}
                    value={nowScreen.currentTicketField}
                    onChange={(value) => handleInput({ target: { value, name: 'currentTicketField' } })}
                    filterOption={
                      (input, opt) => (
                        opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      )
                    }
                  >
                    {dictOptions.map((option) => (
                      <Select.Option value={option.id}>{option.name}</Select.Option>
                    ))}
                  </Select>
                  <Typography.Text type="secondary">
                    <small>Дополнительное поле</small>
                  </Typography.Text>
                </Col>
              )}
              {(inputScreenTypes.includes(nowScreen.type)) && (
                <Col span={6}>
                  <Typography.Text>Действие при нажатии:</Typography.Text>
                  <Select
                    style={{ width: '100%' }}
                    value={nowScreen.screenPath}
                    onChange={(value) => handleInput({ target: { value, name: 'screenPath' } })}
                    filterOption={
                      (input, opt) => (
                        opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      )
                    }
                  >
                    {inputOptions.map((option) => (
                      <Select.Option value={option.id}>{option.name}</Select.Option>
                    ))}
                  </Select>
                  <Typography.Text type="secondary">
                    <small>Переход на следующий экран</small>
                  </Typography.Text>
                </Col>
              )}
              {(nowScreen.type === 'infoScreen') && (
                <Col span={6}>
                  <Typography.Text>Информационный текст:</Typography.Text>
                  <Input
                    name="help"
                    value={nowScreen.help}
                    onChange={handleInput}
                  />
                  <Typography.Text type="secondary">
                    <small>Текст выводимый на экран при нажатии на кнопку информации</small>
                  </Typography.Text>
                </Col>
              )}
            </Row>
          )}
      <div>
        <Checkbox
          checked={nowScreen.isStart}
          onChange={ChangeCheck}
        >
          Назначить стартовым экраном
        </Checkbox>
      </div>
      <Button
        type="primary"
        size="large"
        disabled={!isValid}
        onClick={() => (isValid ? renderValidScreen() : null)}
        icon={<Icon component={FaSave} />}
      >
        <span>Сохранить</span>
      </Button>
    </Space>
  );
}

export default connect((state) => ({ terminal: state.handlePoints.config }))(RenderInputs);
