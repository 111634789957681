import React from 'react';
import { toast } from 'react-toastify';
import searchByKey from '../../../../../pages/private/subpages/statistics/reports/utils/seachByKey';
import {
  StructureBox, StructureSmallBox, StructureItem, StructureTitle,
} from '../../../../../common/ui/Common';

function ScreenSchema({ screens = [], onChange }) {
  const ScreenList = screens.slice(0);
  let cacheScreens;

  const indexOfParam = (array, param, item) => array
    .map((mapItem) => mapItem[param])
    .indexOf(item[param]);
  const filter = (param) => (item, pos, array) => indexOfParam(array, param, item) === pos;
  const removeDuplicates = (a, param) => a.filter(filter(param));

  const addScreenItem = (screenPath, parent, val) => {
    const screenList = ScreenList;
    let nextScreen = {};
    const newParents = parent.parents.slice(0);
    const newQueue = parent.queue.slice(0);
    const scanScreen = {
      title: 'Сканирование',
      name: 'Сканирование',
      id: 'scan',
      type: 'scan',
      parents: newParents,
      screenPath: '',
      nodes: [],
    };
    const preScreen = {
      title: 'Предварительная запись',
      name: 'Предварительная запись',
      id: 'preEntry',
      type: 'preEntry',
      parents: newParents,
      screenPath: '',
      nodes: [],
    };
    const preRecord = {
      title: 'Запись ПЗ',
      name: 'Запись ПЗ',
      id: 'preRecord',
      type: 'preRecord',
      parents: newParents,
      screenPath: '',
      nodes: [],
    };
    const pinCode = {
      title: 'Ввод пин-кода',
      name: 'Ввод пин-кода',
      id: 'pinCode',
      type: 'pineCode',
      parents: newParents,
      screenPath: '',
      nodes: [],
    };
    const printScreen = {
      title: 'Печать',
      name: 'Печать',
      id: 'print',
      type: 'print',
      parents: newParents,
      screenPath: '',
      nodes: [],
    };
    let treeNode;
    if (val !== '') newQueue.push(val);
    newParents.push(parent.id);
    switch (screenPath) {
      case 'print':
        treeNode = printScreen;
        break;
      case 'scan':
        treeNode = scanScreen;
        break;
      case 'preEntry':
        treeNode = preScreen;
        break;
      case 'pinCode':
        treeNode = pinCode;
        break;
      case 'preRecord':
        treeNode = preRecord;
        break;
      default:
        nextScreen = searchByKey(screenList, 'screenId', screenPath);
        treeNode = {
          title: nextScreen.title,
          name: nextScreen.headTitle,
          id: nextScreen.screenId,
          type: nextScreen.type,
          parents: newParents,
          queue: newQueue,
          screenPath: nextScreen.screenPath,
          nodes: [],
        };
        if (parent.parents.indexOf(screenPath) === -1) {
          // eslint-disable-next-line no-use-before-define
          treeNode.nodes = mapScreenItems(nextScreen, treeNode);
        } else {
          // this.props.error('Ошибка. Нельзя ссылаться на ранее выбранный экран.', 5000);
        }
        break;
    }
    return treeNode;
  };

  const mapScreenItems = (screen, parent) => {
    const items = [];
    const {
      screenPath, buttons: bt, screenId, bottomBlock,
    } = screen;

    const buttons = screen.type === 'infoScreen' ? bottomBlock?.buttons : bt;
    if (Object.hasOwnProperty.call(cacheScreens, screenId)) {
      return cacheScreens[screenId];
    }

    if (screenPath !== undefined && screenPath !== '') {
      const treeNode = addScreenItem(screenPath, parent);
      items.push(treeNode);
    } else if (buttons) {
      buttons.forEach((el) => {
        const q = el.params.name === 'queue';
        if (el.path === undefined || el.path === '') return;
        const treeNode = addScreenItem(el.path, parent, q ? el.params.value : '');
        items.push(treeNode);
      });
    }
    cacheScreens[screenId] = items;
    return items;
  };
  const renderScreenItems = (screen, arr) => {
    const {
      nodes, id, title, parents, name,
    } = screen;
    const elements = [];
    const isPrint = title === 'Печать' || title === 'Сканирование' || title === 'Предварительная запись' || /pinCode|preRecord|preEntry/.test(id);
    if (parents.indexOf(id) > -1) {
      toast.error('Зациклинная схема', 3000);
      return (
        <StructureSmallBox key={id} title="Изменить сцену">
          <StructureTitle
            key={`${id}title`}
            error
            print={isPrint}
            onClick={(e) => {
              e.stopPropagation();
              onChange(id);
            }}
          >
            {name}
          </StructureTitle>
        </StructureSmallBox>
      );
    }
    arr.push(id);
    const titleContainer = (
      <StructureTitle
        key={id}
        print={isPrint}
        onClick={(e) => {
          e.stopPropagation();
          if (!isPrint) onChange(id);
        }}
      >
        {name}
      </StructureTitle>
    );

    if (nodes.length > 0) {
      const noDubleNodes = removeDuplicates(nodes, 'id');
      noDubleNodes.forEach((node) => {
        const nextContainer = renderScreenItems(node, arr);
        elements.push(nextContainer);
      });
    }

    const screenNodesContainer = <StructureSmallBox key={`${id}box`} column={nodes.length > 0}>{elements}</StructureSmallBox>;

    const items = [titleContainer, screenNodesContainer];

    return (
      <StructureItem
        key={id}
        onClick={(e) => {
          e.stopPropagation();
          onChange(id);
        }}
      >
        {items}
      </StructureItem>
    );
  };
  const treeNodes = [];
  cacheScreens = {}; // Сброс кеша экранов
  const startScreen = searchByKey(ScreenList, 'isStart', true);

  const treeNode = {
    title: startScreen.title,
    name: startScreen.headTitle,
    id: startScreen.screenId,
    type: startScreen.type,
    parents: [],
    queue: [],
    screenPath: startScreen.screenPath,
    nodes: [],
  };
  treeNode.nodes = mapScreenItems(startScreen, treeNode);
  treeNodes.push(treeNode);
  return (
    <StructureBox>
      {screens.length > 0 && renderScreenItems(treeNodes[0], [])}
    </StructureBox>
  );
}

const compireProps = (prevProps, nextProps) => {
  const prevSc = prevProps.screens;
  const nextSc = nextProps.screens;
  const v = prevSc !== nextSc;
  return !v;
};

export default React.memo(ScreenSchema, compireProps);
