import React, { useContext } from 'react';
import {
  Select, Input, Typography, Space,
} from 'antd';

import terminalCtx from '../../../../../../../pages/public/terminal/context';
import Uppercase from '../../Components/modules/uppercase';

function Layouts() {
  const {
    data: { head: { fontFamily } }, data, dispatch, fonts,
  } = useContext(terminalCtx);

  const { head, general } = data;

  const onChange = (value, name, param) => dispatch({
    type: 'params', name, param, value: Number(value),
  });
  return (
    <Space direction="vertical" size="middle">
      <div>
        <Typography.Text type="secondary">Шрифт</Typography.Text>
        <Select
          value={fontFamily}
          style={{ width: '100%' }}
          onChange={(value) => dispatch({ type: 'fonts', value })}
          filterOption={
              (input, opt) => (opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
          }
        >
          {fonts.map((font) => (<Select.Option value={font.id}>{font.name}</Select.Option>))}
        </Select>
      </div>
      <div>
        <Typography.Text type="secondary">Ширина терминала</Typography.Text>
        <Input
          onChange={(e) => onChange(e.target.value, 'head', 'width')}
          value={head.width}
        />
      </div>
      <div>
        <Typography.Text type="secondary">Высота терминала</Typography.Text>
        <Input
          onChange={(e) => onChange(e.target.value, 'head', 'height')}
          value={head.height}
        />
      </div>
      <div>
        <Uppercase
          element="general"
          dispatch={dispatch}
          title="Поля ввода в Предварительной записи"
          nameParams="preEntryInput"
          value={general.preEntryInput}
        />
      </div>
    </Space>
  );
}

export default Layouts;
