export default function getWplace(connectedWplaces) {
  const wPlaces = connectedWplaces
    .filter((pl) => pl !== '')
    .map((place) => place.match(/\d+/)[0]);
  wPlaces.sort((a, b) => a - b);

  const out = [];
  let i;
  let j;
  let isNull = 0;
  for (i = 0; i < wPlaces.length; i++) {
    if (Number(wPlaces[i]) === 0) isNull++;
    if (!Number.isNaN(wPlaces[i]) && isNull < 2) {
      out.push(Number(wPlaces[i]));
      for (j = i + 1; j < wPlaces.length; j++) {
        if (Number(wPlaces[j - 1]) + 1 !== Number(wPlaces[j])) break;
      }
      j--;
      if (i === j) {
        out.push(',');
      } else if (i + 1 === j) {
        out.push(',', Number(wPlaces[j]), ',');
        i++;
      } else {
        out.push('-', Number(wPlaces[j]), ',');
        i += (j - i);
      }
    }
  }
  out.pop();
  return out.join('');
}
