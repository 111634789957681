import React from 'react';
import { Table } from 'antd';
import locale from 'antd/es/locale/ru_RU';
import 'antd/dist/antd.min.css';

function ReportTable(props) {
  const {
    columns, data, rowKey, loading, scroll = {}, handleChange, expandable,
  } = props;

  return (
    <Table
      locale={locale.Table}
      columns={columns}
      dataSource={data}
      rowKey={rowKey}
      bordered
      scroll={scroll}
      expandable={expandable}
      loading={loading}
      handleChange={handleChange}
      pagination={{
        locale: locale.Pagination,
      }}
    />
  );
}

export default ReportTable;
