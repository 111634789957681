const location = window.location.href;

export const debug = (location.indexOf('localhost') !== -1);
export const wsHost = !debug ? window.location.hostname : '192.168.3.121';
export const wsPort = !debug ? window.location.port : '48088';

export const limitFilter = (maxLimit) => (_, index) => index < maxLimit;

export const declOfNum = (number, words) => {
  const selectedWord = [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10 : 5];
  const wordId = (number % 100 > 4 && number % 100 < 20) ? 2 : selectedWord;
  return words[wordId];
};

export function saveInLocalStorage(stringsMap) {
  for (const key of Object.keys(stringsMap)) {
    localStorage.setItem(key, stringsMap[key]);
  }
}
