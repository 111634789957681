import React, { useState, useEffect } from 'react';
import axios from 'axios';
import validator from '../../../../../logic/utils/validator';

function VideoPlayer({
  inSoundAction, layout, setActiveEl, text, order,
}) {
  const [videos, setVideos] = useState([]);
  const [videoId, setVideoId] = useState(0);
  const [muted, setMuted] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');
  const {
    background, color1, color2, fontSize1, borderSize1, fontFamily, height, volume,
  } = layout.video;

  useEffect(() => {
    axios({
      method: 'get',
      url: '/api/videos',
    })
      .then((res) => {
        if (res.data.length > 0) {
          const v = res.data.filter((el) => el.show);
          setVideos(v);
          setCurrentVideo(v[0] ? v[0].url : '');
        }
      });
  }, []);

  useEffect(() => {
    setMuted(inSoundAction);
  }, [inSoundAction]);

  const nextVideo = () => {
    const currentId = videoId;
    const nextId = (currentId < (videos.length - 1)) ? (currentId + 1) : 0;
    const newVideo = videos[nextId].url;
    if (validator(newVideo)) {
      setVideoId(nextId);
      setCurrentVideo(newVideo);
    } else {
      nextVideo();
    }
  };

  const videoStyle = {
    order,
    maxHeight: height === 'auto' ? '100%' : height,
    minHeight: height === '100%' ? 0 : height,
  };

  const onVideoStart = (vid) => {
    // eslint-disable-next-line no-param-reassign
    vid.target.volume = volume;
  };

  console.log(currentVideo);

  return (
    <div
      className="layout__body__info__video"
      style={{
        ...layout.video,
        border: `${borderSize1}px solid ${color2}`,
        background,
        ...videoStyle,
        color: color1,
        fontSize: fontSize1,
        borderRadius: layout.borderRadius ? '12px' : 0,
      }}
      onClick={() => setActiveEl('video')}
    >
      {currentVideo
        ? (
          <video
            src={currentVideo}
            onLoadStart={onVideoStart}
            width="100%"
            height="auto"
            id="videoBox"
            autoPlay
            loop={!(videos.length > 1)}
            onEnded={nextVideo}
            muted={muted}
          />
        )
        : <p style={{ textAlign: 'center', fontFamily }}>{ text }</p>}

    </div>
  );
}

export default React.memo(VideoPlayer);
