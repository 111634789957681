import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Button, Typography, Table } from 'antd';
import moment from 'moment';
import { PreloaderBox } from '../../../../../common/ui/Common';
import TableConfig from './TableConfig';
import Preloader from '../../../components/preloader/Preloader';
import { useSearchPropsFactory } from '../../../utils/useSearch';

const { Title } = Typography;

function Archive({ preloader }) {
  const [ReportParams, setReportParams] = useState();
  const searchPropsFactory = useSearchPropsFactory();

  const getRepotData = useCallback(async () => {
    preloader(true);
    const rpRequest = await axios.get('/api/reports');
    const { data: reportsData } = rpRequest;

    const params = {
      items: reportsData,
      TableConfig: TableConfig(searchPropsFactory),
    };

    setReportParams((v) => ({ ...v, ...params }));
    preloader(false);
  }, [preloader]);

  const handleTableIcon = async (e, row) => {
    const { dataset } = e.target;
    const { id: fileId } = row;

    if (dataset.name === 'del') {
      const filterData = {
        id: fileId,
      };

      const token = window.localStorage.getItem('token');
      await axios({
        method: 'delete',
        url: `/api/reports/?token=${token}`,
        data: filterData,
      });
      getRepotData();
    }
  };

  useEffect(() => {
    getRepotData();
  }, [getRepotData]);

  return (
    <div>
      <Title style={{ textAlign: 'center', color: '#25547A' }}>Архив</Title>
      <Button onClick={getRepotData} size="large" type="primary">
        Обновить
      </Button>
      { ReportParams ? (
        <Table
          dataSource={ReportParams.items.map((item) => ({ ...item, createdAt: moment(item.createdAt).format('DD.MM.YYYY HH:mm') }))}
          columns={ReportParams.TableConfig.columns}
          onRow={(row) => ({
            onClick: (e) => { handleTableIcon(e, row); },
          })}
        />
      )
        : (
          <PreloaderBox>
            <Preloader isOpen small />
          </PreloaderBox>
        )}
    </div>
  );
}

export default Archive;
