import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import ReportList from './ReportList/ReportList';
import { ReportBigBox } from '../../../../../common/ui/Common';
import filterStore, { resetReportStore } from '../../../../../logic/store/pullstate/filterStore';
import getReportName from './utils/getReportName';

import ChooseReporotType from './components/ChooseReportType';

function Reports(props) {
  const { preloader } = props;
  const [view, setView] = useState(null);
  const [loader, setLoader] = useState(null);

  const reportConfig = filterStore.useState((s) => s);
  const { reportType } = reportConfig;

  useEffect(() => () => resetReportStore(), []);

  return (
    <ReportBigBox>
      <Typography.Title style={{ textAlign: 'center', color: '#25547A' }}>
        Отчет
        {` ${getReportName(reportType).toLowerCase()}`}
      </Typography.Title>

      <ChooseReporotType />

      <ReportList
        preloader={preloader}
        view={view}
        loader={loader}
        setLoader={setLoader}
        setView={setView}
      />
    </ReportBigBox>
  );
}

export default Reports;
