export default function TableConfig() {
  return {
    columns: [
      {
        dataIndex: 'ndate',
        title: 'Дата/часы',
      },
      {
        dataIndex: 'd00',
        title: '00:00 01:00',
      },
      {
        dataIndex: 'd01',
        title: '01:00 02:00',
      },
      {
        dataIndex: 'd02',
        title: '02:00 03:00',
      },
      {
        dataIndex: 'd03',
        title: '03:00 04:00',
      },
      {
        dataIndex: 'd04',
        title: '04:00 05:00',
      },
      {
        dataIndex: 'd05',
        title: '05:00 06:00',
      },
      {
        dataIndex: 'd06',
        title: '06:00 07:00',
      },
      {
        dataIndex: 'd07',
        title: '07:00 08:00',
      },
      {
        dataIndex: 'd08',
        title: '08:00 09:00',
      },
      {
        dataIndex: 'd09',
        title: '09:00 10:00',
      },
      {
        dataIndex: 'd10',
        title: '10:00 11:00',
      },
      {
        dataIndex: 'd11',
        title: '11:00 12:00',
      },
      {
        dataIndex: 'd12',
        title: '12:00 13:00',
      },
      {
        dataIndex: 'd13',
        title: '13:00 14:00',
      },
      {
        dataIndex: 'd14',
        title: '14:00 15:00',
      },
      {
        dataIndex: 'd15',
        title: '15:00 16:00',
      },
      {
        dataIndex: 'd16',
        title: '16:00 17:00',
      },
      {
        dataIndex: 'd17',
        title: '17:00 18:00',
      },
      {
        dataIndex: 'd18',
        title: '18:00 19:00',
      },
      {
        dataIndex: 'd19',
        title: '19:00 20:00',
      },
      {
        dataIndex: 'd20',
        title: '20:00 21:00',
      },
      {
        dataIndex: 'd21',
        title: '21:00 22:00',
      },
      {
        dataIndex: 'd22',
        title: '22:00 23:00',
      },
      {
        dataIndex: 'd23',
        title: '23:00 00:00',
      },
      {
        dataIndex: 'all_cnt',
        title: 'Всего за день',
      },
    ],
  };
}
