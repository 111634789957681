const recurseBox = (o1, old2) => {
  if (!o1.text) return null;
  const o2 = JSON.parse(JSON.stringify(old2));
  const obj1 = {
    text: { ...o1.text },
    layout: o1.layout ? { ...o1.layout } : { ...o1.style.layouts[0] },
  };
  const recurse = (obj, obj2, parent) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in obj) {
      if (typeof obj[prop] === 'object') {
        recurse(obj[prop], obj2, prop);
      } else if (parent === 'text') {
        const regNumber = /[0-9]'/;
        let name = prop;
        if (!regNumber.test(name)) name += 1;
        const reg = name.replace('Title', '');
        // eslint-disable-next-line no-param-reassign
        obj2[parent][reg] = obj[prop];
      } else if (parent === 'layout') {
        // eslint-disable-next-line no-param-reassign
        obj2[parent][prop] = obj[prop];
        // eslint-disable-next-line no-param-reassign
      } else if (obj2.layout[parent]) obj2.layout[parent][prop] = obj[prop];
    }
  };
  recurse(obj1, o2);
  return o2;
};

export default recurseBox;
