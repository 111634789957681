import Request from '../../../../../../../../../../../logic/utils/request';

export default async function SqlQuery(time, endTime) {
  const query = `select s.* from (select q.fullname, coalesce(w.all_cnt, 0) as all_cnt from(
    (select id, fullname from eq.tqueue) q left join 
    (select q_id, count(distinct ticket_id) as all_cnt from eq.ttickethistory
    where modified_on::timestamp >= '${time}' and modified_on::timestamp <= '${endTime}' and status in(3, 4)
    group by q_id) w on w.q_id = q.id)) s order by all_cnt desc`;

  const dataObjects = [];
  const res = await Request('/server/api/genreportfromquery/', 'get', {
    querySql: `${query}`,
  });

  const answer = res.data.data;
  answer.forEach((v) => {
    dataObjects.push({ ...v });
  });

  return { dataObjects, query };
}
