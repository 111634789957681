import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import Modal from '../../../../../../common/ui/Modal/Modal';
import request from '../../../../../../logic/utils/request';
import RenderInputs from './RenderInputs';
import ModalButtons from '../../../../../../common/ui/Modal/ModalButtons';

function NewGrouping({
  isOpen, toggleOpen, fetchBunch, fieldUpdate, setBunchData,
  bunchData, initialData, setFieldUpdate, preloader,
  showError, write, msData,
}) {
  const [isValid, setIsValid] = useState(false);

  const resetModal = () => {
    setBunchData({ ...initialData });
    toggleOpen();
    setFieldUpdate(false);
    setIsValid(false);
    fetchBunch();
  };

  const addField = async () => {
    if (isValid) {
      const newData = {
        ...bunchData,
        bunchObjectsIds: bunchData.bunchObjectsIds.map((el) => el.id),
      };
      await request('/server/api/bunch/', 'add', { ...newData });
      toast.success('Выполнено');
      fetchBunch();
      resetModal();
    }
  };

  const updateField = async () => {
    if (isValid) {
      const newData = {
        ...bunchData,
        bunchObjectsIds: bunchData.bunchObjectsIds.map((el) => el.id),
      };
      const itemId = bunchData.id;
      delete newData.id;
      await request(`/server/api/bunch/${itemId}/`, 'update', { ...newData });
      toast.success('Выполнено');
      fetchBunch();
      resetModal();
    }
  };

  useEffect(() => {
    if (bunchData.description !== '' && bunchData.name !== '' && bunchData.bunchType !== '' && bunchData.bunchType !== 'none' && bunchData.bunchObjectsIds.length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [bunchData]);

  return (
    <>
      <div>
        <Button type="primary" size="large" onClick={toggleOpen}>Добавить</Button>
      </div>
      <Modal title={fieldUpdate ? 'Редактирование группировки' : 'Добавление группировки'} isOpen={isOpen} toggleOpen={resetModal}>
        <RenderInputs
          fieldData={bunchData}
          setFieldData={setBunchData}
          setIsValid={setIsValid}
          preloader={preloader}
          write={write}
          fieldUpdate={fieldUpdate}
          showError={showError}
          msData={msData}
        />
        <div style={{ height: 20 }} />
        <ModalButtons
          isValid={isValid}
          update={fieldUpdate}
          addFunc={addField}
          updateFunc={updateField}
          reset={resetModal}
        />
      </Modal>
    </>
  );
}

export default React.memo(NewGrouping);
