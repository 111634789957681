import React from 'react';
import {
  FilterBox, FilterBoxTitle, FilterItemBox, FilterLineBox,
} from '../style';
import AllFilter from '../utils/ServedFilters/AllFilter';

function IgnoreValue({ changeFilter }) {
  return (
    <FilterBox>
      <FilterBoxTitle>Учитывать только</FilterBoxTitle>
      <FilterItemBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="totalproc"
            title="Время обслуживания"
            smallTitle="true"
            type="Big"
          />
        </FilterLineBox>
      </FilterItemBox>
    </FilterBox>
  );
}
export default IgnoreValue;
