import moment from 'moment';

export default function TableConfig(users, searchPropsFactory) {
  function timeFormatter(cell) {
    const date = new Date(cell);
    return moment(date).format('DD.MM.YYYY HH:mm:ss');
  }

  const actions = {
    0: 'Неизвестное действие',
    1: 'Авторизация',
    2: 'Отключение',
    3: 'Создание объекта на сервере',
    4: 'Удаление объекта на сервере',
    5: 'Изменение объекта на сервере',
    6: 'Изменение параметров сервера',
    7: 'Вход по веб',
    8: 'Выход по веб (или удаление сессии по окончанию времени жизни сессии)',
  };

  return {
    columns: [
      {
        dataIndex: 'created_on',
        title: 'Время',
        fixed: 'left',
        ...searchPropsFactory('created_on', 'Время'),
        render: timeFormatter,
      },
      {
        dataIndex: 'created_by_id',
        title: 'Пользователь',
        fixed: 'left',
        ...searchPropsFactory('created_by_id', 'Пользователю'),
        render: (cell) => users[cell],
      },
      {
        dataIndex: 'action',
        title: 'Событие',
        fixed: 'left',
        ...searchPropsFactory('action', 'действие'),
        render: (cell) => actions[cell],
      },
      {
        dataIndex: 'comment',
        title: 'Комментарий',
        ...searchPropsFactory('comment', 'комментарий'),
        render: (value) => (
          value
        ),
      },
    ],
  };
}
