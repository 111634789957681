import React from 'react';
import Operator from './Tables/Operator/Table';
import Queue from './Tables/Queue/Table';
import ReportLine from './Graphic/Line/ReportLine';
import ReportBar from './Graphic/Bar/ReportBar';
import ReportCard from './Card/ReportCard';
import DoughnutReport from './Graphic/Dounghnuts/DoughnutsReport';
import AvgTimeGraphic from './Graphic/AvgTimeGraphic/AvgTimeGraphic';
import DataPickers from './DatePickers/DatePickers';
import filterStore from '../../../../../../../../logic/store/pullstate/filterStore';
import 'moment/locale/ru';

function GeneralReport(props) {
  const { setView, reportType } = props;

  const reportStore = filterStore.useState((s) => s);
  const { startDate, endDate } = reportStore;

  return (
    <>
      <DataPickers
        reportType={reportType}
        setView={setView}
      />

      <ReportCard
        view={props.view}
        setView={props.setView}
        startDate={startDate}
        endDate={endDate}
      />
      <AvgTimeGraphic
        view={props.view}
        loader={props.loader}
        setLoader={props.setLoader}
        startDate={startDate}
        endDate={endDate}
      />
      <ReportBar
        view={props.view}
        loader={props.loader}
        setLoader={props.setLoader}
        startDate={startDate}
        endDate={endDate}
      />
      <ReportLine
        view={props.view}
        loader={props.loader}
        setLoader={props.setLoader}
        startDate={startDate}
        endDate={endDate}
      />
      <DoughnutReport
        view={props.view}
        loader={props.loader}
        setLoader={props.setLoader}
        startDate={startDate}
        endDate={endDate}
      />
      <Operator
        view={props.view}
        loader={props.loader}
        setLoader={props.setLoader}
        startDate={startDate}
        endDate={endDate}
      />
      <Queue
        view={props.view}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  );
}

export default GeneralReport;
