import iconFormatter from '../../../../utils/iconFormatter';
import { dateFormat } from '../../../../../../old/utils/tableFormatters';

export default null;

export function TableConfig(searchPropsFactory) {
  return [
    {
      dataIndex: 'name',
      title: 'Имя',
      key: 'name',
      ...searchPropsFactory('name', 'Имя'),
    },
    {
      dataIndex: 'rus_name',
      title: 'Наименование',
      key: 'rus_name',
    },
    {
      dataIndex: 'type',
      title: 'Тип',
      key: 'type',
    },
    {
      dataIndex: 'description',
      title: 'Описание',
      key: 'description',
    },
    {
      dataIndex: 'created_on',
      key: 'created_on',
      title: 'Дата создания',
      ...searchPropsFactory('created_on', 'Дата создания'),
      render: (value) => (
        <p>{dateFormat(value)}</p>
      ),
    },
    {
      dataIndex: 'modified_by_username',
      title: 'Изменен',
      key: 'modified_by_username',
    },
    {
      dataIndex: 'modified_on',
      title: 'Когда изменен',
      key: 'modified_on',
      render: (value) => (
        <p>{dateFormat(value)}</p>
      ),
    },
    {
      dataIndex: 'Settings',
      title: '',
      key: 'Settings',
      render: () => (
        iconFormatter({ write: true, update: true })
      ),
    },
  ];
}
