import React, { useState, useEffect, useRef } from 'react';
import FindPosition from '../../../../../../../pages/private/components/Input/findPosition';

function BoxShadow({
  value, dispatch, element, title,
}) {
  const [open, setOpen] = useState(false);
  const [bottom, setBottom] = useState(false);
  const [shadow, setShadow] = useState({});
  const box = useRef(null);

  useEffect(() => {
    let arr = value.split(' ');
    arr = arr.map((el) => el.replace('px', ''));
    setShadow({
      color: arr[3],
      left: arr[0],
      top: arr[1],
      blur: arr[2],
    });
  }, [value]);

  const onChange = ({ index, v }) => {
    const sh = { ...shadow, [index]: v };
    const newShadow = `${sh.left}px ${sh.top}px ${sh.blur}px ${sh.color}`;
    dispatch({
      type: 'params',
      name: element,
      param: 'boxShadow',
      value: newShadow,
    });
  };

  const toggle = () => {
    FindPosition(box.current, setBottom);
    setOpen(!open);
  };

  return (
    <span className="box__color">
      {/* eslint-disable-next-line react/no-danger */}
      <span className="settings__styles__el__label" dangerouslySetInnerHTML={{ __html: title }} />
      <div className="settings__styles__el">
        <div
          className={`settings__btn_to_change${open ? ' theme_light_accent' : ''}`}
          onClick={toggle}
        >
          {open ? 'Закрыть' : 'Изменить'}
        </div>
        <div ref={box} style={{ position: 'relative' }}>
          {open && (
          <div
            className="box__align"
            style={{
              position: 'absolute',
              width: '160px',
              top: bottom ? 'auto' : 0,
              bottom: bottom ? '40px' : 'auto',
            }}
          >
            <div className="box__input_margin">
              <p> Слева </p>
              <input
                type="number"
                onChange={(e) => onChange({ index: 'left', v: Number(e.target.value) })}
                value={shadow.left}
              />
            </div>
            <div className="box__input_margin">
              <p> Сверху </p>
              <input
                type="number"
                onChange={(e) => onChange({ index: 'top', v: Number(e.target.value) })}
                value={shadow.top}
              />
            </div>
            <div className="box__input_margin">
              <p> Размытие </p>
              <input
                type="number"
                min={0}
                onChange={(e) => onChange({ index: 'blur', v: Number(e.target.value) })}
                value={shadow.blur}
              />
            </div>
          </div>
          )}
        </div>
      </div>
    </span>
  );
}

export default BoxShadow;
