import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import request from '../../../../../../../logic/utils/request';
import ValuesTable from './ValuesTable';

function BookValues({
  bookId, preloader, showError, write,
}) {
  const [book, setBook] = useState(null);

  const fetchBook = useCallback(async () => {
    try {
      const res = await request(`/server/api/dictionary/${bookId}/`, 'get');
      setBook(res.data.data);
    } catch (e) {
      showError(e.data.error);
    }
  }, [showError, bookId]);

  useEffect(() => {
    fetchBook();
  }, [fetchBook]);

  const addValue = async (e, vall = 1) => {
    const val = vall;
    const initialData = {
      dictionary_id: book.id,
      name: `Свойство ${book.values.length + val}`,
      prior: 0,
      handjob: false,
      valprefix: '',
    };
    preloader();
    try {
      await request('/server/api/dictionaryvalue/', 'add', initialData);
      await fetchBook();
      toast.success('Выполнено');
    } catch (err) {
      if (Number(err.data.error.code) === 24) {
        addValue(null, val + 1);
      } else {
        showError(err.data.error);
      }
    }
    preloader();
  };

  return (
    <div className="dopFieldsTableWrapper">
      <h2>Варианты выбора</h2>
      <Button size="large" type="primary" onClick={addValue}>Добавить выбор</Button>
      {book && (
        <ValuesTable
          write={write}
          valuesList={book.values}
          setValuesList={(values) => setBook({ ...book, values })}
          fetchBook={fetchBook}
        />
      )}
    </div>
  );
}
export default BookValues;
