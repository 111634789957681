import React, { useState } from 'react';
import { Button, Space } from 'antd';
import Modal from '../../../../../../../common/ui/Modal/Modal';
import request from '../../../../../../../logic/utils/request';
import RenderInputs from './RenderInputs';

function NewDay({
  isOpen, toggleOpen, fetchData, dayUpdate,
  dayData, setDayData, setDayUpdate, preloader, intervalList, defaultWeekList, write,
}) {
  const [isValid, setIsValid] = useState(false);

  const resetModal = () => {
    setDayData({ ...dayData });
    toggleOpen();
    setIsValid('');
    setDayUpdate(false);
  };

  const addDay = async () => {
    if (isValid) {
      preloader();
      await request('/server/api/dworkday/', 'add', dayData);
      fetchData();
      resetModal();
      preloader();
    }
  };

  const updateDay = async () => {
    if (isValid) {
      const { id, ...body } = dayData;

      preloader();
      await request(`/server/api/dworkday/${id}/`, 'update', body);
      fetchData();
      resetModal();
      preloader();
    }
  };

  return (
    <>
      {write && (
        <Button type="primary" size="large" onClick={toggleOpen}>
          Добавить
        </Button>
      )}
      <Modal title={dayUpdate ? 'Редактирование дня' : 'Добавление дня'} isOpen={isOpen} toggleOpen={resetModal}>
        <div style={{ width: '100%' }}>
          <RenderInputs
            dayUpdate={dayUpdate}
            defaultWeekList={defaultWeekList}
            dayData={dayData}
            intervalList={intervalList}
            setDayData={setDayData}
            setIsValid={setIsValid}
            write={write}
          />
          {write && (
            <Space direction="horizontal" size="large" style={{ justifyContent: 'center', width: '100%' }}>
              <Button type="primary" onClick={dayUpdate ? updateDay : addDay}>
                Сохранить
              </Button>
              <Button onClick={resetModal} cancel>Отменить</Button>
            </Space>
          )}
        </div>
      </Modal>
    </>
  );
}

export default React.memo(NewDay);
