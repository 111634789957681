export default {
  main: '#fafafa',
  dmain: '#eaeaea',
  lmain: 'rgba(209, 208, 241, 0.1)',
  green: '#a4c328',
  greenl: '#3b5e3c',
  accent: '#63c2de',
  accentl: '#576d7b',
  bg: '#23282c',
  bgTable: '#353b3f',
  bgButton: '#23282c',
  lightText: '#454545',
  text: '#dcdcdc',
  disable: '#23282c',
  disableText: '#23282c',
  buttonAccent: '#63c2de',
  buttonHover: '#f3f3f3',
  buttonText: '#3a4149',
  blue: '#81c4ca',
};
