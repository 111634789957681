import React, { useContext } from 'react';
import { Space } from 'antd';
import BoardElements from './BoardElements';
import StyleSettings from './StyleSettings/StyleSettings';
import CommonSettings from './CommonSettings';
import UploadImages from './UploadImages';
import BoardCtx from '../../../../../../../pages/public/board/utils/BoardCtx';

function Editor() {
  const { layout, dispatch } = useContext(BoardCtx);
  const items = [
    {
      name: 'Фоновое изображение', value: 'background', img: layout.images.background, tooltipText: 'подсказка 1',
    },
    {
      name: 'Изображение в шапке', value: 'headerBg', img: layout.images.headerBg, tooltipText: 'подсказка 2',
    },
    {
      name: 'Логотип в шапке', value: 'headerLogo', img: layout.images.headerLogo, tooltipText: 'подсказка 3',
    },
  ];

  console.log(layout);
  return (
    <Space direction="vertical" size="large">
      <CommonSettings />
      <UploadImages dispatch={dispatch} items={items} />
      <BoardElements />
      <StyleSettings />
    </Space>
  );
}

export default Editor;
