import React from 'react';
import { useSelector } from 'react-redux';
import {
  BodyContainer, ScanInfoHead, ScanInfoValue, Body,
} from '../../../utils/style';
import getWplace from '../print/getWplace';

function TicketInfo({ children }) {
  const { infoTicket, style } = useSelector((state) => state);
  let avia;
  let { created_on: createdOn, phonenumber } = infoTicket.ticket;
  const {
    dictionary, number, qFullName,
  } = infoTicket.ticket;
  const area = getWplace(infoTicket.connectedWplaces);
  dictionary.forEach((item, index) => {
    if (item.fn === 'Номер авиа накладной') {
      if (item.fv[0] !== null) {
        avia = item.fv[0].split('');
        avia.splice(1, 0, '-');
        avia.splice(7, 0, '-');
        avia.join(' ');
      }
      dictionary[index].fv[0] = avia;
    }
  });

  createdOn = createdOn.split('').splice(0, 16).join('');
  const phone = phonenumber.split('');
  const vPhone = phone.length > 0;
  if (vPhone) {
    phone.splice(2, 0, ' (');
    phone.splice(6, 0, ') ');
    phone.splice(10, 0, '-');
    phone.splice(13, 0, '-');
  }
  phonenumber = phone.join('');

  return (
    <BodyContainer style={style.body}>
      <Body>
        <ScanInfoHead>Информация по талону</ScanInfoHead>
        <ScanInfoValue>
          Название услуги:
          <div>{qFullName}</div>
        </ScanInfoValue>
        <ScanInfoValue>
          Номер:
          <div>{number}</div>
        </ScanInfoValue>

        {dictionary.map((item) => (
          <ScanInfoValue key={item.ticketFieldName}>
            {item.ticketFieldName}
            :
            {' '}
            <div>{item.fvNames}</div>
          </ScanInfoValue>
        ))}

        {area && (
        <ScanInfoValue>
          Ожидайте у окон:
          <div>{area}</div>
        </ScanInfoValue>
        )}
        <ScanInfoValue>
          Талон выдан:
          <div>{createdOn}</div>
        </ScanInfoValue>
        {vPhone && (
        <>
          <ScanInfoHead>Дополнительная информация:</ScanInfoHead>
          <ScanInfoValue>
            Телефон:
            <div>{phonenumber}</div>
          </ScanInfoValue>
        </>
        )}
        {children}
      </Body>
    </BodyContainer>
  );
}

export default TicketInfo;
