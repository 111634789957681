import React from 'react';
import moment from 'moment';
import Button from 'antd/es/button';
import randomId from '../../../../../../../../logic/utils/randomId';

const parseAudio = (audio) => {
  if (!audio) return <span>Записи нет</span>;
  const audioArr = audio.split(',');
  return audioArr.map((record, idx) => {
    const audioPath = record.split('/');
    const src = `/records/${audioPath[audioPath.length - 1]}`;
    // eslint-disable-next-line jsx-a11y/media-has-caption
    return <audio key={randomId()} src={src} controls style={idx !== 0 ? { marginTop: '10px' } : {}} />;
  });
};

export default function TableConfig(moreInfo) {
  return (
    [
      {
        title: 'Информация о талоне',
        children: [
          {
            title: 'Id',
            dataIndex: 'ticket_id',
            key: 'ticket_id',
            width: 100,
            fixed: 'left',
            sorter: (a, b) => a.ticket_id - b.ticket_id,
          },
          {
            title: '№',
            dataIndex: 'ticket_number',
            key: 'ticket_number',
            width: 100,
            fixed: 'left',
          },
        ],
      },
      {
        title: 'Очередь',
        dataIndex: 'queue_name',
      },
      {
        title: 'Оператор',
        dataIndex: 'user_fio',
      },
      {
        title: 'Рабочее место',
        dataIndex: 'workplace_name',
      },

      {
        title: 'Длительность',
        children: [
          {
            title: 'Ожидания',
            dataIndex: 'total_wait_time',
            width: 160,
            sorter: (a, b) => {
              const aToSec = new Date(moment(a.total_wait_time, 'HH:mm:ss')).getTime();
              const bToSec = new Date(moment(b.total_wait_time, 'HH:mm:ss')).getTime();
              return (aToSec - bToSec);
            },
          },
          {
            title: 'Oбслуживания',
            dataIndex: 'total_proc_time',
            width: 160,
            sorter: (a, b) => {
              const aToSec = new Date(moment(a.total_proc_time, 'HH:mm:ss')).getTime();
              const bToSec = new Date(moment(b.total_proc_time, 'HH:mm:ss')).getTime();
              return (aToSec - bToSec);
            },
          },
        ],
      },

      {
        title: 'Дата',
        children: [
          {
            title: 'Создания',
            dataIndex: 'create_data',
            key: 'create_data',
            width: 160,
            sorter: (a, b) => {
              const aToSec = new Date(moment(a.create_data, 'DD.MM.YYYY HH:mm:ss')).getTime();
              const bToSec = new Date(moment(b.create_data, 'DD.MM.YYYY HH:mm:ss')).getTime();
              return (aToSec - bToSec);
            },
          },
          {
            title: 'Вызова',
            dataIndex: 'start_proc_ticket',
            key: 'start_proc_ticket',
            width: 160,
          },
          {
            title: 'Закрытия',
            dataIndex: 'close_data',
            key: 'close_data',
            width: 160,
            sorter: (a, b) => {
              const aToSec = new Date(moment(a.close_data, 'DD.MM.YYYY HH:mm:ss')).getTime();
              const bToSec = new Date(moment(b.close_data, 'DD.MM.YYYY  HH:mm:ss')).getTime();
              return (aToSec - bToSec);
            },
          },
        ],
      },

      {
        title: 'Источник',
        dataIndex: 'source',
        width: 200,
      },
      {
        title: 'Кол-во решенных вопросов',
        dataIndex: 'solved_questions',
        width: 150,
        sorter: (a, b) => a.solved_questions - b.solved_questions,
      },
      {
        title: 'Приоритет',
        dataIndex: 'prior',
        width: 130,
        filters: [
          {
            text: 'Обычный',
            value: 'Обычный',
          },
          {
            text: 'Высокий',
            value: 'Высокий',
          },
        ],
        onFilter: (value, record) => (record.prior.indexOf(value) === 0),
      },
      {
        title: 'Предварительная запись',
        dataIndex: 'preorderstatus',
        width: 180,
        filters: [
          {
            text: 'Да',
            value: 'Да',
          },
          {
            text: 'Нет',
            value: 'Нет',
          },
        ],
        onFilter: (value, record) => (record.preorderstatus.indexOf(value) === 0),
      },
      {
        title: 'Оценка',
        dataIndex: 'ticket_mark',
        width: 100,
        sorter: (a, b) => a.ticket_mark - b.ticket_mark,
      },
      {
        title: 'Аудио запись',
        dataIndex: 'audio',
        width: 320,
        render: (_, res) => {
          const { audio } = res;
          return (
            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
            >
              {parseAudio(audio)}
            </div>
          );
        },
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        fixed: 'right',
        width: 150,
      },
      {
        title: '',
        dataIndex: '',
        fixed: 'right',
        width: 120,
        render: (_, row) => (
          <Button
            default
            onClick={() => {
              moreInfo(row);
            }}
          >
            История
          </Button>
        ),
      },
    ]
  );
}
