import React from 'react';
import {
  LineDescription, LineValue, ReportLine, SomeLineColumn,
} from '../../../../../components/report/ReportTableColumns';

export function DubleLineTemplate(
  firstValueType,
  firstItemName,
  firstItemValue,
  secondValueType,
  secondItemName,
  secondItemValue,
) {
  return (
    <SomeLineColumn>
      <ReportLine key={firstItemName}>
        <LineDescription>{firstItemName}</LineDescription>
        <LineValue>{firstItemValue || (firstValueType === 'count' ? '0' : '00:00:00')}</LineValue>
      </ReportLine>
      <ReportLine key={secondItemName}>
        <LineDescription>{secondItemName}</LineDescription>
        <LineValue>{secondItemValue || (secondValueType === 'count' ? '0' : '00:00:00')}</LineValue>
      </ReportLine>
    </SomeLineColumn>
  );
}

export default null;
