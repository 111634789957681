import React, { useRef } from 'react';
import { toast } from 'react-toastify';

function PreentryScreen({ styleConfig }) {
  const { button, bottomButton } = styleConfig;

  const pinRef = useRef();

  const goHome = () => {
    window.location.reload();
  };

  const checkPin = async () => {
    const pin = pinRef.current.value;
    if (pin.length < 4) {
      toast.error('Пин-код должен содержать минимум 4 символа');
    }
  };

  return (
    <div className="ticketProcess">
      <div className="preentryScreen">
        <div className="preentryScreen__container">
          <p style={{ fontSize: '16px' }} className="ticketProcess__text">Пожалуйста, введите пин-код, полученный во время предварительной записи</p>
          <input type="number" className="ticketProcess__input" style={{ width: '100%', fontSize: '28px' }} inputMode="decimal" ref={pinRef} />
          <button
            type="button"
            onClick={checkPin}
            className="websTeminalScreen__button"
            style={{ background: button.background, boxShadow: button.boxShadow, zIndex: 5 }}
          >
            <div style={{ alignItems: 'center' }}>
              <div className="button__mainText" style={{ color: button.color }}>Получить талон</div>
            </div>
          </button>
        </div>
        <button
          type="button"
          style={{ background: bottomButton.background }}
          className="websTeminalScreen__button button__bottom"
          onClick={goHome}
        >
          <img src={bottomButton.home.background} alt="home" />
          {' '}
          На главную
        </button>
      </div>
    </div>
  );
}

export default PreentryScreen;
