export default ({ button }) => [
  {
    title: 'Маленькая', value: button.widthS, paramName: 'widthS', parentName: 'button',
  },
  {
    title: 'Средняя', value: button.widthM, paramName: 'widthM', parentName: 'button',
  },
  {
    title: 'Большая', value: button.widthL, paramName: 'widthL', parentName: 'button',
  },
];
