import React, {
  useEffect, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Body } from '../../../utils/style';
import Request from '../request';
import {
  resetTicket, setTextError, setPre,
} from '../../../../store/actions';

function CanvasPre(props) {
  const {
    style, data, config, dispatch, errorUrl,
  } = props;

  const { pin, service, date } = data;

  const time = useRef(null);
  const navigate = useNavigate();

  const printTicket = async (body) => {
    const { satelliteIp, satellitePort } = config;
    await Request('/proxySatellite/gate/print', 'no', {
      ...body,
      satelliteIp,
      satellitePort,
    })
      .then((res) => {
        if (!res.data.success) {
          // eslint-disable-next-line no-throw-literal
          throw 'Ошибка печати. Проверьте принтер.';
        }
        time.current = setTimeout(() => {
          navigate(config.startScreen);
          dispatch(setPre({}));
          dispatch(resetTicket());
          clearTimeout(time.current);
        }, 5000);
      })
      .catch((res) => {
        navigate(errorUrl);
        dispatch(setTextError(res));
      });
  };

  const splitString = (string, maxLength, ctx, x, y) => {
    const splittedString = string.split(' ');
    let newString;
    const countedArray = splittedString.map((word) => word.length);
    let str;
    if (countedArray.reduce((a, b) => a + b) > maxLength) {
      newString = splittedString.splice(3).toString().replace(/,/g, ' ');
      str = splittedString.toString().replace(/,/g, ' ');
      splitString(newString, maxLength, ctx, x, y + 20);
    } else {
      return ctx.fillText(string, x, y);
    }
    return ctx.fillText(str, x, y);
  };

  const ref = useRef(null);

  useEffect(() => {
    const ctx = ref.current.getContext('2d');
    ref.current.width = 500;
    ref.current.height = 500;
    ctx.clearRect(0, 0, ctx.width, ctx.height);
    ctx.fillStyle = '#fff';
    ctx.rect(0, 0, 500, 500);
    ctx.fill();
    ctx.textAlign = 'center';
    ctx.textBaseline = 'top';
    ctx.fillStyle = '#000';

    ctx.font = `bold 34px ${style.button.fontFamily || 'Roboto'}`;
    ctx.fillText('Время записи:', 250, 30);
    ctx.font = `bold 34px ${style.button.fontFamily || 'Roboto'}`;
    ctx.fillText(`${date}`, 250, 70);
    ctx.font = `bold 34px ${style.button.fontFamily || 'Roboto'}`;
    ctx.fillText('Ваш PIN-CODE:', 250, 120);
    ctx.font = `bold 72px ${style.button.fontFamily || 'Roboto'}`;
    ctx.fillText(`${pin}`, 250, 160);
    ctx.font = `bold 34px ${style.button.fontFamily || 'Roboto'}`;
    ctx.fillText('Наименование услуги:', 250, 250);
    ctx.font = `bold 34px ${style.button.fontFamily || 'Roboto'}`;
    splitString(service, 20, ctx, 250, 285);
    ctx.font = `bold 20px ${style.button.fontFamily || 'Roboto'}`;
    ctx.fillText('В день посещения введите данный пин-код', 250, 420);
    ctx.font = `bold 20px ${style.button.fontFamily || 'Roboto'}`;
    ctx.fillText('через терминал регистрации', 250, 440);
  }, [date, pin, service, style.button.fontFamily]);

  useEffect(() => {
    const base64 = ref.current.toDataURL();
    const body = { data: base64.split(',')[1] };
    printTicket(body);
  }, []);

  return (
    <Body style={style.body}>
      <canvas ref={ref} style={{ width: 500, height: 500, position: 'relative' }} />
    </Body>
  );
}

export default CanvasPre;
