import React, { useState } from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/ru';
import {
  FilterBox, FilterBoxTitle, FilterItemBox,
} from '../style';
// import DatePicker from 'react-datepicker';
// import ruDate from 'date-fns/locale/ru';

function HistoryDateSelect({ changeFilter, rangeType }) {
  const [startDate, setStartDate] = useState(rangeType ? moment(new Date()) : '');

  // let dpConfig = {
  //   locale: ruDate, timeCaption: 'Время', dateFormat: 'dd.MM.yyyy',
  // };
  let dateFormat = 'DD.MM.YYYY';

  switch (rangeType) {
    case 'day':
      // dpConfig = {
      //   locale: ruDate, timeCaption: 'Время', dateFormat: 'dd.MM.yyyy',
      // };
      dateFormat = 'DD.MM.YYYY';
      break;
    case 'month':
      // dpConfig = {
      //   locale: ruDate, timeCaption: 'Время', dateFormat: 'MM.yyyy', showMonthYearPicker: true,
      // };
      dateFormat = 'MMMM YYYY года';
      break;
    case 'quarter':
      // dpConfig = {
      //   locale: ruDate,
      //   timeCaption: 'Время',
      //   dateFormat: 'QQQ.yyyy',
      //   showQuarterYearPicker: true,
      // };
      dateFormat = 'Q квартал YYYY года';
      break;
    case 'year':
      // dpConfig = {
      //   locale: ruDate, timeCaption: 'Время', dateFormat: 'yyyy', showYearDropdown: true,
      // };
      dateFormat = 'YYYY год';
      break;
    default:
      break;
  }

  if (rangeType && startDate === '') {
    changeFilter('dateTypeDescription', moment(new Date()).format(dateFormat));
  } else if (rangeType) {
    changeFilter('dateTypeDescription', startDate.format(dateFormat));
  }

  const handleDataChange = (date) => {
    if (date === null) {
      const time = '';
      setStartDate(time);
      changeFilter('historyDate', moment(new Date()).format('DD.MM.YYYY'));
      changeFilter('intervalReprotHistoryDate', 'all');
    } else {
      const newStartDate = date || startDate.toDate();
      const time = moment(newStartDate);
      setStartDate(time);
      changeFilter('historyDate', time.format('DD.MM.YYYY'));
      changeFilter('dateTypeDescription', time.format(dateFormat));
      changeFilter('intervalReprotHistoryDate', time.format('DD.MM.YYYY'));
    }
  };

  return (
    <FilterBox History>
      <FilterBoxTitle>Историческая дата</FilterBoxTitle>
      <FilterItemBox>
        {/* <DatePicker */}
        {/*  {...dpConfig} */}
        {/*  selectsStart */}
        {/*  selected={startDate !== '' ? startDate.toDate() : ''} */}
        {/*  onChange={(date) => handleDataChange({ sDate: date })} */}
        {/* /> */}
        <AntDatePicker
          defaultValue={moment(new Date(), dateFormat)}
          format={dateFormat}
          style={{ width: '100%' }}
          picker={rangeType}
          onChange={handleDataChange}
        />
      </FilterItemBox>
    </FilterBox>
  );
}

export default HistoryDateSelect;
