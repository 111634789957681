import React, { useContext } from 'react';
import { Collapse } from 'antd'; // DOCS: https://ant.design/components/collapse/
import BoardCtx from '../../../../../../../../../pages/public/board/utils/BoardCtx';
import Height from '../../../../Components/modules/height';
import FontFamily from '../../../../Components/FontFamily';
import FontSize from '../../../../Components/FontSize';
import Colors from '../../../../Components/Colors';
import colors from './colors';
import fontSize from './fontSize';
import fontFamily from './fontFamily';
import MarginComponent from '../../../../Components/modules/Margin';
import Uppercase from '../../../../Components/modules/uppercase';

function Header() {
  const {
    dispatch, layout, fonts,
  } = useContext(BoardCtx);
  const { big, vertical } = layout;

  const onRefresh = (param) => dispatch({
    type: 'params', name: 'big', param, value: 'auto',
  });

  const { Panel } = Collapse;
  const arrColors = colors({ big });
  const arrFontSize = fontSize({ big });
  const arrFont = fontFamily({ big });

  return (
    <Collapse className="customize-Collapse" ghost>

      <Panel header="Параметры текста" key="1">
        <FontSize arr={arrFontSize} dispatch={dispatch} />
        <FontFamily options={fonts} arr={arrFont} dispatch={dispatch} />
      </Panel>

      <Panel header="Параметры элементов" key="2">
        {!vertical && <Height clear refresh={() => onRefresh('minWidth')} title="Ширина" width="160px" element="big" nameParams="minWidth" value={big.minWidth} dispatch={dispatch} />}
        {vertical && <Height clear refresh={() => onRefresh('height')} title="Высота" width="160px" element="big" nameParams="height" value={big.height} dispatch={dispatch} />}
        <Height title="Размер рамки" width="160px" element="big" nameParams="borderSize1" value={big.borderSize1} dispatch={dispatch} />
        <MarginComponent data={big} dispatch={dispatch} element="big" name="margin" title="Отступы" />
        <Uppercase title="Альтернативный стиль" element="big" nameParams="alternate" value={big.alternate} dispatch={dispatch} />
      </Panel>

      <Panel className="customize-Collapse-Colors" header="Цвет" key="3">
        <Colors arr={arrColors} dispatch={dispatch} />
      </Panel>
    </Collapse>
  );
}

export default Header;
