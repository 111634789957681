import React from 'react';
import { Space } from 'antd';
import Uppercase from './modules/uppercase';

function UppercaseComponent({
  arr, dispatch, text, noText,
}) {
  return (
    <Space wrap direction="horizontal" size="small">
      {!noText && <p>{text || 'Текст заглавными буквами'}</p>}
      {arr.map((el) => (
        <Uppercase
          element={el.parentName}
          dispatch={dispatch}
          title={el.title}
          nameParams={el.paramName}
          value={el.value}
          key={el.title}
        />
      ))}
    </Space>
  );
}

export default UppercaseComponent;
