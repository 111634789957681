import React from 'react';
import { useNavigate } from 'react-router-dom';
import { resetScanPolicyStore } from '../../../../../../../../../logic/store/pullstate/scanPolicy';
import { BoxButtonContent, ButtonContent } from '../../../../../utils/style';
import changeStyle from '../../../../common/changeStyle';

function PolicyBB({
  button, startScreen, isInner, Authorization,
}) {
  const navigate = useNavigate();
  const buttons = [
    { click: () => resetScanPolicyStore(), title: 'Назад' },
    {
      click: () => {
        resetScanPolicyStore();
        navigate(startScreen);
      },
      title: 'На Главный экран',
    },
  ];

  const nextButton = {
    click: () => {
      Authorization();
    },
    title: 'Далее',
  };
  if (isInner) buttons.splice(1, 1, nextButton);

  const styleButton = changeStyle({ style: button, item: { size: 'sm' } });
  return (
    <div className="policyBottomButtons">
      {buttons.map((el) => (
        <div
          onDragStart={() => false}
          style={{ ...styleButton, minWidth: 'max-content' }}
          key={el.title}
          onClick={el.click}
        >
          <ButtonContent>
            <BoxButtonContent>
              <p style={{ ...styleButton.text, textAlign: styleButton.textAlign }}>{el.title}</p>
            </BoxButtonContent>
          </ButtonContent>
        </div>
      ))}
    </div>
  );
}

export default PolicyBB;
