import React from 'react';
import { Button, Table } from 'antd';
import { FaTrash } from 'react-icons/fa';
import Icon from '@ant-design/icons';
import Preloader from '../../../../../components/preloader/Preloader';
import DeleteWarning from '../../../../../../../common/ui/Modal/DeleteWarning';
import { useTable } from './useTable';

function ValuesTable(props) {
  const { valuesList } = props;
  const {
    editableText, editableSelect, delValue, showDelWarn, setShowDelWarn, openModalToDeleteRow,
  } = useTable(props);

  const viewValuesList = valuesList === undefined
    ? <div>Свойств нет</div>
    : (
      <Table
        columns={[
          {
            dataIndex: 'id',
            title: 'ID',
            key: 'id',
          },
          {
            dataIndex: 'name',
            title: 'Наименование',
            key: 'name',
            render: editableText('name'),
          },
          {
            dataIndex: 'valprefix',
            title: 'Префикс',
            key: 'valprefix',
            render: editableText('valprefix'),
          },
          {
            dataIndex: 'prior',
            title: 'Приоритет',
            key: 'prior',
            render: editableText('prior'),
          },
          {
            dataIndex: 'handjob',
            title: 'Ручная обработка',
            key: 'handjob',
            render: editableSelect('handjob'),
          },
          {
            dataIndex: 'Settings',
            title: '',
            key: 'Settings',
            render: (_, elem) => (
              <div>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<Icon component={FaTrash} size={25} />}
                  onClick={() => openModalToDeleteRow(elem.id)}
                />
              </div>
            ),
          },
        ]}
        dataSource={valuesList}
      />
    );

  return (
    <>
      {!valuesList ? <Preloader isOpen small /> : viewValuesList }
      <DeleteWarning confirmDel={delValue} isOpen={showDelWarn} toggleOpen={setShowDelWarn} />
    </>
  );
}

export default React.memo(ValuesTable);
