import translit from '../../../../logic/utils/transliterate';
import parseNumber from './parseNumber';

const EXTENSION = 'mp3';
const PATH = '/sounds';

const engLitter = [
  'ai', 'bi', 'ci', 'di', 'ei', 'ef', 'gi', 'hi', 'ii', 'ji', 'kei', 'el', 'em', 'en', 'oo', 'pi', 'kju', 'ar', 'es', 'ti', 'ju', 'vi', 'dablju', 'eks', 'zed'];

const getPath = (item, pathTo = PATH, ext = EXTENSION) => {
  console.log('item === ', item);
  if (engLitter.includes(item?.split('/')[1])) return `${pathTo}/${item}.wav`;
  return `${pathTo}/${item}.${ext}`;
};

const numberSound = (number, folder = 'numbers') => {
  const soundsArray = [];
  for (let i = 0; i < number.length; i += 1) {
    const num = parseInt(number.slice(i), 10);
    if (num <= 20 && num > 0 && number.length - i <= 2) {
      soundsArray.push(num);
      break;
    }
    if (parseInt(number[i], 10) > 0) soundsArray.push(number[i] * (10 ** (number.length - i - 1)));
  }
  return soundsArray.map((v) => `${folder}/${v}`);
};

const getDigitsFromString = (string) => String(Number(string.replace(/\s/g, '').replace(/\D+/g, '')));
const getLettersFromString = (string) => String(string.replace(/\s/g, '').replace(/\d+/g, ''));

const genLetterSounds = (letters = '') => String(letters)
  .split('')
  .map((letter) => `letters/${translit(letter)}`);

export const generateSoundList = (soundString = '', ticket, path = '/sounds', ext = 'mp3') => {
  const { number, wplace, voice = 'female' } = ticket;

  const ticketLetters = getLettersFromString(number);
  const ticketNumber = getDigitsFromString(number);

  const windowNum = parseNumber(wplace);

  return String(soundString)
    .split(' ')
    .reduce((acc, each) => {
      let next = each;
      const macthes = ['WNUM0', 'WNUM1', 'WNUM2', 'WNUM3'];
      const currentIndex = macthes.indexOf(each);

      if (each === 'TNUM') {
        console.log('ticketLetters === ', ticketLetters);
        console.log(' === ', ticketLetters.split(''));
        next = genLetterSounds(ticketLetters).concat(numberSound(ticketNumber));
        console.log('next === ', next);
      } else if (currentIndex !== -1) {
        next = numberSound(windowNum[currentIndex]);
      }
      return acc.concat(next);
    }, [])
    .map((each) => getPath(each, `${path}/${voice}`, ext));
};

export default { generateSoundList };
