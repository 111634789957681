import React from 'react';
import { SortIconBox } from '../../../../../../common/ui/Common';

const sortCaret = (order) => {
  const def = order === 'asc';
  return (
    <SortIconBox>
      <svg className="sortIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 370.47 359.72">
        <defs>
          <style>{'.cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:20px;}'}</style>
        </defs>
        <g id="Слой_2" data-name="Слой 2">
          <g id="Слой_1-2" data-name="Слой 1">
            <rect id="#top" className="cls-1" x={def ? 10 : 170} y={277.95} width={def ? 350.47 : 190} height="71.77" rx="20.03" />
            <rect className="cls-1" x="97.53" y="143.97" width="262.94" height="71.77" rx="20.03" />
            <rect className="cls-1" x={def ? 170 : 10} y={10} width={def ? 190 : 350} height="71.77" rx="20.03" />
          </g>
        </g>
      </svg>
    </SortIconBox>
  );
};

export default sortCaret;
