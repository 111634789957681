import React, { useContext } from 'react';
import TerminalCtx from '../../../../../../../../pages/public/terminal/context';

function TerminalElements() {
  const { element, dispatchElement } = useContext(TerminalCtx);
  const elements = [
    { id: 'head', name: 'Основные элементы' },
    { id: 'text', name: 'Текст' },
    { id: 'button', name: 'Кнопки' },
    { id: 'navigation', name: 'Кнопки навигации' },
    { id: 'input', name: 'Поля ввода' },
  ];
  const renderBtns = () => elements.map((el) => {
    let active = false;
    if (element === el.id) {
      active = true;
    }
    if (el.id === 'br') return <div style={{ width: '100%', height: 1 }} />;
    return (
      <div
        className="settings__element__item"
        onClick={() => dispatchElement({ type: 'element', value: el.id })}
        key={el.id}
        style={{ background: active ? 'rgb(252, 76, 2)' : null, color: active ? '#fff' : null }}
      >
        <p><span>{el.name}</span></p>
      </div>
    );
  });

  return (
    <div className="settings__element">
      {renderBtns()}
    </div>
  );
}
export default TerminalElements;
