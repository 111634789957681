import React from 'react';
import { Checkbox } from 'antd';

const uppercase = ({
  value, element, dispatch, title, nameParams = '',
}) => {
  const big = nameParams.match(/\./);

  const onChange = () => {
    if (!big) {
      dispatch({
        type: 'params',
        name: element,
        param: nameParams,
        value: !value,
      });
    } else {
      const arr = nameParams.split('.');
      dispatch({
        type: 'bigParams',
        parentName: element,
        name: arr[0],
        param: arr[1],
        value: !value,
      });
    }
  };

  return (
    <Checkbox checked={value} onChange={onChange}>
      {/* eslint-disable-next-line react/no-danger */}
      <span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: title }} />
    </Checkbox>
  );
};

export default uppercase;
