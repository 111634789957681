import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FaSave } from 'react-icons/fa';
import {
  Button,
  Col, Input, Row, Space, Typography,
} from 'antd';
import Icon from '@ant-design/icons/';
import request from '../../../../logic/utils/request';
import {
  Title, ContentBox, PreloaderBox,
} from '../../../../common/ui/Common';
import {
  HeaderAD, BodyAD, BodyADLog, ReportBox, CustomDiv,
} from '../../../styles/ActiveDirectory';
import Preloader from '../../../../pages/private/components/preloader/Preloader';

function ActiveDirectory({ preloader, edit }) {
  const [dataList, setDataList] = useState(null);

  const fetchData = async () => {
    const res = await request('/server/api/activedirectory/', 'get');
    const { data, data: { dc_customrolesjson: dcCustomrolesjson } } = res.data;
    setDataList({ ...data, ...dcCustomrolesjson });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;

    const newData = { ...dataList };

    if (name.indexOf('eq_') !== -1) {
      newData.dc_customrolesjson[name] = value;
    } else {
      newData[name] = value;
    }
    setDataList(newData);
  };

  const isOnCheck = {
    label: 'Включена:', name: 'enabled', type: 'checkbox', value: dataList?.enabled,
  };

  const handleSave = async () => {
    preloader();
    await request('/server/api/activedirectory/', 'update', dataList);
    preloader();
  };

  return (
    <>
      <Title>НАСТРОЙКИ СИНХРОНИЗАЦИИ С ACTIVE DIRECTORY</Title>
      <ContentBox>
        <PreloaderBox>
          {dataList === null
            ? <Preloader isOpen small />
            : (
              <>
                <HeaderAD>
                  <Button
                    size="large"
                    type="primary"
                    onClick={handleSave}
                    icon={<Icon component={FaSave} />}
                  >
                    Сохранить
                  </Button>
                  <CustomDiv>
                    Время последней синхронизации:
                    <br />
                    {moment(dataList.last_sync, 'YYYY.MM.DD HH:mm:ss').format('DD.MM.YYYY - HH:mm:ss')}
                  </CustomDiv>
                  <Input m="0" item={isOnCheck} onChange={handleInput} key="enabled" />
                </HeaderAD>
                <ReportBox>
                  <Row>
                    <Col span={12}>
                      <Space style={{ width: 400 }} size="small" direction="vertical">
                        <Typography.Title level={3}>Настройки</Typography.Title>
                        <div>
                          <div>Наименование</div>
                          <Input
                            disabled={edit}
                            name="name"
                            value={dataList?.name}
                            onChange={handleInput}
                          />
                        </div>
                        <div>
                          <div>IP адрес или имя сервера AD</div>
                          <Input
                            disabled={edit}
                            name="dc_serverfqdn"
                            value={dataList?.dc_serverfqdn}
                            onChange={handleInput}
                          />
                        </div>
                        <div>
                          <div>FQDN домена</div>
                          <Input
                            disabled={edit}
                            name="dc_fqdn"
                            value={dataList?.dc_fqdn}
                            onChange={handleInput}
                          />
                        </div>
                        <div>
                          <div>Логин синхронизации с AD</div>
                          <Input
                            disabled={edit}
                            name="dc_username"
                            value={dataList?.dc_username}
                            onChange={handleInput}
                          />
                        </div>
                        <div>
                          <div>Пароль</div>
                          <Input
                            disabled={edit}
                            name="dc_passwd"
                            value={dataList?.dc_passwd}
                            onChange={handleInput}
                          />
                        </div>
                        <div>
                          <div>Интервал синхронизации(сек)</div>
                          <Input
                            disabled={edit}
                            name="timeout_sync"
                            value={dataList?.timeout_sync}
                            onChange={handleInput}
                          />
                        </div>
                      </Space>
                    </Col>
                    <Col span={12}>
                      <Space style={{ width: 400 }} direction="vertical" size="small">
                        <Typography.Title level={3}>
                          Настройки синхронизации с Active Directory
                        </Typography.Title>
                        <div>
                          <div>Администратор</div>
                          <Input
                            name="eq_root"
                            value={dataList?.eq_root}
                            onChange={handleInput}
                            disabled={edit}
                          />
                        </div>
                        <div>
                          <div>Суперчитатель</div>
                          <Input
                            name="eq_manager"
                            value={dataList?.eq_manager}
                            onChange={handleInput}
                            disabled={edit}
                          />
                        </div>
                        <div>
                          <div>Диспетчер</div>
                          <Input
                            name="eq_controller"
                            value={dataList?.eq_controller}
                            onChange={handleInput}
                            disabled={edit}
                          />
                        </div>
                        <div>
                          <div>Оператор</div>
                          <Input
                            name="eq_operator"
                            value={dataList?.eq_operator}
                            onChange={handleInput}
                            disabled={edit}
                          />
                        </div>
                        <div>
                          <div>Читатель отчетов</div>
                          <Input
                            name="eq_reporter"
                            value={dataList?.eq_reporter}
                            onChange={handleInput}
                            disabled={edit}
                          />
                        </div>
                      </Space>
                    </Col>
                  </Row>
                </ReportBox>
                <ReportBox next>
                  <BodyADLog>
                    <BodyAD>
                      <CustomDiv>
                        Создан:
                        {' '}
                        <CustomDiv>{dataList.created_by_username}</CustomDiv>
                      </CustomDiv>
                      <CustomDiv>
                        Дата создания:
                        {' '}
                        <CustomDiv>{moment(dataList.created_on, 'YYYY.MM.DD HH:mm:ss').format('DD.MM.YYYY - HH:mm:ss')}</CustomDiv>
                      </CustomDiv>
                      <CustomDiv>
                        Изменен:
                        {' '}
                        <CustomDiv>{dataList.modified_by_username}</CustomDiv>
                      </CustomDiv>
                      <CustomDiv>
                        Когда изменен:
                        {' '}
                        <CustomDiv>{moment(dataList.modified_on, 'YYYY.MM.DD HH:mm:ss').format('DD.MM.YYYY - HH:mm:ss')}</CustomDiv>
                      </CustomDiv>
                    </BodyAD>
                    <BodyAD mt>
                      <div style={{ width: '100%' }}>
                        Результат синхронизации:
                        <textarea name="" id="" cols="30" rows="10" value={dataList.last_sync_message} readOnly style={{ resize: 'none', width: '100%' }} />
                      </div>
                    </BodyAD>
                  </BodyADLog>
                </ReportBox>
              </>
            )}
        </PreloaderBox>
      </ContentBox>
    </>
  );
}
export default ActiveDirectory;
