import {
  Row, Col, Input, notification, Button,
} from 'antd';
import React, { useState } from 'react';
import api from '../utils/api';

function ZoneForm({
  zoneData, setIsOpen, fetchZones,
}) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [formData, setFormData] = useState({ ...zoneData });

  const handleInput = (e) => {
    const { name, value } = e.target;

    const currentValue = { ...formData, [name]: value };
    setFormData({ ...currentValue });

    if (currentValue.name && currentValue.longname) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const closeDrawer = () => {
    setIsOpen(false);
    fetchZones();
  };

  const addZone = async (e) => {
    e.preventDefault();
    const res = await api.addZone(formData);
    if (res.status) {
      notification.success({
        message: 'Зона успешно добавлена',
      });
      closeDrawer();
    } else {
      notification.error({
        message: res.errMessage,
      });
    }
  };

  const updateZone = async (e) => {
    e.preventDefault();
    const res = await api.updateZone(formData);
    if (res.status) {
      notification.success({
        message: 'Зона успешно обновлена',
      });
      closeDrawer();
    } else {
      notification.error({
        message: res.errMessage,
      });
    }
  };

  const { name, longname, description } = formData;

  return (
    <form onSubmit={zoneData?.id ? updateZone : addZone}>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <Input placeholder="Имя" onChange={handleInput} value={name} name="name" />
            </Col>
            <Col span={24}>
              <Input placeholder="Наименование" onChange={handleInput} value={longname} name="longname" />
            </Col>
            <Col span={24}>
              <Input placeholder="Описание" onChange={handleInput} value={description} name="description" />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center" gutter={[10, 20]}>
            <Col>
              <Button disabled={isDisabled} htmlType="submit" type="primary">{zoneData?.id ? 'Сохранить' : 'Добавить'}</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
}

export default ZoneForm;
