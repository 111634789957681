import React from 'react';
import moment from 'moment';
import { BigCard, SmallCard } from './StatisticCard';
import { FlexColumn, FlexBetween, FlexCenter } from '../../../../../../../common/ui/Common';

function Statistic(props) {
  const { data } = props;
  const {
    avSecProcTicket,
    avSecWaitTicket,
    countAllTicketsToday,
    countClosedTicketsToday,
    countDeletedTicketsToday,
    countTicketsNow,
    countTicketsWaitingNow,
    countWplacesBusy,
    countWplacesIdle,
    countWplacesTotal,
    maxSecWaitTicketNow,
    total_questions: totalQuestions,
  } = data;
  const ticetInProgres = countTicketsNow - countTicketsWaitingNow;

  return (
    data !== undefined ? (
      <FlexColumn>
        <FlexBetween>
          <BigCard info={['Рабочие места', 'Активно', countWplacesIdle, 'включен', countWplacesBusy, 'Всего', countWplacesTotal]} />
          <BigCard info={['ТАЛОНОВ', 'В ОБСЛУЖИВАНИИ', ticetInProgres, 'ОЖИДАЕТ', countTicketsWaitingNow, 'Всего', countTicketsNow]} />
          <BigCard info={['СЕГОДНЯ', 'ОБСЛУЖЕНО', countClosedTicketsToday, 'НЕЯВКА', countDeletedTicketsToday, 'Всего', countAllTicketsToday]} />
        </FlexBetween>
        <FlexBetween>
          {SmallCard('СРЕДНЕЕ ВРЕМЯ ОБСЛУЖИВАНИЯ', moment.utc(avSecProcTicket * 1000).format('HH:mm:ss'))}
          {SmallCard('СРЕДНЕЕ ВРЕМЯ ОЖИДАНИЯ', moment.utc(avSecWaitTicket * 1000).format('HH:mm:ss'))}
          {SmallCard('МАКСИМАЛЬНОЕ ВРЕМЯ ОЖИДАНИЯ', moment.utc(maxSecWaitTicketNow * 1000).format('HH:mm:ss'))}
        </FlexBetween>
        <FlexCenter>
          {SmallCard('количество решенных вопросов', totalQuestions)}
        </FlexCenter>
      </FlexColumn>
    ) : (<div>пусто</div>)
  );
}
export default React.memo(Statistic);
