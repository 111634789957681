import React from 'react';
import Input from '../../../../../../../pages/private/components/Input/Input';
import RenderCheckList from './RenderCheckList';

function RenderInputs({
  dayData, setDayData, setIsValid, intervalList,
}) {
  const { name, description } = dayData;

  const handleInput = (e) => {
    if (e.target) {
      const { name: fieldName, value } = e.target;
      const validData = { ...dayData, [fieldName]: value };
      setDayData({ ...validData });
    } else {
      setDayData({ ...e });
    }
    if (dayData.name && dayData.description) {
      setIsValid(true);
    }
  };

  const items = [
    {
      label: 'Наименование:', name: 'name', value: name || '', required: true,
    },
    {
      label: 'Описание:', name: 'description', value: description || '', required: true,
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      {items.map((item) => (
        <Input
          key={item.name}
          item={item}
          onChange={handleInput}
          width="100%"
        />
      ))}
      <RenderCheckList dayData={dayData} intervals={intervalList} setDayData={handleInput} />
    </div>
  );
}
export default RenderInputs;
