import React from 'react';

export default React.createContext({
  dispatch: () => { },
  createTicket: () => { },
  fetchQueue: () => { },
  queue: null,
  day: null,
  time: null,
  ticket: {},
});
