import React, { useState, useEffect } from 'react';
import moment from 'moment';

function TimerComponent({ clock, styleTime, viewType }) {
  const getCurrentDate = () => ({
    time: moment().format('LT'),
    date: moment().format('DD/MM/YYYY'),
  });

  const [timeConfig, setTimeConfig] = useState(getCurrentDate());
  const { time } = timeConfig;
  let { date } = timeConfig;

  useEffect(() => {
    const timerMin = setInterval(() => {
      setTimeConfig(getCurrentDate());
    }, 1000);
    return () => clearInterval(timerMin);
  }, []);

  const typeClock = ['.', '-', '/'];

  if (date) {
    date = date.replace(/\.|-|\//g, typeClock[viewType]);
  }

  return (
    <>
      <div
        style={{
          order: clock.order,
          fontSize: clock.fontSizeClock,
          fontWeight: clock.fontWeightClock,
          textAlign: clock.textAlignTime,
          ...styleTime,
        }}
        className="time"
      >
        {time}
      </div>
      <div
        className="date"
        style={{
          fontSize: clock.fontSize,
          order: Number(!clock.order),
          fontWeight: clock.fontWeight,
          textAlign: clock.textAlign,
        }}
      >
        {date}
      </div>
    </>
  );
}

export default TimerComponent;
