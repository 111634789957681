import React from 'react';
import moment from 'moment';
import { ReactComponent as Video } from '../../../../../../../../common/images/videoPlayer.svg';
import { ReactComponent as Comment } from '../../../../../../../../common/images/commentText.svg';
import { ReactComponent as Microphone } from '../../../../../../../../common/images/microphone.svg';
import { ReactComponent as Download } from '../../../../../../../../common/images/download.svg';
import { BtnTable } from '../../../../../../../../common/ui/Common';

export default function TableConfig(filteredData, handleClick) {
  return (
    [
      {
        title: 'Тип',
        dataIndex: 'type',
        key: 'type',
        width: 120,
        fixed: 'left',
        filters: [{
          text: 'Обращение',
          value: 'Обращение',
        },
        {
          text: 'Опрос',
          value: 'Опрос',
        },
        ],
        onFilter: (value, record) => (record.type.indexOf(value) === 0),
      },
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        fixed: 'left',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Id талона',
        dataIndex: 'ticket_id',
        key: 'ticket_id',
        width: 100,
        fixed: 'left',
        sorter: (a, b) => a.ticket_id - b.ticket_id,
      },
      {
        title: '№ талона',
        dataIndex: 'number',
        key: 'number',
        width: 150,
        fixed: 'left',
      },
      {
        title: 'Дата прохождения',
        dataIndex: 'created_on',
        key: 'created_on',
        width: 160,
        fixed: 'left',
        sorter: (a, b) => {
          const aToSec = new Date(moment(a.created_on, 'DD.MM.YYYY HH:mm:ss')).getTime();
          const bToSec = new Date(moment(b.created_on, 'DD.MM.YYYY  HH:mm:ss')).getTime();
          return (aToSec - bToSec);
        },
      },
      {
        title: 'Источник',
        dataIndex: 'source',
        width: 200,
        filters: filteredData.sourceList,
        onFilter: (value, record) => (record.source.indexOf(value) === 0),
      },
      {
        title: 'Оператор',
        dataIndex: 'fio',
        width: 200,
        filters: filteredData.operatorList,
        onFilter: (value, record) => (record.fio.indexOf(value) === 0),
      },
      {
        title: 'Медиа',
        children: [
          {
            title: 'Фотография',
            dataIndex: 'photo_b64',
            width: 170,
            render: (_, row) => {
              const { photo_b64: photoB64 } = row;
              const regEx = /data:image\/([a-zA-Z]*);base64,([^"]*)/g;
              if (photoB64 && regEx.test(photoB64)) {
                return (
                  <img src={photoB64} data-name="photo" style={{ cursor: 'pointer', height: 50 }} alt="customer" />
                );
              }
              return 'Нет фотографии';
            },
          },
          {
            title: 'Аудио запись',
            dataIndex: 'audiorecordpath',
            render: (_, res) => (
              <div style={{ display: 'flex' }}>
                {res.audiorecordpath !== '' ? (
                  <div style={{ marginLeft: '5px' }}>
                    <BtnTable onClick={() => handleClick('micro', res)} data-name="micro" type="button"><Microphone /></BtnTable>
                  </div>
                )
                  : <span>Нет записи </span>}
              </div>
            ),
          },
          {
            title: 'Видео запись',
            dataIndex: 'videorecordpath',
            render: (_, res) => (
              <div style={{ display: 'flex' }}>
                {res.videorecordpath !== '' ? (
                  <div style={{ marginLeft: '5px' }}>
                    <BtnTable onClick={() => handleClick('video', res)} data-name="video" type="button"><Video /></BtnTable>
                  </div>
                )
                  : <span>Нет записи </span>}
              </div>
            ),
          },
          {
            title: 'Текстовое',
            dataIndex: 'textrecord',
            render: (_, res) => (
              <div style={{ display: 'flex' }}>
                {res.textrecord !== '' ? (
                  <div style={{ marginLeft: '5px' }}>
                    <BtnTable onClick={() => handleClick('comment', res)} ata-name="comment" type="button"><Comment /></BtnTable>
                  </div>
                )
                  : <span>Нет записи</span>}
              </div>
            ),
          },
          {
            title: 'Файлы',
            dataIndex: 'files',
            render: (_, res) => {
              if (res.files.length > 0) {
                return (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {res.files.map((el) => (
                      <a href={el.downloadLink} target="_blank" rel="noopener noreferrer">
                        <BtnTable data-name="download" type="button" style={{ display: 'flex', width: 'auto' }}>
                          {el.fileName}
                          {' '}
                          <Download style={{ maxWidth: '20px', marginLeft: '10px' }} />
                        </BtnTable>
                      </a>
                    ))}
                  </div>
                );
              }
              return (
                <div style={{ display: 'flex' }}>
                  <span>Файлов нет</span>
                </div>
              );
            },
          },
        ],
      },

    ]
  );
}
