import React from 'react';
import SelectInput from './SelectInput';
import CheckBox from './CheckBox';
import {
  Input, InputBox, Label,
} from '../../../../common/ui/Common';
import getLinks from '../../../../logic/utils/getLinks';

function InputField(props) {
  const {
    item, onChange, left, children, m, width,
  } = props;
  const write = getLinks(true);
  const {
    value, type, name, options, label, readOnly, noDefault, required, className,
  } = item;

  const renderField = () => {
    switch (type) {
      case 'select':
        return (
          <SelectInput
            maxContent={item.maxContent}
            m={m}
            width={item.width}
            disabled={readOnly || !write}
            noDefault={noDefault}
            options={options}
            onChange={onChange}
            value={value}
            name={name}
            multiple={false}
          />
        );
      case 'checkbox':
        return (
          <Label style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ order: left ? 1 : 0 }} className={children && 'common-settings-box__setting-box__label-icon settings__common__setting-box__label-icon'}>
              {label}
              {children && children}
            </span>
            <CheckBox
              disabled={!write}
              value={value}
              onChange={(e) => write && onChange(e)}
              name={name}
              checked={value}
            />
          </Label>
        );
      default:
        return (
          <Input
            autoComplete="new-password"
            type={type || 'text'}
            value={value}
            disabled={!write}
            min="0"
            m={m}
            maxLength={item.maxlength}
            // max="999"
            // maxlength="3"
            width={width}
            onChange={(e) => {
              if (e.target.value.length > item.maxlength) return;
              onChange(e);
            }}
            name={name}
          />
        );
    }
  };

  return (
    <InputBox className={className || ''}>
      {type !== 'checkbox' && type !== 'noneDescription' && <Label isRequired={required}>{label}</Label>}
      {renderField()}
    </InputBox>
  );
}

export default InputField;
