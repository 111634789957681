import Request from '../../../../../../../../logic/utils/request';

export default async function SqlQuery(sessionDataId) {
  const query = `select q.id, 
  e.sessiondata_id,
  w.question_id,
  r.questiontext,
  w.answerint,
  w.answertxt,
  w."type",
  r."position"
  from eq.tqcsessionquestionanswer q,
  eq.tqcquestioncorrectanswer w,
  eq.tqcquestion r,
  eq.tqcsessionquestion e
  where w.id = q.questioncorrectanswer_id
  and w.question_id = r.id 
  and e.id = q.sessionquestion_id
  and e.sessiondata_id = '${sessionDataId}'`;

  const dataObjects = [];
  const res = await Request('/server/api/genreportfromquery/', 'get', {
    querySql: `${query}`,
  });

  const answer = res.data.data;
  answer.forEach((v) => {
    dataObjects.push({ ...v });
  });

  let summa = 0;
  let queryCnt = 0;
  dataObjects.forEach((e) => {
    if (e[6] === '2') {
      summa = Number(summa) + Number(e[4]);
      queryCnt = Number(queryCnt) + 1;
    }
  });
  let avgMark;
  if (queryCnt === 0) avgMark = 'Нет';
  else { avgMark = (Number(summa) / Number(queryCnt)).toFixed(2); }

  dataObjects.sort((a, b) => {
    if (a[7] > b[7]) return 1;
    if (a[7] < b[7]) return -1;
    return 0;
  });

  return { dataObjects, query, avgMark };
}
