import { Route } from 'react-router-dom';
import React from 'react';
import Home from '../home/home';

export const getInputScene = ({
  config,
  startScreen,
  height,
  props,
}) => config.layoutData
  .filter((scene) => scene.type === 'inputScreen')
  .flatMap((scene) => scene.buttons.map((button) => (
    <Route
      key={button.id}
      path={`${scene.screenId}/:extraId`}
      element={(
        <Home
          {...props}
          height={height}
          scene={scene}
          startScreen={startScreen}
        />
      )}
    />
  )));

export default null;
