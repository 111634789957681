import React, { useState, useEffect } from 'react';
import CheckBoxList from '../../../../../pages/private/components/CheckBoxList';

const RenderCheckList = ({
  userData, setUserData, queue, roles,
}) => {
  const { connectedQueues, connectedRoles } = userData;
  const [checkedQueue, setCheckedQueue] = useState([]);
  const [checkedRoles, setCheckedRoles] = useState([]);

  useEffect(() => {
    setCheckedQueue([...connectedQueues]);
    setCheckedRoles([...connectedRoles]);
  }, [userData, connectedQueues, connectedRoles]);

  const items = [
    {
      label: 'Роли:', name: 'connectedRoles', options: roles, checked: checkedRoles,
    },
    {
      label: 'Очереди:', name: 'connectedQueues', options: queue, checked: checkedQueue,
    },
  ];

  return (
    items.map((item) => (
      <div key={item.name}>
        <CheckBoxList
          item={item}
          data={userData}
          setData={setUserData}
        />
      </div>
    ))
  );
};
export default RenderCheckList;
