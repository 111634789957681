import React, { useState, useEffect, useCallback } from 'react';
import request from '../../../../logic/utils/request';
import DisplaysTable from './DisplaysTable';
import NewDisplay from './NewDisplay/NewDisplay';
import { Title, ContentBox, PreloaderBox } from '../../../../common/ui/Common';
import { useToggle } from '../../../../logic/hooks/useToggle';

function Displays({ preloader, showError, write }) {
  const initialData = {
    address: '192.168.3.203:80',
    config: {},
    description: '',
    enabled: false,
    name: '',
    number: 0,
    oid: 0,
    type: 'abt_matrix',
  };
  const [displayList, setDisplayList] = useState(null);
  const [displayData, setDisplayData] = useState(initialData);
  const [isOpen, toggleOpen] = useToggle(false);
  const [displayUpdate, setDisplayUpdate] = useState(false);

  const fetchDisplays = useCallback(async () => {
    const res = await request('/server/api/digitboard/', 'get');
    setDisplayList(res.data.data);
  }, []);

  useEffect(() => {
    fetchDisplays();
  }, [fetchDisplays]);

  return (
    <>
      <Title>Цифровые табло</Title>
      <ContentBox>
        <NewDisplay
          initialData={initialData}
          displayData={displayData}
          setDisplayData={setDisplayData}
          fetchDisplays={fetchDisplays}
          toggleOpen={toggleOpen}
          isOpen={isOpen}
          displayUpdate={displayUpdate}
          setDisplayUpdate={setDisplayUpdate}
          preloader={preloader}
          showError={showError}
        />
        <PreloaderBox>
          <DisplaysTable
            displayList={displayList}
            setDisplayUpdate={setDisplayUpdate}
            setDisplayData={setDisplayData}
            toggleOpen={toggleOpen}
            write={write}
            preloader={preloader}
            fetchDisplays={fetchDisplays}
            showError={showError}
          />
        </PreloaderBox>
      </ContentBox>
    </>
  );
}

export default Displays;
