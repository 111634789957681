import dayjs from 'dayjs';
import { Select } from 'antd';
import 'dayjs/locale/ru';

const localeData = require('dayjs/plugin/localeData');

dayjs.extend(localeData);
dayjs.locale('ru');

function CalendarHeader({ data }) {
  const { value, onChange } = data;
  const months = dayjs.months();
  const year = value.year();
  const yearOptions = [];
  for (let i = year - 10; i < year + 10; i += 1) {
    yearOptions.push(
      <Select.Option key={i} value={i}>{i}</Select.Option>,
    );
  }
  return (
    <div className="preorder__calendar__header">
      <Select
        style={{ width: '140px' }}
        value={value.month()}
        onChange={(val) => {
          const now = value.clone().month(val);
          onChange(now);
        }}
      >
        {months.map((month, idx) => (
          <Select.Option key={month} value={idx}>{month}</Select.Option>
        ))}
      </Select>
      <Select
        style={{ width: '100px' }}
        value={value.year()}
        onChange={(val) => {
          const now = value.clone().year(val);
          onChange(now);
        }}
      >
        {yearOptions}
      </Select>
    </div>
  );
}

export default CalendarHeader;
