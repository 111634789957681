import React from 'react';
import ReportBuilder from './ReportType/ReportBuilder';

function ReportList(props) {
  const {
    preloader, view, loader, setLoader, setView,
  } = props;

  return (
    <ReportBuilder
      preloader={preloader}
      view={view}
      loader={loader}
      setLoader={setLoader}
      setView={setView}
    />
  );
}
export default (ReportList);
