import {
  Button, Card, Checkbox, Col, Input, Radio, Row, notification,
} from 'antd';
import React, { useState } from 'react';
import defaultValues from '../../../../utils/defaultValues';
import FormTooltip from './FormTooltip';
import zoneStore, { updateZoneStore } from '../../../Store/index';
import randomId from '../../../../../../../../logic/utils/randomId';
import api from '../../../../utils/api';

function ScreenForm(props) {
  const {
    initialData, toggleDrawer, type, button, screen, setUpdatedButton,
  } = props;

  const zoneScreens = zoneStore.useState((s) => s.config.screens);
  const [isDisabled, setIsDisabled] = useState(true);
  const [screenData, setScreenData] = useState(initialData || defaultValues.screenForm);

  const handleInput = (e, isCheckBox = false) => {
    const { name, value, checked } = e.target;

    const currentValue = { ...screenData, [name]: isCheckBox ? checked : value };

    setScreenData(currentValue);

    if (currentValue.headTitle) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const updateZone = async (message) => {
    const res = await api.updateZone({ ...zoneStore.getRawState() });

    if (res.status) {
      notification.success({
        message,
      });
    } else {
      notification.error({
        message: res.errMessage,
      });
    }
  };

  const updateScreen = (e) => {
    e.preventDefault();

    const updatedScreen = { ...screenData };
    const newScreens = zoneScreens.map((item) => {
      if (item.screenId === updatedScreen.screenId) {
        return updatedScreen;
      }
      return updatedScreen.isStart ? { ...item, isStart: false } : item;
    });
    const { config } = zoneStore.getRawState();

    updateZoneStore({ ...zoneStore.getRawState(), config: { ...config, screens: newScreens } });
    toggleDrawer('close');
    updateZone('Экран обновлён');
  };

  const addScreen = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const config = { ...zoneStore.getRawState().config };
    const screens = screenData.isStart
      ? [...config.screens].map((item) => ({ ...item, isStart: false }))
      : [...config.screens];

    screens.push({
      ...defaultValues.screen,
      ...screenData,
      screenId: randomId(),
    });

    // добавление и привязка к кнопке
    if (type === 'link') {
      const addedScreen = screens[screens.length - 1];
      const updatedButton = { ...button, path: addedScreen.screenId };

      const newButtons = screen.buttons.map((item) => {
        if (item.id === updatedButton.id) {
          return updatedButton;
        }
        return item;
      }).sort((a, b) => a.position - b.position);

      const updatedScreen = { ...screen, buttons: [...newButtons] };
      const newScreens = screens.map((item) => {
        if (item.screenId === updatedScreen.screenId) {
          return addedScreen.isStart
            ? { ...updatedScreen, isStart: false } : updatedScreen;
        }
        return item;
      });

      updateZoneStore({
        ...zoneStore.getRawState(),
        config: { ...config, screens: newScreens },
      });

      setUpdatedButton({ ...updatedButton });
    } else {
      updateZoneStore({
        ...zoneStore.getRawState(),
        config: { ...config, screens },
      });
    }

    if (toggleDrawer) {
      toggleDrawer('close');
    }

    setScreenData(defaultValues.screenForm);
    setIsDisabled(true);

    updateZone(`Экран добавлен ${type === 'link' ? 'и привязан' : ''}`);
  };

  return (
    <Card title={(
      <Row gutter={[0, 20]}>
        <Row gutter={[0, 10]} style={{ width: '100%' }}>
          <Col span={24}>
            <span>
              Введите загаловок экрана
            </span>
            <FormTooltip text="Это заголовок экрана, который видит клиент" />
          </Col>
          <Col span={24}>
            <Input
              placeholder="Заголовок экрана"
              onChange={handleInput}
              name="title"
              value={screenData.title}
            />
          </Col>
        </Row>

        <Row gutter={[0, 10]} style={{ width: '100%' }}>
          <Col span={24}>
            <span>
              Введите подзаголовок экрана
            </span>
            <FormTooltip text="Это текст, который отображается под заголовком на экране" />
          </Col>
          <Col span={24}>
            <Input
              placeholder="Подзаголовок"
              onChange={handleInput}
              name="description"
              value={screenData.description}
            />
          </Col>
        </Row>
      </Row>
    )}
    >
      <form onSubmit={initialData ? updateScreen : addScreen}>
        <Row gutter={[0, 20]}>
          <Row gutter={[0, 10]} style={{ width: '100%' }}>
            <Col span={24}>
              <span>
                Введите наименование экрана
              </span>
              <FormTooltip text="Системный параметр для распознавания экранов" />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Наименование"
                onChange={handleInput}
                name="headTitle"
                value={screenData.headTitle}
              />
            </Col>
          </Row>
          <Row gutter={[0, 10]} style={{ width: '100%' }}>
            <Col span={24}>
              <span>
                Введите идентификатор экрана
              </span>
              <FormTooltip
                text="Системный параметр для
                идентификации экрана кастомными терминалами,
                для 'админочных' терминалов - оставить пустым"
              />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Идентификатор"
                onChange={handleInput}
                name="screenSubType"
                value={screenData.screenSubType}
              />
            </Col>
          </Row>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <span>
                Выберите тип экрана
              </span>
              <FormTooltip
                text="Системный параметр для
                идентификации экрана кастомнами терминалами,
                для 'админочных' терминалов - оставить пустым"
              />
            </Col>
            <Col span={24}>
              <Radio.Group
                options={defaultValues.screenTypes}
                onChange={handleInput}
                value={screenData.type}
                optionType="button"
                buttonStyle="solid"
                name="type"
              />
            </Col>
          </Row>
          <Row gutter={[0, 10]} style={{ width: '100%' }}>
            <Col span={24}>
              <Checkbox
                name="isStart"
                checked={screenData.isStart}
                onChange={(e) => handleInput(e, true)}
              >
                <span>
                  Назначить стартовым экраном
                </span>
                <FormTooltip
                  text="Системный параметр для
                идентификации экрана кастомными терминалами,
                для 'админочных' терминалов - оставить пустым"
                />
              </Checkbox>
            </Col>
          </Row>
          <Col span={24}>
            <Row justify="center">
              <Col>
                <Button
                  type="primary"
                  size="large"
                  disabled={isDisabled}
                  htmlType="submit"
                >
                  {initialData ? 'Сохранить' : 'Добавить'}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </Card>
  );
}

export default ScreenForm;
