import styled from 'styled-components';

export const HeaderAD = styled.div`
  margin-bottom: 30px;
  display: flex;
  position: relative;
  align-items: center;
  color: ${(p) => p.theme.text};
  > div {
    margin: 0 0 0 30px;
  }
  > button {
    margin-right: 60px;
  }
`;

export const BodyAD = styled.div`
  display: flex;
  justify-content: flex-start;
  ${(props) => (props.mt ? 'margin-top: 10px;' : '')};
  margin-bottom: 30px;
  textarea {
    background: ${(p) => p.theme.bg};
    color: ${(p) => p.theme.text};
    border: none;
    margin-top: 15px;
    border-radius: 3px;
  }
`;

export const BodyADLog = styled.div`
  display: flex;
  flex-direction: column;
  `;

export const BodyAdColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-right: 25px;
  > p {
    margin: 15px 0 30px;
    text-align: center;
    color: ${(p) => p.theme.accent};
    text-transform:uppercase;
    font-weight: bold;
  }
`;

export const ReportBox = styled.div`
  //${(props) => (props.next ? 'margin-top: 25px;' : '')}  
  color: ${(p) => p.theme.text};
  box-shadow: 0 1px 3px rgba(0,0,0,0.15);
  background: ${(p) => p.theme.bgTable};
  border-radius: 3px;
  border-color: ${(p) => p.theme.gray};
  padding: 15px;
  margin-bottom: 30px;
`;

export const CustomDiv = styled.div`
  font-size: 14px;
  margin-right: 30px;
  div {
    font-size: 18px;
    margin-top: 10px;
  }
  ${(props) => (props.max ? 'width: 100%' : '')}  
`;
