import moment from 'moment/moment';

export function getMomentProcessTimeSeconds(ticket) {
  const momentCreatedSumTotal = moment(ticket.createdOn).clone().add(ticket.totalwait, 'seconds');
  const momentProcessTime = moment.duration(moment().diff(momentCreatedSumTotal));
  return momentProcessTime.asSeconds();
}

export function getMomentDiffSeconds(ticket) {
  const momentNowDiffCreatedOn = moment.duration(moment().diff(moment(ticket.createdOn)));
  return momentNowDiffCreatedOn.asSeconds();
}

export function waitingTimeWithTotalWaiting(ticket) {
  return (ticket.status !== 0
    ? moment.utc(ticket.totalwait * 1000).format('HH:mm:ss')
    : null);
}

export function processTimeWithTotalWaiting(ticket) {
  return (ticket.status !== 0
    ? moment
      .utc(getMomentProcessTimeSeconds(ticket) * 1000)
      .format('HH:mm:ss')
    : null);
}

export function getWaitingTime(ticket) {
  return ticket.totalwait === 0
    ? moment.utc(getMomentDiffSeconds(ticket) * 1000).format('HH:mm:ss')
    : waitingTimeWithTotalWaiting(ticket);
}

export function getProcessTime(ticket) {
  return ticket.totalwait === 0
    ? moment.utc(0).format('HH:mm:ss')
    : processTimeWithTotalWaiting(ticket);
}
