export default ({ button }) => [
  {
    title: 'Маленькая', value: button.heightS, paramName: 'heightS', parentName: 'button',
  },
  {
    title: 'Средняя', value: button.heightM, paramName: 'heightM', parentName: 'button',
  },
  {
    title: 'Большая', value: button.heightL, paramName: 'heightL', parentName: 'button',
  },
];
