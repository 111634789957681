import React from 'react';
import {
  FilterBox, FilterBoxTitle, FilterItemBox, FilterLineBox,
} from '../style';
import AllFilter from '../utils/ServedFilters/AllFilter';

function WorkTimeFilter({ changeFilter }) {
  return (
    <FilterBox>
      <FilterBoxTitle>Длительность обслуживания</FilterBoxTitle>
      <FilterItemBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="total_work_time"
            title="Всего"
            timeFilter="timeFilter"
          />
        </FilterLineBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="avg_work_time"
            title="Средняя"
            timeFilter="timeFilter"
          />
        </FilterLineBox>
      </FilterItemBox>
    </FilterBox>
  );
}
export default WorkTimeFilter;
