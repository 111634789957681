import React from 'react';
import request from '../../../../../logic/utils/request';
import { ScreenCardsBox } from '../../../../styles/HandlePoints';
import Table from '../../../../../pages/private/components/table/Table';
import Config from './TableConfig';

function ScreenCards({
  screenList, zone, fetchScreens, zoneId, screenTypes, screenEdit,
}) {
  const delCard = async (id) => {
    const screens = screenList.filter((item) => item.screenId !== id);
    const newData = { ...zone, config: { ...zone.config, screens } };
    await request(`/server/api/zone/${zoneId}/`, 'update', newData);
    fetchScreens();
  };

  const handleIcon = (e, row) => {
    const { name } = e.target.dataset;
    if (name === 'del') {
      delCard(row.screenId);
    } else {
      screenEdit(row.screenId);
    }
  };

  const Setting = Config(screenTypes);
  const { columns } = Setting;

  return (
    <ScreenCardsBox>
      {screenList && <Table keys="screenId" data={screenList} handleIcon={handleIcon} columns={columns} /> }
    </ScreenCardsBox>
  );
}

export default React.memo(ScreenCards);
