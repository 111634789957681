import React, { useState } from 'react';
import {
  Button, Input, Form, Card,
} from 'antd';
import Surveys from './Surveys/Surveys';
import request from '../../../../../logic/utils/request';

function SurveyWrapper(props) {
  const {
    data, deleteSurvey, openSurveyDrawer, getData,
  } = props;
  const [surveyText, setSurveyText] = useState('');

  const addNewQuestion = async () => {
    const surveyData = {
      surveyname: surveyText,
      connectedQuestions: [],
    };

    await request('/server/api/qcsurvey/', 'add', surveyData);
    setSurveyText('');
    getData();
  };

  return (
    <>

      <Card
        title="Добавить анкету"
        style={{ marginBottom: '18px' }}
      >
        <Form>
          <Form.Item>
            <Input
              placeholder="Название анкеты"
              size="large"
              value={surveyText}
              onChange={(e) => { setSurveyText(e.target.value); }}
              defaultValue={surveyText}
            />
          </Form.Item>

          <Button type="primary" size="large" onClick={addNewQuestion} disabled={!surveyText.length > 0}>
            Добавить анкету
          </Button>
        </Form>
      </Card>

      <Surveys
        tableData={data}
        deleteSurvey={deleteSurvey}
        openSurveyDrawer={openSurveyDrawer}
        getData={getData}
      />
    </>
  );
}

export default SurveyWrapper;
