import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ButtonContent, BoxButtonContent } from '../../utils/style';
import { changeDictionary, changeConfig, setTicket } from '../../../store/actions';
import store from '../../../store/store';
import changeStyle from '../common/changeStyle';

const { dispatch } = store;

function Button({ scene, ...props }) {
  const {
    item, style, selectScreen,
  } = props;
  const navigate = useNavigate();
  const { ticket, twoScreen } = useSelector(({ config, ticket: ticketItem }) => ({
    ticket: ticketItem,
    twoScreen: config.twoScreen,
  }));

  const isActive = twoScreen && ticket.q_id === item.params.value;

  const styleButton = changeStyle({ style, item });

  styleButton.textShadow = 'none';
  if (isActive) {
    styleButton.background = styleButton.backgroundHover;
    styleButton.color = styleButton.colorHover;
  }

  const onClick = ({ name, value, fid }, path) => {
    if (!path) return;
    if (name === 'queue') {
      if (value !== 0) dispatch(setTicket({ name: 'q_id', value }));
      if (twoScreen) {
        dispatch(changeConfig({ name: 'nextScreen', value: path }));
        return;
      }
    } else {
      dispatch(changeDictionary({ fid, to: path, fv: [value] }));
    }
    if (!twoScreen) navigate(`../${path}`);
  };

  const { iconPosition, contentPosition } = styleButton;

  const fontSizeExtra = styleButton.fontSize / 2;
  const { iconTop } = styleButton;
  const text = item.name.replace(/\\\\/g, '</br>');
  const extraText = item.extraText ? item.extraText.replace(/\\\\/g, '</br>') : undefined;

  const paragraphStyle = {
    ...styleButton.text,
    textAlign: styleButton.textAlign,
    fontFamily: styleButton.fontFamily,
  };

  const spanStyle = {
    fontSize: fontSizeExtra,
    textAlign: styleButton.textAlign,
  };

  return (
    <div
      onDragStart={() => false}
      onClick={() => {
        if (selectScreen) return;
        onClick(item.params, item.path);
      }}
      style={styleButton}
    >
      <ButtonContent
        heightIcon={styleButton.heightIcon}
        img={item.icon}
        iconPosition={iconPosition}
        contentPosition={contentPosition}
        iconTop={iconTop}
      >
        {item.icon && (
        <img
          style={{ ...styleButton.icon }}
          src={item.icon && item.icon.replace(/url\(|"|\)/g, '')}
          alt=""
        />
        )}
        <BoxButtonContent img extraText={item.extraText}>
          {/* eslint-disable-next-line react/no-danger */}
          <p style={paragraphStyle} dangerouslySetInnerHTML={{ __html: text }} />
          {item.extraText
          && (
          // eslint-disable-next-line react/no-danger
          <span style={spanStyle} dangerouslySetInnerHTML={{ __html: extraText }} />
          )}
        </BoxButtonContent>
      </ButtonContent>
    </div>
  );
}

function GetButtons({ ...props }) {
  const { scene, button } = props;
  const buttons = scene ? scene.buttons : button;

  if (buttons.length === 0 && scene.type !== 'infoScreen') {
    return (
      <h1>
        Не заданы кнопки.
        <br />
        Обратитесь к Админстратору
      </h1>
    );
  }
  return buttons.map((item) => (
    <Button key={item.id} item={item} {...props} />
  ));
}

export default React.memo(GetButtons);
