import React, { useState, useEffect } from 'react';
import axios from 'axios';
import request from '../../../../../logic/utils/request';
import Input from '../../../../../pages/private/components/Input/Input';
import boardConfig from '../DeviceStyle/BoardStyle/defaultConfig';
import terminalConfig from '../DeviceStyle/TerminalStyle/defaultConfig';
import RenderCheckList from './RenderCheckList';

function Settings({
  deviceData, setDeviceData, setIsValid, preloader, showError,
}) {
  const [zones, setZones] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [wpList, setWpList] = useState([]);
  const { config } = deviceData;

  const fetchZonesTpls = async () => {
    preloader();
    await request('/server/api/zone/', 'get')
      .then((res) => { setZones(res.data.data); });
    try {
      const res = await axios.get('/api/templates');
      setTemplates(res.data.filter((item) => ['terminal', 'board', 'board-lottery'].includes(item.type)));
    } catch (e) {
      showError('не удалось получить список шаблонов устройств');
    }
    preloader();
  };

  const fetchWorkPlaceList = async () => {
    const res = await request('/server/api/wplace/', 'get');
    const wpIdName = res.data.data.map((item) => ({ id: item.id, name: item.name }));
    setWpList(wpIdName);
  };

  useEffect(() => {
    fetchZonesTpls();
    fetchWorkPlaceList();
  }, []);

  const zonesOptions = (
    zones && zones.map((item) => ({ id: item.id, name: item.name }))
  );

  const templatesOptions = (
    templates && templates.map((item) => ({ id: item.type, name: item.name }))
  );

  const handleInput = (e) => {
    const { name, value } = e.target;
    let validData = { ...deviceData, [name]: value };
    if (name === 'type') {
      if (value === 'board') {
        validData = { ...validData, config: { ...config, ...boardConfig } };
      } else if (value === 'terminal') {
        validData = { ...validData, config: { ...config, ...terminalConfig } };
      }
    }
    const {
      ipaddr: iV, port: pV, name: nV, type: tV,
    } = validData;
    setDeviceData({ ...validData });
    if (iV && pV && nV && tV) {
      setIsValid(true);
    } else setIsValid(false);
  };

  const {
    ipaddr, port, name, zoneId, type, uuid,
  } = deviceData;
  const items = [
    {
      label: 'Имя устройства:', name: 'name', value: name || '', required: true,
    },
    {
      label: 'IP адрес устройства:', name: 'ipaddr', value: ipaddr || '', required: true,
    },
    {
      label: 'Порт устройства:', name: 'port', value: port || '', required: true, type: 'number',
    },
    {
      label: 'Зона обслуживания:', name: 'zoneId', value: zoneId || '', noDefault: true, type: 'select', options: [{ id: null, name: 'Не выбрано' }, ...zonesOptions],
    },
    {
      label: 'Шаблоны:', name: 'type', value: type || '', noDefault: true, type: 'select', options: [{ id: null, name: 'Не выбрано' }, ...templatesOptions], required: true,
    },
    {
      label: 'Дисплей:', name: 'uuid', value: uuid || '',
    },
  ];

  const renderInputs = () => items.map((item) => (
    <Input
      key={item.name}
      m="0 0 10px"
      item={item}
      onChange={handleInput}
    />
  ));

  const handleCheck = ({ name: checkName, value }) => {
    const validData = { ...deviceData };
    const {
      ipaddr: iV, port: pV, name: nV, type: tV,
    } = validData;
    setDeviceData({
      ...deviceData,
      config: {
        ...config,
        [checkName]: value || !config[checkName],
      },
    });
    if (iV && pV && nV && tV) setIsValid(true);
  };

  return (
    <div style={{ width: '100%' }}>
      {renderInputs()}
      {type === 'board' && (
      <>
        <RenderCheckList
          wpList={wpList}
          setDeviceData={setDeviceData}
          deviceData={deviceData}
          setIsValid={setIsValid}
        />
        <Input
          item={{
            label: 'Удалять талоны', name: 'removeTickets', value: config.removeTickets, type: 'checkbox',
          }}
          onChange={({ target }) => handleCheck({ ...target })}
        />
      </>
      )}
      <div>
        <div style={{ marginBottom: 20 }}>Настройки дисплея:</div>
        <Input
          item={{
            label: 'Поверх всех окон', name: 'alwaysOnTop', value: deviceData.config.alwaysOnTop, type: 'checkbox',
          }}
          onChange={({ target }) => handleCheck({ ...target })}
        />
      </div>
    </div>
  );
}

export default Settings;
