import { Input, Button, Space } from 'antd';
import React, { useState, useEffect } from 'react';

function UploadForm({ onSave, lineText, setLineText }) {
  const [empty, setEmpty] = useState(true);

  useEffect(() => {
    if (lineText.length > 0) setEmpty(false);
    else setEmpty(true);
  }, [lineText]);

  return (
    <form enctype="multipart/form-data" arr>
      <Space size="middle" style={{ width: 400, marginRight: 15 }} direction="vertical">
        <Input.TextArea
          placeholder="Текст бегущей строки"
          onChange={(e) => setLineText(e.target.value)}
          value={lineText}
        />
        {!empty && <Button type="primary" onClick={onSave}>Сохранить</Button>}
      </Space>
    </form>
  );
}

export default UploadForm;
