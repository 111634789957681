import React from 'react';
import Margin from './modules/Margin';

function MarginComponent({ arr, dispatch }) {
  return (
    <>
      {arr.map((el) => (
        <Margin
          name="margin"
          element={el.parentName}
          dispatch={dispatch}
          data={el.data}
          title={el.title}
          key={el.title}
        />
      ))}
    </>
  );
}

export default MarginComponent;
