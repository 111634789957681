import React, { useContext } from 'react';

// components
import { Collapse } from 'antd'; // DOCS: https://ant.design/components/collapse/
import Colors from '../../../../../Components/Colors';
import FontSize from '../../../../../Components/FontSize';
import PositionOption from '../../../../../Components/PositionOption';
import Order from '../../../../../Components/Order';
import Margin from '../../../../../Components/Margin';
import Align from '../../../../../Components/Align';
import OptionDate from '../../../../../Components/modules/changeView';
import TextComponent from '../../../../../Components/modules/text';
import Uppercase from '../../../../../Components/Uppercase';
import FontFamily from '../../../../../Components/FontFamily';

// arr
import ArrColor from './components/color';
import ArrFontSize from './components/fontSize';
import ArrPosition from './components/position';
import ArrOrder from './components/order';
import ArrMargin from './components/margin';
import ArrAlign from './components/align';
import ArrUppercase from './components/uppercase';
import ArrFontFamily from './components/fontFamily';
import terminalCtx from '../../../../../../../../../../pages/public/terminal/context';

function Text() {
  const { fonts, dispatch, data } = useContext(terminalCtx);
  const { Panel } = Collapse;

  const {
    title, extraTitle, phone, extraText, clock, time, headerMain, headerSub,
  } = data;
  const arrColor = ArrColor({
    title, extraTitle, phone, extraText, clock, headerSub, headerMain,
  });
  const arrFontSize = ArrFontSize({
    title, extraTitle, phone, extraText, clock, time, headerSub, headerMain,
  });
  const arrMargin = ArrMargin({
    title, extraTitle, phone, extraText, clock, time,
  });
  const arrAlign = ArrAlign({
    title, extraTitle, phone, extraText, clock, time,
  });
  const arrPosition = ArrPosition({ extraText, clock });
  const arrOrder = ArrOrder({ phone, clock });
  const arrUppercase = ArrUppercase({
    extraText, headerMain, headerSub, title, extraTitle,
  });
  const arrFontFamily = ArrFontFamily({
    clock, extraText, title, extraTitle, time,
  });

  return (
    <Collapse className="customize-Collapse" ghost>
      <Panel className="customize-Collapse-Header-No-Top-Padding" header="Текст" key="1">
        <TextComponent title="Телефон" element="phone" value={phone.text} dispatch={dispatch} />
        <TextComponent title="Дополнительный текст" element="extraText" value={extraText.text} dispatch={dispatch} />
        <TextComponent title="Заголовок в шапке" element="headerMain" value={headerMain.text} dispatch={dispatch} />
        <TextComponent title="Подзаголовок в шапке" element="headerSub" value={headerSub.text} dispatch={dispatch} />
      </Panel>

      <Panel className="customize-Collapse-Header-No-Top-Padding" header="Параметры текста" key="2">
        <Collapse className="customize-Collapse" ghost>
          <Panel header="Текст заглавными буквами" key="1">
            <Uppercase noText="true" arr={arrUppercase} dispatch={dispatch} />
          </Panel>

          <Panel header="Шрифт" key="2">
            <FontFamily arr={arrFontFamily} options={fonts} dispatch={dispatch} />
          </Panel>

          <Panel header="Размер" key="3">
            <FontSize arr={arrFontSize} dispatch={dispatch} />
          </Panel>

          <Panel className="customize-Collapse-Header-No-Top-Padding" header="Расположение" key="4">
            <Collapse className="customize-Collapse" ghost>
              <Panel header="Выравнивание" key="1">
                <Align arr={arrAlign} dispatch={dispatch} />
              </Panel>

              <Panel header="Отступы" key="2">
                <Margin arr={arrMargin} dispatch={dispatch} />
              </Panel>

              <Panel className="customize-Collapse-Header-No-Top-Padding" header="Подвал" key="3">
                <Collapse className="customize-Collapse" ghost>
                  <Panel header="Выравнивание" key="1">
                    <PositionOption arr={arrPosition} dispatch={dispatch} />
                  </Panel>

                  <Panel header="Порядок размещения" key="2">
                    <Order arr={arrOrder} dispatch={dispatch} />
                  </Panel>
                </Collapse>
              </Panel>
            </Collapse>
          </Panel>

          <Panel header="Варианты написания даты" key="5">
            <OptionDate value={clock.viewType} dispatch={dispatch} />
          </Panel>
        </Collapse>

      </Panel>
      <Panel className="customize-Collapse-Colors" header="Цвет" key="3">
        <Colors arr={arrColor} dispatch={dispatch} />
      </Panel>
    </Collapse>
  );
}

export default Text;
