import React, { useState, useEffect, useCallback } from 'react';
import Queue from './Queue/QueueDoughnut';
import ReportTitle from '../../../ReportTitle';
import AvgWaitTimeQuery from '../AvgTimeGraphic/SqlAvgWaitTime';
import AvgProcTimeQuery from '../AvgTimeGraphic/SqlAvgProcTime';
import QueueQuery from './Queue/SqlQuery';
import { PreloaderBox } from '../../../../../../../../../../common/ui/Common';
import { ReportBox } from '../../../../../../../../components/report/ReportTableColumns';
import Preloader from '../../../../../../../../components/preloader/Preloader';

function DoughnutsReport(props) {
  const {
    view, loader, setLoader, startDate, endDate,
  } = props;
  const [dataParams, setDataParams] = useState();

  const fetchMonitoringData = useCallback(async () => {
    setLoader(true);
    const QueueData = await QueueQuery(startDate, endDate);
    const AvgProcTimeData = await AvgProcTimeQuery(startDate, endDate);
    const AvgWaitTimeData = await AvgWaitTimeQuery(startDate, endDate);

    const data = {
      Queue: QueueData.dataObjects,
      AvgProcTime: AvgProcTimeData.dataObjects,
      AvgWaitTime: AvgWaitTimeData.dataObjects,
    };

    setDataParams(data);
    setLoader(null);
  }, [startDate, endDate]);

  useEffect(() => {
    if (view) {
      fetchMonitoringData();
    }
  }, [startDate, endDate, fetchMonitoringData, view]);

  return (
    <ReportBox>
      <ReportTitle name="Распределение талонов по очередям" />
      { dataParams
        && (
          <Queue
            data={dataParams.Queue}
          />
        )}
      { loader
        && (
          <PreloaderBox>
            <Preloader isOpen small />
          </PreloaderBox>
        )}
    </ReportBox>
  );
}

export default DoughnutsReport;
