import React, { useState, useEffect } from 'react';
import CheckBoxList from '../../../../../../pages/private/components/CheckBoxList';

const RenderCheckList = ({
  questData, setQuestData, queue,
}) => {
  const { connectedQueues } = questData;
  const [checkedQueue, setCheckedQueue] = useState([]);

  useEffect(() => {
    setCheckedQueue([...connectedQueues]);
  }, [connectedQueues]);

  const items = [
    {
      label: 'Очереди:', name: 'connectedQueues', options: queue, checked: checkedQueue,
    },
  ];

  return (
    items.map((item) => (
      <div key={item.name}>
        {/* {item.label} */}
        <CheckBoxList
          item={item}
          data={questData}
          setData={setQuestData}
        />
      </div>
    ))
  );
};
export default RenderCheckList;
