export default ({ bottomButton }) => [
  {
    title: 'Отображать текст', value: bottomButton.textShow, paramName: 'textShow', parentName: 'bottomButton',
  },
  {
    title: 'Отображать иконку', value: bottomButton.iconShow, paramName: 'iconShow', parentName: 'bottomButton',
  },
  {
    title: 'Стандартное расположение кнопок', value: bottomButton.position, paramName: 'position', parentName: 'bottomButton',
  },
];
