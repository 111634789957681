/* eslint-disable no-nested-ternary */
import {
  Input, InputNumber, Switch, Button, Tooltip,
} from 'antd';
import {
  EditOutlined, RedoOutlined, SaveOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import request from '../../../../../logic/utils/request';
import { useToggle } from '../../../../../logic/hooks/useToggle';
import MultiSelect from '../../../../../pages/private/subpages/queue/workplaces/Parameters/MultiSelect/MultiSelect';
import CheckBoxList from '../../../../../pages/private/components/CheckBoxList';

function Value({
  type, val, showError, data, multiData, par,
}) {
  const [updateValue, setUpdateValue] = useState(val);
  const [valid, setValid] = useToggle(true);

  const operatorHideWidgetsData = [
    { id: 1, key: 'fullname', name: 'Полное имя клиента в талоне' },
    { id: 2, key: 'phonenumber', name: 'Номер телефона клиента в талоне' },
    { id: 3, key: 'email', name: 'E-mail клиента в талоне' },
    { id: 4, key: 'comment', name: 'Комментарий к талону' },
    { id: 5, key: 'refundv', name: 'Кнопка "Вернуть в очередь" в окне валидации' },
    { id: 6, key: 'questioncount', name: 'Счетчик решенных вопросов' },
    { id: 7, key: 'filters', name: 'Кнопка "Подключенные фильтры"' },
  ];

  const handleTableChange = async (param, value) => {
    const dataArr = [{ param, value }];

    await request('/server/api/officeparams/', 'update', dataArr)
      .catch((e) => showError(e.data.error));
    console.log(dataArr);
  };

  const handleMultiSelect = (param, newVal) => {
    const newParsed = newVal.map((item) => item.value);
    const joined = newParsed.join(' ');
    handleTableChange(param, joined);
  };

  const setUserData = (e) => {
    const disableElements = [];
    const { connectedRoles: disableElementsId } = e;
    disableElementsId.forEach((disableId) => {
      operatorHideWidgetsData.forEach((element) => {
        if (element.id === disableId) {
          disableElements.push(element.key);
        }
      });
    });

    handleTableChange('operatorHideWidgets', disableElements.join(','));
  };

  const checedValues = [];
  const dbcheced = val.split(',');

  dbcheced.forEach((el) => {
    operatorHideWidgetsData.forEach((element) => {
      if (element.key === el) {
        checedValues.push(element.id);
      }
    });
  });
  return (
    <div>
      {}
      {type === 'boolean'
        ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Switch
              disabled={valid}
              defaultChecked={Boolean(Number(updateValue))}
              checkedChildren="вкл"
              unCheckedChildren="выкл"
              onClick={(value) => {
                setUpdateValue(value ? '1' : '0');
              }}
            />
            {valid === true
              && <Tooltip title="Изменить"><Button type="primary" onClick={() => setValid(false)}><EditOutlined /></Button></Tooltip>}
            {valid === false
              && (
              <Tooltip title="Сохранить">
                <Button
                  type="primary"
                  onClick={() => {
                    handleTableChange(data.param, updateValue);
                    setValid(true);
                  }}
                >
                  <SaveOutlined />
                </Button>
              </Tooltip>
              )}
          </div>
        )
        : type === 'number'
          ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <InputNumber
                disabled={valid}
                value={updateValue}
                onChange={(v) => {
                  setUpdateValue((v));
                }}
              />
              {valid === true
                ? <Tooltip title="Изменить"><Button type="primary" onClick={() => setValid(false)}><EditOutlined /></Button></Tooltip>
                : (
                  <Tooltip title="Сброс">
                    <Button
                      type="primary"
                      onClick={() => {
                        handleTableChange(data.param, val);
                        setUpdateValue(val);
                      }}
                    >
                      <RedoOutlined />
                    </Button>
                  </Tooltip>
                )}
              {valid === false
                && (
                <Tooltip title="Сохранить">
                  <Button
                    type="primary"
                    onClick={() => {
                      handleTableChange(data.param, updateValue);
                      setValid(true);
                    }}
                  >
                    <SaveOutlined />
                  </Button>
                </Tooltip>
                )}
            </div>
          )
          : type === 'string' || type === 'text' || type === 'float' ? (
            <div>
              {par !== 'operatorHideWidgets' ? (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Input
                    disabled={valid}
                    value={updateValue}
                    onChange={((v) => setUpdateValue(v.target.value))}
                  />
                  {valid === true
                    ? <Tooltip title="Изменить"><Button type="primary" onClick={() => setValid(false)}><EditOutlined /></Button></Tooltip>
                    : (
                      <Tooltip title="Сброс">
                        <Button
                          type="primary"
                          onClick={() => {
                            handleTableChange(data.param, val);
                            setUpdateValue(val);
                          }}
                        >
                          <RedoOutlined />
                        </Button>
                      </Tooltip>
                    )}
                  {valid === false
                && (
                <Tooltip title="Сохранить">
                  <Button
                    type="primary"
                    onClick={() => {
                      handleTableChange(data.param, updateValue);
                      setValid(true);
                    }}
                  >
                    <SaveOutlined />
                  </Button>
                </Tooltip>
                )}
                </div>
              ) : (
                <CheckBoxList
                  item={{
                    label: 'Элементы:', name: 'connectedRoles', options: operatorHideWidgetsData, checked: checedValues,
                  }}
                  data={operatorHideWidgetsData}
                  setData={setUserData}
                />
              )}
            </div>
          )
            : (
              <MultiSelect
                data={multiData}
                value={val}
                onSave={(newVal) => handleMultiSelect(data.param, newVal)}
              />
            )}
    </div>

  );
}

export default Value;
