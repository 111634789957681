import React from 'react';
import {
  FilterBox, FilterBoxTitle, FilterItemBox, FilterLineBox,
} from '../style';
import AllFiterDate from '../utils/ServedFilters/AllFiterDate';

function ServedDateFilters({ changeFilter }) {
  return (
    <FilterBox>
      <FilterBoxTitle>Дата обслуживания</FilterBoxTitle>
      <FilterItemBox>
        <FilterLineBox>
          <AllFiterDate
            changeFilter={changeFilter}
            filld="create_data"
            title="Начало"
            smallTitle="true"
          />
        </FilterLineBox>
        <FilterLineBox>
          <AllFiterDate
            changeFilter={changeFilter}
            filld="close_data"
            title="Окончание"
            smallTitle="true"
          />
        </FilterLineBox>
      </FilterItemBox>
    </FilterBox>
  );
}
export default ServedDateFilters;
