import axios from 'axios';

// eslint-disable-next-line no-unused-vars
export default function request(url, action = 'get', data = {}, query = '', limit = 10, offset = 0, sort = {}, filter = {}) {
  let body = { data };

  // Filter some unnecessary params
  delete body.data.created_by_id;
  delete body.data.created_by_username;
  delete body.data.created_on;
  delete body.data.modified_by_id;
  delete body.data.modified_by_username;
  delete body.data.modified_on;

  Object.keys(body.data).forEach((key) => {
    if (typeof (body.data[key]) === 'string') body.data[key] = body.data[key].trim();
  });

  if (action === 'get') {
    body = {
      ...body, limit, offset, sort, filter,
    };
  }
  return new Promise(((resolve, reject) => {
    axios({
      method: 'post',
      url: `${url}${action !== 'no' ? `?action=${action}` : ''}`,
      data: body,
    })
      .then((res) => {
        const { data: resData } = res;

        if (resData.error) {
          // обработку ошибок
          reject(res);
        } else {
          resolve(res);
        }
      }, (err) => {
        reject(err);
      });
  }));
}
