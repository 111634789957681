import React from 'react';
import Timer from './utils/timer';
import { ReactComponent as Logo } from './utils/logo.svg';

function ExtraInfo(props) {
  const {
    extraText, clock, time, phone, logoABT, logoComp,
  } = props;

  return (
    <>
      <div className="layout__absolut_item" data-name="text" style={extraText}>
        <div style={{ order: Number(!phone.order) }}>{extraText.text}</div>
        <div style={{ ...phone, order: phone.order }}>{phone.text}</div>
      </div>

      <div className="layout__absolut_item" data-name="clock" style={clock}>
        <Timer clock={clock} styleTime={time} viewType={clock.viewType} />
      </div>

      <div className="layout__absolut_item" data-name="logoAbt" style={logoABT}>
        <Logo />
      </div>

      {logoComp.backgroundImage && (
        <div className="layout__absolut_item" data-name="logoComp" style={logoComp}>
          <img src={logoComp.backgroundImage} alt="" />
        </div>
      )}
    </>
  );
}

export default ExtraInfo;
