import React from 'react';
import {
  FilterBox, FilterBoxTitle, FilterItemBox, FilterLineBox,
} from '../style';
import AllFilter from '../utils/ServedFilters/AllFilter';

function ActionFilters({ changeFilter }) {
  return (
    <FilterBox>
      <FilterBoxTitle>Действия</FilterBoxTitle>
      <FilterItemBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="share_cnt"
            title="Талонов обработано"
            smallTitle="true"
          />
        </FilterLineBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="solved_questions"
            title="Кол-во решенных вопросов"
            smallTitle="true"
          />
        </FilterLineBox>
      </FilterItemBox>
    </FilterBox>
  );
}
export default ActionFilters;
