import {
  useEffect,
  useState,
} from 'react';
import SqlQuery from './SqlQuery';
import { CardReportTemplate } from '../../../../utils/formater';

const ReportCard = (props) => {
  const { view, startDate, endDate } = props;
  const [ReportParams, setReportParams] = useState();

  useEffect(() => {
    if (view) {
      SqlQuery(startDate, endDate).then((resp) => {
        const { dataObjects, query } = resp;
        const params = {
          items: dataObjects,
          query,
        };
        setReportParams(params);
      });
    }
  }, [startDate, endDate, view]);

  return (
    CardReportTemplate(ReportParams ? ReportParams.items[0] : [])
  );
};

export default ReportCard;
