import React from 'react';

const volumeVideo = ({
  dispatch, element, value, paramName, rusName,
}) => (

  <span className="box__color">
    {/* eslint-disable-next-line react/no-danger */}
    <span className="settings__styles__el__label" dangerouslySetInnerHTML={{ __html: rusName }} />
    <input
      type="range"
      id="settings__styles__video__volume"
      min="0"
      max="1"
      step="0.05"
      value={value}
      onChange={(e) => dispatch({
        type: 'params',
        name: element,
        param: paramName,
        value: Number(e.target.value),
      })}
    />
  </span>
);

export default volumeVideo;
