import React, { useState, useRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import findPosition from '../../../../../pages/private/components/Input/findPosition';
import '../../../../styles/elements2/input/selectInput.scss';

function Select({
  options = [],
  value,
  onChange,
  name,
  noDefault,
  m,
  width,
  maxHeight,
  readOnly,
  bg,
  disabled,
  align,
}) {
  const [active, setActive] = useState(false);
  const sel = useRef(null);
  const box = useRef(null);
  const activeElement = options.find((el) => el.id === value);

  const Change = (id) => {
    const body = { target: { name, value: id } };
    onChange(body);
    setActive(!active);
  };

  return (
    <div
      className="select_input_box"
      ref={box}
      style={{
        align,
        m,
        width,
      }}
    >
      <div
        className="select_input__div"
        data-test="clickItem"
        ref={sel}
        style={{ background: bg }}
        onClick={() => {
          findPosition(sel.current, () => {});
          setActive(!active);
        }}
      >
        <span data-test="defaultValue">{activeElement?.name || ((!noDefault && options[0]?.name) ? options[0].name : 'Не выбрано')}</span>
        <FaChevronDown />
      </div>
      {!disabled && active && options && !readOnly ? (
        <>
          <div
            className="select_input__close"
            onClick={() => setActive(false)}
          />
          <div
            className="select_input__options"
            data-test="selectedMenu"
            style={{
              maxHeight,
            }}
          >
            {options.map((el) => (
              <div
                className="select_input__options__item"
                style={{ background: bg }}
                key={el.id}
                onClick={() => {
                  Change(el.id);
                }}
              >
                {el.name}
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default React.memo(Select);
