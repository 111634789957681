import React from 'react';
import IconFormatter from '../../../utils/iconFormatter';
import sortCaret from '../../statistics/quality-control/utils/sortCaret';
import { BtnTable } from '../../../../../common/ui/Common';
import { ReactComponent as False } from '../../../../../common/images/false.svg';
import { ReactComponent as True } from '../../../../../common/images/true.svg';

function checkFormatter(value) {
  return (<BtnTable bg="accent" hNone>{value ? <False fill="white" /> : <True fill="white" />}</BtnTable>);
}

export default function Setting(zones, searchPropsFactory) {
  function zoneFormatter(value) {
    const zone = zones.find((zoneItem) => zoneItem.id === value[0]);
    return (
      <div>{zone ? zone.name : ''}</div>
    );
  }

  return {
    columns: [
      {
        dataIndex: 'id',
        key: 'id',
        title: 'ID',
        ...searchPropsFactory('id', 'id'),
      },
      {
        dataIndex: 'name',
        key: 'name',
        title: 'Наименование',
        ...searchPropsFactory('name', 'Наименование'),
      },
      {
        dataIndex: 'placename',
        key: 'placename',
        title: 'Обозначение',
        ...searchPropsFactory('placename', 'Обозначение'),
      },
      {
        dataIndex: 'connectedZones',
        key: 'connectedZones',
        title: 'Подключенная зона',
        render: (_, row) => zoneFormatter(row.connectedZones),
      },
      {
        dataIndex: 'disabled',
        key: 'disabled',
        title: 'Статус',
        sort: true,
        sortCaret,
        render: checkFormatter,
      },
      {
        dataIndex: 'Settings',
        key: 'Settings',
        title: '',
        width: 140,
        render: () => (
        //  IconFormatter({ filter: true, write: true, params: true })
        // фильтер у рабочего места отвечал за показ компонента
        // привязки доп полей к рабочему месту. Рабочее место
          IconFormatter({ write: true, params: true })
        ),
      },
    ],
  };
}
