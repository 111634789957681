import React, { useState } from 'react';
import {
  Button, Drawer, Divider, Badge, Col, Row,
} from 'antd';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import getReportName from '../utils/getReportName';
import reportStore, { chnageReportTypeAndResetFilters } from '../../../../../../logic/store/pullstate/filterStore';

function ChooseReportType() {
  const [openReporrDrawer, setOpenReportDrawer] = useState(false);
  const cReportStore = reportStore.useState((s) => s);
  const { reportType } = cReportStore;

  const onClose = () => {
    setOpenReportDrawer(false);
  };

  const reportGroup = [
    {
      title: 'Ocновные',
      tag: 'main',
      reportButtons: [
        'generalReport',
        'ticketReport',
        'operatorReport',
        'queueReport',
        'workPlaceReport',
      ],
    },
    {
      title: 'Дополнительные',
      tag: 'other',
      reportButtons: [
        'groupReport',
        'clockwiseReport',
        'dynamicsServiceTime',
        'surveyReport',
        'preEntryReport',
        'sourceReport',
        'solvedQuestionReport',
      ],
    },
    {
      title: 'Специальные',
      tag: 'new',
      reportButtons: [
        'operatorLogi',
      ],
    },
    {
      title: 'Диаграмма',
      tag: 'diagramma',
      reportButtons: [
        'timeСonsumption', 'ticketClosePieChart',
      ],
    },

  ];

  const selectReport = (cReportType) => {
    chnageReportTypeAndResetFilters({
      reportType: cReportType,
      reportTitle: getReportName(cReportType),
    });
  };

  const renderReportsSelect = () => reportGroup.map((el) => {
    const { title, reportButtons, tag } = el;
    return (
      <React.Fragment key={tag}>
        <Divider orientation="left">
          {title}
        </Divider>
        <Row gutter={[16, 24]}>
          {reportButtons.map((currentReporType) => (
            <Col key={currentReporType} className="gutter-row" span={12}>
              {tag === 'new'
                ? (
                  <Badge.Ribbon text="Новый" color="green">
                    <Button
                      size="large"
                      type={currentReporType === reportType ? 'primary' : ''}
                      block
                      onClick={() => {
                        selectReport(currentReporType);
                      }}
                    >
                      {getReportName(currentReporType)}
                    </Button>
                  </Badge.Ribbon>
                )
                : (
                  <Button
                    size="large"
                    type={currentReporType === reportType ? 'primary' : ''}
                    block
                    onClick={() => {
                      selectReport(currentReporType);
                    }}
                  >
                    {getReportName(currentReporType)}
                  </Button>
                )}

            </Col>
          ))}
        </Row>
      </React.Fragment>
    );
  });

  return (
    <>
      <Button size="large" type="button" onClick={() => { setOpenReportDrawer(true); }} icon={<MenuUnfoldOutlined />}>Выбор типа отчета</Button>
      <Drawer title="Выберите тип отчета" onClose={onClose} open={openReporrDrawer} width="680">
        {renderReportsSelect()}
      </Drawer>
    </>
  );
}
export default ChooseReportType;
