import request from './request';

const GetYear = async (id, year) => {
  try {
    const ye = {};
    if (!id) return ye;
    const url = `/server/public/preorder/calendar/${id}`;

    const days = await request(url);
    console.log('days === ', days);
    days.data.data.forEach((el) => {
      const arr = el.calendarDayDate.split('.');
      const y = arr[2];
      const m = arr[1];
      const d = arr[0];
      if (ye[y]) {
        if (ye[y][m]) {
          if (!ye[y][m][d]) {
            ye[y][m][d] = { id: el.calendarDayId, count: el.calendarDayFreeSlots };
          }
        } else {
          ye[y][m] = {};
          ye[y][m][d] = { id: el.calendarDayId, count: el.calendarDayFreeSlots };
        }
      } else {
        ye[y] = {};
        ye[y][m] = {};
        ye[y][m][d] = { id: el.calendarDayId, count: el.calendarDayFreeSlots };
      }
    });
    return { year: ye, selectedYear: ye[year] };
  } catch (e) {
    console.log('12312 === ', e);
    return (e);
  }
};

export default GetYear;
