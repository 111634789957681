import moment from 'moment';
import { getReportRange } from '../../../../../../../../logic/store/pullstate/filterStore';

const defaultFilters = (defaultSort = '') => {
  const { startDate, endDate } = getReportRange();

  const filters = {
    main: {
      operator: 'all',
      workPlace: 'all',
      queue: 'all',
      close_cnt: 'all',
      all_cnt: 'all',
      groupinType: 'wp_id',
      bunch_id: 'all',
      neyavka_cnt: 'all',
      avg_wait_time: 'all',
      total_wait_time: 'all',
      ticket_id: 'all',
      avg_proc_time: 'all',
      total_proc_time: 'all',
      total_work_time: 'all',
      avg_work_time: 'all',
      registered: 'all',
      mark1: 'all',
      mark2: 'all',
      mark3: 'all',
      mark4: 'all',
      mark5: 'all',
      markcnt: 'all',
      share_cnt: 'all',
      solved_questions: 'all',
      create_data: 'all',
      close_data: 'all',
      ticket_number: 'all',
      status: 'all',
      prior: 'all',
      totalproc: 'all',
      ticket_mark: 'all',
      pincode: 'all',
      clientfio: 'all',
      clientmail: 'all',
      clientphone: 'all',
      dateType: 'day',
      dateTypeDescription: 'день',
      startDate,
      endDate,
      historyDate: moment().format('DD.MM.YYYY HH:mm'),
      intervalReprotHistoryDate: 'all',
      orderArr: (defaultSort) ? [defaultSort] : [],
    },
  };
  return (filters);
};

export default defaultFilters;
