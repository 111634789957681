export default ({ bottomButton }) => [
  {
    title: 'Ширина', value: bottomButton.width, paramName: 'width', parentName: 'bottomButton',
  },
  {
    title: 'Высота', value: bottomButton.height, paramName: 'height', parentName: 'bottomButton',
  },
  {
    title: 'Угол <br/>скругления', value: bottomButton.borderRadius, paramName: 'borderRadius', parentName: 'bottomButton',
  },
  {
    title: 'Толщина <br/>обводки', value: bottomButton.borderWidth, paramName: 'borderWidth', parentName: 'bottomButton',
  },
];
