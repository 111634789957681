import React, { useContext } from 'react';
import BoardCtx from '../../../../../../../pages/public/board/utils/BoardCtx';

function BoardElements() {
  const { setActiveEl, activeEl } = useContext(BoardCtx);
  const elements = [
    { id: 'header', name: 'Шапка' },
    { id: 'columns', name: 'Колонки' },
    { id: 'big', name: 'Окно вызова' },
    { id: 'time', name: 'Окно даты' },
    { id: 'video', name: 'Окно видео' },
    { id: 'news', name: 'Окно Новостей' },
    { id: 'footer', name: 'Бегущая строка' },
  ];

  const renderBtns = () => elements.map((element) => {
    let active = false;
    if (activeEl === element.id) {
      active = true;
    }

    return (
      <div
        className="settings__element__item"
        onClick={() => setActiveEl(element.id)}
        key={element.id}
        style={{ background: active && 'rgb(252, 76, 2)', color: active && '#fff' }}
      >
        {element.name}
      </div>
    );
  });

  return (
    <div className="settings__element">
      {renderBtns()}
    </div>
  );
}
export default BoardElements;
