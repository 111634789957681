import styled from 'styled-components';

export const SomeLineColumn = styled.div`
  display: flex; 
  flex-direction: column;
`;

export const ReportLine = styled.div`
  display: flex; 
  justify-content: space-between;
  height: 41px;
  align-items: center;
  :not(:first-child){
    margin-top: 5px;
  }
`;

export const LineDescription = styled.span`
  display: flex;
  align-items: center;
`;

export const LineValue = styled.span`
  font-weight: 600;
`;

export const ColumnButtonNotActive = styled.button`
  border-radius: 3px;
  padding: 12px 20px 12px 20px;;
  background: ${(p) => p.theme.gray};
  border: 0;
  color: ${(p) => p.theme.buttonHover};
  font-size: 14px;
  cursor: default;
  outline: none;
  display: flex;
  width: ${(p) => (p.size ? '' : '75px')};
  justify-content: center;
`;

export const ColumnButton = styled.button`
  border-radius: 3px;
  padding: 12px 20px 12px 20px;;
  background: ${(p) => p.theme.accent};
  border: 0;
  color: ${(p) => p.theme.buttonHover};
  font-size: 14px;
  cursor: default;
  outline: none;
  display: flex;
  width: ${(p) => (p.size ? '' : '75px')};
  justify-content: center;
`;

export const DoughnutCenterLabel = styled.div`
  font-weight: 400;
  color: #7f7f7f;
  position: absolute;
  left: 20%;
  top: 48px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  `;

export const DoughnutContainer = styled.div`
  position: relative;
`;

export const StarRaitingContainer = styled.div`
  display: flex; 
  justify-content: center;
`;

export const StarRatings = styled.div`
  unicode-bidi: bidi-override;
  color: #c5c5c5; 
  font-size: 20px;
  margin: 0;
  position: relative;
  padding: 0;
  text-shadow: ${(p) => `0px 1px 0 ${p.theme.hover}`};
  & ::before {
    content: '★★★★★'
  }
`;

export const StarRatingsTop = styled.div((props) => ({
  color: '#e7711b',
  padding: '0',
  position: 'absolute',
  zIndex: '1',
  display: 'block',
  top: '0',
  left: '0',
  overflow: 'hidden',
  width: props.width,
  height: '100%',
}));

export const StarRatingsButton = styled.div`
  padding: 0;
  display: block;
  z-index: 0;
`;

export const DivWithoutStyles = styled.div``;

export const CardReportBox = styled.div`
  flex-basis: calc(33.3% - 15px);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  margin: 0 7.5px 15px;
  width: 100%;
  position: relative;
`;

export const CardReportTitle = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 15px;
  background-color: ${(p) => p.theme.accent};
  color: ${(p) => p.theme.buttonHover};
  margin-bottom: 0;
  text-align: center;
  @media(max-width: 1450px) {
    font-size: 14px;
  }
`;

export const CardReportBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CardReportElement = styled.div`
  background-color: ${(p) => p.theme.bgTable};
  color: ${(p) => p.theme.text};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  font-size: 26px;
  width: 100%;
`;

export const CardReportDiscription = styled.div`
  padding: 5px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: Roboto;
  font-weight: 100;
  position: relative;
  text-align: center;
  color: ${(p) => p.theme.bgButton};
  background-color: ${(p) => p.theme.main};
  &:after {
    position: absolute;
    content: '';
    bottom: -19px;
    left: 50%;
    transform: translateX(-50%);
    border: 10px solid transparent;
    border-top-color: ${(p) => p.theme.main};
  }
  @media(max-width: 1450px) {
    font-size: 10px;
  }
`;

export const CardReportItem = styled.div`
  padding: 15px 10px;
  text-align: center;
  font-family: Roboto;
  font-weight: 800;
`;

export const Qq = styled.div`
  display: flex;
  margin: ${(p) => p.m || 0};
  justify-content: center;
`;

export const DoughnutBox = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 15px;
  background-color: {(p) => p.theme.accent};
  color: {(p) => p.theme.white};
  margin-bottom: 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  border-radius: 3px 3px 0 0;
`;

export const ReportBox = styled.div`
  padding: 25px;
  margin-top: 25px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0,0,0,.1);
  background: ${(p) => p.theme.bgTable};
  margin-bottom: 50px;
`;

export const QalityAvgMarkBox = styled.div`
  color: #25547A;
  position: relative;
  padding-top: 5px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  margin-bottom: 10px;
  font-size: 18px
`;
