import React, { useState } from 'react';
import { Select } from 'antd';
import {
  FilterBox, FilterBoxTitle, FilterItemBox,
} from '../style';
import HistoryDateSelect from './HistoryDateSelect';
// import Dropdown from '../../../../../../components/dropdown/Dropdown';

function Dynamic({ changeFilter }) {
  const [rangeType, setRangeType] = useState('day');
  const dropdownConfig = {
    headerTitleText: 'День',
    options: {},
    items: [
      { key: 'day', name: 'day', text: 'День' },
      { key: 'month', name: 'month', text: 'Месяц' },
      { key: 'quarter', name: 'quarter', text: 'Квартал' },
      { key: 'year', name: 'year', text: 'Год' },
    ],
  };

  const changeOperetorFilter = async (value) => {
    let dateTypeDescription = '';
    switch (value) {
      case 'day':
        dateTypeDescription = 'день';
        break;
      case 'month':
        dateTypeDescription = 'месяц';
        break;
      case 'quarter':
        dateTypeDescription = 'квартал';
        break;
      case 'year':
        dateTypeDescription = 'год';
        break;
      default:
        break;
    }
    setRangeType(value);
    changeFilter('dateType', value);
    changeFilter('dateTypeDescription', dateTypeDescription);
  };

  return (
    <>
      <HistoryDateSelect changeFilter={changeFilter} rangeType={rangeType} />
      <FilterBox>
        <FilterBoxTitle>Период</FilterBoxTitle>
        <FilterItemBox>
          {/* <Dropdown */}
          {/*  config={dropdownConfig} */}
          {/*  clickHandler={changeOperetorFilter} */}
          {/*  replaceableTitle */}
          {/* /> */}
          <Select onChange={changeOperetorFilter} value={rangeType} className="select">
            {dropdownConfig.items.map((el) => (
              <Select.Option value={el.name} key={el.key}>{el.text}</Select.Option>
            ))}
          </Select>
        </FilterItemBox>
      </FilterBox>
    </>
  );
}

export default Dynamic;
