import {
  Button, Card, Col, Input, Row, notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import FormTooltip from '../../Constructor/ScreenForm/FormTooltip';
import defaultValues from '../../../../utils/defaultValues';
import api from '../../../../utils/api';
import zoneStore, { updateZoneStore } from '../../../Store';
import { updateQueueStore } from '../../../Store/queueStore';

function QueueForm(props) {
  const {
    screen, button, toggleDrawer, setUpdatedButton,
  } = props;

  const screens = zoneStore.useState((s) => s.config.screens);

  const [workWeeks, setWorkWeeks] = useState([]);
  const [formData, setFormData] = useState({ ...defaultValues.queueForm });
  const [isDisabled, setIsDisabled] = useState(true);

  const {
    prefix, fullname, name: queueName, position, workWeek,
  } = formData;

  const fetchWorkWeeks = async () => {
    const { data, status } = await api.getWorkWeeks();

    if (status) {
      setWorkWeeks(data);
      const firstQueue = data[0];
      setFormData((prev) => ({
        ...prev,
        workWeek: {
          workWeekId: firstQueue.id,
          workWeekName: firstQueue.name,
        },
      }));
    }
  };

  useEffect(() => {
    fetchWorkWeeks();
  }, []);

  const handleInput = (e) => {
    const { value, name } = e.target;

    const currentValue = { ...formData, [name]: value };

    if (currentValue.name) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    setFormData({ ...currentValue });
  };

  const handleCardClick = (week) => {
    setFormData((prev) => ({
      ...prev,
      workWeek: { workWeekId: week.id, workWeekName: week.name },
    }));
  };

  const updateZone = async (message) => {
    const res = await api.updateZone({ ...zoneStore.getRawState() });

    if (res.status) {
      notification.success({
        message,
      });
    } else {
      notification.error({
        message: res.errMessage,
      });
    }
  };

  const addQueue = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    toggleDrawer('close');

    const res = await api.addQueue({ ...formData });

    if (res.status) {
      notification.success({
        message: 'Очередь добавлена',
      });
      const { data: updatedQueue } = await api.getQueue();
      updateQueueStore({ queue: updatedQueue });

      const updatedButton = { ...button, params: { ...button.params, value: res.createdQueueId } };

      const newButtons = screen.buttons.map((item) => {
        if (item.id === updatedButton.id) {
          return updatedButton;
        }
        return item;
      }).sort((a, b) => a.position - b.position);

      const updatedScreen = { ...screen, buttons: [...newButtons] };
      const newScreens = screens.map((item) => {
        if (item.screenId === updatedScreen.screenId) {
          return updatedScreen;
        }
        return item;
      });

      const config = { ...zoneStore.getRawState().config };
      updateZoneStore({
        ...zoneStore.getRawState(),
        config: { ...config, screens: newScreens },
      });

      setUpdatedButton({ ...updatedButton });

      updateZone('Очередь привязана');
    } else {
      notification.error({
        message: res.errMessage,
      });
    }
  };

  return workWeeks.length > 0 ? (
    <Card>
      <form onSubmit={addQueue}>
        <Row gutter={[0, 20]}>
          <Row gutter={[0, 10]} style={{ width: '100%' }}>
            <Col span={24}>
              <span>
                Введите префикс очереди
              </span>
              <FormTooltip text="Системный параметр для распознавания экранов" />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Префикс"
                onChange={handleInput}
                name="prefix"
                value={prefix}
              />
            </Col>
          </Row>
          <Row gutter={[0, 10]} style={{ width: '100%' }}>
            <Col span={24}>
              <span>
                Введите позицию очереди
              </span>
              <FormTooltip
                text="Системный параметр для
                идентификации экрана кастомными терминалами,
                для 'админочных' терминалов - оставить пустым"
              />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Позиция"
                onChange={handleInput}
                name="position"
                value={position}
                type="number"
                min={0}
              />
            </Col>
          </Row>
          <Row gutter={[0, 10]} style={{ width: '100%' }}>
            <Col span={24}>
              <span>
                Введите короткое название очереди
              </span>
              <FormTooltip
                text="Системный параметр для
                идентификации экрана кастомными терминалами,
                для 'админочных' терминалов - оставить пустым"
              />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Короткое название"
                onChange={handleInput}
                name="name"
                value={queueName}
              />
            </Col>
          </Row>
          <Row gutter={[0, 10]} style={{ width: '100%' }}>
            <Col span={24}>
              <span>
                Введите полное название очереди
              </span>
              <FormTooltip
                text="Системный параметр для
                идентификации экрана кастомными терминалами,
                для 'админочных' терминалов - оставить пустым"
              />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Полное название"
                onChange={handleInput}
                name="fullname"
                value={fullname}
              />
            </Col>
          </Row>
          <Row gutter={[0, 10]} style={{ width: '100%' }}>
            <Col span={24}>
              <span>
                Выберите рабочую неделю
              </span>
              <FormTooltip
                text="Системный параметр для
                идентификации экрана кастомными терминалами,
                для 'админочных' терминалов - оставить пустым"
              />
            </Col>
            <Row
              gutter={[10, 10]}
              style={{
                maxHeight: '300px',
                overflow: 'auto',
                padding: '5px 0 5px 0',
              }}
            >
              {workWeeks.map((week) => (
                <Col span={24} key={week.id}>
                  <Card
                    hoverable
                    onClick={() => handleCardClick(week)}
                    className={week.id === workWeek.workWeekId ? 'card-selected' : ''}
                  >
                    {week.name}
                  </Card>
                </Col>
              ))}
            </Row>
          </Row>
          <Col span={24}>
            <Row justify="center">
              <Col>
                <Button
                  type="primary"
                  size="large"
                  disabled={isDisabled}
                  htmlType="submit"
                >
                  Добавить
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </Card>
  ) : <div>Загрузка...</div>;
}

export default QueueForm;
