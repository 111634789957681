import React from 'react';
import ModalPortal from '../../../../../../common/ui/Modal/PortalModal';
import { BodySettingModal } from '../../style';
import Buttons from './Buttons';

export default function ModalSettings({
  open, toggle, data, change, activeElem, ticket,
}) {
  const { fv } = data;
  const findActive = (text, param) => (!!(text && text.indexOf(param) >= 0));
  return (
    <ModalPortal size="sm" isOpen={open} onClose={toggle} title="Список доступных значений">
      <BodySettingModal>
        {fv.map((item) => <Buttons key={item.fvid} active={findActive(ticket[activeElem].textInput, `$FIELD${item.fvid}$`)} onClick={change} item={item} />)}
        <hr style={{ margin: '10px 0' }} />
        <Buttons className="item" active={findActive(ticket[activeElem].textInput, '$QL$')} item={{ fvn: 'Полное название', fvid: 'QL' }} onClick={change} />
        <Buttons className="item" active={findActive(ticket[activeElem].textInput, '$QS$')} item={{ fvn: 'Короткое название', fvid: 'QS' }} onClick={change} />
        <Buttons className="item" active={findActive(ticket[activeElem].textInput, '$NUM$')} item={{ fvn: 'Номер талона', fvid: 'NUM' }} onClick={change} />
        <Buttons className="item" active={findActive(ticket[activeElem].textInput, '$DD$')} item={{ fvn: 'Дата', fvid: 'DD' }} onClick={change} />
        <Buttons className="item" active={findActive(ticket[activeElem].textInput, '$WIN$')} item={{ fvn: 'Окна', fvid: 'WIN' }} onClick={change} />
        <Buttons className="item" active={findActive(ticket[activeElem].textInput, '$DT$')} item={{ fvn: 'Время', fvid: 'DT' }} onClick={change} />
        <Buttons className="item" active={findActive(ticket[activeElem].textInput, '$BC$')} item={{ fvn: 'Кол-во в очереди', fvid: 'BC' }} onClick={change} />
      </BodySettingModal>
    </ModalPortal>
  );
}
