export default ({
  title, extraTitle, phone, extraText, clock, time, headerMain, headerSub,
}) => [
  {
    title: 'Заголовок в шапке', value: headerMain.fontSize, paramName: 'fontSize', parentName: 'headerMain',
  },
  {
    title: 'Подзаголовок в шапке', value: headerSub.fontSize, paramName: 'fontSize', parentName: 'headerSub',
  },
  {
    title: 'Заголовок', value: title.fontSize, paramName: 'fontSize', parentName: 'title',
  },
  {
    title: 'Подзаголовок', value: extraTitle.fontSize, paramName: 'fontSize', parentName: 'extraTitle',
  },
  {
    title: 'Телефон', value: phone.fontSize, paramName: 'fontSize', parentName: 'phone',
  },
  {
    title: 'Дополнительный<br/>текст', value: extraText.fontSize, paramName: 'fontSize', parentName: 'extraText',
  },
  {
    title: 'Дата', value: clock.fontSize, paramName: 'fontSize', parentName: 'clock',
  },
  {
    title: 'Время', value: time.fontSize, paramName: 'fontSize', parentName: 'time',
  },
];
