import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { OrderElementBox } from '../style';

function OrderElement({ task, index }) {
  return (
    <Draggable draggableId={task.fild} index={index} key={task.id}>
      {(provided, snapshot) => (
        <OrderElementBox
          key={index}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          {task.FieldDescription}
        </OrderElementBox>
      )}

    </Draggable>
  );
}

export default OrderElement;
