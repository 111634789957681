import React from 'react';
import ModalButtons from '../../../../common/ui/Modal/ModalButtons';
import Modal from '../../../../common/ui/Modal/Modal';
import { Input } from '../../../../common/ui/Common';

function OfficeParams({
  isValid, save, setOpen, open, office = [], setOffice,
}) {
  const change = (value, name) => {
    const arr = office.map((el) => {
      if (el.param === name) return { ...el, value };
      return el;
    });
    setOffice(arr);
  };

  return (
    <Modal notCancel title="Настройка офиса" isOpen={open} toggleOpen={setOpen}>
      <div className="officeParamsBox">
        {office.map((el) => (
          <div key={el.param}>
            <p>
              {/* eslint-disable-next-line no-nested-ternary */}
              {el.param === 'officeName'
                ? 'Наименование офиса'
                : el.param === 'officeDiscription'
                  ? 'Описание офиса'
                  : 'Адрес офиса'}
            </p>
            <Input
              onChange={(e) => change(e.target.value, el.param)}
              type="text"
              value={el.value}
            />
          </div>
        ))}
      </div>
      <ModalButtons
        isWrite
        notCancel
        isValid={isValid}
        update={false}
        addFunc={save}
        updateFunc={save}
        reset={setOpen}
      />
    </Modal>
  );
}

export default OfficeParams;
