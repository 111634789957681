export default ({
  input, checkbox,
}) => [
  {
    title: 'Ширина</br>поля ввода', value: input.width, paramName: 'width', parentName: 'input',
  },
  {
    title: 'Высота</br>поля ввода', value: input.height, paramName: 'height', parentName: 'input',
  },
  {
    title: 'Угол скругления</br>поля ввода', value: input.borderRadius, paramName: 'borderRadius', parentName: 'input',
  },
  {
    title: 'Толщина обводки</br>поля ввода', value: input.borderWidth, paramName: 'borderWidth', parentName: 'input',
  },
  {
    title: 'Ширина</br>окна выбора', value: checkbox.width, paramName: 'width', parentName: 'checkbox',
  },
  {
    title: 'Высота</br>окна выбора', value: checkbox.height, paramName: 'height', parentName: 'checkbox',
  },
  {
    title: 'Угол скругления</br>окна выбора', value: checkbox.borderRadius, paramName: 'borderRadius', parentName: 'checkbox',
  },
  {
    title: 'Толщина обводки</br>окна выбора', value: checkbox.borderWidth, paramName: 'borderWidth', parentName: 'checkbox',
  },
];
