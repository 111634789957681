import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import ScreenForm from './ScreenForm';

function Constructor() {
  const [isCardVisible, setIsCardVisible] = useState(false);
  return (
    <Row gutter={[0, 20]} justify="center">
      <Col span={24}>
        <Button
          type="primary"
          size="large"
          onClick={() => setIsCardVisible(!isCardVisible)}
        >
          {isCardVisible ? 'Отменить' : 'Добавить экран'}
        </Button>
      </Col>
      {
        isCardVisible && (
        <Col span={10}>
          <ScreenForm type="add" />
        </Col>
        )
      }
    </Row>
  );
}

export default Constructor;
