import Request from '../../../../../../../../../../logic/utils/request';
import seachByKey from '../../../../../utils/seachByKey';

export default async function SqlQuery(time, endTime) {
  const barData = {
    count_close: [],
    count_neyavka: [],
    count_del: [],
    count_crate: [],
  };

  const daysInWeek = [
    {
      days: 'mon', daycount: 0, count_close: 0, count_neyavka: 0, count_del: 0, count_crate: 0,
    },
    {
      days: 'tue', daycount: 0, count_close: 0, count_neyavka: 0, count_del: 0, count_crate: 0,
    },
    {
      days: 'wed', daycount: 0, count_close: 0, count_neyavka: 0, count_del: 0, count_crate: 0,
    },
    {
      days: 'thu', daycount: 0, count_close: 0, count_neyavka: 0, count_del: 0, count_crate: 0,
    },
    {
      days: 'fri', daycount: 0, count_close: 0, count_neyavka: 0, count_del: 0, count_crate: 0,
    },
    {
      days: 'sat', daycount: 0, count_close: 0, count_neyavka: 0, count_del: 0, count_crate: 0,
    },
    {
      days: 'sun', daycount: 0, count_close: 0, count_neyavka: 0, count_del: 0, count_crate: 0,
    },
  ];

  const query = `select 
  days,
  count(distinct(q.unic_data)) as daycount,
  case when sum(count_close) = 0 then 0 else (sum(count_close) / count(distinct(q.unic_data))) end as count_close,
  case when sum(count_neyavka) = 0 then 0 else (sum(count_neyavka) / count(distinct(q.unic_data))) end as count_neyavka,
  case when sum(count_del) = 0 then 0 else (sum(count_del) / count(distinct(q.unic_data))) end as count_del,
  case when sum(count_crate) = 0 then 0 else (sum(count_crate) / count(distinct(q.unic_data))) end as count_crate
  from
  (select
    to_char(modified_on, 'dy') as days,
    to_char(modified_on, 'DD.MM.YYYY') as unic_data,
    case when status = 3 then 1 else 0 end as count_close,
    case when status = 4 then 1 else 0 end as count_neyavka,
    case when status = 9 then 1 else 0 end as count_del,
    case when status = 0 then 1 else 0 end as count_crate
    from
    eq.ttickethistory
    where
    modified_on::timestamp >= '${time}'
    and modified_on::timestamp <= '${endTime}'
    and status in (0,3,4,9))q group by q.days`;

  const res = await Request('/server/api/genreportfromquery/', 'get', {
    querySql: `${query}`,
  });

  const { data } = res;
  const { columnNames, data: RespData } = data;
  const myData = [];

  RespData.forEach((dv) => {
    const rElem = {};
    dv.forEach((v, idx) => {
      rElem[columnNames[idx]] = v;
    });
    myData.push(rElem);
  });

  daysInWeek.forEach((el) => {
    const curDay = seachByKey(myData, 'days', el.days);
    const realDay = (curDay) || el;
    Object.keys(realDay).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(barData, key)) {
        barData[key].push(Number(realDay[key]));
      }
    });
  });

  return barData;
}
