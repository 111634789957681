export default ({
  title, extraTitle, phone, extraText, clock, headerMain, headerSub,
}) => [
  {
    title: 'Заголовок в шапке', value: headerMain.color, paramName: 'color', parentName: 'headerMain',
  },
  {
    title: 'Подзаголовок в шапке', value: headerSub.color, paramName: 'color', parentName: 'headerSub',
  },
  {
    title: 'Заголовок', value: title.color, paramName: 'color', parentName: 'title',
  },
  {
    title: 'Подзаголовок', value: extraTitle.color, paramName: 'color', parentName: 'extraTitle',
  },
  {
    title: 'Телефон', value: phone.color, paramName: 'color', parentName: 'phone',
  },
  {
    title: 'Дополнительный </br>текст', value: extraText.color, paramName: 'color', parentName: 'extraText',
  },
  {
    title: 'Дата, </br>время', value: clock.color, paramName: 'color', parentName: 'clock',
  },
];
