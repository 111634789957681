import React, { useContext } from 'react';

// components
import { Collapse } from 'antd'; // DOCS: https://ant.design/components/collapse/
import Colors from '../../../../../Components/Colors';
import FontSize from '../../../../../Components/FontSize';
import Height from '../../../../../Components/Height';
import Margin from '../../../../../Components/Margin';
import PositionOption from '../../../../../Components/PositionOption';
import Uppercase from '../../../../../Components/modules/uppercase';
import BoxShadow from '../../../../../Components/modules/BoxShadow';
import FontFamily from '../../../../../Components/modules/font';

// arr

import ArrColor from './components/color';
import ArrFontSize from './components/fontSize';
import ArrHeight from './components/height';
import ArrWidth from './components/width';
import ArrMargin from './components/margin';
import ArrSize from './components/size';
import ArrPosition from './components/position';
import ArrAlign from './components/align';
import Align from '../../../../../Components/modules/align';
import terminalCtx from '../../../../../../../../../../pages/public/terminal/context';

function Button() {
  const { fonts, dispatch, data } = useContext(terminalCtx);
  const { button, general: { twoScreen } } = data;
  const colors = ArrColor({ button });
  const fontSize = ArrFontSize({ button });
  const height = ArrHeight({ button });
  const width = ArrWidth({ button });
  const margin = ArrMargin({ button });
  const size = ArrSize({ button });
  const arrPosition = ArrPosition({ button });
  const arrAlign = ArrAlign({ button });
  const { Panel } = Collapse;

  if (!twoScreen) {
    size.splice(size.length - 1, 1);
    colors.splice(colors.length - 1, 1);
    margin.splice(margin.length - 1, 1);
  }
  return (
    <Collapse className="customize-Collapse" ghost>
      <Panel className="customize-Collapse-Header-No-Top-Padding" header="Параметры текста" key="1">
        <Collapse className="customize-Collapse" ghost>
          <Panel header="Размер" key="1">
            <FontSize width="auto" arr={fontSize} dispatch={dispatch} />
            <Uppercase dispatch={dispatch} nameParams="textTransform" title="Текст заглавными буквами" element="button" value={button.textTransform} />
          </Panel>

          <Panel header="Выравнивание" key="2">
            {arrAlign.map((el) => (
              <Align
                element="button"
                value={el.value}
                dispatch={dispatch}
                title={el.title}
                paramName={el.paramName}
              />
            ))}
          </Panel>

          <FontFamily options={fonts} title="Шрифт" element="button" value={String(button.fontFamily)} dispatch={dispatch} />
        </Collapse>
      </Panel>

      <Panel className="customize-Collapse-Header-No-Top-Padding" header="Параметры элементов" key="2">
        <Collapse className="customize-Collapse" ghost>
          <Panel header="Высота кнопок" key="1">
            <Height width="auto" arr={height} dispatch={dispatch} />
          </Panel>

          <Panel header="Ширина кнопок" key="2">
            <Height width="auto" arr={width} dispatch={dispatch} />
          </Panel>

          <Panel header="Отступы" key="3">
            <Margin width="auto" arr={margin} dispatch={dispatch} />
          </Panel>

          <Panel header="Расположение" key="4">
            <PositionOption content arr={arrPosition} dispatch={dispatch} />
          </Panel>

          <Panel header="Другое" key="5">
            <Height widthInput="160px" arr={size} dispatch={dispatch}>
              <BoxShadow dispatch={dispatch} value={button.boxShadow} title="Тень" element="button" />
            </Height>
          </Panel>
        </Collapse>
      </Panel>

      <Panel className="customize-Collapse-Colors" header="Цвет" key="3">
        <Colors width="auto" arr={colors} dispatch={dispatch} />
      </Panel>
    </Collapse>
  );
}

export default Button;
