import React, { useState, useEffect } from 'react';
import request from '../../../../../logic/utils/request';
import { Title, PreloaderBox } from '../../../../../common/ui/Common';
import WorkPlacesTable from './WorkPlacesTable';
import { useToggle } from '../../../../../logic/hooks/useToggle';
import NewWorkPlace from './NewWorkPlace/NewWorkPlace';

function WorkPlaces({ preloader, showError, write }) {
  const initialData = {
    connectedQueues: [],
    connectedZones: [],
    description: '',
    dictionary: { filtersA: [] },
    disabled: false,
    name: '',
    placename: '',
    soundtext: '',
  };
  const [placeList, setPlaceList] = useState(null);
  const [fieldList, setFieldList] = useState(null);
  const [dictList, setDictList] = useState(null);
  const [placeData, setPlaceData] = useState(initialData);
  const [placeUpdate, setPlaceUpdate] = useState(false);
  const [zones, setZones] = useState([]);
  const [isOpen, toggleOpen] = useToggle(false);

  const fetchPlaces = async () => {
    const res = await request('/server/api/wplace/', 'get');
    setPlaceList(res.data.data);
  };

  const fetchData = async () => {
    const zoneListReq = await request('/server/api/zone/', 'get');
    const dictListReq = await request('/server/api/dictionary/', 'get');
    const fieldListReq = await request('/server/api/ticketsfields/', 'get');
    const placesListReq = await request('/server/api/wplace/', 'get');
    setDictList([...dictListReq.data.data.filter((el) => el.type !== 'text')]);
    setFieldList([...fieldListReq.data.data.filter((el) => el.type !== 'text')]);
    setZones([...zoneListReq.data.data]);
    setPlaceList(placesListReq.data.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Title>Рабочие места</Title>
      <NewWorkPlace
        initialData={initialData}
        preloader={preloader}
        toggleOpen={toggleOpen}
        isOpen={isOpen}
        fetchPlaces={fetchPlaces}
        placeData={placeData}
        setPlaceData={setPlaceData}
        setPlaceUpdate={setPlaceUpdate}
        placeUpdate={placeUpdate}
        zones={zones}
        showError={showError}
      />
      <PreloaderBox>
        <WorkPlacesTable
          placeList={placeList}
          fetchPlaces={fetchPlaces}
          preloader={preloader}
          setPlaceUpdate={setPlaceUpdate}
          fieldList={fieldList}
          dictList={dictList}
          setPlaceData={setPlaceData}
          toggleOpen={toggleOpen}
          write={write}
          zones={zones}
          showError={showError}
        />
      </PreloaderBox>
    </>
  );
}
export default WorkPlaces;
