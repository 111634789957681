import React, { useContext } from 'react';
import { Collapse } from 'antd'; // DOCS: https://ant.design/components/collapse/
import BoardCtx from '../../../../../../../../../pages/public/board/utils/BoardCtx';
import fontSize from './fontSize';
import colors from './color';
import fontFamily from './fontFamily';
import FontFamily from '../../../../Components/FontFamily';
import FontSize from '../../../../Components/FontSize';
import TextComponent from '../../../../Components/modules/text';
import Height from '../../../../Components/modules/height';
import Colors from '../../../../Components/Colors';
import MarginComponent from '../../../../Components/modules/Margin';
import Order from '../../../../Components/modules/order';
import PositionContent from '../../../../Components/modules/positionContent';
import Align from '../../../../Components/modules/align';

function Time() {
  const {
    dispatch, layout, fonts, text, dispatchText,
  } = useContext(BoardCtx);
  const { time } = layout;
  const { Panel } = Collapse;

  const onRefresh = (param) => dispatch({
    type: 'params', name: 'time', param, value: 'auto',
  });

  const arrFontSize = fontSize({ time });
  const arrColors = colors({ time });
  const arrFont = fontFamily({ time });
  return (
    <Collapse className="customize-Collapse" ghost>
      <Panel className="customize-Collapse-Header-No-Top-Padding" header="Текст" key="1">
        <TextComponent title="Окно даты" element="text" param="time1" value={text.time1} dispatch={dispatchText} />
      </Panel>

      <Panel className="customize-Collapse-Header-No-Top-Padding" header="Параметры текста" key="2">
        <Collapse className="customize-Collapse customize-Collapse-Margin-Bottom" ghost>
          <Panel header="Размер текста" key="1">
            <FontSize arr={arrFontSize} dispatch={dispatch} />
          </Panel>

          <Panel header="Шрифт" key="2">
            <FontFamily options={fonts} arr={arrFont} dispatch={dispatch} />
          </Panel>
        </Collapse>

        <Height clear refresh={() => onRefresh('bold')} title="Толщина<br/>шрифта даты" width="160px" element="time" nameParams="bold" value={time.bold} dispatch={dispatch} />
        <Align
          element="time"
          value={time.alignItems}
          dispatch={dispatch}
          title="Выравнивание времени"
          paramName="alignItems"
        />
        <Align
          element="time"
          value={time.textAlign}
          dispatch={dispatch}
          title="Выравнивание текста"
          paramName="textAlign"
        />
      </Panel>

      <Panel header="Параметры элементов" key="3">
        <Height clear refresh={() => onRefresh('height1')} title="Высота времени" width="160px" element="time" nameParams="height1" value={time.height1} dispatch={dispatch} />
        <Height clear refresh={() => onRefresh('height2')} title="Высота<br/>контейнера даты" width="160px" element="time" nameParams="height2" value={time.height2} dispatch={dispatch} />
        <Height title="Толщина рамки" width="160px" element="time" nameParams="borderSize1" value={time.borderSize1} dispatch={dispatch} />
        <Order element="time" dispatch={dispatch} value={time.order} title="Позиция" />
        <PositionContent element="time" dispatch={dispatch} paramName="flexDirection" value={time.flexDirection} title="Ориентация" />
        <MarginComponent data={time} dispatch={dispatch} element="time" name="margin" title="Отступы" />
      </Panel>

      <Panel className="customize-Collapse-Colors" header="Цвет" key="4">
        <Colors arr={arrColors} dispatch={dispatch} />
      </Panel>
    </Collapse>
  );
}

export default Time;
