import React from 'react';

const onRefresh = () => {};

function Height({
  value,
  element,
  dispatch,
  title,
  nameParams,
  widthInput,
  refresh = onRefresh,
  clear = false,
}) {
  const big = nameParams.match(/\./);

  const onChange = (e) => {
    if (!big) {
      dispatch({
        type: 'params',
        name: element,
        param: nameParams,
        value: Number(e.target.value),
      });
    } else {
      const arr = nameParams.split('.');
      dispatch({
        type: 'bigParams',
        parentName: element,
        name: arr[0],
        param: arr[1],
        value: Number(e.target.value),
      });
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="box__color">
      <span
        className="settings__styles__el__label"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {clear && <div className="refresh" onClick={refresh}>Сбросить</div>}
      <input
        className="input"
        size="16px"
        width={widthInput || '100px'}
        onChange={onChange}
        value={value}
      />
    </label>
  );
}

export default Height;
