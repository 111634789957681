import React, { useContext } from 'react';
import {
  Input, Select, Space, Typography,
} from 'antd';
import BoardCtx from '../../../../../../../pages/public/board/utils/BoardCtx';
import UppercaseComponent from '../../Components/Uppercase';

function CommonSettings() {
  const { layout, dispatch, fonts } = useContext(BoardCtx);
  const optionsColumns = [
    { id: 1, name: 1 },
    { id: 2, name: 2 },
    { id: 3, name: 3 },
    { id: 4, name: 4 },
  ];

  const arrCheckbox = [
    { title: 'Часы в шапке', value: layout.clock, paramName: 'clock' },
    { title: 'Часы в шапке через точку', value: layout.clockDot, paramName: 'clockDot' },
    { title: 'Часы в бег. строке', value: layout.clockString, paramName: 'clockString' },
    { title: 'Круглые углы', value: layout.borderRadius, paramName: 'borderRadius' },
    { title: 'По вертикали', value: layout.vertical, paramName: 'vertical' },
    { title: 'Инвертировать колонки', value: layout.invert, paramName: 'invert' },
    { title: 'Окно вызова', value: layout.window, paramName: 'window' },
    { title: 'Видео', value: layout.vid, paramName: 'vid' },
    { title: 'Новости', value: layout.tidings, paramName: 'tidings' },
    { title: 'Окно даты', value: layout.date, paramName: 'date' },
  ];

  return (
    <Space direction="vertical" size="small">
      <div>
        <Typography.Text type="secondary">Шрифт</Typography.Text>
        <Select
          value={layout.font}
          style={{ width: '100%' }}
          onChange={(value) => dispatch({ type: 'params', param: 'font', value })}
          filterOption={
              (input, opt) => (opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
          }
        >
          {fonts.map((font) => (<Select.Option value={font.id}>{font.name}</Select.Option>))}
        </Select>
      </div>
      <div>
        <Typography.Text type="secondary">Количество колонок</Typography.Text>
        <Select
          value={layout.cols}
          style={{ width: '100%' }}
          onChange={(value) => dispatch({ type: 'params', param: 'cols', value })}
          filterOption={
              (input, opt) => (opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
          }
        >
          {optionsColumns.map((font) => (
            <Select.Option value={font.id}>{font.name}</Select.Option>
          ))}
        </Select>
      </div>
      <div>
        <Typography.Text type="secondary">Ширина табло</Typography.Text>
        <Input
          onChange={(e) => dispatch({ type: 'params', param: 'width', value: e.target.value })}
          value={layout.width}
        />
      </div>
      <div>
        <Typography.Text type="secondary">Высота табло</Typography.Text>
        <Input
          onChange={(e) => dispatch({ type: 'params', param: 'height', value: e.target.value })}
          value={layout.height}
        />
      </div>
      <div>
        <Typography.Text type="secondary">Кол-во строк у колонки</Typography.Text>
        <Input
          onChange={(e) => dispatch({ type: 'params', param: 'lines', value: +e.target.value > 15 ? 15 : e.target.value })}
          value={layout.lines}
        />
      </div>
      <UppercaseComponent arr={arrCheckbox} noText dispatch={dispatch} />
    </Space>
  );
}

export default CommonSettings;
