import React, { useState } from 'react';
import axios from 'axios';
import { Button, Table } from 'antd';
import { EmptyList } from '../../../../common/ui/Common';
import Config from './TableConfig';
import Preloader from '../../../../pages/private/components/preloader/Preloader';
import DeleteWarning from '../../../../common/ui/Modal/DeleteWarning';
import { useToggle } from '../../../../logic/hooks/useToggle';
import { useSearchPropsFactory } from '../../../../pages/private/utils/useSearch';

function ReserveTable({
  reserveList, fetchData, preloader,
}) {
  const [copyName, setCopyName] = useState(false);
  const [showDelWarn, setShowDelWarn] = useToggle(false);

  const handleTableIcon = (e, row) => {
    const { dataset } = e.target;
    const {
      name, path,
    } = row;
    if (dataset.name === 'del') {
      setCopyName(name);
      // setCopyPath(path);
      setShowDelWarn();
    } else if (dataset.name === 'download') {
      const link = document.createElement('a');
      link.href = path;
      link.target = '_blank';
      link.download = 'backup.sql';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const createCopy = async () => {
    preloader();
    try {
      await axios({
        method: 'post',
        url: '/api/createbackup',
        data: {
          token: window.localStorage.getItem('token'),
        },
        json: true,
      });
      preloader();
      fetchData();
    } catch (e) {
      preloader();
    }
  };

  const Setting = Config(useSearchPropsFactory());
  const { columns } = Setting;

  const viewReserve = reserveList.length
    ? (
      <Table
        keys="name"
        dataSource={reserveList}
        onRow={(row) => ({
          onClick: (e) => { handleTableIcon(e, row); },
        })}
        columns={columns}
      />
    ) : <EmptyList>Резервных копий нет</EmptyList>;

  return (
    <>
      <Button type="primary" onClick={createCopy}>Создать копию</Button>
      {!reserveList ? <Preloader isOpen small /> : viewReserve}
      <DeleteWarning
        confirmDel={() => {}}
        name={copyName}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </>
  );
}

export default React.memo(ReserveTable);
