import moment from 'moment';
import iconFormatter from '../../../../pages/private/utils/iconFormatter';

export default function TableConfig(searchPropsFactory) {
  return {
    columns: [
      {
        dataIndex: 'name',
        title: 'Название',
        ...searchPropsFactory('name', 'Названию'),
      },
      {
        dataIndex: 'path',
        title: 'Путь',
        ...searchPropsFactory('path', 'Пути'),
      },
      {
        dataIndex: 'date',
        title: 'Дата копии',
        ...searchPropsFactory('date', 'Дате копии'),
        render: (_, rec) => (
          <p>{moment(rec.date).format('DD.MM.YYYY HH:mm')}</p>
        ),
      },
      {
        dataIndex: 'Settings',
        title: ' ',
        render: () => iconFormatter({ write: true, update: false, download: true }),
        width: 100,
      },
    ],
  };
}
