import React from 'react';
import {
  FaTrash,
} from 'react-icons/fa';
import {
  SettingLogo,
  Label,
} from '../../../../../../styles/BoardStyle';

const uploadLogo = (event, dispatch) => {
  if (event === 'delete') {
    dispatch({
      type: 'uploadImage2',
      payload: '',
    });
    return;
  }

  const file = event.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (() => (e) => {
    const image = e.target;
    console.log(image);
    if (image.error === null) {
      dispatch({
        type: 'uploadImage2',
        payload: image.result,
      });
    } else {
      // eslint-disable-next-line no-console
      console.log('error upload logo');
    }
  })(file);
};

const bgImage = ({ dispatch, element, value }) => (
  <SettingLogo>
    <div>
      <Label htmlFor={element}>
        Загрузить
      </Label>
      {value ? (
        <Label
          delete
          htmlFor="deleteLogo"
          onClick={() => uploadLogo('delete', dispatch)}
          style={{
            background: 'red',
            border: 0,
            cursor: 'pointer',
          }}
        >
          <FaTrash />
        </Label>
      ) : null}
      <input
        type="file"
        accept="image/*"
        id={element}
        className="devices__btn btn-primary"
        onChange={(e) => uploadLogo(e, dispatch)}
      />
    </div>
  </SettingLogo>
);

export default bgImage;
