import styled from 'styled-components';
import { Button } from '../../common/ui/Common';
import colors from '../../common/ui/theme/Colors';

export const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

export const ProgressBarInner = styled.div`
  width: 300px;
  height: 20px;
`;

export const ProgressBarLine = styled.div`
  background: linear-gradient(to right, ${colors.orng} 0%,${colors.accent} 100%);
  height: 100%;
  text-align: center;
  color: ${colors.white}
  width: ${(props) => (props.progressBar && `${props.progressBar}%`)}
`;

export const ProgressCloseButton = styled.button`
  border: 0;
  background: transparent;
  height: 10px;
  line-height: 0;
  font-size: 22px;
  cursor: pointer;
  &:before {
    content: '\\d7'
  };
`;

export const VideosBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 15px 0;
 
`;

export const VideoList = styled.div`

display: flex;
flex: 1;
flex-direction: column;

`;

export const BoardVideoBox = styled.div`
  max-width: 360px;
  width: 100%;
  margin-right: 50px;
  margin-bottom: 30px;
  video, .shadow {
    background: #1a1a1a;
    width: 360px;
    height: 200px;
  }
`;

export const VideoBoxTitle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  min-height: 40px;
  p {
    flex: 1;
    margin: 0;
  }
  svg {
    min-width: 20px;
  }
  font-weight: bold;
`;

export const VideoRemoveButton = styled(Button)`
  padding: 0;
  height: 20px;
  background: transparent;
  width: 20px;
  margin: 0 0 0 5px;
  transition: 0.1s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 20px;
    fill: ${(p) => (p.show ? p.theme.accent : p.theme.disable)};
  }
`;
