import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BoxButtonContent, ButtonContent } from '../../utils/style';
import { changeDictionary, setTicket } from '../../../store/actions';
import changeStyle from '../common/changeStyle';

function Buttons(
  props,
) {
  const { buttons = [], style, isStart } = props;
  const { headUrl, id } = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const renderButtons = () => buttons.map((item) => {
    const styleButton = { ...changeStyle({ style, item }), ...style.bottom };
    // const { value } = item.params;
    styleButton.textShadow = 'none';
    const { iconPosition, contentPosition } = styleButton;
    const fontSizeExtra = styleButton.fontSize / 2;
    const { iconTop } = styleButton;
    const text = item.name.replace(/\\\\/g, '</br>');
    const extraText = item.extraText ? item.extraText.replace(/\\\\/g, '</br>') : undefined;

    const onClick = () => {
      const qId = item.params.value;
      if (isStart) {
        navigate(`${headUrl}/${id}/print`);
      } else if (item.params.value !== 0) {
        dispatch(setTicket({ name: 'q_id', value: qId }));
        navigate(`../${item.path}`);
      } else {
        dispatch(changeDictionary({ qId, to: item.path, fv: [item.params.value] }));
        navigate(`../${item.path}`);
      }
    };

    return (
      <div
        onDragStart={() => false}
        onClick={onClick}
        style={styleButton}
        key={item.id}
      >
        <ButtonContent
          heightIcon={styleButton.heightIcon}
          img={item.icon}
          iconPosition={iconPosition}
          contentPosition={contentPosition}
          iconTop={iconTop}
        >
          {item.icon && (
          <img
            style={{ ...styleButton.icon }}
            src={item.icon && item.icon.replace(/url\(|"|\)/g, '')}
            alt=""
          />
          )}
          <BoxButtonContent img extraText={item.extraText}>
            <p
              style={{ ...styleButton.text, textAlign: styleButton.textAlign }}
              dangerouslySetInnerHTML={{ __html: text }}
            />
            {item.extraText
              && (
              <span
                style={{
                  fontSize: fontSizeExtra,
                  textAlign: styleButton.textAlign,
                }}
                dangerouslySetInnerHTML={{ __html: extraText }}
              />
              )}
          </BoxButtonContent>
        </ButtonContent>
      </div>
    );
  });

  const cStyle = {
    display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
  };

  return (
    <div style={cStyle}>
      {renderButtons()}
    </div>
  );
}

export default Buttons;
