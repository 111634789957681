import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { ModalWithTransitionStyles } from './PortalModal';

function Modal({
  isOpen, toggleOpen, children, title, width, notCancel,
}) {
  return (
    <CSSTransition
      in={isOpen}
      className="modal-transition"
      classNames="modal-transition"
      unmountOnExit
      timeout={300}
    >
      <ModalWithTransitionStyles
        title={title}
        open={isOpen}
        onClose={notCancel ? () => {} : toggleOpen}
        onSave={toggleOpen}
        width={width}
      >
        {children}
      </ModalWithTransitionStyles>
    </CSSTransition>
  );
}
export default Modal;
