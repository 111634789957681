export default (reportType) => {
  const reportList = {
    generalReport: 'Сводный',
    ticketReport: 'По талонам',
    operatorReport: 'По операторам',
    queueReport: 'По очередям',
    workPlaceReport: 'По рабочим местам',
    groupReport: 'Групповой',
    clockwiseReport: 'Почасовой',
    dynamicsServiceTime: 'Динамика',
    operatorLogi: 'Логи операторов',
    surveyReport: 'По дополнительным полям',
    preEntryReport: 'Предварительная запись',
    sourceReport: 'По источнику',
    solvedQuestionReport: 'По решенным вопросам',
    timeСonsumption: 'Расход рабочего времени',
    ticketClosePieChart: 'Распределение талонов по очередям',
  };

  return reportList[reportType];
};
