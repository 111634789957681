import React from 'react';
import { Timeline } from 'antd';
import {
  UserAddOutlined,
  DeleteOutlined,
  LoadingOutlined,
  UndoOutlined,
  BranchesOutlined,
  DislikeOutlined,
  CheckSquareOutlined,
  EditOutlined,
  SoundOutlined,
  SisternodeOutlined,
} from '@ant-design/icons';

function DescriptionItem({ title, content, type }) {
  return (

    <div style={{ display: 'flex', flexDirection: type }}>
      <p style={{ fontWeight: 'bold', margin: type === 'row' ? '0 8px 0 0' : '0', minWidth: '100px' }}>
        {title}
        :
      </p>
      {content}
    </div>

  );
}

function TicketHistory(props) {
  const { data } = props;
  console.log('data === ', data);

  const statusType = {
    0: 'Создан и находится в изначальной очереди',
    1: 'Вызван на обработку',
    2: 'В процессе обработки',
    3: 'Закрыт (талон был обработан)',
    4: 'Закрыт по неявке',
    5: 'Переведён в новую очередь',
    6: 'Ожидание решения',
    7: 'Возвращён в очередь',
    8: 'Ручная обработка диспетчером',
    9: 'Удален из-за наступления нового дня',
    10: 'Смена услуги',
    11: 'Направлен в конкретное рабочее место',
    12: 'Начато решение вопроса',
    13: 'Завершено решение вопроса.',
  };

  const getDot = (status) => {
    switch (status) {
      case '0':
        return <UserAddOutlined style={{ fontSize: '24px' }} />;
      case '1':
        return <SoundOutlined style={{ fontSize: '24px' }} />;
      case '2':
        return <EditOutlined style={{ fontSize: '24px' }} />;
      case '3':
        return <CheckSquareOutlined style={{ fontSize: '24px' }} />;
      case '4':
        return <DislikeOutlined style={{ fontSize: '24px' }} />;
      case '5':
        return <BranchesOutlined style={{ fontSize: '24px' }} />;
      case '6':
        return <LoadingOutlined style={{ fontSize: '24px' }} />;
      case '7':
        return <UndoOutlined style={{ fontSize: '24px' }} />;
      case '9':
        return <DeleteOutlined style={{ fontSize: '24px' }} />;
      case '10':
        return <SisternodeOutlined style={{ fontSize: '24px' }} />;
      default:
        return null;
    }
  };

  const getColor = (status) => {
    switch (status) {
      case '2':
      case '4':
        return 'grey';
      case '1':
      case '5':
      case '6':
      case '7':
      case '10':
        return 'blue';
      case '0':
      case '3':
        return 'green';
      case '9':
        return 'red';
      default:
        return 'grey';
    }
  };

  const renderTimeLineItems = () => data.map((el, index) => {
    const {
      status,
      close_data: closeData,
      operator_fio: operatorFio,
      queue_name: queueName,
      name,
      questiontext,
    } = el;
    return (
      <Timeline.Item dot={getDot(status)} label={`${index + 1}. ${statusType[status]}`} color={getColor(status)}>
        <DescriptionItem title="Дата и время" content={closeData} type="row" />
        {name && <DescriptionItem title="Рабочее место" content={name} type="row" />}
        {queueName && <DescriptionItem title="Очередь" content={queueName} type="row" /> }
        {(operatorFio && operatorFio !== 'Система') && <DescriptionItem title="Оператор" content={operatorFio} type="row" /> }
        {questiontext && <DescriptionItem title="Решенный вопрос" content={questiontext} type="column" /> }
      </Timeline.Item>
    );
  });

  return (
    <Timeline mode="left">{renderTimeLineItems()}</Timeline>
  );
}

export default TicketHistory;
