import React from 'react';
import { DivWithoutStyles } from '../../../../../components/report/ReportTableColumns';

export function HistoryTableStatusTemplate(cell) {
  let status = '';
  if (cell === '0') {
    status = 'Талон создан';
  } else if (cell === '1') {
    status = 'Вызван на обработку';
  } else if (cell === '2') {
    status = 'Процесс обработки';
  } else if (cell === '3') {
    status = 'Закрыт';
  } else if (cell === '4') {
    status = 'Закрыт по неявке';
  } else if (cell === '5') {
    status = 'Переведен в другую очередь';
  } else if (cell === '6') {
    status = 'Решение отложено';
  } else if (cell === '7') {
    status = 'Возвращен в очередь';
  } else if (cell === '8') {
    status = 'Ручная обработка диспетчером';
  } else if (cell === '9') {
    status = 'Удален системой';
  } else if (cell === '10') {
    status = 'Смена очереди';
  } else if (cell === '11') {
    status = 'Переведен на рабочее место';
  } else if (cell === '12') {
    status = 'Начато решение вопроса';
  } else if (cell === '13') {
    status = 'Завершено решение вопроса';
  }

  return (
    <DivWithoutStyles>
      {status}
    </DivWithoutStyles>
  );
}

export default null;
