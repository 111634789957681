import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography } from 'antd';
import Icon from '@ant-design/icons';
import Request from '../../../../logic/utils/request';
import ConstructorSettings from './constructor/ConstructorSettings';
import TicketDefault from './constructor/components/ticket';

function TicketConstructor() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [name, setName] = useState('');
  const [connect, setConnect] = useState(true);
  const [ticket, setTicket] = useState(TicketDefault);

  useEffect(() => {
    Request('/server/api/tickettemplate/', 'get', {})
      .then((res) => {
        res.data.data.forEach((item) => {
          if (item.id === +id) {
            setName(item.name);
            setConnect(item.connected_queues);
          }
        });
      });

    Request(`/server/api/tickettemplate/${id}`, 'get', {})
      .then((res) => {
        const { data: { data: ticketData } } = res;
        const { config } = ticketData;
        if (config) setTicket(JSON.parse(config));
      });
    Request('/server/public/placeholders/', 'get', {})
      .then((res) => {
        const { data: psData } = res;
        const resData = {};
        resData.win = '1-3, 5-10';
        resData.bc = '199';
        resData.dd = moment().format('DD.MM.YYYY');
        resData.num = moment().format('OT 1234');
        resData.tm = moment().format('HH:mm:ss');
        resData.ql = psData.ql;
        resData.qs = psData.qs;
        resData.fv = psData.fv;
        setData(resData);
      });
  }, [id]);

  return (
    <div>
      <Button type="primary" onClick={() => navigate(-1)} shape="circle" icon={<Icon component={FaArrowLeft} />} />
      <Typography.Title style={{ textAlign: 'center', color: '#25547A' }}>
        Конструктор талонов
      </Typography.Title>
      {data && (
      <ConstructorSettings
        id={id}
        connected_queues={connect}
        name={name}
        setName={setName}
        ticket={ticket}
        data={data}
        setTicket={setTicket}
        idSat={id}
      />
      )}
    </div>
  );
}

export default TicketConstructor;
