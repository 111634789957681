import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import 'react-toastify/dist/ReactToastify.css';
import {
  MainConent, LayoutBox,
} from '../../common/ui/Common';
import Header from './layout/Header';
import SideNav from './layout/SideNav';
import Routes from './router-function/Routes';
import { LayoutContainer } from './layout/style';
import { updateMonitoringStore } from '../../logic/store/pullstate/monitoringStore';
import { requestNotificationPermission, showNotification } from '../../utils/notificationService';
import getToken from '../../logic/utils/getToken';

function Layout() {
  const [showNotifications, setShowNotifications] = useState(localStorage.getItem('showNotifications') === 'true');

  useEffect(() => {
    const token = getToken();

    // Запрашиваем нотификации только если пользователь авторизован
    if (!token) {
      setShowNotifications(false);
      localStorage.setItem('showNotifications', 'false');
    } else {
      requestNotificationPermission();
    }

    const fetchData = async () => {
      try {
        const response = await fetch('/api/proxy-monitoring');
        const data = await response.json();
        updateMonitoringStore({ tickets: [...data] });
        const alerts = {
          serviceTimeExceeded: 0,
          waitTimeExceeded: 0,
        };

        if (data?.length) {
          data.forEach((item) => {
            if (item.procforsecs > item.queueMaxServiceTime + 60) {
              alerts.serviceTimeExceeded += 1;
            }
            if ((item.totalwait > item.queueMaxWaitTime + 60) && (item.status === 0)) {
              alerts.waitTimeExceeded += 1;
            }
          });
        }

        if (showNotifications) {
          if (alerts.waitTimeExceeded > 0 || alerts.serviceTimeExceeded > 0) {
            if (Notification.permission === 'granted') {
              const isMonitoringPage = window.location.pathname.includes('/monitoring');
              const notificationText = isMonitoringPage
                ? 'Превышено регламентное время обслуживания.'
                : 'Превышено регламентное время обслуживания.\nНажмите, чтобы перейти в мониторинг.';
              showNotification('Внимание!', { body: notificationText });
            } else {
              const isMonitoringPage = window.location.pathname.includes('/monitoring');
              notification.warning({
                message: 'Превышено регламентное время',
                description: isMonitoringPage ? '' : 'Подробнее в мониторинге',
                onClick: () => {
                  if (isMonitoringPage) return;
                  window.location.href = '/monitoring';
                },
              });
            }
          }
        }
      } catch (error) {
        console.error('Ошибка при запросе данных:', error);
        showNotification('Ошибка', { body: 'Произошла ошибка при обновлении данных' });
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 10000);

    return () => clearInterval(interval);
  }, [showNotifications]);

  return (
    <LayoutContainer>
      <SideNav />
      <LayoutBox>
        <Header
          showNotifications={showNotifications}
          setShowNotifications={setShowNotifications}
        />
        <MainConent>
          <Routes />
        </MainConent>
      </LayoutBox>
    </LayoutContainer>
  );
}

export default Layout;
