import iconFormatter from '../../../../../pages/private/utils/iconFormatter';

export default function TableConfig(write, searchPropsFactory) {
  return {
    columns: [
      {
        dataIndex: 'id',
        title: 'id',
        width: 100,
        // ...searchPropsFactory('id', 'id'),
        sorter: (a, b) => Number(a.id) - Number(b.id),
        defaultSortOrder: 'ascend',
      },
      {
        dataIndex: 'prefix',
        title: 'Префикс',
        width: 100,
        ...searchPropsFactory('prefix', 'префиксу'),
      },
      {
        dataIndex: 'position',
        title: 'Позиция',
        width: 100,
        ...searchPropsFactory('position', 'позиции'),
      },
      {
        dataIndex: 'name',
        title: 'Короткое название',
        ...searchPropsFactory('name', 'короткому названию'),
      },
      {
        dataIndex: 'fullname',
        title: 'Полное название',
        ...searchPropsFactory('fullname', 'полному названию'),
      },
      {
        dataIndex: 'Settings',
        title: ' ',
        render: () => iconFormatter({ filter: false, write, params: true }),
        width: 150,
      },
    ],
  };
}
