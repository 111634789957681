import React from 'react';
import { Routes } from 'react-router-dom';
import Home from './home/home';
import { getRoutes } from './getRoutes/getRoutes';
import { getInputScene } from './getRoutes/getInputScene';
import { getExtraRoutes } from './getRoutes/getExtraRoutes';

export default function Scene(props) {
  const {
    height, config, style, params, selectScreen, startScreen, headUrl,
  } = props;
  return selectScreen
    ? (
      <Home
        height={height}
        path={null}
        scene={selectScreen}
        style={style}
        selectScreen={selectScreen}
        startScreen={startScreen}
      />
    )
    : (
      <Routes>
        {[
          ...getRoutes({
            headUrl,
            config,
            startScreen,
            height,
            props,
          }),
          ...getExtraRoutes({
            headUrl,
            selectScreen,
            config,
            startScreen,
            style,
            props,
            params,
          }),
          ...getInputScene({
            headUrl,
            config,
            startScreen,
            height,
            props,
          }),
        ]}
      </Routes>
    );
}
