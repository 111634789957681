export default function ticketReducer(state = {}, action) {
  const { type, value } = action;
  switch (type) {
    case 'RECOVERY': {
      return {
        ...state,
        ...value,
      };
    }
    default:
      return state;
  }
}
