import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { changeDictionary } from '../../../store/actions';
import { Checkbox, CheckboxBox, Dot } from '../../utils/style';
import Store from '../../../store/store';

const { dispatch } = Store;

const CheckboxComponent = ({ scene }) => {
  const {
    dictionary, checkbox,
  } = useSelector((state) => ({
    ticket: state.ticket,
    checkbox: state.style.checkbox,
    headUrl: state.config.headUrl,
    id: state.config.id,
    dictionary: state.ticket.dictionary,
  }));
  const { buttons } = scene;
  const f = dictionary.find((el) => el.fid === scene.currentTicketField);
  const arr = f ? f.fv : [];

  const onClick = (id) => {
    const find = arr.some((el) => el === id);
    const fv = [...arr];
    if (find) {
      fv.splice(fv.indexOf(id), 1);
    } else {
      fv.push(id);
    }
    dispatch(changeDictionary({ fid: scene.currentTicketField, fv, to: scene.screenId }));
  };

  useEffect(() => {
    checkbox.fontSize = 40;
  }, []);

  return buttons.map((item) => {
    const active = arr.some((el) => el === item.id);
    const activeDotStyle = {
      ...checkbox,
      width: checkbox.height,
      marginRight: 30,
      background: active
        ? checkbox.background
        : 'white',
    };
    return (
      <CheckboxBox onClick={() => onClick(item.id)}>
        <Dot style={activeDotStyle} />
        <Checkbox
          arr={arr}
          key={item.id}
          st={checkbox}
          style={{
            ...checkbox,
            width: checkbox.width - checkbox.height - 30,
            marginLeft: 0,
            marginRight: 0,
          }}
          item={item}
        >
          {item.name}

        </Checkbox>
      </CheckboxBox>
    );
  });
};

export default React.memo(CheckboxComponent);
