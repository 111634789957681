import React, { useState, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {
  Table, Button, Input, Space,
} from 'antd';
import locale from 'antd/es/locale/ru_RU';
import TableConfig from './TableConfig';
import DeleteWarning from '../../../../../../common/ui/Modal/DeleteWarning';
import { useToggle } from '../../../../../../logic/hooks/useToggle';

function Surveys(props) {
  const {
    tableData, deleteSurvey, openSurveyDrawer,
  } = props;

  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const [showDelWarn, setShowDelWarn] = useToggle(false);
  const [surveyId, setSurveyId] = useState(undefined);
  const [surveyName, setSurveyName] = useState(undefined);
  const input = useRef(null);

  const handleDelete = (row) => {
    const { id, surveyname } = row;
    setSurveyName(surveyname);
    setSurveyId(id);
    setShowDelWarn();
  };

  const confirmDelete = () => {
    deleteSurvey(surveyId);
    setShowDelWarn();
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex, searchItemText) => ({
    // eslint-disable-next-line react/no-unstable-nested-components
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={input}
          placeholder={`Найти ${searchItemText}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Очистить
          </Button>
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => input.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const columns = TableConfig(getColumnSearchProps, handleDelete, openSurveyDrawer);

  return (
    <>
      <Table
        rowKey="id"
        dataSource={tableData}
        columns={columns.columns}
        size="middle"
        bordered
        locale={locale.Table}
      />
      <DeleteWarning
        name={surveyName}
        confirmDel={confirmDelete}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </>
  );
}

export default Surveys;
