import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Body, ConfirmMessage } from '../../utils/style';

function Comfirm({
  style, startScreen,
}) {
  const navigate = useNavigate();
  const timeout = useRef(null);
  const { confirmText } = useSelector((state) => state.config);

  useEffect(() => {
    if (confirmText !== 'Отсутствует соединение с сервером') {
      timeout.current = setTimeout(() => navigate(startScreen), 4000);
    }
    return () => clearTimeout(timeout.current);
  }, [confirmText]);

  const st = { ...style.extraTitle };
  st.fontSize = 30;
  const b = { ...style.body };
  if (!b.backgroundImage) {
    delete b.backgroundImage;
  }
  return (
    <Body style={b}>
      <ConfirmMessage>{confirmText || 'Не задан стартовый экран'}</ConfirmMessage>
    </Body>
  );
}

export default Comfirm;
