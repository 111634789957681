import styled from 'styled-components';

export const ReportRangeItem = styled.div`
    padding: 10px;
    display: flex;
    justify-content: center;
    width: ${(p) => p.width}px;
    align-items: center;
`;

export const ColorCell = styled.div`
    background-color: ${(p) => p.color};
    margin-left: 3px;
    border-radius: 7px;
`;
