import styled, {
  keyframes,
} from 'styled-components';
import img from './logo.png';

export const Body = styled.div`
  // margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => (p.isStartNotButtons ? 'center' : null)};
`;
export const PrintBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
export const BoxButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: ${(p) => (p.img ? 'flex-start' : 'center')};
  p {
    width: 100%;
    padding: 0 15px;
    margin: 0;
    line-height: inherit;
    text-align: center;
  }
  span {
    width: 100%;
    padding: 0 15px;
    display: inline-block;
  }
`;

export const PoliceUl = styled.ul`
  max-width: ${(p) => p.maxWidth}px;
  width: 100%;
  margin: 0 auto;
  max-height: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
  margin-bottom: 30px;
  height: 0;
  .policeListBottom {
    display: flex;
    .time {
      margin-right: 30px;
    }
  }
  li {
    display: flex;
    justify-content: space-between;
    .policeItemText {
      padding-right: 30px;
      flex: 1;
      p {
        -webkit-background-clip: text !important; /* Обрезаем фон по тексту */
        -webkit-text-fill-color: transparent; /* Прозрачный текст */
      }
    }
  }
`;

export const PolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  flex: 1;
  min-width: 100%;
  a {
    text-decoration: none;
  }
  .policyBox {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    display: flex;
    h2 {
      max-width: 90%;
    }
  }
  .policyBottomButtons {
    display: flex;
    justify-content: center;
  }
`;
export const ButtonContent = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  font-size: inherit;
  ${(p) => (p.contentPosition && !p.img ? 'center' : 'space-between')};
  justify-content: center;
  align-items: center;
  font-weight: inherit;
  flex-direction: ${(p) => (p.contentPosition ? 'column' : 'center')};
  div {
    font-weight: inherit;
    order: ${(p) => (p.iconPosition ? 1 : 0)};
    align-items: center;
    span {
      margin-top: 10px
    }
  }
  img {
    order: ${(p) => (!p.iconPosition ? 1 : 0)};
    min-height: ${(p) => `${p.heightIcon}px`};
    max-height: ${(p) => `${p.heightIcon}px`};
    width: ${(p) => (p.bottom ? '40%' : 'auto')};
    margin: 10px;
  }
  p {
    font-weight: inherit;
  }
  span {
    font-weight: inherit;
  }
`;
export const TextHelp = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const BigButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
export const InputButtonContainer = styled.div`
    display: flex;
    // padding: 0 80px;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: center;
    `;
export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 30px;
  a {
    margin: 0 15px !important;
  }
  div {
    //margin: 0 15px !important;
  }
`;
export const StyleButton = styled.div`
      align-items: center;
      display: flex;
      font-family: inherit;
      flex: 1;
      max-width: ${(p) => `${p.width}px`};
      animation-duration: 0.5s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      justify-content: center;
      flex-wrap: wrap;
      a {
        display: flex;
        font-family: inherit;
        align-items: center;
        box-sizing: border-box;
        justify-content: center;
        text-decoration: none;
      }
  `;
export const InputButton = styled.div`
      align-items: stretch;
      display: flex;
      font-family: inherit;
      flex: 1;
      animation-duration: 0.5s;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      justify-content: space-between;
      min-height: 100%;
  `;

export const BottomButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:nth-child(4) {
    min-width: 150px;
  }
  img {
    max-height: 40%;
    min-height: 40%;
    max-width: 40%;
  }
  p {
    font: inherit;
  }
`;
export const NextButton = styled.div`
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  width: max-content !important;
  height: 100px !important;
  font-size: 36px !important;
  box-sizing: border-box;
  margin: 30px auto;
`;
export const Checkbox = styled.div`
      position: relative;
      display: flex;
      background: ${({ arr, item, st }) => (!arr.some((el) => el === item.params.value)
    ? 'rgba(000.2)'
    : st.background)} !important;
      color: ${({ arr, item, st }) => (!arr.some((el) => el === item.params.value)
    ? '#fafafa'
    : st.background)} !important;
      box-shadow: ${({ arr, item, st }) => (!arr.some((el) => el === item.params.value)
    ? 'inset 0 0 15px 0 rgba(000.1)'
    : st.boxShadow)} !important;
      align-items: center;
      justify-content: center;
    }
    `;
export const Dot = styled.div`
  border-radius: 50% !important;
`;

export const CheckboxBox = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between
`;

const PreloaderAnimation = keyframes`
      0% {
        transform: rotate(0deg)
      }
      100% {
        transform: rotate(360deg)
      }`;
const LogoAnim = keyframes`
      0% {
        transform: scale(1)
      }
      10% {
        transform: scale(1.05)
      }
      20% {
        transform: scale(1)
      }
      30% {
        transform: scale(1.05)
      }
      40% {
        transform: scale(1)
      }
      100% {
        transform: scale(1)
      }`;

export const LocalWrapper = styled.div`
  position: relative;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const LogoBox = styled.div`
    max-width: 150px;
    max-height: 150px;
`;

export const Wrapper = styled.div`
    position: ${(p) => (p.op ? 'fixed' : 'static')};
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    width: 100%;
    height: ${(p) => (p.notZoneId ? '1080px' : '100%')};
    z-index: 99999;
    background: transparent;
    p {
      font-size: 34px;
      font-weight: bold;
      text-transform: uppercase;
      transform: translateX(-50%);
      position: absolute;
      left: 50%;
    }
  `;
export const Logo = styled.div`
    width: 50px;
    height: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    top: -70px;
    left: 20px;
    animation-name: ${LogoAnim};
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    //margin-left: -45px;
    background-image: url(${img});
  `;
export const Loader = styled.div`
  display: block;
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: rgba(245, 128, 32, 0.2);
  animation-name: ${PreloaderAnimation};
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    margin: -5px;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: rgb(245,192,159);
    animation-name: ${PreloaderAnimation};
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    margin: -5px;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: rgb(245,160,97);
    animation-name: ${PreloaderAnimation};
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }`;

export const QueueBox = styled.div`
  flex: 1;
  padding-top: 100px;
  h3 {
    text-align: center;
    text-transform:uppercase;
    margin-bottom: 15px;
  }
  .nextButton {
    margin: 30px auto 0 !important;
  }
  .Box {
    margin-bottom: 50px;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    ul {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      list-style: none;
    }
    &.times .item {
      width: 90px;
    }
    .item {
      padding: 15px 25px;
      min-width: 80px;
      margin: 15px;
      border: 1px solid rgba(0,0,0,.1);
      &.active {
        background: ${(p) => p.theme.accent};
        color: ${(p) => p.theme.buttonHover};
      }
    }
  }
  .flex {
    margin: 0 auto;
    width: max-content;
  }
`;

export const InputGroup = styled.div`
  width: ${(p) => `${p.input.width}px`};
  height: ${(p) => `${p.input.height}px`};
  margin-bottom: ${({ input }) => input.marginBottom}px;
  margin-top: ${({ input }) => input.marginTop}px;
  font-weight: ${(p) => p.input.fontWeight} !important;
  label, input {
    font: inherit;
    color: inherit;
  }
  label {
    text-transform: ${({ input }) => (input.textTransform)};
    font-size: ${({ input }) => input.fontSize}px;
  }
  input {
    min-width: 200px;
    outline: none;
    border-radius: ${({ input }) => input.borderRadius}px;
    border-color: ${({ input }) => input.borderColor};
    border-width: ${({ input }) => input.borderWidth}px;
    font-size: ${({ input }) => input.fontSize}px;
    margin-right: ${({ input }) => input.marginRight}px;
    margin-left: ${({ input }) => input.marginLeft}px;
    background: ${({ input }) => input.background};
    color: ${({ input }) => input.color};
    box-shadow: ${({ input }) => input.boxShadow};
    height: 100%;
    text-align: center;
    padding: 0 30px;
    border-style: solid;
    flex: 1;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const animationShow = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  } 100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;
export const ButtonsNotFound = styled.div`
    color: red;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    text-align: center;
    font-family: inherit;
    background: white;
    font-size: 30px;
    padding: 30px 50px;
    box-shadow: 0 3px 15px rgba(0,0,0,.1);
    border-radius: 5px;
    //animation: ${animationShow} 0.3s ease-out forwards 0.3s;
  `;

export const ConfirmMessage = styled.div`
    color: green;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    text-align: center;
    font-family: inherit;
    background: white;
    font-size: 30px;
    padding: 30px 50px;
    box-shadow: 0 3px 15px rgba(0,0,0,.1);
    border-radius: 5px;
    //animation: ${animationShow} 0.3s ease-out forwards 0.3s;
  `;

export const InputBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  label input {
    height: 60px;
    line-height: 60px;
  }
  label {
    margin-right: 50px;
  }
  input {
    outline: none;
    border-radius: ${({ button }) => button.borderRadius / 2}px;
    border-color: ${({ button }) => button.borderColor};
    border-width: ${({ button }) => button.borderWidth}px;
    font-size: ${({ button }) => button.fontSize * 0.8}px;
    color: ${(button) => button.color};
    text-align: center;
    padding: 0 30px;
    border-style: solid;
    flex: 1;
  }
  font-size: 32px;
  font-family: inherit;
  font-weight: ${({ button }) => button.fontWeight};
`;
export const KeyboardContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  .ready {
    width: min-content;
    margin: 30px auto 0;
  }
`;
export const MountBox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 100px;
  margin: 50px 0 0;
  .title {
    background: ${(p) => p.theme.accent};
    color: ${(p) => p.theme.buttonHover};
    &:after {
      display: none;
    }
  }
  li {
    width: calc(100% / 7 - 10px);
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0,0,0,.1);
    color: ${(p) => p.theme.disable};
    height: 50px;
    &.open {
      color: ${(p) => p.theme.text};
      border: 2px solid ${(p) => p.theme.accent};
    }
    &.active {
      background: ${(p) => p.theme.accent};
      color: ${(p) => p.theme.buttonHover};
    }
  }
`;
export const FilterMonth = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
    .filter {
      display: flex;
      justify-content: center;
    }
  }
  p {
    font-size: 28px;
    text-transform:uppercase;
    margin: 0 50px;
    min-width: 200px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  span {
    min-width: 50px;
    min-height: 50px;
    padding: 10px;
    border: 1px solid rgba(0,0,0,.1);
    display: flex;
    align-items: center;
    justify-content: center;
    &.shadow {
      border: none;
    }
  }
`;
export const Element = styled.div`
  position: absolute;
  display: inline-table;
  width: max-content;
  transform: translate(
    ${({ align }) => (align ? '-50%' : `
$ {
  item.x
}
px `)},
    ${({ item }) => item.y}px
  );
  left: ${({ align }) => (align ? '50%' : 'auto')};
  padding: 10px;
`;
export const ErrorText = styled.div`
  position: absolute;
  width: max-content;
  bottom: -100px;
  border-radius: 3px;
  padding: 15px 45px;
  text-transform: uppercase;
  color: white;
  font-family: Roboto;
  background: red;
  font-weight: bold;
`;
export const Img = styled.div`
  flex: 1;
  width: 100%;
  background-image: url(${({ scan }) => scan});
  -webkit-background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
export const ScanInfoHead = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin: 20px 0 20px;
`;
export const ScanInfoValue = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 26px;
  align-items: center;
  width: 100%;
  padding: 10px 20px 10px;
  div {
    margin-left: 30px;
    font-weight: bold;
    font-size: 30px;
  }
  &:nth-child(2n) {
    background: rgba(000.05);
  }
`;
export const TwoScreenBox = styled(BigButtonsContainer)`
  flex-direction: column;
  flex: auto;
  > div {
    flex: 1;
    max-height: 50%;
  }
  h1 {
    text-align: center;
  }
`;

export const BottomBox = styled.div`
  width: 100%;
  min-height: 50%;
  max-height: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const DontPrintTicketAlert = styled.div`
    max-width: 500px;
    margin-bottom: 100px;
    text-align: center;   
    >p{
      font-size: 48px;
      color: #565656;
      font-weight: 500;
      font-family: 'Roboto',serif;
    }

      @media screen and (min-aspect-ratio: 1280/800) {
        max-width: 465px;
        margin-bottom: 30px;
        >p {
          font-size: 30px;
        }
      }

      @media (max-width: 1280){
          >p{
              font-size: 30px;
          }
      }

`;
