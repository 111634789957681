import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

function FormTooltip(props) {
  const { text, margin = '0 0 0 5px' } = props;
  return (
    <span style={{ margin }}>
      <Tooltip title={text}>
        <QuestionCircleOutlined />
      </Tooltip>
    </span>
  );
}

export default FormTooltip;
