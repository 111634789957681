import React from 'react';
import { Input } from '../../../../../../../../../../common/ui/Common';

function Position({ bottomButton, onChange }) {
  const arr = [
    { title: 'Слева', value: bottomButton.left, param: 'left' },
    { title: 'Сверху', value: bottomButton.top, param: 'top' },
  ];
  return (
    <div className="box">
      {arr.map((el) => (
        <span className="box__color">
          <span>{el.title}</span>
          <Input
            size="16px"
            disabled={bottomButton.position}
            width="100px"
            textAlign="center"
            type="number"
            onChange={(e) => onChange(e, el.param)}
            value={el.value}
          />
        </span>
      ))}
    </div>
  );
}

export default Position;
