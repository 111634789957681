import iconFormatter from '../../../../pages/private/utils/iconFormatter';

export default function TableConfig(searchPropsFactory) {
  return {
    columns: [
      {
        dataIndex: 'id',
        title: 'ID',
        ...searchPropsFactory('id', 'ID'),
      },
      {
        dataIndex: 'name',
        title: 'Имя',
        ...searchPropsFactory('name', 'Имя'),
      },
      {
        dataIndex: 'longname',
        title: 'Наименование',
        ...searchPropsFactory('longname', 'Наименование'),
      },
      {
        dataIndex: 'description',
        title: 'Описание',
        ...searchPropsFactory('description', 'Описание'),
      },
      {
        dataIndex: 'Settings',
        width: 150,
        render: () => iconFormatter({
          filter: false, write: true, params: false, styles: true,
        }),
      },
    ],
  };
}
