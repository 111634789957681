import { Button, Input, Space } from 'antd';
import {
  LoginBox, LoginForm, Btn, FormBox, Terminals, AlphaMat, Sueo,
} from './styles/LoginStyles';
import Logotype from '../../../common/images/logo.png';
import OfficeParams from './common/officeParams';

import Modal from '../../../common/ui/Modal/Modal';
import { useLogin } from './use/useLogin';
import { useStyleAndAnimations } from './use/useStyleAndAnimations';
import { LoginContext } from './context';

function Login() {
  const data = useLogin();

  const {
    office,
    handleLogin,
    handleInput,
    isOpen,
    resetModal,
    toggleOpen,
    terminals,
    alphaM,
    sueo,
    setOffice,
    save,
    setOpen,
    open,
  } = data;

  const {
    items,
    modalStyle,
    mouseMove,
    animationForm,
  } = useStyleAndAnimations(data);

  const [loginItem, passwordItem] = items;

  const isValid = office.length > 0 && office.every((el) => el.value);
  return (
    <LoginContext.Provider value={data}>
      <LoginBox onMouseMove={mouseMove}>
        <FormBox className={animationForm ? 'login' : null}>
          <LoginForm onSubmit={handleLogin} className={animationForm ? 'animationForm' : ''}>
            <img src={Logotype} alt="" />
            <h1>Электронная очередь</h1>
            <h2>Вход</h2>
            <h3>Панель администрирования</h3>
            <Space direction="vertical" size="middle">
              <Input
                required
                autocomplite
                placeholder="Логин"
                id={loginItem.name}
                name={loginItem.name}
                value={loginItem.value}
                onChange={handleInput}
                style={{ color: '#454545', width: '100%' }}
                status={!loginItem.value ? 'error' : null}
              />
              <Input.Password
                required
                autocomplite
                placeholder="Пароль"
                id={passwordItem.name}
                name={passwordItem.name}
                value={passwordItem.value}
                onChange={handleInput}
                style={{ color: '#454545', width: '100%' }}
                status={!passwordItem.value ? 'error' : null}
              />
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
                disabled={!loginItem.value || !passwordItem.value}
              >
                Войти
              </Button>
            </Space>
          </LoginForm>
          <Modal
            title=""
            isOpen={isOpen}
            toggleOpen={resetModal}
            style={modalStyle}
          >
            <h1>Вы не можете войти в админ панель</h1>
            <Btn
              cancel
              onClick={() => {
                toggleOpen();
              }}
            >
              Назад
            </Btn>
          </Modal>
        </FormBox>
        <Terminals className={animationForm ? 'login' : null} ref={terminals} />
        <AlphaMat className={animationForm ? 'login' : null} ref={alphaM} />
        <Sueo className={animationForm ? 'login' : null} ref={sueo} />
        <OfficeParams
          office={office}
          isValid={isValid}
          setOffice={setOffice}
          save={save}
          setOpen={setOpen}
          open={open}
        />
      </LoginBox>
    </LoginContext.Provider>
  );
}

export default Login;
