export default function TableConfig() {
  return {
    columns: [
      {
        dataIndex: 'paend_on',
        title: 'Дата записи',
      },
      {
        dataIndex: 'pincode',
        title: 'Пин-код',
      },
      {
        dataIndex: 'clientfio',
        title: 'ФИО',
      },
      {
        dataIndex: 'clientmail',
        title: 'E-mail',
      },
      {
        dataIndex: 'clientphone',
        title: 'Телефон',
      },
      {
        dataIndex: 'open_data',
        title: 'Дата выдачи талона (если был выдан)',
      },
      {
        dataIndex: 'close_data',
        title: 'Дата завершения',
      },
      {
        dataIndex: 'q_name',
        title: 'Услуга',
      },
      {
        dataIndex: 'ticket_number',
        title: 'Номер талона',
      },
      {
        dataIndex: 'total_wait_time',
        title: 'Ожидание (мин)',
      },
      {
        dataIndex: 'total_proc_time',
        title: 'Обслуживание (мин)',
      },
      {
        dataIndex: 'fio',
        title: 'Оператор',
      },
      {
        dataIndex: 'wp_name',
        title: 'Рабочее место',
      },
      {
        dataIndex: 'status',
        title: 'Статус',
      },
      {
        dataIndex: 'ticket_mark',
        title: 'Оценка',
      },
      {
        dataIndex: 'slot_created',
        title: 'Дата создания слота',
      },
      {
        dataIndex: 'registered',
        title: 'Зарегистрированный',
      },
    ],
  };
}
