export default ({ button }) => [
  {
    title: 'Толщина обводки', value: button.borderWidth, paramName: 'borderWidth', parentName: 'button',
  },
  {
    title: 'Угол скругления', value: button.borderRadius, paramName: 'borderRadius', parentName: 'button',
  },
  {
    title: 'Высота иконки', value: button.icon.height, paramName: 'icon.height', parentName: 'button',
  },
  {
    title: 'Расстояние</br> между строчками', value: button.lineHeight, paramName: 'lineHeight', parentName: 'button',
  },
  {
    title: 'Высота</br> нижнего блока', value: button.bottomHeight, paramName: 'bottomHeight', parentName: 'button',
  },
];
