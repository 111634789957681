import { Button } from 'antd/lib/radio';
import React from 'react';
import randomId from '../../../../../logic/utils/randomId';

function queueFormatter(cell, queues) {
  const displayQueueList = [];
  cell.forEach((el) => queues.forEach((q) => q.id === el && displayQueueList.push(q.name)));
  return (
    <div className="filterBoxTable">
      {displayQueueList.map((el) => (
        <p key={randomId()}>{el}</p>
      ))}
    </div>
  );
}

const TaableConfig = (queueList, deleteQuestion, updateQuestion, getColumnSearchProps) => ({
  columns: [
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Вопрос/Услуга',
      dataIndex: 'displayname',
      key: 'displayname',
      ...getColumnSearchProps('displayname'),
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      ...getColumnSearchProps('description'),
    },
    {
      title: 'Регламентное время решения вопроса (сек)',
      dataIndex: 'regulatoryTime',
      key: 'regulatoryTime',
      ...getColumnSearchProps('regulatoryTime'),
    },
    {
      title: 'Прикрепленные услуги',
      dataIndex: 'connectedQueues',
      key: 'connectedQueues',
      render: (cell) => queueFormatter(cell, queueList),
    },
    {
      title: 'Действие',
      key: 'operation',
      dataIndex: '',
      fixed: 'right',
      width: 100,
      render: (_, row) => (
        <div style={{ display: 'flex' }}>
          <Button onClick={() => { updateQuestion(row); }}>Редактировать</Button>
          <Button onClick={() => { deleteQuestion(row); }} style={{ marginLeft: '8px' }}>Удалить</Button>
        </div>
      ),
    },
  ],
});

export default TaableConfig;
