import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';
import request from '../../../../logic/utils/request';
import NewTable from './Table/NewTable';

function NewParams({ preloader, showError }) {
  const [groups, setGroups] = useState([]);
  const [paramList, setParamList] = useState(null);
  const [multiData, setMultiData] = useState([]);

  const fetchData = useCallback(async () => {
    preloader();
    try {
      const res = await axios({
        method: 'get',
        url: '/api/soundlist',
      });
      const newMultiData = [];
      newMultiData.push(res.data.sounds);
      newMultiData.push(res.data.variables);
      setMultiData(newMultiData);
      preloader();
    } catch (e) {
      showError(e.data.error);
      preloader();
    }
  }, [preloader, showError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchParams = useCallback(async () => {
    try {
      const newParams = await request('/server/api/officeparams/', 'get');

      setParamList(newParams.data.data);

      const groupsArr = newParams.data.data.map((param) => param.group_name);
      const groupObj = {};
      for (let i = 0; i < groupsArr.length; i += 1) {
        const str = groupsArr[i];
        groupObj[str] = true;
      }
      const newGroup = Object.keys(groupObj);
      setGroups([...newGroup]);
    } catch (e) {
      showError(e.data.error);
    }
  }, [showError]);

  useEffect(() => {
    fetchParams();
  }, [fetchParams]);

  console.log(groups, paramList, multiData);
  return (
    <>
      <h1>Параметры сервера</h1>
      {groups.length > 0
        ? (
          <NewTable
            groups={groups}
            paramList={paramList}
            multiData={multiData}
            showError={showError}
          />
        ) : <Spin />}
      <div />
    </>
  );
}

export default NewParams;
