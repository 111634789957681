import styled from 'styled-components';

export const RenderBox = styled.div`
  display: flex;
  margin: 30px auto;
  border-radius: 3px;
  width: max-content;
  flex-wrap: wrap;
  h4 {
    width: 100%;
  }
  > div {
    margin-right: 30px;
    min-width: calc(20% - 30px);    
    max-width: calc(20% - 30px);
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ScreenCardsBox = styled.div`
  display: flex;
  > div {
    flex: 1;
  }
`;
