import React from 'react';
import { HexColorPicker } from 'react-colorful';
import {
  Col,
  Input,
  Row,
  Dropdown,
} from 'antd';

function ColorPicker(props) {
  const { color, onChange, name } = props;

  return (
    <Input.Group size="large">
      <Row gutter={8}>
        <Col>
          <Input
            value={color}
            onChange={(e) => {
              onChange(e.target.value, name);
            }}
          />
        </Col>
        <Col>
          <Dropdown
            overlay={(
              <div className="colorPicker">
                <HexColorPicker
                  color={color}
                  onChange={(e) => {
                    onChange(e, name);
                  }}
                  name={name}
                />
              </div>
             )}
            placement="bottom"
          >
            <div
              className="colorPicker colorPicker__preview"
              style={{ background: `${color}`.includes('#') ? color : `#${color}` }}
            />
          </Dropdown>

        </Col>
      </Row>
    </Input.Group>
  );
}

export default ColorPicker;
