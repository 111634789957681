import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { ContentBox, PreloaderBox, Title } from '../../../../common/ui/Common';
import ReserveTable from './ReserveTable';

function Reserve({ showError, preloader }) {
  const [reserveList, setReserveList] = useState([]);

  const fetchData = useCallback(async () => {
    preloader();
    try {
      const res = await axios({
        method: 'get',
        url: '/api/backups',
      });
      const { data: { backups } } = res;
      setReserveList(backups);
      preloader();
    } catch (e) {
      showError(e);
      preloader();
    }
  }, [preloader, showError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Title>Резервные копии базы данных</Title>
      <ContentBox>
        <PreloaderBox>
          <ReserveTable preloader={preloader} fetchData={fetchData} reserveList={reserveList} />
        </PreloaderBox>
      </ContentBox>
    </>
  );
}

export default Reserve;
