import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BoxButtonContent, ButtonContent, PolicyContainer } from '../../../../../utils/style';
import changeStyle from '../../../../common/changeStyle';
import PoliceButton from './policyButton';
import { resetScanPolicyStore, updateScanPolicyStore } from '../../../../../../../../../logic/store/pullstate/scanPolicy';

function Start({
  headUrl, button, terminalId, startScreen,
}) {
  const navigate = useNavigate();
  const buttons = [
    { link: `${headUrl}/${terminalId}/scanPolicy/scan`, text: 'Считать полис', name: 'scan' },
    { link: `${headUrl}/${terminalId}/scanPolicy/inner`, text: 'Ввести данные полиса', name: 'inner' },
  ];
  const styleButton = changeStyle({ style: button, item: {} });

  const test = (name) => {
    updateScanPolicyStore({ activeScreen: name });
  };

  return (
    <>
      <PolicyContainer>
        <div className="policyBox">
          {buttons.map((el) => (
            <PoliceButton
              key={el.link}
              style={styleButton}
              el={el}
              handeleClick={test}
              name={el.name}
            />
          ))}
        </div>
      </PolicyContainer>

      <div className="policyBottomButtons" style={{ display: 'flex', justifyContent: 'center' }}>

        <div
          onDragStart={() => false}
          style={{ ...changeStyle({ style: button, item: { size: 'sm' } }), minWidth: 'max-content' }}
          onClick={() => {
            resetScanPolicyStore();
            navigate(startScreen);
          }}
        >
          <ButtonContent>
            <BoxButtonContent>
              <p style={{ ...styleButton.text, textAlign: styleButton.textAlign }}>На главную</p>
            </BoxButtonContent>
          </ButtonContent>
        </div>

      </div>
    </>
  );
}

export default Start;
