import styled, { keyframes } from 'styled-components';

function animationHover(add, w) {
  const pxWidth = w ? `${w}px` : '120px';

  return keyframes`
    0% {
      box-shadow: 0 0 0 ${(p) => p.theme.accentl};
    }
    100% {
      box-shadow: 0 0 0 3px ${(p) => p.theme.accentl};
      max-width: ${add ? pxWidth : 'auto'};
    }
  `;
}

export const Combo = styled.div`
  position: relative;
  max-width: 350px;
  width: 100%;
  span {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 3px 3px 0;
    background-color: ${(p) => p.theme.accent};
    height: 40px;
    cursor: pointer;
    width: 40px;
    &:hover {
      animation: ${animationHover} 0.1s ease-out forwards;
    }
    svg path {
      fill: ${(p) => p.theme.white}
    }
  }
`;

export const FilterInput = styled.input`
  height: 30px;
  width: 100%;
  border: none;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
  padding: 5px;
  outline: 0;
`;

export const FilterList = styled.ul`
  display: ${(p) => (p.show ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute; 
  will-change: transform;
  transform: translate3d(0px, 30px, 0px);
  max-height: 300px;
  width: 100%;
  overflow: auto;
  top: 15px;
  left: 0;
  z-index: 9;
  background: ${(p) => p.theme.bgTable};
  border-radius: 3px;
  box-shadow: 0 3px 8px rgba(0,0,0,.1);
`;

export const Filter = styled.li`
  cursor: pointer;
  padding: 10px 15px;
  transition: 0.1s ease-out;
  &:hover{
    background: ${(p) => p.theme.accent};
    color: ${(p) => p.theme.buttonHover};
  }
`;
